<template>
	<!-- tooltip-->
	<div class="clickable" v-bind:class="cssClass" v-if="!isLinkToPrevent && showLink">
		<router-link :to="href">
			<!-- top icon removed-->
			<image-placeholder v-if="visible && !imgObj" :style="{width: identifyPixelWidth() + 'px', height: identifyPixelHeight()+ 'px'}"
							   :isCustomImagePlaceholder="options.isCustomImagePlaceholder"
							   :cssClass="svgClass()"
							   :placeholderEntityName="'#entity-icons-'+placeholderEntityName">
			</image-placeholder>
			<div v-else-if="visible && imgObj" v-bg-image="imgObj"></div>
		</router-link>
	</div>
	<div class="clickable not-clickable" v-bind:class="cssClass" v-else-if="isLinkToPrevent || !showLink">
		<a @click="redirect">
			<image-placeholder v-if="visible && !imgObj" :style="{width: identifyPixelWidth() + 'px', height: identifyPixelHeight()+ 'px'}"
							   :isCustomImagePlaceholder="options.isCustomImagePlaceholder"
							   :cssClass="svgClass()"
							   :placeholderEntityName="'#entity-icons-'+placeholderEntityName">
			</image-placeholder>
			<div v-else-if="visible && imgObj" v-bg-image="imgObj"></div>
		</a>
	</div>
</template>
<script>
/* eslint no-unused-vars: 0 */
import { recordLink } from '@acx-xms/data-functions/dist';
import bgImage from './../../../Bindings/bg-image.directive';
import Image from './../../../Bindings/image.directive';
import helperMethods from './../../ComponentSet/component-set-evaluation-context-helper.methods';
import ImageMixins from './images.mixins.js';
export default {
	name: 'control-svg-image-reference',
	mixins: [ImageMixins],
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			visible: (this.options.visible) !== void 0 ? this.evaluationContext.eval(this.options.visible) : true,
			placeholderEntityName: this.evaluationContext.eval(this.options.placeholderEntityName) || '',
			cssClass: (this.options.cssClass) ? helperMethods.eval(this.evaluationContext, this.options.cssClass) : '',
			imgObj: null,
			placeholderClass: '',
			clickableRef: null,
			href: ''
		};
	},
	methods: {
		redirect() {
			if (!this.showLink) {
				return;
			}
			this.$router.push(this.href);
		}
	},
	created() {
		const subtype = this.evaluationContext.eval(this.options.subtype) || '';

		this.placeholderClass = subtype === ''
			? 'entity-icons-' + this.placeholderEntityName + '-' + this.options.size
			: 'entity-icons-' + this.placeholderEntityName + '-' + this.subtype + '-' + this.options.size;
		const ref = (this.options.imageRef) ? this.evaluationContext.eval(this.options.imageRef) : null;
		if (ref && ref.id) {
			this.buildImageUrl(ref);
		} else if (this.options.imageUrl) {
			this.imgObj = this.defaultImgObj();
			this.imgObj.src = this.evaluationContext.eval(this.options.imageUrl);
		} else if (this.options.smartIconText) {
			const dataProvider = sc.classes.get('smartIcon.dataProvider', this.evaluationContext.eval);
			this.fontSize = this.options.fontSize;
			const imageFontSize = () => {
				if (this.fontSize && this.fontSize > 0) {
					return this.fontSize;
				}
				switch (this.options.size) {
				case 'small':
					return 18;
				case 'medium':
					return 26;
				default:
					return 26;
				}
			};
			this.processImageUrl();
			if (!this.imgObj || !this.imgObj.src) {
				let smartIconText = this.evaluationContext.eval(this.options.smartIconText);
				smartIconText = typeof smartIconText === 'string' ? smartIconText.toUpperCase() : smartIconText;
				smartIconText = encodeURIComponent(smartIconText);

				dataProvider.getImageUrl(smartIconText,
					this.identifyPixelSize(),
					imageFontSize(),
					'Segoe UI Semibold').done((imageUrl) => {
					this.imgObj = {
						src: imageUrl,
						width: this.identifyPixelSize(),
						height: this.identifyPixelSize(),
						placeholder: ''
					};
				});
			}
		}

		this.clickableRef = this.evaluationContext.entity;
		if (this.options.useValueAsRef) {
			this.clickableRef = this.evaluationContext.eval(this.options.value);
		}

		this.href = recordLink(this.$route, this.clickableRef);
		this.isLinkToPrevent = this.options.layoutsToPreventLink
			? this.options.layoutsToPreventLink.find(item => this.$route.params.layout.indexOf(item) !== -1)
			: false;
		this.showLink = this.options.showLink;
	}
};
</script>
<style src="./images.mixins.less" scoped></style>
