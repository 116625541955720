import { isActive, checkPrivilege } from './Methods/action.methods';

export default {
	async enabled(options, evaluationContext, selection) {
		if (selection && selection.length === 1) {
			const active = await isActive(evaluationContext, selection);

			if (!active) {
				return false;
			}
			return await checkPrivilege('staticlist', 'Write');
		}

		return false;
	},
	async execute(options, context, selection) {
		sc.classes.get('logger.processor').track('Static lists: edit');
		const sel = selection || context.eval(options.selection);
		const maximizedWidth = sc.classes.get('additionalParameters.controller').getValue('staticlist-edit-dialog-maximized-width', '90%');
		const maximizedHeight = sc.classes.get('additionalParameters.controller').getValue('staticlist-edit-dialog-maximized-height', '100%');

		const title = await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('staticListsPanel.editor.dialogTitle');
		const componentOptions = await sc.classes.get('marketspaceComponents.dataProvider').getComponentSetById(context.eval(options.componentsetName));
		const dialogConfig = {
			component: 'staticlists-ms-edit',
			title,
			icon: 'entity-icons-awx_adhoclist-small',
			maximizedWidth,
			maximizedHeight,
			params: Object.assign({}, {
				entity: sel[0],
				componentOptions: componentOptions.component[0],
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey)
			}, options)
		};

		sc.events.emit('vue.dialog.open', dialogConfig);
	}
};
