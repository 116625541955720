import { isActive } from './Methods/action.methods';

export default {
	enabled(options, evaluationContext, selection) {
		return isActive(evaluationContext, selection);
	},

	async execute(options, context, selection) {
		const localizations = sc.classes.get('localization.dataProvider');
		const _selection = context.eval(options.selection) || selection[0];

		sc.events.emit('vue.dialog.open', {
			title: await localizations.getLabelForCurrentLanguage('dialogues.manageAccessToRoom.title'),
			component: 'manage.access.to.room',
			maximizedWidth: '450px',
			maximizedHeight: '350px',
			parameters: { accessuserid: sc.utils.findProperty(_selection, 'Source.accessuserid', true) }
		});
	}
};
