var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-loader" },
    [
      _vm.message
        ? _c("initialization-panel", { attrs: { message: _vm.message } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.message
        ? _c(
            _vm.layoutName,
            _vm._b({ tag: "component" }, "component", _vm.layoutOptions, false)
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }