import { GetUserInfo } from '@/Data/Auth/authentication-service';
import { evaluate } from '@/Data/EvaluationContext/evaluationContext';

export default {
	props: {
		params: Object,
		isAnonymous: Boolean
	},

	data() {
		return {
			menuState: '',
			profileIconDefault: null,
			userProfilePhoto: null,
			firstName: '',
			lastName: '',
			currentLanguage: null,
			currentSom: '',
			currentCurrency: '',
			singoutComponent: null,
			enable: false
		};
	},

	methods: {
		initLanguges() {
			this._selectedLanguage = undefined;
			this.availableLanguages = [];
			this.previousLang = null;
			this.isLanguageChanged = false;

			this.availableLanguagesVisible = () => {
				const langs = this.availableLanguages();
				return langs && langs.length > 0;
			};
			Promise.all([
				this._localizationDataProvider.getAllLanguages(),
				this._localizationDataProvider.getCurrentLanguage()
			]).then(([availableLanguages, userLanguage]) => {
				this.availableLanguages = availableLanguages;
				if (userLanguage) {
					this.previousLang = userLanguage;
					this._selectedLanguage = userLanguage;
					this.currentLanguage = userLanguage.displayName;
				}
			});
		},

		isCurrentLanguage(langDisplayName) {
			if (!langDisplayName) {
				return false;
			}

			const currentLang = this.previousLang;
			return currentLang && currentLang.displayName === langDisplayName;
		},

		initSystemsOfMeasure() {
			this.currentSom = 'English';
		},

		initTransactionCurrencies() {
			this.currentCurrency = 'United States Dollar';
		},

		toggleMenu() {
			if (this.menuState === 'collapsed') {
				this.openMenu();
			} else {
				this.closeMenu();
			}
		},

		closeMenu() {
			this.menuState = 'collapsed';
		},

		openMenu() {
			this.menuState = 'expanded';
		},

		redirectToSignIn() {
			window.location = window.location.href.substring(0, location.href.indexOf(sc.cluster.configKey + '/')).replace(/[Aa]nonymous/, '');
		},

		logout() {
			sc.classes.get('authentication').logout(true).done(function (url) {
				window.location.assign(url);
			});
		},

		async getUserPhoto() {
			const userInfo = await GetUserInfo(true);
			const avatarid = sc.utils.findProperty(userInfo, 'avatarid', true);
			if (avatarid) {
				this.userProfilePhoto = sc.classes.get('edge.dataProvider').getImageUrlWithToken('avatar', avatarid.id, 'thumbnail');
			}
		},

		async getUserInfo() {
			const info = await GetUserInfo();
			this.firstName = info.firstname;
			this.lastName = info.lastname;
			this.domainName = info.internalemailaddress;
			this.enable = true;
		},
		entityCreated(args) {
			if (args && args.entity && args.entity.logicalname === 'avatar') {
				this.getUserPhoto();
			}
		}
	},

	watch: {
		currentLanguage(newValue) {
			if (newValue && !this.isCurrentLanguage(newValue)) {
				const newLanguage = this.availableLanguages && this.availableLanguages.find(item => {
					return item.displayName === newValue;
				});

				if (newLanguage) {
					this.isLanguageChanged = true;
					this._selectedLanguage = newLanguage;
				}
			} else {
				this.isLanguageChanged = false;
			}
		}
	},
	computed: {
		fullName() {
			return this.firstName + ' ' + this.lastName;
		}
	},
	created() {
		this.options = {
			defaultIcon: 'ms-usericon-default',
			namespace: '',
			internalNamespace: '',
			...this.params.userProfileMenu
		};
		this.profileIconDefault = this.options.defaultIcon;

		this.namespace = evaluate(this.options.namespace);
		this.internalNamespace = evaluate(this.options.internalNamespace);
		this.detailTemplateRef = this.options.detailTemplate;
		this.viewProfileVisible = (this.options.viewProfileVisible) ? evaluate(this.options.viewProfileVisible) : true;

		this._uomDataProvider = sc.classes.get('uom.dataProvider');
		this._localizationDataProvider = sc.classes.get('localization.dataProvider');

		this.menuState = 'collapsed';
		this.outsideClickListener = null;
		this.domainName = '';

		this.getUserInfo();

		this.getUserPhoto();

		this.initLanguges();
		this.initSystemsOfMeasure();
		this.initTransactionCurrencies();
		this.$root.$on('entity.created', this.entityCreated);

		this.$parent.$on('toggleMenu', this.toggleMenu);
	},
	beforeDestroy() {
		this.$root.off('entity.created', this.entityCreated);
	}
};
