import { isArray } from '@acx-xms/data-functions/dist';
import store from '@/States/ms-state';
import { GetUserInfo } from '@/Data/Auth/authentication-service';

export const checkPrivilege = (entity, privilege) => {
	if (isArray(entity)) {
		const promises = [];
		entity.forEach(logicalName => {
			promises.push(sc.classes.get('userPrivileges.dataProvider').getPrivilegeForEntity(logicalName, privilege));
		});
		return $.when.apply($, promises).then(function () {
			const args = Array.prototype.slice.call(arguments, 0); // Arguments to Array
			return args.every(arg => {
				return !!arg;
			});
		});
	} else {
		return sc.classes.get('userPrivileges.dataProvider').getPrivilegeForEntity(entity, privilege);
	}
};

export const isActive = async (evaluationContext, selection) => {
	const selections = selection.length && selection[0].Source
		? selection
		: [evaluationContext.eval({ $type: 'expression.source' })];

	const entityConfig = await sc.classes.get('entityConfiguration.dataProvider').fetchEntity(evaluationContext.entity.logicalname);

	if (!entityConfig || !entityConfig.closeCompleteStatuses3) {
		return true;
	}

	return selections.every(selction => {
		const recordstate = sc.utils.findProperty(selction, 'source.recordstate', true);

		const inactiveStateCode = evaluationContext.eval((entityConfig.closeCompleteStatuses3 || {}).inactiveStateCode);
		if (inactiveStateCode && recordstate) {
			return recordstate.id !== inactiveStateCode.id;
		} else {
			return true;
		}
	});
};

export const isAllowedActivation = (options, evaluationContext, selection) => {
	return _isAllowedChangeEntityState(options, evaluationContext, selection, false);
};

export const isAllowedEntity = (allowedEntityTypes, fullselection) => {
	let valid = true;
	if (allowedEntityTypes && fullselection) {
		valid = Object.keys(fullselection).filter(logicalname => fullselection[logicalname].length).every(logicalname => {
			return allowedEntityTypes.includes(logicalname);
		});
	}
	return valid;
};

export const isAllowedDeactivation = (options, evaluationContext, selection) => {
	return _isAllowedChangeEntityState(options, evaluationContext, selection, true);
};

const _isAllowedChangeEntityState = (options, evaluationContext, selection, activate) => {
	const def = $.Deferred();
	if (!selection) {
		return $.Deferred(function (def) { def.resolve(false); }).promise();
	}
	if (selection && selection.length) {
		const entityRefs = selection.map(item => {
			return sc.classes.is(item).instanceOf('entityReference') ? item : sc.classes.get('entityReference', item);
		});

		const logicalNames = [...new Set(entityRefs.map(ref => ref.logicalname))].valueOf();
		if (logicalNames.length !== 1) {
			def.resolve(false);
		}

		const privilegePromise = checkPrivilege(logicalNames[0], 'Write');
		const entityConfigurationPromise = sc.classes.get('entityConfiguration.dataProvider').fetchEntity(logicalNames[0]);

		$.when(privilegePromise, entityConfigurationPromise).then((isAllowed, entityConfig) => {
			if (!entityConfig.closeCompleteStatuses3) {
				def.resolve(false);
			}

			const inactiveStateCode = evaluationContext.eval((entityConfig.closeCompleteStatuses3 || {}).inactiveStateCode);

			if (!inactiveStateCode.id) {
				def.resolve(false);
			}

			const checkActive = function (item) {
				const recordstate = sc.utils.findProperty(item, 'source.recordstate', true);
				const isActive = recordstate && recordstate.id !== inactiveStateCode.id;

				return recordstate === null || activate ? isActive : !isActive;
			};

			const selectionHasSourceData = selection.every(item => { return !!item.Source; });

			if (!entityConfig) {
				def.resolve(false);
			}

			const entityData = evaluationContext.eval({ $type: 'expression.source' });

			def.resolve(isAllowed && entityData && !selectionHasSourceData
				? checkActive(entityData)
				: selection.every(checkActive));
		});
	} else {
		def.resolve(false);
	}

	return def.promise();
};

export const mapParametersObject = (parameters, evaluationContext) => {
	return _.reduce(
		parameters,
		function (result, param, key) {
			if (key !== '$type') {
				result[param.name] = evaluationContext.eval(param.value);
			}
			return result;
		},
		{});
};

export const log = (message) => {
	sc.classes.get('logger.processor').track(message);
};

export const isUserCoownerOrOwner = async (evaluationContext) => {
	const stateKey = 'dealroom.participants';
	const entityName = 'chatparticipant';
	const items = store.getters[stateKey + '/getSearchResults'](entityName);
	const chatId = evaluationContext.eval({
		$type: 'expression.field',
		name: 'chatparticipantchatidchat.chatid',
		schema: 'source'
	});
	const { systemuserid } = await GetUserInfo();
	if (items && items[chatId]) {
		const userCP = items[chatId].find(item => item.Source.chatparticipantsystemuseridsystemuser && item.Source.chatparticipantsystemuseridsystemuser.systemuserid === systemuserid);
		if (userCP && (userCP.Source.coowner || userCP.Source.isroomowner)) {
			return true;
		}
	}
	return false;
};
