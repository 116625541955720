var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.images.length > 0
    ? _c(
        "div",
        { staticClass: "image-gallery-mobile" },
        [
          !_vm.reference
            ? _c(
                "div",
                {
                  staticClass: "wrapper",
                  on: { scroll: _vm.onScrollDebounced }
                },
                _vm._l(_vm.images, function(image, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "image-node",
                      style: { height: _vm.imageHeight }
                    },
                    [
                      _c("div", {
                        staticClass: "bg-image",
                        style: { "background-image": "url(" + image + ")" }
                      })
                    ]
                  )
                })
              )
            : _vm.reference
            ? _c(
                "router-link",
                {
                  staticClass: "wrapper clickable",
                  attrs: { to: _vm.reference }
                },
                _vm._l(_vm.images, function(image, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "image-node",
                      style: { height: _vm.imageHeight }
                    },
                    [
                      _c("div", {
                        staticClass: "bg-image",
                        style: { "background-image": "url(" + image + ")" }
                      })
                    ]
                  )
                })
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.options.showCounter || _vm.images.length > 1
            ? _c("div", { staticClass: "images-counter p2" }, [
                _vm._v(
                  _vm._s(_vm.currentImage) + " of " + _vm._s(_vm.images.length)
                )
              ])
            : _vm._e()
        ],
        1
      )
    : _vm.images.length == 0
    ? _c(
        "div",
        { staticClass: "svg-thumbnail", style: { height: _vm.imageHeight } },
        [
          _c("svg", { staticClass: "svg-icon svg-20" }, [
            _c("use", { attrs: { "xlink:href": _vm.placeholderEntityName } })
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }