<template>
    <div
        v-show="!options.hide"
        v-data-attr
        class="marketspace-conversation-menu"
        title="Chat Messages"
        @click="openConversation"
    >
        <svg class="svg-icon svg-20">
            <use xlink:href="#action-icons-ms-menu-messages" class="icon" />
        </svg>
        <Counter v-if="count" :count="count" />
    </div>
</template>
<script>

import '@/Bindings/ui-attribute.directive';
import Counter from '@/Components/Control/UnreadMessagesCounter/counter';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import {
	SubscribeWithResubscribe, KINDS, UnsubscribeAndProcessLocalStorageKey, LOCAL_STORAGE_FEED_KEYS
} from '@/Data/DataProviders/signalRDataProvider';
import { SubscribeOnlineOfflineConnection, NETWORKSTATE } from '@/Data/DataProviders/onlineStateDataProvider';
import {
	Search, getBaseRouteUrl, RECORD_STATE, getValueByString, isArray
} from '@acx-xms/data-functions/dist';
import { CHATPARTICIPANT_FIELDS } from '@/Data/selected-fields';
import { mapState } from 'vuex';

/* TODO: replace this variables in config file */
const MESSAGE_FIELDS = ['createdonfromui', 'createdby', 'chatmessageid', 'messagetext', 'chatid', 'contactid', 'contactname', 'messagetype', 'parentchatmessageid', 'visibleto',
	'chatmessagecreatedbysystemuser.systemuserid', 'chatmessagechatidchat.dealroomid', 'chatmessagechatidchat.collaborationroomid',
	// for control-chat.vue
	'chatmessagecontactidcontact.fullname', 'chatmessagecreatedbysystemuser.avatarid', 'chatmessagecreatedbysystemuser.fullname', 'ownerid', 'chatmessageparentchatmessageidchatmessage', 'nonregistered',
	'chatmessageparentchatmessageidchatmessage.chatmessageid', 'chatmessageparentchatmessageidchatmessage.messagetext', 'chatmessageparentchatmessageidchatmessage.createdonfromui',
	'chatmessageparentchatmessageidchatmessage.contactname'
];
const TYPES = {
	DR: {
		default: 'dealroom',
		normal: 'deal-rooms'
	},
	CR: {
		default: 'collaborationroom',
		normal: 'collaboration-rooms'
	}
};

const isActionByType = (type, text, value = null) => {
	const ACTION_TYPES = {
		closed: 'has closed this room',
		added: `has added ${value}`,
		entered: `${value} has entered the room.`,
		removed: `Has removed ${value} from this room.`
	};
	if (!Object.keys(ACTION_TYPES).includes(type)) return false;
	return ACTION_TYPES[type] === text;
};

const ROLES = {
	admin: 'Organization Admin',
	guest: 'Guest',
	broker: 'Broker'
};

export default {
	name: 'menu-conversation',
	props: { options: Object },
	components: { Counter },
	data() {
		return {
			participantsIDs: [],
			participantsData: [],
			messagesIDs: [],
			visibleToQuerys: ['All Users'],
			feeds: {
				chatparticipant: '',
				chatmessage: ''
			},
			user: null
		};
	},
	computed: {
		...mapState('counters', ['chatParticipant']),
		count() {
			return this.$store.getters['counters/getUnreadMessagesCount'];
		},
		currentParticipant() {
			return this.chatParticipant || null;
		},
		currentRoom() {
			return this.participantsData.find(el => el.collaborationroomid === this.$route.params.id || el.dealroomid === this.$route.params.id);
		},
		isOrgAdmin() {
			return this.user.CRMRoles.some(role => role.Name === ROLES.admin);
		},
		isGuest() {
			return this.user.CRMRoles.some(role => role.Name === ROLES.guest);
		}
	},
	async created() {
		this.user = await GetUserInfo();
		this.systemuserid = this.user.systemuserid;
		await this.subscribeOnNewMessages();

		const сonversationMenuInit = this.$store.getters['сonversationMenuInit'];
		/* TODO: write a better solution */
		this.$root.$on('resubscribe', this.resubscribe);
		this.fork = sc.events.fork();
		this.fork.on('messages-resubscribe', this.resubscribe);

		сonversationMenuInit.resolve();

		this.isOnline = true;

		SubscribeOnlineOfflineConnection('menu-conversation-panel', async (status) => {
			if (status !== NETWORKSTATE.ONLINE) return;

			await GetUserInfo(true, false);

			await this.subscribe();
		});
	},
	methods: {
		openConversation() {
			sc.events.emit('sidePanel.open', 'ms-conversations-panel', {
				evaluationContext: null,
				messageType: this.options.messageType
			});
		},
		buildFilters(data) {
			const filters = [];
			data.forEach(e => {
				filters.push(
					{
						$type: 'search.operand',
						filter: [
							{
								$type: 'search.termFacetFilter',
								negative: false,
								filterViewKind: 'Default',
								item: [
									{
										$type: 'search.termFacetItem',
										value: {
											$type: 'expression.text',
											value: e.chatid
										}
									}],
								logicalName: 'chatid.id'
							},
							{
								$type: 'search.dateFacetFilter',

								item: [
									{
										$type: 'search.dateFacetItem',
										min: {
											$type: 'expression.cdate',
											argument: {
												$type: 'expression.text',
												value: e.lastreadmessagedate
											}
										},
										fromIncluded: false
									}
								],
								logicalName: 'createdonfromui'
							}]
					});
			});
			return filters;
		},
		createSelectedFieldsFromArray(fields) {
			return fields.reduce((result, field) => {
				if (field.includes('.')) {
					const parts = field.split('.');
					if (result.hasOwnProperty(parts[0])) {
						result[parts[0]][parts[1]] = { IsSelected: true };
					} else {
						result[parts[0]] = { [parts[1]]: { IsSelected: true } };
					}
				} else {
					result[field] = { IsSelected: true };
				}
				return result;
			}, {});
		},
		getFiltersForNewMessages(data) {
			let filters = []; let filtersWithTermFacets = [];
			const logicalFilter = {
				$type: 'search.logicalFilter',
				operator: {
					$type: 'search.or',
					operator: this.buildFilters(data)
				}
			};

			const SelectedFields = { Fields: this.createSelectedFieldsFromArray(MESSAGE_FIELDS) };
			const resultsFormat = {
				Query: {
					SelectedFields,
					Size: 1,
					OrderBy: [{
						Field: 'createdonfromui',
						IsDescending: true
					}]
				}
			};
			const chatidTermfacetFilter = sc.classes.get('termFacet.filter', {
				logicalName: 'chatid.id',
				query: data.map(item => item.chatid)
			});
			const chatmessageTermfacetFilter2 = sc.classes.get('termFacet.filter', {
				logicalName: 'chatmessagechatidchat.recordstate.id',
				query: ['8d113fa8-3015-4060-a107-14cedcd19dd3']
			});
			const filledLogicalFilter = sc.classes.get('search.logicalFilter', logicalFilter);
			const chatidSelectedTermFacetFilter = sc.classes.get('selectedTermFacet.filter', 'chatid.id', data.length, false, resultsFormat, null, [chatidTermfacetFilter.mapData(), chatmessageTermfacetFilter2.mapData()]);
			const chatmessageSelectedTermFacetFilter2 = sc.classes.get('selectedTermFacet.filter', 'chatmessagechatidchat.chatid', data.length, false, null, filledLogicalFilter.toFilter().data, null);

			const commonFilters = [
				sc.classes.get('offsetFrom.filter', 0).fillQuery(),
				sc.classes.get('resultsFormat.filter', 'Source').fillQuery()
			];

			filtersWithTermFacets = [
				...commonFilters,
				...[
					sc.classes.get('offsetSize.filter', 0).fillQuery(),
					chatidSelectedTermFacetFilter.fillQuery(),
					chatmessageSelectedTermFacetFilter2.fillQuery()
				]
			];

			filters = [
				...commonFilters,
				...[
					chatidTermfacetFilter.fillQuery(),
					chatmessageTermfacetFilter2.fillQuery(),
					filledLogicalFilter.toFilter(),
					sc.classes.get('selectedFields.filter',
						MESSAGE_FIELDS.map(field => ({ logicalname: field }))
					).fillQuery()
				]
			];

			this.options.messageType !== 'All Users' && this.visibleToQuerys.push(this.options.messageType);
			filtersWithTermFacets.push(sc.classes.get('termFacet.filter', {
				logicalName: 'visibleto',
				query: this.visibleToQuerys
			}).fillQuery());

			return [filters, filtersWithTermFacets];
		},
		async subscribeOnNewMessages() {
			const crFilter = {
				$type: 'search.operand',
				filter: [
					{
						$type: 'search.termFacetFilter',
						negative: true,
						filterViewKind: 'Default',
						item: [
							{
								$type: 'search.termFacetItem',
								value: {
									$type: 'expression.text',
									value: null
								}
							}],
						logicalName: 'chatparticipantchatidchat.collaborationroomid.id'
					}
				]
			};
			const drOperators = [
				{
					$type: 'search.operand',
					filter: [
						{
							$type: 'search.termFacetFilter',
							negative: true,
							filterViewKind: 'Default',
							item: [
								{
									$type: 'search.termFacetItem',
									value: {
										$type: 'expression.text',
										value: null
									}
								}],
							logicalName: 'chatparticipantchatidchat.dealroomid.id'
						}
					]
				}
			];

			if (this.isGuest) {
				drOperators.push({
					$type: 'search.or',
					operator: [
						{
							$type: 'search.operand',
							filter: [
								{
									$type: 'search.termFacetFilter',
									negative: false,
									filterViewKind: 'Default',
									item: [
										{
											$type: 'search.termFacetItem',
											value: {
												$type: 'expression.text',
												value: false
											}
										}],
									logicalName: 'chatparticipantchatidchat.isroomwithnda'
								}
							]
						},
						{
							$type: 'search.and',
							operator: [
								{
									$type: 'search.operand',
									filter: [
										{
											$type: 'search.termFacetFilter',
											negative: true,
											filterViewKind: 'Default',
											item: [
												{
													$type: 'search.termFacetItem',
													value: {
														$type: 'expression.text',
														value: false
													}
												}],
											logicalName: 'chatparticipantchatidchat.isroomwithnda'
										},
										{
											$type: 'search.dateFacetFilter',
											item: [
												{
													$type: 'search.dateFacetItem',
													min: {
														$type: 'expression.cdate',
														argument: {
															$type: 'expression.text',
															value: '1969-12-31T21:00:00.000Z'
														}
													}
												}
											],
											logicalName: 'ndasignindate'
										}
									]
								}]
						},
						{
							$type: 'search.operand',
							filter: [
								{
									$type: 'search.termFacetFilter',
									negative: false,
									filterViewKind: 'Default',
									item: [
										{
											$type: 'search.termFacetItem',
											value: {
												$type: 'expression.text',
												value: this.systemuserid
											}
										}],
									logicalName: 'ownerid.id'
								}
							]
						}
					]
				});
			}

			const drFilter = {
				$type: 'search.and',
				operator: drOperators
			};
			const cRdRLogicalFilter = {
				$type: 'search.logicalFilter',
				operator: {
					$type: 'search.or',
					operator: [
						crFilter,
						drFilter
					]
				}
			};
			const participantsFilter = [
				sc.classes.get('offsetSize.filter', 9999),
				sc.classes.get('termFacet.filter', {
					logicalName: 'systemuserid.id',
					query: [this.user.systemuserid]
				}).fillQuery(),
				sc.classes.get('termFacet.filter', {
					logicalName: 'ispending',
					query: ['false']
				}).fillQuery(),
				sc.classes.get('search.logicalFilter', cRdRLogicalFilter).toFilter(),
				sc.classes.get('selectedFields.filter',
					CHATPARTICIPANT_FIELDS.map(field => ({ logicalname: field }))
				).fillQuery()
			];
			const participants = await Search(['chatparticipant'], participantsFilter);
			this.participantsIDs = participants.Results.map(cp => cp.Id);
			this.participantsData = participants.Results.map(cp => {
				const result = {
					chatid: cp.Source.chatid.id,
					lastreadmessagedate: cp.Source.lastreadmessagedate,
					collaborationroomid: cp.Source.chatparticipantchatidchat.collaborationroomid && cp.Source.chatparticipantchatidchat.collaborationroomid.id,
					dealroomid: cp.Source.chatparticipantchatidchat.dealroomid && cp.Source.chatparticipantchatidchat.dealroomid.id,
					ispending: cp.Source.ispending
				};
				if (result.dealroomid) {
					result.ndasignindate = cp.Source.ndasignindate;
					result.systemuserid = cp.Source.chatparticipantcontactidcontact.systemuserid.id;
				}
				return result;
			});
			(this.isOrgAdmin || this.isGuest) && this.$store.commit('setUserChatParticipants', this.participantsData);
			/* Get available chats */
			const getChatsForFilters = async () => {
				const chatFilters = [
					sc.classes.get('offsetFrom.filter', 0),
					sc.classes.get('offsetSize.filter', 999),
					sc.classes.get('search.relation', {
						entity: 'chatparticipant',
						alias: 'chatparticipants',
						output: 'item'
					}).toFilter(),
					sc.classes.get('termFacet.filter', {
						logicalName: 'chatparticipants.systemuserid.id',
						items: [this.user.Id]
					}),
					sc.classes.get('termFacet.filter', {
						logicalName: 'chatparticipants.recordstate.id',
						items: ['8d113fa8-3015-4060-a107-14cedcd19dd3']
					}),
					sc.classes.get('selectedFields.filter', [{}])
				];

				if (this.isGuest) {
					chatFilters.push(sc.classes.get('termFacet.filter', {
						logicalName: 'chatparticipants.ispending',
						items: ['false']
					}));
				}
				const chatResults = await Search(['chat'], chatFilters);
				const result = chatResults.Results.map(el => el.Id);
				return result;
			};
			const availableChats = await getChatsForFilters();
			/* check incoming message */
			const checkMessageByFilters = (message) => {
				const FILTERS = { 'chatid.id': availableChats };
				const check = Object.keys(FILTERS).map(filter => {
					return isArray(FILTERS[filter])
						? FILTERS[filter].includes(getValueByString(filter, message))
						: FILTERS[filter] === getValueByString(filter, message);
				});
				return !check.includes(false);
			};
			const onNewCP = async (response) => {
				if (!this.participantsIDs.includes(response.Id.PrimaryEntityId)) {
					this.participantsIDs.push(response.Id.PrimaryEntityId);
					const newData = response.Changes[0].Entity;
					if (!newData) return;

					this.participantsData.push({
						chatid: newData.chatid.id,
						lastreadmessagedate: newData.lastreadmessagedate,
						collaborationroomid: newData.chatparticipantchatidchat.collaborationroomid && newData.chatparticipantchatidchat.collaborationroomid.id,
						dealroomid: newData.chatparticipantchatidchat.dealroomid && newData.chatparticipantchatidchat.dealroomid.id,
						ispending: newData.ispending
					});
					await subscribeOnMessages(false);
				}

				if (response) {
					this.inactiveParticipantHandler(response);
					this.approveAccessHandler(response);
					this.closedRoomHandler(response);
				}
			};
			const processMessages = async (data) => {
				const termFacetsObj = {
					'chatid.id': {
						LogicalName: 'chatid.id',
						Missing: 0,
						Other: 0,
						Total: 0,
						Items: []
					},
					'chatmessagechatidchat.chatid': {
						LogicalName: 'chatmessagechatidchat.chatid',
						Missing: 0,
						Other: 0,
						Total: 0,
						Items: []
					}
				};
				data.TermFacets.reduce((acc, item) => {
					acc[item.LogicalName].Missing += item.Missing;
					acc[item.LogicalName].Other += item.Other;
					acc[item.LogicalName].Total += item.Total;
					item.Items.length && (acc[item.LogicalName].Items = acc[item.LogicalName].Items.concat(item.Items));
					return acc;
				}, termFacetsObj);

				const messages = [];
				termFacetsObj['chatid.id'].Items.forEach(item => {
					messages.push({
						message: item.Items[0],
						chatid: item.Items[0].chatid.id
					});
				});

				const filtredMessages = messages.filter(({ message: chatmessage }) => {
					const result = this.participantsData.find(participant => participant.chatid === chatmessage.chatid.id && !participant.ispending);
					return result && result.lastreadmessagedate !== chatmessage.createdonfromui && chatmessage.chatmessagecreatedbysystemuser.systemuserid !== this.systemuserid;
				});
				const counters = {};

				termFacetsObj['chatmessagechatidchat.chatid'].Items.forEach(item => {
					const message = filtredMessages.find(msg => msg.chatid === item.Term);
					if (message) {
						/* action message */
						const isAction = message.message.messagetype === 'Action';
						/* actions */
						const closed = isAction && isActionByType('closed', message.message.messagetext);
						const added = isAction && isActionByType('added', message.message.messagetext, this.user.fullname);
						const removed = isAction && isActionByType('removed', message.message.messagetext, this.user.fullname);
						/* check by actions */
						if (!closed && !added && !removed) {
							counters[message.chatid] = {
								counter: item.Count,
								chatlogicalname: (message.message.chatmessagechatidchat.dealroomid && 'dealroom') || (message.message.chatmessagechatidchat.collaborationroomid && 'collaborationroom')
							};
						}
					}
				});
				this.messagesIDs = messages.map(msg => msg.message.chatmessageid);

				this.$store.commit('counters/setCounters', counters);
				this.$store.commit('counters/setMessages', messages.map(msg => {
					return {
						chatid: msg.chatid,
						message: {
							Source: msg.message,
							Id: msg.message.chatmessageid
						}
					};
				}));
			};
			const subscribeOnMessages = async (searchOnInit = true) => {
				if (searchOnInit) {
					const newMessagesFiltersArray = []; const newMessageFiltersArrayWithTermFacet = [];
					const step = 1000;
					const numberOfChunks = Math.ceil(this.participantsData.length / step);
					const chunkSize = Math.floor(this.participantsData.length / numberOfChunks);
					for (let i = 0; i < numberOfChunks; i++) {
						let filters = []; let filtersWithTermFacets = [];
						if (i === numberOfChunks - 1) {
							// in case there is a remainder of the division - we need to get all messages from last chunk to the array length
							[filters, filtersWithTermFacets] = this.getFiltersForNewMessages(this.participantsData.slice(i * chunkSize, this.participantsData.length));
						} else {
							[filters, filtersWithTermFacets] = this.getFiltersForNewMessages(this.participantsData.slice(i * chunkSize, (i + 1) * chunkSize));
						}
						newMessagesFiltersArray.push(filters);
						newMessageFiltersArrayWithTermFacet.push(filtersWithTermFacets);
					}
					for (const [, newMessagesFilterChunk] of newMessageFiltersArrayWithTermFacet.entries()) {
						const results = await Search('chatmessage', newMessagesFilterChunk);
						processMessages(results);
					}
				}

				const newmsgfilters = [
					{
						requestLabel: 'SelectedFields',
						data: { Fields: this.createSelectedFieldsFromArray(MESSAGE_FIELDS) }
					},
					{
						requestLabel: 'ResultsFormat',
						data: 'Source'
					},
					{
						requestLabel: 'TermFacetFilters',
						data: [
							{
								logicalName: 'visibleto',
								negative: false,
								items: this.visibleToQuerys
							}
						]
					}
				];

				const chatMessageFeedId = await SubscribeWithResubscribe(['chatmessage'], newmsgfilters, onNewMessage, [KINDS.CREATED, KINDS.UPDATED], 30, LOCAL_STORAGE_FEED_KEYS.MESSAGE);
				if (this.feeds.chatmessage) {
					await UnsubscribeAndProcessLocalStorageKey('chatmessage.feedId', this.feeds.chatmessage);
					this.feeds.chatmessage = chatMessageFeedId;
				}
			};
			const onNewMessage = async (response) => {
				if (!this.messagesIDs.includes(response.Id.PrimaryEntityId)) {
					const newMessage = response.Changes[0].Entity;
					/* return if it fails the test */
					if (!checkMessageByFilters(newMessage)) return;
					const existingCp = this.participantsData.filter(participant => {
						if (newMessage && newMessage.chatid) {
							return participant && !participant.ispending && participant.chatid === newMessage.chatid.id;
						}
						return false;
					});
					if (!existingCp.length) return;

					this.messagesIDs.push(response.Id.PrimaryEntityId);
					const saveMessage = () => {
						const counter = {
							[newMessage.chatid.id]: {
								counter: 1,
								chatlogicalname: (newMessage.chatmessagechatidchat.dealroomid && 'dealroom') || (newMessage.chatmessagechatidchat.collaborationroomid && 'collaborationroom')
							}
						};
						/* action message */
						const isAction = newMessage.messagetype === 'Action';
						/* actions */
						const closed = isAction && isActionByType('closed', newMessage.messagetext);
						const added = isAction && isActionByType('added', newMessage.messagetext, this.user.fullname);
						const entered = isAction && isActionByType('entered', newMessage.messagetext, this.user.fullname);
						const removed = isAction && isActionByType('removed', newMessage.messagetext, this.user.fullname);
						/* check by actions */
						if (closed) {
							this.$store.commit('counters/removeCounter', counter);
						} else if (!added && !entered && !removed) {
							this.$store.commit('counters/addCounter', counter);
							this.$store.commit('counters/setMessages', [{
								chatid: newMessage.chatid.id,
								message: {
									Id: newMessage.chatmessageid,
									Source: newMessage
								}
							}]);
							this.$root.$emit(`newMessage.${newMessage.chatid.id}`, {
								Id: newMessage.chatmessageid,
								Source: newMessage
							});
						}
					};

					const isNotLastMessage = new Date(existingCp[0].lastreadmessagedate) < new Date(newMessage.createdonfromui); /* checking last readed message */
					const isNotCurrentUser = newMessage.chatmessagecreatedbysystemuser.systemuserid !== this.systemuserid; /* checking current user in message body */
					const isNotCurrentNonRegisteredUser = this.currentParticipant && this.currentParticipant.Source.nonregistered && (newMessage.contactname !== this.currentParticipant.Name); /* check on anonymous */
					const lastMessageInStore = this.$store.getters['counters/getMessageByChatId'](newMessage.chatid.id); /* get last message from store */
					const isNotTheSameMessage = lastMessageInStore ? newMessage.chatmessageid !== lastMessageInStore.message.Id : false; /* compare message id */
					const isCurrentMessageOlder = lastMessageInStore ? new Date(newMessage.createdonfromui) < new Date(lastMessageInStore.message.Source.createdonfromui) : false;
					if ((isNotLastMessage && isNotCurrentUser) || isNotCurrentNonRegisteredUser) {
						/* if it's not current user AND it's not current message OR it's not current anonymous */
						saveMessage();
					} else if (!isNotCurrentUser && isNotTheSameMessage && !isCurrentMessageOlder) {
						/* if it's current user, but NOT stored message & current message are newest */
						saveMessage();
					}
				}
			};
			const chatParticipantFeedId = await SubscribeWithResubscribe(['chatparticipant'], participantsFilter, onNewCP, [KINDS.CREATED, KINDS.UPDATED], 30, LOCAL_STORAGE_FEED_KEYS.PARTICIPANT);
			if (this.feeds.chatparticipant) {
				await UnsubscribeAndProcessLocalStorageKey('chatparticipant.feedId', this.feeds.chatparticipant);
				this.feeds.chatparticipant = chatParticipantFeedId;
			}
			await subscribeOnMessages();
		},
		inactiveParticipantHandler(response) {
			const participant = response.Changes[0].Entity;
			if (!participant) return;

			if (this.$route.params.id === participant.chatparticipantchatidchat[participant.chatparticipantchatidchat.collaborationroomid ? 'collaborationroomid' : 'dealroomid'].id &&
				this.systemuserid === participant.chatparticipantcontactidcontact.systemuserid.id &&
				participant.recordstate.id === '9e817a63-452c-e811-8320-7824af3b452f') {
				window.location = `${getBaseRouteUrl(this.$route)}/${participant.chatparticipantchatidchat.collaborationroomid ? 'collaboration-rooms' : 'deal-rooms'}`;
			}
		},
		closedRoomHandler(response) {
			const participant = response.Changes && response.Changes[0] && response.Changes[0].Entity;
			if (!participant) return;

			const isCurrentUser = this.systemuserid === participant.chatparticipantcontactidcontact.systemuserid.id;
			const isInactiveRoom = participant.chatparticipantchatidchat.recordstate.id === RECORD_STATE.INACTIVE;

			if (isCurrentUser && isInactiveRoom) {
				/* if it's not room owner */
				if (!participant.isroomowner) {
					/* get room type */
					const CURRENT_TYPE = Object.keys(TYPES).find(element => participant.chatparticipantchatidchat[`${TYPES[element].default}id`]);
					/* redirect from room */
					if (this.$route.params.id === participant.chatparticipantchatidchat[`${TYPES[CURRENT_TYPE].default}id`].id) {
						this.$router.replace({ path: `${getBaseRouteUrl(this.$route)}/${TYPES[CURRENT_TYPE].normal}` });
					} else {
						/* or refresh search */
						/* TODO: get namespace and action from config */
						this.$root.$emit(`mainSearch.${TYPES[CURRENT_TYPE].default}.searching`);
					}
				}
				/* remove chat message counter if it has */
				if (this.$store.getters['counters/getCounterByChatId'](participant.chatid.id)) {
					this.$store.commit('counters/removeAllCountersForChat', participant.chatid.id);
				}
				/* remove chat from list */
				const messages = this.$store.getters['counters/getAllMessages'];
				const currentChat = messages.findIndex(element => element.chatid === participant.chatid.id);
				if (currentChat > -1) {
					messages.splice(currentChat, 1);
					this.$store.commit('counters/setMessages', messages);
				}
				/* resubscribe with new available chats */
				this.resubscribe();
			}
		},
		async approveAccessHandler(response) {
			const res = response.Changes[0].Entity;
			if (!res) return;

			const recordstate = res.recordstate && res.recordstate.id;
			const participant = res.chatparticipantcontactidcontact && res.chatparticipantcontactidcontact.systemuserid && res.chatparticipantcontactidcontact.systemuserid.id;
			const chatId = res.chatid && res.chatid.id;
			const collaborationroomId = res.chatparticipantchatidchat && res.chatparticipantchatidchat.collaborationroomid && res.chatparticipantchatidchat.collaborationroomid.id;
			const dealroomid = res.chatparticipantchatidchat && res.chatparticipantchatidchat.dealroom && res.chatparticipantchatidchat.dealroom.id;

			if ((!res.chatmessageid && !res.messagetext && !res.messagetype) &&
					(participant === this.user.systemuserid) &&
					(this.currentRoom && this.currentRoom.chatid === chatId) &&
					(res.requestedaccess !== null && res.requestedaccess !== undefined && !res.requestedaccess) &&
					(recordstate === '8d113fa8-3015-4060-a107-14cedcd19dd3') &&
					(collaborationroomId === this.currentRoom.collaborationroomid || dealroomid === this.currentRoom.dealroomid)) {
				this.$root.$emit('updateChatParticipantHandler', res.requestedaccess);
			}
		},
		async subscribe() {
			try {
				await this.subscribeOnNewMessages();
			} catch {
				setTimeout(this.subscribeOnNewMessages, 5000);
			}
		},
		resubscribe() {
			this.subscribe();
		}
	}
}; </script>
<style src='./menu-conversation.less' scoped></style>
