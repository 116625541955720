<template>
	<!--tooltip needed-->
	<div class="formcontrol-panel" v-bind:class="css" v-if="visible">
		<div class="control-container"  v-for="(item, index) in options.control" :key="index">
			<control v-bind:name="item.$type" v-bind:contentProps="item" v-bind:evaluationContext="evaluationContext"></control>
		</div>
	</div>
</template>
<script>
export default {
	name: 'control-panel',
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: { Control: () => import('./../../Entity/control.vue') },
	data() {
		return {
			visible: this.options.visible !== void 0 ? this.evaluationContext.eval(this.options.visible) : true,
			css: ''
		};
	},
	created() {
		const classes = [this.evaluationContext.eval(this.options.cssClass)];
		if (this.options.nowrap) {
			classes.push('no-wrap');
		}
		this.css = classes.join(' ');
	}
};
</script>
<style src="./control-panel.less" scoped></style>
