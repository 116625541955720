import ExpressionCbool from '../boolean/cbool';

export default {
	parameters(options) {
		return [
			this.eval(options.argument)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument)
		];
	},
	evaluate(arg) {
		return !ExpressionCbool.evaluate(arg);
	}
};
