var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "searchResults-tableView" },
        [
          _c("ControlTableView", {
            class: _vm.cssClass || "",
            attrs: {
              options: _vm.component,
              stateNamespace: _vm.stateNamespace,
              evaluationContext: _vm.evaluationContext,
              initResults: _vm.results,
              paginationParams: _vm.paginationParams
            }
          }),
          _vm._v(" "),
          _vm.paginationParams
            ? _c("Pagination", {
                attrs: {
                  pageSize: _vm.paginationParams.pageSize,
                  total: _vm.paginationParams.total,
                  from: _vm.paginationParams.from,
                  showPaging: _vm.paginationParams.showPaging,
                  activePages: _vm.paginationParams.activePages
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }