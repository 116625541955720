/* eslint no-useless-catch: 0 */
import axios from 'axios';
import authAxios from '@/Data/auth-axios';
import Store from '@/States/ms-state';
import * as tracking from '@/Data/trackingModule';
import modulesLauncher from '@/Data/StartUpModules/modulesLauncher';
import router from '@/router';
import { Deferred, Search } from '@acx-xms/data-functions/dist';
import { LICENSE_FIELDS } from '@/Data/selected-fields';
import { setFakeUserToExpressions } from './fakeExpressionsController';

let currentUser = null;
let refreshPromise = null;
const availableLayouts = {};
const availableConfigurations = {};
let validLicensesPromise = null;
let validLicenses = null;

function getTokenKey(clusterKey) {
	if (window.location.href.includes('/accessbylink/')) {
		// TODO: replace id with router.default.currentRoute.params.id and remove unnecessary code
		const temp = window.location.href.split('/');
		const tempIndex = temp.findIndex(t => t === 'accessbylink');
		const id = temp[tempIndex + 2].split('?')[0];
		return `${clusterKey.toLocaleLowerCase()}_accessbylink_${id}_token`;
	}
	return `${clusterKey.toLocaleLowerCase()}_token`;
}

function handleAuthResults(response) {
	const authResults = response.data;

	if (authResults.success) {
		sc.cluster.key = authResults.clusterKey;
		localStorage.setItem(getTokenKey(authResults.clusterKey), authResults.token);
		if (!window.location.href.includes('/accessbylink/')) { // to avoid redirecting from public layout
			// if there were specific url before SSO redirect
			try {
				const url = sessionStorage.getItem('redirectUrl');
				sessionStorage.removeItem('redirectUrl');
				if (url) {
					window.location = url;
				}
			} catch (e) {
				let errorMessage = e.message;
				if (errorMessage.includes('localStorage')) {
					errorMessage = 'Please allow all cookies in your browser to continue and try again';
				}
				Store.commit('changeErrorMessage', errorMessage);
			}
		}
		return true;
	} else {
		let error = authResults.message;
		if (error.indexOf('Systemuser with specified domainname is disabled. Please activate user account or contact your System Administrator') !== -1) {
			const authInfo = localStorage.getItem('authInfo');
			error = (authInfo && authInfo.DisabledUserErrorMessage) || error;
		}

		throw new Error(error || 'sid validation failed');
	}
}
async function checkUserAcceptedTerms() {
	if (currentUser.acceptedterms === null) {
		router.push({
			name: 'acceptTermsOfUseAndPrivacyPolicy',
			params: { user: currentUser }
		});
	} else {
		const environmentSettings = await sc.classes.get('settings.dataProvider').getEnvironmentSettings(sc.cluster.key);
		const compareAcceptedTermsData = (date) => {
			return new Date(currentUser.acceptedterms).getTime() < new Date(date).getTime();
		};
		if (compareAcceptedTermsData(environmentSettings.privacyPolicyDate) || compareAcceptedTermsData(environmentSettings.termsOfUseDate)) {
			router.push({
				name: 'acceptTermsOfUseAndPrivacyPolicy',
				params: { user: currentUser }
			});
		}
	}
}

// ping EDGE every 5 minutes to refresh EDGE token
setInterval(() => GetUserInfo(true, false), (300 * 1000));

const OnAvatarUpdate = (record) => {
	currentUser.avatarid = record;
};

const
	Authenticate = async (request) => {
		const authResultsResp = await axios.post('/api/Authentication', request);
		const result = handleAuthResults(authResultsResp);

		return result;
	};
const FinishExternalAuthentication = async (sid) => {
	const authInfo = localStorage.getItem('authInfo');
	const authResultsResp = await axios.get(`/api/Authentication/external/${sid}/${authInfo.ClusterKey}/${authInfo.OneTimeToken}`);

	return handleAuthResults(authResultsResp);
};
const IsAuthorized = async (orgId) => {
	sc.cluster.key = orgId;
	const token = localStorage.getItem(getTokenKey(orgId));
	if (token) {
		return Promise.resolve({ result: true });
	} else {
		try {
			if (window.sessionStorage.getItem('redirect_url')) window.sessionStorage.removeItem('redirect_url');

			const authInfoResp = await axios.get(`/api/Authentication/authinfo/${orgId}`);
			localStorage.setItem('authInfo', authInfoResp.data);

			if (authInfoResp.data.RedirectUrl) {
				window.location = authInfoResp.data.RedirectUrl;
				return;
			} else {
				throw new Error('No external auth url found for this organization');
			}
		} catch (e) {
			if (parseInt(e.response.status) === 503 && (e.response.data && e.response.data.Code)) {
				window.sessionStorage.setItem('redirect_url', window.location.href);
				return {
					result: false,
					redirect: 'maintenance',
					error: e.response.data.Detail.Operation.toLowerCase() === 'restore' ? 'true' : 'false',
					redirect_url: window.sessionStorage.getItem('redirect_url')
				};
			} else {
				// todo : show error screen
				throw new Error(e && e.response ? e.response.data : e.message);
			}
		}
	}
};
const RefreshToken = async () => {
	if (refreshPromise) {
		return refreshPromise;
	}

	const key = getTokenKey(sc.cluster.key);
	try {
		const promise = authAxios.put('/api/Authentication');
		refreshPromise = promise;
		Store.commit('changeInitMessage', 'Refreshing authentication');
		const refreshRes = await promise;
		if (refreshRes.data.success) {
			localStorage.setItem(key, refreshRes.data.token);

			tracking.AddUserActivity('token.refresh', 'Token refreshed');

			refreshPromise = null;
			return promise;
		} else {
			Store.commit('changeInitMessage', refreshRes.data.message);
			throw new Error(refreshRes.message);
		}
	} catch (e) {
		console.log(e);
		localStorage.removeItem(key);
		window.location.reload();
	}
};
const GetUserInfo = async (force, trackActivity = true) => {
	if (!sc.cluster.key) return;
	if (force || !currentUser) {
		try {
			const userResp = await authAxios.get('/api/Authentication');
			currentUser = userResp && userResp.data;

			trackActivity && tracking.AddUserActivity('login.successful', 'Successful login', {
				id: currentUser.Id,
				logicalname: 'systemuser'
			});
			// as we call this metod in router - sc.classes is not fully initialized yet
			// sc.classes.get('authentication')._currentUser = currentUser; //TODO: rework. Workaround for ACX Space. expr:hasCRMRole is not working in routeMenuItemSVG
			setFakeUserToExpressions(currentUser); // TODO: workaround for evalAsyncFake
			const isAnonymousOrNonRegistered = currentUser.CRMRoles.some(role => role.Name === 'Anonymous User' || role.Name === 'Non-Registered Participant');
			if (!force && !isAnonymousOrNonRegistered && sc.cluster.features && sc.cluster.features.termsofuseandprivacypolicy) {
				// we dont need to check every time on force get of user info
				await checkUserAcceptedTerms();
			}
			return currentUser;
		} catch (e) {
			// log error if needed
			throw e;
		}
	} else {
		return currentUser;
	}
};

const LaunchModules = async () => {
	// Before launch modules we have to ensure that token is valid
	await GetUserInfo();
	return await modulesLauncher();
};
const StoreUrl = () => {
	try {
		const currentUrl = sessionStorage.getItem('redirectUrl');
		if (!currentUrl) {
			sessionStorage.setItem('redirectUrl', window.location.href);
		}
	} catch (e) {
		let errorMessage = e.message;
		if (errorMessage.includes('localStorage')) {
			errorMessage = 'Please allow all cookies in your browser to continue and try again';
		}
		Store.commit('changeErrorMessage', errorMessage);
	}
};
const GetToken = (clusterKey) => {
	try {
		const key = getTokenKey(clusterKey);
		return localStorage.getItem(key);
	} catch (e) {
		throw new Error(e);
	}
};
const SetToken = (clusterKey, value) => {
	try {
		const key = getTokenKey(clusterKey);
		return localStorage.setItem(key, value);
	} catch (e) {
		throw new Error(e);
	}
};
const GetAvailableLayouts = async (config) => {
	if (!(availableLayouts[config] && Object.keys(availableLayouts[config]).length)) {
		Store.commit('changeInitMessage', 'Loading available configurations');
		const response = await authAxios.get(`/api/layouts/${config}`);
		Store.commit('changeInitMessage', null);
		availableLayouts[config] = response.data;
	}
	return availableLayouts[config];
};
const GetAvailableConfigurations = async (withHiddenConfigs = false) => {
	const key = withHiddenConfigs ? 'hidden' : 'visible';
	const url = withHiddenConfigs ? '/api/Authentication/Configurations?withHiddenConfigs' : '/api/Authentication/Configurations';
	if (!(availableConfigurations[key] && availableConfigurations[key].length)) {
		Store.commit('changeInitMessage', 'Loading available configurations');
		const configurationsResp = await authAxios.get(url);
		availableConfigurations[key] = configurationsResp.data;
		Store.commit('changeInitMessage', 'Loading available configurations');
	}
	return availableConfigurations[key];
};
const GetValidLicenses = async () => {
	if (validLicenses) {
		return validLicenses;
	}
	if (validLicensesPromise) {
		return validLicensesPromise;
	}
	const def = Deferred();
	const userInfo = await GetUserInfo();
	// TODO: add selected fields
	validLicensesPromise = def.promise;
	Search(['license'], [
		sc.classes.get('offsetSize.filter', 0),
		sc.classes.get('offsetSize.filter', 10),
		sc.classes.get('termFacet.filter', {
			logicalName: 'systemuserid.id',
			items: [userInfo.Id]
		}),
		sc.classes.get('dateTimeFacet.filter', {
			logicalName: 'validto',
			items: [{
				isChecked: true,
				Range: { From: new Date() }
			}]
		}),
		sc.classes.get('orderBy.filter', {
			query: [{
				logicalName: 'validto',
				value: false
			}]
		}),
		sc.classes.get('selectedFields.filter', LICENSE_FIELDS.map(field => ({ logicalname: field }))).fillQuery()
	]).then(results => {
		validLicenses = results;
		def.resolve(results);
	});
	return validLicensesPromise;
};
const GetMaintenanceStatus = async () => {
	const response = await authAxios.get('/api/maintenance');
	return response.data;
};
const Ping = async () => {
	return await authAxios.get('/api/Authentication/Ping');
};

export {
	Ping,
	Authenticate,
	FinishExternalAuthentication,
	IsAuthorized,
	RefreshToken,
	GetUserInfo,
	LaunchModules,
	StoreUrl,
	GetToken,
	SetToken,
	GetAvailableLayouts,
	GetAvailableConfigurations,
	GetValidLicenses,
	GetMaintenanceStatus,
	OnAvatarUpdate
};
