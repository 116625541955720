<template>
	<div class="iframe-wrapper" v-if="iframeUrl && !isLoading && visible">
		<iframe :src="iframeUrl" scrolling="auto" frameborder="0" ref="iframe"
				allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true" :style="{ width: options.width || '100%', height: options.height || '660px'}"></iframe>
	</div>
</template>
<script>
/* eslint no-useless-escape: 0 */
export default {
	name: 'control-iframe',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			fullscreen: false,
			iframeUrl: null,
			isLoading: false,
			visible: this.options.visible !== void 0 ? this.evaluationContext.eval(this.options.visible) : true
		};
	},
	async created() {
		this.fork = sc.events.fork();
		this.fork.on('fullscreen.open', this.openFullscreen);
		this.isLoading = true;
		this.iframeUrl = await this.processUrl(this.evaluationContext.eval(this.options.iframeUrl));
		this.isLoading = false;
	},
	methods: {
		openFullscreen() {
			// check if fullscreen mode is available
			if (document.fullscreenEnabled ||
					document.webkitFullscreenEnabled ||
					document.mozFullScreenEnabled ||
					document.msFullscreenEnabled) {
				// which element will be fullscreen
				const iframe = this.$refs.iframe;
				// Do fullscreen
				if (iframe.requestFullscreen) {
					iframe.requestFullscreen();
				} else if (iframe.webkitRequestFullscreen) {
					iframe.webkitRequestFullscreen();
				} else if (iframe.mozRequestFullScreen) {
					iframe.mozRequestFullScreen();
				} else if (iframe.msRequestFullscreen) {
					iframe.msRequestFullscreen();
				}
			}
		},
		getYouTubeLink(url) {
			const regex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
			const match = url.match(regex);
			if (match && match[2].length === 11) {
				const videoId = match[2];
				return 'https://www.youtube.com/embed/' + videoId;
			} else {
				return null;
			}
		},
		getVimeoLink(url) {
			const regex = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;
			const match = url.match(regex);
			if (match && match[1]) {
				if (url.includes('player')) {
					return 'https://' + match[0];
				} else {
					return 'https://player.vimeo.com/video/' + match[1];
				}
			} else {
				return null;
			}
		},
		getGoogleDriveLink(url) {
			const regex = /(drive.google.com\/file\/d\/)([^#\&\?]*)(\/\bpreview\b|\/view)/i;
			const match = url.match(regex);
			if (match && match[2].length === 33) {
				const preview = match[3] === '/preview' ? match[3] : '/preview';
				return 'https://' + match[1] + match[2] + preview;
			} else {
				return null;
			}
		},
		getLink(url) {
			return this.getYouTubeLink(url) || this.getVimeoLink(url) || this.getGoogleDriveLink(url) || url;
		},
		async processUrl(url) {
			if (this.options.processDocumentUrl) {
				const filename = (this.evaluationContext.eval({
					$type: 'expression.field',
					name: 'filename',
					schema: 'source'
				}) || '').toLowerCase();
				// TODO: Remove if not needed
				if (filename.endsWith('.doc') || filename.endsWith('.docx')) {
					return `https://view.officeapps.live.com/op/embed.aspx?src=${window.location.origin}${url}`;
				}
			}
			return this.getLink(url);
		}
	},
	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
		}
	}
};
</script>
<style scoped>
	.iframe-wrapper {
		height: inherit;
	}
</style>
