<template>
	<!-- tooltip-->
	<div class="scrollable-panel">
		<div class="no-data-message" v-if="results.length == 0">{{noDataMessage}}</div>
		<div class="content-scroll-wrapper" v-if="results.length > 0" v-perfectscroll="{enable: true, addMouseoverEvent: true}" v-bind:class="'columns-' + options.columns" style="display: flex; padding-bottom: 5px;">
			<entitytemplate-template :options="options.template"
									 :evaluationContext="getContext(item)"
									 v-for="item in results" :key="item.Id">
			</entitytemplate-template>
		</div>
	</div>
</template>
<script>
import EntitytemplateTemplate from './../EntityTemplate/entitytemplate-template.vue';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'control-scrollablepanel',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			results: this.evaluationContext.eval(this.options.dataSource),
			noDataMessage: this.evaluationContext.eval(this.options.noDataMessage)
		};
	},
	components: { EntitytemplateTemplate },
	methods: {
		getContext(item) {
			// to remove vue reactivity
			return createEvaluationContext(item);
		}
	}
};
</script>
<style>
	.scrollable-panel .component-search-results-details .rows .row .column .column-content > div {
		height: auto;
	}
</style>
