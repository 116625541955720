/* eslint prefer-promise-reject-errors: 0 */
import { GetPerConfig } from '@/Data/DataProviders/userStorageDataProvider';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import { isArray, SendRequest } from '@acx-xms/data-functions/dist';

const requestPath = '/api/plugins/endpoint/mci';

function sendRequest(request) {
	let { type, url, body = null, query = {}, returnWithStatusCode } = request;
	url = requestPath + '/' + url;
	return SendRequest(url, type, body, null, query, null, returnWithStatusCode);
}
async function getToken() {
	return GetPerConfig('co-auth-key');
}

// AUTH
// 1. /api/auth/oauth/mailchimp/get-url finalRedirect parameter should be any url to close window after user is authorized in composer online
// 2. handle 401 error from mail chimp on our side, unusual case (if 401 then go to #1) not needed to open auth dialog every time, authentication can be checked by /api/auth
export async function getMCIUrl() {
	return sendRequest({
		type: 'get',
		url: 'geturl'
	});
}
export async function getMCIUrlSwagger() {
	const url = await sendRequest({
		type: 'get',
		url: 'geturl'
	});
	return `${url}swagger/`;
}
export async function getAuthUrl(redirectUrl) {
	return sendRequest({
		type: 'get',
		url: 'proxy',
		query: {
			authorization: await getToken(),
			q: 'api/auth/oauth/mailchimp/get-url?finalRedirect=' + redirectUrl
		}
	});
}
export async function authenticate() {
	return sendRequest({
		type: 'get',
		url: 'authmci',
		query: { authorization: await getToken() }
	});
};
export async function authenticateRedirect(redirectUrl, newWindow) {
	const userinfo = await GetUserInfo();
	const response = await sendRequest({
		type: 'post',
		url: 'authmciredirect',
		body: {
			redirectUrl: redirectUrl + '/' + userinfo.Id,
			authorization: await getToken()
		}
	});
	newWindow.location.href = response;
	if (newWindow) {
		newWindow.focus();
	}
};

export async function getMCIUserInfo() {
	return sendRequest({
		type: 'get',
		url: 'proxy',
		query: {
			q: 'api/auth',
			authorization: await getToken()
		}
	});
};

export async function deleteMCIUser() {
	return sendRequest({
		type: 'delete',
		url: 'proxy',
		query: {
			authorization: await getToken(),
			q: 'api/auth'
		}
	});
};
// Default List
export async function getDefaultList(count = 9999, offset = 0) {
	return sendRequest({
		type: 'get',
		url: 'proxy',
		query: {
			q: `api/default-list/tags?count=${count}&offset=${offset}`,
			authorization: await getToken()
		}
	});
};
export async function createDefaultList(data) {
	return sendRequest({
		type: 'post',
		url: 'proxy',
		body: {
			// change defaults if needed
			name: 'string',
			permissionReminder: 'You signed up for updates on our website',
			contact: {
				zip: 'string',
				state: 'string',
				country: 'string',
				company: 'string',
				city: 'string',
				address1: 'string'
			},
			campaignDefaults: {
				subject: 'Subject',
				language: 'English',
				from_name: (data && data.from_name) || 'Sender',
				from_email: (data && data.from_email) || 'string'
			}
		},
		query: {
			q: 'api/default-list',
			authorization: await getToken()
		}
	});
};
export async function addMembersToList(members, tags) {
	!isArray(members) && (members = [members]);
	!isArray(tags) && (tags = [tags]);

	return sendRequest({
		type: 'post',
		url: 'proxy',
		body: {
			members,
			tags
		},
		query: {
			q: 'api/default-list/members',
			authorization: await getToken()
		}
	});
};
export async function getMembersStatus(operationId) {
	return new Promise((resolve, reject) => {
		const exec = async () => {
			const result = await sendRequest({
				type: 'get',
				url: 'proxy',
				query: {
					q: `api/default-list/members/status/${operationId}`,
					authorization: await getToken()
				}
			});
			switch (result.status) {
			case 'finished': {
				resolve(result);
				break;
			}
			case 'failed': {
				reject('Get status failed');
				break;
			}
			default:
				setTimeout(exec, 2000);
				break;
			}
		};
		exec();
	});
};

// Campaigns
export async function createCampaign(data) {
	return sendRequest({
		type: 'post',
		url: 'proxy',
		body: data,
		query: {
			q: 'api/campaigns',
			authorization: await getToken()
		}
	});
};
export async function getCampaigns() {
	return sendRequest({
		type: 'get',
		url: 'proxy',
		query: {
			q: 'api/campaigns',
			authorization: await getToken()
		}
	});
}
export async function getCampaign(id) {
	return sendRequest({
		type: 'get',
		url: 'proxy',
		query: {
			q: `api/campaigns/${id}`,
			authorization: await getToken()
		}
	});
};
export async function startCampaign(id) {
	return sendRequest({
		type: 'post',
		url: 'proxy',
		query: {
			q: `api/campaigns/${id}/actions/send`,
			authorization: await getToken()
		}
	});
};
export async function deleteCampaign(id) {
	return sendRequest({
		type: 'post',
		url: 'proxy',
		query: {
			q: `api/campaigns/${id}/actions/delete`,
			authorization: await getToken()
		}
	});
};
export async function sendTestCampaign(id, data) {
	return sendRequest({
		type: 'post',
		url: 'proxy',
		body: data,
		query: {
			q: `api/campaigns/${id}/actions/test`,
			authorization: await getToken()
		},
		returnWithStatusCode: true
	});
};
export async function getDomains(id) {
	return sendRequest({
		type: 'get',
		url: 'proxy',
		query: {
			q: `api/domains`,
			authorization: await getToken()
		}
	});
};