export default {
	parameters(options) {
		return [
			this.entity
		];
	},
	evaluate(ref) {
		return ref;
	}
};
