<template>
	<div class="total-counter-wrapper">
		<div class="total-counter" v-if="!isLoading">
			<div class="number">
				<svg class='svg-icon svg-40' :style="{fill:color}">
					<use :xlink:href="icon"></use>
				</svg>
				<span>{{count}}</span>
			</div>
			<p>{{label}}</p>
		</div>
		<div class="loading-overlay" v-show="isLoading"></div>
	</div>
</template>
<script>
export default {
	name: 'total-counter',
	props: {
		color: String,
		entity: String,
		label: String,
		filters: Array,
		customIcon: String
	},
	data() {
		return {
			isLoading: false,
			count: ''
		};
	},
	async created() {
		this.isLoading = true;
		const size = sc.classes.get('offsetSize.filter', 0);
		const selectedFieldsFilter = sc.classes.get('selectedFields.filter', [{ logicalname: 'createdon' }]);
		const resp = await sc.classes.get('edge.dataProvider').search({
			filters: [size, selectedFieldsFilter].concat(this.filters || []),
			entities: [this.entity]
		});
		this.count = resp.Total.toLocaleString();
		this.isLoading = false;
	},
	computed: {
		icon() {
			return this.customIcon ? this.customIcon : '#entity-icons-' + this.entity;
		}
	}
};
</script>
<style src="./total-counter.less" scoped></style>
