import { isActive } from './action.methods';
import { showToastOrInfoDialog, Update } from '@acx-xms/data-functions/dist';

export default {
	async enabled(options, evaluationContext, selection) {
		return selection && selection.length > 0 && await isActive(evaluationContext, selection);
	},
	async execute(options, context, selection) {
		const refreshEvent = context.eval(options.refreshEvent);
		const refreshEvents = options.refreshEvents && options.refreshEvents.map(context.eval);
		const actionMenuRefreshEvent = context.eval(options.actionMenuRefreshEvent);
		const loadingEvent = context.eval(options.loadingEvent);
		const toastMessageKey = context.eval(options.toastMessageKey);
		const emitLoading = (isLoading) => {
			loadingEvent && sc.events.emit(loadingEvent, isLoading);
		};
		emitLoading(true);
		const updateParameters = {};
		options.updateData.forEach((data) => {
			updateParameters[context.eval(data.field)] = context.eval(data.value);
		});

		const entityReference = options.entityReference ? context.eval(options.entityReference) : sc.classes.get('entityReference', selection[0]);
		const updatedRecord = await Update(entityReference.logicalname, entityReference.id, updateParameters, null, { syncProcessMode: 'All' });
		emitLoading(false);
		setTimeout(async () => {
			refreshEvent && sc.events.emit(refreshEvent);
			refreshEvents && refreshEvents.forEach(event => sc.events.emit(event));
			actionMenuRefreshEvent && sc.events.emit(actionMenuRefreshEvent, {
				Id: updatedRecord.Id,
				Name: updatedRecord.name,
				Source: updatedRecord,
				Type: updatedRecord.type
			});
			// to disable/enable systemusers
			if (toastMessageKey) {
				sc.events.emit('toast.open', await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage(toastMessageKey, [1]), 3000);
			} else {
				await showToastOrInfoDialog({ informationDialogTextKey: context.eval(options.informationDialogTextKey) });
			}
		}, 500);
	}
};
