import {
	partition, generateGuid, showToastOrInfoDialog, Get, Search, CreateBulk, Update
} from '@acx-xms/data-functions/dist';

export default {
	async enabled(options, evaluationContext, selection) {
		return true;
	},
	async execute(options, context, selection) {
		const refreshEvent = context.eval(options.refreshEvent);
		const localizations = sc.classes.get('localization.dataProvider');
		const entityRef = sc.classes.get('entityReference', selection[0]);
		const record = await Get(entityRef.logicalname, entityRef.id);
		const edgeEntityName = record.Source.edgeentity.charAt(0).toUpperCase() + record.Source.edgeentity.slice(1);
		const toastMessageKey = context.eval(options.toastMessageKey);
		const informationDialogTextKey = context.eval(options.informationDialogTextKey);
		sc.events.emit('vue.dialog.open', {
			icon: 'action-icons-search-save',
			title: await localizations.getLabelForCurrentLanguage('common.dictionary.confirmDlgCaption'),
			component: 'confirm.dialog',
			maximizedWidth: '450px',
			maximizedHeight: 'auto',
			onSubmit: () => resetMappings(record, edgeEntityName, refreshEvent, toastMessageKey, informationDialogTextKey),
			message: await localizations.getLabelForCurrentLanguage('common.dictionary.resetMappings', [edgeEntityName]),
			okLabel: await localizations.getLabelForCurrentLanguage('common.dictionary.buttons.reset')
		});
	}
};
async function resetMappings(mappingRecord, edgeEntityName, refreshEvent, toastMessageKey, informationDialogTextKey) {
	if (mappingRecord.Source.isresetting) {
		return openDialogue('common.dictionary.mappingAlreadyResetting', edgeEntityName);
	}

	const updatedRecord = await Update(mappingRecord.Type, mappingRecord.Id, { isresetting: true });
	// in case update returns 204, which means someone is already resetting mapping
	if (!updatedRecord) {
		return openDialogue('common.dictionary.mappingAlreadyResetting', edgeEntityName);
	}
	try {
		let defaultMappings = JSON.parse(mappingRecord.Source.defaultmapping);
		if (!Array.isArray(defaultMappings)) {
			return openDialogue('common.dictionary.defaultMappingsAreMissing', edgeEntityName);
		}
		const mappingPairs = await Search(['mappingpair'], [
			sc.classes.get('offsetFrom.filter', 0).fillQuery(),
			sc.classes.get('offsetSize.filter', 9999).fillQuery(),
			sc.classes.get('termFacet.filter', {
				logicalName: 'mappingid.id',
				query: [mappingRecord.Id]
			}).fillQuery(),
			sc.classes.get('selectedFields.filter', [
				{ logicalname: 'crmFields' }
			]).fillQuery()
		]);
		const records = [];

		const mappingPairsIds = mappingPairs.Results.map(mp => mp.Id);
		let mappingRules = await Search(['mappingrule'], [
			sc.classes.get('offsetFrom.filter', 0).fillQuery(),
			sc.classes.get('offsetSize.filter', 9999).fillQuery(),
			sc.classes.get('termFacet.filter', {
				logicalName: 'mappingpairid.id',
				query: mappingPairsIds
			}).fillQuery(),
			sc.classes.get('selectedFields.filter', [
				{ logicalname: 'mappingpairid.id' }
			]).fillQuery()
		]);
		mappingRules = mappingRules.Results.map(mr => mr.Source.mappingpairid.id);
		// get unique values
		mappingRules = Array.from(new Set(mappingRules));
		let [mappingPairsForDelete, excludeFromCreateMappingPairs] = partition(mappingPairs.Results, e => !mappingRules.includes(e.Id));
		mappingPairsForDelete.forEach(mp => {
			const record = {
				id: generateGuid(),
				method: 'Delete',
				url: `mappingpair/${mp.Id}`,
				body: {}
			};
			records.push(record);
		});
		excludeFromCreateMappingPairs = excludeFromCreateMappingPairs.map(mp => mp.Source.crmFields);
		defaultMappings = defaultMappings.filter(dm => !excludeFromCreateMappingPairs.includes(dm.crmFields));
		defaultMappings.forEach(mapping => {
			const record = {
				id: generateGuid(),
				method: 'POST',
				url: 'mappingpair',
				body: {
					type: 'mappingpair',
					name: mapping.crmFields + mapping.edgeField,
					edgeField: mapping.edgeField,
					crmFields: mapping.crmFields,
					mappingid: {
						id: mappingRecord.Id,
						logicalname: mappingRecord.Type
					},
					recordstate: {
						id: '8d113fa8-3015-4060-a107-14cedcd19dd3',
						logicalname: 'lookuprecordstate'
					}
				}
			};

			records.push(record);
		});
		if (records.length) {
			await CreateBulk(records, { async: true });
		}
		if (toastMessageKey || informationDialogTextKey) {
			await showToastOrInfoDialog({
				toastMessageKey,
				informationDialogTextKey
			});
		} else {
			sc.events.emit('toast.open', await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('common.dictionary.mappingAreReset', [edgeEntityName]));
		}
	} catch (e) {
		sc.utils.errorMessage.byResponse(e);
	} finally {
		// we need this in finally because if something goes wrong - mapping record shouldn't be locked for resetting
		await Update(mappingRecord.Type, mappingRecord.Id, { isresetting: false });
		refreshEvent && sc.events.emit(refreshEvent);
	}
}

async function openDialogue(localizationKey, edgeEntityName) {
	sc.events.emit('dialog.open', {
		title: 'Warning',
		component: 'dialog.info',
		params: {
			message: await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage(localizationKey, [edgeEntityName]),
			buttons: [
				{ title: 'OK' }
			]
		}
	});
}
