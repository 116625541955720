/// <summary>
/// If you want to programmatically detect an authentication failure (for example to automatically send an beacon) you can prepare a callback function.
/// If the following global function is defined it will be called when the authentication fails.
/// </summary>
export class ScMapGoogleAuthInfo {
	constructor() {
		if (ScMapGoogleAuthInfo.instance) {
			return ScMapGoogleAuthInfo.instance;
		}

		this.isAuthenticated = true;
		window.gm_authFailure = this.authFailure;
		ScMapGoogleAuthInfo.instance = this;
	}

	authFailure() {
		this.isAuthenticated = false;
	}
}
