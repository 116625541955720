import { GetUserInfo } from '@/Data/Auth/authentication-service';
import { userFake } from '@/Data/Auth/fakeExpressionsController';

let userInfo;
export default {
	parameters(options) {
		// todo: think of proper error handling
		console.error('isRecordOwner must be awaited');
	},
	async parametersAsync(options) {
		if (!userInfo) {
			userInfo = GetUserInfo();
		}
		const user = await userInfo;
		return [
			options.record ? this.eval(options.record) : this.datasource,
			user.Id
		];
	},
	parametersAsyncFake(options) {
		return [
			userFake.record ? this.eval(options.record) : this.datasource,
			userFake.Id
		];
	},
	evaluate(record, userId) {
		const owner = sc.utils.findProperty(record, 'detail.ownerid', true) || sc.utils.findProperty(record, 'source.ownerid', true);
		return owner ? owner.id === userId : true;
	}
};
