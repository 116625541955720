let defaultSom;
export default {
	parameters(options) {
		console.error('defaultSom must be awaited');
	},
	async parametersAsync(options) {
		if (!defaultSom) {
			defaultSom = sc.classes.get('uom.dataProvider').getStorageDefaultSystemOfMeasure();
		}
		return [
			options.isEntityRef,
			await defaultSom
		];
	},
	evaluate(isref, som) {
		if (isref && som) {
			return sc.classes.get('entityReference', {
				id: som.awx_somid,
				logicalname: 'awx_som',
				name: som.awx_name
			});
		} else {
			return som;
		}
	}
};
