export default {
	async enabled(options, evaluationContext, selection) {
		if (selection && selection.length === 1) {
			return true;
		}
		return false;
	},
	async execute(options, context, selection) {
		sc.events.emit('vue.dialog.open', {
			title: await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('convertInquiryDialog.title'),
			component: 'convert.inquiry.dialog',
			maximizedHeight: '100%',
			maximizedWidth: '80%',
			evaluationContext: context,
			params: {
				entities: selection,
				refreshEvent: context.eval(options.refreshEvent),
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey)
			}
		});
	}
};
