export default {
	parameters(options) {
		return [
			this.eval(options.argument),
			this.eval(options.name)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument),
			await this.evalAsync(options.name)
		];
	},
	evaluate(value, name) {
		return sc.utils.findProperty(value, name, true);
	}
};
