// TODO: remove after JQuery is removed

import BaseModule from './baseModule';

function jQueryEventPublisher(e, args) {
	sc.events.emit(e.type, args);
}

export default class extends BaseModule {
	install() {
		$(document).on('dragStart', jQueryEventPublisher);
		$(document).on('dragDrop', jQueryEventPublisher);
		$(document).on('dragStop', jQueryEventPublisher);
		$(document).on('beforeDragStart', jQueryEventPublisher);
		return Promise.resolve();
	}
}
