var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "icon-action-wrapper",
      on: { click: _vm.executeMenuAction }
    },
    [
      _c(
        "svg",
        {
          staticClass: "svg-icon svg-20",
          class: _vm.cssClass,
          style: _vm.iconStyleObject
        },
        [_c("use", { attrs: { "xlink:href": "#" + _vm.name } })]
      ),
      _vm._v(" "),
      _c("i", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading"
          }
        ],
        staticClass: "loading-overlay"
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }