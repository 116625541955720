import { isNullOrUndefined } from '@acx-xms/data-functions/dist';

export default {
	parameters(options) {
		return [
			this.eval(options.argument)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument)
		];
	},
	evaluate(arg) {
		if (isNullOrUndefined(arg)) {
			return null;
		}
		const date = new Date(arg);
		if (isNaN(date.getTime())) {
			return null;
		}
		return date;
	}
};
