import { isActive } from './action.methods';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default async (options, evaluationContext, selection) => {
	if (!await isActive(evaluationContext, selection)) {
		return false;
	}

	if ((selection && selection.length === 1) || (evaluationContext.entity && evaluationContext.entity.id)) {
		const enableOptions = options.enable !== undefined ? options.enable : true;

		const enable = evaluationContext.entity && evaluationContext.entity.id
			? await evaluationContext.evalAsync(enableOptions)
			: await createEvaluationContext(selection[0]).evalAsync(enableOptions);

		return enable;
	}

	return false;
};
