<template>
	<div class='maintenance-wrapper'>
        <img v-if='statusRestore' src='/Images/xms_maintenance.svg' alt='maintenance-image'/>
        <img v-else src='/Images/xms_maintenance_server_error.svg' alt='maintenance-image'/>
        <div class='maintenance-wrapper-desc' v-html='textHandler'></div>
	</div>
</template>
<script>
export default {
	name: 'maintenance-wrapper',
	data() {
		return { statusRestore: false };
	},
	methods: {
		maintenanceRedirectHandler() {
			window.location.replace(this.$route.query.redirect_url);
		}
	},
	computed: {
		textHandler() {
			return this.statusRestore
				? 'We\'re down for maintenance. Be right back.<br>This page will be automatically refreshed, as soon as the maintenance window ends.'
				: 'Server is not available now. We are working on it.<br>This page will be automatically refreshed, as soon as the issue is resolved.';
		}
	},
	created() {
		this.statusRestore = this.$route.query.restore === 'true';
		setTimeout(() => {
			this.maintenanceRedirectHandler();
		}, 120000);
	}
};
</script>
<style src='./maintenance-wrapper.less' scoped></style>
