var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "scrollable-panel" }, [
    _vm.results.length == 0
      ? _c("div", { staticClass: "no-data-message" }, [
          _vm._v(_vm._s(_vm.noDataMessage))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.results.length > 0
      ? _c(
          "div",
          {
            directives: [
              {
                name: "perfectscroll",
                rawName: "v-perfectscroll",
                value: { enable: true, addMouseoverEvent: true },
                expression: "{enable: true, addMouseoverEvent: true}"
              }
            ],
            staticClass: "content-scroll-wrapper",
            class: "columns-" + _vm.options.columns,
            staticStyle: { display: "flex", "padding-bottom": "5px" }
          },
          _vm._l(_vm.results, function(item) {
            return _c("entitytemplate-template", {
              key: item.Id,
              attrs: {
                options: _vm.options.template,
                evaluationContext: _vm.getContext(item)
              }
            })
          })
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }