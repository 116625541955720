<template>
	<div class="marketspace-conversation-menu" :class="isAnonymous && 'anonymous'" @click="onClick" title="Favorites">
		<svg class='svg-icon svg-20'>
			<use xlink:href="#layout-icons-favorite-false" class="icon"></use>
		</svg>
		<counter :count="count" :maxCount="99"></counter>
	</div>
</template>
<script>
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import Counter from '@/Components/Control/UnreadMessagesCounter/counter';
import { Search, CreateBulk } from '@acx-xms/data-functions/dist';
import MsSearchResultsState from './../../../States/modules/ms-search-results-state';

export default {
	name: 'menu-favorites',
	components: { Counter },
	data() {
		return { isAnonymous: true };
	},
	computed: {
		count() {
			return this.$store.state.counters && this.$store.state.counters.favoritesCounter;
		}
	},
	async created() {
		this.settings = await sc.classes.get('settings.dataProvider').get('favoritesPanelSettings');
		this.entity = helperMethods.eval(null, this.settings.entity);
		this.isAnonymous = await helperMethods.evalAsync(null, this.settings.isAnonymous);
		this.stateNamespace = helperMethods.eval(null, this.settings.stateNamespace);
		this.combinedNamespace = helperMethods.eval(null, this.settings.combinedNamespace);

		await this.setFavoritesFromLocalStorage();
		!this.isAnonymous && this.getFavoritesCounter();
	},
	methods: {
		onClick() {
			if (this.$route.path.includes(`${this.entity}-`)) {
				this.$root.$emit('applyFavoritesFilter');
			}
		},
		async setFavoritesFromLocalStorage() {
			let lsFavorites = JSON.parse(localStorage.getItem('favorites'));
			if (lsFavorites && lsFavorites.length) {
				if (this.isAnonymous) {
					this.$store.commit('counters/setFavoritesCounter', lsFavorites.length);
					if (!this.$store.state[this.stateNamespace]) {
						this.$store.registerModule(this.stateNamespace, MsSearchResultsState);
					}
					lsFavorites.forEach(fav => {
						this.$store.commit(this.stateNamespace + '/addToFavorites', {
							id: fav,
							value: true
						});
					});
				} else {
					const filters = [sc.classes.get('offsetFrom.filter', 0), sc.classes.get('offsetSize.filter', 9999)];
					const { Results } = await this.getFavorites(filters);
					if (Results.length) {
						const favoritesListings = Results.map(r => r.Id);
						lsFavorites = lsFavorites.filter((fav) => !favoritesListings.includes(fav));
					}
					const records = [];
					lsFavorites.forEach(fav => {
						const record = {
							id: sc.utils.guid(),
							method: 'POST',
							url: 'favorite',
							body: {
								type: 'favorite',
								name: ' ', // required field in EDGE
								entityid: {
									id: fav,
									logicalname: this.entity
								}
							}
						};
						records.push(record);
					});
					if (records.length) {
						await CreateBulk(records);
						this.$root.$emit(this.combinedNamespace + '.searching');
					}
					localStorage.removeItem('favorites');
				}
			}
		},
		async getFavoritesCounter() {
			const filters = [
				sc.classes.get('offsetFrom.filter', 0), sc.classes.get('offsetSize.filter', 0),
				sc.classes.get('selectedFields.filter', []).fillQuery()
			];
			let counters = await this.getFavorites(filters);
			counters = counters.Total;
			this.$store.commit('counters/setFavoritesCounter', counters);
		},
		async getFavorites(filters) {
			let configuredFilters = this.settings.filters.map((f) => {
				const filter = sc.classes.get(f.$type, f);
				return filter.toFilter();
			});
			configuredFilters = [...configuredFilters, ...filters];
			return await Search(this.entity, configuredFilters);
		}
	}
};
</script>
<style src="./menu-conversation.less" scoped></style>
