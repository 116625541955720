<template>
	<div class="component-innersearch-results" v-if="results.length > 0 && visible">
		<div class="header">
			<results-toggle v-if="content"
							:namespace="namespace"
							:internalNamespace="internalNamespace"
							:toggleNamespace="toggleNamespace"
							:initCollapsed="collapsed"
							:isParent="false"></results-toggle>
			<div class="title" v-bind:class="{ 'counter-only' : !content }">{{ (results.length > 1 && pluralTitle) ? pluralTitle : title}}</div>
			<div class="counter">{{results.length}}</div>
			<div class="loading-overlay" v-show="loading"></div>
		</div>
		<div class="content-wrapper" v-show="!collapsed" v-if="content">
			<div class="content">
				<div class="content-inherit-height inner-search-results"
					 v-bind:is="prepareComponentName(content.$type)"
					 v-bind:options="content"
					 v-bind:stateNamespace="stateNamespace"
					 cssClass="inner-search-results"
					 v-bind:evaluationContext="evaluationContext"
					 v-bind:results="results"></div>
			</div>
		</div>
	</div>
</template>
<script>
import { prepareComponentName as prepCompName } from '@acx-xms/data-functions/dist';
import ComponentSearchResultsMixins from './component-search-results.mixins';
import helperMethods from './../component-set-evaluation-context-helper.methods';
import SortPanelMixins from './../../SortPanel/sortpanel.mixins';
import ResultsToggle from './results-toggle/results-toggle';

export default {
	name: 'component-set-component-inner-search-results',
	mixins: [ComponentSearchResultsMixins, SortPanelMixins],
	props: {
		options: Object,
		evaluationContext: Object
	},

	components: {
		'component-set-searchresultstableview': () => import('./Views/table-view/component-set-search-results-table-view'),
		ResultsToggle
	},

	data() {
		return {
			results: [],
			loading: true,
			collapsed: this.options.isCollapsed,
			pluralTitle: (this.options.pluralTitle) ? helperMethods.eval(this.evaluationContext, this.options.pluralTitle) : '',
			title: (this.options.title) ? helperMethods.eval(this.evaluationContext, this.options.title) : '',
			internalNamespace: helperMethods.eval(this.evaluationContext, this.options.internalNamespace),
			namespace: helperMethods.eval(this.evaluationContext, this.options.namespace),
			toggleNamespace: helperMethods.eval(this.evaluationContext, this.options.toggleNamespace),
			modalNamespace: helperMethods.eval(this.evaluationContext, this.options.modalNamespace),
			stateNamespace: helperMethods.eval(this.evaluationContext, this.options.stateNamespace),
			content: null,
			visible: this.options.visible !== void 0 ? helperMethods.eval(this.evaluationContext, this.options.visible) : true
		};
	},
	created() {
		// todo: correlate with -//-base.vue component (they have similar logic, may be allocate it in mixin)
		if (this.options.component && this.options.component[0]) {
			// todo clean and more to methods
			this.content = {
				internalNamespace: this.internalNamespace,
				namespace: this.namespace,
				toggleNamespace: this.toggleNamespace,
				targetEntity: this.logicalName,
				modalNamespace: this.modalNamespace,
				isParent: false,
				...this.options.component[0]
			};

			// inner results should not have check all
			this.content.withCheckAll = false;
		}

		this.fork = sc.events.fork();

		this.logicalName = helperMethods.eval(this.evaluationContext, this.options.targetEntity[0]);
		this.combinedNamespace = this.namespace + '.' + this.internalNamespace;
		const itemsPerPage = this.options.itemsPerPage
			? this.options.itemsPerPage.itemsCountPerPage
			: [{
				value: 10,
				default: true
			}];
		const defaultItem = itemsPerPage.filter(item => item.default === true);
		const availablePageSizes = itemsPerPage.map(item => parseInt(item.value));
		this.pageSize = defaultItem.length ? parseInt(defaultItem[0].value) : availablePageSizes[0];
		this.$on('toggleResults', (state) => {
			this.collapsed = state;
		});
		if (this.evaluationContext && this.stateNamespace) {
			this.$root.$on(`${this.stateNamespace}.syncSelection.parent.${this.evaluationContext.entity.id}`, this.selectChildren);
			this.$root.$on(this.stateNamespace + '.entity.searched', () => {
				this.groupedSearch();
			});
		}

		this.orderByFilters = [];

		if (this.options.sortByFields && this.options.sortByFields.field) {
			const orderByFields = this.evalOrderByField(this.options.sortByFields.field);
			const defaultOrderBy = this.getDefaultOrderByField(orderByFields);
			const orderByFilters = this.getOrderByFilter(defaultOrderBy);

			if (orderByFilters) {
				this.orderByFilters = (orderByFilters.length ? orderByFilters : [orderByFilters])
					.map((f) => f.fillQuery());
			}
		}
		this.$root.$on(['entity.changed', 'entity.created'], this.onEntityCallback);
		this.$root.$on(this.combinedNamespace + '.searching', () => {
			this.groupedSearch();
		});
	},

	async mounted() {
		await this.groupedSearch();

		if (!this.fork) {
			return;
		}
		this.fork.on(['entity.changed', 'entity.created'], this.onEntityCallback);

		this.fork.on(this.combinedNamespace + '.searching', () => {
			this.groupedSearch();
		});
	},

	methods: {
		async onEntityCallback(data) {
			if (this.logicalName === data.entity.logicalname) {
				await this.groupedSearch();
			}
		},
		prepareComponentName(name) {
			return prepCompName(name);
		},
		selectChildren(select) {
			const filteredResults = this.results.filter(record => {
				return this.$store.getters[this.stateNamespace + '/isRecordPreselected'](record) === 'default';
			});

			if (this.results.length > 0) {
				this.$store.commit(this.stateNamespace + '/changeSelection', {
					records: filteredResults,
					select
				});
			}
		}
	},

	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
			this.fork = null;
		}
		this.$root.$off(`${this.stateNamespace}.syncSelection.parent.${this.evaluationContext.entity.id}`, this.selectChildren);
		this.$root.$off(['entity.changed', 'entity.created'], this.onEntityCallback);
		this.$root.$off(this.combinedNamespace + '.searching', this.groupedSearch);
		this.$root.$off(this.stateNamespace + '.entity.searched', this.groupedSearch);
	}
};
</script>
<style>
	.inner-search-results {
		margin-bottom: -7px;
	}

	.component-search-results-details .inner-search-results {
		margin-bottom: 0;
	}

	.component-innersearch-results {
		position: relative;
	}

	.inner-search-results > div > div:first-child {
		padding-top: 5px;
	}

	.inner-search-results > div > div:last-child {
		padding-bottom: 8px;
	}

	.inner-search-results > div > div:not(:last-child) .content-wrapper {
		padding-bottom: 8px;
	}
</style>
<style src="./inner-search-results.less" scoped></style>
