import ExpressionAnd from './and';
import ExpressionCbool from './cbool';
import ExpressionEndsWith from './endsWith';
import ExpressionEq from './eq';
import ExpressionFalse from './false';
import ExpressionGe from './ge';
import ExpressionGt from './gt';
import ExpressionGuidValid from './guidvalid';
import ExpressionHasCRM from './hasCRM';
import ExpressionHasValue from './hasvalue';
import ExpressionIn from './in';
import ExpressionIsEmpty from './isempty';
import ExpressionLe from './le';
import ExpressionLt from './lt';
import ExpressionNe from './ne';
import ExpressionNot from './not';
import ExpressionOr from './or';
import ExpressionTrue from './true';
import ExpressionXor from './xor';

export default {
	resolve() {
		return {
			'expression.and': ExpressionAnd,
			'expression.cbool': ExpressionCbool,
			'expression.endsWith': ExpressionEndsWith,
			'expression.eq': ExpressionEq,
			'expression.false': ExpressionFalse,
			'expression.ge': ExpressionGe,
			'expression.gt': ExpressionGt,
			'expression.guidvalid': ExpressionGuidValid,
			'expression.hasCRM': ExpressionHasCRM,
			'expression.hasvalue': ExpressionHasValue,
			'expression.in': ExpressionIn,
			'expression.isempty': ExpressionIsEmpty,
			'expression.le': ExpressionLe,
			'expression.lt': ExpressionLt,
			'expression.ne': ExpressionNe,
			'expression.not': ExpressionNot,
			'expression.or': ExpressionOr,
			'expression.true': ExpressionTrue,
			'expression.xor': ExpressionXor
		};
	}
};
