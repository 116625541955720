<template>
	<div class="custom-image-placeholder" v-if="isCustomImagePlaceholder">
		<img src="/Images/custom-image-placeholder.jpg" :alt="alt">
	</div>
	<div class="svg-thumbnail" v-else>
		<svg  class="svg-icon" :class="cssClass">
			<use :xlink:href="placeholderEntityName"></use>
		</svg>
	</div>
</template>
<script>
export default {
	name: 'image-placeholder',
	props: {
		isCustomImagePlaceholder: Boolean,
		cssClass: String,
		placeholderEntityName: String,
		alt: String
	}
};
</script>
<style src="./image-placeholder.less" scoped></style>
