<template>
	<div class="component-search-results is-parent">
		<div class="component-search-results-details">
			<component v-if="component" v-bind:is="prepareComponentName(component.template.content.$type)"
					   v-bind="{ scrollNamespace: component.scrollNamespace, template: component.template, defaultRoute, trackActivity: !!component.trackUserActivity, accessByLink: accessByLink, redirectRouteIfNotExist: redirectRouteIfNotExist }" />
			<div class="loading-wrapper" v-if="isLoading">
				<div class="loading-overlay"></div>
			</div>
			<div class="no-data" v-if="!component && !isLoading" v-localization="{key: 'common.dictionary.recordDetails.noAccess'}"></div>
		</div>
	</div>
</template>
<script>
import * as tracking from '@/Data/trackingModule';
import { prepareComponentName as prepCompName } from '@acx-xms/data-functions/dist';
import helperMethods from './../component-set-evaluation-context-helper.methods';
const EntitytemplateComponentsetdetails = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/EntityTemplate/Details/entitytemplate-componentsetdetails');
const EntitytemplateComponentsettabdetails = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/EntityTemplate/Details/entitytemplate-componentsettabdetails');

export default {
	name: 'route-search-details',
	props: {
		options: Object,
		defaultRoute: Object,
		accessByLink: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			evaluationContext: null,
			component: null,
			isLoading: false,
			redirectRouteIfNotExist: ''
		};
	},
	components: {
		EntitytemplateComponentsetdetails,
		EntitytemplateComponentsettabdetails
	},
	methods: {
		prepareComponentName: (name) => {
			return prepCompName(name);
		}
	},
	async created() {
		tracking.UpdateSession(this.$route);
		this.logicalName = this.$route.params.type;
		this.isLoading = true;

		const componentSetKeys = [this.logicalName + '-search-details'];
		this.$route.params.componentSet && componentSetKeys.unshift(this.logicalName + '-search-details-' + this.$route.params.componentSet);
		const componentSets = await sc.classes.get('marketspaceComponents.dataProvider').getComponentSetByIds(componentSetKeys);
		componentSets.forEach(componentSet => {
			if (!this.component && componentSet && componentSet.component && componentSet.component.length) {
				this.component = componentSet.component[0];
				this.redirectRouteIfNotExist = helperMethods.eval(null, this.component.redirectRouteIfNotExist) || '';
			}
		});

		this.isLoading = false;
	},
	beforeDestroy() {
		tracking.UpdateSession(this.$route);
	}

};
</script>
<style src="../SearchResults/search-results.less" scoped></style>
<style src="./search-details.less" scoped></style>
<style>
	.loading-wrapper .loading-overlay {
		background-color: transparent;
	}
</style>
