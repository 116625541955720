import { render, staticRenderFns } from "./search-results-thumbnail-view.vue?vue&type=template&id=23c3e50a&scoped=true&"
import script from "./search-results-thumbnail-view.vue?vue&type=script&lang=js&"
export * from "./search-results-thumbnail-view.vue?vue&type=script&lang=js&"
import style0 from "./search-results-thumbnail-view.less?vue&type=style&index=0&id=23c3e50a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23c3e50a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\SCCore\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('23c3e50a', component.options)
    } else {
      api.reload('23c3e50a', component.options)
    }
    module.hot.accept("./search-results-thumbnail-view.vue?vue&type=template&id=23c3e50a&scoped=true&", function () {
      api.rerender('23c3e50a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Scripts/Vue/Components/ComponentSet/SearchResults/Views/thumbnail-view/search-results-thumbnail-view.vue"
export default component.exports