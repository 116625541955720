export default {
	async enabled() {
		return true;
	},
	async execute(options, context) {
		const localizations = sc.classes.get('localization.dataProvider');

		sc.events.emit('vue.dialog.open', {
			title: await localizations.getLabelForCurrentLanguage('dialogues.addBrokers.title'),
			component: 'add.brokers',
			maximizedWidth: '80%',
			maximizedHeight: '100%',
			toastMessageKey: context.eval(options.toastMessageKey),
			informationDialogTextKey: context.eval(options.informationDialogTextKey)
		});
	}
};
