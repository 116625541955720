var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "maintenance-wrapper" }, [
    _vm.statusRestore
      ? _c("img", {
          attrs: {
            src: "/Images/xms_maintenance.svg",
            alt: "maintenance-image"
          }
        })
      : _c("img", {
          attrs: {
            src: "/Images/xms_maintenance_server_error.svg",
            alt: "maintenance-image"
          }
        }),
    _vm._v(" "),
    _c("div", {
      staticClass: "maintenance-wrapper-desc",
      domProps: { innerHTML: _vm._s(_vm.textHandler) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }