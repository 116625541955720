<template>
	<div class="component-tab-search-results" v-if="!isLoading">
		<template v-if="cachedComponents.length">
			<keep-alive :include="cachedComponents">
				<component
					v-for="tab in tabs.filter(tab => tab.selected)"
					:key="tab.title"
					:is="prepareComponentName(tab.component.$type)"
					:options="tab.component"
					:evaluationContext="evaluationContext"
					:isSelected="tab.selected"
				>
					<div slot="title-slot" class="tabs">
						<span
							v-for="tab in tabs"
							:key="tab.title"
							:class="['title', tab.selected&&'selected', tab.titleCssClass]"
							@click="selectTab(tab)"
						>{{ tab.title }}</span>
					</div>
					<div slot="toggle-slot"></div>
				</component>
			</keep-alive>
		</template>
		<template v-else>
			<component
				v-for="tab in filteredTabs"
				v-show="tab.selected"
				:key="tab.title"
				:is="prepareComponentName(tab.component.$type)"
				:options="tab.component"
				:evaluationContext="evaluationContext"
				:isSelected="tab.selected"
			>
				<div slot="title-slot" class="tabs">
					<span
						v-for="tab in tabs"
						:key="tab.title"
						:class="['title', tab.selected&&'selected', tab.titleCssClass]"
						@click="selectTab(tab)"
					>{{ tab.title }}</span>
				</div>
				<div slot="toggle-slot"></div>
			</component>
		</template>
	</div>
</template>
<script>
import { prepareComponentName as prepCompName } from '@acx-xms/data-functions/dist';

export default {
	name: 'component-set-tabsearchresults',
	components: {
		'component-set-vuecontrolcomponentwrapper': () => import('./../../../Control/control-vuecontrol-wrapper'), // TODO: revisit xml and component name
		'control-chat': () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../../../Control/Chat/control-chat'),
		'control-searchresultnamespaceddetailscolumn': () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/ComponentSet/Details/details-column-control-namespaced'),
		'control-async-control': () => import('./../../../Control/control-async-control')
	},
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			tabs: [],
			cachedComponents: '',
			isLoading: true
		};
	},
	async created() {
		const cachedComponents = [];
		const fillTabs = async () => {
			for (const tab of this.options.tab) {
				const visible = tab.visible === void 0 || await this.evaluationContext.evalAsync(tab.visible);
				if (visible) {
					this.tabs.push({
						title: this.evaluationContext.eval(tab.title),
						titleCssClass: this.evaluationContext.eval(tab.titleCssClass),
						selected: false,
						component: tab.control
					});
					if (tab.cacheTab) {
						cachedComponents.push(this.prepareComponentName(tab.control.$type));
					}
				}
			}
		};
		await fillTabs();
		this.cachedComponents = cachedComponents.join(',');

		this.tabs[0].selected = true;
		this.isLoading = false;
	},
		beforeDestroy() {
		//TODO: remove this hardcode after the release R7 and review work with store for tab and doc/folder treeview components

		if (this.$store.state['dealroom.documents']) {
			this.$store.commit('dealroom.documents' + '/cleanSelection');
		}
		if (this.$store.state['dealroom.folder-tree']) {
			this.$store.dispatch('dealroom.folder-tree' + '/setDefaultValues');
		}
		if (this.$store.state['collaborationroom.documents']) {
			this.$store.commit('collaborationroom.documents' + '/cleanSelection');
		}
		if (this.$store.state['collaborationroom.folder-tree']) {
			this.$store.dispatch('collaborationroom.folder-tree' + '/setDefaultValues');
		}
	},
	computed: {
		filteredTabs() {
			if (this.options.saveSearchField) {
				return this.tabs;
			}
			return this.tabs.filter(tab => tab.selected);
		}
	},
	methods: {
		selectTab(tab) {
			this.tabs.forEach((tab) => {
				tab.selected = false;
			});
			tab.selected = true;
		},
		prepareComponentName(name) {
			return prepCompName(name);
		}
	}
};
</script>
<style src="./tab-search-results.less" scoped></style>
