export default {
	async enabled(options, evaluationContext, selection) {
		return true;
	},
	execute(options, context, selection) {
		const authentication = sc.classes.get('authentication');
		const configName = context.eval(options.configName);
		const blank = options.blank ? context.eval(options.blank) : true;
		const trimChar = (char) => {
			const replaceString = '^' + char + '+|' + char + '$';
			const regex = new RegExp(replaceString, 'g');
			return char.replace(regex, '');
		};
		let title = 'actions.switchConfigErrorTitle';
		let message = 'actions.configDoesNotExist';
		sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage(title).then((text) => { title = text; });
		sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage(message, [configName]).then((text) => { message = text; });

		authentication.getConfigurations()
			.then((result) => {
				if (result.configurations && result.configurations.indexOf('MSGuest') === -1) {
					result.configurations.push('MSGuest');
				} // hotfix rethink after release
				if (result.configurations && result.configurations.indexOf(configName) !== -1) {
					const hostName = window.location.origin;
					const baseUrl = trimChar.call(sc.cluster.baseUrl, '/');
					const url = new URL(baseUrl + '/' + sc.cluster.key + '/' + configName, hostName);
					if (blank) {
						const win = window.open(url.href, '_blank');
						// IOS Safari blocks popups by default
						if (!win) {
							window.location.href = url.href;
						} else {
							win.focus();
						}
					} else {
						window.location.href = url.href;
					}
				} else {
					sc.events.emit('dialog.error', {
						title,
						message
					});
				}
			});
	}
};
