<template>
	<div class="sync-wrapper" v-bind:class="crm">
		<div class="header">
			<div class="h2" v-localization="{key: 'crmSyncPlugin.header.syncstatus'}"></div>
		</div>
		<div class="body">
			<SyncProgress :processId="processId" @onSyncCompleted="onSyncCompleted" v-if="stepNumber===1" />
			<div class="step2" v-if="stepNumber===2">
				<div class="stepWrapper" v-if="syncStatus.ApplicationErrors.length === 0 || syncStatus.Total > 0">
					<span :key="'syncIsCompleted'" class="primary-label" v-localization="{key: 'crmSyncPlugin.syncIsCompleted'}"></span>
					<div class="info">
						<div class="skipped-records-labels" v-if="syncStatus.HasSkippedParentEnities">
							<span v-localization="{key: 'syncPlugin.warn.someRecordSkippedMessage1line'}"></span>
							<span v-localization="{key: 'syncPlugin.warn.someRecordSkippedMessage2line'}"></span>
						</div>
						<div class="content sync-stasus" v-if="Object.keys(syncStatus.StateEntities).length">
							<div class="tableView">
								<div class="tableview-header">
									<div v-localization="{key: crm ? 'crmSyncPlugin.tableHeader.entity' + '.' + crm : 'crmSyncPlugin.tableHeader.entity'}"></div>
									<div v-localization="{key: 'crmSyncPlugin.updated'}"></div>
									<div v-localization="{key: 'crmSyncPlugin.synced'}"></div>
									<div v-localization="{key: 'crmSyncPlugin.failed'}"></div>
									<div v-localization="{key: 'crmSyncPlugin.interrupted'}"></div>
								</div>
								<div class="tableview-content" v-perfectscroll="{enable: true}">
									<div class="row" v-for="(entity, value) in syncStatus.StateEntities" :key="value">
										<div class="column">
											<EntityTitle :logicalname="value" :isSingular="false"/>
										</div>
										<div class="column">
											<span>{{entity.Updated}}</span>
										</div>
										<div class="column">
											<span>{{entity.AlreadySynced}}</span>
										</div>
										<div class="column">
											<span>{{entity.Failed}}</span>
										</div>
										<div class="column">
											<span>{{entity.Interrupted}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="content" v-if="skippedRecords && skippedRecords.length">
							<div class="tableView">
								<div class="tableview-header">
									<div v-localization="{key: 'crmSyncPlugin.tableHeader.failedRecords'}"></div>
									<div v-localization="{key: 'crmSyncPlugin.tableHeader.entity'}"></div>
									<div v-localization="{key: 'crmSyncPlugin.tableHeader.id'}"></div>
									<div v-localization="{key: 'crmSyncPlugin.tableHeader.reason'}"></div>
								</div>
								<div class="tableview-content" v-perfectscroll="{enable: true}">
									<div class="row" v-for="skippedRecord in skippedRecords" :key="skippedRecord.Id">
										<div class="column">
											<span>{{skippedRecord.Name}}</span>
										</div>
										<div class="column">
											<EntityTitle :logicalname="skippedRecord.Entity" :isSingular="true" />
										</div>
										<div class="column">
											<span>{{skippedRecord.Id}}</span>
										</div>
										<div class="column">
											<span>{{skippedRecord.Reason}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="stepWrapper" v-else-if="syncStatus.ApplicationErrors.length > 0">
					<span :key="'syncIsCompleted'" class="primary-label" v-localization="{key: 'crmSyncPlugin.syncIsFailed'}"></span>
					<span class="label" v-localization="{key: 'crmSyncPlugin.applicationErrors'}" />
					<div class="application-errors">
						<div v-for="(error,index) in syncStatus.ApplicationErrors" v-perfectscroll="{enable: true}" :key="index">
							{{error.Message}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<span class="buttons">
				<button class="cancel" @click="cancel" v-show="stepNumber===1 && processId!==null" type="button" v-localization="{key: 'crmSyncPlugin.button.cancel'}"></button>
				<button @click="ok" v-show="stepNumber===2" type="button" v-localization="{key: 'crmSyncPlugin.button.ok'}"></button>
			</span>
		</div>
	</div>
</template>
<script>

import SyncProgress from './sync-progress';
import EntityTitle from './entity-title';
import crmSyncDataProvider from '@/Data/DataProviders/crmSyncDataProvider.js';

export default {
	name: 'sync',
	props: { options: Object },
	data() {
		return {
			stepNumber: 1,
			syncStatus: null,
			processId: null
		};
	},
	components: {
		SyncProgress,
		EntityTitle
	},
	async created() {
		this.localizationDataProvider = sc.classes.get('localization.dataProvider');
		this.cancelWarn = ko.unwrap(this.localizationDataProvider.localize('cancel.warn'));
		this.entityConfigurationDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		this.crm = this.findGetParameter('crm');
		const processId = this.findGetParameter('processId');
		this.processId = processId;
	},
	methods: {
		ok() {
			window.close();
		},

		cancel() {
			if (confirm(this.cancelWarn)) {
				crmSyncDataProvider.cancel(this.processId);
			}
		},

		findGetParameter(parameterName) {
			let result = null;
			let tmp = [];
			location.search
				.substr(1)
				.split('&')
				.forEach(function (item) {
					tmp = item.split('=');
					if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
				});
			return result;
		},
		onSyncCompleted(data) {
			if (data) {
				this.syncStatus = data;
				delete this.syncStatus.StateEntities.geography;

				for (const key in this.syncStatus.StateEntities) {
					const value = this.syncStatus.StateEntities[key];
					if (!value.AlreadySynced && !value.Failed && !value.Interrupted && !value.Updated) {
						delete this.syncStatus.StateEntities[key];
					}
				}
			} else {
				this.syncStatus = {
					ApplicationErrors: [],
					StateEntities: {}
				};
			}

			this.stepNumber = 2;
		}
	},
	computed: {
		skippedRecords() {
			let result = [];

			if (this.syncStatus !== null) {
				for (const key in this.syncStatus.StateEntities) {
					const value = this.syncStatus.StateEntities[key];

					result = result.concat(value.SkippedRecords);
				}
			}

			return result;
		}
	}
};
</script>
<style src="./sync.less" scoped></style>
