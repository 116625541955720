import ExpressionContextRef from './contextRef';
import ExpressionCReference from './cReference';
import ExpressionDefaultCurrency from './defaultCurrency';
import ExpressionDefaultSom from './defaultSom';
import ExpressionReference from './reference';

export default {
	resolve() {
		return {
			'expression.contextRef': ExpressionContextRef,
			'expression.cReference': ExpressionCReference,
			'expression.defaultCurrency': ExpressionDefaultCurrency,
			'expression.defaultSom': ExpressionDefaultSom,
			'expression.reference': ExpressionReference
		};
	}
};
