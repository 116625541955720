import { isNullOrUndefined, isArray } from '@acx-xms/data-functions/dist';

export default {
	parameters(options) {
		return [
			this.eval(options.argument)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument)
		];
	},
	evaluate(argument) {
		if (isNullOrUndefined(argument)) {
			return 0;
		}
		if (!isArray(argument)) {
			throw new Error('expression.array.length: Argument is not an array');
		}
		return argument.length;
	}
};
