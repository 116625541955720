<template>
	<div class="actionButton clickable" v-bind:class="styles" @click.prevent="click" v-if="visible && isEnabled && !isLoading">
		<span class="icon-wrapper" v-if="icon">
			<svg class="svg-icon" v-bind:class="size">
				<use :xlink:href="'#action-icons-'+icon"></use>
			</svg>
		</span>
		<span>{{caption}}</span>
	</div>
</template>
<script>
import ActionResolver from '../../Actions/actionResolver';
export default {
	name: 'control-actionbutton',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			visible: false,
			icon: (this.options.icon) ? this.evaluationContext.eval(this.options.icon) : null,
			caption: (this.options.caption) ? this.evaluationContext.eval(this.options.caption) : '',
			size: this.options.size ? ('svg-' + this.options.size) : 'svg-20',
			isEnabled: false,
			selection: this.options.action.source === 'noSelection' ? [] : ((this.options.action.selection) ? this.evaluationContext.eval(this.options.action.selection) : null),
			isLoading: true
		};
	},
	computed: {
		styles() {
			let styles = this.options.style || 'default';
			if (!this.isEnabled) {
				styles += ' disabled';
			}
			if (this.icon) {
				styles += ' withicon';
			}
			return styles;
		}
	},
	async created() {
		this.visible = this.options.visible !== void 0 ? await this.evaluationContext.evalAsync(this.options.visible) : true;
		this.actionResolver = ActionResolver();
		if (this.options.action) {
			this.action = this.actionResolver.resolve(this.options.action.$type);
			if (!this.action) {
				throw new Error(`'${this.options.action.$type}' action was not found`);
			}
		}
		// todo get rid of ko actions
		if (this.action && this.action.isEnabled) {
			this.isEnabled = Promise.resolve(this.action.isEnabled()).then(result => {
				this.isEnabled = result;
			});
		} else if (this.action && this.action.enabled) {
			Promise.resolve(this.action.enabled(this.options.action, this.evaluationContext, this.selection)).then(result => {
				this.isEnabled = result;
			});
		}
		this.isLoading = false;
	},
	methods: {
		click: function () {
			if (!this.options.action) {
				return;
			}
			if (!this.isEnabled) {
				return;
			}

			this.action.execute(this.options.action, this.evaluationContext);
		}
	}
};
</script>
<style scoped>
</style>
