import { isNullOrUndefined, isArray } from '@acx-xms/data-functions/dist';

export default {
	parameters(options) {
		return [
			this.eval(options.argument),
			this.eval(options.skip),
			this.eval(options.take)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument),
			await this.evalAsync(options.skip),
			await this.evalAsync(options.take)
		];
	},
	evaluate(array, skip, take) {
		if (isNullOrUndefined(array)) {
			return [];
		}
		if (!isArray(array)) {
			throw new Error('expression.array.slice: Argument is not an array');
		}
		skip = skip || 0;
		take = take || array.length - skip;

		return array.slice(skip, skip + take);
	}
};
