var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ms-conversation-details" },
    [
      _c(
        "div",
        {
          staticClass: "svg-thumbnail back-button",
          on: { click: _vm.closeDetails }
        },
        [
          _c("svg", { staticClass: "svg-icon svg-12" }, [
            _c("use", { attrs: { "xlink:href": "#action-icons-back-button" } })
          ])
        ]
      ),
      _vm._v(" "),
      _c("control", {
        key: _vm.entity.Id,
        attrs: {
          name: _vm.detailsTemplate.$type,
          contentProps: _vm.detailsTemplate,
          evaluationContext: _vm.evaluationContext
        }
      }),
      _vm._v(" "),
      _vm.tabsData
        ? _c(
            "div",
            { staticClass: "tabs" },
            _vm._l(_vm.tabsData, function(tab, key) {
              return tab.enabled
                ? _c("div", {
                    directives: [
                      {
                        name: "localization",
                        rawName: "v-localization",
                        value: { key: tab.localizationKey },
                        expression: "{ key: tab.localizationKey }"
                      }
                    ],
                    key: key,
                    staticClass: "tab-title",
                    class: { active: tab.active },
                    on: {
                      click: function($event) {
                        _vm.loadTab(key)
                      }
                    }
                  })
                : _vm._e()
            })
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.activeTab
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "perfectscroll",
                  rawName: "v-perfectscroll",
                  value: { enable: true },
                  expression: "{enable: true}"
                }
              ],
              key: _vm.activeTab.key,
              staticClass: "tab-content"
            },
            _vm._l(_vm.activeTab.context, function(context, key) {
              return _c("control", {
                key: key,
                class: [_vm.activeTab.cssClass],
                attrs: {
                  name: _vm.activeTab.template.$type,
                  contentProps: _vm.activeTab.template,
                  evaluationContext: context
                }
              })
            })
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }