<template>
  <div
    v-data-attr
    class="marketspace-conversation-menu"
    title="Org Settings"
    @click="onClick"
  >
    <svg class="svg-icon svg-20">
      <use xlink:href="#action-icons-ms-menu-org-settings" class="icon" />
    </svg>
  </div>
</template>
<script>
import '@/Bindings/ui-attribute.directive';

export default {
	name: 'menu-org-settings',
	methods: {
		onClick() {
			this.$root.$emit('vue.dialog.open', {
				component: 'tabs-popup',
				maximizedHeight: '80%',
				maximizedWidth: '550px',
				title: 'Org Settings'
			});
		}
	}
};
</script>
<style src='./menu-conversation.less' scoped></style>
