var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "perfectscroll",
          rawName: "v-perfectscroll",
          value: { enabled: true },
          expression: "{enabled: true}"
        }
      ],
      staticClass: "wizard-preview-sidebar-root"
    },
    [
      _c(
        "div",
        {
          staticClass: "sidebar-items",
          style: "height: calc(100vh - " + _vm.heightHandler + "px)"
        },
        [
          _c("div", { staticClass: "sidebar-item sidebar-item-setup" }, [
            _c("div", { staticClass: "sidebar-item-head" }, [
              _vm._v("\n\t\t\t\t\tSetup\n\t\t\t\t\t"),
              !_vm.isDetails
                ? _c(
                    "svg",
                    {
                      staticClass: "svg-icon",
                      on: {
                        click: function($event) {
                          _vm.updateActiveStep(1)
                        }
                      }
                    },
                    [
                      _c("use", {
                        attrs: { "xlink:href": "#action-icons-ms-entity-edit" }
                      })
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.getSetupStep
              ? _c(
                  "div",
                  { staticClass: "sidebar-item-content" },
                  _vm._l(_vm.getSetupStep.form, function(item, key) {
                    return _c(
                      "div",
                      { key: key, staticClass: "item-setup-field" },
                      [
                        _c("div", { staticClass: "item-setup-field-name" }, [
                          _vm._v(_vm._s(item.title))
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "item-setup-field-value" },
                          [
                            _vm._v(_vm._s(item.value)),
                            key === "email"
                              ? [_vm._v(_vm._s(_vm.getDomain))]
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    )
                  })
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sidebar-item sidebar-item-template" }, [
            _c("div", { staticClass: "sidebar-item-head" }, [
              _vm._v("\n\t\t\t\t\tTemplate\n\t\t\t\t\t"),
              !_vm.isDetails
                ? _c(
                    "svg",
                    {
                      staticClass: "svg-icon",
                      on: {
                        click: function($event) {
                          _vm.updateActiveStep(2)
                        }
                      }
                    },
                    [
                      _c("use", {
                        attrs: { "xlink:href": "#action-icons-ms-entity-edit" }
                      })
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.getSteps && _vm.getSteps[0]
              ? _c("div", { staticClass: "sidebar-item-content" }, [
                  _c("div", { staticClass: "item-template" }, [
                    _vm.getTemplate
                      ? _c("div", { staticClass: "item-template-img" }, [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.getTemplate && _vm.getTemplate.preview
                                  ? _vm.getTemplate.preview
                                  : "/Images/co-default-thumbnail.png"
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-template-text" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t" +
                          _vm._s(
                            (_vm.getTemplate && _vm.getTemplate.templateName) ||
                              "no template"
                          ) +
                          "\n\t\t\t\t\t\t"
                      )
                    ])
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sidebar-item sidebar-item-listing" }, [
            _c("div", { staticClass: "sidebar-item-head" }, [
              _vm._v(
                "\n\t\t\t\t\t" + _vm._s(_vm.collectionTitle) + "\n\t\t\t\t\t"
              ),
              !_vm.isDetails
                ? _c(
                    "svg",
                    {
                      staticClass: "svg-icon",
                      on: {
                        click: function($event) {
                          _vm.updateActiveStep(3)
                        }
                      }
                    },
                    [
                      _c("use", {
                        attrs: { "xlink:href": "#action-icons-ms-entity-edit" }
                      })
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.getCollection
              ? _c(
                  "div",
                  { staticClass: "sidebar-item-content" },
                  _vm._l(_vm.getCollection, function(item) {
                    return _c(
                      "div",
                      { key: item.Id, staticClass: "item-listing" },
                      [
                        _c("div", { staticClass: "item-listing-img" }, [
                          _c("img", { attrs: { src: _vm.getImage(item) } })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item-listing-desc" }, [
                          _c("div", { staticClass: "item-listing-name" }, [
                            _vm._v(_vm._s(item.Name))
                          ]),
                          _vm._v(" "),
                          item.Source[_vm.collectionAddress] &&
                          item.Source[_vm.collectionAddress].address
                            ? _c(
                                "div",
                                { staticClass: "item-listing-location" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.Source[_vm.collectionAddress].address
                                    )
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "item-listing-type" }, [
                            _vm._v(_vm._s(item.Source.usetype))
                          ])
                        ])
                      ]
                    )
                  })
                )
              : _c("div", { staticClass: "item-empty" }, [
                  _vm._v("\n\t\t\t\t\tPlease select a collection\n\t\t\t\t")
                ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sidebar-item sidebar-item-list" }, [
            _c("div", { staticClass: "sidebar-item-head" }, [
              _vm._v("\n\t\t\t\t\tMarketing List\n\t\t\t\t\t"),
              !_vm.isDetails
                ? _c(
                    "svg",
                    {
                      staticClass: "svg-icon",
                      on: {
                        click: function($event) {
                          _vm.updateActiveStep(4)
                        }
                      }
                    },
                    [
                      _c("use", {
                        attrs: { "xlink:href": "#action-icons-ms-entity-edit" }
                      })
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.getMarketList
              ? _c(
                  "div",
                  { staticClass: "sidebar-item-content" },
                  _vm._l(_vm.getMarketList, function(item, i) {
                    return _c("div", { key: i, staticClass: "item-list" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s((item && item.Name) || item.Source.name) +
                          "\n\t\t\t\t\t"
                      )
                    ])
                  })
                )
              : _c("div", { staticClass: "item-empty" }, [
                  _vm._v(
                    "\n                    Please select recipients\n                "
                  )
                ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }