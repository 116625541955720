var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "searchResults-thumbnailview-columned",
      class: _vm.cssClass
    },
    [
      _vm.results && _vm.results.length
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "perfectscroll",
                  rawName: "v-perfectscroll",
                  value: {
                    enable: true,
                    refreshScroll: _vm.paginationParams.activePages.length < 2,
                    onScroll: _vm.onScroll
                  },
                  expression:
                    "{enable: true,  refreshScroll: paginationParams.activePages.length < 2, onScroll}"
                }
              ],
              staticClass: "perfectscroll-wrapper"
            },
            [
              _c(
                "div",
                { staticClass: "sc-flex" },
                _vm._l(_vm.results, function(item) {
                  return _c(
                    "div",
                    { key: _vm.recordKey(item), staticClass: "item" },
                    [
                      _c(
                        "div",
                        { staticClass: "item-wrapper" },
                        [
                          _c("control", {
                            attrs: {
                              name: _vm.options.contentComponent.$type,
                              contentProps: _vm.options.contentComponent,
                              evaluationContext: _vm.wrapResult(item)
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                })
              ),
              _vm._v(" "),
              _c("pagination", {
                staticClass: "bottomed",
                attrs: {
                  pageSize: _vm.paginationParams.pageSize,
                  total: _vm.paginationParams.total,
                  from: _vm.paginationParams.from,
                  activePages: _vm.paginationParams.activePages
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.results || !_vm.results.length,
              expression: "!results||!results.length"
            }
          ],
          staticClass: "no-results-message"
        },
        [
          _c("div", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "common.dictionary.noMatchesFound" },
                expression: "{ key: 'common.dictionary.noMatchesFound' }"
              }
            ],
            style: { width: "100%" }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }