import { isActive, checkPrivilege } from './Methods/action.methods';
// TODO: rework action and dialogue from knockout
export default {
	async enabled(options, evaluationContext, selection) {
		const active = await isActive(evaluationContext, selection);

		if (!active) {
			return false;
		}

		const def = $.Deferred();

		if (selection && selection.length > 0) {
			const listmemberEntity = evaluationContext.eval(options.listmemberEntity);
			const logicalName = selection[0].Type || selection[0].logicalname;
			const promises = [];

			promises.push(sc.classes.get('entityConfiguration.dataProvider').fetchEntity(logicalName));
			promises.push(checkPrivilege(listmemberEntity || 'awx_adhoclistmember', 'Read'));
			promises.push(checkPrivilege(listmemberEntity || 'awx_adhoclistmember', 'Create'));

			$.when.apply($, promises).then((entity, readAllowed, createAllowed) => {
				def.resolve(readAllowed && createAllowed && !!entity.staticListMemberName);
			});
		} else {
			def.resolve(false);
		}

		return def.promise();
	},
	execute(options, context, selection) {
		const listEntity = context.eval(options.listEntity);

		const selectionRefs = selection.map((item) => {
			return sc.classes.get('entityReference', item);
		});
		const logicalname = selection[0].logicalname || selection[0].Type;
		const utils = sc.classes.get('staticLists.utils');
		const parameters = {
			listType: listEntity || 'awx_adhoclist',
			selection: selectionRefs,
			listFilter: options.listFilter,
			listOrder: options.listOrder,
			allowListCreation: true, // TODO: remove this, It should be calculated on the screen based on user rights
			configuration: options.tableViewConfiguration,
			additionalListData: options.additionalListData
		};

		utils.getTypeCode(logicalname)
			.done(function (data) {
				const dialogParams = {
					localizedTitle: 'actions.staticList.addMember.title',
					icon: 'entity-icons-awx_adhoclistmember-small',
					component: 'staticLists.msAddToList',
					params: parameters,
					maximizedWidth: sc.classes.get('additionalParameters.controller').getValue('addToList-dialog-width', '800px')
				};

				sc.events.emit('dialog.open', dialogParams);
			});
	}
};
