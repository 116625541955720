<template>
	<div class="control-image-gallery"
		 v-bind:class="{'not-clickable':withExtendedGallery}"
		 v-bind:style="{ width, height }"
		 @click="openDialog">

		<div class="gallery">
			<div v-if="items.length" v-bind:style="{ width, height }">
				<div v-if="current" v-bg-image="current" :key="current.id"></div>
				<div class="image-controlbox-wrapper">
					<div class="control-image-rotator">
						<div class="image-rotator-wrapper prev" @click.stop="selectPrevious">
							<svg class='svg-icon' :class="{'disabled': currentIndex === 0}">
								<use xlink:href="#action-icons-triangle-1-w"></use>
							</svg>
						</div>
						<div class="image-rotator-wrapper next" @click.stop="selectNext">
							<svg class='svg-icon' :class="{'disabled': currentIndex >= (items.length - 1)}">
								<use xlink:href="#action-icons-triangle-1-e"></use>
							</svg>
						</div>
					</div>
					<div class="image-count-info-wrapper">
						<span class="image-count-info" v-localization="{key:'imageGallery.control.imageCountInfo', args:[currentIndex+1, items.length]}"></span>
					</div>
				</div>
			</div>

			<control-svg-image v-if="parentEntityLogicalName&&items.length==0 && !isLoading"
							   v-bind:style="{ width, height }"
							   :options="{placeholderEntityName: parentEntityLogicalName, isCustomImagePlaceholder: options.isCustomImagePlaceholder,
										size: 'custom', placeholderSize: 'medium', customSize: 100, widthUom: '%', heightUom: '%', alt: this.alt }"
							   :evaluationContext="evaluationContext"></control-svg-image>

			<div class="info-message" v-if="!parentEntityLogicalName&&items.length==0">
				<b class="info-message-title" data-bind="localization: { key: 'imageGallery.noDataMessage'}"></b>
			</div>

			<i class="loading-overlay" v-show="isLoading"></i>
		</div>
	</div>
</template>
<script>
import SmartImageGalleryMixin from './smart-image-gallery.mixins.js';
import * as tracking from '@/Data/trackingModule';
const ControlSvgImage = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '../../control-svg-image');

export default {
	name: 'smart-image-gallery',
	props: {
		options: Object,
		evaluationContext: Object
	},
	mixins: [SmartImageGalleryMixin],
	components: { ControlSvgImage },
	computed: {
		current() {
			return (this.items.length > 0)
				? {
					src: this.items[this.currentIndex].preview,
					imageFit: this.options.imageFitType,
					id: this.items[this.currentIndex].Source.imageid,
					alt: this.alt,
					mandatoryToken: true
				}
				: null;
		}
	},
	data() {
		return {
			withExtendedGallery: this.evaluationContext.eval(this.options.withExtendedGallery),
			items: [],
			currentIndex: 0,
			isLoading: true,
			parentEntityLogicalName: this.options.parentLogicalName ? this.evaluationContext.eval(this.options.parentLogicalName) : ''
		};
	},
	created() {
		this.fork = sc.events.fork();
		this.parentRef = this.evaluationContext.eval(this.options.parentRef);
		this.parentEntityLogicalName = this.parentEntityLogicalName || (this.parentRef || {}).logicalname;
		this.isItemsFromMainSource = true;
		this.subtype = this.evaluationContext.eval(this.options.subtype);
		this.alt = this.options.alt && this.evaluationContext.eval(this.options.alt);
		this.width = this.options.width;
		this.height = this.options.height;
		this.targetEntityName = this.evaluationContext.eval(this.options.targetEntityName);
		this.updateImages();
		this.fork.on(['entity.changed', 'entity.created', 'entity.deleted'], this.checkForUpdateImage);
		this.$root.$on(['entity.changed', 'entity.created', 'entity.deleted'], this.checkForUpdateImage);
	},
	beforeDestroy() {
		this.$root.$off(['entity.changed', 'entity.created', 'entity.deleted'], this.checkForUpdateImage);
		if (this.fork) {
			this.fork.dispose();
		}
	},
	methods: {
		async checkForUpdateImage(data) {
			if (data.entity.logicalname === this.targetEntityName) {
				this.isLoading = true;
				this.items = [];
				await this.updateImages();
			}
		},
		async updateImages() {
			const dataSources = this.options.dataSources.map((source) => {
				return sc.classes.get(source.$type, source, this.evaluationContext, [this.targetEntityName], source.subtype || this.subtype);
			});
			let images = [];
			images = await this.getImages(dataSources, (source) => {
				return source.subtype === this.subtype;
			});
			this.isItemsFromMainSource = true;
			if (!images.length) {
				images = await this.getImages(dataSources, (source) => {
					return source.subtype !== this.subtype;
				});
				this.isItemsFromMainSource = false;
			}
			this.setImages(images);
		},
		async getImages(dataSources, callback) {
			let images = [];
			await Promise.all(dataSources.filter(callback).map(async (fs) => {
				await fs.search().done((response) => {
					if (response.hasData) {
						images = images.concat(response.data);
					}
				});
			}));
			return images;
		},
		setImages: function (images) {
			const imageArray = images;
			const hasOrderField = imageArray.some(image => image.Source.hasOwnProperty('order'));
			if (hasOrderField) {
				imageArray.sort((a, b) => {
					return (parseInt(a.Source.order) || 0) - (parseInt(b.Source.order) || 0);
				});
			}
			this.items = imageArray;
			this.isLoading = false;
			this.$root.$emit('imageGalleryUpdate', {
				items: this.items,
				menu: this.isItemsFromMainSource ? this.options.menu : null
			});
		},
		selectPrevious() {
			if (this.currentIndex > 0) {
				this.currentIndex--;
				tracking.AddUserActivity('imagegallery.navigate.prev', 'Image gallery navigation - prev image', this.parentRef);
			}
		},
		selectNext() {
			if (this.currentIndex < this.items.length - 1) {
				this.currentIndex++;
				tracking.AddUserActivity('imagegallery.navigate.next', 'Image gallery navigation - next image', this.parentRef);
			}
		}
	}
};
</script>
<style src="../../images.mixins.less" scoped></style>
<style src="./smart-image-gallery.less" scoped></style>
