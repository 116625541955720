export default {
	parameters(options) {
		return [
			options.property.map(prop => {
				return {
					enable: this.eval(prop.enable || true),
					key: this.eval(prop.key),
					value: this.eval(prop.value)
				};
			})
		];
	},
	async parametersAsync(options) {
		return [await Promise.all(options.property.map(async (prop) => {
			return {
				enable: await this.evalAsync(prop.enable || true),
				key: await this.evalAsync(prop.key),
				value: await this.evalAsync(prop.value)
			};
		}))];
	},
	evaluate(props) {
		return props.reduce((result, item) => {
			if (item.enable) {
				result[item.key] = item.value;
			}
			return result;
		}, {});
	}
};
