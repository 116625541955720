var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "image-wrapper" }, [
    _vm.isLogoLoading
      ? _c("div", { staticClass: "loading-wrapper" }, [
          _c("div", { staticClass: "loading-overlay" })
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("img", {
      style: { objectFit: _vm.fit },
      attrs: {
        alt: _vm.alt,
        src: _vm.url,
        width: _vm.width,
        height: _vm.height
      },
      on: { load: _vm.onLogoLoaded, error: _vm.onLogoError }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }