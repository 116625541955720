import ExpressionConcat from '../string/concat';

export default {
	parameters(options) {
		return [
			this.eval(options.separator),
			this.eval(options.argument)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.separator),
			await this.evalAsync(options.argument)
		];
	},
	evaluate(separator, items) {
		return ExpressionConcat.evaluate(separator, items);
	}
};
