<template>
	<div class="userProfileMenu-wrapper">
		<div class="user-profile-component" :disabled="enable" :class="isAnonymous && 'anonymous'">
			<div class="component-header" v-if="isAnonymous">
				<button @click="redirectToSignIn">Sign In</button>
			</div>
			<div class="component-header" v-if="!isAnonymous">
				<div class="user-info-wrapper" @click="openProfilePopup">
					<div class="profile-image">
						<svg class='svg-icon' v-if="!userProfilePhoto">
							<use :xlink:href="'#layout-icons-' + profileIconDefault" :class="profileIconDefault" class=''></use>
						</svg>
						<img v-if="userProfilePhoto" :src="userProfilePhoto" class="image-node" />
					</div>
					<div class="user-name">{{ fullName }}</div>
					<div class="openbtn-wrapper" :class="menuState">
						<svg class='svg-icon'>
							<use xlink:href="#layout-icons-ms-triangle-down"></use>
						</svg>
					</div>
				</div>
			</div>
			<div class="user-profile-popup-wrapper" :class="menuState">
				<div class="user-profile-popup" v-perfectscroll="{ enable: true }">
					<div class="content">
						<menu-userprofile-content	:currentLanguage="currentLanguage"
													currentCurrency="currentCurrency"
													:currentSom="currentSom"
													cssClass="mobile"/>
						<div class="version-info-mobile">
							<div class="versions">
								<div class="version-title h2">{{versionTitle}}</div>
								<div class="version-item" v-for="(version,index) in versions" :key="index">
									<span class="label p4">{{version.label + ''}} </span>
									<span class="value h5">{{version.value}}</span>
								</div>
							</div>
							<div class="loading-overlay large" v-if="isLoading">
							</div>
						</div>

					</div>
					<div class="footer">
						<div class="bunttons-container">
							<div class="signout-component">
								<a class="signout-button button clickable" @click="logout">
									<i class="layout-icons-signout"></i>
									<span>Sign Out</span>
								</a>
							</div>
							<slot></slot>
						</div>
						<div class="powered-by">
							<span class="label p1">Powered by</span>
							<span class="value p2"><a target="_blank" href="https://ascendix.com">Ascendix Technologies</a></span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import MenuUserprofileMixin from './menu-userprofile.mixins';
import MenuUserprofileContent from './menu-userprofile-content';
export default {
	name: 'mobile-menu-userprofile',

	mixins: [MenuUserprofileMixin],

	data() {
		return {
			isLoading: false,
			versions: []
		};
	},

	components: { MenuUserprofileContent },

	methods: {
		async openProfilePopup() {
			this.toggleMenu();

			await this.getVersionInfo();

			if (this.menuState === 'expanded') {
				this.outsideClickListener = $('body').on('click', (event) => {
					if (!$(event.target).closest('.user-profile-component').length) {
						this.closeMenu();
						this.outsideClickListener.off();
					}
				});
			}
		},

		async getVersionInfo() {
			this.isLoading = true;

			const localizationDataProvider = sc.classes.get('localization.dataProvider');

			this.versionTitle = await localizationDataProvider.getLabelForCurrentLanguage('dialogues.versionInfo.dialog.title');

			this.versions = [
				{
					label: await localizationDataProvider.getLabelForCurrentLanguage('dialogues.versionInfo.ms'),
					value: (sc.cluster.version || {}).Version
				},
				{
					label: await localizationDataProvider.getLabelForCurrentLanguage('dialogues.versionInfo.sc.configuration', [sc.cluster.configKey]),
					value: sc.cluster.configVersion
				}
			];

			this.isLoading = false;
		}
	},

	created() {
		this.versionTitle = '';
	}
};
</script>
<style src="./mobile-menu-userprofile.less" scoped></style>
