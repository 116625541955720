const queries = {};
const sendSearch = (key, switchOffNestedCollectionType, selectedFieldsSectionType) => {
	sc.classes.get('edge.dataProvider').search({
		filters: Object.values(queries[key].filters),
		entities: [queries[key].logicalName],
		switchOffNestedCollectionType,
		selectedFieldsSectionType
	}).done((response) => {
		if (queries[key]) {
			queries[key].promise.resolve(response);
			setTimeout(() => { delete queries[key]; }, 1000);
		} else {
			console.warn('Query key "' + key + '" does not exist');
		}
	});
};
const debouncedDict = {};

const debouncedSearch = (key, switchOffNestedCollectionType, selectedFieldsSectionType, callback) => {
	if (!debouncedDict[key]) {
		debouncedDict[key] = setTimeout(() => {
			delete debouncedDict[key];
			callback(key, switchOffNestedCollectionType, selectedFieldsSectionType);
		}, 10);
	}
};
export default function ProcessQuery(logicalName, filters, switchOffNestedCollectionType, selectedFieldsSectionType) {
	// we need this to distinguish lots of automatic searches and initiated by user
	const key = logicalName + new Date().getSeconds();
	if (!queries[key]) {
		queries[key] = {
			logicalName,
			promise: $.Deferred(),
			filters: {}
		};
	}
	const entry = queries[key].filters;
	const mergeFilters = (filters) => {
		for (let i = 0; i < filters.length; i++) {
			// we do not need to merge from yet, while not implemented
			if (['From', 'ResultsFormat'].indexOf(filters[i].requestLabel) >= 0) {
				entry[filters[i].requestLabel] = filters[i];
				continue;
			}

			if (filters[i].requestLabel === 'Size') {
				if (!entry[filters[i].requestLabel]) {
					entry[filters[i].requestLabel] = filters[i];
				} else {
					entry[filters[i].requestLabel].data += filters[i].data;
				}
				continue;
			}

			if (filters[i].requestLabel === 'SelectedFields') {
				// selected fields concatenation
				if (!entry.SelectedFields) {
					entry.SelectedFields = filters[i];
				} else {
					entry.SelectedFields.data.fields = {
						...entry.SelectedFields.data.fields,
						...filters[i].data.fields
					};
				}
				continue;
			}

			/* add ID filter */
			if (filters[i].requestLabel === 'Ids') {
				filters[i].data = filters[i].data.filter(filter => filter !== '00000000-0000-0000-0000-000000000000'); // filter empty GUID | TODO: investigate this issue if it repeats
				if (entry[filters[i].requestLabel]) entry[filters[i].requestLabel].data.concat(filters[i].data);
				else entry[filters[i].requestLabel] = filters[i];
			}

			/* filters with data */
			const filterName = filters[i].data.logicalName || filters[i].data.Name;
			if (filterName) {
				if (filters[i].requestLabel === 'SelectedTermFacets') {
					entry[`selected.${filterName}`] = filters[i];
				} else {
					// Term facet filters
					if (!entry[filterName]) entry[filterName] = filters[i];
					else {
						// implemeted for facet like filters, do not know if we can do it for the rest
						if (filters[i].data.items) {
							filters[i].data.items.forEach((item) => {
								if (entry[filterName].data.items && entry[filterName].data.items.indexOf(item) < 0) {
									entry[filterName].data.items.push(item);
								}
							});
						}
					}
				}
			}
		}
	};
	mergeFilters(filters);
	debouncedSearch(key, switchOffNestedCollectionType, selectedFieldsSectionType, sendSearch);
	return queries[key].promise.promise();
}
