var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.results.length > 0 && _vm.visible
    ? _c("div", { staticClass: "component-innersearch-results" }, [
        _c(
          "div",
          { staticClass: "header" },
          [
            _vm.content
              ? _c("results-toggle", {
                  attrs: {
                    namespace: _vm.namespace,
                    internalNamespace: _vm.internalNamespace,
                    toggleNamespace: _vm.toggleNamespace,
                    initCollapsed: _vm.collapsed,
                    isParent: false
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "title", class: { "counter-only": !_vm.content } },
              [
                _vm._v(
                  _vm._s(
                    _vm.results.length > 1 && _vm.pluralTitle
                      ? _vm.pluralTitle
                      : _vm.title
                  )
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "counter" }, [
              _vm._v(_vm._s(_vm.results.length))
            ]),
            _vm._v(" "),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "loading-overlay"
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.content
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.collapsed,
                    expression: "!collapsed"
                  }
                ],
                staticClass: "content-wrapper"
              },
              [
                _c("div", { staticClass: "content" }, [
                  _c(_vm.prepareComponentName(_vm.content.$type), {
                    tag: "div",
                    staticClass: "content-inherit-height inner-search-results",
                    attrs: {
                      options: _vm.content,
                      stateNamespace: _vm.stateNamespace,
                      cssClass: "inner-search-results",
                      evaluationContext: _vm.evaluationContext,
                      results: _vm.results
                    }
                  })
                ])
              ]
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }