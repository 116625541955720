<template>
	<div class="action-menu-wrapper inline-block">
		<div class="action-menu">
			<div	v-if="shareActions && shareActions.length"
					v-data-attr
					:class="['dropdown-toggle', 'share-actions',
					shareVisible,
					shareActions.length ? 'enabled' : 'disabled',
					{ clickable: shareActions.length }
				]"
				@click.prevent="toggleMenu($event, 'shareVisible', 'shareActions')">
				<svg class="svg-icon">
					<use class="action-icons-ms-entity-share" xlink:href="#action-icons-ms-entity-share" />
				</svg>
			</div>
			<div v-if="menuItemsToolbar && menuItemsToolbar.length" class="toolbar-items">
				<MenuItem	v-for="item in menuItemsToolbar"
							:key="getKey(item)"
							:options="item.menuItem"
							:evaluationContext="evaluationContext"
							@click.native="executeCallback(item.menuItem.action)" />
			</div>
			<div	v-if="hasDropDownItems"
					v-data-attr
					:class="['dropdown-toggle',
					dropdownVisible,
					menuItemsDropdown.length && enable ? 'enabled' : 'disabled',
					{ clickable: menuItemsDropdown.length }
				]"
				@click.prevent="toggleMenu($event, 'dropdownVisible', 'menuItemsDropdown')">
				<svg class="svg-icon">
					<use class="layout-icons-ms-actions-toggle" xlink:href="#layout-icons-ms-actions-toggle" />
				</svg>
			</div>
		</div>
	</div>
</template>
<script>
import '@/Bindings/ui-attribute.directive';
import MenuItem from './Items/menu-item';
import HelperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import { generateId } from '@acx-xms/data-functions/dist';

export default {
	name: 'action-menu-base',
	props: {
		evaluationContext: Object,
		menuItemsToolbar: Array,
		menuItemsDropdown: Array,
		hasDropDownItems: Boolean,
		combinedNamespace: String,
		enable: {
			type: Boolean,
			default: true
		},
		shareActions: Array
	},
	components: { MenuItem },
	data() {
		return {
			dropdownVisible: null,
			shareVisible: null
		};
	},
	created() {
		// need this to  handle unique calling place for absolute popup
		this.key = HelperMethods.getRandomArbitary(1, 10000000000000000).toFixed();
		this.$root.$on('absoluteMenu.closed', this.absoluteMenuClosedCallback);
	},
	beforeDestroy() {
		this.$root.$off('absoluteMenu.closed', this.absoluteMenuClosedCallback);
	},
	methods: {
		toggleMenu(event, menuVisible, menuType) {
			if (!this.menuItemsDropdown.length || !this.enable) return;
			this.$root.$emit('absoluteMenu.open', event, this.key, this[menuType], this.evaluationContext, this.executeCallback);
			this.$root.$once(`${this.combinedNamespace}.scrolled`, () => {
				this[menuVisible] = 'collapsed';
				this.$root.$emit(`${this.key}.absoluteMenu.close`);
			});
			this[menuVisible] = 'expanded';
		},
		executeCallback(action) {
			this.$emit('execute', action);
		},
		absoluteMenuClosedCallback() {
			this.dropdownVisible = this.shareVisible = 'collapsed';
		},
		getKey() {
			return generateId();
		}
	}
};
</script>
<style src="./action-menu.less" scoped></style>
