export default {
	parameters(options) {
		return [
			this.eval(options.argument),
			this.eval(options.first),
			this.eval(options.last),
			this.eval(options.splitFullname)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument),
			await this.evalAsync(options.first),
			await this.evalAsync(options.last),
			await this.evalAsync(options.splitFullname)
		];
	},
	evaluate(argument, first, last, splitFullname = false) {
		if (!argument) {
			return '';
		}
		if (!first) {
			first = 0;
		}
		if (splitFullname && argument.split(' ').length === 2) {
			const name = argument.split(' ');
			return `${name[0][0]}${name[1][0]}`;
		}
		return argument.substring(first, last);
	}
};
