import {
	resourceIsRichable, Search, Create, Update
} from '@acx-xms/data-functions/dist';
import {
	authenticate as authenticateMCI, getMCIUrlSwagger, getMCIUserInfo
} from '@/Data/DataProviders/coMCIDataprovider';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import { GetPerConfig } from '@/Data/DataProviders/userStorageDataProvider';
import coAuthDataProvider from '@/Data/DataProviders/coAuthDataprovider';
import { mapActions, mapGetters } from 'vuex';
export const mciAuthMixin = {
	data() {
		return {
			isLoading: true,
			isInitialization: false,
			isInitializationMCI: false,
			userInfo: '',
			mciUser: '',
			systemuserserviceaccount: '',
			isServerError: false
		};
	},
	watch: {
		systemuserserviceaccount(newVal) {
			if (newVal) this.updateSystemUser(newVal);
		},
		getMCUserID(value) {
			if (!value) this.mciAuthorization(true);
			else this.processMCIUser();
		}
	},
	computed: { ...mapGetters('wizard', ['getMCUserID']) },
	methods: {
		...mapActions('wizard', ['updateMcUser', 'updateSystemUser', 'updateForceExit']),
		async mailChimpAuthorization() {
			this.isLoading = true;

			const resourcesPromiseArr = [
				resourceIsRichable(await coAuthDataProvider.getCOAuthURLSwagger()),
				resourceIsRichable(await getMCIUrlSwagger())
			];
			const [coAuthIsRichable, mciAuthIsRichable] = await Promise.all(resourcesPromiseArr);
			if (!coAuthIsRichable || !mciAuthIsRichable) {
				this.isServerError = true;
				this.isLoading = false;
				return;
			};

			this.userInfo = await GetUserInfo();

			let token = await GetPerConfig('co-auth-key');
			if (token) {
				await this.authorization(true);
			} else {
				this.isInitialization = true;
				await this.coAuthorization(true).then((res) => {
					this.modalAuthenticate(res);
				});
			}

			window.onstorage = async () => {
				this.isInitialization = false;
				const newToken = await GetPerConfig('co-auth-key');
				if (newToken && token !== newToken) {
					token = newToken;
					await this.authorization(true);
				} else if (newToken === 'undefined') {
					this.isServerError = true;
					this.isLoading = false;
					window.onstorage = null;
				}
			};
		},
		async coAuthorization(onlyUserGet = false) {
			const res = await Search('systemuserserviceaccount', [
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('termFacet.filter', {
					logicalName: 'systemuserid.id',
					query: [this.userInfo.systemuserid]
				}).fillQuery(),
				// TODO to move guid to the package in the nearest future
				sc.classes.get('termFacet.filter', {
					logicalName: 'serviceaccounttype.id',
					query: ['b08a0bf8-d86b-4a16-b3c8-65846f74ffcc']
				}).fillQuery(),
				sc.classes.get('termFacet.filter', {
					logicalName: 'inuse',
					query: [true]
				}).fillQuery()
			]);

			if (res.Total) this.systemuserserviceaccount = res.Results[0];
			if (onlyUserGet) return !this.systemuserserviceaccount;
			await this.mciAuthorization();
		},
		async mciAuthorization(force = false) {
			if (force) {
				this.mciUser = '';
				this.systemuserserviceaccount = '';
				this.isInitializationMCI = false;
				this.isServerError = false;
				this.isLoading = false;
			} else {
				// this.isInitializationMCI = true;
				if (this.systemuserserviceaccount && this.mciUser) {
					await this.getMCDomains();
					return Promise.resolve();
				}
			}

			try {
				const token = await GetPerConfig('co-auth-key');
				if (!token) throw Error;
				this.mciUser = JSON.parse(await authenticateMCI());
				this.updateMcUser(JSON.parse(await authenticateMCI()));
				await this.processMCIUser();
				this.isInitializationMCI = false;
				this.isServerError = false;
				this.isLoading = false;
			} catch (e) {
				this.isLoading = true;
				this.isInitializationMCI = true;
				await this.modalAuthenticate(true);
			}
		},
		async processMCIUser() {
			if (this.systemuserserviceaccount) {
				return Promise.resolve();
			}

			if (!this.mciUser) {
				this.mciUser = await getMCIUserInfo();
				this.updateMcUser(await getMCIUserInfo());
			}
			const res = await Search('systemuserserviceaccount', [
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('termFacet.filter', {
					logicalName: 'serviceaccountuserid',
					query: [this.mciUser.mcUserId]
				}).fillQuery(),
				sc.classes.get('termFacet.filter', {
					logicalName: 'systemuserid.id',
					query: [this.mciUser.userId]
				}).fillQuery(),
				sc.classes.get('termFacet.filter', {
					logicalName: 'serviceaccounttype.id',
					query: ['b08a0bf8-d86b-4a16-b3c8-65846f74ffcc']
				}).fillQuery()
			]);

			if (res.Total) {
				if (res.Results[0].Source.inuse) {
					this.systemuserserviceaccount = res.Results[0];
				} else {
					this.systemuserserviceaccount = await Update('systemuserserviceaccount', res.Results[0].Id, { inuse: true });
				}
			} else {
				this.systemuserserviceaccount = await Create('systemuserserviceaccount', {
					name: this.mciUser.mcName || this.userInfo.firstname + this.userInfo.lastname,
					systemuserid: {
						id: this.mciUser.userId,
						logicalname: 'systemuser'
					},
					serviceaccountuserid: this.mciUser.mcUserId,
					email: this.mciUser.email,
					firstname: this.userInfo.firstname,
					lastname: this.userInfo.lastname,
					serviceaccounttype: {
						id: 'b08a0bf8-d86b-4a16-b3c8-65846f74ffcc', // MailChimp
						logicalname: 'lookupserviceaccounttype'
					},
					inuse: true
				});
			}
		},
		async modalAuthenticate(isWithMci) {
			await coAuthDataProvider.authenticate(`${window.location.origin}/${this.$route.params.clusterKey}/${this.$route.params.config}/coauth`, isWithMci)
				.then(async (res) => {
					/* if returned with prop - reject promise */
					const token = await GetPerConfig('co-auth-key');
					if (token) {
						this.mciUser = JSON.parse(
							await authenticateMCI()
								.then((result) => {
									this.updateForceExit(false);
									return result;
								}).catch(() => {
									this.updateForceExit(true); /* force exit after login failure */
									throw Error();
								})
						);
						this.updateMcUser(this.mciUser);
						this.$store.commit('wizard/UPDATE_EC_PROCESS_STATUS', false);
						await this.getMCDomains();
					}
				})
				.catch(e => {
					if (e.message === 'pop-ups are blocked') {
						this.$parent.$emit('close');
						sc.events.emit('vue.dialog.info', {
							title: 'Start Email Campaign',
							message: 'Please, allow pop-ups on in the browser\'s settings and re-open the window to continue',
							showFooter: false
						});
					}
				})
				.finally(() => {
					this.isLoading = false;
					this.isInitializationMCI = false;
					this.isInitialization = false;
				});
		},
		async refreshToken() {
			try {
				await coAuthDataProvider.refreshToken();
				return true;
			} catch {
				await this.coAuthorization();
				return false;
			}
		},
		async authorization(refresh) {
			await this.mciAuthorization().catch(async err => {
				if (refresh && (err.toString() === 'Error: JWT token is absent or invalid' || err.message === 'JWT token is absent or invalid')) {
					this.isInitialization = true;
					const refreshSuccesfull = await this.refreshToken();
					refreshSuccesfull && await this.mciAuthorization();
				} else {
					this.isServerError = true;
					this.isLoading = false;
				}
			});
		}
	}
};
