var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ComponentSetMobileSearchResultsTableView", {
    class: { "mobile-table-view": true },
    attrs: {
      options: _vm.options,
      results: _vm.results,
      paginationParams: _vm.paginationParams,
      stateNamespace: _vm.stateNamespace
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }