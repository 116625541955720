import { isActive } from './Methods/action.methods';

export default {
	async enabled(o, evaluationContext, selection) {
		return await isActive(evaluationContext, selection);
	},
	async execute(options, context, selection, fullSelection) {
		const sel = selection || context.eval(options.selection);
		let entity;
		if (sel) {
			entity = sc.classes.get('entityReference', sel[0]);
		}
		const title = await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('AddNDA.dialogTitle.dialogTitle');
		const openDialog = () => {
			sc.events.emit('vue.dialog.open', {
				component: 'dealroom.add.nda',
				evaluationContext: context,
				logicalname: 'dealroom',
				maximizedWidth: '75%',
				maximizedHeight: '100%',
				title,
				refreshEvent: options.refreshEvent,
				editorTemplate: options.editorTemplate,
				params: {
					entity,
					...options
				},
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey)
			});
		};
		openDialog();
	}
};
