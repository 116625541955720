<template>
	<div class="dialog-manager">
		<div class="dialogues-area">
			<old-dialog-wrapper :initDialog="dialog" v-on:set-active="setActiveById($event)" v-for="dialog in dialogues" :key="dialog.id" v-if="isMounted"></old-dialog-wrapper>
		</div>
	</div>
</template>
<script>
const OldDialogWrapper = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './old-dialog-wrapper');
export default {
	// This component manages collection of dialogs. It's responsible for open|close|setActive and similar dialog level behavior
	name: 'old-dialog-manager',
	props: { options: Object },
	components: { OldDialogWrapper },
	data() {
		return {
			dialogues: [],
			dialogViewModel: 'dialog.viewModel',
			dialogInfoOptions: {},
			dialogErrorOptions: {}
		};
	},
	methods: {
		setActiveById(dialogId) {
			if (!dialogId) { return false; }

			const dialog = this.dialogues.filter(d => {
				return d.id === dialogId;
			});

			if (!dialog || dialog.length !== 1) {
				return false;
			}

			return this._setActive(dialog[0].vm);
		}
	},
	created() {
		const self = this;
		this.fork = sc.events.fork();

		this._getDialogShouldBeSkipped = (options) => {
			return self._getDialogIsDisablsed(options);
		};

		this._doOpen = (options) => {
			const newDialog = sc.classes.get(self.dialogViewModel, options);

			newDialog.close = () => {
				self._close(newDialog);
			};
			newDialog.updateRoute = void 0;

			this.dialogues.push(
				{
					id: newDialog.id,
					vm: newDialog
				}
			);

			options.onOpen && options.onOpen(newDialog);

			self._setActive(newDialog);
		};

		this._open = (options) => {
			if (options.params && options.params.canBeDisabled && !options.params.dialogId) {
				throw new Error('the dialogId option must be assigned for dialogs that can be disabled');
			}

			$.when(self._getDialogShouldBeSkipped(options)).then(function (shouldBeSkipped) {
				if (!shouldBeSkipped) {
					if (options.params && options.params.canBeDisabled) {
						sc.classes.get('dialog.stateManager').getState(options.params.dialogId).then(function (state) {
							options.params.doNoShowAgain = state.disabled;
							self._doOpen(options);
						});
					} else {
						self._doOpen(options);
					}
				}
			});
		};

		this.localize = (key, args) => sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage(key, args);

		this._close = (dialog) => {
			dialog.onClose && dialog.onClose(dialog);

			self.dialogues = self.dialogues.filter(item => {
				return dialog.id && item.vm.id !== dialog.id;
			});

			if (self.dialogues.length > 0) {
				self._setActive(self.dialogues[self.dialogues.length - 1].vm);
			}
		};

		this._getDialogIsDisablsed = (options) => {
			if (options.params && options.params.dialogId) {
				return sc.classes.get('dialog.stateManager').getState(options.params.dialogId).then(function (state) {
					return ko.unwrap(state.disabled);
				});
			} else {
				return false;
			}
		};

		this._setActive = (dialog) => {
			if (!dialog.active()) {
				self.dialogues.forEach(d => {
					d.vm.active(false);
				});
				dialog.active(true);
			}
			return true;
		};

		this.fork.on('dialog.open', self._open);

		// todo maybe we need to use dialog.info component with custom image for this functionality
		this.fork.on('dialog.error', (eventArgs) => {
			eventArgs.image = 'layout-icons-dialog-error';

			const component = eventArgs.errorReport // Added for backward compatibility if error dialog was opened now from SC Core code
				? 'dialog.error'
				: 'dialog.info';

			const options = {
				...{
					title: eventArgs.title,
					localizedTitle: eventArgs.localizedTitle,
					icon: eventArgs.icon,
					component,
					message: '',
					allowMinimize: false,
					allowPin: false,
					maximizedWidth: '400px',
					onClose: eventArgs.onClose,
					params: eventArgs
				},
				...self.dialogErrorOptions
			};

			self._open(options);
		});

		this.fork.on('dialog.info', (eventArgs) => {
			const options = {
				...{
					title: eventArgs.title,
					icon: eventArgs.icon,
					component: 'dialog.info',
					message: '',
					allowMinimize: false,
					allowPin: false,
					maximizedWidth: '400px',
					params: eventArgs
				},
				...self.dialogInfoOptions
			};

			self._open(options);
		});

		this.fork.on('dialog.confirm', async (msg, yesCallback, noCallback, closeCallback, allowClose, customYesKey, customNoKey, titleKey) => {
			const options = {
				localizedTitle: titleKey || 'common.dictionary.confirmDlgCaption',
				allowMinimize: false,
				allowMaximize: false,
				allowPin: false,
				allowClose: allowClose === void 0 ? true : allowClose,
				component: 'dialog.info',
				onClose: closeCallback,
				params: {
					message: await self.localize(msg),
					buttons: [
						{
							title: await self.localize(customYesKey || 'common.dictionary.yes'),
							callback: yesCallback
						},
						{
							title: await self.localize(customNoKey || 'common.dictionary.no'),
							callback: noCallback,
							isCancelBtn: true
						}
					]
				}
			};

			self._open(options);
		});

		const _notifyReadyState = () => {
			self.fork.emit('dialogManager.ready');
		};

		_notifyReadyState();

		this.$on('closeDialog', (id) => {
			const index = self.dialogues.findIndex((item) => {
				return item.id === id;
			});
			this.dialogues.splice(index, 1);

			if (this.dialogues.length > 0) {
				// self._setActive(self.dialogues[self.dialogues.length - 1].vm);
				this.dialogues[this.dialogues.length - 1].active = true;
			}
		});
	},
	mounted() {
		this.isMounted = true;
	},
	beforeDestroy() {
		this.fork.dispose();
	}
};
</script>
<style src="./dialog-manager.less" scoped></style>
