export default {
	parameters(options) {
		return [options.item.map(this.eval)];
	},
	async parametersAsync(options) {
		return [await Promise.all(options.item.map(await this.evalAsync))];
	},
	evaluate(items) {
		return items;
	}
};
