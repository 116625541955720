import Store from '@/States/ms-state';
import { THEME_FIELDS } from '@/Data/selected-fields';
import { colorHandler } from '@acx-xms/data-functions/dist';

const logicalName = 'theme';
let _cache = null;

function _applyColors(theme) {
	const themeData = theme && theme.Source;
	if (themeData) {
		Object.keys(themeData).forEach((key) => {
			if (/^color\d+$/.test(key)) {
				document.documentElement.style.setProperty('--' + key, '#' + themeData[key]);
			}
		});

		Object.keys(document.documentElement.style).forEach((key) => {
			if ((/__darken|__lighten/).test(document.documentElement.style[key])) {
				const colorName = document.documentElement.style[key];
				const pureColorName = colorName.split('__')[0];
				const colorValue = document.documentElement.style.getPropertyValue(pureColorName);
				colorHandler(colorName, colorValue);
			}
		});
	} else {
		throw new Error('Apply colors: theme is invalid.');
	}
}
async function _getTheme(filters) {
	const edgeDataProvider = sc.classes.get('edge.dataProvider');
	const additionalFilters = filters || [];
	let results;
	const selectedFields = THEME_FIELDS.map(field => ({ logicalname: field }));
	try {
		results = await edgeDataProvider.search({
			filters: [
				...additionalFilters,
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('selectedFields.filter', selectedFields)
			],
			entities: [logicalName]
		});
	} catch (error) {
		sc.utils.errorMessage.byResponse(error);
	}

	const theme = results.Results[0];
	_cache = theme;

	return theme;
}
function _applyLogo(theme) {
	Store.commit('changeTenantLogo', {
		logobigid: theme.Source.logobigid,
		logosmallid: theme.Source.logosmallid,
		logoanonymousid: theme.Source.logoanonymousid
	});
}
export default {
	getCurrentTheme(force) {
		if (_cache !== null && !force) {
			return _cache;
		}

		return _getTheme([
			sc.classes.get('termFacet.filter', {
				logicalName: 'active',
				query: [true]
			})
		]);
	},
	async previewTheme(id) {
		_cache = null;
		const theme = await _getTheme([sc.classes.get('ids.filter', [id])]);
		_applyColors(theme);
		_applyLogo(theme);
	},
	async applyCurrentTheme() {
		const theme = await this.getCurrentTheme(true);
		if (!theme) {
			return;
		}
		_applyColors(theme);
		_applyLogo(theme);
	},
	async applyTheme(id) {
		const edgeDataProvider = sc.classes.get('edge.dataProvider');
		try {
			await edgeDataProvider.update(logicalName, id, { active: true });
			this.previewTheme(id);
		} catch (error) {
			sc.utils.errorMessage.byResponse(error);
		}
	}
};
