<template>
	<div class="logout-wrapper">
		<div class="message-wrapper">
			<p class="text">
				To complete sign out of your account,
				please close all browser windows.
			</p>
		</div>
	</div>
</template>
<script>
export default { name: 'logout-wrapper' };
</script>
<style src="./logout-wrapper.less" scoped></style>
