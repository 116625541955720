import { isString } from '@acx-xms/data-functions/dist';

export default {
	parameters(options) {
		return [
			this.eval(options.value),
			this.eval(options.items),
			options.ignoreCase
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.value),
			await this.evalAsync(options.items),
			options.ignoreCase
		];
	},
	evaluate(value, items, ignoreCase) {
		if (!isString(value)) {
			value = value.toString();
		}
		if (ignoreCase) {
			value = value.toLowerCase();
		}
		return items.some(item => {
			if (ignoreCase) {
				return value.endsWith(item.toLowerCase());
			}
			return value.endsWith(item);
		});
	}
};
