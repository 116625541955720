import Vue from 'vue';
import Vuex from 'vuex';
import { Deferred } from '@acx-xms/data-functions/dist';

import { GetUserInfo } from '@/Data/Auth/authentication-service';
const SPECIAL = {
	created_by_current_user: (value) => {
		return {
			requestLabel: 'TermFacetFilters',
			data: {
				logicalName: 'createdby.id',
				items: [value],
				negative: false
			}
		};
	}
};

function handleSpecialFilter(filters) {
	const specialKeys = Object.keys(SPECIAL);
	Object.values(filters).forEach(async filter => {
		if (Array.isArray(filter)) {
			const index = filter.findIndex(el => specialKeys.includes(el));
			if (index > -1) {
				const user = await GetUserInfo();
				const value = SPECIAL[filter[index]](user.Id);
				filter.splice(index, 1);
				filter.push(value);
			}
		}
	});
	return filters;
}

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		initMessage: null,
		errorMessage: null,
		logobigid: null,
		logosmallid: null,
		logoanonymousid: null,
		defaultMenuItemPath: '',
		menuPopupKey: '',
		configTemplates: null,
		previewTemplates: {
			listing: [],
			availability: [],
			contact: []
		},
		isPushNotificationsEnabled: false,
		paginationState: {},
		userChatParticipants: [],
		initFilters: {},
		clientServerTimeDelta: null,
		roomToUpdate: '',
		processingIds: {}
	},
	mutations: {
		changeInitMessage(state, message) {
			state.initMessage = message;
		},
		changeErrorMessage(state, message) {
			state.initMessage = '';
			state.errorMessage = message;
		},
		changeTenantLogo(state, logos) {
			state.logosmallid = logos.logosmallid;
			state.logobigid = logos.logobigid;
			state.logoanonymousid = logos.logoanonymousid;
		},
		changeDefaultMenuItemPath(state, defaultMenuItemPath) {
			state.defaultMenuItemPath = defaultMenuItemPath;
		},
		changeMenuPopupKey(state, menuPopupKey) {
			state.menuPopupKey = menuPopupKey;
		},
		changeConfigTemplates(state, configTemplates) {
			state.configTemplates = configTemplates;
		},
		addPreviewConfigTemplate(state, template) {
			const previewArr = state.previewTemplates;
			const sameTypeTemplateIndex = previewArr[template.entity].findIndex(item => item.templatetype === template.templatetype);
			sameTypeTemplateIndex === -1 ? previewArr[template.entity].push(template) : previewArr[template.entity][sameTypeTemplateIndex] = template;
			state.previewTemplates = previewArr;
		},
		removePreviewConfigTemplate(state, { entity, templatetype }) {
			let previewArr = state.previewTemplates[entity];
			previewArr = previewArr.filter(item => item.templatetype !== templatetype);
			Vue.set(state.previewTemplates, entity, previewArr);
		},
		changeIsPushNotificationsEnabled(state, value) {
			state.isPushNotificationsEnabled = value;
		},
		setPaginationState(state, { route, value }) {
			state.paginationState[route] = value;
		},
		setUserChatParticipants(state, value) {
			state.userChatParticipants = value;
		},
		setInitFilters(state, filters) {
			const sp = Object.keys(SPECIAL);
			const eq = Object.values(filters).some(filter => filter.filter(el => sp.includes(el)));
			if (eq) filters = handleSpecialFilter(filters);
			state.initFilters = filters;
		},
		setClientServerTimeDelta(state, value) {
			state.clientServerTimeDelta = value;
		},
		SET_ROOM_TO_UPDATE(state, value) {
			state.roomToUpdate = value; 
		},
		addProcessingId(state, { logicalName, id }) {
			if (!state.processingIds[logicalName]) {
				state.processingIds[logicalName] = [];
			}
			state.processingIds[logicalName].push(id);
		},
		removeProcessingId(state, { logicalName, id }) {
			if (!state.processingIds[logicalName]) {
				return;
			}
			state.processingIds[logicalName] = state.processingIds[logicalName].filter(i => i !== id);
		}
	},
	getters: {
		// TODO: remove getters that only return state value cause they are redundant
		initMessage: state => state.initMessage,
		logobigid: state => state.logobigid,
		logosmallid: state => state.logosmallid,
		logoanonymousid: state => state.logoanonymousid,
		defaultMenuItemPath: state => state.defaultMenuItemPath,
		menuPopupKey: state => state.menuPopupKey,
		configTemplates: state => state.configTemplates,
		previewTemplates: state => state.previewTemplates,
		isPushNotificationsEnabled: state => state.isPushNotificationsEnabled,
		getPaginationState(state) {
			return (route) => {
				return state.paginationState[route];
			};
		},
		сonversationMenuInit() {
			return {
				promise: сonversationMenuInitDef.promise,
				resolve: сonversationMenuInitDef.resolve
			};
		},
		serverTimeInit() {
			return {
				promise: serverTimeInitDef.promise,
				resolve: serverTimeInitDef.resolve
			};
		},
		getUserChatParticipants: state => state.userChatParticipants,
		getRoomToUpdateByID: state => state.roomToUpdate,
        getProcessingIds: state => logicalName => state.processingIds[logicalName]
	},
	actions: {
		setRoomToUpdate({ commit }, data) {
			commit('SET_ROOM_TO_UPDATE', data);
		}
	}
});

const сonversationMenuInitDef = Deferred();
const serverTimeInitDef = Deferred();
