<template>
	<!--seems like here we don't need stopPropagation that was added in #96789-->
	<div
		v-data-attr
		class="checkbox-wrapper"
		@click.prevent="clickHandler"
	>
		<div :class="['virtual-checkbox clickable', { selected }, checkboxState, cssClass]">
			<svg class="svg-icon">
				<use
					v-if="checkboxState === 'partiallySelected' && !selected"
					xlink:href="#action-icons-selection-checked-partially"
					class="action-icons-selection-checked-partially"
				/>
				<use
					v-else
					xlink:href="#layout-icons-ms-checkbox"
					class="layout-icons-ms-checkbox"
				/>
			</svg>
		</div>
	</div>
</template>
<script>
import '@/Bindings/ui-attribute.directive';

export default {
	name: 'virtual-checkbox',
	props: {
		stateNamespace: String,
		record: Object,
		isParent: Boolean,
		evaluationContext: Object,
		syncSelection: Boolean,
		singleSelection: Boolean,
		cssClass: {
			type: String,
			default: ''
		}
	},
	computed: {
		selected() {
			return this.stateNamespace && !!this.$store.getters[`${this.stateNamespace}/isRecordSelected`](this.record);
		},
		checkboxState() {
			return this.$store.getters[`${this.stateNamespace}/isRecordPreselected`](this.record);
		}
	},
	created() {
		if (this.isParent) {
			this.$root.$on(`.syncSelection.child.${this.record.Id}`, () => {
				if (!this.selected) {
					this.$store.commit(`${this.stateNamespace}/changeSelection`, {
						records: [this.record],
						select: true
					});
				}
			});
		}

		this.$root.$on(`.syncSelection.selectParent.${this.record.Id}`, () => {
			!this.selected && this.clickHandler();
		});
	},
	beforeDestroy() {
		this.$root.$off(`.syncSelection.child.${this.record.Id}`);
		this.$root.$off(`${this.stateNamespace}.syncSelection.selectParent.${this.record.Id}`);
	},
	methods: {
		clickHandler() {
			if (this.checkboxState === 'readonlyPreselected') return;

			const selected = !this.selected;
			if (selected && this.singleSelection) {
				this.$store.commit(`${this.stateNamespace}/cleanSelection`);
			}
			this.$store.commit(`${this.stateNamespace}/changeSelection`, {
				records: [this.record],
				select: selected
			});

			if (this.syncSelection) {
				if (this.isParent) {
					this.$root.$emit(`${this.stateNamespace}.syncSelection.parent.${this.record.Id}`, selected);
				} else {
					if (selected) {
						this.$root.$emit(`.syncSelection.child.${this.evaluationContext.entity.id}`);
					} else {
						/* TODO: move from here */
						/* wizard special - unselect listing if all child availability unselected */
						if (this.stateNamespace === 'wizard.listing' && this.record.Type === 'availability') {
							const parentHasSelectedChild = !!this.$store.getters[`${this.stateNamespace}/getSelection`][this.record.Type].find(el => el.Source.listingid.id === this.evaluationContext.entity.id);
							if (!parentHasSelectedChild) {
								this.$store.commit(`${this.stateNamespace}/changeSelection`, {
									records: [this.evaluationContext.datasource],
									select: selected
								});
							}
						}
					}
				}
			}
		}

	}
};
</script>
<style src="./virtual-checkbox.less" scoped></style>
