var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "marketspace-menu" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.state === "iconsAndText",
            expression: "state === 'iconsAndText'"
          }
        ],
        staticClass: "user-profile-wrapper"
      },
      [
        _c("mobile-" + _vm.userProfileMenusMap[_vm.userProfileMenu.$type], {
          tag: "component",
          attrs: {
            isAnonymous: _vm.isAnonymous,
            params: { userProfileMenu: _vm.userProfileMenu }
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "items-container" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "perfectscroll",
              rawName: "v-perfectscroll",
              value: { enable: true },
              expression: "{ enable: true }"
            }
          ],
          staticClass: "items-wrapper"
        },
        [
          _vm._l(_vm.items, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "items",
                on: { click: _vm.toggleMenu }
              },
              [
                _c("route-menu-item-svg", {
                  class: _vm.state,
                  attrs: { options: item }
                })
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm.marketspaceHeaderMenu
            ? [
                _c("div", { staticClass: "separator" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "main-header-menu-wrapper" },
                  [
                    _c("menu-marketspace-header", {
                      attrs: { item: _vm.marketspaceHeaderMenu.item }
                    })
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }