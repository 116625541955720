<template>
	<control	class="formcontrol-template" v-if="visible && template"
				v-bind:name="template.$type"
				v-bind:contentProps.once="template"
				v-bind:evaluationContext="evaluationContext"></control>
	<p class="p13" v-else-if="!template">Please add template with an appropriate type</p>

</template>
<script>
import { getActiveTemplate } from '@/Data/DataProviders/templatesDataProvider';
export default {
	name: 'control-configtemplate',
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: { Control: () => import('./../Entity/control.vue') },
	data() {
		return {
			visible: this.options.visible !== void 0 ? this.evaluationContext.eval(this.options.visible) : true,
			template: null
		};
	},
	async created() {
		let entityName;
		if (this.options.entity) {
			entityName = this.options.entity;
		}
		if (!entityName) {
			entityName = this.evaluationContext.entity.logicalname;
		}
		this.template = await getActiveTemplate(entityName, this.options.type);
	}
};
</script>
