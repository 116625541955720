export default {
	async enabled(options, evaluationContext, selection) {
		return Boolean(selection.length);
	},
	async execute(options, context, selection, fullSelection) {
		const logicalNames = options.logicalName.map(logicalname => context.eval(logicalname));

		const filters = {};

		for (const logicalname in fullSelection) {
			filters[logicalname] = fullSelection[logicalname].map(sel => sel.id || sel.Id);
		}

		Object.keys(filters).forEach(logicalname => {
			filters[logicalname] = [sc.classes.get('ids.filter', filters[logicalname]).fillQuery()];
		});
		sc.events.emit('vue.dialog.open', {
			icon: '',
			title: 'Share Selected',
			component: 'share.search.results.dialog',
			maximizedWidth: options.dialogWidth || '450px',
			maximizedHeight: options.dialogHeight || '355px',
			logicalNames,
			evaluationContext: context,
			filters: JSON.stringify(filters),
			infoText: 'Copy this link to share search results list. Search results will be the same and won\'t change'
		});
	}
};
