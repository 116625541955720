<template>
	<div>
		<div v-if="!detailsProcess" :class="['email-campaign-root', { 'email-campaign-root-preview' : activeStepNumber === 5 && logged }]">
			<WizardHeader :loading="(isLoading || !logged)" :breadcrumbsOptions="breadcrumbsOptions" />
			<ErrorsManager v-if="logged && isErrors" />
			<div class="email-campaign-root-wrapper">
				<div v-if="emailCampaignProcess" class="reportingservice-overlay loading-overlay large"></div>
				<PreviewSidebar v-if="(activeStepNumber === 5 && logged)" />
				<div class="email-campaign-root-content">
					<div class="email-campaign-steps" :style="`height: calc(100vh - ${heightHandler}px)`">
						<template v-if="!isLoading">
							<transition v-if="logged" name="fade" mode="out-in">
								<component v-if="getStepName" :is="getStepName" />
								<div v-else>Loading...</div>
							</transition>
							<Error
								v-if="!logged"
								:text="error.text"
								:button="error.btn"
								class="login-error"
								@click="login"
							/>
						</template>
						<div v-if="isLoading || isInitialization || isInitializationMCI" class="loading-overlay">
							<div v-if="isInitialization">Your authorization to Composer Online is in progress. Please wait.</div>
							<div v-else-if="isInitializationMCI">Your authorization to MailChimp is in progress. Please wait.</div>
						</div>
						<div v-else-if="isServerError">
							<div>Couldn't sign in to Composer Online. Please try again later.</div>
						</div>
					</div>
					<WizardFooter v-if="(!isLoading && logged)" />
				</div>
			</div>
		</div>
		<div v-else class="loading-overlay details-overlay"></div>
	</div>
</template>

<script>
import { wizardMixin } from '../../Mixins/wizardMixin';
import { wizardValidation } from '../../Mixins/wizardValidation';
import { mciAuthMixin } from './helpers/mciAuthMixin';
import { getBaseRouteUrl } from '@acx-xms/data-functions/dist';
import {
	mapState, mapActions, mapGetters
} from 'vuex';
import Preview from './Steps/Preview/preview';
import WizardHeader from './Components/Header/header';
import WizardFooter from './Components/Footer/footer';
import PreviewSidebar from './Components/PreviewSidebar/previewSidebar';
import ErrorsManager from './Components/ErrorsManager/errorsManager';

const SetupCampaign = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'./Steps/SetupCampaign/setup-campaign');
const SelectCampaignListing = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'./Steps/SelectCampaignListing/select-campaign-listing.vue');
const SelectTemplate = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'./Steps/SelectTemplate/select-template.vue');
const SelectMarketingList = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'./Steps/SelectMarketingList/select-campaign-marketing-list.vue');
const Error = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'./Components/WizardError/wizardError');

export default {
	name: 'email-campaign-set',
	components: {
		Step1: SetupCampaign,
		Step2: SelectTemplate,
		Step3: SelectCampaignListing,
		Step4: SelectMarketingList,
		Step5: Preview,
		WizardHeader,
		WizardFooter,
		PreviewSidebar,
		ErrorsManager,
		Error
	},
	mixins: [wizardMixin, mciAuthMixin, wizardValidation],
	computed: {
		...mapState('wizard', ['emailCampaignProcess', 'campaign', 'detailsProcess', 'errors', 'generatedArtifactsIds']),
		...mapGetters('wizard', ['isErrors']),
		heightHandler() {
			const currentStepAmount = this.steps.find(i => i.active).errors.length + this.errors.length;
			const margin = (currentStepAmount > 1 && 30) || 15;
			return currentStepAmount ? currentStepAmount * 45 + margin + 188 : 188;
		},
		isSent() {
			return this.campaign.status === 'Sent';
		},
		logged() {
			return this.isSent || (!!this.mciUser.userId && !!this.steps.find(i => i.name === 'setup').mcUser.mcUserId);
		},
		error() {
			return {
				text: 'Please, log in to your Mailchimp account to proceed to work with email campaigns.',
				btn: {
					enable: !this.logged,
					text: 'Login to Mailchimp'
				}
			};
		}
	},
	data() {
		return { breadcrumbsOptions: {} };
	},
	methods: {
		...mapActions('wizard', ['setDetailsData', 'updateDetailsProcessStatus', 'updateCampaignEdgeId', 'setErrors', 'getMCDomains']),
		login() {
			this.isLoading = true;
			this.mciAuthorization();
		}
	},
	async created() {
		this.setErrors([
			{
				text: 'Ensure that the number of contacts in chosen marketing lists is less or equal to the daily send limit based on your Mailchimp pricing plan.',
				type: 'warning',
				close: true,
				step: 'recepients',
				name: 'recepientsContacts'
			}
		]);
		if (this.isDetails || this.withEditMode) {
			this.updateDetailsProcessStatus(true);

			try {
				await this.setDetailsData({
					campaignId: this.$route.params.id,
					withEditMode: this.withEditMode
				});
			} catch {
				this.$router.push(`${getBaseRouteUrl(this.$route)}/${this.$route.params.backward || 'listing-search-results'}`);
				sc.events.emit('toast.open', 'The campaign does not exist anymore', 3000);
				return;
			}
			this.breadcrumbsOptions = {
				breadcrumbs: [
					{ title: this.$route.params.prevRouteName },
					{ title: this.campaign.name }
				],
				labelOptions: {
					options: {
						cssClass: {
							$type: 'expression.text',
							value: `email-campaign tag ${this.campaign.status.toLowerCase()}`
						},
						text: {
							$type: 'expression.text',
							value: this.campaign.status
						}
					}
				}
			};
			this.updateCampaignEdgeId(this.$route.params.id);

			this.updateDetailsProcessStatus(false);
		} else {
			this.updateActiveStep(1);
		}
		if (this.isSent) {
			this.isLoading = false;
		} else this.mailChimpAuthorization();

		/* get domains */
		if (this.getMCUserID) await this.getMCDomains(); /* if user logged */
		else { /* if not */
			this.unwatch = this.$watch('getMCUserID', async () => {
				await this.getMCDomains();
				this.unwatch();
			});
		}
	}
};
</script>

<style scoped lang='less'>
@import './email-campaign.less';
</style>
