import { ScMapGoogleAuthInfo } from './scMap.google.authInfo';
import ScMapGoogleMediator from './scMap.google.mediator';
import ScMapGoogleMarker from './scMap.google.marker';
import ScMapGoogleTooltip from './scMap.google.tooltip';
import ScMapGoogleClusterer from './scMap.google.clusterer';
import ScMapGooglePolyline from './shapes/scMap.google.polyline';
import ScMapGoogleDrawingTool from './shapes/scMap.google.drawingTool';
import { isArray, getScript } from '@acx-xms/data-functions/dist';

let _readyDefer;
export default class {
	constructor(options, config) {
		if (!options.maxZoom) {
			options.maxZoom = 18;
		}

		this.eventListeners = [];
		this.config = config;
		this.__markers = [];
		this._mainSettings = void 0;
		this.staticMapBaseUrl = this.config.staticMapUrl || 'https://maps.googleapis.com/maps/api/staticmap';
		this.options = {
			placeholder: void 0,
			zoom: 1,
			center: {
				lat: 0,
				lng: 0
			},
			markerIcons: {},
			zoomOnDoubleclick: true,
			draggable: true,
			clusterMarkers: false,
			...options
		};
		this.authenticationProvider = new ScMapGoogleAuthInfo();
	}

	isAuthenticated() {
		return this.authenticationProvider.isAuthenticated;
	}

	_start() {
		if (!this.isAuthenticated()) {
			return;
		}
		const createFullSCreenControl = (controlDiv, options) => {
			const controlUI = document.createElement('div');
			controlUI.classList.add('scMap-fullscreen');
			const fullScreenIcon = '<img src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%3E%0A%20%20%3Cpath%20fill%3D%22%23666%22%20d%3D%22M4%2C4H0v2h6V0H4V4z%20M14%2C4V0h-2v6h6V4H14z%20M12%2C18h2v-4h4v-2h-6V18z%20M0%2C14h4v4h2v-6H0V14z%22%2F%3E%0A%3C%2Fsvg%3E%0A" style="height: 18px; width: 18px;">';
			const defaultIcon = '<img src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%20018%2018%22%3E%0A%20%20%3Cpath%20fill%3D%22%23666%22%20d%3D%22M0%2C0v2v4h2V2h4V0H2H0z%20M16%2C0h-4v2h4v4h2V2V0H16z%20M16%2C16h-4v2h4h2v-2v-4h-2V16z%20M2%2C12H0v4v2h2h4v-2H2V12z%22%2F%3E%0A%3C%2Fsvg%3E%0A" style="height: 18px; width: 18px;">';
			controlUI.title = 'Fullscreen';
			controlDiv.appendChild(controlUI);
			const controlText = document.createElement('div');
			controlText.innerHTML = defaultIcon;
			controlText.classList.add('icon');
			controlUI.appendChild(controlText);

			// Setup the click event listeners: simply set the map to Chicago.
			controlUI.addEventListener('click', () => {
				if (options.fullScreenCallback && options.fullScreenCallback()) {
					controlText.innerHTML = fullScreenIcon;
				} else {
					controlText.innerHTML = defaultIcon;
				}
			});
		};
		this._map = new google.maps.Map(this.options.placeholderElem, {
			center: this.options.center,
			zoom: this.options.zoom,
			draggable: this.options.draggable,
			scrollwheel: true,
			disableDoubleClickZoom: !this.options.zoomOnDoubleclick,
			streetViewControl: !this.options.hideStreetView,
			mapTypeControl: !this.options.hideMapType,
			scaleControl: this.options.enableScaleControl,
			fullscreenControl: this.options.enableFullscreenControl !== undefined
				? this.options.enableFullscreenControl
				: !this.options.fullScreenCallback,
			gestureHandling: this.options.gestureHandling !== undefined
				? this.options.gestureHandling
				: 'auto',
			maxZoom: this.options.maxZoom,
			minZoom: 3,
			restriction: {
				latLngBounds: {
					north: 85,
					south: -85,
					west: -180,
					east: 180
				},
				strictBounds: false
			}
		});

		if ((this.options.enableFullscreenControl === undefined || this.options.enableFullscreenControl) &&
			this.options.fullScreenCallback && this.options.enableCustomFullscreenControl) {
			const fullSCreenControlDiv = document.createElement('div');
			createFullSCreenControl(fullSCreenControlDiv, this.options);
			fullSCreenControlDiv.index = 1;
			this._map.controls[google.maps.ControlPosition.TOP_RIGHT].push(fullSCreenControlDiv);
		}

		const showTransport = this.config.showTransport;

		if (showTransport) {
			const transitLayer = new google.maps.TransitLayer();
			transitLayer.setMap(this._map);
		}

		this.maxZoomService = new google.maps.MaxZoomService();

		const panorama = this._map.getStreetView();

		this.eventListeners.push(panorama.addListener('closeclick', function () {
			if (document.msFullscreenElement || document.webkitIsFullScreen) {
				$('.gm-fullscreen-control').click();
			}
		}));

		this.eventListeners.push(panorama.addListener('visible_changed', function () {
			if (this.getVisible()) {
				sc.events.emit('scMap.google.removeMapTooltips');
			}
		}));

		this.mediator = new ScMapGoogleMediator({
			target: this._map,
			context: this,
			events: ['center_changed', 'zoom_changed']
		});

		this.clusterer = new ScMapGoogleClusterer(this, this.options.clusterOptions);

		this.__overlayView = new google.maps.OverlayView();
		this.__overlayView.draw = function () {
		};
		this.__overlayView.setMap(this._map);
	}

	setOptions(options) {
		this._map.setOptions(options);
	}

	createMarkers(markers) {
		if (!markers) return;

		if (markers && !isArray(markers)) {
			markers = [markers];
		}

		if (!markers.length) return;

		const result = markers.map((options) => {
			const marker = new ScMapGoogleMarker(this, options);
			this.__markers.push(marker);
			if (this.options.clusterMarkers) {
				this.clusterer.addMarker(marker, true); // Add marker to cluster without redrawing
			}
			return marker;
		});

		return result.length === 1 ? result[0] : result;
	}

	removeMarkers(markers) {
		if (!markers) return;

		if (markers && !isArray(markers)) {
			markers = [markers];
		}

		if (!markers.length) return;

		markers.forEach((marker) => {
			marker.mediator.fork.emit(marker.mediator.guid + '.remove');
			marker.mediator.dispose();

			this.__markers = this.__markers.filter(item => item !== marker);

			marker._marker.setMap(null);

			if (this.options.clusterMarkers) {
				this.clusterer.removeMarker(marker, true); // Remove marker from clusterer without redrawing
			}
		});
	}

	createTooltip(options) {
		return new ScMapGoogleTooltip(this, options);
	}

	createPolyline(options) {
		return new ScMapGooglePolyline(this, options);
	}

	createDrawingTool(options) {
		return new ScMapGoogleDrawingTool(this, options);
	}

	fitTo(coords) {
		if (!coords) {
			return;
		}
		const bounds = new google.maps.LatLngBounds();

		// Normalize markers list
		if (coords && !isArray(coords)) {
			coords = [coords];
		}

		coords = coords.filter(c => {
			const fitToLatLngRange = (c.lat >= -85 && c.lat <= 85) && (c.lng >= -180 && c.lng <= 180); // goole map works wrong if lat coordinate is 85 and greater
			if (!fitToLatLngRange) {
				console.warn('Google Maps: The specified coordinate exceeds the valid coordinate range. Lat: ' + c.lat + ', Lng: ' + c.lng);
			}
			return fitToLatLngRange;
		});

		if (!coords.length) {
			return;
		}

		coords.forEach(item => {
			bounds.extend(new google.maps.LatLng(item.lat, item.lng));
		});

		this._map.fitBounds(bounds);
		const currZoom = this._map.getZoom();
		if (!currZoom || currZoom > this.options.maxZoom) {
			this._map.setZoom(this.options.maxZoom);
		}
	}

	getDistance(a, b) {
		a = this.locationToPoint(a.lat, a.lng);
		b = this.locationToPoint(b.lat, b.lng);

		return Math.sqrt((b.x -= a.x) * b.x + (b.y -= a.y) * b.y);
	}

	pointToLocation(x, y) {
		return this.__overlayView
			.getProjection()
			.fromContainerPixelToLatLng(new google.maps.Point(x, y));
	}

	locationToPoint(lat, lng) {
		const point = this.__overlayView
			.getProjection()
			.fromLatLngToContainerPixel(new google.maps.LatLng(lat, lng));

		return {
			x: point.x,
			y: point.y
		};
	}

	dispose() {
		this.eventListeners.forEach(listener => {
			google.maps.event.removeListener(listener);
		});

		this.__markers.forEach(marker => {
			marker.mediator.dispose();
		});

		this.clusterer.mediator.dispose();
		this.mediator.dispose();
	}

	fetchApi(mainConfig) {
		this._mainSettings = mainConfig;

		if (_readyDefer) {
			return _readyDefer.promise();
		}

		_readyDefer = $.Deferred();

		window.gmapsLoaded = () => {
			delete window.gmapsLoaded;
			_readyDefer.resolve();
		};

		getScript('//maps.googleapis.com/maps/api/js?' + 'callback=gmapsLoaded&libraries=drawing,places' + (this.config.apiKey ? '&key=' + this.config.apiKey : ''));

		return _readyDefer.promise();
	}

	resize() {
		google.maps.event.trigger(this._map, 'resize');
	}

	setCenter(lat, lng) {
		this._map.setCenter(new google.maps.LatLng(lat, lng));
	}

	_replaceHashtag(str) {
		if (str) {
			str = str.replace('#', '');
		}
		return str;
	}
}
