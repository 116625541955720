<template>
	<div class="configurations" v-perfectscroll="{ enable: true }" v-if="configurations.length > 1">
		<p @click="SelectConfig(config)" v-for="config in configurations" :key="config">{{config}}</p>
	</div>
</template>
<script>
import {
	Authenticate, FinishExternalAuthentication, IsAuthorized, GetAvailableConfigurations
} from '@/Data/Auth/authentication-service';
export default {
	name: 'configurations-list',
	data() {
		return {
			message: null,
			configurations: []
		};
	},
	async created() {
		try {
			const authenticatedResult = await this.handleAuth();
			if (authenticatedResult && authenticatedResult.result) {
				await this.loadConfigs();
			} else {
				// TODO: may be think of another way of handling unathorized users, because previously we were not handling them
				if (authenticatedResult && authenticatedResult.redirect) {
					this.$router.push({
						name: authenticatedResult.redirect,
						query: {
							restore: authenticatedResult.error,
							redirect_url: authenticatedResult.redirect_url
						}
					});
				} else {
					this.$router.push(`/${this.$route.params.clusterKey || ''}`);
				}
			}
		} catch (e) {
			let errorMessage = e.message;
			if ((/localStorage|sessionStorage/).test(errorMessage)) {
				errorMessage = 'Please allow all cookies in your browser to continue and try again';
			}
			this.$store.commit('changeErrorMessage', errorMessage);

			try {
				const errorObj = JSON.parse(errorMessage);

				switch (errorObj.Type) {
				case 'license': {
					this.$router.push('/licenseError');
					break;
				}
				default:
					this.$router.push('/error');
				}
			} catch (e) {
				this.$router.push('/error');
			}
		}
	},
	methods: {
		SelectConfig(config) {
			const url = `/${this.$route.params.clusterKey}/${config}`;
			this.$router.replace(url);
		},
		async handleAuth() {
			let authenticated = { result: false };
			// Anonymous login
			if (this.$route.params.clusterKey && this.$route.params.clusterKey.toLocaleLowerCase().indexOf('anonymous') > -1) {
				this.$store.commit('changeInitMessage', 'Authenticating');
				authenticated.result = await Authenticate({ cluster: this.$route.params.clusterKey });
			} else if (this.$route.query.sid) {
				this.$store.commit('changeInitMessage', 'Finishing authentication process');
				await FinishExternalAuthentication(this.$route.query.sid);
				// we don't need sid in get params anymore
				this.$router.replace(this.$route.path);
				authenticated = await IsAuthorized(this.$route.params.clusterKey);
			} else {
				this.$store.commit('changeInitMessage', 'Getting authentication info');
				authenticated = await IsAuthorized(this.$route.params.clusterKey);
			}
			return authenticated;
		},
		async loadConfigs() {
			this.$store.commit('changeInitMessage', 'Loading available configurations');
			this.configurations = await GetAvailableConfigurations();
			this.$store.commit('changeInitMessage', null);
			if (!this.configurations || !this.configurations.length) {
				this.$store.commit('changeErrorMessage', 'You have provided valid credentials, but your account is not configured yet. Please contact Administrator or retry login.');
				this.$router.push('/error');
			} else if (this.configurations.length === 1) {
				this.SelectConfig(this.configurations[0]);
			}
		}
	}
};
</script>
<style src="@/Components/Initialization/initialization.mixins.less" scoped></style>
