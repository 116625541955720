export default {
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: { Control: () => import('./../../Entity/control.vue') },
	data() {
		return {
			css: '',
			title: '',
			prompt: this.options.prompt ? this.evaluationContext.eval(this.options.prompt) : '',
			tooltipText: null,
			stateKey: null
		};
	},
	methods: {
		collapse() {
			this.collapsed = !this.collapsed;
		}
	},
	computed: {
		visible() {
			if (this.storeState) {
				return !this.storeState.hiddenFields[this.options.stateField];
			}
			return this.options.visible !== void 0 ? this.evaluationContext.eval(this.options.visible) : true;
		},
		storeState() {
			return this.$store.state[this.stateKey];
		}
	},
	async created() {
		this.title = await this.evaluationContext.evalAsync(this.options.title) || '';
		this.tooltipText = this.evaluationContext.eval(this.options.tooltipText) || null;
		this.stateKey = 'editor.' + this.evaluationContext.entity.logicalname;
		this.dataAttr = this.options.qaDataAttribute && this.evaluationContext.eval(this.options.qaDataAttribute);

		const classes = [this.evaluationContext.eval(this.options.cssClass), this.options.displayStyle];
		if (this.options.nowrap) {
			classes.push('no-wrap');
		}
		if (this.evaluationContext.eval(this.defaultFocus)) {
			classes.push('default-focus');
		}
		this.css = classes.join(' ');
	}
};
