var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("div", { staticClass: "loading-wrapper", class: _vm.cssClass }, [
        _c("div", { staticClass: "loading-overlay" })
      ])
    : !_vm.loading && _vm.visible
    ? _c("control", {
        class: _vm.cssClass,
        attrs: {
          name: _vm.options.control.$type,
          contentProps: _vm.options.control,
          evaluationContext: _vm.innerContext
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }