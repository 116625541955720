<template>
	<div class="email-campaign-header-root">
		<section v-if="!isDetails" class="header">
			<div class="header-title">
				<div class="title">Start Email Campaign</div>
				<div v-if="!!personCounter && activeStepNumber === 5 && getMCUserID" class="person">
					{{ recepientsLabel }}
				</div>
			</div>
			<StepCounter
				v-if="!loading"
				:disableCounter="emailCampaignProcess"
				namespace="wizard.navigation"
				@active="changeStepHandler"
			/>
		</section>
		<section v-if="isDetails" class="header details">
			<Breadcrumbs
				:breadcrumbs="breadcrumbsOptions.breadcrumbs"
				:labelOptions="breadcrumbsOptions.labelOptions"
			/>
			<ActionMenu
				:hasDropDownItems="true"
				:options="menuOptions"
				:record="{
					Id: campaign.edgeId,
					Source: {
						status: campaign.status,
						mcicampaignid: campaign.id,
						marketingcampaignid: campaign.edgeId,
						systemuserserviceaccountid: { id: campaign.xmsAccountId },
						marketingcampaignsystemuserserviceaccountidsystemuserserviceaccount: {
							serviceaccountuserid: campaign.mciAccountId
						}
					}
				}"
			/>
		</section>
	</div>
</template>

<script>
import { wizardMixin } from '@/Components/Wizard/Mixins/wizardMixin';
import StepCounter from '@/Components/StepCounter/step-counter';
import Breadcrumbs from '@/Components/ComponentSet/breadcrumbs/breadcrumbs';
import ActionMenu from '@/Components/Menu/action-menu';
import { menuConfig } from './menu-config';
import { refreshEvents } from '../../helpers/refreshEvents';
import { mapActions, mapState } from 'vuex';

export default {
	name: 'email-campaign-header',
	components: {
		StepCounter,
		Breadcrumbs,
		ActionMenu
	},
	props: {
		loading: {
			type: Boolean,
			default: true
		},
		breadcrumbsOptions: Object
	},
	data() {
		return { menuOptions: menuConfig };
	},
	mixins: [wizardMixin],
	computed: {
		...mapState('wizard', ['campaign', 'emailCampaignProcess']),
		personCounter() {
			const contacts = this.recipients;
			if (contacts) {
				const count = contacts.reduce((a, i) => {
					return +a + +i.Source.RelationCounts.contacts;
				}, '');
				return count;
			}
			return null;
		},
		recepientsLabel() {
			const suffix = this.personCounter > 1 ? 's' : '';
			return (this.personCounter && `This email will be sent to ${this.personCounter} recipient${suffix}`) || '';
		}
	},
	methods: {
		...mapActions('wizard.navigation', ['setActive', 'setSteps', 'setDisabled', 'setFilled', 'setInvalid', 'setCaptions']),
		changeStepHandler(data) {
			this.updateActiveStep(data);
		},
		setBreadcrumbsToSessionStorage() {
			sessionStorage.setItem('wizard.breadcrumbs', JSON.stringify({
				prevRouteName: this.breadcrumbsOptions.breadcrumbs[0].title,
				entryPoint: this.$route.params.entryPoint
			}));
		}
	},
	created() {
		if (!this.isDetails) {
			this.setSteps(5);
		} else {
			addEventListener('beforeunload', this.setBreadcrumbsToSessionStorage);
			this.$on('.navigateBreadcrumbs', () => {
				refreshEvents.forEach(e => sc.events.emit(e));
				this.$router.push(this.$route.params.entryPoint);
			});
		}
	},
	mounted() {
		!this.isDetails && this.changeStepName();
	},
	beforeDestroy() {
		removeEventListener('beforeunload', this.setBreadcrumbsToSessionStorage);
		this.$off('.navigateBreadcrumbs');
	}
};
</script>

<style scoped lang='less'>
@import './header.less';
</style>
