let localizations;
export default {
	parameters(options) {
		console.error('label must be awaited');
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.key),
			options.language ? await this.evalAsync(options.language) : sc.UILanguage.code
		];
	},
	evaluate(key, lng) {
		console.error('label must be awaited');
	},
	async evaluateAsync(key, lng) {
		if (!localizations) {
			localizations = sc.classes.get('localization.dataProvider');
		}
		return await localizations.getLabel(lng, key);
	}
};
