<template>
	<div v-if="visible" class="searchResults-documentTree">
		<div v-perfectscroll="{ enabled:true }" class="folder-tree">
			<div>
				<FolderTreeMain
					v-if="options.logicalname"
					:watchSearchResults="options.watchSearchResults"
					:srNamespace="folderSrNamespace"
					:logicalname="options.logicalname"
					:treeLogicalName="options.treeLogicalName"
					:rootName="rootName"
					:namespace="this.options.namespace"
					:configTreeFilters="configTreeFilters"
					@treeClicked="folderClickedHandler"
					@setBreadcrumbs="setBreadcrumbs"
				/>
			</div>
			<div>
				<SharedFolderTree
					v-if="options.sharedlogicalname"
					:srNamespace="sharedFolderSrNamespace"
					:folderSrNamespace="folderSrNamespace"
					:sharedTreeLogicalName="options.sharedTreeLogicalName"
					:sharedlogicalname="options.sharedlogicalname"
					@setBreadcrumbs="setBreadcrumbs"
					@sharedTreeClicked="sharedClickedHandler"
				/>
			</div>
		</div>
		<div class="results-wrapper">
			<TreeBreadcrumbs
				v-if="breadcrumbs.length"
				:maxBreadcrumbsLength="options.maxBreadcrumbsLength"
				:stateNamespace="stateNamespace"
				:breadcrumbs="breadcrumbs"
			/>
			<div :class="['table-wrapper', { 'm-top': !breadcrumbs.length, grid: hasTagList }]">
				<TagList
					v-if="hasTagList"
					:stateNamespace="stateNamespace"
					:entityNamespace="options.targetEntity && options.targetEntity[0]"
				/>
				<ControlTableView
					:class="cssClass || ''"
					:options="component"
					:stateNamespace="stateNamespace"
					:evaluationContext="evaluationContext"
					:initResults="results"
				/>
				<Pagination
					v-if="paginationParams"
					:pageSize="paginationParams.pageSize"
					:total="paginationParams.total"
					:from="paginationParams.from"
					:showPaging="paginationParams.showPaging"
					:activePages="paginationParams.activePages"
				/>
			</div>
		</div>
	</div>
</template>
<script>
/* eslint no-unused-vars: 0 */
/* eslint vue/no-unused-components: 0 */
import KOComponent from '@/Bindings/kocomponent.directive';

import { Search, CreateBulk } from '@acx-xms/data-functions/dist';
import { evaluate } from '@/Data/EvaluationContext/evaluationContext';

const ControlTableView = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'@/Components/Control/Table/control-table-view');
const SharedFolderTree = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'@/Components/Control/SharedFolderTree/sharedfolder-tree');
const TreeBreadcrumbs = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'./tree-breadcrumbs');
const FolderTreeMain = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'./folder-tree-main');
const Pagination = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'@/Components/ComponentSet/pagination/pagination');
const TagList = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'@/Components/Common/TagsList/tags-list');

export default {
	name: 'component-set-search-results-document-tree-view',
	props: {
		options: Object,
		evaluationContext: Object,
		results: Array,
		stateNamespace: String,
		combinedNamespace: String,
		paginationParams: Object,
		cssClass: String
	},
	components: {
		ControlTableView,
		SharedFolderTree,
		TreeBreadcrumbs,
		FolderTreeMain,
		Pagination,
		TagList
	},
	data() {
		return {
			component: {},
			folderSrNamespace: `${this.options.namespace}.documents`,
			sharedFolderSrNamespace: `${this.options.namespace}.shareddocuments`,
			breadcrumbs: [],
			visible: this.options.contentComponent.visible === undefined ? true : evaluate(this.options.contentComponent.visible)
		};
	},
	computed: {
		hasTagList() {
			return this.options.withSelectionList && this.options.targetEntity && this.stateNamespace && (this.results && this.results.length);
		}
	},
	async created() {
		this.treeLogicalName = this.options.treeLogicalName;
		this.sharedTreeLogicalName = this.options.sharedTreeLogicalName;
		this.rootName = this.options.rootName ? this.evaluationContext.eval(this.options.rootName) : '';
		this.logicalname = this.options.logicalname;
		this.sharedlogicalname = this.options.sharedlogicalname;

		this.configTreeFilters = this.options.treeFilters
			? this.options.treeFilters.map((filter) => {
				return sc.classes.get(filter.$type, filter, this.evaluationContext).toFilter();
			})
			: [];
		this.component = {
			...this.options.contentComponent,
			namespace: this.options.namespace,
			internalNamespace: this.options.internalNamespace,
			modalNamespace: this.options.modalNamespace,
			targetEntity: this.options.targetEntity,
			isParent: this.options.isParent,
			scrollEnabled: this.options.scrollEnabled,
			withToggleAll: this.options.withToggleAll,
			allowSelection: this.options.allowSelection,
			singleSelection: this.options.singleSelection,
			allowSingleSelection: this.options.allowSingleSelection,
			syncSelection: this.options.syncSelection,
			withCheckAll: this.options.withCheckAll,
			evaluationContext: this.evaluationContext || {}
		};

		// subscriptions
		this.$root.$on(this.stateNamespace + '.setRecordsToDeactivate', this.setIdsToDeactivate);
		this.$root.$on(this.stateNamespace + '.layoutSwitched', this.onViewChange);
		this.$root.$on(this.stateNamespace + '.entity.searched', this.clearSelection);
		this.$on('.pageChanged', function (activePages) {
			this.$parent.$emit('.pageChanged', activePages);
		});
		if (this.options.watchSearchResults) {
			this.unwatch = this.$watch('results', () => {
				this.$emit(this.folderSrNamespace + '.resultsChanged');
			});
		}
	},
	beforeDestroy() {
		this.$root.$off(this.stateNamespace + '.setRecordsToDeactivate', this.setIdsToDeactivate);
		this.$root.$off(this.stateNamespace + '.layoutSwitched', this.onViewChange);
		this.$root.$off(this.stateNamespace + '.entity.searched', this.clearSelection);
		if (this.unwatch) {
			this.unwatch();
		};
	},
	methods: {
		clearSelection() {
			this.$store.commit(this.stateNamespace + '/cleanSelection');
		},

		async findRecordsToDeactivate(folderIds, folderEntity, documentEntity, records) {
			const filters = [
				sc.classes.get('offsetFrom.filter', 0),
				sc.classes.get('offsetSize.filter', 9999),
				sc.classes.get('termFacet.filter', {
					logicalName: 'parentfolder.id',
					query: folderIds
				}).fillQuery(),
				sc.classes.get('termFacet.filter', {
					logicalName: 'recordstate.id',
					query: ['8d113fa8-3015-4060-a107-14cedcd19dd3']
				}).fillQuery(),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'parentfolder' }
				]).fillQuery()
			];
			const results = await Search([documentEntity, folderEntity], filters);
			if (results.Total) {
				const subfoldersIds = results.Results.filter(record => record.Type === folderEntity &&
						!folderIds.includes(record.Id)
				).map(folder => folder.Id);
				let mappedRecords = results.Results.map(record => {
					return {
						id: record.Id,
						entity: record.Type
					};
				});
				mappedRecords = mappedRecords.concat(records);
				if (subfoldersIds.length) {
					return await this.findRecordsToDeactivate(subfoldersIds, folderEntity, documentEntity, mappedRecords);
				} else {
					return mappedRecords;
				}
			} else {
				return records;
			}
		},
		async setIdsToDeactivate(folders) {
			let documentEntity,
				folderEntity;

			if (this.stateNamespace === this.folderSrNamespace) {
				documentEntity = this.logicalname;
				folderEntity = this.treeLogicalName;
			}
			if (this.stateNamespace === this.sharedFolderSrNamespace) {
				documentEntity = this.sharedlogicalname;
				folderEntity = this.sharedTreeLogicalName;
			};
			const folderIds = folders.map(folder => folder.id);

			const records = await this.findRecordsToDeactivate(folderIds, folderEntity, documentEntity, folders);
			const ids = records.map(rec => rec.id);
			this.$store.commit(this.stateNamespace + '/setRecordsToDeactivate', ids);
			const requests = records.map(record => {
				return {
					id: sc.utils.guid(),
					method: 'PUT',
					url: `${record.entity}/${record.id}/put`,
					body: {
						type: record.entity,
						recordstate: {
							id: '9e817a63-452c-e811-8320-7824af3b452f',
							logicalname: 'lookuprecordstate'
						}
					}
				};
			});
			await CreateBulk(requests, {
				async: true,
				waitMilliseconds: 20000
			});

			this.$store.commit(this.stateNamespace + '/excludeRecordsToDeactivate', ids);
			this.emitSearch();
			this.$emit(this.folderSrNamespace + '.resultsChanged');
		},
		emitSearch() {
			if (!this.combinedNamespace) {
				this.$root.$emit(this.stateNamespace + 'search');
			} else {
				this.$root.$emit(this.combinedNamespace + '.searching');
			}
		},
		setBreadcrumbs(breadcrumbs) {
			this.breadcrumbs = breadcrumbs;
		},
		sharedClickedHandler(node, srNamespace) {
			this.breadcrumbs = [];
			if (srNamespace === this.stateNamespace) {
				if (node.data.callback) {
					node.data.callback(this.stateNamespace);
					this.emitSearch();
				}
			} else {
				const componentSet = 'shareddocuments';
				node.refreshEvent = () => {
					this.$emit(this.folderSrNamespace + '.componentSetChanged');
				};
				this.$root.$emit(this.stateNamespace + '.targetEntityChange', componentSet, node);
			}
		},
		folderClickedHandler(node, srNamespace) {
			if (srNamespace === this.stateNamespace) {
				if (node.data.callback) {
					node.data.callback(this.stateNamespace);
					this.emitSearch();
				}
			} else {
				const componentSet = 'documents';
				node.refreshEvent = () => {
					this.$emit(this.sharedFolderSrNamespace + '.componentSetChanged');
				};
				this.$root.$emit(this.stateNamespace + '.targetEntityChange', componentSet, node);
			}
		},
		onViewChange(name) {
			if (this.options.ignoreViewChange) {
				this.$root.$emit(this.stateNamespace + 'search');
				return;
			}
			if (name === this.options.name) {
				this.$root.$emit(this.folderSrNamespace + '.setActiveFolder', { data: { folderId: null } });
			} else {
				this.$store.commit(this.stateNamespace + '/deleteSingleFilter', {
					entity: this.logicalname,
					logicalName: 'parentfolder.id'
				});
				this.$root.$emit(this.stateNamespace + 'search');
			}
		},
	}
};
</script>
<style src='./document-folder-tree-view.less' scoped></style>
