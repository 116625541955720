import { mapAsync, isUndefined } from '@acx-xms/data-functions/dist';
import ExpressionHasvalue from '../boolean/hasvalue';

export default {
	parameters(options) {
		return [
			options.argument.map(this.eval)
		];
	},
	async parametersAsync(options) {
		return [
			await mapAsync(options.argument, this.evalAsync)
		];
	},
	evaluate(args) {
		const result = args.find(ExpressionHasvalue.evaluate);
		if (isUndefined(result)) {
			return null;
		}
		return result;
	}
};
