var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "logo-wrapper" }, [
    _c(
      "div",
      {
        class: {
          logo: true,
          iconsOnly: true,
          active: _vm.state === "iconsOnly"
        }
      },
      [
        _c(
          "svg",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.smallLogoUrl,
                expression: "!smallLogoUrl"
              }
            ],
            staticClass: "iconsOnly"
          },
          [
            _c("use", {
              attrs: {
                "xlink:href": "#tenant-logo-" + _vm.logoIcon + "-compact"
              }
            })
          ]
        ),
        _vm._v(" "),
        _vm.smallLogoUrl
          ? _c("image-wrapper", {
              attrs: {
                url: _vm.smallLogoUrl,
                width: "81px",
                height: "80px",
                fit: "contain"
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { class: { logo: true, active: _vm.state !== "iconsOnly" } },
      [
        _c(
          "svg",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.bigLogoUrl,
                expression: "!bigLogoUrl"
              }
            ],
            staticClass: "iconsOnly"
          },
          [
            _c("use", {
              attrs: { "xlink:href": "#tenant-logo-" + _vm.logoIcon }
            })
          ]
        ),
        _vm._v(" "),
        _vm.bigLogoUrl
          ? _c("image-wrapper", {
              attrs: {
                alt: _vm.alt,
                url: _vm.bigLogoUrl,
                width: "100%",
                height: "80px",
                fit: "cover"
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }