<template>
	<div class="sync-wrapper">

		<div class="header">
			<div class="h2" v-localization="{key: 'crmSyncPlugin.header.syncstatus'}"></div>
		</div>

		<div class="body">
			<div class="step1" v-if="stepNumber===1">
				<div class="stepWrapper" v-if="syncStatus!==null">
					<span :key="'percentage'" class="primary-label" v-localization="{key: 'crmSyncPlugin.percentage'}"></span>
					<span class="label">{{syncedRecordsPercentage}}</span>

					<div class="content">
						<div class="progress-bar-container" v-bind:style="{ width: syncedRecordsPercentage }"></div>
					</div>
				</div>
				<div class="sync-info">
				</div>
			</div>

			<div class="step2" v-if="stepNumber===2">
				<div class="stepWrapper" v-if="syncStatus.ApplicationErrors.length === 0 || syncStatus.Total > 0">
					<span :key="'syncIsCompleted'" class="primary-label" v-localization="{key: 'crmSyncPlugin.syncIsCompleted'}"></span>
					<div class="info">
						<span v-if="syncStatus.hasSkippedParentEnities" v-localization="{key: 'crmSyncPlugin.warn.someRecordSkippedMessage'}"></span>
						<div class="content sync-stasus" v-if="Object.keys(syncStatus.StateEntities).length">
							<div class="tableView">
								<div class="tableview-header">
									<div v-localization="{key: 'crmSyncPlugin.tableHeader.entity'}"></div>
									<div v-localization="{key: 'crmSyncPlugin.updated'}"></div>
									<div v-localization="{key: 'crmSyncPlugin.synced'}"></div>
									<div v-localization="{key: 'crmSyncPlugin.failed'}"></div>
									<div v-localization="{key: 'crmSyncPlugin.interrupted'}"></div>
								</div>
								<div class="tableview-content" v-perfectscroll="{enable: true}">
									<div class="row" v-for="(entity, value) in syncStatus.StateEntities" :key="value">
										<div class="column">
											<span>{{getEntityDisplayName(value)}}</span>
										</div>
										<div class="column">
											<span>{{entity.Updated}}</span>
										</div>
										<div class="column">
											<span>{{entity.AlreadySynced}}</span>
										</div>
										<div class="column">
											<span>{{entity.Failed}}</span>
										</div>
										<div class="column">
											<span>{{entity.Interrupted}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="content" v-if="skippedRecords && skippedRecords.length">
							<div class="tableView">
								<div class="tableview-header">
									<div v-localization="{key: 'crmSyncPlugin.tableHeader.failedRecords'}"></div>
									<div v-localization="{key: 'crmSyncPlugin.tableHeader.entity'}"></div>
									<div v-localization="{key: 'crmSyncPlugin.tableHeader.id'}"></div>
									<div v-localization="{key: 'crmSyncPlugin.tableHeader.reason'}"></div>
								</div>
								<div class="tableview-content" v-perfectscroll="{enable: true}">
									<div class="row" v-for="skippedRecord in skippedRecords" :key="skippedRecord.Id">
										<div class="column">
											<span>{{skippedRecord.Name}}</span>
										</div>
										<div class="column">
											<span>{{skippedRecord.Entity}}</span>
										</div>
										<div class="column">
											<span>{{skippedRecord.Id}}</span>
										</div>
										<div class="column">
											<span>{{skippedRecord.Reason}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="stepWrapper" v-else-if="syncStatus.ApplicationErrors.length > 0">
					<span :key="'syncIsCompleted'" class="primary-label" v-localization="{key: 'crmSyncPlugin.syncIsFailed'}"></span>
					<span class="label" v-localization="{key: 'crmSyncPlugin.applicationErrors'}" />
					<div class="application-errors">
						<div v-for="(error, index) in syncStatus.ApplicationErrors" v-perfectscroll="{enable: true}" :key="index">
							{{error.Message}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<span class="buttons">
				<button @click="cancel" class="cancel" v-show="stepNumber===1 && processId!==null" type="button" v-localization="{key: 'crmSyncPlugin.button.cancel'}"></button>
				<button @click="ok" v-show="stepNumber===2" type="button" v-localization="{key: 'crmSyncPlugin.button.ok'}"></button>
			</span>
		</div>
	</div>
</template>
<script>
import crmSyncDataProvider from '@/Data/DataProviders/crmSyncDataProvider.js';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'crmsync',
	props: { options: Object },
	components: {},
	data() {
		return {
			stepNumber: 1,
			syncStatus: null,
			processId: null
		};
	},

	async created() {
		this.localizationDataProvider = sc.classes.get('localization.dataProvider');
		this.cancelWarn = ko.unwrap(this.localizationDataProvider.localize('cancel.warn'));
		this.evaluationContext = createEvaluationContext({});
		this.entityConfigurationDataProvider = sc.classes.get('entityConfiguration.dataProvider');

		const itemstosyncId = this.findGetParameter('itemstosyncId');

		if (itemstosyncId) {
			try {
				const syncRes = await crmSyncDataProvider.sync(itemstosyncId);
				this.processId = syncRes.processId;

				await crmSyncDataProvider.getState(syncRes.processId, this.onStatusChange);

				delete this.syncStatus.StateEntities.geography;

				for (const key in this.syncStatus.StateEntities) {
					const value = this.syncStatus.StateEntities[key];
					if (!value.AlreadySynced && !value.Failed && !value.Interrupted && !value.Updated) {
						delete this.syncStatus.StateEntities[key];
					}
				}

				this.stepNumber = 2;
			} catch (e) {
				if (e.status === 409) {
					this.syncStatus = { ApplicationErrors: [{ Message: ko.unwrap(this.localizationDataProvider.localize('error.processisrunning')) }] };
					this.stepNumber = 2;
				}
			}
		}
	},
	methods: {
		ok: function () {
			window.close();
		},

		cancel: function () {
			if (confirm(this.cancelWarn)) {
				crmSyncDataProvider.cancel(this.processId);
			}
		},
		findGetParameter: function (parameterName) {
			let result = null;
			let tmp = [];
			location.search
				.substr(1)
				.split('&')
				.forEach(function (item) {
					tmp = item.split('=');
					if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
				});
			return result;
		},
		onStatusChange: function (data) {
			this.syncStatus = data;
		},
		getEntityDisplayName: function (entityLogicalName, isSingular) {
			return ko.unwrap(ko.utils.fromPromise(this.entityConfigurationDataProvider.getEntityCaptions([entityLogicalName], isSingular ? 'singular' : 'plural'))[0]);
		}
	},
	computed: {
		syncedRecordsPercentage() {
			if (this.syncStatus !== null && this.syncStatus.Total > 0) {
				let processedCount = 0;

				for (const key in this.syncStatus.StateEntities) {
					const value = this.syncStatus.StateEntities[key];
					processedCount += value.AlreadySynced;
					processedCount += value.Failed;
					processedCount += value.Interrupted;
					processedCount += value.Updated;
				}

				const percents = parseInt(processedCount / this.syncStatus.Total * 100);
				const resultPercents = percents > 100 ? 100 : percents;

				return resultPercents + '%';
			} else {
				return 0 + '%';
			}
		},

		skippedRecords() {
			let result = [];

			if (this.syncStatus !== null) {
				for (const key in this.syncStatus.StateEntities) {
					const value = this.syncStatus.StateEntities[key];

					value.SkippedRecords.forEach((r) => {
						r.Entity = this.getEntityDisplayName(r.Entity, true);
					});

					result = result.concat(value.SkippedRecords);
				}
			}

			return result;
		}
	}
};
</script>
<style src="./crmsync.less" scoped></style>
