var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "email-campaign-step-root step" }, [
    _c("div", { class: ["wizard-step-header", { details: _vm.isDetails }] }, [
      _c("div", { staticClass: "wizard-step-header-title" }, [
        _vm._v("\n\t\t\tPreview\n\t\t")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "wizard-step-header-buttons" }, [
        !_vm.isDetails
          ? _c(
              "svg",
              {
                staticClass: "svg-icon",
                class: { "svg-icon-disabled": _vm.templateProcess },
                on: { click: _vm.refreshTemplate }
              },
              [
                _c("use", {
                  attrs: { "xlink:href": "#action-icons-ec-wizard-refresh" }
                })
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isDetails
          ? _c(
              "svg",
              {
                staticClass: "svg-icon",
                class: { "svg-icon-disabled": _vm.templateProcess },
                on: { click: _vm.editTemplate }
              },
              [
                _c("use", {
                  attrs: { "xlink:href": "#action-icons-ms-entity-edit" }
                })
              ]
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { class: ["wizard-step-content", { details: _vm.isDetails }] },
      [
        _c(
          "transition",
          { attrs: { name: "fade", mode: "out-in" } },
          [
            _vm.templateProcess
              ? _c("Loader", {
                  attrs: { height: "100%", text: _vm.loaderText }
                })
              : !_vm.templateProcess && !_vm.hasArtifact
              ? _c(
                  "div",
                  { staticClass: "preview-error" },
                  [
                    _c("Error", {
                      attrs: { text: _vm.errorText, button: _vm.errorBtn },
                      on: { click: _vm.regenerateArtifactAction }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { attrs: { id: "preview-iframe-wrapper" } }, [
          _c("iframe", {
            ref: "previewIframe",
            staticClass: "wizard-preview-iframe",
            attrs: { frameborder: "0", height: "100%" }
          })
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }