var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isCustomImagePlaceholder
    ? _c("div", { staticClass: "custom-image-placeholder" }, [
        _c("img", {
          attrs: { src: "/Images/custom-image-placeholder.jpg", alt: _vm.alt }
        })
      ])
    : _c("div", { staticClass: "svg-thumbnail" }, [
        _c("svg", { staticClass: "svg-icon", class: _vm.cssClass }, [
          _c("use", { attrs: { "xlink:href": _vm.placeholderEntityName } })
        ])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }