<template>
	<div
		v-data-attr="{ options }"
		:class="['filter-info-panel open', { loading: isLoading }]"
	>
		<div class="content">
			<header>
				<div class="back-button" @click="closePanel">
					<svg class="svg-icon svg-15">
						<use xlink:href="#action-icons-back-button" class="action hover action-icons-back-button" />
					</svg>
				</div>
				<div class="h5">
					{{ caption }}
				</div>
				<div v-if="!options.disableResetFilter" class="actions">
					<div
						v-data-attr="{ options, stateNamespace: 'clear' }"
						class="action-wrapper filters-reset-hover-parent"
						@click="clear"
					>
						<svg class="svg-icon svg-20">
							<use xlink:href="#action-icons-filters-reset" class="action hover action-icons-filters-reset" />
						</svg>
					</div>
				</div>
			</header>

			<section v-if="!isLoading" class="body visible">
				<!--todo handle params better-->
				<component
					v-if="innerComponent"
					:is="innerComponent"
					:key="logicalName"
					:options="options"
					:evaluationContext="evaluationContext"
					:stateNamespace="stateNamespace"
					class="filter-info-component"
					@filterError="$emit('filterError', $event)"
				/>
				<div class="buttonsMenu">
					<button
						v-if="showApplyButton()"
						type="button"
						class="applyButton"
						@click="apply"
					>
						Apply
					</button>
				</div>
			</section>
		</div>

		<div v-if="isLoading" class="loading-overlay" />
	</div>
</template>
<script>
import '@/Bindings/ui-attribute.directive';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import FilterMixins from './../filter.mixins';
const FilterInfoPanelsList = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './panels/list');
const FilterInfoPanelsIdsList = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './panels/ids-list');
const FilterInfoPanelsSlider = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './panels/slider');
const FilterInfoPanelsDateRange = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './panels/date-range');
const SavedSearchInfoPanel = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './panels/saved-search-info-panel');
const FilterInfoPanelsField = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './panels/field');

export default {
	name: 'filter-info-panel',
	mixins: [FilterMixins],
	data() {
		return { caption: '' };
	},
	props: {
		options: Object,
		evaluationContext: Object,
		stateNamespace: String,
		isLoading: Boolean
	},
	components: {
		FilterInfoPanelsList,
		FilterInfoPanelsIdsList,
		FilterInfoPanelsSlider,
		FilterInfoPanelsDateRange,
		SavedSearchInfoPanel,
		FilterInfoPanelsField
	},
	computed: {
		innerComponent: function () {
			return this.getInfoComponentName(this.options);
		},
		logicalName: function () {
			return helperMethods.eval(this.evaluationContext, this.options.logicalName);
		}
	},
	watch: {
		'options.displayName': {
			handler: async function (newVal, oldVal) {
				this.caption = await helperMethods.evalAsync(this.evaluationContext, newVal);
			},
			immediate: true
		}
	},
	methods: {
		showApplyButton() {
			return sc.utils.userAgent.userAgent.isIos || sc.utils.userAgent.userAgent.isAndroid;
		},
		closePanel() {
			this.$store.commit(this.stateNamespace + '/openInfoPanel', null);
		},
		clear() {
			this.$emit(this.stateNamespace + 'resetFilter');
		},
		apply() {
			this.$store.commit(this.stateNamespace + '/openInfoPanel', null);
			this.$store.commit(this.stateNamespace + '/toggleFiltersPanel');
		}
	}
};
</script>
<style src="./filter-info-panel.less" scoped></style>
