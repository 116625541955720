<template>
	<div class="dialog-manager">
		<!--hotfix to handle both dialog managers-->
		<div class="dialogues-area newer">
			<dialog-wrapper :initDialog="dialog" v-for="dialog in dialogues" :key="dialog.id"></dialog-wrapper>
			<component v-if="fullscreenComponent"
					   :is="fullscreenComponent.name"
					   v-on:close="onCloseFullscreen"
					   :options="fullscreenComponent.options"></component>
		</div>
	</div>
</template>
<script>
import { prepareComponentName as prepCompName } from '@acx-xms/data-functions/dist';
const DialogWrapper = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './dialog-wrapper');
const ImageFullscreenPreview = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './image-fullscreen-preview/image-fullscreen-preview');
export default {
	name: 'dialog-manager',
	props: { options: Object },
	components: {
		DialogWrapper,
		ImageFullscreenPreview
	},
	data() {
		return {
			dialogues: [],
			fullscreenComponent: null
		};
	},
	created() {
		this.fork = sc.events.fork();

		// temporary solution until we move all dialogs to vue
		this.fork.on('vue.dialog.open', (options) => {
			options.active = true;
			options.id = (Math.random() + '').replace('.', '');
			this.dialogues.push(options);
		});
		// temporary solution until we move all dialogs to vue
		this.fork.on('vue.dialog.info', (options) => {
			options.active = true;
			options.id = (Math.random() + '').replace('.', '');
			this.dialogues.push({
				...options,
				component: 'info-dialog',
				maximizedWidth: '400px'
			});
		});
		this.fork.on('vue.progressDialog.open', (progress) => {
			sc.events.emit('vue.dialog.open', {
				icon: 'entity-icons-deal-small',
				title: progress.title,
				allowPin: false,
				component: 'progress.dialog',
				allowClose: false,
				maximizedWidth: '450px',
				maximizedHeight: '390px',
				params: { progress }
			});
		});

		this.$root.$on('vue.dialog.info', (options) => {
			options.active = true;
			options.id = (Math.random() + '').replace('.', '');
			this.dialogues.push({
				...options,
				component: 'info-dialog'
			});
		});
		this.$root.$on('vue.dialog.open', (options) => {
			options.active = true;
			options.id = (Math.random() + '').replace('.', '');
			this.dialogues.push(options);
		});
		this.$root.$on('dialog.fullscreen.open', (options) => {
			this.fullscreenComponent = options;
		});

		this.$on('closeDialog', this.onClose);
	},
	methods: {
		prepareComponentName(name) {
			return prepCompName(name);
		},
		onClose(id) {
			this.dialogues = this.dialogues.filter(dialog => dialog.id !== id);
			if (this.dialogues.length > 0) {
				// self._setActive(self.dialogues[self.dialogues.length - 1].vm);
				this.dialogues[this.dialogues.length - 1].active = true;
			}
		},
		onCloseFullscreen() {
			this.fullscreenComponent = null;
		}
	},
	beforeDestroy() {
		this.fork.dispose();
		this.$off('closeDialog');
	}
};
</script>
<style src="./dialog-manager.less" scoped></style>
