var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.iframeUrl && !_vm.isLoading && _vm.visible
    ? _c("div", { staticClass: "iframe-wrapper" }, [
        _c("iframe", {
          ref: "iframe",
          style: {
            width: _vm.options.width || "100%",
            height: _vm.options.height || "660px"
          },
          attrs: {
            src: _vm.iframeUrl,
            scrolling: "auto",
            frameborder: "0",
            allowfullscreen: "true",
            mozallowfullscreen: "true",
            webkitallowfullscreen: "true"
          }
        })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }