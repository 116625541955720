import { flattenDeep } from '@acx-xms/data-functions/dist';

export default {
	parameters(options) {
		return [options.argument.map(this.eval)];
	},
	async parametersAsync(options) {
		return [await Promise.all(options.argument.map(await this.evalAsync))];
	},
	evaluate(arrays) {
		return flattenDeep(arrays.filter(Boolean));
	}
};
