import Vue from 'vue';
Vue.directive('targeted-popup', (() => {
	const heightDelta = (bounding) => {
		return bounding.bottom - (window.innerHeight || document.documentElement.clientHeight);
	};
	const fitElementToScreen = (element, binding) => {
		// TODO: remove timeout
		// timeout was added to wait for render of tooltip's nested elements on mobile devices (US 96787)
		setTimeout(() => {
			const rect = binding.value.target.getBoundingClientRect();
			const offsetLeft = binding.value.offsetLeft === void 0 ? 5 : binding.value.offsetLeft;
			const offsetTop = binding.value.offsetTop === void 0 ? 0 : binding.value.offsetTop;
			const isMobile = binding.value.isMobile === void 0 ? false : binding.value.isMobile;
			const isLookup = binding.value.isLookup === void 0 ? false : binding.value.isLookup;
			const wrapperClass = '.' + (binding.value.wrapperClass || 'dropdown-wrapper');
			element.style.position = 'absolute';
			element.style.top = (rect.top - 5 + offsetTop) + 'px';

			const dropDown = element.querySelector(wrapperClass);
			let dropDownRect = dropDown.getBoundingClientRect();
			const defaultPosLeft = (rect.left + (rect.width / 2) + offsetLeft) + 'px';
			const leftTopTriangle = element.querySelector('.left-top-triangle');
			const topRightTriangle = element.querySelector('.top-right-triangle');

			const deltaH = heightDelta(dropDownRect);
			if (deltaH > 0 && !isLookup) {
				element.style.top = (rect.top - deltaH - 15) + 'px';
				element.style.left = isMobile ? defaultPosLeft : (rect.left - offsetLeft) + 'px';
				if (topRightTriangle) {
					topRightTriangle.classList.add('right-positioned');
					topRightTriangle.style.top = `${deltaH - 10}px`;
				}

				if (leftTopTriangle) {
					leftTopTriangle.style.top = deltaH + offsetTop + 10 + 'px';
				}
			} else if (isLookup && deltaH > 0) {
				element.style.top = (rect.top - element.clientHeight) + 'px';
				element.style.left = defaultPosLeft;
				element.classList.add('top-positioned');
				binding.value.target.classList.add('top-positioned');
			} else {
				if (element.classList.contains('top-positioned') && binding.value.target.classList.contains('top-positioned')) {
					element.classList.remove('top-positioned');
					binding.value.target.classList.remove('top-positioned');
				}
				element.style.left = defaultPosLeft;
				if (leftTopTriangle) {
					leftTopTriangle.style.top = 0;
				}
			}
			dropDownRect = dropDown.getBoundingClientRect();
			if (dropDownRect.left < 0) {
				element.style.left = `${parseInt(element.style.left) - dropDownRect.left + 10}px`;
				if (topRightTriangle) {
					topRightTriangle.style.right = `${-dropDownRect.left}px`;
				}
			}
			// check if element fits the screen
			if (element.offsetHeight >= document.documentElement.clientHeight) {
				element.style.height = (document.documentElement.clientHeight - 10) + 'px';
			}

			// we do not need this for desktop
			if (element.offsetWidth >= document.documentElement.clientWidth) {
				element.style.width = (document.documentElement.clientWidth - 10) + 'px';
			}
			// check if element position is inside screen bounds
			const posLeft = parseFloat(element.style.left);
			if (posLeft <= 0 || (element.offsetWidth + posLeft >= document.documentElement.clientWidth && isMobile)) {
				element.style.left = '5px';
			}

			const posTop = parseFloat(element.style.top);
			if (leftTopTriangle && posTop <= 0) {
				element.style.top = '5px';
				leftTopTriangle.style.top = parseFloat(leftTopTriangle.style.top) + posTop + 'px';
			}
		}, 0);
	};
	let fitElementDebounced;
	return {
		inserted(element, binding) {
			if (sc.utils.userAgent.userAgent.isIos) {
				window.scrollTo(0, 0); // iphone fix for keyboard adding scroll (US #96785)
			}
			fitElementToScreen(element, binding);
			fitElementDebounced = _.debounce(fitElementToScreen, 150).bind(this, element, binding);
			window.addEventListener('resize', fitElementDebounced);
		},
		componentUpdated(element, binding) {
			// shiftLeft parameter is passed only from mobile components
			// need to investigate why on component updated rect position is 0,0
			if (binding.value.isMobile === void 0 && !binding.value.isLookup) return;
			fitElementToScreen(element, binding);
		},
		unbind() {
			window.removeEventListener('resize', fitElementDebounced);
		}
	};
})());
