var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLinkToPrevent && _vm.showLink
    ? _c(
        "div",
        { staticClass: "clickable", class: _vm.cssClass },
        [
          _c(
            "router-link",
            { attrs: { to: _vm.href } },
            [
              _vm.visible && !_vm.imgObj
                ? _c("image-placeholder", {
                    style: {
                      width: _vm.identifyPixelWidth() + "px",
                      height: _vm.identifyPixelHeight() + "px"
                    },
                    attrs: {
                      isCustomImagePlaceholder:
                        _vm.options.isCustomImagePlaceholder,
                      cssClass: _vm.svgClass(),
                      placeholderEntityName:
                        "#entity-icons-" + _vm.placeholderEntityName
                    }
                  })
                : _vm.visible && _vm.imgObj
                ? _c("div", {
                    directives: [
                      {
                        name: "bg-image",
                        rawName: "v-bg-image",
                        value: _vm.imgObj,
                        expression: "imgObj"
                      }
                    ]
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm.isLinkToPrevent || !_vm.showLink
    ? _c(
        "div",
        { staticClass: "clickable not-clickable", class: _vm.cssClass },
        [
          _c(
            "a",
            { on: { click: _vm.redirect } },
            [
              _vm.visible && !_vm.imgObj
                ? _c("image-placeholder", {
                    style: {
                      width: _vm.identifyPixelWidth() + "px",
                      height: _vm.identifyPixelHeight() + "px"
                    },
                    attrs: {
                      isCustomImagePlaceholder:
                        _vm.options.isCustomImagePlaceholder,
                      cssClass: _vm.svgClass(),
                      placeholderEntityName:
                        "#entity-icons-" + _vm.placeholderEntityName
                    }
                  })
                : _vm.visible && _vm.imgObj
                ? _c("div", {
                    directives: [
                      {
                        name: "bg-image",
                        rawName: "v-bg-image",
                        value: _vm.imgObj,
                        expression: "imgObj"
                      }
                    ]
                  })
                : _vm._e()
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }