import { checkPrivilege, isAllowedEntity } from './Methods/action.methods';

export default {
	async enabled(options, evaluationContext, selection, fullselection) {
		const ref = sc.classes.get('entityReference', selection[0]);
		return isAllowedEntity(options.allowedEntityTypes, fullselection) && await checkPrivilege(ref.logicalname, 'Delete');
	},
	async execute(options, context, selection, fullselection) {
		const isFullSelection = options.takeFullSelection && context.eval(options.takeFullSelection);
		if (isFullSelection) {
			let records = [];
			Object.values(fullselection).forEach(selection => {
				selection.length && records.push(...selection);
			});
			selection = records;
		}
		sc.events.emit('vue.dialog.open', {
			title: context.eval(options.customDialogTitle) || await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('common.dictionary.confirmDlgCaption'),
			component: 'delete.dialog',
			maximizedHeight: 'auto',
			evaluationContext: context,
			params: {
				entities: selection,
				refreshEvent: context.eval(options.refreshEvent),
				customConfirmRemovingMessage: context.eval(options.customConfirmRemovingMessage),
				customConfirmText: context.eval(options.customConfirmText),
				actionMenuRefreshEvent: context.eval(options.actionMenuRefreshEvent),
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey),
				isFolder: context.eval(options.isFolder),
				isGuestSharedDoc: context.eval(options.isGuestSharedDoc)
			}
		});
	}
};
