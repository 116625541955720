var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-marketspace", attrs: { id: "page-wrapper" } },
    [
      _c(
        "div",
        { staticClass: "marketspace-layout-mobile-wrapper" },
        [
          !_vm.isAnonymous
            ? _c(
                "div",
                { staticClass: "marketspace-menu-wrapper" },
                [
                  _c("mobile-menu-marketspace", {
                    class: _vm.mainMenuState,
                    attrs: {
                      items: _vm.menu.menu.item,
                      state: _vm.mainMenuState,
                      userProfileMenu: _vm.userProfileMenu,
                      isAnonymous: _vm.isAnonymous,
                      marketspaceHeaderMenu: _vm.marketspaceHeaderMenu
                    },
                    on: { "route-clicked": _vm.switchMenuState }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.mainMenuState === "iconsAndText"
            ? _c("div", { staticClass: "marketspace-menu-overlay" })
            : _vm._e(),
          _vm._v(" "),
          _vm.isAnonymous
            ? _c("div", { staticClass: "marketspace-anon-logo-wrapper" }, [
                _c("svg", { staticClass: "svg-icon" }, [
                  _c("use", {
                    attrs: { "xlink:href": "#tenant-logo-anon-logo" }
                  })
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "marketspace-content-wrapper",
              class: [_vm.mainMenuState, !_vm.isAnonymous ? "hasMenu" : ""]
            },
            [
              _c(
                "div",
                { staticClass: "main-content-area adaptive" },
                [
                  _c(
                    "div",
                    { staticClass: "marketspace-components-area adaptive" },
                    [
                      _vm.ready
                        ? _c(
                            "div",
                            { staticClass: "component" },
                            [
                              _c("router-view", {
                                key: _vm.$route.fullPath,
                                attrs: { defaultRoute: _vm.defaultMenuItem }
                              }),
                              _vm._v(" "),
                              _c(
                                "keep-alive",
                                {
                                  attrs: {
                                    include: "mobile-route-search-results"
                                  }
                                },
                                [
                                  _c(
                                    "router-view",
                                    {
                                      key: _vm.$route.fullPath,
                                      attrs: {
                                        name: "mobile",
                                        defaultRoute: _vm.defaultMenuItem
                                      }
                                    },
                                    [
                                      !_vm.isAnonymous
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "collapse-menu-btn",
                                              attrs: { slot: "msMenu" },
                                              on: {
                                                click: _vm.switchMenuState
                                              },
                                              slot: "msMenu"
                                            },
                                            [
                                              _c(
                                                "svg",
                                                { staticClass: "svg-icon" },
                                                [
                                                  _c("use", {
                                                    staticClass:
                                                      "icon-ms-btn-menu",
                                                    attrs: {
                                                      "xlink:href":
                                                        "#layout-icons-ms-btn-menu"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm.sidePanelActive
                    ? _c(
                        _vm.sidePanelComponent,
                        _vm._b(
                          {
                            directives: [
                              {
                                name: "clickoutside",
                                rawName: "v-clickoutside",
                                value: {
                                  visible: _vm.sidePanelActive,
                                  callback: _vm.toggleSidePanel
                                },
                                expression:
                                  "{ visible: sidePanelActive, callback: toggleSidePanel}"
                              }
                            ],
                            tag: "component",
                            staticClass: "side-panel",
                            on: { closePanel: _vm.closeSidePanel }
                          },
                          "component",
                          _vm.sidePanelOptions,
                          false
                        )
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialogmanager-footer" },
            [_c("old-dialog-manager"), _vm._v(" "), _c("dialog-manager")],
            1
          ),
          _vm._v(" "),
          _c("absolute-menu-popup"),
          _vm._v(" "),
          _c("absolute-template-popup")
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }