import { isArray } from '@acx-xms/data-functions/dist';
import ExpressionsResolver from './expressionsResolver';

let _anonymousContext;

class EvaluationContext {
	constructor(datasource = {}, parentContext) {
		this.entity = sc.classes.get('entityReference', datasource);
		this.datasource = datasource;
		parentContext && (this.datasource.parentContext = parentContext.datasource);

		this.eval = (expression) => {
			if (expression && expression.$type) {
				const expr = ExpressionsResolver.resolve(expression.$type);
				if (!expr) {
					throw new Error(`${expression.$type} is not known expression type`);
				}
				let mappedParams = expr.parameters.call(this, expression);
				if (mappedParams === null || mappedParams === void 0) {
					mappedParams = [];// TODO: check if call function without parameters directly could give some performance improvements
				}
				return expr.evaluate(...mappedParams);
			} else {
				return expression;
			}
		};
		this.evalAsync = async (expression) => {
			if (expression && expression.$type) {
				const expr = ExpressionsResolver.resolve(expression.$type);
				if (!expr) {
					throw new Error(`${expression.$type} is not known expression type`);
				}
				let mappedParams = expr.parametersAsync ? await expr.parametersAsync.call(this, expression) : expr.parameters.call(this, expression);
				if (mappedParams === null || mappedParams === void 0) {
					mappedParams = [];// TODO: check if call function without parameters directly could give some performance improvements
				}
				return expr.evaluateAsync ? await expr.evaluateAsync(...mappedParams) : expr.evaluate(...mappedParams);
			} else {
				return expression;
			}
		};
		this.evalAsyncFake = (expression) => {
			// TODO: workaround. See related comments
			if (expression && expression.$type) {
				const expr = ExpressionsResolver.resolve(expression.$type);
				if (!expr) {
					throw new Error(`${expression.$type} is not known expression type`);
				}
				let mappedParams = expr.parametersAsyncFake ? expr.parametersAsyncFake.call(this, expression) : expr.parameters.call(this, expression);
				if (mappedParams === null || mappedParams === void 0) {
					mappedParams = [];// TODO: check if call function without parameters directly could give some performance improvements
				}
				return expr.evaluateAsyncFake ? expr.evaluateAsyncFake(...mappedParams) : expr.evaluate(...mappedParams);
			} else {
				return expression;
			}
		};
		this.get = (path) => {
			return sc.utils.findProperty(this.datasource, isArray(path) ? path.slice() : path.split('.'), true);
		};
		this.queries = new Queries();
		this.scope = new ScopeObject();
		this.emitQuery = async (key, q) => {
			let executing = false;
			const obj = {
				query: sc.classes.get(q.query.$type, q.query, this),
				data: [],
				state: '',
				enable: this.eval(q.enable)

			};
			this.queries.set(key, obj);

			const queryExec = _.debounce(async () => {
				if (executing) {
					return;
				}
				obj.data = [];
				if (obj.enable) {
					executing = true;
					obj.state = 'executing';
					sc.classes.get('edge.dataProvider').search(await obj.query.toQuery())
						.done(function (data) {
							obj.data = obj.data.concat(data.Results);
							obj.state = 'done';
						})
						.fail(function () {
							obj.state = 'failed';
						})
						.always(function () {
							executing = false;
						});
				} else {
					obj.state = 'inactive';
				}
			}, 500);

			await queryExec();

			const getQueryEntities = () => {
				return obj.query.toQuery().entities;
			};

			return {
				reEvaluate: queryExec,
				getEntities: getQueryEntities,
				results: obj.data,
				state: obj.state,
				allowAutoRefresh: q.allowAutoRefresh,
				forceReload: q.forceReload
			};
		};
	}

	buildPath(path) {
		return isArray(path) ? path.slice() : path.split('.');
	}
}

class Queries {
	constructor() {
		this._dict = {};
	}

	get(key) {
		return this._dict[key];
	}

	set(key, data) {
		this._dict[key] = data;
	}
}

class ScopeObject {
	constructor() {
		this._items = {};
	}

	queue(key, value) {
		this.getQueue(key).unshift(value);
	}

	get(key) {
		const queue = this.getQueue(key);
		if (queue.length === 0) {
			return null;
		}
		return queue[0];
	}

	dequeue(key) {
		this.getQueue(key).shift();
	}

	getQueue(key) {
		key = key.toLowerCase();
		let result = this._items[key];
		if (!result) {
			result = this._items[key] = [];
		}
		return result;
	}
}

export function createEvaluationContext(datasource, parent) {
	return new EvaluationContext(datasource, parent);
}

export async function evaluateAsync(expression) {
	if (!_anonymousContext) {
		_anonymousContext = createEvaluationContext(null);
	}
	return await _anonymousContext.evalAsync(expression);
}

export function evaluate(expression) {
	if (!_anonymousContext) {
		_anonymousContext = createEvaluationContext(null);
	}
	return _anonymousContext.eval(expression);
}

export function evaluateAsyncFake(expression) {
	if (!_anonymousContext) {
		_anonymousContext = createEvaluationContext(null);
	}
	return _anonymousContext.evalAsyncFake(expression);
}
