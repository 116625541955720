var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "thumbnail-annotated", class: _vm.cssClass },
    [
      _vm.options.thumbnail
        ? _c("control", {
            staticClass: "thumbnail",
            attrs: {
              name: _vm.options.thumbnail.$type,
              contentProps: _vm.options.thumbnail,
              evaluationContext: _vm.evaluationContext
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "info" },
        [
          _vm._l(_vm.options.title, function(title, index) {
            return _vm.options.title
              ? _c("control", {
                  key: index,
                  staticClass: "info-title-row",
                  attrs: {
                    name: title.$type,
                    contentProps: title,
                    evaluationContext: _vm.evaluationContext
                  }
                })
              : _vm._e()
          }),
          _vm._v(" "),
          _vm.options.info
            ? _c(
                "div",
                {
                  staticClass: "info-holder sc-grid",
                  class: "columns-" + _vm.options.infoColumns
                },
                _vm._l(_vm.options.info, function(info, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "grid-cell",
                      class: "column-span-" + _vm.options.colspan
                    },
                    [
                      info
                        ? _c("control", {
                            attrs: {
                              name: info.$type,
                              contentProps: info,
                              evaluationContext: _vm.evaluationContext
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                })
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }