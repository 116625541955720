export default {
	async enabled(options, context, selection) {
		return true;
	},
	async execute(options, context, selection) {
		sc.events.emit('vue.dialog.open', {
			title: 'Import from Excel',
			maximizedWidth: '75%',
			maximizedHeight: '90%',
			component: 'import.dialog',
			logicalname: context.eval(options.logicalname),
			displayname: context.eval(options.displayname),
			refreshEvent: context.eval(options.refreshEvent),
			columnsSize: options.columnsSize,
			dataExamples: options.dataExamples.map(example => context.eval(example)),
			fields: options.fields.map(field => context.eval(field)),
			failureOptions: options.failureOptions
		});
	}
};
