export default {
	parameters(options) {
		return [
			this.eval(options.value),
			this.eval(options.precision)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.value),
			await this.evalAsync(options.precision)
		];
	},
	evaluate(value, precision) {
		let val = parseFloat(value);
		const prec = parseFloat(precision);

		if (isNaN(val)) {
			return null;
		} else {
			if (isNaN(prec)) {
				return val;
			} else {
				val = val.toFixed(prec);
				return parseFloat(val);
			}
		}
	}
};
