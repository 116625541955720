import authAxios from '@/Data/auth-axios';
import { Get } from '@acx-xms/data-functions/dist';

let schema = null;
export const typesDictionary = {};

export async function LoadSchema() {
	const resp = await authAxios.get('/api/schema');
	schema = resp.data;
}

export function fillTypesDictionary() {
	Object.keys(schema.definitions).forEach(key => {
		if (Object.prototype.hasOwnProperty.call(schema.definitions[key], 'properties')) {
			if (schema.definitions[key].properties.$type) {
				typesDictionary[key] = schema.definitions[key].properties.$type.default;
			}
		}
	});
}

function findGeneration(base) {
	const children = schema.definitions[base].oneOf;
	const results = [];
	if (children) {
		const generationNames = children.map(child => {
			return child.$ref.replace('#/definitions/', '');
		});
		generationNames.forEach(name => {
			results.push(schema.definitions[name]);
		});
	} else {
		results.push(schema.definitions[base]);
	}

	return results;
}

export function ResolveByBase(base) {
	const result = {};
	const concatGeneration = (obj, arr) => {
		arr.forEach((item) => {
			obj[item.title] = item;
		});
	};
	const generation = findGeneration(base);
	generation.forEach((type) => {
		// to prevent infinite loops
		if (type.title !== base) {
			const children = ResolveByBase(type.title);
			if (children) {
				concatGeneration(result, children);
			} else {
				concatGeneration(result, [type]);
			}
		} else {
			concatGeneration(result, [type]);
		}
	});

	return Object.values(result);
}

export function ResolveByType(type) {
	return schema.definitions[type];
}
export async function getTemplateRecord(id) {
	return await Get('configtemplate', id);
}
