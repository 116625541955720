import { isActive } from './Methods/action.methods';
import {
	generateGuid, Search, CreateBulk
} from '@acx-xms/data-functions/dist';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	getPreferenceRecord(parentRecord, options, context) {
		const dfd = $.Deferred();

		const preferenceFilters = [];

		preferenceFilters.push(sc.classes.get('termFacet.filter', {
			logicalName: 'collaborationroomid.id',
			query: [parentRecord.id]
		}));
		preferenceFilters.push(sc.classes.get('offsetSize.filter', 1));
		preferenceFilters.push(sc.classes.get('selectedFields.filter', [
			{ logicalname: 'collaborationroomid' },
			{ logicalname: 'saleorlease' },
			{ logicalname: 'propertytype.id' },
			{ logicalname: 'propertyclass' },
			{ logicalname: 'priceminimum' },
			{ logicalname: 'pricemaximum' },
			{ logicalname: 'areaminimum' },
			{ logicalname: 'areamaximum' },
			{ logicalname: 'requiredmoveindate' },
			{ logicalname: 'geography1' },
			{ logicalname: 'geography2' },
			{ logicalname: 'preferencesaleorleaselookupsaleorlease.name' },
			{ logicalname: 'preferencepropertytypelookuppropertytype.name' }
		]));
		sc.classes.get('edge.dataProvider').search({
			entities: ['preference'],
			filters: preferenceFilters,
			resultFormat: 'source'
		})
			.done(function (data) {
				const keyField = parentRecord.logicalname + 'id';
				const result = data.Results && data.Results.length ? data.Results[0] : null;

				result && result.Source[keyField].id === parentRecord.id ? dfd.resolve(result) : dfd.resolve(null);
			});

		return dfd.promise();
	},

	async enabled(options, evaluationContext, selection) {
		if (!await isActive(evaluationContext, selection)) {
			return false;
		}

		if ((selection && selection.length === 1) || (evaluationContext.entity && evaluationContext.entity.id)) {
			const enableOptions = options.enable !== undefined ? options.enable : true;

			const enable = evaluationContext.entity && evaluationContext.entity.id
				? await evaluationContext.evalAsync(enableOptions)
				: await createEvaluationContext(selection[0]).evalAsync(enableOptions);

			return enable;
		}

		return false;
	},

	execute(options, context, selection) {
		this.parentRef = (selection && selection.length > 0) ? sc.classes.get('entityReference', selection[0]) : context.entity;
		const componentSetPromise = sc.classes.get('marketspaceComponents.dataProvider').getComponentSetById(context.eval(options.componentsetName));
		const preferencePromise = this.getPreferenceRecord(this.parentRef, options, context);
		const self = this;
		sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('crAddListingPlugin.title').then((title) => {
			$.when(componentSetPromise, preferencePromise).then((componentSet, preference) => {
				sc.events.emit('vue.dialog.open', {
					icon: 'entity-icons-deal-small',
					title,
					allowPin: false,
					component: 'ms.addlisting.dialog',
					maximizedWidth: '90%',
					maximizedHeight: '100%',
					params: {
						componentSet,
						modalNamespace: 'addlistingpopup',
						filterMappingRules: context.eval(options.filterMappingRules),
						preference,
						searchResultComponentName: options.searchResultComponentName,
						saveCallback: function () {
							sc.events.emit(context.eval(options.refreshNamespace) + '.searching');
						},
						modalDialogCallback: function (listings, availabilities) {
							return self.addListings(listings, availabilities && availabilities.length > 0 ? availabilities : [], this.saveCallback);
						},
						toastMessageKey: context.eval(options.toastMessageKey),
						informationDialogTextKey: context.eval(options.informationDialogTextKey),
						parentRef: this.parentRef,
						isNotificationAllowed: context.eval(options.isNotificationAllowed)
					}
				});
			});
		});
	},

	async addListings(listings, availabilities, saveCallback) {
		const selection = {};

		selection.listings = [];
		selection.availabilities = [];
		listings.forEach((listing) => {
			const _crlistingData = {
				name: sc.utils.findProperty(listing, 'Source.name', true),
				listingid: sc.classes.get('entityReference', listing),
				collaborationroomid: ko.unwrap(this.parentRef),
				type: 'crlisting',
				interestlevel: null,
				archived: false
			};

			selection.listings.push(_crlistingData);
		});

		availabilities.forEach((availability) => {
			const _cravailabilityData = {
				name: availability.Name,
				availabilityid: sc.classes.get('entityReference', availability),
				listingid: sc.utils.findProperty(availability, 'Source.listingid', true),
				collaborationroomid: ko.unwrap(this.parentRef),
				interestlevel: null,
				archived: false,
				type: 'cravailability'
			};

			selection.availabilities.push(_cravailabilityData);
		});

		await this.saveSelection(selection);
		saveCallback && saveCallback();
	},

	async saveSelection(selection) {
		const parentId = ko.unwrap(this.parentRef).id;
		let errors = [];

		this.filters = [
			sc.classes.get('termFacet.filter', {
				logicalName: 'collaborationroomid.id',
				query: [parentId]
			}).fillQuery(),
			sc.classes.get('offsetSize.filter', 1000)
		];

		const data = await this.getCrlistings();
		const existingCRListings = data.Results;
		const initLength = selection.listings.length;
		selection = await this.filterSelection(selection, existingCRListings, initLength);
		const total = (selection.listings.length ? 1 : 0) + (selection.availabilities.length ? 1 : 0);

		if (total === 0) {
			return;
		}

		this.progress = {
			total,
			processed: 0,
			message: await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('crAddListingPlugin.progress.message'),
			title: await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('crAddListingPlugin.progress.updateCR.title')
		};

		sc.events.emit('vue.progressDialog.open', this.progress);

		try {
			const createCRErrors = await this.createCrlistings(selection.listings, existingCRListings);
			const createAvailabilityErrors = await this.createCravailabilities(selection.availabilities, existingCRListings);

			errors = [...createCRErrors, ...createAvailabilityErrors];
		} finally {
			if (errors.length) {
				const errorDetailsMessages = [];

				errors.forEach((response) => {
					let message = response;
					if (!(typeof response === 'string') && response && response.responseText) {
						message = response.responseText;
					}
					errorDetailsMessages.push(message);
				});

				sc.utils.errorMessage.byMessage('Some records were skipped.', errorDetailsMessages.join('\r\n'));
			}
		}
	},

	async filterSelection(selection, existingCRListings, initLength) {
		let existingListingsIds = existingCRListings.map(item => {
			return sc.utils.findProperty(item, 'Source.listingid.id', true);
		});

		existingListingsIds = [...new Set(existingListingsIds)];

		selection.listings = selection.listings.filter(item => {
			return !existingListingsIds.find(id => {
				return id === sc.utils.findProperty(item, 'listingid.id', true);
			});
		});

		if (selection.listings.length !== initLength) {
			sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('crAddListingPlugin.warning.recordsAllreadyExists').then((text) => {
				this.displayError(text);
			});
		}

		const data = await this.getCravailabilities();
		selection.availabilities = this.filterAvailabilities(selection.availabilities, data);

		return selection;
	},

	filterAvailabilities(availabilities, data) {
		const existingAvailabilityIds = data.Results.map(item => {
			return sc.utils.findProperty(item, 'Source.availabilityid.id', true);
		});

		return availabilities.filter(item => {
			return !existingAvailabilityIds.find(id => {
				return id === sc.utils.findProperty(item, 'availabilityid.id', true);
			});
		});
	},

	getCrlistings() {
		return Search(['crlisting'], [
			...this.filters,
			sc.classes.get('selectedFields.filter', [
				{ logicalname: 'listingid.id' }
			]).fillQuery()
		]);
	},

	getCravailabilities() {
		return Search(['cravailability'], [
			...this.filters,
			sc.classes.get('selectedFields.filter', [
				{ logicalname: 'availabilityid.id' }
			]).fillQuery()
		]);
	},

	createBulkBody(body, url) {
		return {
			id: generateGuid(),
			url,
			method: 'POST',
			body
		};
	},

	async createCrlistings(listings, existingCRListings) {
		const errors = [];

		const listingRecords = [];
		listings.forEach((listing) => {
			_.pick(listing, ['name', 'listingid', 'collaborationroomid', 'archived', 'interestlevel', 'type']);
			listingRecords.push(this.createBulkBody(listing, 'crlisting'));
		});

		const promise = listingRecords.length
			? CreateBulk(listingRecords, { async: true })
			: Promise.resolve({ Responses: [] });
		const { Responses } = await promise;

		Responses.forEach((listing) => {
			existingCRListings.push({
				Id: listing.Body.crlistingid,
				Type: 'crlisting',
				Source: listing.Body
			});
		});

		this.progress.processed++;

		return errors;
	},

	async createCravailabilities(availabilities, existingCRListings) {
		const errors = [];
		const availabilityRecords = [];

		availabilities.forEach((availability) => {
			const filtered = existingCRListings.filter(function (crlisting) {
				return crlisting.Source.listingid.id === availability.listingid.id;
			});

			if (filtered.length) {
				availability.crlistingid = {
					id: filtered[0].Id,
					logicalname: filtered[0].Type
				};

				availabilityRecords.push(this.createBulkBody(availability, 'cravailability'));
			}
		});

		const promise = availabilityRecords.length
			? CreateBulk(availabilityRecords, { async: true })
			: Promise.resolve({ Responses: [] });
		await promise;

		this.progress.processed++;

		return errors;
	},

	// todo change after moving utility dialogues to vue
	displayError(message) {
		sc.events.emit('dialog.open', {
			title: 'Warning',
			allowMinimize: false,
			allowMaximize: false,
			allowPin: false,
			allowClose: false,
			component: 'dialog.info',
			params: {
				message,
				buttons: [
					{ title: 'OK' }
				]
			}
		});
	}
};
