<template>
	<div class="component-search-results is-parent mobile" v-if="visible">
		<filters-panel v-if="options.filterPanel" v-show="showFilterPanel"
					   :options="options.filterPanel"
					   :evaluationContext="filterPanelEvaluationContext"
					   :isLoading="isLoading"
					   :entityName="logicalName"
					   :stateNamespace="stateNamespace"></filters-panel>
		<div class="search-results-wrapper">
			<div class="header" v-show="!hideHeader">
				<div class="top-wrapper">
					<slot name="msMenu" />
					<search-field class="mobile" />
					<sortpanel class="sortby-filter-wrapper mobile"
							   v-show="sortByFields"
							   v-if="options.sortByFields"
							   :fields="options.sortByFields"
							   :entityName="logicalName"
							   :evaluationContext="evaluationContext"
							   :scrollNamespace="scrollNamespace"
							   :namespace="namespace"
							   :internalNamespace="internalNamespace"
							   :stateNamespace="stateNamespace"></sortpanel>

					<view-switcher :items="items" :stateNamespace="stateNamespace" class="mobile" />
				</div>
				<div class="bottom-wrapper">
					<div class="region">
						<div v-if="options.filterPanel" class="filter-button active" @click="toggleFilterPanel()" v-bind:class="{ open: !!showFilterPanel }">
							<svg class='svg-icon'>
								<use class="'ms-filter-icon'" xlink:href="#layout-icons-ms-filter-icon"></use>
							</svg>
						</div>
						<slot name="title-slot"><div class="title h1">{{ title }}</div></slot>
						<slot name="toggle-slot">
							<results-toggle :namespace="namespace"
											class="toggle-padding"
											v-if="withToggleAll"
											:internalNamespace="internalNamespace"
											:toggleNamespace="toggleNamespace"
											:isParent="true"></results-toggle>
						</slot>
						<MobileComponentSetSearchResultsSelectedRecords v-bind:stateNamespace="stateNamespace"
																		v-bind:showSelectedCount="showSelectedCount"
																		v-bind:targetEntity="logicalName" />
					</div>
					<div class="region">
						<reactive-action-menu :options="actionMenuComponent"
											  :stateNamespace="stateNamespace"
											  v-bind:class="{'without-border': !(sortByFields)&&(items||[]).length<2}"
											  v-if="actionMenuComponent && (actionMenuComponent.menu || actionMenuComponent.toolbarActions)"
											  :evaluationContext="evaluationContext"
											  class="mobile"></reactive-action-menu>
					</div>
				</div>
			</div>
			<div class="content-wrapper" v-show="!isCollapsed" v-bind:class="{'withPanel':showFilterPanel,'loading':isLoading}">
				<div class="content">
					<!-- remove results.results after we migrate all views to vue-->
					<div class="content-inherit-height"
						 v-bind:class="{'with-footer': !!results.total}"
						 v-bind:is="content.name"
						 v-bind:options="content"
						 v-bind:isActive="isActive"
						 v-bind:hasNoMatchesFoundSubTitle="true"
						 v-bind:results="results.results"
						 v-bind:stateNamespace="stateNamespace"
						 v-bind:paginationParams="paginationParams"></div>
					<div class="loading-overlay" v-show="isLoading"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
// TODO: investigate if we need to load styles like this
import '@acx-xms/styles-mixins/style.mixins.less';
import RouteSearchResultsMixins from './route-search-results.mixins';
import MobileComponentSetSearchResultsSelectedRecords from './Views/mobile-component-set-search-results-selectedrecords';

export default {
	name: 'mobile-route-search-results',
	mixins: [RouteSearchResultsMixins],
	components: { MobileComponentSetSearchResultsSelectedRecords }
};
</script>
<style src="./mobile-route-search-results.less" scoped></style>
<!--<style src="./../../style.mixins.less" scoped></style>v-->
<!--TODO:investigate for future-->
<!--<style src="acx-xms/styles-mixins/style.mixins.less" scoped></style>-->
