var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c("image-gallery-small-base", {
        attrs: {
          currentIndex: _vm.currentIndex,
          showArrows: _vm.showArrows,
          images: _vm.images,
          reference: _vm.reference,
          placeholderEntityName: _vm.placeholderEntityName,
          isCustomImagePlaceholder: _vm.options.isCustomImagePlaceholder,
          galleryStylesObj: _vm.galleryStylesObj
        },
        on: { next: _vm.next, prev: _vm.prev, openDialog: _vm.openDialog }
      })
    : _c("i", { staticClass: "loading-overlay" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }