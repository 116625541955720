var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "marketspace-conversation-menu",
      class: _vm.isAnonymous && "anonymous",
      attrs: { title: "Favorites" },
      on: { click: _vm.onClick }
    },
    [
      _c("svg", { staticClass: "svg-icon svg-20" }, [
        _c("use", {
          staticClass: "icon",
          attrs: { "xlink:href": "#layout-icons-favorite-false" }
        })
      ]),
      _vm._v(" "),
      _c("counter", { attrs: { count: _vm.count, maxCount: 99 } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }