var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.severalSortFields
    ? _c(
        "div",
        {
          directives: [
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: { options: _vm.$parent.options, stateNamespace: "sort" },
              expression: "{ options: $parent.options, stateNamespace: 'sort' }"
            }
          ],
          staticClass: "sortby-filter-container inline-block"
        },
        [
          _c("div", { staticClass: "sort-panel" }, [
            _c(
              "svg",
              {
                ref: "sortPanel",
                staticClass: "svg-icon toggle-sortpanel",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.openSortPanel($event)
                  }
                }
              },
              [
                _c("use", {
                  staticClass: "layout-icons-ms-sort",
                  attrs: { "xlink:href": "#layout-icons-ms-sort" }
                })
              ]
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }