<template>
	<div :class="['component-search-results is-parent', cssClass]">
		<ComponentSearchDetails
			v-if="details"
			:key="details.$key"
			:breadcrumbs="breadcrumbs"
			:options="details"
		/>
		<FiltersPanel
			v-if="options.filterPanel"
			v-show="!details && showFilterPanel"
			:options="options.filterPanel"
			:evaluationContext="filterPanelEvaluationContext"
			:isLoading="isLoading"
			:entityName="logicalName"
			:stateNamespace="stateNamespace"
		/>
		<div v-if="!details" class="search-results-wrapper">
			<div v-show="!hideHeader" class="header">
				<div class="region">
					<div
						v-if="options.filterPanel"
						@click="toggleFilterPanel"
						:class="['filter-button active', { open: !!showFilterPanel }]"
					>
						<svg class='svg-icon'>
							<use class="ms-filter-icon" xlink:href="#layout-icons-ms-filter-icon" />
						</svg>
					</div>
					<slot name="title-slot">
						<div class="title">{{ title }}</div>
					</slot>
					<slot name="toggle-slot">
						<ResultsToggle
							v-if="withToggleAll"
							:namespace="namespace"
							:internalNamespace="internalNamespace"
							:toggleNamespace="toggleNamespace"
							:isParent="true"
							class="toggle-padding"
						/>
					</slot>
					<ComponentSetSearchResultsSelectedRecords
						:stateNamespace="stateNamespace"
						:showSelectedCount="showSelectedCount"
						:targetEntity="targetEntity"
						:overridedNames="overridedNames"
					/>
				</div>
				<div class="region">
					<SearchField v-if="!hideSearchField" :searchString="searchQuery" />
					<SortPanel
						v-if="options.sortByFields"
						v-show="sortByFields"
						:fields="options.sortByFields"
						:entityName="logicalName"
						:evaluationContext="evaluationContext"
						:scrollNamespace="scrollNamespace"
						:namespace="namespace"
						:internalNamespace="internalNamespace"
						:stateNamespace="stateNamespace"
						:saveViewState="options.saveViewState"
						:defaultSelectedView='selectedViewItem'
						:item="items && items[0]"
						class="sortby-filter-wrapper"
					/>
					<ViewSwitcher
						:items="items"
						:defaultSelectedView='selectedViewItem'
						:stateNamespace="stateNamespace"
						:saveViewState="options.saveViewState"
					/>
					<slot name="reactive-menu">
						<ReactiveActionMenu
							v-if="actionMenuComponent && (actionMenuComponent.menu || actionMenuComponent.toolbarActions)"
							:options="actionMenuComponent"
							:class="{'without-border': !sortByFields && (items || []).length < 2 }"
							:evaluationContext="evaluationContext"
							:stateNamespace="stateNamespace"
						/>
					</slot>
				</div>
			</div>
			<div v-show="!isCollapsed" :class="['content-wrapper', { 'withPanel': showFilterPanel, 'loading': isLoading }]">
				<div class="content">
					<!-- TODO: remove results.results after we migrate all views to vue-->
					<keep-alive>
						<div
							:is="content.name"
							:class="['component-view', { 'with-footer': showPaging  && !!results.total }]"
							:options="content"
							:results="results.results"
							:evaluationContext="evaluationContext"
							:stateNamespace="stateNamespace"
							:combinedNamespace="!options.filterPanel ? combinedNamespace : ''"
							:paginationParams="paginationParams"
						/>
					</keep-alive>
					<div v-show="isLoading" class="loading-overlay" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import helperMethods from './../component-set-evaluation-context-helper.methods';
import ComponentSearchResultsMixins from './component-search-results.mixins';

// import ReactiveActionMenu from './../../Menu/reactive-action-menu';
// import ComponentSetSearchResultsThumbnailviewColumned from './Views/thumbnailview-columned/search-results-thumbnailview-columned'
// import ComponentSetSearchResultsThumbnailview from './Views/thumbnail-view/search-results-thumbnail-view'
// import ComponentSetSearchResultsSelectedRecords from './Views/component-set-search-results-selectedrecords'
// import ComponentSetSearchResultsTableView from './Views/table-view/component-set-search-results-table-view'
// import ComponentSetSearchResultsDocumentTreeView from './Views/document-folder-tree-view/document-folder-tree-view';
// import ComponentSetSearchResultsMapView from './Views/map-view/search-results-map-view'
import ComponentSetSearchResultsMapOnlyView from './Views/map-view/search-results-map-only-view';
import ComponentSetSearchResultsWithPreview from './Views/with-preview/component-set-search-results-with-preview';
import ComponentSetMobileSearchResultsMapOnlyView from './Views/map-view/mobile-search-results-map-only-view';
import ComponentSetMobileSearchResultsTableView from './Views/table-view/component-set-mobile-search-results-table-view';
import SortPanel from './../../SortPanel/sortpanel';
import FiltersPanel from './../../FiltersPanel/filters-panel/filters-panel';
import ComponentSearchDetails from './../Details/component-search-details';

const ComponentSetSearchResultsThumbnailviewColumned = () => import('./Views/thumbnailview-columned/search-results-thumbnailview-columned');
const ComponentSetSearchResultsDocumentTreeView = () => import('./Views/document-folder-tree-view/document-folder-tree-view');
const ComponentSetSearchResultsSelectedRecords = () => import('./Views/component-set-search-results-selectedrecords');
const ComponentSetSearchResultsThumbnailview = () => import('./Views/thumbnail-view/search-results-thumbnail-view');
const ComponentSetSearchResultsTableView = () => import('./Views/table-view/component-set-search-results-table-view');
const ComponentSetSearchResultsMapView = () => import('./Views/map-view/search-results-map-view');
const ReactiveActionMenu = () => import('./../../Menu/reactive-action-menu');
// const  = () => import();
const ResultsToggle = () => import('./results-toggle/results-toggle');
const ViewSwitcher = () => import('./view-switcher');
const SearchField = () => import('./search-field/search-field');
// import ResultsToggle from './results-toggle/results-toggle'
// import SearchField from './search-field/search-field';
// import ViewSwitcher from './view-switcher'

export default {
	name: 'component-search-results',
	mixins: [ComponentSearchResultsMixins],
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: {
		ComponentSetSearchResultsThumbnailviewColumned,
		ComponentSetMobileSearchResultsMapOnlyView,
		ComponentSetSearchResultsDocumentTreeView,
		ComponentSetSearchResultsSelectedRecords,
		ComponentSetMobileSearchResultsTableView,
		ComponentSetSearchResultsThumbnailview,
		ComponentSetSearchResultsMapOnlyView,
		ComponentSetSearchResultsWithPreview,
		ComponentSetSearchResultsTableView,
		ComponentSetSearchResultsMapView,
		ComponentSearchDetails,
		ReactiveActionMenu,
		ResultsToggle,
		FiltersPanel,
		ViewSwitcher,
		SearchField,
		SortPanel
	},
	data() {
		return {
			showFilterPanel: (this.options.filterPanel && this.options.filterPanel.alwaysVisible != null) ? this.options.filterPanel.alwaysVisible : false,
			cssClass: (this.options.cssClass) ? helperMethods.eval(this.evaluationContext, this.options.cssClass) : '',
			isCollapsed: false,
			details: null,
			title: helperMethods.eval(this.evaluationContext, this.options.title),
			actionMenuComponent: {},
			content: {},
			results: [],
			selectedViewTitle: '',
			isLoading: false,
			paginationParams: {
				showPaging: true,
				pageSize: 0,
				total: 0,
				activePages: [1],
				from: 0
			},
			items: [],
			breadcrumbs: [],
			filterPanelEvaluationContext: this.evaluationContext,
			filters: null,
			withToggleAll: false,
			showSelectedCount: true,
			combinedNamespace: null,
			stateNamespace: helperMethods.eval(this.evaluationContext, this.options.stateNamespace),
			trackUserActivity: false,
			selectedViewItem: null
		};
	},
	computed: {
		filtersReady() {
			return this.$store.getters[`${this.stateNamespace}/filtersReady`](this.logicalName);
		}
	},
	created() {
		this.fork = sc.events.fork();

		this.initVariables();
		if (this.options.defaultFilters) {
			const filters = {
				listing: {
					entity: 'listing',
					logicalName: 'idsFilter',
					filter: this.options.defaultFilters.listing
				},
				availability: {
					entity: 'availability',
					logicalName: 'idsFilter',
					filter: this.options.defaultFilters.availability
				}
			};
			Object.keys(filters).forEach(key => {
				this.$store.commit(`${this.stateNamespace}/addOrUpdateFilter`, filters[key]);
				this.$store.commit(`${this.stateNamespace}/addDefaultFilter`, filters[key]);
			});
		}
		this.initFilters();
		this.initSearchableFields();
		this.initialization();
		this.initItemsPerPageAndPagionation();

		this.storageKey = `${sc.cluster.configKey}_${this.namespace}${this.internalNamespace}.component-set-component-search-results.state`;

		this.breadcrumbs = [{ title: this.title }];
		this.$on('pushBreadcrumb', (bread) => {
			const checkLastBread = (bread) => {
				const id = this.breadcrumbs[this.breadcrumbs.length - 1].id;
				return id && id === bread.id;
			};
			// to prevent pushing to breadcrumbs the same record as the last one #135294
			if (!(this.breadcrumbs.length > 1 && checkLastBread(bread))) this.breadcrumbs.push(bread);
		});
		this.$on('replaceBreadcrumbs', (bread, index) => {
			// to preserve the parentView
			bread.options.parentView = _.cloneDeep(this.breadcrumbs[this.breadcrumbs.length - index].options.parentView);
			this.breadcrumbs[this.breadcrumbs.length - index] = bread;
		});
		// subscriptions
		this.$on('.navigateBreadcrumbs', (index) => {
			this._hideDetails(index);
		});

		if (this.options.filterPanel) {
			this.selectedFilters = [];

			this.rootSearchSubscription = this.$root.$on(this.stateNamespace + 'search', () => {
				this._search();
			});
		}
		this.$on('.sortChanged', this._search);
		this.$on('.pageChanged', (activePages) => {
			this.paginationParams.from = (activePages[0] - 1) * this.paginationParams.pageSize;
			this.paginationParams.activePages = activePages;
			this._search(true);

			this.trackUserActivity && this.trackActivity();
		});

		this._hideDetails = (index = 1) => {
			this.breadcrumbs.splice(-index);
			const lastBread = this.breadcrumbs[this.breadcrumbs.length - 1];
			this.details = lastBread.options;
		};

		this.$root.$on(`${this.namespace}.switchDetailsItem`, this.switchDetailsData);

		this._selectSingleItem = (data) => {
			if (this.options.useRouting) {
				this.$router.replace({
					query: {
						id: data.ref.id,
						logicalname: data.ref.logicalname
					}
				});
				return;
			}

			const _details = sc.utils.findProperty(data, 'templateRef.content.details', true)
				? data.templateRef.content.details
				: (_.cloneDeep(this.options.details) || {});
			let item;
			if (data.parentView === 'null' && data.keepParentView && this.breadcrumbs.length > 1) {
				item = this.breadcrumbs[this.breadcrumbs.length - 1].options.parentView;
			} else {
				item = this.items.find(item => this.evaluationContext.eval(item.viewType) === data.parentView);
			}
			this.openRecordDetails(_details, data.ref, item);

			this.selectedViewItem = item || null;
		};
		// end of private functions

		this.fork.on(`${this.namespace}.selectSingleItem`, this._selectSingleItem);
		this.$root.$on(`${this.namespace}.selectSingleItem`, this._selectSingleItem);
		this.fork.on(`${this.combinedNamespace}.getSearchResults`, (callback) => {
			callback(this.results);
		});
		this.$root.$on(`${this.combinedNamespace}.searching`, this._search);
		this.fork.on(`${this.combinedNamespace}.searching`, this._search);
		// end of subscriptions

		this.fork.emit(`${this.combinedNamespace}.componentsetSearchResult.ready`);

		this.onEntityChanged = (data) => { if (this.targetEntity.includes(data.entity.logicalname)) this._search(true); };

		this.$root.$on(['entity.changed', 'entity.created', 'entity.deleted'], this.onEntityChanged);
		// TODO: change using vue events
		this.fork.on(['entity.changed', 'entity.created', 'entity.deleted'], this.onEntityChanged);

		this.subscribeOnSearchFieldEvents();
	},

	mounted() {
		if (this.autoSearch) {
			this.unwatchFiltersReady = this.$watch('filtersReady', (newVal, oldVal) => {
				if (newVal) {
					this.doSearch({ initSearch: false });
					// because of immediate: true we cannot unwatch it on first callback call. See https://vuejs.org/v2/api/#vm-watch
					this.unwatchFiltersReady && this.unwatchFiltersReady();
				}
			}, { immediate: true });
		}
	},

	beforeDestroy() {
		this.$root.$off(`${this.namespace}.switchDetailsItem`, this.switchDetailsData);
		this.$root.$off(`${this.namespace}.selectSingleItem`, this._selectSingleItem);
		this.$root.$off(`${this.stateNamespace}.searching`, this._search);
		this.$root.$off(['entity.changed', 'entity.created', 'entity.deleted'], this.onEntityChanged);

		if (this.fork) this.fork.dispose();
		if (this.rootSearchSubscription) this.$root.$off(`${this.stateNamespace}search`);
		if (this.options.passResultsToStore) this.$store.commit(`${this.stateNamespace}/clearSearchResults`);
	},

	methods: {
		toggleFilterPanel() {
			this.showFilterPanel = !this.showFilterPanel;
			this.fork.emit(`${this.combinedNamespace}.filterPanelToggle`, this.showFilterPanel);
		},
		switchDetailsData(data) {
			this.details.entityref = data;
			this.details.$key = data.id;
			this.details.breadcrumbsOptions = 'replace'; // to replace breacrumbs instead of pushing so after navigating back - we will be on searchresults (for listings)
		}
	}
};
</script>
<style src="./search-results.less" scoped></style>
