<template>
	<div class="external-discovery">
		<input v-model.trim="organizationId" type="text" placeholder="Organization ID" @keyup.enter="selectOrganization" />
		<div class="tooltip-wrapper">
			<span class="tooltip-icon" @click="toggleTooltip" :class="{'opened': showTooltip}">
				<svg class="svg-icon svg-16">
					<use xlink:href="/Images/login-info.svg#login-info"></use>
				</svg>
			</span>
			<div class="tooltip" v-if="showTooltip" v-clickoutside="{ visible: showTooltip, callback: closeTooltip}">
				<p>
					Ask your admin or broker for Org ID. You can also extract your ORG ID from any MarketSpace link.
					It is in the second folder. E.g. xREDemo is an ORG ID for https://marketspace.ascendixonline.com/xREDemo/MarketSpace/listing-search-results#/
				</p>
			</div>
		</div>
		<div class="notifications-area" v-if="notificationText.length > 0">
			<span>{{notificationText}}</span>
		</div>
		<button class="submit button" type="submit" @click="selectOrganization">Search</button>
	</div>
</template>
<script>
export default {
	name: 'external-discovery',
	data() {
		return {
			organizationId: null,
			notificationText: '',
			showTooltip: false
		};
	},
	methods: {
		async selectOrganization() {
			if (this.organizationId && this.organizationId.length) {
				this.$router.push(`/${encodeURIComponent(this.organizationId)}`);
			} else {
				this.notificationText = 'Please, enter an organization id';
			}
		},
		closeTooltip() {
			this.showTooltip = false;
		},
		toggleTooltip(e) {
			this.clickTarget = e.target;
			this.showTooltip = !this.showTooltip;
		}
	}
};
</script>
<style src="./external-discovery.less"></style>
