var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [{ name: "data-attr", rawName: "v-data-attr" }],
      staticClass: "marketspace-conversation-menu",
      attrs: { title: "Org Settings" },
      on: { click: _vm.onClick }
    },
    [
      _c("svg", { staticClass: "svg-icon svg-20" }, [
        _c("use", {
          staticClass: "icon",
          attrs: { "xlink:href": "#action-icons-ms-menu-org-settings" }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }