var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sync-progress" }, [
    _vm.syncStatus !== null
      ? _c("div", { staticClass: "stepWrapper" }, [
          _c("span", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "crmSyncPlugin.percentage" },
                expression: "{key: 'crmSyncPlugin.percentage'}"
              }
            ],
            key: "percentage",
            staticClass: "primary-label"
          }),
          _vm._v(" "),
          _c("span", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.syncedRecordsPercentage))
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [
            _c("div", {
              staticClass: "progress-bar-container",
              style: { width: _vm.syncedRecordsPercentage }
            })
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "sync-info" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }