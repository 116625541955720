/* eslint no-useless-catch: 0 */
import { SendRequest } from '@acx-xms/data-functions/dist';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import { SetPerConfig, GetPerConfig } from '@/Data/DataProviders/userStorageDataProvider';

const composeronlinePath = '/api/plugins/endpoint/coauth';

function sendRequest(request) {
	let { type, url, body = null, query = {} } = request;
	url = composeronlinePath + '/' + url;
	query.configKey = sc.cluster.configKey;

	return SendRequest(url, type, body, null, query);
}

async function getToken() {
	return GetPerConfig('co-auth-key');
}

export default {
	async isTokenAvailable() {
		return !!GetPerConfig('co-auth-key');
	},
	async getCOAuthURL() {
		return sendRequest({
			type: 'get',
			url: 'geturl'
		});
	},
	async getCOAuthURLSwagger() {
		const url = await sendRequest({
			type: 'get',
			url: 'geturl'
		});

		return `${url}swagger/`;
	},
	async authenticate(redirectUrl, withMCI = false) {
		const userinfo = await GetUserInfo();

		const response = await sendRequest({
			type: 'post',
			url: 'auth',
			body: {
				redirectUrl: redirectUrl + '/' + userinfo.Id,
				withMCI
			}
		});

		const newWindow = window.open(response + (withMCI ? '/mci' : ''), '_blank', 'toolbar=0,location=0,menubar=0,width=450px,height=790px');
		if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
			throw new Error('pop-ups are blocked');
		}
		if (newWindow) {
			/* focus popup window */
			newWindow.focus();
			/* wrapped in promise to catch close "action" */
			return new Promise((resolve, _reject) => {
				/* check closed prop from window */
				const timer = setInterval(() => {
					if (newWindow.closed) {
						clearInterval(timer);
						/* resolve without error */
						resolve({ closed: newWindow.closed });
					}
				}, 500);
			});
			// let body = "<div style=\"text-align:center;margin-top: 25%\"><div>Authorization to Composer Online is in progress. Please wait...<div><img src=\"https://localhost:5001/Images/load-indicator.gif\"></img></div>"
			// newWindow.document.body.innerHTML = body;
		}
	},

	async refreshToken() {
		try {
			const token = await sendRequest({
				type: 'get',
				url: 'refresh',
				query: { authorization: await getToken() }
			});

			await SetPerConfig('co-auth-key', token);
		} catch (err) {
			throw err;
		}
	}
};
