/* eslint no-throw-literal: 0 */
export default {
	async getChat(parentEntity, chatLogicalName, chatActiveState) {
		const parentEntityRef = sc.classes.get('entityReference', parentEntity);
		const chatFilters = [];
		chatFilters.push(sc.classes.get('termFacet.filter', {
			logicalName: parentEntityRef.logicalname + 'id.id',
			query: [parentEntityRef.id]
		}));
		chatFilters.push(sc.classes.get('offsetSize.filter', 1));
		chatFilters.push(sc.classes.get('selectedFields.filter', [
			{ logicalname: 'chatparticipants.chatparticipantid' },
			{ logicalname: 'recordstate.id' },
			{ logicalname: 'chatid' },
			{ logicalname: 'chatparticipants.contactid.id' },
			{ logicalname: 'chatparticipants.recordstate' }
		]));
		const data = await sc.classes.get('edge.dataProvider').search({
			entities: [chatLogicalName],
			filters: chatFilters,
			resultFormat: 'detail'
		});
		if (data.Total > 0) {
			return data.Results[0];
		}
		return this.createChat(parentEntity, chatLogicalName, chatActiveState);
	},

	async createChatParticipant(chat, contact, participantLogicalName, chatRelation, participantRelation, additionalParams) {
		const dataProvider = sc.classes.get('edge.dataProvider');
		const chatRef = sc.classes.get('entityReference', chat);
		const contactRef = sc.classes.get('entityReference', contact);
		const chatParticipantFilters = [];
		chatParticipantFilters.push(sc.classes.get('termFacet.filter', {
			logicalName: chatRelation,
			query: [chatRef.id]
		}));
		chatParticipantFilters.push(sc.classes.get('termFacet.filter', {
			logicalName: participantRelation,
			query: [contactRef.id]
		}));
		chatParticipantFilters.push(sc.classes.get('offsetSize.filter', 1));
		chatParticipantFilters.push(sc.classes.get('selectedFields.filter', [
			{ logicalname: 'recordstate.id' },
			{ logicalname: 'contactid.id' },
			{ logicalname: 'chatparticipantid' },
			{ logicalname: 'chatid.id' }
		]));
		const data = await dataProvider.search({
			entities: [participantLogicalName],
			filters: chatParticipantFilters,
			resultFormat: 'detail'
		});
		if (data.Total > 0) {
			const participantStateId = sc.utils.findProperty(data.Results[0], 'Source.recordstate.id', true);

			if (participantStateId === '9e817a63-452c-e811-8320-7824af3b452f') {
				return dataProvider.update(participantLogicalName, data.Results[0].Id, {
					recordstate: {
						id: '8d113fa8-3015-4060-a107-14cedcd19dd3',
						logicalname: 'lookuprecordstate'
					}
				});
			} else {
				return data.Results[0].Source;
			}
		}
		let participantParams = { ispending: true };
		participantParams[chatRef.logicalname + 'id'] = chatRef;
		participantParams[contactRef.logicalname + 'id'] = contactRef;
		if (additionalParams) {
			participantParams = {
				...participantParams,
				...additionalParams
			};
		}
		return dataProvider.create(participantLogicalName, participantParams);
	},

	createChat(parentEntity, chatLogicalName, chatActiveState) {
		const dataProvider = sc.classes.get('edge.dataProvider');
		const parentEntityRef = sc.classes.get('entityReference', parentEntity);
		const chatData = {
			collaborationroomid: parentEntityRef,
			public: true,
			name: parentEntityRef.name
		};
		if (chatActiveState) {
			chatData.recordstate = chatActiveState;
		}
		return dataProvider.create(chatLogicalName, chatData);
	},

	async saveContacts(chat, payloadData, participantLogicalName, chatRelation, participantRelation, showProgressPopup = true) {
		const payload = _.cloneDeep(payloadData);
		const recordCount = payload.length;
		const result = [];
		if (recordCount < 1) {
			throw { error: 'No records were added. Please, select at least one record' };
		}

		const progress = {
			total: recordCount,
			processed: 0,
			message: 'Participants are being added to your room',
			title: 'Participants Creation'
		};

		if (showProgressPopup) {
			sc.events.emit('vue.progressDialog.open', progress);
		}

		for (const contact of payload) {
			const additionalParams = contact.additionalParams;
			try {
				const participant = await this.createChatParticipant(chat, contact, participantLogicalName, chatRelation, participantRelation, additionalParams);
				result.push({
					status: 'ok',
					data: participant
				});
			} catch (error) {
				result.push({
					status: 'error',
					data: error
				});
			}

			progress.processed++;
		}

		return result;
	},

	async createChatMessage(chatEntity, invitedParticipants) {
		const chatid = chatEntity.Id || chatEntity.chatid;
		const chatparticipants = chatEntity.Source ? chatEntity.Source.chatparticipant : chatEntity.chatparticipant;

		if (chatparticipants && chatparticipants.length) {
			const addedParticipants = chatparticipants
				.filter(x => x.recordstate.id !== '9e817a63-452c-e811-8320-7824af3b452f')
				.map(x => x.chatparticipantid);

			invitedParticipants = invitedParticipants.filter(x => addedParticipants.indexOf(x.Id) === -1);
		}

		if (!invitedParticipants.length) {
			return;
		}

		const dataProvider = sc.classes.get('edge.dataProvider');
		const userInfo = await sc.classes.get('authentication').getUserInfo();
		const filters = [
			sc.classes.get('termFacet.filter', {
				logicalName: 'systemuserid.id',
				query: [userInfo.systemuserid]
			}),
			sc.classes.get('offsetSize.filter', 1),
			sc.classes.get('selectedFields.filter', [])
		];
		const contact = await dataProvider.search({
			entities: ['contact'],
			filters
		});

		if (contact && contact.Results.length) {
			const inviterFullName = contact.Results[0].Name;
			const invitedFullNames = invitedParticipants.map((invitedParticipant) => invitedParticipant.name).join(', ');
			const message = `has added ${invitedFullNames}`;

			return dataProvider.create('chatmessage', {
				chatid: {
					id: chatid,
					logicalname: 'chat'
				},
				messagetext: message,
				contactid: {
					id: contact.Results[0].Id,
					logicalname: 'contact'
				},
				contactname: inviterFullName,
				messagetype: 'Action',
				visibleto: 'All Users',
				createdonfromui: new Date()
			});
		}
	},

	async relatedChats(parentEntity, chatLogicalName) {
		const dataProvider = sc.classes.get('edge.dataProvider');
		const parentEntityRef = sc.classes.get('entityReference', parentEntity);
		const chatFilters = [
			sc.classes.get('termFacet.filter', {
				logicalName: parentEntityRef.logicalname + 'id.id',
				query: [parentEntityRef.id]
			}),
			sc.classes.get('offsetSize.filter', 1000),
			sc.classes.get('selectedFields.filter', [])
		];
		const data = await dataProvider.search({
			entities: [chatLogicalName],
			filters: chatFilters,
			resultFormat: 'detail'
		});
		return data.Results;
	}
};
