import ExpressionCdate from './cdate';
import { isNull } from '@acx-xms/data-functions/dist';

export default {
	parameters(options) {
		return [
			this.eval(options.minuend),
			this.eval(options.subtrahend),
			this.eval(options.unit)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.minuend),
			await this.evalAsync(options.subtrahend),
			await this.evalAsync(options.unit)
		];
	},
	evaluate(date, subs, unit) {
		date = ExpressionCdate.evaluate(date);
		subs = ExpressionCdate.evaluate(subs);

		if (isNull(date)) {
			return null;
		}
		if (isNull(subs)) {
			return date;
		}
		return sc.utils.subtractDate(date, subs, unit);
	}
};
