// TODO: remove after removing all knockout views
import BaseModule from './baseModule';

export default class extends BaseModule {
	install() {
		const componentLoader = sc.classes.get('ko.componentLoader');
		return componentLoader.resolveHeaders().then(() => {
			ko.components.loaders.unshift(componentLoader);
		});
	}
}
