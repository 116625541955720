import entityDeactivateBase from './Methods/action.entity.deactivateBase';
import { getOrgSettings } from '@acx-xms/data-functions/dist';
import { removeUrl } from './Methods/action.methods.sitemapHelper';

export default {
	async enabled(options, evaluationContext, selection, fullSelection) {
		const availability = selection.find(item => item.Type === 'availability');
		if (options.takefullSelection && availability) {
			const listingid = availability && availability.Source.listingid.id;
			selection = [fullSelection.listing && fullSelection.listing.find(listing => listing.Id === listingid)];
		}
		return entityDeactivateBase.enabled(options, evaluationContext, selection);
	},
	async execute(options, context, selection, fullSelection) {
		if (options.takefullSelection) {
			const records = [];
			fullSelection.listing && fullSelection.listing.forEach(selection => {
				records.push(selection);
			});
			selection = records;
		}
		const orgSettings = await getOrgSettings(true);

		const callback = () => {
			(fullSelection ? fullSelection.listing : selection).forEach(async item => {
				if (!item.Source.publishedtolistingportal) {
					return;
				}

				await removeUrl(item.Id, item.Name, item.Type);
			});
		};

		options.acceptCallback = orgSettings.enableseo && callback;
		entityDeactivateBase.execute(options, context, selection, fullSelection);
	}
};
