var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.visible
      ? _c(
          "div",
          { staticClass: "aside-panel" },
          [
            _vm.filterInfoPanelOptions
              ? _c("filter-info-panel", {
                  key: _vm.filterKey,
                  attrs: {
                    options: _vm.filterInfoPanelOptions,
                    evaluationContext: _vm.evaluationContext,
                    isLoading: _vm.isLoading,
                    stateNamespace: _vm.stateNamespace
                  },
                  on: {
                    filterError: function($event) {
                      _vm.filterError = $event
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "data-attr",
                    rawName: "v-data-attr",
                    value: {
                      options: _vm.options,
                      stateNamespace: _vm.stateNamespace
                    },
                    expression: "{ options, stateNamespace }"
                  }
                ],
                staticClass: "filter-panel"
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "perfectscroll",
                        rawName: "v-perfectscroll",
                        value: { enable: true },
                        expression: "{ enable: true }"
                      }
                    ],
                    staticClass: "filter-panel-holder"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "filter-panel-wrapper" },
                      _vm._l(_vm.options.filters.group, function(group, index) {
                        return _c(
                          "div",
                          {
                            key: index + _vm.filterKey,
                            staticClass: "filter-panel-frame"
                          },
                          [
                            _c(_vm.resolveGroupType(group), {
                              key: index + _vm.filterKey,
                              tag: "component",
                              attrs: {
                                stateNamespace: _vm.stateNamespace,
                                options: group,
                                evaluationContext: _vm.evaluationContext
                              }
                            })
                          ],
                          1
                        )
                      })
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "buttonsMenu" }, [
                  _c("button", {
                    directives: [
                      {
                        name: "data-attr",
                        rawName: "v-data-attr",
                        value: {
                          options: _vm.options,
                          stateNamespace: "button-reset"
                        },
                        expression:
                          "{ options, stateNamespace: 'button-reset' }"
                      },
                      {
                        name: "localization",
                        rawName: "v-localization",
                        value: { key: "filtersPanel.resetAllFilters" },
                        expression: "{ key: 'filtersPanel.resetAllFilters' }"
                      }
                    ],
                    staticClass: "resetButton",
                    attrs: {
                      disabled:
                        _vm.filterInfoPanelOptions != null ||
                        !_vm.filtersChanged,
                      type: "button"
                    },
                    on: { click: _vm.onMSpaceReset }
                  }),
                  _vm._v(" "),
                  _vm.showApplyButton()
                    ? _c("button", {
                        directives: [
                          {
                            name: "data-attr",
                            rawName: "v-data-attr",
                            value: {
                              options: _vm.options,
                              stateNamespace: "button-apply"
                            },
                            expression:
                              "{ options, stateNamespace: 'button-apply' }"
                          },
                          {
                            name: "localization",
                            rawName: "v-localization",
                            value: { key: "filtersPanel.apply" },
                            expression: "{ key: 'filtersPanel.apply' }"
                          }
                        ],
                        staticClass: "applyButton",
                        attrs: { type: "button" },
                        on: { click: _vm.onMSpaceApply }
                      })
                    : _vm._e()
                ])
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.filterInfoPanelOptions,
          expression: "filterInfoPanelOptions"
        }
      ],
      staticClass: "filter-panel-overlay",
      on: { click: _vm.filterOverlayClick }
    }),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible"
        }
      ],
      staticClass: "filter-panel-mobile-overlay",
      on: { click: _vm.mobileOverlayClick }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }