import { Update, SendRequest } from '@acx-xms/data-functions/dist';

export default {
	async enabled(options, evaluationContext, selection) {
		return selection && selection.length;
	},
	async execute(options, context, selection) {
		if (!Array.isArray(selection)) {
			selection = [selection];
		}

		const refreshEvents = context.eval(options.refreshEvents) || [];
		const loadingEvent = context.eval(options.loadingEvent);
		const emitLoading = (isLoading) => {
			loadingEvent && sc.events.emit(loadingEvent, isLoading);
		};
		emitLoading(true);
		const promiseArray = [];
		selection.forEach(s => {
			const ref = sc.classes.get('entityReference', s);
			const promise = Update(ref.logicalname, ref.id, { anonymousaccess: false }).then(() => {
				return SendRequest('/api/PublicAccess/DeleteToken', 'POST', {
					Id: s.Source.accesstokeninvitationid.id,
					EntityName: s.Source.accesstokeninvitationid.logicalname
				});
			}).catch(error => {
				sc.events.emit('dialog.error', {
					title: sc.classes.get('localization.dataProvider').localize('common.dictionary.loginScreen.dialog.loginError'),
					message: error.message
				});
				return Update(ref.logicalname, ref.id, { anonymousaccess: true });
			});
			promiseArray.push(promise);
		});
		await Promise.all(promiseArray);
		emitLoading(false);
		refreshEvents.forEach(refreshEvent => {
			sc.events.emit(refreshEvent);
		});
	}
};
