import { GetUserInfo } from '@/Data/Auth/authentication-service';
import { isUserCoownerOrOwner } from './Methods/action.methods';
import {
	showToastOrInfoDialog, Create, Update, Search, Get
} from '@acx-xms/data-functions/dist';

export default {
	async enabled(options, evaluationContext, selection) {
		return await isUserCoownerOrOwner(evaluationContext);
	},
	async execute(options, context, selection) {
		const loadingEvent = context.eval(options.loadingEvent);
		const emitLoading = (isLoading) => {
			loadingEvent && sc.events.emit(loadingEvent, isLoading);
		};

		try {
			emitLoading(true);
			const selectionItem = selection[0];
			const cp = await Update(
				selectionItem.logicalname || selectionItem.Type,
				selectionItem.id || selectionItem.Id,
				{ requestedaccess: false }
			);
			if (!cp) {
				return;
			}
			const chatPromise = Get('chat', cp.chatid.id);
			const contactPromise = Get('contact', cp.contactid.id);
			const currentSystemUserPromise = GetUserInfo();
			Promise.all([chatPromise, contactPromise, currentSystemUserPromise]).then(async ([chat, contact, currentSystemUser]) => {
				const teammemberid = sc.utils.guid();
				const chatTeamRef = sc.classes.get('entityReference', sc.utils.findProperty(chat, 'Source.teamid', true));

				const contactForMsgPromise = Search(['contact'], [
					sc.classes.get('offsetSize.filter', 1).fillQuery(),
					sc.classes.get('termFacet.filter', {
						logicalName: 'systemuserid.id',
						query: [currentSystemUser.Id]
					}).fillQuery(),
					sc.classes.get('selectedFields.filter', []).fillQuery()
				]);

				const teammemberCreatePromise = Create('teammember', {
					teammemberid,
					name: teammemberid.toString(),
					memberid: contact.Source.systemuserid,
					teamid: chatTeamRef
				});
				Promise.all([contactForMsgPromise, teammemberCreatePromise]).then(async ([contactResponse, tm]) => {
					const contactForMsg = contactResponse.Results.pop();

					await sc.classes.get('edge.dataProvider').grantAccess('teammember', tm.teammemberid, {
						PrincipalType: 'chat',
						PrincipalId: chatTeamRef.id,
						AccessRights: [
							'Read',
							'Delete'
						]
					});

					await Create('chatmessage', {
						chatid: {
							id: chat.Id,
							logicalname: chat.Type
						},
						messagetext: `approved access for ${cp.name}`,
						contactid: {
							id: contactForMsg.Id,
							logicalname: contactForMsg.Type
						},
						contactname: contactForMsg.Name,
						messagetype: 'Action',
						createdonfromui: new Date(),
						visibleto: 'All Users'
					});
					options.refreshEvent && sc.events.emit(context.eval(options.refreshEvent));
				}).catch(e => sc.utils.errorMessage.byMessage(e));
			}).catch(e => sc.utils.errorMessage.byMessage(e))
				.finally(async () => {
					emitLoading(false);
					await showToastOrInfoDialog({
						toastMessageKey: context.eval(options.toastMessageKey),
						informationDialogTextKey: context.eval(options.informationDialogTextKey)
					});
				});
		} catch (e) {
			sc.utils.errorMessage.byMessage(e);
		}
	}
};
