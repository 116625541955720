var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-results-with-preview" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.results || !_vm.results.length,
            expression: "!results || !results.length"
          }
        ],
        staticClass: "no-results-message"
      },
      [
        _c("div", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.noMatchesFound" },
              expression: "{ key: 'common.dictionary.noMatchesFound' }"
            }
          ]
        })
      ]
    ),
    _vm._v(" "),
    _vm.results && _vm.results.length > 0
      ? _c("div", { staticClass: "content-scroll-wrapper" }, [
          _vm.previewItem && _vm.withPreview
            ? _c(
                "div",
                { staticClass: "participant-preview" },
                [
                  _c("entitytemplate-template", {
                    key: _vm.previewItem.Id + "_" + _vm.updateNumber,
                    attrs: {
                      options: _vm.options.contentComponent.previewTemplate,
                      evaluationContext: _vm.getContext(_vm.previewItem)
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "perfectscroll",
                  rawName: "v-perfectscroll",
                  value: { enable: true, addMouseoverEvent: true },
                  expression: "{enable: true, addMouseoverEvent: true}"
                }
              ],
              class: { "participants-list": _vm.withPreview }
            },
            _vm._l(_vm.results, function(item) {
              return _c("entitytemplate-template", {
                key: item.Id + "_" + _vm.updateNumber,
                class: {
                  selected:
                    _vm.previewItem !== null && item.Id === _vm.previewItem.Id
                },
                attrs: {
                  options: _vm.options.contentComponent.listTemplate,
                  evaluationContext: _vm.getContext(item)
                },
                nativeOn: {
                  click: function($event) {
                    _vm.selectItem(item)
                  }
                }
              })
            })
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }