import { render, staticRenderFns } from "./absolute-template-popup.vue?vue&type=template&id=1faeee71&scoped=true&"
import script from "./absolute-template-popup.vue?vue&type=script&lang=js&"
export * from "./absolute-template-popup.vue?vue&type=script&lang=js&"
import style0 from "./absolute-template-popup.less?vue&type=style&index=0&id=1faeee71&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1faeee71",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\SCCore\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('1faeee71', component.options)
    } else {
      api.reload('1faeee71', component.options)
    }
    module.hot.accept("./absolute-template-popup.vue?vue&type=template&id=1faeee71&scoped=true&", function () {
      api.rerender('1faeee71', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Scripts/Vue/Components/Popup/absolute-template-popup.vue"
export default component.exports