import { applyConfigTemplate } from '@/Data/DataProviders/templatesDataProvider';
import { mapParametersObject } from './Methods/action.methods';
import { showToastOrInfoDialog } from '@acx-xms/data-functions/dist';
export default {

	async enabled() {
		return true;
	},
	async execute(options, context, selection) {
		const refreshEvent = context.eval(options.refreshEvent);
		const parameters = mapParametersObject(options.parameter, context);
		const isDirty = options.isDirty;
		const isDetails = context.eval(options.isDetails);
		if (!parameters.entity && selection && selection.length) {
			parameters.entity = sc.classes.get('entityReference', selection[0]);
		}
		if (isDirty) {
			const localizations = sc.classes.get('localization.dataProvider');
			sc.events.emit('vue.dialog.open', {
				icon: 'action-icons-search-save',
				title: await localizations.getLabelForCurrentLanguage('common.dictionary.confirmDlgCaption'),
				component: 'confirm.dialog',
				maximizedWidth: '450px',
				maximizedHeight: 'auto',
				evaluationContext: context,
				onSubmit: () => sc.events.emit('saveTemplate', {
					isApplyAction: true,
					id: parameters.entity.id,
					refreshEvent
				}),
				message: await localizations.getLabelForCurrentLanguage('common.dictionary.pleaseSaveChanges'),
				okLabel: await localizations.getLabelForCurrentLanguage('common.dictionary.buttons.save')
			});
		} else {
			sc.events.emit('dialog.confirm', 'dialogs.configChangeConfirmation.message', async () => {
				if (parameters.entity && parameters.entity.id) {
					await applyConfigTemplate(parameters.entity.id);
					if (isDetails) {
						sc.events.emit('refreshAfterApply');
					};
					refreshEvent && sc.events.emit(refreshEvent);
					await showToastOrInfoDialog({
						toastMessageKey: context.eval(options.toastMessageKey),
						informationDialogTextKey: context.eval(options.informationDialogTextKey)
					});
				}
			}, null, null, undefined, 'dialogs.apply', 'dialogs.cancel');
		}
	}
};
