import { recordLink } from '@acx-xms/data-functions/dist';
import ImageGallerySmallBase from './control-image-gallery-small-base';
import * as tracking from '@/Data/trackingModule';

export default {
	components: { ImageGallerySmallBase },
	methods: {
		async init() {
			this.galleryStylesObj = { ...(this.options.height && { height: this.options.height }) };
			this.placeholderEntityName = '#entity-icons-' + this.evaluationContext.eval(this.options.placeholderEntityName);
			const recordreference = await this.evaluationContext.evalAsync(this.options.recordReference);
			if (recordreference) {
				this.reference = recordLink(this.$route, recordreference);
			}
			this.parentRef = this.evaluationContext.eval(this.options.parentRef) || recordreference;
		},
		prev(e) {
			e.stopPropagation();
			this.currentIndex = (this.currentIndex > 0) ? this.currentIndex - 1 : this.images.length - 1;
			tracking.AddUserActivity('imagegallery.navigate.prev', 'Image gallery navigation - prev image', this.parentRef);
		},
		next(e) {
			e.stopPropagation();
			this.currentIndex = (this.currentIndex < this.images.length - 1) ? this.currentIndex + 1 : 0;
			tracking.AddUserActivity('imagegallery.navigate.next', 'Image gallery navigation - next image', this.parentRef);
		},
		setImages(images) {
			const token = localStorage.getItem(sc.classes.get('authentication').getTokenKey(sc.cluster.key));
			const imageSize = (this.options.imageSize) ? this.options.imageSize : 'thumbnail';
			images.forEach(imageObj => {
				let url = sc.classes.get('edge.dataProvider').getImageUrl('image', (imageObj.imageid || imageObj.Source.imageid), imageSize);
				url += '?token=' + token;
				if (imageObj.primary) {
					this.images.unshift({
						...imageObj,
						url,
						error: false
					});
				} else {
					this.images.push({
						...imageObj,
						url,
						error: false
					});
				}
			});
		},
		openDialog() {
			const withExtendedGallery = this.options.withExtendedGallery;

			if (withExtendedGallery && this.images.length) {
				sc.events.emit('vue.dialog.open', {
					component: 'image-gallery-dialog',
					images: this.images,
					maximizedWidth: '80%',
					maximizedHeight: '100%',
					parentEntity: this.parentRef,
					evaluationContext: this.evaluationContext,
					menu: null
				});

				tracking.AddUserActivity('imagegallery.open', 'Image gallery open', this.parentRef);
			}
		}
	}
};
