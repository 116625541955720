<template>
	<div class="manage-images component-search-results-details" v-if="!isError">
		<div class="content">
			<div class="rows">
				<div class="row">
					<div class="column">
						<div class="column-content">
							<component-search-results	v-if="componentOptions"
														class="details-inner-results"
														:options="componentOptions"></component-search-results>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="isError" class="manage-images error">
		You don't have an access to this record or record doesn't exist.
		Please try to sync the record first.
	</div>
</template>
<script>
import ComponentSearchResults from '@/Components/ComponentSet/SearchResults/component-search-results';

export default {
	name: 'manage-images',
	props: { options: Object },
	components: { ComponentSearchResults },
	data() {
		return {
			componentOptions: null,
			isError: false
		};
	},

	created() {
		const logicalnameMapping = {
			awx_property: 'property',
			awx_availability: 'availability'
		};

		const crmln = this.findGetParameter('logicalname');
		const logicalname = logicalnameMapping[crmln] || crmln;

		sc.classes.get('edge.dataProvider').search({
			entities: [logicalname],
			filters: [
				sc.classes.get('offsetFrom.filter', 0),
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('termFacet.filter', {
					logicalName: 'crmid',
					query: [(this.findGetParameter('recordId') || '').toLowerCase()]
				}),
				sc.classes.get('selectedFields.filter', [])
			]
		}).done((data) => {
			const vals = data.Results.map(res => { return res.Id; });
			if (vals && vals.length) {
				sc.classes.get('entityConfiguration.dataProvider').getTemplate('image', 'tableview', logicalname).done((template) => {
					const tmpl = JSON.stringify(template.content.control[0].component);
					const strComponent = _.template(tmpl, { interpolate: /{{([\s\S]+?)}}/g })({
						recordid: vals[0],
						recordlogicalname: logicalname
					});
					this.componentOptions = JSON.parse(strComponent);
				});
			} else {
				this.isError = true;
			}
		});
	},

	methods: {
		findGetParameter: function (parameterName) {
			let result = null;
			let tmp = [];
			location.search
				.substr(1)
				.split('&')
				.forEach(function (item) {
					tmp = item.split('=');
					if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
				});
			return result;
		}
	}
};
</script>
<style lang="css" scoped>
	.column-content {
		height: 100vh;
		padding: 25px 30px;
		box-sizing: border-box;
	}
</style>
