var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "userProfileMenu-wrapper" }, [
    _c(
      "div",
      {
        staticClass: "user-profile-component",
        class: _vm.isAnonymous && "anonymous",
        attrs: { disabled: _vm.enable }
      },
      [
        _vm.isAnonymous
          ? _c("div", { staticClass: "component-header" }, [
              _c("button", { on: { click: _vm.redirectToSignIn } }, [
                _vm._v("Sign In")
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.isAnonymous
          ? _c("div", { staticClass: "component-header" }, [
              _c(
                "div",
                {
                  staticClass: "user-info-wrapper",
                  on: { click: _vm.openProfilePopup }
                },
                [
                  _c("div", { staticClass: "profile-image" }, [
                    !_vm.userProfilePhoto
                      ? _c("svg", { staticClass: "svg-icon" }, [
                          _c("use", {
                            class: _vm.profileIconDefault,
                            attrs: {
                              "xlink:href":
                                "#layout-icons-" + _vm.profileIconDefault
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.userProfilePhoto
                      ? _c("img", {
                          staticClass: "image-node",
                          attrs: { src: _vm.userProfilePhoto }
                        })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "user-name" }, [
                    _vm._v(_vm._s(_vm.fullName))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "openbtn-wrapper", class: _vm.menuState },
                    [
                      _c("svg", { staticClass: "svg-icon" }, [
                        _c("use", {
                          attrs: {
                            "xlink:href": "#layout-icons-ms-triangle-down"
                          }
                        })
                      ])
                    ]
                  )
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "user-profile-popup-wrapper", class: _vm.menuState },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "perfectscroll",
                    rawName: "v-perfectscroll",
                    value: { enable: true },
                    expression: "{ enable: true }"
                  }
                ],
                staticClass: "user-profile-popup"
              },
              [
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("menu-userprofile-content", {
                      attrs: {
                        currentLanguage: _vm.currentLanguage,
                        currentCurrency: "currentCurrency",
                        currentSom: _vm.currentSom,
                        cssClass: "mobile"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "version-info-mobile" }, [
                      _c(
                        "div",
                        { staticClass: "versions" },
                        [
                          _c("div", { staticClass: "version-title h2" }, [
                            _vm._v(_vm._s(_vm.versionTitle))
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.versions, function(version, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "version-item" },
                              [
                                _c("span", { staticClass: "label p4" }, [
                                  _vm._v(_vm._s(version.label + "") + " ")
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "value h5" }, [
                                  _vm._v(_vm._s(version.value))
                                ])
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.isLoading
                        ? _c("div", { staticClass: "loading-overlay large" })
                        : _vm._e()
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "footer" }, [
                  _c(
                    "div",
                    { staticClass: "bunttons-container" },
                    [
                      _c("div", { staticClass: "signout-component" }, [
                        _c(
                          "a",
                          {
                            staticClass: "signout-button button clickable",
                            on: { click: _vm.logout }
                          },
                          [
                            _c("i", { staticClass: "layout-icons-signout" }),
                            _vm._v(" "),
                            _c("span", [_vm._v("Sign Out")])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _vm._t("default")
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm._m(0)
                ])
              ]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "powered-by" }, [
      _c("span", { staticClass: "label p1" }, [_vm._v("Powered by")]),
      _vm._v(" "),
      _c("span", { staticClass: "value p2" }, [
        _c("a", { attrs: { target: "_blank", href: "https://ascendix.com" } }, [
          _vm._v("Ascendix Technologies")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }