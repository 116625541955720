import { isFunction } from '@acx-xms/data-functions/dist';

export default class {
	constructor(context, options) {
		this.map = context._map;
		this.currentShape = null;
		this.drawingOptions = {
			strokeColor: options.strokeColor,
			strokeWeight: options.strokeWeight,
			strokeStyle: options.strokeStyle,
			strokeOpacity: options.strokeOpacity
		};

		const drawingModes = [];

		if (!options.drawingTool.customButtons) {
			if (options.drawingTool.circle) {
				drawingModes.push(google.maps.drawing.OverlayType.CIRCLE);
			}

			if (options.drawingTool.rectangle) {
				drawingModes.push(google.maps.drawing.OverlayType.RECTANGLE);
			}

			if (options.drawingTool.polygon) {
				drawingModes.push(google.maps.drawing.OverlayType.POLYGON);
			}
		}
		const drawingManager = new google.maps.drawing.DrawingManager({
			drawingControl: options.drawingTool.enableDrawingTool && !options.drawingTool.customButtons,
			drawingControlOptions: {
				position: google.maps.ControlPosition.TOP_CENTER,
				drawingModes
			},
			circleOptions: this.drawingOptions,
			polygonOptions: this.drawingOptions,
			rectangleOptions: this.drawingOptions
		});

		drawingManager.setMap(this.map);

		google.maps.event.addListener(drawingManager, 'overlaycomplete', (event) => {
			const searchCriteria = this.getShapeSearchCriteria(event);
			this.clear();
			this.currentShape = event;

			if (options.setAreaSelection) {
				options.setAreaSelection(searchCriteria, this.clear);
			}

			drawingManager.setDrawingMode(null);
			this._stopDrawing();
		});

		$('.scMap').on('mousedown', (e) => {
			const $target = $(e.target);
			const drawingMode = drawingManager.getDrawingMode();
			if ($target.parents('.gmnoprint').length === 0 && $target.parents('.custom-drawing-button').length === 0 && drawingMode !== null) {
				this.clear();
			}
		});

		this.elementsArray = [];
		if (options.drawingTool.enableDrawingTool && options.drawingTool.customButtons) {
			const createButton = (element, className, btnClassName, title, type) => {
				element.className = className;
				this._createShapesButtonControl(element, btnClassName, title);

				$(element).on('click', (e) => {
					if (isFunction(type)) {
						type();
					} else {
						drawingManager.setDrawingMode(type);
					}

					const isAreaSelection = e.currentTarget.classList.contains('clear-selected-area');
					this._stopDrawing(isAreaSelection);
					!isAreaSelection && e.currentTarget.classList.add('selected');
				});

				this.elementsArray.push(element);

				this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(element);
			};

			createButton(document.createElement('div'), 'stop-drawing selected custom-drawing-button', 'map-area-selection-hand', 'Stop drawing', null);
			options.drawingTool.circle && createButton(document.createElement('div'), 'circle-selected-area custom-drawing-button', 'map-area-selection-circle', 'Draw a circle', google.maps.drawing.OverlayType.CIRCLE);
			options.drawingTool.rectangle && createButton(document.createElement('div'), 'rectangle-selected-area custom-drawing-button', 'map-area-selection-rectangle', 'Draw a rectangle', google.maps.drawing.OverlayType.RECTANGLE);
			options.drawingTool.polygon && createButton(document.createElement('div'), 'polygon-selected-area custom-drawing-button', 'map-area-selection-polygon', 'Draw a shape', google.maps.drawing.OverlayType.POLYGON);
			options.drawingTool.clear && createButton(document.createElement('div'), 'clear-selected-area custom-drawing-button', 'map-area-selection-clear', 'Clear selected area', () => {
				if (this.currentShape) {
					this.clear();
					options.clearSelection && options.clearSelection();
				}
			});
		}

		if (options.drawingTool.enableDrawingTool && options.drawingTool.clear && !options.drawingTool.customButtons) {
			const imgControlDiv = document.createElement('div');
			imgControlDiv.className = 'clear-selected-area';
			this._createClearShapesButtonControl(imgControlDiv, 'mapSearchResults-icons-clear-selected', 'Clear selected area');

			$(imgControlDiv).on('click', () => {
				if (this.currentShape) {
					this.clear();
					options.clearSelection && options.clearSelection();
				}
			});

			this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(imgControlDiv);
		}
	}

	_stopDrawing(isAreaSelection = true) {
		for (const index in this.elementsArray) {
			const ea = this.elementsArray[index];
			ea.classList.remove('selected');

			if (isAreaSelection && ea.classList.contains('stop-drawing')) {
				ea.classList.add('selected');
			}
		}
	}

	clear() {
		if (this.currentShape) {
			this.currentShape.overlay.setMap(null);
			this.currentShape = null;
		}
	}

	getShapeSearchCriteria(shape) {
		let searchCriteria;
		switch (shape.type) {
		case google.maps.drawing.OverlayType.POLYGON:
		{
			searchCriteria = this._getPolygonSearchCriteria(shape.overlay);
			break;
		}
		case google.maps.drawing.OverlayType.CIRCLE:
		{
			searchCriteria = this._getCircleSearchCriteria(shape.overlay);
			break;
		}
		case google.maps.drawing.OverlayType.RECTANGLE:
		{
			searchCriteria = this._getRectangleSearchCriteria(shape.overlay);
			break;
		}
		default:
		{
			searchCriteria = null;
		}
		}

		if (searchCriteria !== null) {
			searchCriteria.filterType = shape.type === google.maps.drawing.OverlayType.CIRCLE ? 'geoDistanceFacet' : 'GeoPolygon';
		}

		return searchCriteria;
	}

	_getCircleSearchCriteria(circle) {
		const center = this._unwrapCoordinate(circle.getCenter());
		const radius = circle.getRadius() / 1000;

		return {
			value: {
				center,
				radius
			}
		};
	}

	_getPolygonSearchCriteria(polygon) {
		return { value: polygon.getPath().getArray().map(point => this._unwrapCoordinate(point)) };
	}

	_getRectangleSearchCriteria(rectangle) {
		const bounds = rectangle.getBounds();
		const ne = this._unwrapCoordinate(bounds.getNorthEast());
		const sw = this._unwrapCoordinate(bounds.getSouthWest());
		const nw = {
			lat: ne.lat,
			lng: sw.lng
		};
		const se = {
			lat: sw.lat,
			lng: ne.lng
		};

		return { value: [nw, ne, se, sw] };
	}

	drawShape(options) {
		this.clear();

		if (!options) {
			return;
		}

		let skipZoom = false;
		if (Object.prototype.hasOwnProperty.call(options, 'skipZoom')) {
			skipZoom = options.skipZoom;
			delete options.skipZoom;
		}

		const mapData = {};
		let bounds = new google.maps.LatLngBounds();

		mapData.type = options.radius
			? google.maps.drawing.OverlayType.CIRCLE
			: google.maps.drawing.OverlayType.POLYGON;

		mapData.data = options;

		let shape = {};

		switch (mapData.type) {
		case google.maps.drawing.OverlayType.POLYGON: {
			const points = []; let latLng;

			if (!mapData.data || mapData.data.length === 0) {
				return;
			}
			mapData.data.forEach(point => {
				latLng = new google.maps.LatLng(point.lat, point.lng);
				points.push(latLng);
				bounds.extend(latLng);
			});
			shape = new google.maps.Polygon({
				...{ paths: points },
				...this.drawingOptions
			});
			break;
		}
		case google.maps.drawing.OverlayType.CIRCLE: {
			const center = new google.maps.LatLng(mapData.data.center.lat, mapData.data.center.lng);
			const radius = mapData.data.radius * 1000;
			shape = new google.maps.Circle({
				center,
				radius,
				...this.drawingOptions
			});
			bounds = shape.getBounds();
			break;
		}
		}
		if (Object.keys(shape).length > 0) {
			shape.setMap(this.map);
			!skipZoom && this.map.fitBounds(bounds);
			this.currentShape = {
				type: mapData.type,
				overlay: shape
			};
		}
	}

	_unwrapCoordinate(latlon) {
		if (latlon) {
			return {
				lat: latlon.lat(),
				lng: latlon.lng()
			};
		} else {
			return {};
		}
	}

	_createShapesButtonControl(controlDiv, className, title) {
		const makeSVG = function (attrs, svgAttrs) {
			const svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
			const useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');

			svgElem.appendChild(useElem);

			for (const k in attrs) {
				if (k === 'xlink:href') {
					useElem.setAttributeNS('http://www.w3.org/1999/xlink', k, attrs[k]);
				} else {
					useElem.setAttribute(k, attrs[k]);
				}
			}

			for (const l in svgAttrs) {
				svgElem.setAttribute(l, svgAttrs[l]);
			}

			return svgElem;
		};

		const img = makeSVG({
			class: 'action-icons-' + className,
			'xlink:href': '#action-icons-' + className
		}, {
			title,
			class: 'wrapper-' + className
		});
		controlDiv.appendChild(img);
	}

	_createClearShapesButtonControl(controlDiv, className, title) {
		const controlImg = document.createElement('div');
		controlDiv.appendChild(controlImg);
		controlImg.className = className;
		controlImg.title = title;
	}
}
