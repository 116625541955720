<template>
	<mobile-menu-userprofile :isAnonymous="isAnonymous" v-bind:params="{userProfileMenu}" :initMenuState="initMenuState">
		<div class="buttons">
			<button @click="showUserDetails()" class="clickable" v-localization="{key: 'userPanel.viewProfile'}" />
		</div>
	</mobile-menu-userprofile>
</template>
<script>
import MobileMenuUserprofile from './mobile-menu-userprofile.vue';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
export default {
	name: 'menu-userprofile-with-viewprofile',

	props: {
		params: Object,
		isAnonymous: Boolean
	},

	components: { MobileMenuUserprofile },

	data() {
		return {
			initMenuState: null,
			userProfileMenu: this.params.userProfileMenu
		};
	},

	methods: {
		showUserDetails: function () {
			this.$emit('toggleMenu');
			this.$root.$emit('showDetails', this.userRef);
			this.initMenuState = 'collapsed';
		}
	},

	async created() {
		const info = await GetUserInfo();
		this.userRef = sc.classes.get('entityReference', info);
	}
};
</script>
