import coDataProvider from '@/Data/DataProviders/coDataprovider';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import {
	RECORD_STATE, generateGuid, Create, Search, SearchByIds, CreateBulk, Update, isNullOrUndefined, getBaseRouteUrl
} from '@acx-xms/data-functions/dist';

import {
	createCampaign, startCampaign, createDefaultList, getDefaultList, addMembersToList, getMembersStatus, sendTestCampaign, deleteCampaign, getDomains
} from '@/Data/DataProviders/coMCIDataprovider';
import { refreshEvents } from '@/Components/Wizard/WizardSets/EmailCampaign/helpers/refreshEvents';
import { getFormattedDateAndTime } from '@/Components/Wizard/WizardSets/EmailCampaign/helpers/misc-methods';
import coAuthDataProvider from '@/Data/DataProviders/coAuthDataprovider';
import router from '@/router';

import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';

function handleTestRequestStatus(status, description) {
	const messages = {
		200: 'The Test Email has been sent.',
		204: 'The Test Email has been sent.',
		400: 'Bad Request',
		401: 'API Key Invalid',
		403: 'Forbidden: This account has been deactivated OR Authorization Failure: User does not have access to the requested operation',
		404: 'Resource Not Found',
		405: 'Method Not Allowed',
		414: 'Resource Nesting Too Deep',
		422: 'Invalid Method Override',
		426: 'Your request was made with the HTTP protocol',
		429: 'Too Many Requests: You have exceeded the limit of 10 simultaneous connections',
		500: 'Internal Server Err'
	};
	const prefix = 'The test email has not been sent. Error ';
	const suffix = '. Please try again or contact your Admin.';
	let message = '';
	switch (status) {
	case 400:
	case 401:
	case 403:
	case 404:
	case 405:
	case 414:
	case 422:
	case 426:
	case 429:
	case 500:
		message = `${prefix} ${status} ${description.replaceAll('"', '') || messages[`${status}`]}${suffix}`;
		break;
	case 200:
	case 204:
		message = messages[status];
		break;
	}
	return message;
};

/* TODO: refactor to global utils (or Vuex module) */
function showToast(status, message, time = 3000) {
	sc.events.emit('toast.open', handleTestRequestStatus(status, message), time);
}

const actions = {
	updateActiveStep({ commit, dispatch, state }, data) {
		if (state.steps.find(i => i.active) && state.steps.find(i => i.active).index <= 2 && data - 1 > 2 && !state.templateProcess) {
			dispatch('processArtifactChanges');
		}
		commit('UPDATE_ACTIVE_STEP', data);
		commit('wizard.navigation/SET_ACTIVE', data, { root: true });
	},
	updateNavigation({ commit }, data) {
		commit('UPDATE_STEP_NAVIGATION', data);
	},
	updateFormField({ commit }, data) {
		commit('UPDATE_FORM_FIELD', data);
	},
	updateTempalteAvailable({ commit }, data) {
		commit('UPDATE_TEMPLATE_AVAILABLE', data);
	},
	updateTemplates({ commit, state, dispatch }, data) {
		const templateList = state.steps.find(i => i.name === 'template').templates;
		const selectedTemplateId = templateList.find(i => i.selected) && templateList.find(i => i.selected).templateId || null;
		if (selectedTemplateId && !data.some(i => i.templateId === selectedTemplateId)) dispatch('updateTempalteAvailable', false);
		commit('UPDATE_TEMPLATES', data);
	},
	deleteTemplate({ commit }, data) {
		commit('DELETE_TEMPLATE', data);
	},
	updateSystemUser({ commit }, data) {
		commit('UPDATE_SYSTEM_USER', data);
	},
	selectAllListingsAvailabilities({ commit, rootState, state }) {
		const listings = rootState &&
			rootState['wizard.listing'] &&
			rootState['wizard.listing'].selection &&
			rootState['wizard.listing'].selection.listing &&
			rootState['wizard.listing'].selection.listing || [];
		const availabilitys = rootState && rootState['wizard.listing'] &&
			rootState['wizard.listing'].selection &&
			rootState['wizard.listing'].selection.availability &&
			Array.isArray(rootState['wizard.listing'].selection.availability) && 
			rootState['wizard.listing'].selection.availability
		listings.length && listings.forEach(l => {
			const availabilities = l.Source.availabilities;
			if (availabilities.length) {
				availabilities.forEach(a => {
					if (!availabilitys.some(i => i.Id === a.id)) {
						commit('wizard.listing/changeSelection', {
							records: [{ ...this.rowItem, Id: a.availabilityid, Type: a.type, Name: a.name, Source: { listingid: { id: l.Id } }}],
							select: true
						}, { root: true });
					}
				})
			}
		})
	},
	updateSelectedTemplate({ commit, rootState, state, dispatch }, data) {
		dispatch('updateTempalteAvailable', true);
		const hasStepSelection = Object.values(state.steps.find(i => i.name === 'collection').selection).some(arr => arr.length);
		const isCollectionSelected = (rootState &&
            rootState['wizard.listing'] &&
            rootState['wizard.listing'].selection &&
            rootState['wizard.listing'].selection.listing &&
            rootState['wizard.listing'].selection.listing.length &&
            true) || hasStepSelection || false;
		const oldTemplate = state.steps.find(i => i.name === 'template').templates.find(i => i.selected);
		const newTemplate = state.steps.find(i => i.name === 'template').templates.find(i => i.templateId === data);
		if (oldTemplate && oldTemplate.inputTypes === 'availability' && newTemplate && newTemplate.inputTypes === 'listing') dispatch('selectAllListingsAvailabilities');
		commit('UPDATE_COLLECTION_TYPE', newTemplate.inputTypes);
		if (isCollectionSelected && oldTemplate && oldTemplate.templateId !== data && oldTemplate.inputTypes !== newTemplate.inputTypes) {
			sc.events.emit('vue.dialog.open', {
				icon: 'action-icons-search-save',
				title: oldTemplate.inputTypes === 'listing' ? 'Listing -> Availability' : 'Availability -> Listing',
				component: 'confirm.dialog',
				maximizedWidth: '450px',
				maximizedHeight: 'auto',
				message: oldTemplate.inputTypes === 'listing'
					? 'You are going to change the input type from Listing to Availability. Then, you will be able to manage the number of availabilities in every listing. Ensure the number and order of availabilities are correct'
					: 'You are going to change the input type from Availability to Listing. Then, you will not be able to manage the number of availabilities in every listing (all availabilities will be mentioned). Ensure the number and order of availabilities are correct',
				okLabel: 'Close',
				hideCancel: true
			});
		}
		commit('UPDATE_SELECTED_TEMPLATE', data);
		commit('UPDATE_EDITED_FLAG', true);
	},
	updateFrameSrc({ commit }, data) {
		commit('UPDATE_FRAME_SRC', data);
	},
	updateMcUser({ commit }, data) {
		commit('UPDATE_MC_USER', data);
	},
	updateRecordList({ commit }, data) {
		commit('UPDATE_RECORD_LIST', data);
	},
	updateDetailsProcessStatus({ commit }, data) {
		commit('UPDATE_DETAILS_PROCESS_STATUS', data);
	},
	updateCampaignEdgeId({ commit }, data) {
		commit('UPDATE_CAMPAIGN_EDGE_ID', data);
	},
	updateStepValidationStatus({ commit }, data) {
		commit('UPDATE_STEP_VALIDATION_STATUS', data);
	},
	setListingInitSelection({ commit }, data) {
		commit('PREPARE_LISTING_SELECTION', data);
	},
	setMlInitSelection({ commit }, data) {
		commit('PREPARE_ML_SELECTION', data);
	},
	resetWizardState({ commit, dispatch, state }) {
		commit('RESET_WIZARD_STATE');
		Object.keys(state.steps[0].form).forEach(i => {
			dispatch('updateFormField', {
				step: 1,
				name: i,
				value: ''
			});
		});
	},
	processArtifactChanges({ dispatch, state, rootState }) {
		const TYPE = rootState.wizard.steps.find(i => i.name === 'template').collectionType || null;
		const listings = (rootState && rootState['wizard.listing'] &&
            rootState['wizard.listing'].selection &&
            rootState['wizard.listing'].selection.listing &&
            Array.isArray(rootState['wizard.listing'].selection.listing) &&
            !!rootState['wizard.listing'].selection.listing.length &&
            rootState['wizard.listing'].selection.listing) ||
            (!!rootState.wizard.steps.find(i => i.name === 'collection').selection.listing.length &&
            rootState.wizard.steps.find(i => i.name === 'collection').selection.listing) || [];
		const availabilitys = (rootState && rootState['wizard.listing'] &&
            rootState['wizard.listing'].selection &&
            rootState['wizard.listing'].selection.availability &&
            Array.isArray(rootState['wizard.listing'].selection.availability) &&
            !!rootState['wizard.listing'].selection.availability.length &&
            rootState['wizard.listing'].selection.availability) ||
            (!!rootState.wizard.steps.find(i => i.name === 'collection').selection.availability &&
            !!rootState.wizard.steps.find(i => i.name === 'collection').selection.availability.length &&
            rootState.wizard.steps.find(i => i.name === 'collection').selection.availability) || [];

		if (TYPE === 'listing' && !!listings.length && listings.length <= 100 || TYPE === 'availability' && !!availabilitys.length && availabilitys.length <= 100) {
		// if (listings.length) {
			if (!state.steps.find(i => i.name === 'template').artifact) {
				dispatch('processCollectionForArtifact', [...listings, ...availabilitys]);
			} else {
				const artifact = state.steps.find(i => i.name === 'template').artifact;
				const template = state.steps.find(i => i.name === 'template').templates.find(i => i.selected);
				if (template && template.templateId !== artifact.templateId) {
					dispatch('processCollectionForArtifact', [...listings, ...availabilitys]);
				} else {
					const artifactCollection = artifact.recordIds;
					let selectedCollection = [];
					if (artifact.mainEntity === 'listing') {
						selectedCollection = [...listings.map(i => i.Id)];
					} else {
						const filteredAvailabilitys = listings.reduce((a, i) => {
							return i.Source.availabilities.length ? [...a, ...availabilitys.filter(l => l.Source.listingid.id === i.Id).map(av => av.Id)] : a;
						}, []);
						selectedCollection = [...filteredAvailabilitys];
					}

					let isCollectionChanged;
					if (selectedCollection.length === artifactCollection.length) {
						isCollectionChanged = !selectedCollection.reduce((a, c) => {
							return artifactCollection.some(i => i === c) ? [...a, true] : [...a, false];
						}, []).every(i => i);
					} else isCollectionChanged = true;

					if (isCollectionChanged) dispatch('processCollectionForArtifact', [...listings, ...availabilitys]);
				}
			}
		}
	},
	async processCollectionForArtifact({ dispatch, state }, collection) {
		try {
			const mappedItems = await dispatch('mapItems', collection);
			let selectedTemplateEntity = null;
			dispatch('removeError', { name: 'artifactError', step: 'summary' });

			if (state.steps.find(i => i.name === 'template').templates.find(i => i.selected) && state.steps.find(i => i.name === 'template').templates.find(i => i.selected).inputTypes && state.steps.find(i => i.name === 'template').templates.find(i => i.selected).inputTypes) {
				selectedTemplateEntity = state.steps.find(i => i.name === 'template').templates.find(i => i.selected).inputTypes;
			} else if (state.steps.find(i => i.name === 'template').collectionType) {
				selectedTemplateEntity = state.steps.find(i => i.name === 'template').collectionType;
			}

			if (mappedItems.length) {
				const grouped = _.groupBy(mappedItems, item => item.logicalname);
				const promises = Object.entries(grouped).map(async ([key, items]) => {
					const list = {
						// matchedToTemplate: selectedTemplateEntity === key || !selectedTemplateEntity,
						matchedToTemplate: true,
						removeAll: (item) => {
							dispatch('setActiveGroup', null);
							item.items = [];
							item.itemsCount = 0;
						},
						remove: (group, item) => {
							group.items = group.items.filter(itemVal => itemVal.id !== item.id);
							item.itemsCount--;
						},
						entityName: key,
						entity: await dispatch('getDisplayName', key),
						items,
						isVisible: true
					};
					dispatch('updateRecordList', list);
				});
				await Promise.all(promises).then(() => {
					dispatch('actionTemplateHandler', 'select');
				});
			}
		} catch (err) {
			console.error('error', err);
		}
	},
	async mapItems({ state }, selects) {
		const items = selects || [];
		const result = [];
		const searchItems = items.filter(item => !item.Source);
		let searchResults;
		if (searchItems && searchItems.length) {
			searchResults = await SearchByIds(searchItems[0].logicalname, searchItems.map(item => item.id));
			searchResults = searchResults.Results;
		} else {
			searchResults = items;
		}
		if (searchResults && searchResults.length) {
			searchResults.forEach(item => {
				const entityRef = sc.classes.get('entityReference', item);
				entityRef.Source = item.Source;
				result.push(entityRef);
			});
		}
		return result;
	},
	async getDisplayName({ state }, logicalName) {
		const names = await sc.classes.get('entityConfiguration.dataProvider').getEntityCaptions([logicalName], 'plural', true);
		// TODO: remove eval after getEntityCaptions method is refactored
		return helperMethods.eval(null, (names[0] || logicalName));
	},
	async processSelection({ commit }, data) {
		if (Array.isArray(data)) {
			commit('PREPARE_ML_SELECTION', data);
		} else {
			commit('PREPARE_LISTING_SELECTION', data);
			commit('UPDATE_ENTRY_POINT_STATUS', true);
		}
	},
	async actionTemplateHandler({ commit, dispatch, state }, action) {
		try {
			if (action == 'select') {
				commit('UPDATE_TEMPLATE_PROCESS_STATUS', true);
				commit('UPDATE_FRAME_SRC', null);
				commit('UPDATE_ARTIFACT', null);

				// await coAuthDataProvider.refreshToken().finally(() => {
				await dispatch('createArtifact').then(() => {
					coDataProvider.getArtifactContent(state.steps[1].artifact.artifactId).then(res => {
						commit('UPDATE_FRAME_SRC', res.replace('</style>', 'body{font-family: Open Sans,sans-serif;} body::-webkit-scrollbar {width: 6px;background-color: rgba(170, 170, 170, 0.2);} body:-webkit-scrollbar-track {width: 6px;} body::-webkit-scrollbar-thumb {background-color: rgba(170, 170, 170, 0.5);border-radius: 6px;transition: background-color 0.2s linear;width: 6px;} body::-webkit-scrollbar-thumb:hover{background-color: rgba(170, 170, 170, 1);}</style>').replace('<link href="https://co-qa.ascendixonline.com/ohc/appstatic/fonts/fonts-import.css" rel="stylesheet">', ''));
						commit('UPDATE_EDITED_FLAG', true);
					});
				});
				// })
			} else if (action == 'edit') {
				const newWindow = window.open(`${window.location.origin}/in-progress/${encodeURIComponent('Artifact generation is in progress. Please wait...')}`, '_blank');
				const [coURL, token] = await Promise.all([coDataProvider.getCoURL(), coDataProvider.getToken()]).catch(() => { newWindow.close(); });
				newWindow.location.href = `${coURL}ohc/app/page/artifact/${state.steps[1].artifact.artifactId}?token=${token}`;
			} else if (action == 'refresh') {
				commit('UPDATE_TEMPLATE_PROCESS_STATUS', true);
				coDataProvider.getArtifactContent(state.steps[1].artifact.artifactId).then(res => {
					commit('UPDATE_FRAME_SRC', res.replace('rel="stylesheet">', 'rel="stylesheet"><style>body{font-family: Open Sans,sans-serif;} body::-webkit-scrollbar {width: 6px;background-color: rgba(170, 170, 170, 0.2);} body:-webkit-scrollbar-track {width: 6px;} body::-webkit-scrollbar-thumb {background-color: rgba(170, 170, 170, 0.5);border-radius: 6px;transition: background-color 0.2s linear;width: 6px;} body::-webkit-scrollbar-thumb:hover{background-color: rgba(170, 170, 170, 1);}</style>').replace('<link href="https://co-qa.ascendixonline.com/ohc/appstatic/fonts/fonts-import.css" rel="stylesheet">', ''));
				});
			}
		} catch (e) {
			// TODO: sync with composer team about errors
			if ((e.details && e.details.log.includes(`Template with id ${(state.steps[1].templates.find(i => i.selected) && state.steps[1].templates.find(i => i.selected).templateId) || state.steps[1].templates.find(i => i.deleted) && state.steps[1].templates.find(i => i.deleted).templateId} was not found`)) ||
            (e && e.message && e.message.includes('Validation Failed')) || (e && e.message && e.message === `Artifact with alias ${state.steps[1].artifactAlias} was not found`)) {
				dispatch('setErrors', [{
					text: 'Previously selected template doesn’t exist. Please select another one.',
					type: 'warning',
					close: true,
					step: 'template',
					name: 'templateExist'
				}]);
				dispatch('updateActiveStep', 2);
			}
			return;
		} finally {
			if (state.delayCampaignSend) {
				dispatch('startEmailCampaign');
				commit('UPDATE_DELAY_SEND_STATUS', false);
			}
			commit('UPDATE_TEMPLATE_PROCESS_STATUS', false);
		}
	},
	async createArtifact({ state, rootState, commit, dispatch }) {
		let templateId;
		let collectionType;

		if (state.steps.find(i => i.name === 'template').templates.find(i => i.selected)) {
			templateId = state.steps.find(i => i.name === 'template').templates.find(i => i.selected).templateId;
		} else if (state.steps.find(i => i.name === 'template').templates.find(i => i.deleted)) {
			templateId = state.steps.find(i => i.name === 'template').templates.find(i => i.deleted).templateId;
		}
		if (state.steps.find(i => i.name === 'template').templates.find(i => i.selected) && state.steps.find(i => i.name === 'template').templates.find(i => i.selected).inputTypes) {
			collectionType = state.steps.find(i => i.name === 'template').templates.find(i => i.selected).inputTypes;
		} else if (state.steps.find(i => i.name === 'template').collectionType) {
			collectionType = state.steps.find(i => i.name === 'template').collectionType;
		}

		const artifactAlias = `${templateId}_${Date.now()}`;
		commit('UPDATE_ARTIFACT_ALIAS', artifactAlias);

		let collection = [];
		const collectionForRegeneration = state.steps.find(i => i.name === 'collection').collectionForRegeneration;
		const listings = (rootState &&
            rootState['wizard.listing'] &&
            rootState['wizard.listing'].selection &&
            rootState['wizard.listing'].selection.listing &&
            rootState['wizard.listing'].selection.listing) || (state.steps.find(i => i.name === 'collection').selection.listing) || null;
		const availabilitys = (rootState &&
            rootState['wizard.listing'] &&
            rootState['wizard.listing'].selection &&
            rootState['wizard.listing'].selection.availability &&
            rootState['wizard.listing'].selection.availability.length &&
            rootState['wizard.listing'].selection.availability) || (state.steps.find(i => i.name === 'collection').selection.availability) || [];
		if (!collectionForRegeneration.length) {
			if (collectionType === 'listing') {
				collection = [...listings.map(i => i.Id)];
			} else {
				const availabilities = listings.reduce((a, i) => {
					return i.Source.availabilities.length ? [...a, ...availabilitys.filter(l => l.Source.listingid.id === i.Id).map(av => av.Id)] : a;
				}, []);
				collection = [...availabilities];
			}
		} else collection = collectionForRegeneration;
		const data = {
			templateId,
			artifactName: state.steps[1].templates.find(i => i.selected) && state.steps[1].templates.find(i => i.selected).templateName || `Template ${templateId}`,
			artifactAlias,
			accessible: true,
			entityIds: collection
		};
		try {
			// const freshToken = await dispatch('refreshToken');
			// const jobId = freshToken && await coDataProvider.generateHtml(data);
			const jobId = await coDataProvider.generateHtml(data);
			await coDataProvider.getJobStatus(jobId, 24);
			const artifact = await coDataProvider.getArtifactByAlias(artifactAlias);
			commit('UPDATE_GENERATED_ARTIFACTS_IDS', artifact.artifactId);
			commit('UPDATE_ARTIFACT', artifact);
			return artifact;
		} catch (e) {
			throw e;
		} finally {
			commit('UPDATE_COLLECTION_FOR_REGENERATION', []);
			commit('UPDATE_RECEPIENTS_FOR_REGENERATION', []);
		}
	},
	async refreshToken() {
		try {
			await coAuthDataProvider.refreshToken();
			return true;
		} catch {
			return false;
		}
	},
	async checkDataStatus({ dispatch, commit, state, rootState }) {
		if (state.campaign.edgeId) {
			const { Results } = await SearchByIds('marketingcampaign', state.campaign.edgeId, [
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('selectedFields.filter', [{ logicalname: 'status' }]).fillQuery()
			]);
			if (!Results.length || Results[0].Source.status === 'Deleted') {
				sc.events.emit('toast.open', 'The campaign does not exist anymore', 3000);
				commit('UPDATE_EC_PROCESS_STATUS', false);
				commit('SET_FORCE_EXIT', true);
				return;
			}
		}
		commit('UPDATE_EC_PROCESS_STATUS', true);
		const listings = (rootState &&
			rootState['wizard.listing'] &&
			rootState['wizard.listing'].selection &&
			rootState['wizard.listing'].selection.listing &&
			rootState['wizard.listing'].selection.listing) || (state.steps.find(i => i.name === 'collection').selection.listing) || [];
		const marketingList = (rootState &&
			rootState['wizard.ml'] &&
			rootState['wizard.ml'].selection &&
			rootState['wizard.ml'].selection.staticlist &&
			rootState['wizard.ml'].selection.staticlist.length > 0 &&
			rootState['wizard.ml'].selection.staticlist) || state.steps.find(i => i.name === 'recepients').selection;

		const collection = await SearchByIds('listing', [...listings.map(i => i.Id)]);
		const recepients = await SearchByIds('staticlist', [...marketingList.map(i => i.Id)]);
		const oldActiveListings = listings.map(i => i.Id);
		const currentActiveListings = collection.Results.filter(i => i.Source.recordstate.id === RECORD_STATE.ACTIVE).map(i => i.Id);
		const oldActiveAvailabilities = listings.reduce((acc, i) => {
			return i.Source.availabilities.length > 0 ? [...acc, ...i.Source.availabilities.map(a => a.availabilityid)] : acc;
		}, []);
		const currentActiveAvailabilities = collection.Results.reduce((acc, i) => {
			return i.Source.availabilities.length > 0
				? [...acc, ...i.Source.availabilities.filter(a => a.recordstate.id === RECORD_STATE.ACTIVE).map(item => item.availabilityid)]
				: acc;
		}, []);

		const oldActiveRecepients = marketingList.map(i => i.Source.recordstate.id);
		const currentActiveRecepients = recepients.Results.filter(i => i.Source.recordstate.id === RECORD_STATE.ACTIVE);
		const collectionType = state.steps.find(i => i.name === 'template').collectionType;

		const dataErrors = [];
		oldActiveListings.length !== currentActiveListings.length && dataErrors.push('listings');
		oldActiveAvailabilities.length !== currentActiveAvailabilities.length && dataErrors.push('availabilities');
		oldActiveRecepients.length !== currentActiveRecepients.length && dataErrors.push('recepients');

		if (dataErrors.length) {
			const dataErrorsHandler = () => {
				switch (dataErrors.length) {
				case 2:
					return `${dataErrors[0]} and ${dataErrors[1]}`;
				case 3:
					return `${dataErrors[0]}, ${dataErrors[1]} and ${dataErrors[2]}`;
				default:
					return dataErrors[0];
				}
			};

			sc.events.emit('vue.dialog.open', {
				icon: 'action-icons-search-save',
				title: `Issue with ${dataErrors.join(',')}`,
				component: 'confirm.dialog',
				maximizedWidth: '550px',
				maximizedHeight: 'auto',
				message: `Some of the ${dataErrorsHandler()} were deactivated. Would you like to send the campaign with the previous settings?`,
				onSubmit: () => {
					dispatch('startEmailCampaign');
				},
				onCustomSubmit: () => {
					dataErrors.includes('recepients') && commit('UPDATE_RECEPIENTS_FOR_REGENERATION', currentActiveRecepients);
					if (!!currentActiveListings.length && !!currentActiveRecepients.length) {
						if (dataErrors.some(i => ['listings', 'availabilities'].includes(i))) {
							commit('UPDATE_COLLECTION_FOR_REGENERATION', collectionType === 'listing' ? currentActiveListings : currentActiveAvailabilities);
							commit('UPDATE_DELAY_SEND_STATUS', true);
							dispatch('regenerateArtifact');
						} else dispatch('startEmailCampaign');
					} else {
						dispatch('setErrors', [{
							text: `No active ${dataErrorsHandler()} were found and the campaign was not launched. To send it with all deactivated items, please select the “Send anyway” option`,
							type: 'warning',
							close: true,
							step: null,
							name: 'emptyCollectionError'
						}]);
					}
				},
				onCancel: () => {
					commit('UPDATE_COLLECTION_FOR_REGENERATION', []);
					commit('UPDATE_RECEPIENTS_FOR_REGENERATION', []);
					dispatch('setErrors', [{
						text: `Some ${dataErrorsHandler()} were deactivated. Ensure whether these changes affect your campaign`,
						type: 'warning',
						close: true,
						step: null,
						name: 'collectionError'
					}]);
				},
				okLabel: 'Send anyway',
				cancelLabel: 'Cancel',
				customLabel: 'Send without inactive items'
			});
			commit('UPDATE_EC_PROCESS_STATUS', false);
		} else {
			dispatch('startEmailCampaign').catch(() => {
				commit('UPDATE_MC_USER', {
					accountType: 'MAIL_CHIMP',
					createdAt: 0,
					defaultMailListId: '',
					email: '',
					mcName: '',
					mcUserId: 0,
					plan: 'forever_free',
					updatedAt: 0,
					userId: ''
				});
				commit('UPDATE_EC_PROCESS_STATUS', false);
				throw Error();
			});
		}
	},
	async startEmailCampaign({ dispatch, commit, state, rootState }) {
		commit('UPDATE_EC_PROCESS_STATUS', true);
		const broker = await GetUserInfo();
		const updatedRecepients = state.steps.find(i => i.name === 'recepients').recepientsForRegeneration;
		// TODO: rework after CO Team rework MC start campaign fucntionallity
		const marketingList = updatedRecepients.length
			? updatedRecepients
			: (rootState &&
				rootState['wizard.ml'] &&
				rootState['wizard.ml'].selection &&
				rootState['wizard.ml'].selection.staticlist &&
				rootState['wizard.ml'].selection.staticlist.length > 0 &&
				rootState['wizard.ml'].selection.staticlist) || (state.steps.find(i => i.name === 'recepients').selection);
		const segmentName = `Multiple: ${marketingList.reduce((a, b, i) => { return i === marketingList.length - 1 ? a + `${b.Name}` : a + `${b.Name},`; }, '')}`;
		const getOrCreateDefaultList = async () => {
			let list;
			try {
				list = await getDefaultList();
			} catch (er) {
				list = await createDefaultList({ 
					from_name: state.steps[0].form.from_name.value, 
					from_email: `${state.steps[0].form.email.value}${state.steps[0].domains.find(i => i.selected).displayName}`
				});
			}
			return list;
		};
		let [contacts, brokerUser, list] = await Promise.all([
			Search('staticlistmember',
				[
					sc.classes.get('offsetSize.filter', 10000),
					sc.classes.get('termFacet.filter', {
						logicalName: 'staticlist.id',
						query: [...marketingList.map(i => i.Id)]
					}).fillQuery(),
					sc.classes.get('termFacet.filter', {
						logicalName: 'recordstate.id',
						query: [RECORD_STATE.ACTIVE]
					}).fillQuery(),
					sc.classes.get('selectedFields.filter', [
						{
							logicalname: 'staticlistmemberentityreferencecontact.email',
							title: 'contactEmail'
						}
					]).fillQuery()
				]),
			SearchByIds('systemuser', [broker.systemuserid],
				[
					sc.classes.get('offsetSize.filter', 1),
					sc.classes.get('selectedFields.filter', [
						{ logicalname: 'email' },
						{ logicalname: 'fullname' }
					]).fillQuery()
				]),
			getOrCreateDefaultList().catch(() => {
				throw Error('no default list');
			})
		]);
		brokerUser = brokerUser.Results[0];
		const getSegment = (list) => (list.segments || []).find(segment => segment.name.toLowerCase() === segmentName.toLowerCase());
		let segment = getSegment(list);
		const members = contacts.Results.map(item => item.Source.contactEmail);
		const status = await addMembersToList(members, [segmentName]);
		const membersResult = await getMembersStatus(status.id);
		const failedMembers = membersResult.erroredOperations;
		const totalMembers = membersResult.totalOperations;
		try {
			if (failedMembers === totalMembers && !(segment && segment.memberCount)) {
				throw Error();
			}
			// TODO: rework flow with failed emails in R7.1
			// else if (failedMembers > 0) {
			// 	sc.events.emit('vue.dialog.info', {
			// 		title: 'Contacts Import',
			// 		message: `Imported contacts: ${totalMembers - failedMembers} out of ${totalMembers}. Please make sure that the emails you provided are correct.`,
			// 		showFooter: false
			// 	});
			// }
			if (!segment) {
				list = await getDefaultList();
				segment = getSegment(list);
			}
			await createCampaign({
				tagId: segment.id,
				title: state.steps[0].form.campaign_name.value,
				previewText: state.steps[0].form.preview_text.value,
				fromName: state.steps[0].form.from_name.value,
				replyTo: `${state.steps[0].form.email.value}${state.steps[0].domains.find(i => i.selected).displayName}`,
				// TODO: ask about Broker email
				// replyTo: brokerUser.Source.email,
				subject: state.steps[0].form.subject.value,
				artifactId: state.steps[1].artifact.artifactId
				// artifactId: '8b6ce576-b2e7-4f6f-83f1-0fe2c9993fc0'
			}).then(res => {
				commit('UPDATE_CAMPAIGN', res.id);
			});
			// create campaign in EDGE -> create campaign in MC -> send campaign
			await startCampaign(state.campaign.id);
			state.campaign.edgeId
				? await dispatch('saveAsDraft', {
					withBack: false,
					defaultPage: false,
					toast: false
				})
				: await dispatch('createRecord', 'Sent');
			commit('UPDATE_EDITED_FLAG', false);

			sc.events.emit('vue.dialog.open', {
				icon: 'action-icons-search-save',
				title: 'Confirmation',
				component: 'confirm.dialog',
				maximizedWidth: '450px',
				maximizedHeight: 'auto',
				allowClose: false,
				message: 'The campaign was successfully sent.',
				onSubmit: () => {
					dispatch('back');
				},
				okLabel: 'Close',
				hideCancel: true
			});
			dispatch('removeOldArtifacts');
		} catch (e) {
			console.log('error', e);
			if (e.message === 'Not Found') {
				sc.events.emit('vue.dialog.open', {
					icon: 'action-icons-search-save',
					title: 'Issues with the publication',
					component: 'confirm.dialog',
					maximizedWidth: '450px',
					maximizedHeight: 'auto',
					message: 'Publication was deleted. To proceed with sending the campaign, you must have a generated publication. Would you like to regenerate it?',
					onSubmit: () => {
						commit('UPDATE_DELAY_SEND_STATUS', true);
						dispatch('regenerateArtifact');
					},
					onCancel: () => {
						commit('UPDATE_FRAME_SRC', null);
						commit('UPDATE_ARTIFACT', null);
						commit('UPDATE_REGENERATE_LATER', true);
						dispatch('setErrors', [{
							text: 'Publication was deleted on the background and the campaign cannot be sent. Please, regenerate it to proceed.',
							type: 'error',
							close: false,
							step: 'summary',
							name: 'artifactError'
						}]);
					},
					okLabel: 'Regenerate now',
					cancelLabel: 'Regenerate later'
				});
			} else {
				console.error('error', e);
			}
		} finally {
			commit('UPDATE_EC_PROCESS_STATUS', false);
		}
	},
	async createRecord({ state, rootState }, campaignStatus) {
		const broker = await GetUserInfo();
		const setupStep = state.steps.find(step => step.name === 'setup');
		const templateStep = state.steps.find(step => step.name === 'template');

		// const email = setupStep.form.email.value ? `${setupStep.form.email.value}@${setupStep.mcUser.email.split('@')[1]}` : setupStep.mcUser.email;
		const email = setupStep.form.email.value;

		const body = {
			name: setupStep.form.campaign_name.value,
			brokerid: {
				id: broker.systemuserid,
				logicalname: 'systemuser',
				name: broker.fullname
			},
			artifactid: templateStep.artifact.artifactId,
			templateid: templateStep.templates.find(t => t.selected).templateId,
			...(state.campaign.id && { mcicampaignid: state.campaign.id }),
			systemuserserviceaccountid: sc.classes.get('entityReference', setupStep.systemuser),
			status: campaignStatus,
			fromname: setupStep.form.from_name.value,
			fromemail: email,
			domain: setupStep.domains.find(i => i.selected).displayName,
			previewtext: setupStep.form.preview_text.value,
			subject: setupStep.form.subject.value,
			collectiontype: templateStep.templates.length && templateStep.templates.some(t => t.selected)
				? templateStep.templates.find(t => t.selected).inputTypes
				: templateStep.artifact && templateStep.artifact.mainEntity ? templateStep.artifact.mainEntity : 'collection'
		};
		const campaign = await Create('marketingcampaign', body).catch(e => {
			console.log('marketingcampaign creation errror', e);
		});

		// START / TODO: Temporary marketingcampaignmember for single staticlist. Rework after Wizard will be done.
		const updatedRecepients = state.steps.find(i => i.name === 'recepients').recepientsForRegeneration;
		const marketingList = updatedRecepients.length
			? updatedRecepients
			: (rootState &&
				rootState['wizard.ml'] &&
				rootState['wizard.ml'].selection &&
				rootState['wizard.ml'].selection.staticlist &&
				rootState['wizard.ml'].selection.staticlist.length > 0 &&
				rootState['wizard.ml'].selection.staticlist) || (state.steps.find(i => i.name === 'recepients').selection);
		const records = [];
		marketingList.forEach(i => {
			records.push(
				{
					id: generateGuid(),
					method: 'POST',
					url: 'marketingcampaignmember',
					body: {
						type: 'marketingcampaignmember',
						name: ' ',
						marketingcampaignid: {
							id: campaign.Id,
							logicalname: 'marketingcampaign'
						},
						recordid: {
							id: i.Id,
							logicalname: i.Type
						}
					}
				}
			);
		});
		// END
		const recordsList = [];
		templateStep.recordsList.filter(item => item.matchedToTemplate).forEach(list => recordsList.push(list.items.map(item => {
			return {
				id: item.id,
				logicalname: item.logicalname
			};
		})));
		recordsList.flat().forEach(rec => {
			const record = {
				id: generateGuid(),
				method: 'POST',
				url: 'marketingcampaignmember',
				body: {
					type: 'marketingcampaignmember',
					name: ' ', // required field in EDGE
					marketingcampaignid: {
						id: campaign.Id,
						logicalname: 'marketingcampaign'
					},
					recordid: {
						id: rec.id,
						logicalname: rec.logicalname
					}
				}
			};
			records.push(record);
		});

		await CreateBulk(records, { async: true }).catch(e => {
			console.log('records createBulk error ', e);
		});
	},
	async saveAsDraft({ state, rootState, getters, commit, dispatch }, settings = {
		withBack: true,
		defaultPage: true,
		toast: true
	}) {
		const generateBulkRecord = (method, url, body = {}) => (
			{
				id: generateGuid(),
				method,
				url,
				body
			}
		);
		const splitByChunks = (records, chunkLength) => {
			const chunks = [];
			while (records.length) {
				chunks.push(records.splice(0, chunkLength));
			}
			return chunks;
		};
		const errorHandler = () => {
			!state.errorPopupStatus && sc.events.emit('vue.dialog.open', {
				icon: 'action-icons-search-save',
				title: 'Error',
				component: 'confirm.dialog',
				maximizedWidth: '450px',
				maximizedHeight: 'auto',
				allowClose: false,
				message: 'The campaign has not been fully saved. The reason: An internal error occurs. Please, try again',
				onSubmit: async () => {
					commit('UPDATE_ERROR_POPUP_STATUS', false);
					await dispatch('saveAsDraft', {
						withBack: settings.withBack,
						defaultPage: settings.defaultPage,
						toast: settings.toast
					});
				},
				onCancel: () => {
					commit('UPDATE_EC_PROCESS_STATUS', false);
					commit('UPDATE_ERROR_POPUP_STATUS', false);
					Update('marketingcampaign', draftCampaignBody.marketingcampaignid || edgeId, { savinginterrupted: bulkRecords.length > chunkLength });
				},
				okLabel: 'Retry',
				cancelLabel: 'Close'
			});
			commit('UPDATE_ERROR_POPUP_STATUS', true);
		};
		const processBulkRequests = (requestsBodies, campaignId, chunkLength) => {
			function onBeforeUnload(e) {
				e.preventDefault();
				return e.returnValue = '';
			};
			const update = () => {
				Update('marketingcampaign', campaignId, {
					status: state.campaign.id ? 'Sent' : 'Draft',
					...(state.campaign.id && { mcicampaignid: state.campaign.id }),
					savinginterrupted: false
				})
					.then(() => {
						settings.toast && sc.events.emit('toast.open', 'Email Campaign has been saved', 3000);
						commit('UPDATE_SAVED_FLAG', true);
						commit('UPDATE_EC_PROCESS_STATUS', false);
						settings.withBack && dispatch('back', settings.defaultPage);
					})
					.catch(() => errorHandler())
					.finally(() => {
						removeEventListener('beforeunload', onBeforeUnload);
						commit('removeProcessingId',
							{
								logicalName: 'marketingcampaign',
								id: campaignId
							},
							{ root: true }
						);
					});
			};

			addEventListener('beforeunload', onBeforeUnload);

			if (requestsBodies.length) {
				commit('addProcessingId',
					{
						logicalName: 'marketingcampaign',
						id: campaignId
					},
					{ root: true }
				);
				const chunks = splitByChunks(requestsBodies, chunkLength);
				const sendByChunks = () => {
					CreateBulk(...chunks.splice(0, 1), { async: true })
						.then(() => {
							chunks.length ? sendByChunks() : update();
						});
				};
				sendByChunks();
			} else {
				update();
			}
		};

		commit('UPDATE_EC_PROCESS_STATUS', true);

		const edgeId = state.campaign.edgeId;
		if (edgeId) {
			const { Results } = await SearchByIds('marketingcampaign', edgeId, [
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('selectedFields.filter', [{ logicalname: 'status' }]).fillQuery()
			]);
			if (!Results.length || Results[0].Source.status === 'Deleted') {
				sc.events.emit('toast.open', 'The campaign does not exist anymore', 3000);
				commit('UPDATE_EC_PROCESS_STATUS', false);
				commit('SET_FORCE_EXIT', true);
				return;
			}
		}

		const bulkRecords = [];
		const { systemuserid } = await GetUserInfo();
		const chunkLength = 50;
		const setupStep = state.steps.find(step => step.name === 'setup');
		const templateStep = state.steps.find(step => step.name === 'template');
		const collectionStep = state.steps.find(step => step.name === 'collection');
		const recepientsStep = state.steps.find(step => step.name === 'recepients');

		// const email = setupStep.form.email.value ? `${setupStep.form.email.value}@${setupStep.mcUser.email.split('@')[1]}` : setupStep.mcUser.email;
		const email = setupStep.form.email.value;

		const draftCampaignBody = {
			wizardstep: getters.activeStepNumber,
			collectiontype: templateStep.templates.length && templateStep.templates.some(t => t.selected)
				? templateStep.templates.find(t => t.selected).inputTypes
				: templateStep.artifact && templateStep.artifact.mainEntity ? templateStep.artifact.mainEntity : 'collection',
			fromname: setupStep.form.from_name.value,
			fromemail: email,
			domain: setupStep.domains.find(i => i.selected).displayName,
			previewtext: setupStep.form.preview_text.value,
			subject: setupStep.form.subject.value,
			savinginterrupted: true,
			status: 'Saving',
			brokerid: {
				id: systemuserid,
				logicalname: 'systemuser'
			},
			systemuserserviceaccountid: {
				id: setupStep.systemuser.Id,
				logicalname: setupStep.systemuser.Type || setupStep.systemuser.type
			},
			...(!edgeId && { marketingcampaignid: generateGuid() }),
			...(templateStep.artifact && templateStep.artifact.artifactId && { artifactid: templateStep.artifact.artifactId }),
			...(templateStep.templates.length && templateStep.templates.some(t => t.selected) && { templateid: templateStep.templates.find(t => t.selected).templateId })
		};

		if (setupStep.form.campaign_name.value) {
			draftCampaignBody.name = setupStep.form.campaign_name.value;
		} else {
			const { date, time } = getFormattedDateAndTime(new Date());
			draftCampaignBody.name = `Untitled - ${date} - ${time}`;
		}

		const stateStaticlist = (rootState['wizard.ml'] && rootState['wizard.ml'].selection.staticlist); /* marketing lists */
		const stateListing = (rootState['wizard.listing'] && rootState['wizard.listing'].selection.listing); /* listings */
		const stateAvailability = (rootState['wizard.listing'] && rootState['wizard.listing'].selection.availability); /* availabilities */
		const campaignMembers = [
			...(stateAvailability || collectionStep.selection.availability || []),
			...(stateListing || collectionStep.selection.listing || []),
			...(stateStaticlist || recepientsStep.selection || [])
		];

		if (!edgeId) {
			campaignMembers.forEach(member => {
				bulkRecords.push(
					generateBulkRecord('POST', 'marketingcampaignmember', {
						name: member.Name,
						recordid: {
							id: member.Id,
							logicalname: member.Type
						},
						marketingcampaignid: {
							id: draftCampaignBody.marketingcampaignid || edgeId,
							logicalname: 'marketingcampaign'
						}
					})
				);
			});
		}

		if (edgeId) {
			Search('marketingcampaignmember', [
				sc.classes.get('offsetSize.filter', 10000),
				sc.classes.get('termFacet.filter', {
					logicalName: 'marketingcampaignid.id',
					query: [edgeId]
				}).fillQuery(),
				sc.classes.get('selectedFields.filter', [{ logicalname: 'recordid.id' }]).fillQuery()
			]).then(({ Results }) => {
				const recordsToDelete = Results.filter(({ Source }) =>
					!campaignMembers.some(member => Source.recordid.id === member.Id)
				);
				const recordsToCreate = campaignMembers.filter(member =>
					!Results.some(({ Source }) => Source.recordid.id === member.Id)
				);

				recordsToCreate && recordsToCreate.forEach(record => {
					bulkRecords.push(
						generateBulkRecord('POST', 'marketingcampaignmember', {
							name: record.Name,
							recordid: {
								id: record.Id,
								logicalname: record.Type
							},
							marketingcampaignid: {
								id: draftCampaignBody.marketingcampaignid || edgeId,
								logicalname: 'marketingcampaign'
							}
						})
					);
				});
				recordsToDelete.length && recordsToDelete.forEach(({ Id }) => {
					bulkRecords.push(
						generateBulkRecord('DELETE', `marketingcampaignmember/${Id}`)
					);
				});
				processBulkRequests(bulkRecords, edgeId, chunkLength);
			});
			await Update('marketingcampaign', edgeId, draftCampaignBody).catch(() => errorHandler());
		} else {
			await Create('marketingcampaign', draftCampaignBody).catch(() => errorHandler());
			processBulkRequests(bulkRecords, draftCampaignBody.marketingcampaignid, chunkLength);
		}

		const generatedArtifactsIds = state.generatedArtifactsIds;
		generatedArtifactsIds.pop();

		Promise.all(generatedArtifactsIds.map(id => coDataProvider.deleteArtifact(id)));
	},
	back({ commit }, customPage) {
		(router.currentRoute.params.refreshEvents || refreshEvents).forEach(e => sc.events.emit(e));
		router.push(customPage ? `${getBaseRouteUrl(router.currentRoute)}/email-campaigns` : router.currentRoute.params.entryPoint);
	},
	async setDetailsData({ commit, dispatch }, { campaignId, withEditMode }) {
		const membersMapping = {
			listing: [],
			staticlist: [],
			availability: []
		};
		const [{ Results, campaignData = Results[0].Source }, { Results: campaignMembers }] = await Promise.all(
			[
				Search('marketingcampaign', [
					sc.classes.get('offsetSize.filter', 1),
					sc.classes.get('termFacet.filter', {
						logicalName: 'marketingcampaignid',
						query: [campaignId]
					}).fillQuery(),
					sc.classes.get('selectedFields.filter', [
						{ logicalname: 'name' },
						{ logicalname: 'status' },
						{ logicalname: 'subject' },
						{ logicalname: 'fromname' },
						{ logicalname: 'fromemail' },
						{ logicalname: 'domain' },
						{ logicalname: 'collectiontype' },
						{ logicalname: 'templateid' },
						{ logicalname: 'artifactid' },
						{ logicalname: 'previewtext' },
						{ logicalname: 'createdby.id' },
						{ logicalname: 'modifiedby.id' },
						{ logicalname: 'mcicampaignid' },
						{ logicalname: 'marketingcampaignid' },
						{ logicalname: 'systemuserserviceaccountid.id' },
						{ logicalname: 'modifiedby.id' },
						{ logicalname: 'createdby.id' },
						{ logicalname: 'modifiedon' },
						{ logicalname: 'modifiedbyfullname' },
						{ logicalname: 'recordstate.id' },
						{ logicalname: 'marketingcampaignsystemuserserviceaccountidsystemuserserviceaccount.serviceaccountuserid' },
						...(withEditMode
							? [{ logicalname: 'wizardstep' }, { logicalname: 'savinginterrupted' }]
							: [])
					]).fillQuery()
				]),
				Search('marketingcampaignmember', [
					sc.classes.get('offsetSize.filter', 10000),
					sc.classes.get('termFacet.filter', {
						logicalName: 'marketingcampaignid.id',
						query: [campaignId]
					}).fillQuery(),
					sc.classes.get('selectedFields.filter', [
						{ logicalname: 'name' },
						{ logicalname: 'recordid.id' },
						{ logicalname: 'recordid.logicalname' }
					]).fillQuery()
					// ...(!withEditMode
					// 	? [sc.classes.get('termFacet.filter', {
					// 		logicalName: 'recordid.logicalname',
					// 		query: ['availability'],
					// 		negative: true
					// 	}).fillQuery()]
					// 	: [])
				])
			]
		);
		const templateFilters = {
			idListFilter: {
				exactMatch: true,
				values: [campaignData.templateid]
			},
			offset: 0,
			limit: 1,
			searchType: 'ALL'
		};
		/* entities */
		const entities = [...new Set(campaignMembers.map(({ Source }) => Source.recordid.logicalname))];
		const values = {};
		/* relation filter for staticlist */
		const getRelation = type => type === 'staticlist' && sc.classes.get('search.relationCount', {
			logicalName: 'staticlistmember',
			aliasName: 'contacts'
		}, undefined).toFilter();
		/* selected fields filters */
		/* TODO: investigate needed fields */
		// const getSelectedFields = type => {
		// 	if (type === 'listing') {
		// 		return sc.classes.get('selectedFields.filter', [
		// 			{ logicalname: 'address' },
		// 			{ logicalname: 'listingimages' },
		// 			{ logicalname: 'availabilityimages' },
		// 			{ logicalname: 'listingpropertyidproperty.address' },
		// 			{ logicalname: 'availabilitypropertyproperty.address' },
		// 			{ logicalname: 'usetype' }
		// 		]).fillQuery();
		// 	} else return { /* TODO: create filter for 'staticlist' */ };
		// };

		/* set ID-s for entities */
		entities.forEach(element => {
			values[element] = campaignMembers.filter(({ Source }) => Source.recordid.logicalname === element).map(({ Source }) => Source.recordid.id);
		});
		/* search data for collections */
		const membersData = await Promise.all(
			Object.keys(values).map(el => SearchByIds(el, values[el], [getRelation(el)]))
		);
		membersData.forEach(({ Results }) => {
			Results.forEach(i => {
				membersMapping[Results[0].Type].push(i);
			});
		});

		let template, iframeContent;
		let artifactExist = true;
		const { systemuserid } = await GetUserInfo();

		if (campaignData.status === 'Sent') {
			if (await coAuthDataProvider.isTokenAvailable()) {
				await coAuthDataProvider.refreshToken().catch(async () => {
					await coAuthDataProvider.authenticate(`${window.location.origin}/${router.currentRoute.params.clusterKey}/${router.currentRoute.params.config}/coauth`, false);
				})
			} else {
				await coAuthDataProvider.authenticate(`${window.location.origin}/${router.currentRoute.params.clusterKey}/${router.currentRoute.params.config}/coauth`, false);
			}
		}

		if (campaignData.artifactid) {
			// iframeContent = await coDataProvider.getArtifactContent('609a10c4-9975-4a6d-801e-24c1e999abd8').then((res) => {
			iframeContent = await coDataProvider.getArtifactContent(campaignData.artifactid).then(res => res).catch((e) => {
				if (JSON.stringify(e.cause).includes('Artifact not found')) {
					dispatch('artifactError', [...membersMapping.listing, ...membersMapping.availability]);
					artifactExist = false;
				}
				campaignData.artifactid = null;
				return null;
			});
		}

		if (campaignData.templateid) {
			template = await coDataProvider.searchTemplates(templateFilters, true).then((res) => {
				if (res.htmlTemplates.length > 0) {
					return res.htmlTemplates;
				} else {
					if (Number(campaignData.wizardstep) !== 2 && !campaignData.artifactid && artifactExist) dispatch('templateDetailsError');
					// return [{templateId: '999', dummyTemplate: true}]
					return [{
						templateId: campaignData.templateid,
						dummyTemplate: true
					}];
				}
			}).catch((e) => {
				if (JSON.stringify(e.cause).includes('invalid string value') && Number(campaignData.wizardstep) !== 2 && !campaignData.artifactid && artifactExist) dispatch('templateDetailsError');
				// return [{templateId: '999', dummyTemplate: true}]
				return [{
					templateId: campaignData.templateid,
					dummyTemplate: true
				}];
			});
		}

		if (campaignData.modifiedby.id !== campaignData.createdby.id && campaignData.modifiedby.id !== systemuserid) {
			const { date, time } = getFormattedDateAndTime(new Date(campaignData.modifiedon));
			dispatch('setErrors', [{
				text: `The campaign was edited by ${campaignData.modifiedbyfullname} on ${date} at ${time}`,
				type: 'warning',
				close: true,
				step: null,
				name: 'globalCampaignEdit'
			}]);
		}

		const mutationsMapping = {
			name: (value) => {
				commit('UPDATE_FORM_FIELD', {
					step: 1,
					name: 'campaign_name',
					value
				});
				commit('UPDATE_CAMPAIGN_NAME', value);
			},
			savinginterrupted: (value) => {
				commit('UPDATE_SAVING_STATUS', value);
				value && dispatch('setErrors', [{
					text: 'Some data was lost during the campaign saving process.',
					type: 'warning',
					close: true,
					step: null,
					name: 'globalDataLost'
				}]);
			},
			subject: (value) => commit('UPDATE_FORM_FIELD', {
				step: 1,
				name: 'subject',
				value
			}),
			fromemail: (value) => commit('UPDATE_FORM_FIELD', {
				step: 1,
				name: 'email',
				value
			}),
			domain: (value) => commit('SET_DOMAINS', [{
				displayName: value,
				selected: true
			}]),
			fromname: (value) => commit('UPDATE_FORM_FIELD', {
				step: 1,
				name: 'from_name',
				value
			}),
			previewtext: (value) => commit('UPDATE_FORM_FIELD', {
				step: 1,
				name: 'preview_text',
				value
			}),
			wizardstep: (value) => dispatch('updateActiveStep', value),
			status: (value) => commit('UPDATE_CAMPAIGN_STATUS', value),
			mcicampaignid: (value) => commit('UPDATE_CAMPAIGN_MCI_ID', value),
			marketingcampaignid: (value) => commit('UPDATE_CAMPAIGN_EDGE_ID', value),
			'systemuserserviceaccountid.id': (value) => commit('UPDATE_XMS_ACCOUNT_ID', value),
			'marketingcampaignsystemuserserviceaccountidsystemuserserviceaccount.serviceaccountuserid': (value) => commit('UPDATE_MCI_ACCOUNT_ID', value),
			template: (value) => commit('UPDATE_TEMPLATES', value),
			// templateid: (value) => commit('UPDATE_SELECTED_TEMPLATE', '999'),
			templateid: (value) => commit('UPDATE_SELECTED_TEMPLATE', value),
			artifactid: (value) => {
				commit('UPDATE_ARTIFACT', {
					// artifactId: '609a10c4-9975-4a6d-801e-24c1e999abd8',
					artifactId: value,
					templateId: campaignData.templateid,
					recordIds: campaignData.collectiontype === 'listing' ? [...membersMapping.listing.map(({ Id }) => Id)] : [...membersMapping.availability.map(({ Id }) => Id)],
					mainEntity: campaignData.collectiontype
				});
				commit('UPDATE_GENERATED_ARTIFACTS_IDS', value);
				commit('UPDATE_CAMPAIGN_WITH_ARTIFACT_STATUS', true);
			},
			iframeContent: (value) => commit(
				'UPDATE_FRAME_SRC',
				value
					.replace('</style>', 'body{font-family: Open Sans,sans-serif}</style>')
					.replace('<link href="https://co-qa.ascendixonline.com/ohc/appstatic/fonts/fonts-import.css" rel="stylesheet">', '')
			),
			listing: (value) => commit('PREPARE_LISTING_SELECTION', { listing: value }),
			availability: (value) => commit('PREPARE_AVAILABILITY_SELECTION', value),
			staticlist: (value) => commit('PREPARE_ML_SELECTION', value),
			collectiontype: (value) => commit('UPDATE_COLLECTION_TYPE', value)
		};

		const detailsData = {
			wizardstep: 5,
			...(iframeContent && { iframeContent }),
			...(campaignData.templateid && { template }),
			...campaignData,
			...membersMapping
		};

		Object.entries(mutationsMapping).forEach(([key, mutation]) => {
			const path = key.split('.');
			const valueToSet = path.length > 1
				? path.reduce((acc, prop) => acc[prop], detailsData)
				: detailsData[key];

			!isNullOrUndefined(valueToSet) && mutation(valueToSet);
		});
	},
	templateDetailsError({ dispatch }) {
		sc.events.emit('vue.dialog.open', {
			icon: 'action-icons-search-save',
			title: 'Issues with the template',
			component: 'confirm.dialog',
			maximizedWidth: '450px',
			maximizedHeight: 'auto',
			message: 'Previously selected template doesn’t exist. Please select another one.',
			onSubmit: async () => {
				dispatch('updateActiveStep', 2);
			},
			okLabel: 'Go to Templates',
			hideCancel: true,
			allowClose: false
		});
	},
	artifactError({ state, dispatch }, selection) {
		sc.events.emit('vue.dialog.open', {
			icon: 'action-icons-search-save',
			title: 'Issues with the publication',
			component: 'confirm.dialog',
			maximizedWidth: '450px',
			maximizedHeight: 'auto',
			message: 'Publication was deleted. Would you like to regenerate it?',
			onSubmit: () => {
				dispatch('processCollectionForArtifact', [...selection]);
			},
			onCancel: () => {
				dispatch('setErrors', [{
					text: 'Publication was deleted on the background and the campaign cannot be sent. Please, regenerate it to proceed.',
					type: 'error',
					close: false,
					step: 'summary',
					name: 'artifactError'
				}]);
				if (state.steps.find(i => i.name === 'template').templates.some(i => i.dummyTemplate)) dispatch('updateActiveStep', 2);
			},
			okLabel: 'Regenerate now',
			cancelLabel: 'Regenerate later'
		});
	},
	setErrors({ commit }, data) {
		commit('SET_ERRORS', data);
	},
	removeError({ commit }, data) {
		commit('REMOVE_ERROR', data);
	},
	regenerateArtifact({ state, dispatch, rootState }) {
		const listings = (rootState &&
            rootState['wizard.listing'] &&
            rootState['wizard.listing'].selection &&
            rootState['wizard.listing'].selection.listing &&
            rootState['wizard.listing'].selection.listing) || (state.steps.find(i => i.name === 'collection').selection.listing) || [];
		const availabilitys = (rootState &&
            rootState['wizard.listing'] &&
            rootState['wizard.listing'].selection &&
            rootState['wizard.listing'].selection.availability &&
            rootState['wizard.listing'].selection.availability.length &&
            rootState['wizard.listing'].selection.availability) || (state.steps.find(i => i.name === 'collection').selection.availability) || [];
		dispatch('processCollectionForArtifact', [...listings, ...availabilitys]);
	},
	/* TODO: merge with send campaign action */
	async createCampaignForTest({ commit, state, rootState }, options) {
		if (state.campaign.edgeId) {
			const { Results } = await SearchByIds('marketingcampaign', state.campaign.edgeId, [
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('selectedFields.filter', [{ logicalname: 'status' }]).fillQuery()
			]);
			if (!Results.length || Results[0].Source.status === 'Deleted') {
				sc.events.emit('toast.open', 'The campaign does not exist anymore', 3000);
				return;
			}
		};
		/* TODO: move to module function */
		const getOrCreateDefaultList = async () => {
			let list = null;
			try {
				list = await getDefaultList();
			} catch (er) {
				if (er.type === 2) {
					commit('UPDATE_EC_PROCESS_STATUS', false);
					commit('UPDATE_MC_USER', {
						accountType: 'MAIL_CHIMP',
						createdAt: 0,
						defaultMailListId: '',
						email: '',
						mcName: '',
						mcUserId: 0,
						plan: 'forever_free',
						updatedAt: 0,
						userId: ''
					});
					return;
				}
				await createDefaultList({
					from_name: state.steps[0].form.from_name.value,
					from_email: `${state.steps[0].form.email.value}${state.steps[0].domains.find(i => i.selected).displayName}`
				});
				list = await getDefaultList();
			}
			return list;
		};
		const list = await getOrCreateDefaultList();
		if (!list) return false;
		const campaign = {
			tagId: list.segments[0].id,
			title: state.steps[0].form.campaign_name.value,
			previewText: state.steps[0].form.preview_text.value,
			fromName: state.steps[0].form.from_name.value,
			replyTo: `${state.steps[0].form.email.value}${state.steps[0].domains.find(i => i.selected).displayName}`,
			subject: state.steps[0].form.subject.value,
			artifactId: state.steps[1].artifact.artifactId
		};

		let result = null;

		try {
			result = await createCampaign(campaign);
		} catch (e) {
			console.error(e);
			commit('UPDATE_EC_PROCESS_STATUS', false);
			if (e.type === 2) {
				commit('UPDATE_MC_USER', {
					accountType: 'MAIL_CHIMP',
					createdAt: 0,
					defaultMailListId: '',
					email: '',
					mcName: '',
					mcUserId: 0,
					plan: 'forever_free',
					updatedAt: 0,
					userId: ''
				});
				return false;
			}
			return false;
		}
		if (!result) {
			result = await createCampaign(campaign);
		}
		const campaignId = result.id;
		try {
			/* send test campaign */
			const { status } = await sendTestCampaign(campaignId, { testEmails: options.testEmails });
			showToast(status);
		} catch (e) {
			showToast(e.details.status, e.details.detail);
		}
		/* delete campaign after test sending (will be fixed in new version) */
		if (campaignId) await deleteCampaign(campaignId);
	},
	updateEntryPointStatus({ commit }, data) {
		commit('UPDATE_ENTRY_POINT_STATUS', data);
	},
	updateAvailabilitiesSelection({ commit }, data) {
		commit('PREPARE_AVAILABILITY_SELECTION', data);
	},
	updateEditedFlag({ commit }, data) {
		commit('UPDATE_EDITED_FLAG', data);
	},
	updateLimitError({ commit }, data) {
		commit('UPDATE_LIMIT_ERROR', data);
	},
	updateCollectionLimitWarning({ commit }, data) {
		commit('UPDATE_COLLECTION_LIMIT_WARNING', data);
	},
	removeOldArtifacts({ state }) {
		const generatedArtifactsIds = state.generatedArtifactsIds;
		generatedArtifactsIds.pop();
		generatedArtifactsIds.length && Promise.all(generatedArtifactsIds.map(id => coDataProvider.deleteArtifact(id)));
	},
	updateForceExit({ commit }, data) {
		commit('SET_FORCE_EXIT', data);
	},
	async getMCDomains({ commit }, data) {
		await getDomains()
			.then(res => {
				commit('SET_DOMAINS', res.domains.map((i) => {
					return {
						...i,
						displayName: `@${i.domain}`,
						selected: false
					};
				}));
			});
	}
};

export default actions;
