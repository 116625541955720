<template>
	<div class="layout-accessbylink-wrapper">
		<template v-if="!noAccess">
			<div id="page-wrapper" class="layout-marketspace" v-if="userName && !error">
				<div class="marketspace-layout-wrapper">
					<div class="marketspace-anon-logo-wrapper">
						<div class="marketspace-anon-logo">
							<svg class='svg-icon' v-if="!anonymousLogoUrl">
								<use xlink:href="#tenant-logo-anon-logo"></use>
							</svg>
							<image-wrapper v-else :url="anonymousLogoUrl" :width="'100%'" :height="'80px'" :fit="'cover'"></image-wrapper>
						</div>
						<div class="component-header">
                            <MenuConversation :options="{enabled: true, hide: true}" />
							<button @click="redirectToSignIn">Sign In</button>
						</div>
					</div>
					<div class="marketspace-content-wrapper">
						<div class="main-content-area adaptive">
							<div v-if="ready" class="marketspace-components-area adaptive">
								<div class="component">
									<router-view :key="$route.path" :accessByLink="false" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="dialogmanager-footer">
					<old-dialog-manager></old-dialog-manager>
					<dialog-manager></dialog-manager>
				</div>
				<toast-pop-up></toast-pop-up>
				<absolute-menu-popup></absolute-menu-popup>
				<absolute-template-popup></absolute-template-popup>
			</div>
			<div v-else-if="!userName" class="initialization-wrapper">
				<div class="dialog" v-show="!isLoading">
					<logo></logo>
					<accessbylink-login-form v-if="!error" @input="onUserNameSubmitted"></accessbylink-login-form>
					<confirm-dialog v-else :options="confirmDlgOptions"></confirm-dialog>
				</div>
				<div class="credits"><span>Powered by <a target="_blank" href="https://ascendix.com">Ascendix Technologies</a></span></div>
			</div>
		</template>
		<div class="no-access" v-else>
			<details-no-access :requestedAccess="null" />
		</div>
		<div class="loading-overlay large" v-show="isLoading"></div>
	</div>
</template>
<script>

import Logo from '@/Components/Initialization/InitializationLoader/logo';
import AccessbylinkLoginForm from './accessbylink-login-form';
import ToastPopUp from '@/Components/ToastPopUp/toast-pop-up';
import DialogManager from '@/Components/Dialog/dialog-manager';
import OldDialogManager from '@/Components/Dialog/old-dialog-manager';
import AbsoluteMenuPopup from '@/Components/Menu/absolute-menu-popup';
import AbsoluteTemplatePopup from '@/Components/Popup/absolute-template-popup';
import ConfirmDialog from '@/Components/Dialog/confirm-dialog';
import ImageWrapper from '@/Components/Common/image-wrapper';
import { Get as GetItem, Set as SetItem } from '@/Data/DataProviders/userStorageDataProvider';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import {
	getFullRecordLink, formLogoUrl, Search, Get, Create
} from '@acx-xms/data-functions/dist';
import LayoutMarketspaceMixin from '@/Components/Layout/layout.marketspace.mixins';
import MenuConversation from '@/Components/Menu/menu-app-bar/menu-conversation.vue';
const DetailsNoAccess = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Common/details-no-access');

export default {
	name: 'layout-marketspace-accessbylink',
	data() {
		return {
			error: false,
			ready: true,
			userName: '',
			isLoading: true,
			confirmDlgOptions: {},
			noAccess: false,
			conversationPanelProps: null
		};
	},
	components: {
		AbsoluteMenuPopup,
		DialogManager,
		OldDialogManager,
		AbsoluteTemplatePopup,
		ToastPopUp,
		Logo,
		AccessbylinkLoginForm,
		ConfirmDialog,
		DetailsNoAccess,
		ImageWrapper,
		MenuConversation
	},
	async created() {
		const allowedTypes = ['collaborationroom'];
		this.lspath = `${this.$route.params.layout}_${this.$route.params.type}_${this.$route.params.id}`;
		if (['details'].indexOf(this.$route.name) === -1 || !allowedTypes.includes(this.$route.params.type)) { // if not allowed route
			this.noAccess = true;
			this.isLoading = false;
			return;
		}
		const [record, systemUser] = await Promise.all([Get(this.$route.params.type, this.$route.params.id), GetUserInfo()]);
		if (!record) {
			this.noAccess = true;
			this.isLoading = false;
			return;
		}
		this.systemuserid = systemUser.systemuserid;
		this.userName = await GetItem(this.lspath);
		this.isLoading = false;
	},
	mixins: [LayoutMarketspaceMixin],
	methods: {
		async onUserNameSubmitted(name) {
			this.isLoading = true;
			await SetItem(this.lspath, name);
			const cpResults = await Search(['chatparticipant'],
				[
					sc.classes.get('offsetSize.filter', 0),
					sc.classes.get('termFacet.filter', {
						logicalName: `chatparticipantchatidchat.${this.$route.params.type}id.id`,
						query: [`${this.$route.params.id}`]
					}).fillQuery(),
					sc.classes.get('termFacet.filter', {
						logicalName: 'systemuserid.id',
						query: [this.systemuserid]
					}).fillQuery(),
					sc.classes.get('termFacet.filter', {
						logicalName: 'name',
						query: [name]
					}).fillQuery(),
					sc.classes.get('selectedFields.filter', []).fillQuery()
				]
			);
			if (cpResults.Total) {
				const localization = sc.classes.get('localization.dataProvider');
				const localizations = {
					title: await localization.getLabelForCurrentLanguage('common.dictionary.confirmDlgCaption'),
					message: await localization.getLabelForCurrentLanguage('common.dictionary.nonRegisteredParticipant.exist'),
					okLabel: await localization.getLabelForCurrentLanguage('common.dictionary.nonRegisteredParticipant.proceed.yes'),
					cancelLabel: await localization.getLabelForCurrentLanguage('common.dictionary.nonRegisteredParticipant.proceed.no')
				};
				this.isLoading = false;
				this.confirmDlgOptions = {
					title: localizations.title,
					message: localizations.message,
					onSubmit: () => {
						this.userName = name;
						this.error = false;
					},
					okLabel: localizations.okLabel,
					onCancel: async () => {
						this.userName = '';
						this.error = false;
						await SetItem(this.lspath, this.userName);
					},
					cancelLabel: localizations.cancelLabel
				};
				this.error = true;
			} else {
				const [contactRes, chatRes] = await Promise.all([
					Search('contact', [
						sc.classes.get('offsetSize.filter', 1),
						sc.classes.get('termFacet.filter', {
							logicalName: 'systemuserid.id',
							query: [this.systemuserid]
						}).fillQuery()
					]),
					Search('chat', [
						sc.classes.get('offsetSize.filter', 1),
						sc.classes.get('termFacet.filter', {
							logicalName: `${this.$route.params.type}id.id`,
							query: [`${this.$route.params.id}`]
						}).fillQuery()
					])
				]);
				await Create('chatparticipant', {
					name,
					contactid: {
						id: contactRes.Results[0].Id,
						logicalname: 'contact'
					},
					systemuserid: {
						id: this.systemuserid,
						logicalname: 'systemuser'
					},
					chatid: {
						id: chatRes.Results[0].Id,
						logicalname: 'chat'
					},
					primary: false,
					ispending: true,
					isroomowner: false,
					nonregistered: true
				}, null, { syncProcessMode: 'Brief' })
					.catch((e) => {
						window.sessionStorage.setItem('redirect_url', window.location.href);
						if (e.Code === 'MaintenanceInProgress') {
							const errorMessage = e.message || e.Detail;
							this.$router.push({
								name: 'maintenance',
								query: {
									restore: errorMessage.Operation.toLowerCase() === 'restore' ? 'true' : 'false',
									redirect_url: window.sessionStorage.getItem('redirect_url')
								}
							});
						}
					});

				this.userName = name;
				await this.timeout(1500); // TODO: revisit. after record creation it doesnt appear in search request and user cant find himself in room
				this.isLoading = false;
			}
		},
		timeout(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		},
		redirectToSignIn() {
			const route = {
				params: {
					...this.$route.params,
					layout: 'main',
					config: 'MarketSpace'
				}
			};
			window.location.href = getFullRecordLink(route, {
				id: this.$route.params.id,
				logicalname: this.$route.params.type
			});
		}
	},
	computed: {
		anonymousLogoUrl() {
			return this.$store.getters.logoanonymousid ? formLogoUrl(this.$store.getters.logoanonymousid) : null;
		}
	}
};
</script>
<!--TODO: create separate less file-->
<style src="./layout-marketspace-accessbylink.less" scoped></style>
<style src="@/Components/Layout/ui-elements.less" scoped></style>
