var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-manager" }, [
    _c(
      "div",
      { staticClass: "dialogues-area newer" },
      [
        _vm._l(_vm.dialogues, function(dialog) {
          return _c("dialog-wrapper", {
            key: dialog.id,
            attrs: { initDialog: dialog }
          })
        }),
        _vm._v(" "),
        _vm.fullscreenComponent
          ? _c(_vm.fullscreenComponent.name, {
              tag: "component",
              attrs: { options: _vm.fullscreenComponent.options },
              on: { close: _vm.onCloseFullscreen }
            })
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }