/* eslint no-throw-literal:0 */
import {
	Search, Update, Get
} from '@acx-xms/data-functions/dist';
import { CONFIG_TEMPLATES_FIELDS } from '@/Data/selected-fields';
import Store from '@/States/ms-state';

const logicalName = 'configtemplate';
let _templates = null;
const _previewedTemplates = {};

async function _fetchConfigTemplates() {
	let results;
	try {
		results = await Search(
			[logicalName],
			[
				sc.classes.get('offsetFrom.filter', 0),
				sc.classes.get('offsetSize.filter', 9999),
				sc.classes.get('termFacet.filter', {
					logicalName: 'active',
					query: [true]
				}).fillQuery(),
				sc.classes.get('selectedFields.filter', CONFIG_TEMPLATES_FIELDS.map(field => ({ logicalname: field }))).fillQuery()
			]);
		const configTemplates = results.Results.map(result => result.Source);

		_addTemplatesToStore(configTemplates);
		_templates = configTemplates;

		return configTemplates;
	} catch (error) {
		sc.utils.errorMessage.byResponse(error);
	}
}

function _addTemplatesToStore(data) {
	const templates = {
		MarketSpace: {
			listing: [],
			availability: [],
			contact: []
		},
		MSGuest: {
			listing: [],
			availability: [],
			contact: []
		}
	};
	data.forEach(template => {
		const templateUserType = template.configuration;
		const entity = template.entity;

		templates[templateUserType][entity].push(template);
	});

	Store.commit('changeConfigTemplates', templates);
}

function _getExistingPreviewedTemplate(entity, type) {
	return Object.values(_previewedTemplates).find(template => template &&
		template.entity.toLocaleLowerCase() === entity && template.templatetype === type);
}

async function _findTemplate(id) {
	const template = _templates.find(template => template.configtemplateid === id);
	if (template) {
		return template;
	}
	// if we preview template - add it to _templates array for configtemplate.control
	let result = await Get(logicalName, id);
	result = result.Source;
	_templates.push(result);
	return result;
}

export async function getCurrentConfigTemplates() {
	if (_templates !== null) {
		return _templates;
	}
	return await _fetchConfigTemplates();
}
export async function getActiveTemplate(entity, type) {
	if (!_templates) {
		await this.getCurrentConfigTemplates();
	}
	let template = null;
	let previewedTemplate = null;
	if (entity && type && _templates && _templates.length) {
		previewedTemplate = _getExistingPreviewedTemplate(entity, type);
		if (previewedTemplate) {
			template = _templates.find(template => previewedTemplate.id === template.configtemplateid);
		} else {
			template = _templates.find(template => template.entity.toLocaleLowerCase() === entity && template.templatetype === type && template.active &&
				template.configuration === sc.cluster.configKey);
		}

		return (template && JSON.parse(template.content)) || template;
	}
	return template;
}
export async function applyConfigTemplate(id) {
	const { entity, templatetype } = await _findTemplate(id);
	Store.commit('removePreviewConfigTemplate', {
		entity,
		templatetype
	});
	const existingPreviewedTemplate = _getExistingPreviewedTemplate(entity, templatetype);
	if (existingPreviewedTemplate) {
		delete _previewedTemplates[existingPreviewedTemplate.id];
	}
	_templates = null;
	try {
		await Update(logicalName, id, { active: true });
	} catch (error) {
		sc.utils.errorMessage.byResponse(error);
	}
	await _fetchConfigTemplates();
}
export async function previewConfigTemplate(id) {
	if (!_templates) {
		await this.getCurrentConfigTemplate();
	}
	const template = await _findTemplate(id);
	const { entity, templatetype, configuration } = template;

	if (configuration === sc.cluster.configKey) {
		Store.commit('addPreviewConfigTemplate', template);
		const existingPreviewedTemplate = _getExistingPreviewedTemplate(entity, templatetype);
		if (existingPreviewedTemplate && !_previewedTemplates[id]) {
			delete _previewedTemplates[existingPreviewedTemplate.id];
		}
		_previewedTemplates[id] = {
			entity,
			templatetype,
			configuration,
			id
		};
	} else {
		const localizations = sc.classes.get('localization.dataProvider');
		throw {
			title: localizations.localize('entity.editor.validationError'),
			message: localizations.localize('common.dictionary.configtemplate.dialog.previewError')
		};
	}
}
export function updateConfigTemplate(id, template) {
	const loadedTemplateIndex = _templates.findIndex(template => template.configtemplateid === id);
	// if template is loaded - update it. Otherwise it will be loaded from EDGE
	if (loadedTemplateIndex !== -1) {
		_templates[loadedTemplateIndex] = template;
	}
}
