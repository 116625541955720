<template>
	<span>{{text}}</span>
</template>
<script>
export default {
	name: 'action-menu',
	props: {
		logicalname: String,
		isSingular: Boolean
	},
	data() {
		return { text: '' };
	},
	async created() {
		const captions = await sc.classes.get('entityConfiguration.dataProvider').getEntityCaptions([this.logicalname], this.isSingular ? 'singular' : 'plural', true);
		this.text = captions[0];
	}
};
</script>
