var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logout-wrapper" }, [
      _c("div", { staticClass: "message-wrapper" }, [
        _c("p", { staticClass: "text" }, [
          _vm._v(
            "\n\t\t\tTo complete sign out of your account,\n\t\t\tplease close all browser windows.\n\t\t"
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }