import { render, staticRenderFns } from "./layout-marketspace-accessbylink.vue?vue&type=template&id=2427c551&scoped=true&"
import script from "./layout-marketspace-accessbylink.vue?vue&type=script&lang=js&"
export * from "./layout-marketspace-accessbylink.vue?vue&type=script&lang=js&"
import style0 from "./layout-marketspace-accessbylink.less?vue&type=style&index=0&id=2427c551&scoped=true&lang=css&"
import style1 from "@/Components/Layout/ui-elements.less?vue&type=style&index=1&id=2427c551&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2427c551",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\SCCore\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('2427c551', component.options)
    } else {
      api.reload('2427c551', component.options)
    }
    module.hot.accept("./layout-marketspace-accessbylink.vue?vue&type=template&id=2427c551&scoped=true&", function () {
      api.rerender('2427c551', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Scripts/Vue/Components/Layout/Layouts/accessbylink/layout-marketspace-accessbylink.vue"
export default component.exports