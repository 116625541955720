<template>
	<BaseErrorWrapper :errorMessage="errorMessage">
		<button type="submit" @click="retry">Retry</button>
		<button type="submit" @click="logOut">Log out</button>
	</BaseErrorWrapper>
</template>
<script>
import BaseErrorWrapper from './base-error-wrapper';
import axios from 'axios';
import ErrorMixin from './error.mixin';

export default {
	name: 'license-error-wrapper',
	components: { BaseErrorWrapper },
	mixins: [ErrorMixin],
	methods: {
		retry() {
			const clusterKey = localStorage.getItem('authInfo').ClusterKey;
			this.$router.push(`/${clusterKey}`);
		},
		async logOut() {
			const errorObj = JSON.parse(this.$store.state.errorMessage);

			const authInfo = localStorage.getItem('authInfo');
			if (authInfo && authInfo.OneTimeToken && authInfo.ClusterKey) {
				const url = await axios.delete(`/api/Authentication/${authInfo.ClusterKey}/${errorObj.AuthorizationToken}`);
				window.location.assign(url.data);
			}
		}
	}
};
</script>
