var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isLoading
      ? _c(
          "div",
          { staticClass: "layout-marketspace", attrs: { id: "page-wrapper" } },
          [
            _c(
              "div",
              { staticClass: "marketspace-layout-wrapper" },
              [
                !_vm.isAnonymous
                  ? _c(
                      "div",
                      {
                        class: ["marketspace-menu-wrapper", _vm.mainMenuState]
                      },
                      [
                        _c("TenantLogo", {
                          attrs: {
                            alt: _vm.companyTitle,
                            "logo-icon": _vm.logoIcon,
                            state: _vm.mainMenuState
                          }
                        }),
                        _vm._v(" "),
                        _c("MenuMarketspace", {
                          attrs: {
                            items: _vm.menu.menu.item,
                            state: _vm.mainMenuState
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAnonymous
                  ? _c(
                      "div",
                      { staticClass: "marketspace-anon-logo-wrapper" },
                      [
                        _vm.anonymousLogoUrl
                          ? _c("ImageWrapper", {
                              attrs: {
                                alt: _vm.companyTitle,
                                url: _vm.anonymousLogoUrl,
                                width: "100%",
                                height: "80px",
                                fit: "cover"
                              }
                            })
                          : _c("div", { staticClass: "svg-container" }, [
                              _c("svg", { staticClass: "svg-icon" }, [
                                _c("use", {
                                  attrs: {
                                    "xlink:href": "#tenant-logo-anon-logo"
                                  }
                                })
                              ])
                            ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      "marketspace-content-wrapper",
                      _vm.mainMenuState,
                      !_vm.isAnonymous ? "hasMenu" : ""
                    ]
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "main-header-area" },
                      [
                        !_vm.isAnonymous
                          ? _c(
                              "div",
                              {
                                directives: [
                                  { name: "data-attr", rawName: "v-data-attr" }
                                ],
                                staticClass: "collapse-menu-btn",
                                on: { click: _vm.switchMenuState }
                              },
                              [
                                _c("svg", { staticClass: "svg-icon" }, [
                                  _c("use", {
                                    staticClass: "icon-ms-btn-menu",
                                    attrs: {
                                      "xlink:href": "#layout-icons-ms-btn-menu"
                                    }
                                  })
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "main-header-menu-wrapper" },
                          [
                            _vm.hasLicenceModule &&
                            !(_vm.isAnonymous || _vm.isGuest)
                              ? _c("LicenseInfo")
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.marketspaceHeaderMenu
                              ? _c("MarketspaceHeaderMenu", {
                                  attrs: {
                                    item: _vm.marketspaceHeaderMenu.item
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.favoritesPanel ? _c("MenuFavorites") : _vm._e(),
                            _vm._v(" "),
                            _vm.notificationPanel
                              ? _c("MenuNotification")
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.conversationPanelProps &&
                            _vm.conversationPanelProps.enabled
                              ? _c("MenuConversation", {
                                  attrs: { options: _vm.conversationPanelProps }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isGuestConfig && _vm.isOrgAdmin
                              ? _c("MenuOrgSettings")
                              : _vm._e(),
                            _vm._v(" "),
                            _c("MenuKnowledgeBase")
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(_vm.userProfileMenusMap[_vm.userProfileMenu.$type], {
                          tag: "component",
                          attrs: {
                            isAnonymous: _vm.isAnonymous,
                            params: { userProfileMenu: _vm.userProfileMenu }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "main-content-area adaptive" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "marketspace-components-area adaptive"
                          },
                          [
                            _vm.ready && _vm.trackingReady
                              ? _c(
                                  "div",
                                  { staticClass: "component" },
                                  [
                                    _c("router-view", {
                                      key: _vm.$route.path,
                                      attrs: {
                                        defaultRoute: _vm.defaultMenuItem
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "keep-alive",
                                      {
                                        attrs: {
                                          include: "route-search-results"
                                        }
                                      },
                                      [
                                        _c("router-view", {
                                          key: _vm.$route.path,
                                          attrs: {
                                            defaultRoute: _vm.defaultMenuItem,
                                            name: "route"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.ready,
                                  expression: "!ready"
                                }
                              ],
                              staticClass: "loading-overlay"
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "keep-alive",
                          [
                            _vm.sidePanelActive
                              ? _c(
                                  _vm.sidePanelComponent,
                                  _vm._b(
                                    {
                                      directives: [
                                        {
                                          name: "clickoutside",
                                          rawName: "v-clickoutside",
                                          value: {
                                            visible: _vm.sidePanelActive,
                                            callback: _vm.toggleSidePanel
                                          },
                                          expression:
                                            "{ visible: sidePanelActive, callback: toggleSidePanel}"
                                        }
                                      ],
                                      tag: "component",
                                      staticClass: "side-panel",
                                      on: { closePanel: _vm.closeSidePanel }
                                    },
                                    "component",
                                    _vm.sidePanelOptions,
                                    false
                                  )
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "dialogmanager-footer" },
                  [_c("OldDialogManager"), _vm._v(" "), _c("DialogManager")],
                  1
                ),
                _vm._v(" "),
                _c("ToastPopUp"),
                _vm._v(" "),
                _c("AbsoluteMenuPopup"),
                _vm._v(" "),
                _c("AbsoluteTemplatePopup")
              ],
              1
            ),
            _vm._v(" "),
            _c("Tooltip")
          ],
          1
        )
      : _c(
          "div",
          [_c("InitializationPanel", { attrs: { message: "Almost there" } })],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }