export default {
	enabled(options, context, [selection]) {
		return $.Deferred(function (def) {
			def.resolve(sc.user && sc.user.hasPrivilege(selection.Type || selection.logicalname, 'Share'));
		}).promise();
	},
	async execute(options, context, [selection]) {
		const text = await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('dialogues.shareSavedSearch.shareEntity', [context.eval(options.entityTitleName)]);
		const title = `${text}: ${selection.Name || selection.name}`;
		sc.events.emit('vue.dialog.open', {
			icon: 'action-icons-search-save',
			title,
			allowPin: true,
			component: 'share.entity.dialog',
			maximizedWidth: '80%',
			maximizedHeight: '100%',
			evaluationContext: context,
			modalNamespace: options.modalNamespace,
			stateNamespace: options.stateNamespace,
			userEntity: options.userEntity,
			selection,
			userListTemplate: options.userListTemplate,
			userLookupTemplate: options.userLookupTemplate,
			toastMessageKey: context.eval(options.toastMessageKey),
			informationDialogTextKey: context.eval(options.informationDialogTextKey),
			sendNotification: context.eval(options.sendNotification),
			shareRelatedEntities: options.shareRelatedEntities
		});
	}
};
