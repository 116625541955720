import themesDataProvider from '@/Data/DataProviders/themesDataProvider';
import { mapParametersObject } from './Methods/action.methods';
import { showToastOrInfoDialog } from '@acx-xms/data-functions/dist';

export default {
	async enabled() {
		return true;
	},
	async execute(options, context, selection) {
		sc.events.emit('dialog.confirm', 'dialogs.themeChangeConfirmation.message', async () => {
			const parameters = mapParametersObject(options.parameter, context);
			if (!parameters.entity && selection && selection.length) {
				parameters.entity = sc.classes.get('entityReference', selection[0]);
			}
			if (parameters.entity && parameters.entity.id) {
				await themesDataProvider.applyTheme(parameters.entity.id);
				await showToastOrInfoDialog({
					toastMessageKey: context.eval(options.toastMessageKey),
					informationDialogTextKey: context.eval(options.informationDialogTextKey)
				});
			}
		}, null, null, undefined, 'dialogs.apply', 'dialogs.cancel');
	}
};
