<template>
	<div class="marketspace-header-menu" v-if="items.length">
		<div class="items">
			<menu-item class="p6" v-for="item in items"
						:options="item.menuItem"
						:evaluationContext="context"
						:key="item.menuItem.action.$type"
						@click.native="executeCallback(item.menuItem.action)"/>
		</div>
	</div>
</template>
<script>
import MenuItem from './../Items/menu-item.vue';
import MenuMixins from './../menu.mixins';
export default {
	name: 'menu-marketspace-header',
	props: { item: Array },
	mixins: [MenuMixins],
	created() {
		this.fillActionArrayAsync(this.item, this.context, [], 'items');
	},
	data() {
		return { items: [] };
	},
	methods: {
		executeCallback(action) {
			this.executeAction(action, this.context, []);
		}
	},
	components: { MenuItem }
};
</script>
<style src="./menu-marketspace-header.less" scoped></style>
