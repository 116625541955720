import ExpressionHasvalue from '../boolean/hasvalue';

export default {
	parameters(options) {
		return [
			this.eval(options.separator),
			options.argument.map(this.eval)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.separator),
			await Promise.all(options.argument.map(await this.evalAsync))
		];
	},
	evaluate(separator, args) {
		if (!separator) {
			separator = '';
		}
		return args.filter(item => ExpressionHasvalue.evaluate(item)).join(separator);
	}
};
