import EntityEdit from './action.entity.edit';
import EntityCreate from './action.entity.create';
import Deactivate from './action.entity.deactivate';
import DeactivateListing from './action.entity.deactivateListing';
import DeactivateAvailability from './action.entity.deactivateAvailability';
import DeactivateWithCoownerCheck from './action.deactivateWithCoownerCheck';
import Reactivate from './action.entity.reactivate';
import ReactivateListing from './action.entity.reactivateListing';
import ReactivateAvailability from './action.entity.reactivateAvailability';
import MarketspaceEmailLink from './action.marketspace.emailLink';
import CopyLink from './action.copyLink';
import ReportingserviceAdd from './action.reportingservice.add';
import CreateDocument from './action.createDocument';
import CreateDealRoom from './plugins.MSComponents.dealroom.create';
import EntityDelete from './action.entity.delete';
import AddListing from './plugins.MSComponents.collaborationroom.addListing';
import DownloadFile from './action.downloadFile';
import MarketingStaticListEdit from './action.marketingList.staticList.edit'; // TODO: used only in colliers guest, consider removing
import EntityUpdateWithParameter from './action.entity.updateWithParameter';
import UpdateWithParameterWithCoownerCheck from './action.updateWithParameterWithCoownerCheck';
import StaticListAddMember from './action.staticList.msAddMember';
import GuestDeleteDocument from './action.marketspace.guestDeleteDocument';
import ConfigSwitch from './action.config.switch';
import EntityShare from './action.entity.share';
import SavedSearchShareDeleteContact from './action.savedSearch.share.deleteContact';
import SavedSearchDelete from './action.savedSearch.delete';
import SavedSearchRemoveShared from './action.savedSearch.removeShared';
import MsExportToExcel from './plugins.ExportPlugin.msExportToExcel';
import OpenConversationsPanel from './plugins.MSComponents.conversationsPanel.open';
import copyChatMessage from './action.chatmessage.copy';
import VueEditor from './action.vue.editor';
import VueEditorCopy from './action.vue.editor.copy';
import AddBrokerslist from './action.brokerslist.add';
import ThemePreview from './action.theme.preview';
import ThemeApply from './action.theme.apply';
import ConfigApply from './action.config.apply';
import ConfigPreview from './action.config.preview';
import InviteParticipants from './action.inviteParticipants';
import DeleteParticipant from './action.deleteParticipant';
import MarketingListAddToList from './plugins.MSComponents.ml.AddToList';
import MarketingListEdit from './action.ml.edit';
import MarketingListCreate from './action.ml.create';
import MarketingListDeleteMember from './action.marketingList.deleteMember';
import MarketingListDeletePredefinedMember from './action.marketinglist.deletePredefinedMember';
import MakeRecordPublic from './action.makeRecordPublic';
import MakeRecordPrivate from './action.makeRecordPrivate';
import CreateRelation from './action.createRelation';
import SharedTo from './action.sharedTo';
import ShareSearch from './action.shareSearch';
import ShareSelected from './action.shareSelected';
import CreateRelatedRecord from './action.createRelatedRecord';
import LeaveRoom from './action.leaveRoom';
import ManageSecurityRoles from './action.manageSecurityRoles';
import ManageAccessToRoom from './action.manageAccessToRoom';
import AssignLicense from './action.assignLicense';
import ApproveAccessToRoom from './action.approveAccessToRoom';
import ImportTemplate from './action.importTemplate';
import DownloadRecordAsJSON from './action.downloadRecordAsJSON';
import OpenPath from './action.openPath';
import OpenInSalesforce from './action.openInSalesforce';
import PublishToPortal from './action.publishToListingPortal';
import UnpublishFromListingPortal from './action.unpublishFromListingPortal';
import SelectRecord from './action.selectRecord';
import MappingEdit from './action.mapping.edit';
import MappingResetToDefault from './action.mapping.resetToDefault';
import RelateToContactInCRM from './action.relateToContactInCRM';
import AddPreference from './action.addPreference';
import EventEmiter from './action.eventEmiter';
import ConvertInquiry from './action.convertInquiry';
import CreateCollaborationRoom from './action.createCollaborationRoom';
import DealroomAddNDA from './action.dealroom.addNDA';
import ShareddocuementsOpen from './action.shareddocuements.open';
import SetPrimaryCRListing from './action.setPrimaryCRListing';
import StaticListCreate from './action.staticlist.create';
import StaticListDetails from './action.staticlist.details';
import StaticListEdit from './action.staticlist.edit';
import StaticListAddTo from './action.staticlist.addTo';
import StaticListAddListing from './action.staticlist.addListing';
import OpenImport from './action.openImport';
import StaticListCopy from './action.staticlist.copy';
import MCIOpen from './action.mci.open';
import MCWizardOpen from './action.mcWizardOpen';
import SystemUserServiceCreate from './action.systemUserService.create';
import SystemUserServiceDelete from './action.systemUserService.delete';
import EmailCampaignView from './action.emailCampaign.view';
import EmailCampaignSendTestEmail from './action.emailCampaign.sendTestEmail';
import EmailCampaignStartCampaign from './action.emailCampaign.startCampaign';
import EmailCampaignDelete from './action.emailCampaign.delete';
import EmailCampaignReport from './action.emailCampaign.report';
import DocumentFolderAddTo from './action.documentFolderAddTo';
import OpenComposerEntityDetails from './action.openComposerEntityDetails';
import SendTestEmailDeleteRecipient from './action.sendTestEmail.deleteRecipient';

export default function () {
	const actionsDictionary = {};
	actionsDictionary['action.entity.edit'] = EntityEdit;
	actionsDictionary['action.entity.create'] = EntityCreate;
	actionsDictionary['action.entity.deactivate'] = Deactivate;
	actionsDictionary['action.entity.deactivateListing'] = DeactivateListing;
	actionsDictionary['action.entity.deactivateAvailability'] = DeactivateAvailability;
	actionsDictionary['action.deactivateWithCoownerCheck'] = DeactivateWithCoownerCheck;
	actionsDictionary['action.entity.reactivate'] = Reactivate;
	actionsDictionary['action.entity.reactivateListing'] = ReactivateListing;
	actionsDictionary['action.entity.reactivateAvailability'] = ReactivateAvailability;
	actionsDictionary['action.marketspace.emailLink'] = MarketspaceEmailLink;
	actionsDictionary['action.copyLink'] = CopyLink;
	actionsDictionary['action.reportingservice.add'] = ReportingserviceAdd;
	actionsDictionary['action.createDocument'] = CreateDocument;
	actionsDictionary['plugins.MSComponents.dealroom.create'] = CreateDealRoom;
	actionsDictionary['action.publishToListingPortal'] = PublishToPortal;
	actionsDictionary['action.unpublishFromListingPortal'] = UnpublishFromListingPortal;
	actionsDictionary['action.inviteParticipants'] = InviteParticipants;
	actionsDictionary['action.entity.delete'] = EntityDelete;
	actionsDictionary['plugins.MSComponents.collaborationroom.addListing'] = AddListing;
	actionsDictionary['action.downloadFile'] = DownloadFile;
	actionsDictionary['action.marketingList.staticList.edit'] = MarketingStaticListEdit;
	actionsDictionary['action.entity.updateWithParameter'] = EntityUpdateWithParameter;
	actionsDictionary['action.updateWithParameterWithCoownerCheck'] = UpdateWithParameterWithCoownerCheck;
	actionsDictionary['action.staticList.msAddMember'] = StaticListAddMember;
	actionsDictionary['action.marketspace.guestDeleteDocument'] = GuestDeleteDocument;
	actionsDictionary['action.config.switch'] = ConfigSwitch;
	actionsDictionary['action.entity.share'] = EntityShare;
	actionsDictionary['action.savedSearch.share.deleteContact'] = SavedSearchShareDeleteContact;
	actionsDictionary['action.savedSearch.delete'] = SavedSearchDelete;
	actionsDictionary['action.savedSearch.removeShared'] = SavedSearchRemoveShared;
	actionsDictionary['plugins.ExportPlugin.msExportToExcel'] = MsExportToExcel;
	actionsDictionary['plugins.MSComponents.conversationsPanel.open'] = OpenConversationsPanel;
	actionsDictionary['action.chatmessage.copy'] = copyChatMessage;
	actionsDictionary['action.vue.editor'] = VueEditor;
	actionsDictionary['action.vue.editor.copy'] = VueEditorCopy;
	actionsDictionary['action.brokerslist.add'] = AddBrokerslist;
	actionsDictionary['action.theme.preview'] = ThemePreview;
	actionsDictionary['action.theme.apply'] = ThemeApply;
	actionsDictionary['action.config.apply'] = ConfigApply;
	actionsDictionary['action.config.preview'] = ConfigPreview;
	actionsDictionary['action.dealroom.addNDA'] = DealroomAddNDA;
	actionsDictionary['action.deleteParticipant'] = DeleteParticipant;
	actionsDictionary['plugins.MSComponents.marketinglist.addToList'] = MarketingListAddToList;
	actionsDictionary['action.marketinglist.edit'] = MarketingListEdit;
	actionsDictionary['action.marketinglist.create'] = MarketingListCreate;
	actionsDictionary['action.createCollaborationRoom'] = CreateCollaborationRoom;
	actionsDictionary['action.marketingList.deleteMember'] = MarketingListDeleteMember;
	actionsDictionary['action.makeRecordPublic'] = MakeRecordPublic;
	actionsDictionary['action.makeRecordPrivate'] = MakeRecordPrivate;
	actionsDictionary['action.marketinglist.deletePredefinedMember'] = MarketingListDeletePredefinedMember;
	actionsDictionary['action.createRelation'] = CreateRelation;
	actionsDictionary['action.sharedTo'] = SharedTo;
	actionsDictionary['action.shareSearch'] = ShareSearch;
	actionsDictionary['action.shareSelected'] = ShareSelected;
	actionsDictionary['action.createRelatedRecord'] = CreateRelatedRecord;
	actionsDictionary['action.leaveRoom'] = LeaveRoom;
	actionsDictionary['action.manageSecurityRoles'] = ManageSecurityRoles;
	actionsDictionary['action.manageAccessToRoom'] = ManageAccessToRoom;
	actionsDictionary['action.assignLicense'] = AssignLicense;
	actionsDictionary['action.approveAccessToRoom'] = ApproveAccessToRoom;
	actionsDictionary['action.importTemplate'] = ImportTemplate;
	actionsDictionary['action.downloadRecordAsJSON'] = DownloadRecordAsJSON;
	actionsDictionary['action.openPath'] = OpenPath;
	actionsDictionary['action.openInSalesforce'] = OpenInSalesforce;
	actionsDictionary['action.selectRecord'] = SelectRecord;
	actionsDictionary['action.mapping.edit'] = MappingEdit;
	actionsDictionary['action.mapping.resetToDefault'] = MappingResetToDefault;
	actionsDictionary['action.relateToContactInCRM'] = RelateToContactInCRM;
	actionsDictionary['action.addPreference'] = AddPreference;
	actionsDictionary['action.eventEmiter'] = EventEmiter;
	actionsDictionary['action.convertInquiry'] = ConvertInquiry;
	actionsDictionary['action.shareddocuements.open'] = ShareddocuementsOpen;
	actionsDictionary['action.setPrimaryCRListing'] = SetPrimaryCRListing;
	actionsDictionary['action.staticlist.create'] = StaticListCreate;
	actionsDictionary['action.staticlist.details'] = StaticListDetails;
	actionsDictionary['action.staticlist.edit'] = StaticListEdit;
	actionsDictionary['action.staticlist.addTo'] = StaticListAddTo;
	actionsDictionary['action.staticlist.addListing'] = StaticListAddListing;
	actionsDictionary['action.import'] = OpenImport;
	actionsDictionary['action.staticlist.copy'] = StaticListCopy;
	actionsDictionary['action.mci.open'] = MCIOpen;
	actionsDictionary['action.mcWizardOpen'] = MCWizardOpen;
	actionsDictionary['action.systemUserService.create'] = SystemUserServiceCreate;
	actionsDictionary['action.systemUserService.delete'] = SystemUserServiceDelete;
	actionsDictionary['action.emailCampaign.view'] = EmailCampaignView;
	actionsDictionary['action.emailCampaign.sendTestEmail'] = EmailCampaignSendTestEmail;
	actionsDictionary['action.emailCampaign.startCampaign'] = EmailCampaignStartCampaign;
	actionsDictionary['action.emailCampaign.delete'] = EmailCampaignDelete;
	actionsDictionary['action.emailCampaign.report'] = EmailCampaignReport;
	actionsDictionary['action.documentFolderAddTo'] = DocumentFolderAddTo;
	actionsDictionary['action.openComposerEntityDetails'] = OpenComposerEntityDetails;
	actionsDictionary['action.sendTestEmail.deleteRecipient'] = SendTestEmailDeleteRecipient;

	return {
		resolve: (type) => {
			if (actionsDictionary[type]) {
				return actionsDictionary[type];
			} else {
				console.log('Action with name: ' + type + ' was not found');
				return null;
			}
		}
	};
}
