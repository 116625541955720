var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "external-discovery" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model.trim",
          value: _vm.organizationId,
          expression: "organizationId",
          modifiers: { trim: true }
        }
      ],
      attrs: { type: "text", placeholder: "Organization ID" },
      domProps: { value: _vm.organizationId },
      on: {
        keyup: function($event) {
          if (
            !("button" in $event) &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.selectOrganization($event)
        },
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.organizationId = $event.target.value.trim()
        },
        blur: function($event) {
          _vm.$forceUpdate()
        }
      }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "tooltip-wrapper" }, [
      _c(
        "span",
        {
          staticClass: "tooltip-icon",
          class: { opened: _vm.showTooltip },
          on: { click: _vm.toggleTooltip }
        },
        [
          _c("svg", { staticClass: "svg-icon svg-16" }, [
            _c("use", {
              attrs: { "xlink:href": "/Images/login-info.svg#login-info" }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _vm.showTooltip
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "clickoutside",
                  rawName: "v-clickoutside",
                  value: {
                    visible: _vm.showTooltip,
                    callback: _vm.closeTooltip
                  },
                  expression: "{ visible: showTooltip, callback: closeTooltip}"
                }
              ],
              staticClass: "tooltip"
            },
            [
              _c("p", [
                _vm._v(
                  "\n\t\t\t\tAsk your admin or broker for Org ID. You can also extract your ORG ID from any MarketSpace link.\n\t\t\t\tIt is in the second folder. E.g. xREDemo is an ORG ID for https://marketspace.ascendixonline.com/xREDemo/MarketSpace/listing-search-results#/\n\t\t\t"
                )
              ])
            ]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.notificationText.length > 0
      ? _c("div", { staticClass: "notifications-area" }, [
          _c("span", [_vm._v(_vm._s(_vm.notificationText))])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "submit button",
        attrs: { type: "submit" },
        on: { click: _vm.selectOrganization }
      },
      [_vm._v("Search")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }