import MsConversationDetails from './ms-conversation-details';
import Control from '../Entity/control';
import Counter from '../Control/UnreadMessagesCounter/counter';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	data() {
		return {
			evalContext: this.evaluationContext || createEvaluationContext({}),
			entityLogicalName: null,
			tabsData: [],
			currentTab: null,
			isLoading: false,
			selectedEntity: null,
			tabsContent: {}
		};
	},
	components: {
		MsConversationDetails,
		Control,
		Counter
	},
	computed: {
		tabContent: {
			get() {
				if (!this.currentTab) {
					return [];
				}

				return this.tabsContent[this.currentTab.title] || [];
			}
		}
	},
	watch: {
		$route() {
			this.$emit('closePanel');
		}
	},
	methods: {
		async closeDetails() {
			this.selectedEntity = null;
			await this.loadTabDataBase(this.currentTab);
		},
		openDetails(tabEntity) {
			this.selectedEntity = tabEntity;
		},

		getContext(item) {
			return createEvaluationContext(item);
		},
		async loadTabDataBase(tab, chunkSize = 999) {
			this.currentTab = tab;

			if (!this.tabContent || !this.tabContent.length) {
				this.isLoading = true;
			}

			const onPageLoad = (data = []) => {
				this.$set(this.tabsContent, tab.title, data);
				this.isLoading = false;
			};

			await this.loadTabData(tab, onPageLoad, chunkSize);
		},
		splitByChunk(array, chunkSize) {
			const chunks = [];
			let i; let j; const chunk = chunkSize;
			for (i = 0, j = array.length; i < j; i += chunk) {
				chunks.push(array.slice(i, i + chunk));
			}

			return chunks;
		}
	},
	async created() {
		this.isInit = true;
		this.entityLogicalName = this.evalContext.eval(this.entity);
		// TODO: rework to edge fetch
		this.dataProvider = sc.classes.get('edge.dataProvider');
		if (this.tabs) this.tabsData = this.tabs.map(tab => this.prepareTabData(tab));
		await this.loadDataOnCreate();

		this.$root.$on('closeDetailsChat', this.closeDetails);
	},
	async loadDataOnCreate() {
		throw new Error('implement logic in a derived component');
	},
	async activated() {
		if (this.tabsData.length && !this.isInit) {
			await this.loadTabDataBase(this.tabsData[0]);
		}

		this.isInit = false;
	}
};
