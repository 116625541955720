import { isUndefined } from '@acx-xms/data-functions/dist';
import { evaluate } from '@/Data/EvaluationContext/evaluationContext';

export default {
	props: {
		options: Object,
		evaluationContext: Object,
		results: Array,
		stateNamespace: String,
		paginationParams: Object,
		isActive: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			totalCount: void 0,
			drawMode: false,
			areaSelectionEnabled: false,
			selected: [],
			namespace: '',
			columns: 1,
			combinedNamespace: '',
			resultsInternal: this.results || [],
			targetEntity: null,
			mapFullScreen: false,
			isMapReady: false,
			markerIcons: {},
			getColorByName: {}
		};
	},

	computed: {
		selection() {
			const selection = this.$store.getters[this.stateNamespace + '/getSelection'];
			return selection[this.targetEntity] || [];
		}
	},

	created() {
		this.targetEntity = evaluate(this.options.targetEntity);
		this.namespace = evaluate(this.options.namespace);
		this.internalNamespace = evaluate(this.options.internalNamespace);
		this.modalNamespace = evaluate(this.options.modalNamespace);
		this.combinedNamespace = this.namespace + '.' + this.internalNamespace;
		this.fork = sc.events.fork();
		this.withCheckAll = evaluate(this.options.withCheckAll);
		this.withSelectionList = evaluate(this.options.withSelectionList);

		this.scrollToTopEvent = this.combinedNamespace + '.scrollToTop';
		this.invalidPolygonTitle = 'Geo Polygon';
		this.invalidPolygonMessage = 'Selected area is not valid.';

		const ldp = sc.classes.get('localization.dataProvider');
		$.when(ldp.getLabelForCurrentLanguage('mapSearchResults.invalidPolygonTitle'), ldp.getLabelForCurrentLanguage('mapSearchResults.invalidPolygonMessage'))
			.then((title, message) => {
				this.invalidPolygonTitle = title || this.invalidPolygonTitle;
				this.invalidPolygonMessage = message || this.invalidPolygonMessage;
			});

		this.$on('.pageChanged', (activePages) => {
			// todo use state managment
			this.$parent.$emit('.pageChanged', activePages);
		});

		const selectionChangedCallback = (oldValue) => {
			this.selectAll = !!this.resultsInternal.length && this.selected.length === this.resultsInternal.length;

			const diff1 = _.difference(oldValue, this.selected);
			const diff2 = _.difference(this.selected, oldValue);
			const diff = diff1.length ? diff1 : diff2;

			this.fork.emit(this.namespace + '.' + this.internalNamespace + '.selectionChanged', { selection: this.selected });

			if (this.modalNamespace) {
				this.fork.emit('modal.' + this.modalNamespace + '.selectionChanged', {
					selection: this.selected,
					selectionDiff: diff,
					combinedNamespace: this.combinedNamespace,
					isParent: true,
					parentId: this.combinedNamespace.split('.').pop()
				});
			}
		};

		const fn = (data) => {
			const cloneSelection = { ...this.selected };
			this.resultsInternal = data.results;
			this.selected = [];
			if (data.results) {
				if (!this.paginationParams || this.paginationParams.activePages.length === 1) {
					this.$emit(this.scrollToTopEvent);
				}
				selectionChangedCallback(cloneSelection);
				data.results.forEach(rowItem => {
					this.$emit('row-selected.' + rowItem.Id, false);
				});
			}
		};

		this.fork.on(this.combinedNamespace + '.entity.searched', fn);
		this.fork.emit(this.combinedNamespace + '.getSearchResults', fn);

		// Handle when area selection filter is enabled or disabled
		this.fork.on(['mapSearchResults.areaSelectionFilter-enabled', 'mapSearchResults.areaSelectionFilter-disabled'], (data) => {
			this.areaSelectionEnabled = data.isSelected;
		});

		this.$on('row-selected', (data) => {
			const oldValue = { ...this.selected };

			if (!data.isSelected) {
				const index = this.selected.findIndex(n => {
					return n.Id === data.item.Id;
				});
				index !== -1 && this.selected.splice(index, 1);
			} else {
				const isSelected = this.selected.find(n => {
					return n.Id === data.item.Id;
				});

				if (!isSelected) {
					this.selected.push(data.item);
				}
			}

			selectionChangedCallback(oldValue);
		});

		this.fork.on('modal.' + this.modalNamespace + '.selectParent', (data) => {
			if (data && data.diffSelection.length) {
				this.resultsInternal.forEach(item => {
					if (item.Id === data.parentId) {
						if (data.selection) {
							if (!this.selected.find(n => { return n.Id === data.parentId; })) {
								this.selected.push(item);
								this.$emit('row-selected.' + data.parentId, true, true);
							}
						} else {
							_.remove(this.selected, function (n) {
								return n.Id === item.Id;
							});

							if (!this.selected.length) {
								this.$emit('row-selected.' + data.parentId, false, true);
							}
						}
					}
				});
			}
		});

		this.fork.on('modal.' + this.modalNamespace + '.selectChilds', (data) => {
			const selection = data.diffSelection;
			const isSelected = selection[0].selected && !!data.selection && data.selection.find(sel => { return sel.Id === selection[0].Id; });
			const parentId = this.combinedNamespace.split('.').pop();

			const selCount = this.resultsInternal && this.resultsInternal.filter(item => {
				return item.selected;
			});

			const parentEqualToSelection = selection[0].Id === parentId;
			if ((data.selectAllChilds && parentEqualToSelection) || (parentEqualToSelection && ((selCount.length > 1 || selCount.length === 0) || (!isSelected && selCount.length === 1)))) {
				this.resultsInternal.forEach(item => {
					this.$emit('row-selected.' + item.Id, isSelected);
				});
			}
		});

		this.$on('select-childs', (data) => {
			data.selection = this.selected;
			if (this.modalNamespace) {
				if (data) data.selectAllChilds = true;
				this.fork.emit('modal.' + this.modalNamespace + '.selectChilds', data);
			}
		});

		this.$root.$on(this.stateNamespace + '.map-ready', () => {
			this.isMapReady = true;
		});
	},

	methods: {
		fullScreenCallback() {
			this.mapFullScreen = !this.mapFullScreen;
			return this.mapFullScreen;
		},
		// todo - move to methods and add debounce
		onScroll() {
			this.$root.$emit(this.combinedNamespace + '.scrolled');
		},
		// Toggle draw mode
		draw(enable) {
			if (isUndefined(enable)) {
				this.drawMode = !this.drawMode;
			} else {
				this.drawMode = enable;
			}
		},

		// Get coords for defined entity using raw data
		getEntityCoords(rawEntity) {
			return sc.utils.getEntityCoords(rawEntity);
		},

		// Apply polygon coords to the filter		Polygon points coordinates
		setAreaSelection(coords, clear) {
			if (coords.filterType === 'GeoPolygon' && coords.value.length <= 2) {
				this.fork.emit('dialog.info', {
					title: this.invalidPolygonTitle,
					message: this.invalidPolygonMessage
				});

				clear && clear();
			} else {
				this.fork.emit(this.stateNamespace + '.mapSearchResults.areaSelectionFilter-setPolygon', coords);
			}
		},

		clearSelection() {
			this.fork.emit(this.stateNamespace + '.mapSearchResults.areaSelectionFilter-reset');
		},

		// Subscribe on area selection changed* param {function} handler        Event handler
		onAreaSelectionChanged(handler) {
			this.fork.on(this.stateNamespace + '.mapSearchResults.areaSelectionFilter-valueChanged', (coords) => {
				handler(coords);
			});
		},

		// Get rea selection filter value
		getAreaSelection(callback) {
			this.fork.emit(this.stateNamespace + '.mapSearchResults.areaSelectionFilter-getPolygon', callback);
		}
		// loadMore: function () {
		//	this.fork.emit(this.namespace + '.loadMore');
		// },

		// showModalEntity: function (rawEntity) {
		//	this.fork.emit('dialog.open', {
		//		title: this.localize('common.dictionary.loading'),
		//		params: sc.classes.get('entityReference', rawEntity),
		//		component: 'entity.detailsDialog'
		//	});
		// }
	},

	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
		}
		this.$root.$off(this.stateNamespace + '.map-ready');
	}
};
