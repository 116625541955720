import { GetUserInfo } from '@/Data/Auth/authentication-service';
import { userFake } from '@/Data/Auth/fakeExpressionsController';

let userInfo;
export default {
	parameters(options) {
		// todo: think of proper error handling
		console.error('user must be awaited');
	},
	async parametersAsync(options) {
		if (!userInfo) {
			userInfo = GetUserInfo();
		}
		const user = await userInfo;
		if (options.fieldName) {
			return [sc.utils.findProperty(user, options.fieldName)];
		} else {
			return [{
				id: user.Id,
				logicalname: user.type,
				name: user.fullname
			}];
		}
	},
	parametersAsyncFake(options) {
		if (options.fieldName) {
			return [sc.utils.findProperty(userFake, options.fieldName)];
		} else {
			return [{
				id: userFake.Id,
				logicalname: userFake.type,
				name: userFake.fullname
			}];
		}
	},
	evaluate(data) {
		return data;
	}
};
