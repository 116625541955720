var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("virtual-check-box", {
        attrs: {
          record: _vm.evaluationContext.Source,
          stateNamespace: _vm.namespace,
          isParent: _vm.options.isParent,
          syncSelection: _vm.options.syncSelection,
          evaluationContext: _vm.evaluationContext
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }