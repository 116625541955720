import ValidatorBase from './base';
export default class extends ValidatorBase {
	async validate(entityData, context) {
		const number = context.eval(this.options.value);
		const errorKey = this.options.customErrorKey || 'entity.control.maxValueValidator.msg';
		return {
			field: await this.customFieldName() || this.field,
			result: !entityData[this.field] || number === null || entityData[this.field] <= number,
			message: this.message ? await context.evalAsync(this.message) : await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage(errorKey, [number])
		};
	}
}
