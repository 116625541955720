<template>
	<div
		v-show="visible"
		:class="['control-tableView', cssClass, { 'allowSelection': options.allowSelection, 'empty': results && !results.length }]"
		ref="tableView"
	>
		<h5 v-if="title" class="table-title">{{ title }}</h5>
		<div
			v-if="header.length>0"
			:class="['tableview-header', {'with-check': options.withCheckAll}]"
		>
			<ControlSelectAll
				v-if="results.length"
				:stateNamespace="stateNamespace"
				:results="results"
				:withCheckAll="options.withCheckAll"
				:isParent="options.isParent"
				:syncSelection="options.syncSelection"
				:targetEntity="targetEntity"
			/>
			<div
				v-for="(headerItem, index) in header"
				:style="{'width': headerItem.width}"
				:class="['cell', index === 0 && 'first-cell']"
				:key="index"
			>
				<span :class="headerItem.cssClass">{{ headerItem.title }}</span>
			</div>
		</div>

		<div :class="['tableview-content', { 'only-content': !options.withSelectionList }]">
			<TagList
				v-if="options.withSelectionList && stateNamespace && targetEntity && results && results.length"
				:stateNamespace="stateNamespace"
				:entityNamespace="targetEntity && targetEntity[0]"
				:placeholder="tagListPLaceholder"
			/>
			<div
				class="content-scroll-wrapper"
				v-perfectscroll="{ enable: scrollEnabled, onScroll, scrollToTopEvent }"
			>
				<ControlTableViewRow
					v-for="(rowItem, index) in results"
					:rowItem="rowItem"
					:content="content"
					:key="recordKey(rowItem)"
					:options="rowOptions"
					:stateNamespace="stateNamespace"
					:evaluationContext="evaluationContext"
					:class="{'allowSelection': allowSelection}"
					:selectAll="selectAll"
					:is-odd="index % 2 === 0"
					@click.native="selectRow(rowItem)"
				/>
				<div v-if="results && !results.length" class="row no-results-message p13">
					<div class="table-cell" :style="{ width: '100%' }">
						<div v-localization="{ key: 'common.dictionary.noMatchesFound' }" />
						<div v-if="noResultsMessage">{{ noResultsMessage }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import ComponentMixins from '../../component.mixins';
import { evaluate, evaluateAsync } from '@/Data/EvaluationContext/evaluationContext';
import { mapAsync } from '@acx-xms/data-functions/dist';

const ControlTableViewRow = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'./control-table-view-row');
const ControlSelectAll = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'./../Checkboxes/control-select-all');
const TagList = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'@/Components/Common/TagsList/tags-list');

export default {
	name: 'control-table-view',
	props: {
		options: Object,
		initResults: Array,
		paginationParams: Object,
		stateNamespace: String,
		syncSelection: Boolean,
		evaluationContext: Object,
		onSelect: Function,
		useParentContext: Boolean
	},
	computed: {
		results() {
			if (this.initResults !== void 0 && this.initResults !== null) {
				return this.initResults;
			}
			if (this.dataSource) {
				return this.evaluationContext.eval(this.dataSource);
			}
			return [];
		}
	},
	components: {
		ControlTableViewRow,
		ControlSelectAll,
		TagList
	},
	data() {
		return {
			component: this.options.contentComponent,
			header: [],
			visible: true,
			selectAll: false,
			content: this.options.content,
			scrollEnabled: this.options.scrollEnabled,
			targetEntity: evaluate(this.options.targetEntity),
			modalNamespace: this.options.modalNamespace,
			internalNamespace: this.options.internalNamespace,
			namespace: this.options.namespace,
			dataSource: this.options.dataSource,
			noResultsMessage: null,
			allowSelection: this.options.allowSelection,
			cssClass: this.options.cssClass ? evaluate(this.options.cssClass) : null,
			title: this.options.title ? evaluate(this.options.title) : null
		};
	},
	methods: {
		recordKey(item) {
			return item.Source ? ComponentMixins.recordKey(item) : item.templateId;
		},
		onScroll: function () {
			this.$root.$emit(this.combinedNamespace + '.scrolled');
		},
		selectRow(item) {
			this.onSelect && this.onSelect(item);
		}
	},
	async created() {
		this.selected = [];
		this.fork = sc.events.fork();
		this.combinedNamespace = this.namespace + '.' + this.internalNamespace;
		this.scrollToTopEvent = this.combinedNamespace + '.scrollToTop';
		this.tagListPLaceholder = evaluate(this.options.tagListPLaceholder);
		this.rowOptions = {
			namespace: this.namespace,
			internalNamespace: this.internalNamespace,
			modalNamespace: this.modalNamespace,
			isParent: this.options.isParent,
			syncSelection: this.options.syncSelection,
			needRefresh: false,
			allowSelection: this.options.allowSelection,
			singleSelection: this.options.singleSelection,
			allowSingleSelection: this.options.allowSingleSelection,
			useParentContext: this.options.useParentContext
		};
		this.fork.on(this.combinedNamespace + '.entity.searched', () => {
			if (!this.paginationParams || this.paginationParams.activePages.length === 1) {
				this.$emit(this.scrollToTopEvent);
			}
		});

		this.$parent.$on('.pageChanged', () => {
			this.visible && this.results.length && this.$refs.tableView.scrollIntoView();
		});
		if (this.options.header) {
			this.header = await mapAsync(this.options.header, async (headerItem) => {
				return {
					title: await evaluateAsync(headerItem.title),
					width: headerItem.width,
					cssClass: evaluate(headerItem.cssClass)
				};
			});
		}

		this.noResultsMessage = this.options.noResultsMessage ? await evaluateAsync(this.options.noResultsMessage) : null;
	},
	// this watcher need to detect results rendering and correct emit after it
	watch: {
		results: function (arr) {
			if (arr.length) {
				this.$nextTick(() => {
					this.$root.$emit('parentScrollUpdate');
				});
			}
		}
	},
	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
		}
	}
};
</script>
<style src="./control-table-view.less" scoped></style>
