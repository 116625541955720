﻿import ComponentSearchResultsMixins from './component-search-results.mixins';
import ComponentsMixins from './mixins/components.mixins';
export default {
	mixins: [ComponentSearchResultsMixins, ComponentsMixins],
	props: {
		evaluationContext: Object,
		filterPanelVisible: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		showFilterPanel() {
			return this.$store.getters[this.stateNamespace + '/showFilterPanel'];
		}
	},
	data() {
		return {
			isCollapsed: false,
			sortByFields: null,
			title: '',
			actionMenuComponent: {},
			content: {},
			results: [],
			selectedViewName: '',
			isLoading: false,
			paginationParams: {
				pageSize: 0,
				total: 0,
				activePages: [1],
				from: 0
			},
			filterPanelEvaluationContext: this.evaluationContext,
			filters: null,
			withToggleAll: false,
			showSelectedCount: true,
			combinedNamespace: null,
			stateNamespace: '',
			searchBoxPlaceholder: sc.classes.get('localization.dataProvider').localize('common.dictionary.search'),
			visible: false,
			trackUserActivity: false,
			options: null,
			currentcomponentset: null
		};
	},
	created() {
		if (!this.$route.params.componentSet) {
			// TODO: find a better way. Possibly not render component if componentSet is undefined
			return;
		}

		this.currentcomponentset = sc.classes.get('marketspaceComponents.dataProvider').getComponentSetByIdSync(this.$route.params.componentSet);
		this.init();
		this.subscribeOnSearchFieldEvents();
	},

	methods: {
		init() {
			if (this.currentcomponentset) {
				this.options = this.currentcomponentset.component[0];
			}

			if (this.options && this.options.filterPanel && this.options.filterPanel.alwaysVisible && (this.filterPanelVisible === void 0 || this.filterPanelVisible)) {
				this.$nextTick(() => {
					this.toggleFilterPanel(true);
				});
			}

			this.initVariables();

			this.initFilters();

			this.initSearchableFields();

			this.initialization();

			this.initItemsPerPageAndPagionation();

			if (this.$route.query && this.$route.query.page) {
				let page = parseInt(this.$route.query.page);
				if (page > 214748363) {
					page = 214748363; // 2147483647 //max value of int type
				}
				this.paginationParams.from = page === 1 ? 0 : (page - 1) * this.paginationParams.pageSize;
				this.paginationParams.activePages = [page];
				this.$store.commit('setPaginationState', {
					route: this.$route.params.componentSet,
					value: page
				});
			}

			this._onPageChangedHandler = (activePages) => {
				this.paginationParams.from = (activePages[0] - 1) * this.paginationParams.pageSize;
				this.paginationParams.activePages = activePages;

				this._search(true);

				this.trackUserActivity && this.trackActivity();

				if (this.options.withoutPaginationRouting) return;

				const query = activePages.length === 1 && activePages[0] === 1
					? {}
					: { page: activePages[activePages.length - 1] };

				this.$store.commit('setPaginationState', {
					route: this.$route.params.componentSet,
					value: query.page || null
				});

				this.$router.push({
					name: this.$route.name,
					params: this.$route.params,
					query
				});
			};
		},
		processEntity(componentSet, node) {
			if (node.refreshEvent) {
				node.refreshEvent();
			}
			this.currentcomponentset = null;
			this.content = {};
			this.$emit('.removeSearchSubscriptions');
			this.$nextTick(() => {
				this.currentcomponentset = sc.classes.get('marketspaceComponents.dataProvider').getComponentSetByIdSync(componentSet);
				this.$root.$off(this.stateNamespace + '.targetEntityChange', this.processEntity);
				this.init();
				this.$emit('.changeSearchSubscriptions');
				if (node.data.callback) {
					node.data.callback(this.stateNamespace);
				}
				this.$nextTick(() => {
					this.doSearch({ initSearch: true });
				});
			});
		},
		toggleFilterPanel(force) {
			this.$store.commit(this.stateNamespace + '/toggleFiltersPanel', force);
			this.fork.emit(this.combinedNamespace + '.filterPanelToggle', this.showFilterPanel);
		}
	}
};
