var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        {
          directives: [
            {
              name: "targeted-popup",
              rawName: "v-targeted-popup",
              value: {
                target: _vm.target,
                wrapperClass: _vm.wrapperClass,
                offsetLeft: _vm.offsetLeft,
                offsetTop: _vm.offsetTop,
                isMobile: _vm.options.params.isMobile,
                isLookup: _vm.isLookup
              },
              expression:
                "{target, wrapperClass: wrapperClass, offsetLeft: offsetLeft, offsetTop: offsetTop, isMobile: options.params.isMobile, isLookup: isLookup}"
            }
          ],
          key: _vm.namespace,
          ref: "popup",
          staticClass: "popup-template-control-wrapper",
          style: { width: _vm.popupWidth || "auto" }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "clickoutside",
                  rawName: "v-clickoutside",
                  value: {
                    visible: _vm.visible,
                    callback: _vm.hidePopup,
                    event: _vm.event
                  },
                  expression: "{ visible: visible, callback: hidePopup, event}"
                }
              ],
              staticClass: "popup-template-control"
            },
            [
              _c(_vm.options.name, {
                tag: "component",
                staticClass: "popup-component",
                attrs: { options: _vm.options.params }
              })
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }