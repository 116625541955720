import SearchField from '../search-field/search-field';
import ViewSwitcher from '../view-switcher';
import ComponentSetSearchResultsThumbnailviewColumned from '../Views/thumbnailview-columned/search-results-thumbnailview-columned';
import ComponentSetSearchResultsThumbnailview from '../Views/thumbnail-view/search-results-thumbnail-view';
import ComponentSetSearchResultsSelectedRecords from '../Views/component-set-search-results-selectedrecords';
import ComponentSetSearchResultsTableView from '../Views/table-view/component-set-search-results-table-view';
import ComponentSetSearchResultsDocumentTreeView from '../Views/document-folder-tree-view/document-folder-tree-view';
import ComponentSetMobileSearchResultsTableView from '../Views/table-view/component-set-mobile-search-results-table-view';
import ComponentSetSearchResultsMapView from '../Views/map-view/search-results-map-view';
import ComponentSetMobileSearchResultsMapOnlyView from '../Views/map-view/mobile-search-results-map-only-view';
import sortpanel from '../../../SortPanel/sortpanel';
import FiltersPanel from '../../../FiltersPanel/filters-panel/filters-panel';
import ResultsToggle from '../results-toggle/results-toggle';
import ReactiveActionMenu from '../../../Menu/reactive-action-menu';

export default {
    components: {
		ComponentSetSearchResultsTableView,
		ComponentSetSearchResultsThumbnailview,
		ComponentSetSearchResultsDocumentTreeView,
		ReactiveActionMenu,
		sortpanel,
		ComponentSetSearchResultsThumbnailviewColumned,
		ComponentSetSearchResultsSelectedRecords,
		ComponentSetSearchResultsMapView,
		ComponentSetMobileSearchResultsMapOnlyView,
		FiltersPanel,
		ResultsToggle,
		SearchField,
		ViewSwitcher,
		ComponentSetMobileSearchResultsTableView
	},
}