import Vue from 'vue';
import { isString } from '@acx-xms/data-functions/dist';

export const TYPES = {
	'menu.routeMenuItemSVG': 'route-menu-item',
	'menu.menuItemSVG': 'menu-item',
	'menu.menuItem': 'menu-item',
	'dialog.header': 'dialog-header',
	'dialog.button.close': 'dialog-button-close',
	'dialog.button.submit': 'dialog-button-submit',
	'dialog.button.copyLink': 'dialog-button-copy-link',
	'dialog.button.emailLink': 'dialog-button-email-link',
	'dialog.input': 'dialog-input',
	'dialog.input.upload': 'dialog-input-upload',
	'dialog.input.lookup': 'dialog-input-lookup',
	'dialog.radio': 'dialog-radio'
};

const EXCESS = [
	/(component(-)?(set)?(\.)?(-)?)/g,
	/(entity-(new-)?(entity-)?)/g,
	/(facet)/g,
	/(control(-)?)/g,
	/(route(-)?)/g,
	/(clickable(-)?)/g,
	/(enabled(-)?)/g,
	/((-)?wrapper(-)?)/g
];

function equalizeString(string) {
	if (!string || !string.length || !isString(string)) return '';
	string = string.replaceAll(/[A-Z]/g, '-$&');
	string = string.replaceAll(/[,._]/g, '-');
	string = string.replaceAll(/\s/g, '-');
	return string.toLowerCase();
}

function generateAttributeFromOptions(options) {
	let type = TYPES[options.$type] || equalizeString(options.$type);
	EXCESS.forEach(item => { type = type.replaceAll(item, ''); });
	return type;
}

function addElementContent(options) {
	let content = '';
	if (!options.title && !options.text) return content;

	if (options.title) {
		const string = options.title ? options.title.value || options.title : '';
		content = `-${equalizeString(string)}`;
	}
	if (!content.length && options.text) {
		const string = options.text.value || options.text.name || (/(expression)/g.test(options.text.$type) ? '' : options.text.$type);
		content = string ? `-${equalizeString(string)}` : '';
	}
	if (!content.length && options.icon) {
		const string = options.icon.value;
		content = string ? `-${equalizeString(string)}` : '';
	}

	return content;
}

function addElementNamespace(namespace) {
	return namespace ? `-${equalizeString(namespace)}` : '';
}

function addElementType(element) {
	if (element.nodeName === 'DIV') return '';
	if (element.nodeName === 'A') return 'link';
	return `${equalizeString(element.nodeName.toLowerCase())}`;
}

function addElementID(element) {
	return `-${equalizeString(element.id.toLowerCase())}`;
}

function addClassNames(element) {
	let result = element.classList.value.toLowerCase();
	EXCESS.forEach(item => { result = result.replaceAll(item, ''); });
	return equalizeString(result);
}

function insertAttribute(element, binding) {
	const options = binding.value ? binding.value.options || binding.value : false;
	let attribute = '';

	if (options) {
		attribute += generateAttributeFromOptions(options);
		if (binding.value.stateNamespace) {
			attribute += addElementNamespace(binding.value.stateNamespace);
		} else if (options.displayName) {
			attribute += options.displayName.value ? addElementNamespace(options.displayName.value) : '';
		} else if (options.logicalname) {
			attribute += options.logicalname.value ? addElementNamespace(options.logicalname.value) : '';
		}
		attribute += addElementContent(options);
	} else {
		attribute += addElementType(element);
		attribute += addElementID(element);
		attribute += addClassNames(element);
		attribute += `-${equalizeString(element.textContent)}`;
	}

	/* Fixes extra '-' in string */
	attribute = attribute.replaceAll(/-{2,}/g, '-');
	if (attribute[0] === '-') attribute = attribute.substring(1);
	if (attribute[attribute.length - 1] === '-') attribute = attribute.substring(0, attribute.length - 1);

	element.setAttribute('data-qa-test', attribute);
}

Vue.directive('data-attr', (() => {
	return {
		inserted(element, binding) {
			insertAttribute(element, binding);
		},
		componentUpdated(element, binding) {
			insertAttribute(element, binding);
		}
	};
})());
