var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ms-panel popup-shaded notification-panel" },
    [
      _c("div", { staticClass: "panel-list" }, [
        _c(
          "div",
          { staticClass: "tabs" },
          [
            _vm._l(_vm.tabsData, function(tab, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "tab-title",
                  class: { active: tab.active },
                  on: {
                    click: function($event) {
                      _vm.loadTabData(tab)
                    }
                  }
                },
                [_c("p", { staticClass: "text" }, [_vm._v(_vm._s(tab.title))])]
              )
            }),
            _vm._v(" "),
            !_vm.isLoading && _vm.tabContent.length
              ? _c("div", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "common.dictionary.clearAll" },
                      expression: "{key:'common.dictionary.clearAll'}"
                    }
                  ],
                  staticClass: "clear-all-btn",
                  on: { click: _vm.clearAll }
                })
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "perfectscroll",
                rawName: "v-perfectscroll",
                value: { addMouseoverEvent: true, refreshScroll: false },
                expression: "{ addMouseoverEvent: true, refreshScroll: false }"
              }
            ],
            staticClass: "tab-content"
          },
          [
            !_vm.isLoading && _vm.tabContent.length
              ? _vm._l(_vm.tabContent, function(tabEntity, index) {
                  return _c(
                    "div",
                    { key: tabEntity.id, staticClass: "notification-wrapper" },
                    [
                      _c("control", {
                        staticClass: "striped",
                        class: {
                          "without-hyperlink": tabEntity.withoutHyperlink
                        },
                        attrs: {
                          name: _vm.currentTab.messageTemplate.$type,
                          contentProps: _vm.currentTab.messageTemplate,
                          evaluationContext: _vm.getContext(tabEntity)
                        },
                        nativeOn: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.openEntity(tabEntity)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "delete-wrapper",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.deleteEntity(tabEntity, index)
                            }
                          }
                        },
                        [
                          _c("svg", { staticClass: "svg-icon" }, [
                            _c("use", {
                              attrs: {
                                "xlink:href": "#layout-icons-dialog-close"
                              }
                            })
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isNotificationOnDelete(tabEntity.id),
                            expression: "isNotificationOnDelete(tabEntity.id)"
                          }
                        ],
                        staticClass: "loading-overlay"
                      })
                    ],
                    1
                  )
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isLoading && !_vm.tabContent.length,
                    expression: "!isLoading && !tabContent.length"
                  }
                ],
                staticClass: "no-results-message"
              },
              [
                _c("div", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: {
                        key: _vm.isPushNotificationsEnabled
                          ? "common.dictionary.noMatchesFound"
                          : "notificationPanel.disabledNotificationMessage",
                        args: [" "]
                      },
                      expression:
                        "{ key: isPushNotificationsEnabled ? 'common.dictionary.noMatchesFound': 'notificationPanel.disabledNotificationMessage', args: [' ']}"
                    }
                  ]
                })
              ]
            ),
            _vm._v(" "),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isLoading,
                  expression: "isLoading"
                }
              ],
              staticClass: "loading-overlay"
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loadMore,
                    expression: "loadMore"
                  }
                ],
                staticClass: "items-footer"
              },
              [_c("div", { staticClass: "loading-overlay inline" })]
            )
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }