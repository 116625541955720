import { mapAsync, isUndefined } from '@acx-xms/data-functions/dist';

export default {
	parameters(options) {
		return [
			this.eval(options.argument),
			options.case.map(item => {
				return {
					key: this.eval(item.key),
					value: this.eval(item.value)
				};
			}),
			this.eval(options.default)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument),
			await mapAsync(options.case, async (item) => {
				return {
					key: await this.evalAsync(item.key),
					value: await this.evalAsync(item.value)
				};
			}),
			await this.evalAsync(options.default)
		];
	},
	evaluate(val, cases, def) {
		def = isUndefined(def) ? null : def;

		const result = cases.find(item => {
			return item.key === val;
		});
		if (isUndefined(result)) {
			return def;
		} else {
			return result.value;
		}
	}
};
