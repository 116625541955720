import { isActive } from './action.methods';
import {
	showToastOrInfoDialog, getOrgSettings, Update
} from '@acx-xms/data-functions/dist';
import { send } from './action.methods.sitemapHelper';

export default {
	async enabled(options, evaluationContext, selection) {
		return selection && selection.length && await isActive(evaluationContext, selection);
	},
	async execute(options, context, selection, isPublish) {
		this.base(options, context, selection);
		const preparedData = this.prepareData();

		// TODO: possibly move to server side
		const orgSettings = await getOrgSettings(true);

		if (preparedData !== null) {
			let toastTextKey;
			let ids = [];

			if (isPublish) {
				ids = preparedData.published.map(item => item.id);
				toastTextKey = 'common.dictionary.published';
			} else {
				ids = preparedData.unpublished.map(item => item.id);
				toastTextKey = 'common.dictionary.unpublished';
			}

			await this.updateListings(ids, { publishedtolistingportal: isPublish });
			setTimeout(async () => {
				const refreshEvent = context.eval(options.refreshEvent);
				refreshEvent && sc.events.emit(refreshEvent);
				const toastMessageKey = context.eval(options.toastMessageKey); const informationDialogTextKey = context.eval(options.informationDialogTextKey);
				if (toastMessageKey || informationDialogTextKey) {
					await showToastOrInfoDialog({
						toastMessageKey,
						informationDialogTextKey
					});
				} else {
					sc.events.emit('toast.open', await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage(toastTextKey));
				}
			}, 500);

			if (orgSettings.enableseo) {
				let items;
				let endpoint;
				if (isPublish) {
					items = preparedData.published;
					endpoint = 'addurl';
				} else {
					items = preparedData.unpublished;
					endpoint = 'removeurl';
				}

				items.forEach(async item => {
					await send(endpoint, item.id, item.name, 'listing');
				});
			}
		}
	},

	base(options, evaluationContext, selection) {
		this.options = options;
		this.evaluationContext = evaluationContext;
		this.selection = this.getSelectedRecords(evaluationContext, selection);
	},

	getSelectedRecords(evaluationContext, selection) {
		return selection && selection.length && selection[0].Source
			? selection
			: [evaluationContext.eval({ $type: 'expression.source' })];
	},

	async updateListings(ids = [], data) {
		const updatePromises = [];

		ids.forEach(id => {
			updatePromises.push(Update('listing', id, data, null, { syncProcessMode: 'All' }));
		});

		await Promise.all(updatePromises).catch(e => {
			sc.utils.errorMessage.byMessage(e);
		});
	},

	prepareData() {
		if (this.selection && this.selection.length) {
			const data = {
				published: [],
				unpublished: []
			};

			this.selection.forEach(selection => {
				data[selection.Source.publishedtolistingportal ? 'unpublished' : 'published'].push({
					id: selection.Id,
					name: selection.Name
				});
			});

			return data;
		} else {
			return null;
		}
	}
};
