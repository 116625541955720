export default {
	parameters(options) {
		return [
			this.datasource
		];
	},
	evaluate(source) {
		return source;
	}
};
