var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isDetails
    ? _c("div", { staticClass: "email-campaign-footer-root" }, [
        _c(
          "section",
          { staticClass: "footer" },
          [
            _c("WizardButton", {
              staticClass: "footer-btn",
              attrs: { text: _vm.isForceExit ? "exit" : "cancel" },
              on: { click: _vm.cancelCampaign }
            }),
            _vm._v(" "),
            _c("WizardButton", {
              staticClass: "footer-btn",
              attrs: {
                text: "Save as Draft",
                disabled:
                  _vm.templateProcess || (!_vm.isEdited && _vm.withEditMode)
              },
              on: { click: _vm.saveAsDraft }
            }),
            _vm._v(" "),
            _vm.visible
              ? _c(
                  "WizardButton",
                  {
                    staticClass: "footer-btn",
                    attrs: {
                      disabled: !_vm.isWizardValid,
                      text: "Send Test",
                      icon: "action"
                    },
                    on: { click: _vm.openSendTestDialog }
                  },
                  [
                    _c("svg", { staticClass: "wizard-email-icon" }, [
                      _c("use", {
                        attrs: { "xlink:href": "#action-icons-wizard-email" }
                      })
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.activeStepNumber !== 1
              ? _c(
                  "WizardButton",
                  {
                    staticClass: "footer-btn",
                    attrs: {
                      disabled: !_vm.isDisabled.prev,
                      text: "back",
                      icon: "back"
                    },
                    on: {
                      click: function($event) {
                        _vm.changeStep()
                      }
                    }
                  },
                  [
                    _c("svg", { staticClass: "wizard-arrow" }, [
                      _c("use", {
                        attrs: { "xlink:href": "#action-icons-ms-entity-arrow" }
                      })
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.visible
              ? _c(
                  "WizardButton",
                  {
                    staticClass: "footer-btn",
                    attrs: {
                      disabled: _vm.isNextDisabled,
                      text: "next",
                      color: "blue",
                      icon: "next"
                    },
                    on: {
                      click: function($event) {
                        _vm.changeStep("next")
                      }
                    }
                  },
                  [
                    _c("svg", { staticClass: "wizard-arrow" }, [
                      _c("use", {
                        attrs: { "xlink:href": "#action-icons-ms-entity-arrow" }
                      })
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.visible
              ? _c(
                  "WizardButton",
                  {
                    staticClass: "footer-btn",
                    attrs: {
                      disabled: !_vm.isWizardValid,
                      color: "blue",
                      text: "Send"
                    },
                    on: { click: _vm.checkDataStatus }
                  },
                  [
                    _c("svg", { staticClass: "wizard-flag" }, [
                      _c("use", {
                        attrs: { "xlink:href": "#action-icons-ms-entity-flag" }
                      })
                    ])
                  ]
                )
              : _vm._e()
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }