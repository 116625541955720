import arrayExprs from './expressions/array/arrayExpressionsResolver';
import booleanExprs from './expressions/boolean/booleanExpressionsResolver';
import dateExprs from './expressions/date/dateExpressionsResolver';
import entityRefExprs from './expressions/entityRef/entityRefExpressionsResolver';
import logicalExprs from './expressions/logical/logicalExpressionsResolver';
import miscExprs from './expressions/misc/miscExpressionsResolver';
import numericExprs from './expressions/numeric/numericExpressionsResolver';
import objectExprs from './expressions/object/objectExpressionsResolver';
import stringExprs from './expressions/string/stringExpressionsResolver';

const expressionsDictionary = {
	...arrayExprs.resolve(),
	...booleanExprs.resolve(),
	...dateExprs.resolve(),
	...entityRefExprs.resolve(),
	...logicalExprs.resolve(),
	...miscExprs.resolve(),
	...numericExprs.resolve(),
	...objectExprs.resolve(),
	...stringExprs.resolve()
};

export default {
	resolve(type) {
		if (expressionsDictionary[type]) {
			return expressionsDictionary[type];
		} else {
			console.log('Expression with name: ' + type + ' was not found');
			return null;
		}
	}
};
