import { isActive, isAllowedEntity } from './Methods/action.methods';
import { downloadUrl, showToastOrInfoDialog } from '@acx-xms/data-functions/dist';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	async enabled(options, evaluationContext, selection, fullselection) {
		return selection && selection.length > 0 && isAllowedEntity(options.allowedEntityTypes, fullselection) && await isActive(evaluationContext, selection);
	},
	async execute(options, context, selection) {
		let selectedEntity = selection[0];

		if (options.selection) {
			selectedEntity = await context.evalAsync(options.selection) ||
				await createEvaluationContext(selectedEntity).evalAsync(options.selection);
		}
		const entityRef = sc.classes.get('entityReference', selectedEntity);
		const fileUrl = sc.classes.get('edge.dataProvider').getFileUrl(entityRef, null);
		if (fileUrl) {
			downloadUrl(fileUrl);
			await showToastOrInfoDialog({
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey)
			});
		}
	}
};
