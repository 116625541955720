<template>
	<div class="searchResults-map-view">
		<div v-bind:class="{ fullscreen: mapFullScreen }" v-if="isActive" class="map-search-results" v-mapSearchResults="{
				 results: results,
				 selection: selection,
				 getAreaSelection: getAreaSelection,
				 setAreaSelection: setAreaSelection,
				 clearSelection: clearSelection,
				 getEntityCoords: getEntityCoords,
				 onAreaSelectionChanged: onAreaSelectionChanged,
				 areaSelectionEnabled: areaSelectionEnabled,
				 drawMode: drawMode,
				 draw: draw,
				 markerIcons: markerIcons,
				 namespace: combinedNamespace,
				 stateNamespace: stateNamespace,
				 fullScreenCallback: fullScreenCallback,
			     targetEntity: targetEntity,
				 enableFullscreenControl: options.enableFullscreenControl,
				 enableDrawingTool: options.enableDrawingTool,
				 getColorByName: getColorByName
			 }">
			<div class="scMap">
				<div class="sc-map-holder"></div>
			</div>
			<div v-show="!isMapReady" class="loading-overlay"></div>
		</div>
	</div>
</template>
<script>
/* eslint no-unused-vars: 0 */
/* eslint vue/no-unused-components: 0 */
import Map from './../../../../../Bindings/mapSearchResults.directive';
import Pagination from './../../../pagination/pagination';
import ComponentSetSearchResultsMapViewBase from './search-results-map-view-base.mixins';

export default {
	name: 'component-set-search-results-map-only-view',
	mixins: [ComponentSetSearchResultsMapViewBase],
	components: { Pagination }
};
</script>
<style src="./search-results-map-view.less" scoped></style>
