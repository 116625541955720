<template>
	<div>
		<div v-if="visible" class="aside-panel">
			<filter-info-panel v-if="filterInfoPanelOptions"
				:options="filterInfoPanelOptions"
				:key="filterKey"
				:evaluationContext="evaluationContext"
				:isLoading="isLoading"
				:stateNamespace="stateNamespace"
				@filterError="filterError = $event"
			/>
			<div v-data-attr="{ options, stateNamespace }" class="filter-panel">
				<div v-perfectscroll="{ enable: true }" class="filter-panel-holder">
					<div class="filter-panel-wrapper">
						<div
							v-for="(group, index) in options.filters.group"
							:key="index + filterKey"
							class="filter-panel-frame"
						>
							<component
								:is="resolveGroupType(group)"
								:key="index + filterKey"
								:stateNamespace="stateNamespace"
								:options="group"
								:evaluationContext="evaluationContext"
							/>
						</div>
					</div>
				</div>
				<div class="buttonsMenu">
					<button
						v-data-attr="{ options, stateNamespace: 'button-reset' }"
						v-localization="{ key: 'filtersPanel.resetAllFilters' }"
						:disabled="filterInfoPanelOptions != null || !filtersChanged"
						type="button"
						class="resetButton"
						@click="onMSpaceReset"
					/>
					<button
						v-if="showApplyButton()"
						v-data-attr="{ options, stateNamespace: 'button-apply' }"
						v-localization="{ key: 'filtersPanel.apply' }"
						type="button"
						class="applyButton"
						@click="onMSpaceApply"
					/>
				</div>
			</div>
		</div>
		<div
			v-show="filterInfoPanelOptions"
			class="filter-panel-overlay"
			@click="filterOverlayClick"
		/>
		<div
			v-show="visible"
			class="filter-panel-mobile-overlay"
			@click="mobileOverlayClick"
		/>
	</div>
</template>
<script>
import '@/Bindings/ui-attribute.directive';
import FiltersGroup from './../filters-group/filters-group';
import FilterInfoPanel from './../filter-info-panel/filter-info-panel';
import { generateId } from '@acx-xms/data-functions/dist';
const SavedSearchesGroup = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../filters-group/filters-saved-search-group');

export default {
	name: 'filters-panel',
	props: {
		options: Object,
		evaluationContext: Object,
		isLoading: Boolean,
		stateNamespace: String,
		entityName: String
	},
	data() {
		return {
			visible: true,
			filterGroups: [],
			filterKey: generateId(),
			filterError: false
		};
	},
	created() {
		this.fork = sc.events.fork();
		this.fork.on('routeUpdated', () => {
			this.$store.commit(this.stateNamespace + '/resetFilters');
			this.filterKey = generateId();
			this.$nextTick(() => {
				this.queryReplace();
				this.$root.$emit(this.stateNamespace + 'search');
			});
		});
	},
	mounted() {
		this.queryReplace();
	},
	components: {
		FiltersGroup,
		FilterInfoPanel,
		SavedSearchesGroup
	},
	computed: {
		filterInfoPanelOptions() {
			return this.$store.getters[this.stateNamespace + '/getInfoPanelOptions'];
		},
		filtersChanged() {
			return this.$store.getters[this.stateNamespace + '/filtersChanged'];
		}
	},
	methods: {
		queryReplace() {
			const query = {};
			if (this.$route.query.page) {
				query.page = this.$route.query.page;
			}
			if (!this.$route.query.accessToken) {
				this.$router.replace({ query });
			}
		},
		showApplyButton() {
			return !sc.utils.userAgent.userAgent.isIos && !sc.utils.userAgent.userAgent.isAndroid;
		},
		onMSpaceReset() {
			this.$store.commit(this.stateNamespace + '/resetFilters');
			this.$root.$emit(this.stateNamespace + 'search');
		},
		onMSpaceApply() {
			if (!this.filterError) this.$store.commit(this.stateNamespace + '/openInfoPanel', null);
		},
		filterOverlayClick() {
			this.$store.commit(this.stateNamespace + '/openInfoPanel', null);
		},
		mobileOverlayClick() {
			this.filterInfoPanelOptions && this.$store.commit(this.stateNamespace + '/openInfoPanel', null);
			this.$store.commit(this.stateNamespace + '/toggleFiltersPanel');
		},
		resolveGroupType(group) {
			switch (group.$type) {
			case 'entity.savedSearchGroup':
				return 'saved-searches-group';
			default:
				return 'filters-group';
			}
		}
	},
	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
		}
	}
};
</script>
<style src="./filters-panel.less" scoped></style>
