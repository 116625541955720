import MsCountersState from '@/States/modules/ms-counters-state';
import { getBaseRouteUrl } from '@acx-xms/data-functions/dist';
import ToastPopUp from './../ToastPopUp/toast-pop-up';
import DialogManager from './../Dialog/dialog-manager';
import OldDialogManager from './../Dialog/old-dialog-manager';
import AbsoluteMenuPopup from './../Menu/absolute-menu-popup';
import AbsoluteTemplatePopup from './../Popup/absolute-template-popup';
import { evaluateAsync } from '@/Data/EvaluationContext/evaluationContext';
import { lockTab } from '@/Data/DataProviders/signalRDataProvider';

export default {
	components: {
		AbsoluteMenuPopup,
		DialogManager,
		OldDialogManager,
		AbsoluteTemplatePopup,
		ToastPopUp
	},
	data() {
		return { ready: false };
	},
	async created() {
		const mainPath = this.$route.name === 'component' ? this.$route.params.componentSet : this.$route.name;
		let hasPagePermission = true;

		if (sc.cluster.features.pagePermissionsSettings) {
			try {
				const pagePermissionsSettings = await sc.classes.get('settings.dataProvider').get('pagePermissionsSettings');
				const currentPagePermissions = pagePermissionsSettings.page && pagePermissionsSettings.page.find(item => item.title === mainPath);
				hasPagePermission = currentPagePermissions ? await evaluateAsync(currentPagePermissions.enable) : true;
			} catch (e) {
				console.warn('pagePermissionsSettings not exist', e);
			}
		}

		this.$store.registerModule('counters', MsCountersState);
		await this.loadComponentSets();
		const currentComponentSet = sc.classes.get('marketspaceComponents.dataProvider').getComponentSetByIdSync(this.$route.params.componentSet);
		const isGuidInvalid = mainPath === 'details' && this.$route.params && !sc.utils.isGuidValid(this.$route.params.id);
		if (((!currentComponentSet && ['component'].indexOf(this.$route.name) >= 0) || !hasPagePermission || isGuidInvalid) && !this.$route.fullPath.includes('sharedsearch')) {
			this.$router.push({ path: `${getBaseRouteUrl(this.$route)}/${this.defaultMenuItem.path}` });
		}

		lockTab();

		/* Only if all requiremenets */
		this.ready = true;
	},
	methods: {
		async loadComponentSets() {
			await sc.classes.get('marketspaceComponents.dataProvider').getComponentSets();
		}
	}
};
