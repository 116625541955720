import { checkPrivilege, isActive } from './Methods/action.methods';

export default {
	async enabled(options, evaluationContext, selection) {
		const active = await isActive(evaluationContext, selection);
		if (!active) {
			return false;
		}
		return selection.length > 0 && await checkPrivilege('collaborationroom', 'Create');
	},

	async execute(options, context, selection, fullSelection) {
		const logicalname = context.eval(options.logicalname);
		const parameters = { entity: { logicalname } };
		if (fullSelection) {
			parameters.listings = fullSelection.listing;
			parameters.availabilities = fullSelection.availability;
		} else {
			parameters.listings = selection;
			if (options.takeRelatedAvailabilities) {
				parameters.availabilities = parameters.listings[0].Source.availabilities;
			}
		}
		const entityData = await sc.classes.get('entityConfiguration.dataProvider').fetchEntity(logicalname);
		const entityLabel = await context.evalAsync(entityData.displayName);
		const title = await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('entity.editor.titleCreate', [entityLabel]);
		const maxWidth = options.dialogWidth || (entityData.editDialogWidth ? context.eval(entityData.editDialogWidth) : '');
		const maxHeight = options.dialogHeight || (entityData.editDialogHeight ? context.eval(entityData.editDialogHeight) : '');

		const openDialog = () => {
			sc.events.emit('vue.dialog.open', {
				component: 'vue.editor',
				logicalname,
				maximizedWidth: maxWidth,
				maximizedHeight: maxHeight,
				title,
				parameters,
				stateField: options.stateField,
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey)
			});
		};

		openDialog();
	}
};
