export default {
	parameters(options) {
		return [
			this.eval(options.logicalname),
			this.eval(options.id)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.logicalname),
			await this.evalAsync(options.id)
		];
	},
	evaluate(logicalname, id) {
		return sc.classes.get('entityReference', id, logicalname);
	}
};
