var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.pages.length,
          expression: "pages.length"
        }
      ],
      staticClass: "marketspace-pagination-wrapper",
      class: { "no-pages": _vm.pages.length < 2 }
    },
    [
      _c("div", { staticClass: "pagination" }, [
        _c(
          "div",
          { staticClass: "pagination-arrow prev", on: { click: _vm.previous } },
          [
            _c(
              "div",
              {
                staticClass: "navigation-item",
                class: { disabled: _vm.isSelected(1) }
              },
              [
                _c("span", { staticClass: "pagination-item-arrow" }, [
                  _c("svg", { staticClass: "svg-icon svg-10" }, [
                    _c("use", {
                      attrs: { "xlink:href": "#layout-icons-ms-arrow-left" }
                    })
                  ])
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "ul",
          _vm._l(_vm.pages, function(page, index) {
            return _c(
              "li",
              {
                key: index,
                on: {
                  click: function($event) {
                    _vm.selectPage(page.number)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "navigation-item page-button",
                    class: {
                      disabled: page.number == _vm.elipsis,
                      selected: page.selected
                    }
                  },
                  [_vm._v(_vm._s(page.number))]
                )
              ]
            )
          })
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pagination-arrow next", on: { click: _vm.next } },
          [
            _c(
              "div",
              {
                staticClass: "navigation-item",
                class: { disabled: _vm.isSelected(_vm.lastPage) }
              },
              [
                _c("span", { staticClass: "pagination-item-arrow" }, [
                  _c("svg", { staticClass: "svg-icon svg-10" }, [
                    _c("use", {
                      attrs: { "xlink:href": "#layout-icons-ms-arrow-right" }
                    })
                  ])
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _vm.total > 0
          ? _c(
              "div",
              {
                staticClass: "load-more-wrapper",
                style: { visibility: _vm.showLoadMoreVisibility }
              },
              [
                _c(
                  "button",
                  { attrs: { type: "submit" }, on: { click: _vm.loadMore } },
                  [
                    _c("span", {
                      directives: [
                        {
                          name: "localization",
                          rawName: "v-localization",
                          value: { key: "mainLayout.loadMore" },
                          expression: "{key:'mainLayout.loadMore'}"
                        }
                      ]
                    })
                  ]
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.total > 0
        ? _c("div", { staticClass: "results-label" }, [
            _vm._v(_vm._s(_vm.label))
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }