import Vue from 'vue';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
import {
	isObject, traverseObj, Search
} from '@acx-xms/data-functions/dist';

import requiredValidator from '@/Components/Editor/Validators/required';
import fileUploadValidator from '@/Components/Editor/Validators/fileUpload';
import minValueValidator from '@/Components/Editor/Validators/minValue';
import maxValueValidator from '@/Components/Editor/Validators/maxValue';
import maxLengthValidator from '@/Components/Editor/Validators/maxLength';
import regexpValidator from '@/Components/Editor/Validators/regexp';

import maxLatValueValidator from '@/Components/Editor/Validators/LatLonValidators/maxLatValue';
import minLatValueValidator from '@/Components/Editor/Validators/LatLonValidators/minLatValue';
import maxLonValueValidator from '@/Components/Editor/Validators/LatLonValidators/maxLonValue';
import minLonValueValidator from '@/Components/Editor/Validators/LatLonValidators/minLonValue';

function getValidatorNames(options) {
	return Object.keys(options).filter(f => f.toLowerCase().includes('validator')) || [];
}
async function setDefaultValue({ options, stateContext }) {
	const returnObj = {};
	const fieldOptions = options;
	if (fieldOptions.defaultValue) {
		const defaultValue = stateContext.eval(fieldOptions.defaultValue);
		returnObj.entityData = defaultValue;
	}
	// TODO: revisit later because of duplicate in control.mixin
	// Need to recalculate defaultFilteredValue on observed field change
	if (fieldOptions.defaultFilteredValue) {
		const configFilters = fieldOptions.defaultFilteredValue.filters.map((filter) => {
			return sc.classes.get(filter.$type, filter, stateContext).toFilter();
		});
		const filters = [
			sc.classes.get('offsetSize.filter', 1),
			...configFilters
		];
		const entity = fieldOptions.defaultFilteredValue.entityTypes;
		const defaultValueResponse = await Search(entity, filters);
		if (defaultValueResponse.Results.length) {
			const result = defaultValueResponse.Results[0];
			let defaultValue = null;
			if (fieldOptions.defaultFilteredValue.path) {
				const { name, schema } = fieldOptions.defaultFilteredValue.path;
				if (schema === 'root') {
					defaultValue = result;
				} else {
					defaultValue = name.split('.').reduce((o, i) => o[i], result[schema]);
				}
				if (options.defaultFilteredValue.pathName) {
					const { name, schema } = options.defaultFilteredValue.pathName;
					defaultValue.name = name.split('.').reduce((o, i) => o[i], result[schema]);
				}
			} else {
				defaultValue = sc.classes.get('entityReference', result);
			}
			returnObj.entityData = defaultValue;
		}
	}
	return returnObj;
}

function setEnabledField({ options, stateContext, isFormInit }) {
	const returnObj = {};
	const enableCondition = options && options.enable;
	if (enableCondition) {
		const enabled = stateContext.eval(enableCondition);
		returnObj.disabledFields = enabled ? null : true;
		if (!enabled && !isFormInit) {
			returnObj.requiredFields = null;
		}
	}
	return returnObj;
}

async function setHiddenField({ options, stateContext }) {
	const visibleCondition = options.visible;
	if (visibleCondition) {
		const visible = await stateContext.evalAsync(visibleCondition);
		return { hiddenFields: visible ? null : true };
	}
}

async function setRequiredField({ state, field, options, stateContext }) {
	const returnObj = {};
	for (const validatorName of getValidatorNames(options)) {
		if (validatorName) {
			const withVaidator = options[validatorName] && (!options[validatorName].enable || await stateContext.evalAsync(options[validatorName].enable));
			if (withVaidator) {
				returnObj.fieldsWithValidators = state.entityData[field] ? null : withVaidator;
				if (options[validatorName].type === 'required') {
					const validator = new validatorsMapping[validatorName](field, options[validatorName], stateContext);
					const result = await validator.validate(state.entityData, stateContext);
					if (!result.result) {
						returnObj.requiredFields = true;
					}
				}
			} else if (returnObj.fieldsWithValidators) {
				returnObj.fieldsWithValidators = null;
			}
		}
	}
	return returnObj;
}

function addValidator({ state, field, options, stateContext }) {
	const validators = [...(state.validators[field] || [])];
	getValidatorNames(options).forEach((validatorName) => {
		if (!validatorName) {
			return;
		}
		const validatorExist = validators.some(validator => validator.options.$type === options[validatorName].$type);
		if (!validatorExist) {
			validators.push(new validatorsMapping[validatorName](field, options[validatorName], stateContext));
		}
	});
	return { validators };
}

function setFieldsToRecalculate({ field, options }) {
	// we take only needed properties from options by destructuring and then compose new _options object to traverse
	const {
		enable, visible, resetOn, computed, defaultValue, defaultFilteredValue,
		requiredValidator, combinedFileUploadValidator, minValueValidator, maxValueValidator, maxLengthValidator, regexpValidator,
		maxLatValueValidator, minLatValueValidator, maxLonValueValidator, minLonValueValidator
	} = options;
	const _options = {
		enable,
		visible,
		resetOn,
		computed,
		defaultValue,
		defaultFilteredValue,
		requiredValidator,
		combinedFileUploadValidator,
		minValueValidator,
		maxValueValidator,
		maxLengthValidator,
		regexpValidator,
		maxLatValueValidator,
		minLatValueValidator,
		maxLonValueValidator,
		minLonValueValidator
	};
	const fields = {};
	traverseObj(_options, value => {
		if (isObject(value) && value.$type === 'expression.field' && value.name) {
			const fieldName = value.name.includes('.') ? value.name.split('.')[0] : value.name;
			fields[fieldName] = field;
		}
	});
	fields[field] = field;

	return { fieldsToRecalculate: fields };
}

const validatorsMapping = {
	requiredValidator,
	combinedFileUploadValidator: fileUploadValidator,
	minValueValidator,
	maxValueValidator,
	maxLengthValidator,
	regexpValidator,
	maxLatValueValidator,
	minLatValueValidator,
	maxLonValueValidator,
	minLonValueValidator
};

export default {
	namespaced: true,
	state() {
		return {
			initialEntity: null,
			entityData: {},
			validators: {},
			fieldsOptions: {},
			disabledFields: {},
			hiddenFields: {},
			validFields: {},
			requiredFields: {},
			fieldsWithValidators: {},
			fieldsToReset: {},
			asyncSavePromise: null,
			fieldsToRecalculate: {}
		};
	},
	getters: {
		changedFields: state => {
			if (state.initialEntity) {
				const diff = (obj1, obj2) => Object.keys(obj1).filter(k => {
					if (isObject(obj1[k]) && isObject(obj2[k])) {
						// if we have object in store - it is definitely entity reference
						return obj1[k].id !== obj2[k].id;
					} else {
						return obj1[k] !== obj2[k];
					}
				});
				return diff(state.entityData, state.initialEntity);
			} else {
				return [];
			}
		}
	},
	mutations: {
		initEntity(state, entity) {
			state.initialEntity = entity;
		},

		setAsyncSavePromise(state, promise) {
			state.asyncSavePromise = promise;
		},

		setValidState(state, { field, valid }) {
			Vue.set(state.validFields, field, valid);
		},

		setFieldValue(state, { field, value }) {
			Vue.set(state.entityData, field, value);
		},

		setFieldData(state, { field, fieldData }) {
			for (const key in fieldData) {
				if (key === 'fieldsToRecalculate') {
					for (const _key in fieldData[key]) {
						if (!state.fieldsToRecalculate[_key]) state.fieldsToRecalculate[_key] = [];
						state.fieldsToRecalculate[_key].push(fieldData.fieldsToRecalculate[_key]);
					}
				} else if (key === 'fieldsToReset') {
					Object.keys(fieldData.fieldsToReset).forEach(_key => {
						if (!state.fieldsToReset[_key]) state.fieldsToReset[_key] = [];
						state.fieldsToReset[_key] = state.fieldsToReset[_key].concat(fieldData.fieldsToReset[_key]);
					});
				} else {
					Vue.set(state[key], field, fieldData[key]);
				}
			}
		}
	},
	actions: {
		async setField({ commit, dispatch }, { name, value, isFormInit }) {
			commit('setFieldValue', {
				field: name,
				value
			});
			await dispatch('recalculateFieldData', {
				name,
				isFormInit
			});
		},

		async recalculateFieldData({ state, commit, dispatch }, { name, isFormInit, recalculateOnlyRelatedFields = false }) {
			// reset related fields before all checks
			if (state.fieldsToReset[name] && !isFormInit) {
				state.fieldsToReset[name].forEach((field) => {
					if (state.entityData[field]) {
						commit('setFieldValue', {
							field,
							value: null
						});
					}
				});
			}
			// recalculate field states
			const stateContext = createEvaluationContext(state.entityData);
			const fieldsToRecalculate = state.fieldsToRecalculate[name] || [];
			for (const field of fieldsToRecalculate) {
				if (recalculateOnlyRelatedFields && field === name) {
					continue;
				}
				const options = { ...(state.fieldsOptions[field] || {}) };
				let returnObj = {};
				const [enabledFieldRes, hiddenFieldRes, requiredFieldRes] = await Promise.all([
					setEnabledField({
						options,
						stateContext,
						isFormInit
					}),
					setHiddenField({
						options,
						stateContext
					}),
					setRequiredField({
						state,
						field,
						options,
						stateContext
					})
				]);

				const computedExpr = options.computed;
				let defaultValueRes = {};
				if (computedExpr) {
					const computedEnable = stateContext.eval(computedExpr.enable);
					const computedFieldName = computedExpr.fieldName;
					if (computedEnable && (!computedFieldName || (computedFieldName && computedFieldName === name))) {
						defaultValueRes = await setDefaultValue({
							options,
							stateContext
						});
					}
				}
				returnObj = {
					...returnObj,
					...enabledFieldRes,
					...hiddenFieldRes,
					...requiredFieldRes,
					...defaultValueRes
				};
				commit('setFieldData', {
					field,
					fieldData: returnObj
				});
				if (field !== name && !isFormInit) {
					// we dont need to recalculate fieldData for current field, only for dependant fields
					await dispatch('recalculateFieldData', {
						name: field,
						isFormInit,
						recalculateOnlyRelatedFields: true
					});
				}
			}
		},

		async registerField({ state, commit, dispatch }, { field, options }) {
			let returnObj = {};
			// set valid status
			returnObj.validFields = true;
			const stateContext = createEvaluationContext(state.entityData);
			// set required and enabled fields on form init, add validator
			const [validatorRes, enabledFieldRes, hiddenFieldRes, requiredFieldRes, fieldsToRecalculate] = await Promise.all([
				addValidator({
					state,
					field,
					options,
					stateContext
				}),
				setEnabledField({
					options,
					stateContext,
					isFormInit: true
				}),
				setHiddenField({
					options,
					stateContext
				}),
				setRequiredField({
					state,
					field,
					options,
					stateContext
				}),
				setFieldsToRecalculate({
					field,
					options
				})
			]);
			returnObj = {
				...returnObj,
				...validatorRes,
				...enabledFieldRes,
				...hiddenFieldRes,
				...requiredFieldRes,
				...fieldsToRecalculate,
				fieldsOptions: options
			};
			if (options.resetOn) {
				options.resetOn.forEach((fieldName) => {
					if (!returnObj.fieldsToReset) returnObj.fieldsToReset = {};
					if (!returnObj.fieldsToReset[fieldName]) returnObj.fieldsToReset[fieldName] = [];
					returnObj.fieldsToReset[fieldName].push(field);
				});
			}
			commit('setFieldData', {
				field,
				fieldData: returnObj
			});
			// if this fields depends not only from himself (length > 1), then we need to recalculate it because value could have changed
			if (Object.keys(fieldsToRecalculate.fieldsToRecalculate).length > 1) {
				await dispatch('recalculateFieldData', {
					name: field,
					isFormInit: true
				});
			}
		},

		async validate({ state, commit }) {
			const errors = [];
			const stateContext = createEvaluationContext(state.entityData);

			for (const [field, validators] of Object.entries(state.validators)) {
				// clean validation errors
				commit('setValidState', {
					field,
					valid: true
				});
				// skip disabled and not required fields
				if (state.disabledFields[field] && !state.fieldsWithValidators[field]) continue;
				for (const validator of validators) {
					const result = await validator.validate(state.entityData, stateContext);
					result.hideFieldName = validator.options.hideFieldName;
					if (!result.result) {
						commit('setValidState', {
							field,
							valid: false
						});
						errors.push(result);
					}
				}
			}
			if (errors.length) {
				throw errors;
			}
		}
	}
};
