<template>
	<div class="flexible-field-wrapper" v-bind:class="css" v-show="visible" v-bind:style="{'width': options.controlWidth, 'min-width':options.minWidth}">
		<div class="icon-wrapper" v-if="options.icon">
			<i v-bind:class="'form-icons-' + options.icon"></i>
		</div>
		<div class="field-title" :class="titleClass" :style="{'width': options.titleWidth}" v-if="title">{{title}}</div>
		<span class="field-value" :style="{'width': 'calc(100% - ' + options.titleWidth +')'}">
			<control v-bind:name="options.value.$type" :contentProps="options.value" :evaluationContext="evaluationContext"></control>
		</span>
	</div>
</template>
<script>
import FieldWrapperMixins from './fieldWrapper.mixins';
export default {
	name: 'control-flexiblefieldwrapper',
	mixins: [FieldWrapperMixins],
	data() {
		return { titleClass: this.evaluationContext.eval(this.options.titleCssClass) || 'p13' };
	}
};
</script>
<style src="./control-flexiblefieldwrapper.less" scoped></style>
