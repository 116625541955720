import {
	Delete, Update, Search, SearchByIds, getBaseRouteUrl, Deferred
} from '@acx-xms/data-functions/dist';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
import { deleteCampaign as deleteFromMCI } from '@/Data/DataProviders/coMCIDataprovider';
import coAuthDataProvider from '@/Data/DataProviders/coAuthDataprovider';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import { GetPerConfig } from '@/Data/DataProviders/userStorageDataProvider';

import router from '@/router';

export default {
	async enabled(options, context, selection) {
		if (!context.datasource.Source) {
			context = createEvaluationContext(selection[0]);
		}
		return context.eval(options.enable);
	},
	async execute(options, context, selection) {
		let selectedCampaign = null;

		if (selection[0].Type === 'marketingcampaignmember') {
			/* TODO: refactor */
			const campaign = await SearchByIds('marketingcampaign', selection[0].Source.marketingcampaignid.id, [sc.classes.get('offsetSize.filter', 1)]);
			selectedCampaign = campaign.Results[0].Source;
		} else {
			selectedCampaign = selection[0].Source;
		}
		const selectedCampaignServiceAccount = selectedCampaign.marketingcampaignsystemuserserviceaccountidsystemuserserviceaccount.serviceaccountuserid;
		const initToken = await GetPerConfig('co-auth-key');
		const userInfo = await GetUserInfo();
		const campaignStatus = selectedCampaign.status;
		let deletedFromMC = false;
		const deleteFromEdge = async (edgeId) => {
			await Update('marketingcampaign', edgeId, { status: 'Deleted' }).then(async () => {
				await Delete('marketingcampaign', edgeId).then(() => {
					successToas();
				}).catch(async (error) => {
					await errorHandler(error);
				});
			}).catch(async (error) => {
				if (error.message === `marketingcampaign with Id: ${edgeId} not found.`) {
					successToas(!deletedFromMC && 'error');
				} else {
					await errorHandler(error);
				}
			});
		};
		const deleteEverywhere = async (mciId, edgeId) => {
			await deleteFromMCI(mciId)
				.then(async () => {
					deletedFromMC = true;
					await deleteFromEdge(edgeId);
				})
				.catch(async (error) => {
					if (error && error.details && error.details.status && error.details.status === 404) {
						await deleteFromEdge(edgeId);
					} else errorHandler(error, () => deleteEverywhere(mciId, edgeId), initToken);
				});
		};
		const errorHandler = async (err, thrower, initialToken) => {
			if (
				err.toString() === 'Error: JWT token is absent or invalid' ||
				err.message === 'JWT token is absent or invalid'
			) {
				await coAuthDataProvider.refreshToken().catch(async () => {
					await coAuthDataProvider.authenticate(
						`${location.origin}/${sc.clusterKey()}/${sc.configurationKey()}/coauth`, false
					);
				});

				const def = Deferred();
				onstorage = async (e) => {
					const token = await GetPerConfig('co-auth-key');
					if (e.key.includes('co-auth-key') && token && token !== initialToken) {
						await thrower();
						onstorage = null;
						def.resolve();
					}
				};
				await def.promise;
			} else {
				console.log(err);
				sc.events.emit('toast.open', 'The email campaign has not been deleted due to some reason. Please, try again.', 3000);
			}
		};
		const successToas = (error) => {
			sc.events.emit('toast.open', error ? 'The campaign does not exist anymore' : 'The email campaign has been deleted', 3000);
			if (router.currentRoute.params.isDetails) {
				const path = router.currentRoute.params.entryPoint || `${getBaseRouteUrl(router.currentRoute)}/email-campaigns`;
				router.push(path);
			}
			(options.refreshEvents || []).forEach(e => sc.events.emit(e));
		};

		sc.events.emit('vue.dialog.open', {
			icon: 'action-icons-search-save',
			title: 'Delete email campaign',
			component: 'confirm.dialog',
			maximizedWidth: '450px',
			maximizedHeight: 'auto',
			message: 'Are you sure you want to delete the email campaign? The email campaign will be deleted permanently and you will not be able to restore it.',
			okLabel: 'Delete',
			onSubmit: async () => {
				const currentServiceAccount = await Search('systemuserserviceaccount', [
					sc.classes.get('offsetSize.filter', 1),
					sc.classes.get('termFacet.filter', {
						logicalName: 'systemuserid.id',
						query: [userInfo.Id]
					}).fillQuery(),
					// TODO: move guid to the package in the nearest future
					sc.classes.get('termFacet.filter', {
						logicalName: 'serviceaccounttype.id',
						query: ['b08a0bf8-d86b-4a16-b3c8-65846f74ffcc']
					}).fillQuery(),
					sc.classes.get('termFacet.filter', {
						logicalName: 'inuse',
						query: [true]
					}).fillQuery()
				]);

				// CASE 1: EMPTY OR DIFFERENT MC ACCOUNT
				if ((!currentServiceAccount.Total) || (selectedCampaignServiceAccount !== currentServiceAccount.Results[0].Source.serviceaccountuserid)) {
					const edgeId = selectedCampaign.marketingcampaignid;
					if (campaignStatus === 'Draft') {
						await deleteFromEdge(edgeId);
					} else {
						sc.events.emit('vue.dialog.open', {
							icon: 'action-icons-search-save',
							title: 'Operation cannot be fully done',
							component: 'confirm.dialog',
							maximizedWidth: '450px',
							maximizedHeight: 'auto',
							message: 'The campaign will be deleted in MarketSpace <b>only</b> since there is no connection with the Mailchimp account from where the campaign was sent. To delete it in Mailchimp as well, please, log in to the relevant account in Settings. <br />Would you like to delete the campaign in MarketSpace?',
							okLabel: 'Delete',
							cancelLabel: 'Close',
							customLabel: 'Go to settings',
							onSubmit: async () => {
								await deleteFromEdge(edgeId);
							},
							onCustomSubmit: () => {
								router.push(`${getBaseRouteUrl(router.currentRoute)}/${userInfo.type}/${userInfo.fullname}/${userInfo.Id}`);
							}
						});
					}
				}
				// CASE 2: MC ACCOUNT IS SAME WITH CAMPAIGN
				if (selectedCampaignServiceAccount === currentServiceAccount.Results[0].Source.serviceaccountuserid) {
					const mciId = selectedCampaign.mcicampaignid;
					const edgeId = selectedCampaign.marketingcampaignid;
					if (campaignStatus === 'Draft') {
						await deleteFromEdge(edgeId);
					} else {
						await deleteEverywhere(mciId, edgeId);
					}
				}
			}
		});
	}
};
