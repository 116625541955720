import { isActive, isAllowedEntity } from './Methods/action.methods';

export default {
	async enabled(options, evaluationContext, selection, fullselection) {
		return isAllowedEntity(options.allowedEntityTypes, fullselection) && await isActive(evaluationContext, selection);
	},
	async execute(options, context, selection) {
		const relateFromRecord = context.eval(options.relateFromRecord);

		sc.events.emit('vue.dialog.open', {
			icon: '',
			title: context.eval(options.dialogTitle),
			allowPin: false,
			component: 'document.folder.add.to.dialog',

			maximizedWidth: options.dialogWidth || '500px',
			maximizedHeight: options.dialogHeight || '300px',
			documentRelatedRecordsTemplate: options.documentRelatedRecordsTemplate,
			editorTemplate: options.editorTemplate,
			logicalName: relateFromRecord.logicalname,
			params: {
				evaluationContext: context,
				relateFromRecord,
				relateToEntityName: context.eval(options.relateToEntityName),
				relationRecordName: 'shared' + relateFromRecord.logicalname,
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey),
				lookupFilters: options.lookupFilters
			}
		});
	}
};
