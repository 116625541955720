<template>
	<div class="config-tool-wrapper">
		<div class="header" v-if="!isLoading && !isChildLoading">
			<breadcrumbs v-if="breadcrumbs" :breadcrumbs="breadcrumbs"></breadcrumbs>
			<action-menu :options="actionMenuComponent"
						 :record="templateRecord"
						 v-if="actionMenuComponent"
						 class="without-padding without-border"
						 :evaluationContext="evaluationContext">
			</action-menu>
		</div>
		<config-tool v-if="!isLoading"
					 :templateRecord="templateRecord"
					 @onLoadingChange="onLoadingChange"
					 @onIsDirtyChange="onIsDirtyChange"></config-tool>
		<div class="loading-overlay" v-show="isLoading || isChildLoading"></div>
	</div>
</template>

<script>
import { getTemplateRecord } from './resolver';
import { getBaseRouteUrl } from '@acx-xms/data-functions/dist';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
const ConfigTool = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './config-tool.vue');
const ActionMenu = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Menu/action-menu');
const Breadcrumbs = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/ComponentSet/breadcrumbs/breadcrumbs');

export default {
	name: 'config-tool-wrapper',
	components: {
		ConfigTool,
		ActionMenu,
		Breadcrumbs
	},
	data() {
		return {
			isLoading: true,
			isChildLoading: true,
			templateRecord: null,
			isDirty: false
		};
	},
	props: { defaultRoute: Object },
	async created() {
		window.onbeforeunload = (event) => {
			if (this.isDirty) {
				const e = event || window.event;
				// IE & Firefox
				if (e) {
					e.returnValue = 'Are you sure?';
				}
				// For Safari
				return 'Are you sure?';
			}
		};
		this.fork = sc.events.fork();
		this.fork.on('refreshAfterApply', async () => {
			this.templateRecord = await getTemplateRecord(this.id);
			this.refreshPage();
		});
		this.id = this.$route.params.id;
		this.templateRecord = await getTemplateRecord(this.id);
		this.settings = this.getSettings();
		const entityConfig = await sc.classes.get('entityConfiguration.dataProvider').fetchEntity('configtemplate');
		this.evaluationContext = createEvaluationContext(this.templateRecord);
		const title = this.evaluationContext.eval(entityConfig.entityItemDisplayName);
		this.syncBreadcrumbs({
			id: this.id,
			logicalName: 'config-tool',
			title
		});

		this.$on('.navigateBreadcrumbs', () => {
			const bread = [...this.breadcrumbs];
			// current
			bread.pop();
			// prev
			const prev = bread.pop();
			if (prev) {
				this.$router.push(prev.route);
			}
		});

		for (let i = 0; i < this.settings.item.length; i++) {
			if (this.exportActionIndex && this.previewActionIndex && this.applyActionIndex) break;
			const text = this.evaluationContext.eval(this.settings.item[i].text);
			if (text === 'Export') {
				this.exportActionIndex = i;
			} else if (text === 'Preview') {
				this.previewActionIndex = i;
			} else if (text === 'Apply') {
				this.applyActionIndex = i;
			}
		}
		this.actionMenuComponent = {
			menu: this.settings,
			entityType: 'configtemplate',
			$type: 'layoutcomponent.marketspaceActionMenu',
			scrollNamespace: 'mainSearch.marketspace.config-tool'
		};

		const localizations = sc.classes.get('localization.dataProvider');
		this.localizations = {
			title: await localizations.getLabelForCurrentLanguage('common.dictionary.confirmDlgCaption'),
			message: await localizations.getLabelForCurrentLanguage('common.dictionary.doYouWantSave'),
			okLabel: await localizations.getLabelForCurrentLanguage('common.dictionary.buttons.save'),
			customLabel: await localizations.getLabelForCurrentLanguage('common.dictionary.buttons.doNotSave')
		};

		this.isLoading = false;
	},
	beforeDestroy() {
		window.onbeforeunload = null;
		this.$off('.navigateBreadcrumbs');
		this.fork.dispose();
	},
	methods: {
		onLoadingChange(value) {
			this.isChildLoading = value;
		},
		onIsDirtyChange(value) {
			this.isDirty = value;
			this.$set(this.settings.item[this.exportActionIndex].action, 'isDirty', value);
			this.$set(this.settings.item[this.previewActionIndex].action, 'isDirty', value);
			this.$set(this.settings.item[this.applyActionIndex].action, 'isDirty', value);
		},
		syncBreadcrumbs(item, breadcrumbs) {
			const breadcrumbsKey = 'routes.breadcrumbs';
			if (breadcrumbs) {
				sessionStorage.setItem(breadcrumbsKey, JSON.stringify(breadcrumbs));
				return;
			}
			breadcrumbs = JSON.parse(sessionStorage.getItem(breadcrumbsKey));
			if (item) {
				const routeBase = getBaseRouteUrl(this.$route);

				breadcrumbs = breadcrumbs && breadcrumbs.length > 0
					? breadcrumbs
					: [{
						id: null,
						title: this.defaultRoute.displayName,
						route: { path: `${routeBase}/${this.defaultRoute.path}` }
					}];
				const currentBreadCrumb = breadcrumbs[breadcrumbs.length - 1];
				if (currentBreadCrumb.id !== item.id) {
					item.route = { path: `${routeBase}/${item.logicalName}/${item.id}` };
					breadcrumbs.push(item);
					sessionStorage.setItem(breadcrumbsKey, JSON.stringify(breadcrumbs));
				} else if (currentBreadCrumb.id === item.id && currentBreadCrumb.title !== item.title) {
					breadcrumbs.splice(breadcrumbs.length - 1, 1);
					breadcrumbs.push(item);
					sessionStorage.setItem(breadcrumbsKey, JSON.stringify(breadcrumbs));
				}
			}
			this.breadcrumbs = breadcrumbs;
		},
		refreshPage() {
			this.isLoading = true;
			setTimeout(() => {
				this.isLoading = false;
			}, 1000);
		},
		// actions for config-tool
		getSettings() {
			return {
				$type: 'menu.menu',
				name: 'configtemplate-dropdownMenu',
				display: 'auto',
				responsiveDisplayStyle: 'auto',
				responsiveStyle: 'collapsed',
				menuIconType: 'PNG',
				item: [
					{
						$type: 'menu.menuItem',
						display: 'text',
						default: false,
						text: {
							$type: 'expression.text',
							value: 'Export'
						},
						action: {
							$type: 'action.downloadRecordAsJSON',
							fieldsToExport: 'name,templatetype,entity,description,majorversion,editable,active,configuration,type,content',
							selection: { $type: 'expression.contextRef' },
							source: 'context'
						}
					},
					{
						$type: 'menu.menuItem',
						display: 'text',
						default: false,
						text: {
							$type: 'expression.text',
							value: 'Select Record'
						},
						action: {
							$type: 'action.selectRecord',
							eventName: 'configToolRecordSelected',
							logicalNamePath: 'Source.entity',
							template: {
								$type: 'control.template',
								usage: 'custom',
								subtype: 'vue-list',
								entity: this.templateRecord.Source.entity,
								colspan: 1
							},
							selection: { $type: 'expression.contextRef' },
							source: 'context',
							ignoreSelectedFields: true
						}
					},
					{
						$type: 'menu.menuItem',
						display: 'text',
						default: false,
						text: {
							$type: 'expression.text',
							value: 'Apply'
						},
						action: {
							$type: 'action.config.apply',
							refreshEvent: {
								$type: 'expression.text',
								value: 'mainSearch.marketspace.configtemplates.searching'
							},
							isDetails: true,
							enable: {
								$type: 'expression.and',
								argument: [
									{
										$type: 'expression.ne',
										argument: [
											{
												$type: 'expression.field',
												name: 'recordstate.id',
												schema: 'source'
											},
											{
												$type: 'expression.text',
												value: '9e817a63-452c-e811-8320-7824af3b452f'
											}
										]
									},
									{
										$type: 'expression.ne',
										argument: [
											{
												$type: 'expression.field',
												name: 'active',
												schema: 'source'
											},
											{ $type: 'expression.true' }
										]
									}
								]
							},
							selection: { $type: 'expression.contextRef' },
							source: 'context'
						}
					},
					{
						$type: 'menu.menuItem',
						display: 'text',
						default: false,
						text: {
							$type: 'expression.text',
							value: 'Preview'
						},
						action: {
							$type: 'action.config.preview',
							enable: {
								$type: 'expression.and',
								argument: [
									{
										$type: 'expression.ne',
										argument: [
											{
												$type: 'expression.field',
												name: 'recordstate.id',
												schema: 'source'
											},
											{
												$type: 'expression.text',
												value: '9e817a63-452c-e811-8320-7824af3b452f'
											}
										]
									},
									{
										$type: 'expression.ne',
										argument: [
											{
												$type: 'expression.field',
												name: 'active',
												schema: 'source'
											},
											{ $type: 'expression.true' }
										]
									}
								]
							},
							selection: { $type: 'expression.contextRef' },
							source: 'context'
						}
					}
				]
			};
		}
	},

	// ask to save template before leaving the page via navigation
	beforeRouteLeave(to, from, next) {
		if (this.isDirty) {
			sc.events.emit('vue.dialog.open', {
				icon: 'action-icons-search-save',
				title: this.localizations.title,
				component: 'confirm.dialog',
				maximizedWidth: '450px',
				maximizedHeight: 'auto',
				message: this.localizations.message,
				onSubmit: () => {
					sc.events.emit('saveTemplate', { callback: next });
				},
				okLabel: this.localizations.okLabel,
				onCancel: () => {
					next(false);
				},
				onCustomSubmit: () => {
					next();
				},
				customLabel: this.localizations.customLabel
			});
		} else {
			next();
		}
	}
};

</script>
<style src="./config-tool-wrapper.less" scoped></style>
