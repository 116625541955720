import ValidatorBase from './../base';
export default class extends ValidatorBase {
	async validate(entityData) {
		const fldValue = entityData[this.field];
		const value = (fldValue || {})[this.options.field];

		return {
			field: this.options.title,
			result: !fldValue ? true : (!value || value <= this.options.number),
			message: await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('entity.control.maxValueValidator.msg', [this.options.number])
		};
	}
}
