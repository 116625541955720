import ExpressionScope from '../misc/scope';

export default {
	parameters(options) {
		return [
			this.eval(options.array),
			options.itemScope,
			this.scope,
			options.mapper,
			this.eval
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.array),
			options.itemScope,
			this.scope,
			options.mapper,
			this.eval
		];
	},
	evaluate(array, scopekey, scopes, expr, builder) {
		return array.every(item => {
			return ExpressionScope.evaluate(scopes, scopekey, item, expr, builder) === true;
		});
	}
};
