import { mapAsync } from '@acx-xms/data-functions/dist';

const dateSpanParams = ['minutes', 'hours', 'days', 'months', 'years'];

export default {
	parameters(options) {
		return [
			dateSpanParams.map(param => this.eval(options[param]))
		];
	},
	async parametersAsync(options) {
		return [
			await mapAsync(options.argument, async (item) => {
				return await this.evalAsync(options[item]);
			})
		];
	},
	evaluate(args) {
		return args.reduce((result, item, key) => {
			const val = item || 0;
			result[dateSpanParams[key]] = val;
			return result;
		}, {});
	}
};
