var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.menuObj && _vm.visible
    ? _c("action-menu", {
        key: _vm.key,
        staticClass: "centered-menu without-border",
        attrs: {
          options: _vm.menuObj,
          record: _vm.menuObj.selection,
          evaluationContext: _vm.context
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }