var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "column", class: _vm.cssClass },
        _vm._l(_vm.controls, function(item) {
          return _c("control", {
            key: item.$key,
            attrs: {
              name: item.$type,
              contentProps: item,
              evaluationContext: _vm.evaluationContext
            }
          })
        })
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }