export default {
	async enabled(options, evaluationContext, selection) {
		return true;
	},
	async execute(options, context, selection) {
		let logicalName;
		if (options.logicalName) {
			logicalName = context.eval(options.logicalName);
		}
		if (options.logicalNamePath) {
			logicalName = sc.utils.findProperty(selection[0], options.logicalNamePath, true);
		}

		sc.events.emit('vue.dialog.open', {
			icon: '',
			title: await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('common.dictionary.selectRecord'),
			allowPin: false,
			component: 'select.record.dialog',
			maximizedWidth: '500px',
			maximizedHeight: '400px',
			params: {
				evaluationContext: context,
				logicalName,
				eventName: options.eventName,
				lookupTemplate: options.template,
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey),
				ignoreSelectedFields: options.ignoreSelectedFields
			}
		});
	}
};
