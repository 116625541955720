import ValidatorBase from './base';
export default class extends ValidatorBase {
	async validate(entityData, context) {
		const validMaxLength = context.eval(this.options.length);
		return {
			field: await this.customFieldName() || this.field,
			result: !entityData[this.field] || entityData[this.field].length < validMaxLength,
			message: this.message ? await context.evalAsync(this.message) : await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('entity.control.maxLengthValidator.msg', [validMaxLength])
		};
	}
}
