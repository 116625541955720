var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "email-campaign-header-root" }, [
    !_vm.isDetails
      ? _c(
          "section",
          { staticClass: "header" },
          [
            _c("div", { staticClass: "header-title" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v("Start Email Campaign")
              ]),
              _vm._v(" "),
              !!_vm.personCounter &&
              _vm.activeStepNumber === 5 &&
              _vm.getMCUserID
                ? _c("div", { staticClass: "person" }, [
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(_vm.recepientsLabel) + "\n\t\t\t"
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            !_vm.loading
              ? _c("StepCounter", {
                  attrs: {
                    disableCounter: _vm.emailCampaignProcess,
                    namespace: "wizard.navigation"
                  },
                  on: { active: _vm.changeStepHandler }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.isDetails
      ? _c(
          "section",
          { staticClass: "header details" },
          [
            _c("Breadcrumbs", {
              attrs: {
                breadcrumbs: _vm.breadcrumbsOptions.breadcrumbs,
                labelOptions: _vm.breadcrumbsOptions.labelOptions
              }
            }),
            _vm._v(" "),
            _c("ActionMenu", {
              attrs: {
                hasDropDownItems: true,
                options: _vm.menuOptions,
                record: {
                  Id: _vm.campaign.edgeId,
                  Source: {
                    status: _vm.campaign.status,
                    mcicampaignid: _vm.campaign.id,
                    marketingcampaignid: _vm.campaign.edgeId,
                    systemuserserviceaccountid: {
                      id: _vm.campaign.xmsAccountId
                    },
                    marketingcampaignsystemuserserviceaccountidsystemuserserviceaccount: {
                      serviceaccountuserid: _vm.campaign.mciAccountId
                    }
                  }
                }
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }