<template>
	<div class="content-wrapper">
		<div :class="['option', cssClass]">
			<div class="title" v-localization="{ key: 'userPanel.userLanguage' }"></div>
			<input
				v-model="currentLanguage"
				v-data-attr
				:class="cssClass"
				type="text"
				disabled
			/>
		</div>
		<div :class="['option', cssClass]">
			<div class="title" v-localization="{ key: 'userPanel.userCurrency' }"></div>
			<input
				v-data-attr
				v-model="currentCurrency"
				:class="cssClass"
				type="text"
				disabled
			/>
		</div>
		<!-- TODO: User Story 90189: [MVP] [All configs] Change SOM displayed to sf
		<div class="option" :class="cssClass">
		<div class="title" v-localization="{key: 'userPanel.userSOM'}"></div>
		<input type="text" v-bind:disabled="'true'" v-model="currentSom" :class="cssClass"/>
	</div>-->
	</div>
</template>
<script>
/* eslint vue/no-mutating-props: 0 */
import '@/Bindings/ui-attribute.directive';
import '@/Bindings/localization.directive';

export default {
	name: 'menu-userprofile-content',
	props: {
		currentLanguage: String,
		currentCurrency: String,
		currentSom: String,
		cssClass: String
	}
};
</script>

<style src="./menu-userprofile-content.less" scoped></style>
