let entityConfiguration;

export default {
	parameters(options) {
		if (!entityConfiguration) {
			entityConfiguration = sc.classes.get('entityConfiguration.dataProvider');
		}
		return [
			options.entity ? this.eval(options.entity) : this.entity.logicalname,
			options.isPlural ? this.eval(options.isPlural) : false,
			this.eval(options.language),
			sc.UILanguage.code
		];
	},
	async parametersAsync(options) {
		return [
			options.entity ? await this.evalAsync(options.entity) : this.entity.logicalname,
			options.isPlural ? await this.evalAsync(options.isPlural) : false,
			await this.evalAsync(options.language),
			sc.UILanguage.code
		];
	},
	evaluate(entity, isPlural, lng, uiLng) {
		console.error('entityName must be awaited');
	},
	async evaluateAsync(entity, isPlural, lng, uiLng) {
		if (!entityConfiguration) {
			entityConfiguration = sc.classes.get('entityConfiguration.dataProvider');
		}
		const data = await entityConfiguration.getEntityCaptionsFromMetadata([entity], isPlural ? 'plural' : 'singular');
		const entityData = data[0] || {};
		return entityData[lng || uiLng] || entityData.base;
	}
};
