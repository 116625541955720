<template>
	<div class="accessbylink-login-form">
		<input v-model.trim="userName" type="text" placeholder="Your name" @keyup.enter="submit" />
		<div class="tooltip-wrapper">
			<span class="tooltip-icon" @click="toggleTooltip" :class="{'opened': showTooltip}">
				<svg class="svg-icon svg-16">
					<use xlink:href="/Images/login-info.svg#login-info"></use>
				</svg>
			</span>
			<div class="tooltip" v-if="showTooltip" v-clickoutside="{ visible: showTooltip, callback: closeTooltip}">
				<p>
					Your name should be from 1 to 80 characters long
				</p>
			</div>
		</div>
		<div class="notifications-area" v-if="notificationText.length > 0">
			<span>{{notificationText}}</span>
		</div>
		<div class="footer">
			<div class="sign-in p13">
				<span>Have an account? <a :href="getLoginURL()">Sign in</a></span>
			</div>
			<button class="submit button" type="submit" @click="submit">Enter Without Registration</button>
		</div>
	</div>
</template>
<script>
import { getFullRecordLink } from '@acx-xms/data-functions/dist';
export default {
	name: 'accessbylink-login-form',
	data() {
		return {
			userName: null,
			notificationText: '',
			showTooltip: false
		};
	},
	methods: {
		async submit() {
			if (!this.userName || (this.userName && (this.userName.length < 1 || this.userName.length > 80))) {
				this.notificationText = 'Your name should be from 1 to 80 characters long';
			} else {
				this.$emit('input', this.userName);
			}
		},
		closeTooltip() {
			this.showTooltip = false;
		},
		toggleTooltip(e) {
			this.clickTarget = e.target;
			this.showTooltip = !this.showTooltip;
		},
		getLoginURL() {
			const route = {
				params: {
					...this.$route.params,
					layout: 'main',
					config: 'MarketSpace'
				}
			};
			return getFullRecordLink(route, {
				id: this.$route.params.id,
				logicalname: this.$route.params.type
			});
		}
	}
};
</script>
<style src="./accessbylink-login-form.less"></style>
