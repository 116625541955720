<template>
	<div class="initialization-wrapper">
		<initialization-panel v-show="initMessage" :message="initMessage"></initialization-panel>
		<div class="dialog" v-show="!initMessage">
			<logo></logo>
			<router-view></router-view>
		</div>
		<div class="credits"><span>Powered by <a target="_blank" href="https://ascendix.com">Ascendix Technologies</a></span></div>
	</div>
</template>
<script>
/* eslint no-unused-vars: 0 */
/* eslint vue/no-unused-components: 0 */
/* eslint no-undef: 0 */
// TODO review this eslint rules
import InitializationPanel from '@/Components/Initialization/InitializationLoader/initialization-panel';
import StaticDiscovery from '@/Components/Initialization/Discovery/static-discovery';
import Logo from '@/Components/Initialization/InitializationLoader/logo';
export default {
	name: 'initialization-wrapper',
	components: {
		InitializationPanel,
		StaticDiscovery,
		Logo
	},
	methods: {
		SelectConfig(config) {
			this.$router.push(`/${orgId}/${config}`);
		},
		async SelectOrg(orgId) {
			this.$router.push(`/${orgId}`);
		}
	},
	computed: {
		initMessage() {
			return this.$store.getters.initMessage;
		}
	}
};
</script>
<style src="@/Components/Initialization/initialization.mixins.less" scoped></style>
