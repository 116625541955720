<template>
	<div>
		<div v-if="!isLoading" id="page-wrapper" class="layout-marketspace">
			<div class="marketspace-layout-wrapper">
				<div v-if="!isAnonymous" :class="['marketspace-menu-wrapper', mainMenuState]">
					<TenantLogo
						:alt="companyTitle"
						:logo-icon="logoIcon"
						:state="mainMenuState"
					/>
					<MenuMarketspace :items="menu.menu.item" :state="mainMenuState" />
				</div>
				<div v-if="isAnonymous" class="marketspace-anon-logo-wrapper">
					<ImageWrapper v-if="anonymousLogoUrl"
						:alt="companyTitle"
						:url="anonymousLogoUrl"
						:width="'100%'"
						:height="'80px'"
						fit="cover"
					/>
					<div v-else class="svg-container">
						<svg class="svg-icon">
							<use xlink:href="#tenant-logo-anon-logo"></use>
						</svg>
					</div>
				</div>
				<div :class="['marketspace-content-wrapper', mainMenuState, !isAnonymous? 'hasMenu' : '']">
					<div class="main-header-area">
						<div
							v-if="!isAnonymous"
							v-data-attr
							class="collapse-menu-btn"
							@click="switchMenuState"
						>
							<svg class="svg-icon">
								<use xlink:href="#layout-icons-ms-btn-menu" class="icon-ms-btn-menu" />
							</svg>
						</div>
						<div class="main-header-menu-wrapper">
							<LicenseInfo v-if="hasLicenceModule && !(isAnonymous || isGuest)" />
							<MarketspaceHeaderMenu v-if="marketspaceHeaderMenu" :item="marketspaceHeaderMenu.item" />
							<MenuFavorites v-if="favoritesPanel" />
							<MenuNotification v-if="notificationPanel" />
							<MenuConversation v-if="conversationPanelProps && conversationPanelProps.enabled" :options="conversationPanelProps" />
							<MenuOrgSettings v-if="!isGuestConfig && isOrgAdmin" /> <!-- disabled for MSGuest config and all users except org admin -->
							<MenuKnowledgeBase />
						</div>
						<component
							:is="userProfileMenusMap[userProfileMenu.$type]"
							:isAnonymous="isAnonymous"
							:params="{ userProfileMenu }"
						/>
					</div>
					<div class="main-content-area adaptive">
						<div class="marketspace-components-area adaptive">
							<div v-if="ready && trackingReady" class="component">
								<router-view :defaultRoute="defaultMenuItem" :key="$route.path" />
								<keep-alive include="route-search-results">
									<router-view
										:key="$route.path"
										:defaultRoute="defaultMenuItem"
										name="route"
									/>
								</keep-alive>
							</div>
							<div class="loading-overlay" v-show="!ready"></div>
						</div>
						<keep-alive>
							<component v-if="sidePanelActive"
								v-clickoutside="{ visible: sidePanelActive, callback: toggleSidePanel}"
								v-bind="sidePanelOptions"
								:is="sidePanelComponent"
								class="side-panel"
								@closePanel="closeSidePanel"
							/>
						</keep-alive>
					</div>
				</div>
				<div class="dialogmanager-footer">
					<OldDialogManager />
					<DialogManager />
				</div>
				<ToastPopUp />
				<AbsoluteMenuPopup />
				<AbsoluteTemplatePopup />
			</div>
			<Tooltip />
		</div>
		<div v-else>
			<InitializationPanel message="Almost there" />
		</div>
	</div>
</template>
<script>
import '@/Bindings/ui-attribute.directive';
import MenuMarketspace from '@/Components/Menu/menu-marketspace/menu-marketspace';
import MenuUserprofile from '@/Components/Menu/menu-marketspace-userprofile/menu-userprofile.vue';
import MenuUserprofileWithViewprofile from '@/Components/Menu/menu-marketspace-userprofile/menu-userprofile-with-viewprofile.vue';
import MarketspaceHeaderMenu from '@/Components/Menu/menu-marketspace-header/menu-marketspace-header.vue';
import MenuConversation from '@/Components/Menu/menu-app-bar/menu-conversation.vue';
import MenuNotification from '@/Components/Menu/menu-app-bar/menu-notification.vue';
import MenuFavorites from '@/Components/Menu/menu-app-bar/menu-favorites.vue';
import MsConversationsPanel from '@/Components/ConversationsPanel/ms-conversations-panel';
import MsNotificationsPanel from '@/Components/ConversationsPanel/ms-notifications-panel';
import LayoutMarketspaceMixin from '@/Components/Layout/layout.marketspace.mixins';
import TenantLogo from '@/Components/Layout/tenant-logo/tenant-logo';
import LicenseInfo from '@/Components/Layout/license-info';
import MenuKnowledgeBase from '../../../Menu/menu-app-bar/menu-knowledge-base.vue';
import ImageWrapper from '@/Components/Common/image-wrapper';
import MenuOrgSettings from '@/Components/Menu/menu-app-bar/menu-org-settings.vue';
import InitializationPanel from '@/Components/Initialization/InitializationLoader/initialization-panel';
import { Tooltip } from '@acx-xms/components/dist';

export default {
	metaInfo() {
		return {
			meta: [
				{
					name: 'robots',
					content: this.isOpenForIndexing && this.isListingPortal ? 'all' : 'noindex'
				}
			]
		};
	},
	name: 'layout-marketspace',
	mixins: [LayoutMarketspaceMixin],
	methods: {
		switchMenuState() {
			const newState = (this.mainMenuState === 'iconsOnly') ? 'iconsAndText' : 'iconsOnly';
			this.mainMenuState = newState;
			sc.classes.get('localStorage.dataProvider').set('mainMenuState', newState);
		}
	},
	components: {
		MenuUserprofile,
		MenuMarketspace,
		MarketspaceHeaderMenu,
		MsConversationsPanel,
		MsNotificationsPanel,
		MenuUserprofileWithViewprofile,
		TenantLogo,
		MenuConversation,
		MenuNotification,
		MenuFavorites,
		LicenseInfo,
		MenuKnowledgeBase,
		ImageWrapper,
		MenuOrgSettings,
		InitializationPanel,
		Tooltip
	},
	created() {
		sc.classes.get('localStorage.dataProvider').get('mainMenuState').done((data) => {
			const storedState = data || 'iconsOnly';
			this.mainMenuState = storedState;
		});
		this.userProfileMenusMap = {
			'menu.userProfileMenuWithViewProfile': 'menu-userprofile-with-viewprofile',
			'menu.userProfileMenu': 'menu-userprofile'
		};
		this.hasLicenceModule = sc.cluster.features.license;
	}
};
</script>
<style src="./layout-marketspace.less"></style>
<style src="@/Components/Layout/ui-elements.less" scoped></style>
