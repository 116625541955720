<template>
	<image-gallery-small-base :currentIndex="currentIndex" v-if="!isLoading"
							  :showArrows="showArrows"
							  :images="images"
							  :reference="reference"
							  :placeholderEntityName="placeholderEntityName"
							  :isCustomImagePlaceholder="options.isCustomImagePlaceholder"
							  :galleryStylesObj="galleryStylesObj"
							  @next="next"
							  @prev="prev"
							  @openDialog="openDialog">
	</image-gallery-small-base>
	<i class="loading-overlay" v-else></i>
</template>
<script>
import ImageGallerySmallMixin from './image-gallery-small.mixins.js';
export default {
	name: 'control-image-gallery-small-withDataSource',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			currentIndex: 0,
			showArrows: false,
			images: [],
			reference: null,
			isLoading: true,
			placeholderEntityName: ''
		};
	},
	mixins: [ImageGallerySmallMixin],
	methods: {
		async getImages(dataSources, callback) {
			let images = [];
			await Promise.all(dataSources.filter(callback).map(async (fs) => {
				await fs.search().done((response) => {
					if (response.hasData) {
						images = images.concat(response.data);
					}
				});
			}));
			return images;
		},
		async updateImages() {
			let images = this.evaluationContext.eval(this.options.items) || [];
			if (images.length) {
				this.setImages(images);
				this.isLoading = false;
				return;
			}
			if (this.options.dataSources && this.options.dataSources.length) {
				const dataSources = await this.options.dataSources.map((source) => {
					return sc.classes.get(source.$type, source, this.evaluationContext, ['image'], source.subtype || this.options.subtype);
				});
				images = await this.getImages(dataSources, (source) => {
					return source.subtype === this.options.subtype;
				});
				if (!images.length) {
					images = await this.getImages(dataSources, (source) => {
						return source.subtype !== this.options.subtype;
					});
				}
				this.setImages(images.map(({ Source }) => Source));
				this.isLoading = false;
			}
		}
	},
	async created() {
		await this.init();
		await this.updateImages();
		this.showArrows = this.images.length > 1;
	}
};
</script>
