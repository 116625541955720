import { isArray } from '@acx-xms/data-functions/dist';

export default {
	parameters(options) {
		return [
			this.eval(options.argument),
			this.eval(options.sortBy)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument),
			await this.evalAsync(options.sortBy)
		];
	},
	evaluate(array, sortBy) {
		if (!isArray(array)) {
			throw new Error('expression.array.sort: Argument is not an array');
		}
		if (sortBy) {
			return array.sort((a, b) => b[sortBy] - a[sortBy]);
		}
		return array.sort();
	}
};
