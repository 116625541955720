var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["toggle-wrapper", { parent: _vm.isParent }] }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible"
          }
        ],
        staticClass: "toggle-buttons-container display-modifier"
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.uncollapseVisibility,
                expression: "uncollapseVisibility"
              },
              {
                name: "data-attr",
                rawName: "v-data-attr",
                value: {
                  options: _vm.$parent.options,
                  stateNamespace: "expand-button"
                },
                expression:
                  "{ options: $parent.options, stateNamespace: 'expand-button' }"
              }
            ],
            staticClass: "toggle-button",
            on: { click: _vm.expand }
          },
          [_c("span", [_vm._v("+")])]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.collapseVisibility,
                expression: "collapseVisibility"
              },
              {
                name: "data-attr",
                rawName: "v-data-attr",
                value: {
                  options: _vm.$parent.options,
                  stateNamespace: "collapse-button"
                },
                expression:
                  "{ options: $parent.options, stateNamespace: 'collapse-button' }"
              }
            ],
            staticClass: "toggle-button",
            on: { click: _vm.collapse }
          },
          [_c("span", [_vm._v("–")])]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }