var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.pages.length || _vm.showWrapperIfEmpty,
          expression: "pages.length || showWrapperIfEmpty"
        },
        { name: "data-attr", rawName: "v-data-attr" }
      ],
      class: [
        "marketspace-pagination-wrapper",
        { "no-pages": _vm.pages.length < 2 }
      ]
    },
    [
      _c("div", { staticClass: "pagination" }, [
        _c(
          "div",
          { staticClass: "pagination-arrow prev", on: { click: _vm.previous } },
          [
            _c(
              "div",
              { class: ["navigation-item", { disabled: _vm.isSelected(1) }] },
              [
                _c(
                  "a",
                  {
                    staticClass: "pagination-item-arrow",
                    attrs: { rel: "prev" }
                  },
                  [
                    _c("svg", { staticClass: "svg-icon svg-10" }, [
                      _c("use", {
                        attrs: { "xlink:href": "#layout-icons-ms-arrow-left" }
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("a", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "common.dictionary.pagination.previous" },
                      expression:
                        "{ key: 'common.dictionary.pagination.previous' }"
                    },
                    { name: "data-attr", rawName: "v-data-attr" }
                  ],
                  staticClass: "text",
                  attrs: { rel: "prev" }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "ul",
          _vm._l(_vm.pages, function(page, index) {
            return _c(
              "li",
              {
                key: page.number + "-" + index,
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    _vm.selectPage(page.number)
                  }
                }
              },
              [
                page.number == _vm.elipsis
                  ? _c(
                      "span",
                      { staticClass: "navigation-item page-button disabled" },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" + _vm._s(_vm.elipsis) + "\n\t\t\t\t"
                        )
                      ]
                    )
                  : _c(
                      "a",
                      {
                        directives: [
                          { name: "data-attr", rawName: "v-data-attr" }
                        ],
                        class: [
                          "navigation-item page-button",
                          { selected: page.selected }
                        ],
                        attrs: {
                          href: _vm.getPaginationUrl(page),
                          rel: _vm.rel(_vm.pages, page)
                        }
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" + _vm._s(page.number) + "\n\t\t\t\t"
                        )
                      ]
                    )
              ]
            )
          })
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pagination-arrow next", on: { click: _vm.next } },
          [
            _c(
              "div",
              {
                class: [
                  "navigation-item",
                  { disabled: _vm.isSelected(_vm.lastPage) }
                ]
              },
              [
                _c("a", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "common.dictionary.pagination.next" },
                      expression: "{ key: 'common.dictionary.pagination.next' }"
                    },
                    { name: "data-attr", rawName: "v-data-attr" }
                  ],
                  staticClass: "text",
                  attrs: { rel: "next" }
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "pagination-item-arrow",
                    attrs: { rel: "next" }
                  },
                  [
                    _c("svg", { staticClass: "svg-icon svg-10" }, [
                      _c("use", {
                        attrs: { "xlink:href": "#layout-icons-ms-arrow-right" }
                      })
                    ])
                  ]
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _vm.total
          ? _c(
              "div",
              {
                staticClass: "load-more-wrapper",
                style: { visibility: _vm.showLoadMoreVisibility }
              },
              [
                _c(
                  "button",
                  {
                    directives: [{ name: "data-attr", rawName: "v-data-attr" }],
                    attrs: { type: "submit" },
                    on: { click: _vm.loadMore }
                  },
                  [
                    _c("span", {
                      directives: [
                        {
                          name: "localization",
                          rawName: "v-localization",
                          value: { key: "mainLayout.loadMore" },
                          expression: "{ key: 'mainLayout.loadMore' }"
                        }
                      ]
                    })
                  ]
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.total
        ? _c("div", { staticClass: "results-label" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.label) + "\n\t")
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }