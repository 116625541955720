var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "multiline-label" }, [
        _c("div", { staticClass: "label-wrapper", class: _vm.cssClass }, [
          _c(
            "div",
            {
              staticClass: "stripped-text-wrapper min-width",
              class: {
                "full-text": _vm.showMore,
                preserve: _vm.preserveWhiteSpace
              }
            },
            [
              _c("div", { staticClass: "stripped-text-label" }, [
                _vm._v(_vm._s(_vm.text))
              ]),
              _vm._v(" "),
              !_vm.showMore
                ? _c(
                    "div",
                    {
                      staticClass: "icon-wrapper more-text",
                      on: { click: _vm.showMoreAction }
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "svg-icon layout-icons-letter-more-grey"
                        },
                        [
                          _c("use", {
                            attrs: {
                              "xlink:href": "#layout-icons-letter-more-grey"
                            }
                          })
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }