import Required from './required';
export default class extends Required {
	async validate(entityData, context) {
		return {
			field: await this.customFieldName() || this.field,
			result: (entityData[this.field] &&
				((!entityData[this.field].isExisting && entityData[this.field].uploadedFileName) ||
					(entityData[this.field].isExisting && entityData[this.field].selectedFile))),
			message: this.message ? await context.evalAsync(this.options.message) : null

		};
	}
}
