import store from '@/States/ms-state';

const SPECIAL = { 'createdby.id': 'created_by_current_user' };

function handleSpecialFilter(filters) {
	Object.values(filters).forEach(filter => {
		const index = filter.findIndex(item => Object.keys(SPECIAL).includes(item.data.logicalName));
		if (index > -1) {
			filter.push(SPECIAL[filter[index].data.logicalName]);
			filter.splice(index, 1);
		}
	});
}

export default {
	async enabled(options, evaluationContext, selection) {
		return true;
	},
	async execute(options, context, selection) {
		const logicalNames = options.logicalName.map(logicalname => context.eval(logicalname));
		const storeNamespace = context.eval(options.storeNamespace);
		const filters = {};
		logicalNames.forEach(logicalname => {
			filters[logicalname] = store.getters[`${storeNamespace}/getFilters`](logicalname);
		});

		/* Remove special filters */
		handleSpecialFilter(filters);

		const searchQuery = store.getters[`${storeNamespace}/getSearchQuery`];
		filters.searchQuery = searchQuery || null;

		sc.events.emit('vue.dialog.open', {
			icon: '',
			title: 'Share Search',
			component: 'share.search.results.dialog',
			maximizedWidth: options.dialogWidth || '450px',
			maximizedHeight: options.dialogHeight || '355px',
			logicalNames,
			evaluationContext: context,
			filters: JSON.stringify(filters),
			infoText: 'Copy this link to share search query and filters. Search results can be changed'
		});
	}
};
