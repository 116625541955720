import { SendRequest } from '@acx-xms/data-functions/dist';

export async function addUrl(id, name, logicalname) {
	await send('addurl', id, name, logicalname);
};

export async function removeUrl(id, name, logicalname) {
	await send('removeurl', id, name, logicalname);
};

export async function send(path, id, name, logicalname) {
	await sendSitemapRequest(path, 'post', id, name, logicalname);
};
async function sendSitemapRequest(path, method, id, name, logicalname) {
	await SendRequest(
		`/api/sitemap/${path}`,
		method,
		{
			id,
			name,
			logicalname
		},
		null,
		{ configKey: sc.cluster.configKey },
		false,
		false);
};
