<template>
	<div v-if="views"
		 v-show="(views || []).length > 1"
		 class="view-switchers-container">
		<div v-for="(view, index) in views"
			 v-data-attr="{ options: $parent.options, stateNamespace: view.title}"
			 :key="`${view.title}-${index}`"
			 :class="['switch-button clickable', { selected: view.name === selectedView.name }]"
			 :title="view.title"
			 @click="changeSelection(view.name)">
			<svg class='svg-icon'>
				<use :xlink:href="`#layout-icons-ms-view-${view.viewIcon}`"
					 :class="`layout-icons-ms-view-${view.viewIcon}`" />
			</svg>
		</div>
	</div>
</template>
<script>
// TODO: investigate if we need to load styles like this
import '@acx-xms/styles-mixins/style.mixins.less';
import '@/Bindings/ui-attribute.directive';
import { Get, Set } from '@/Data/DataProviders/userStorageDataProvider';

export default {
	name: 'view-switcher',
	props: {
		items: Array,
		stateNamespace: String,
		storageKey: String,
		defaultSelectedView: Object,
		saveViewState: Boolean
	},
	data() {
		return {
			selectedView: this.defaultSelectedView || this.items[0],
			views: this.items.map(item => {
				return {
					title: item.title,
					viewIcon: item.viewIcon,
					selected: item.selected,
					name: item.name
				};
			})
		};
	},
	computed: {
		viewSwitcherKey() {
			return `view_switcher_${this.selectedView.namespace}`;
		}
	},

	async created() {
		const viewSwitcherState = await Get(this.viewSwitcherKey);
		viewSwitcherState && await this.changeSelection(viewSwitcherState);

		this.$root.$on(`${this.stateNamespace}.switchLayout`, async (componentName) => {
			if (this.selectedView.name !== componentName) {
				await this.changeSelection(componentName);
			}
		});

		// todo - rework after 88454 implemented
		this.$parent.$emit('set-content', {
			...this.selectedView,
			...{ $type: this.selectedView.$type }
		});
		this.$parent.$emit('set-with-toggle', this.hasToggleAll(this.selectedView));
	},
	methods: {
		changeSelection: async function (name) {
			if (this.saveViewState) await Set(this.viewSwitcherKey, name);

			this.$root.$emit(`${this.stateNamespace}.layoutSwitched`, name);
			const component = this.items.find(item => item.name === name);
			if (component) {
				this.selectedView = component;
				const content = {
					...component,
					...{
						$type: component.$type,
						$needRefresh: true
					}
				};
				this.$parent.$emit('set-content', content);
				this.$parent.$emit('set-with-toggle', this.hasToggleAll(component));
			} else {
				this.$parent.$emit('set-content', null);
			}
		},
		hasToggleAll(component) {
			let withToggleAll = component.withToggleAll;
			if (withToggleAll === void 0 && component.component) withToggleAll = component.component.withToggleAll;
			return withToggleAll;
		}
	},
	deactivated() {
		this.$root.$off(`${this.stateNamespace}switchLayout`);
	}
};
</script>
<style src="./view-switcher.less" scoped></style>
<!--TODO:investigate for future-->
<!--<style src="acx-xms/styles-mixins/style.mixins.less" scoped></style>-->
