import * as userStorageDataProvider from '@/Data/DataProviders/userStorageDataProvider';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import {
	generateGuid, SearchByIds, CreateBulk
} from '@acx-xms/data-functions/dist';

const defaultUpdateEdgeInterval = 60000;
const userActivityKey = 'userActivity';
const userActiveSessionKey = 'userActiveSessionKey';
let userGroupName = '';
let userId = '';
const groupNameMapping = {
	Brokers: 'Broker',
	Guests: 'Guest'
};

export const UpdateSessionInterval = 10000;
export async function Init() {
	const userAuthentication = await GetUserInfo();

	const filter = [
		{
			requestLabel: 'SelectedFields',
			data: { Fields: { name: { IsSelected: true } } }
		},
		{
			requestLabel: 'ResultsFormat',
			data: 'Source'
		}];

	const { Results: groups } = await SearchByIds(userAuthentication.groupid.logicalname, userAuthentication.groupid.id, filter);

	if (!sc.cluster.features.trackuseractivity) {
		return;
	}

	const group = groups.pop();
	if (!group || !groupNameMapping[group.name]) {
		userGroupName = 'Anonymous User';
	} else {
		userGroupName = groupNameMapping[group.name];
	}

	userId = userAuthentication.Id;

	const userActiveSessionData = await userStorageDataProvider.Get(userActiveSessionKey);
	if (!userActiveSessionData) {
		await userStorageDataProvider.Set(userActiveSessionKey, {
			globalSession: getSessionTemplate(),
			detailsSessions: {}
		});
	}

	updateEdgeRecords();

	startTimer();
}

export async function AddUserActivity(name, activitytype, targetrecord, relatedrecord) {
	if (!(sc.cluster.features && sc.cluster.features.trackuseractivity)) {
		return;
	}

	let data = await userStorageDataProvider.Get(userActivityKey);

	if (!data) { data = []; }

	data.push({
		name,
		activitytype,
		activitydate: new Date(),
		...(targetrecord && {
			targetrecordid: targetrecord.id,
			targetrecordlogicalname: targetrecord.logicalname
		}),
		...(relatedrecord && {
			relatedrecordid: relatedrecord.id,
			relatedrecordlogicalname: relatedrecord.logicalname
		})

	});

	await userStorageDataProvider.Set(userActivityKey, data);
}

export async function UpdateSession($route) {
	if (!sc.cluster.features.trackuseractivity) {
		return;
	}

	let userActiveSessionData = await userStorageDataProvider.Get(userActiveSessionKey);
	if (!userActiveSessionData) {
		userActiveSessionData = {
			globalSession: getSessionTemplate(),
			detailsSessions: {}
		};
	}

	const now = new Date();
	const allowedDetailsTypes = ['dealroom', 'collaborationroom', 'systemuser', 'listing', 'availability'];

	if ($route.name === 'details' && $route.params && allowedDetailsTypes.indexOf($route.params.type) > -1) {
		const detailsEntityRef = {
			id: $route.params.id,
			logicalname: $route.params.type
		};

		// Update details session
		const detailsSession = userActiveSessionData.detailsSessions[detailsEntityRef.id];
		if (detailsSession) {
			detailsSession.end = now;
		} else {
			// Add details session
			const detailSession = getSessionTemplate();
			detailSession.entity = detailsEntityRef;
			userActiveSessionData.detailsSessions[detailsEntityRef.id] = detailSession;
		}
	}

	// Update global session
	userActiveSessionData.globalSession.end = now;
	await userStorageDataProvider.Set(userActiveSessionKey, userActiveSessionData);
}

function startTimer() {
	setTimeout(updateEdgeRecords, defaultUpdateEdgeInterval);
}

async function updateEdgeRecords() {
	if (!sc.cluster.features.trackuseractivity) {
		return;
	}

	// create edge record
	let userActivityRecords = [];
	let activeSessionRecords = [];

	if (userGroupName && userId) {
		userActivityRecords = await buldUserActivityRecords();
		activeSessionRecords = await buldActiveSessionRecords();
	}

	const records = [...userActivityRecords, ...activeSessionRecords];

	if (records && records.length) {
		try {
			await CreateBulk(records, { async: true });
		} catch (e) {
			console.log(e);
		}
	}

	startTimer();
}

async function buldUserActivityRecords() {
	if (!sc.cluster.features.trackuseractivity) {
		return;
	}

	const data = await userStorageDataProvider.Get(userActivityKey);
	if (!data) {
		return [];
	}
	const records = [];
	data.forEach((item) => {
		const newRecord = {
			id: generateGuid(),
			method: 'POST',
			url: 'useractivity',
			body: {
				...item,
				securitygroup: userGroupName,
				systemuserid: {
					id: userId,
					logicalname: 'systemuser'
				}
			}
		};
		if (!newRecord.body.targetrecordid) {
			newRecord.body.targetrecordid = userId;
			newRecord.body.targetrecordlogicalname = 'systemuser';
		}
		records.push(newRecord);
	});

	await userStorageDataProvider.Set(userActivityKey, []);

	return records;
}

async function buldActiveSessionRecords() {
	if (!sc.cluster.features.trackuseractivity) {
		return;
	}

	const sessionsForUpdate = [];
	const userActiveSessionData = await userStorageDataProvider.Get(userActiveSessionKey);
	const now = new Date();
	const isSessionExpired = (session) => {
		const end = new Date(session.end);
		end.setMilliseconds(end.getMilliseconds() + defaultUpdateEdgeInterval);

		return now > end;
	};

	const getDurationSeconds = (session) => {
		const start = new Date(session.start);
		const end = new Date(session.end);

		return end - start;
	};

	if (isSessionExpired(userActiveSessionData.globalSession)) {
		sessionsForUpdate.push({ ...userActiveSessionData.globalSession });
		userActiveSessionData.globalSession = getSessionTemplate();
	}

	for (const key in userActiveSessionData.detailsSessions) {
		const detailsSession = userActiveSessionData.detailsSessions[key];
		if (isSessionExpired(detailsSession)) {
			sessionsForUpdate.push({ ...detailsSession });
		}

		delete userActiveSessionData.detailsSessions[key];
	}

	if (sessionsForUpdate.length) {
		await userStorageDataProvider.Set(userActiveSessionKey, userActiveSessionData);
	}

	return sessionsForUpdate.map((s) => {
		s.systemuserid = {
			id: userId,
			logicalname: 'systemuser'
		};

		s.duration = getDurationSeconds(s);
		s.name = 'activesession';

		return {
			id: generateGuid(),
			method: 'POST',
			url: 'activesession',
			body: s
		};
	});
}

function getSessionTemplate() {
	return {
		start: new Date(),
		end: new Date(),
		securitygroup: userGroupName,
		entity: null,
		duration: null
	};
}
