<template>
	<div class="create-collaborationroom-from-crm">
		<div class="loading-overlay" v-show="isLoading"></div>
		<div class="header">
			<h2 v-localization="{key: 'CreateRoom.createCR.title'}"></h2>
		</div>
		<div class="body" v-if="!isLoading" v-perfectscroll="{enable: true}">
			<div class="editor">
				<control v-if="editorTemplate" :name="editorTemplate.$type"
						 :contentProps="editorTemplate"
						 :evaluationContext="controlContext"></control>

			</div>
			<h5 class="title" v-localization="{key: 'CreateRoom.contactsTable.title'}"></h5>
			<div class="entity-list" v-if="!syncContactProcessId">
				<control class="formcontrol-template"
						 v-if="userListTemplate"
						 :name="userListTemplate.$type"
						 :contentProps="userListTemplate"
						 :evaluationContext="evaluationContext"
						 :initResults="contacts"></control>
				<div class="p13" v-else-if="noContactInCRM">{{noContactInCRM}}</div>
			</div>
			<SyncProgress :processId="syncContactProcessId" @onSyncCompleted="onSyncContactCompleted(true)" v-if="syncContactProcessId"/>
			<h2 class="title" v-localization="{key: 'addPreferenceDialog.crm.title'}"></h2>
			<div class="add-preference editor">
				<div class="flexible-item">
					<div class="field-wrapper">
						<div class="field-title" v-localization="{key: 'addPreferenceDialog.contact.lookup'}"></div>
						<control-lookup-editor v-model="selectedContact"
											   :showRequired="false"
											   :options="getContactLookupOptions()"></control-lookup-editor>
					</div>
				</div>

				<div class="flexible-item">
					<div class="field-wrapper">
						<div class="field-title" v-localization="{key: 'addPreferenceDialog.preference.lookup'}"></div>
						<div class="add-preference">
							<control-lookup-editor v-model="selectedPreference"
												   :defaultValue="preferenceDefaultValue"
												   :showRequired="false"
												   :options="getPreferenceLookupOptions()"></control-lookup-editor>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="dialog-buttons">
				<button @click="cancel" class="cancel" type="button" v-localization="{key: 'common.dictionary.buttons.cancel'}"></button>
				<button @click="save" type="button" v-localization="{key: 'common.dictionary.buttons.save'}"></button>
			</div>
		</div>
	</div>
</template>
<script>
import Control from '@/Components/Entity/control';
import EditorMixin from '@/Components/editor.mixins.js';
import addPreferenceMixin from '@/Components/AddPreference/add-preference.mixin';
import SyncProgress from '@/Components/Sync/sync-progress';
import { Search } from '@acx-xms/data-functions/dist';
import CreateRoomFromCrmMixin from '../create-room-from-crm.mixins';
import crmSyncDataProvider, { GetCrmSetting } from '@/Data/DataProviders/crmSyncDataProvider.js';
export default {
	name: 'create-collaborationroom-from-crm',
	props: { options: Object },
	components: {
		Control,
		SyncProgress
	},
	mixins: [EditorMixin, CreateRoomFromCrmMixin, addPreferenceMixin],
	data() {
		return {
			editorTemplate: null,
			userListTemplate: null,
			evaluationContext: null,
			contacts: [],
			logicalName: 'collaborationroom',
			contactLookupOptions: null,
			storeState: null,
			preferenceDefaultValue: null
		};
	},

	async created() {
		this.editorSubType = 'edit-room-name';
		this.progress = {
			processed: 0,
			message: await this.localization.getLabelForCurrentLanguage('CreateCollaborationRoom.progress.message'),
			title: await this.localization.getLabelForCurrentLanguage('CreateCollaborationRoom.progress.title'),
			total: 0
		};
		this.contactLookupOptions = await this.getContactLookupWrapperOptions();
	},
	methods: {
		async getContactFilteredDefaultValue() { // remove after SF update
			const crmSetting = await GetCrmSetting();
			const crmNamespace = crmSetting.Total ? crmSetting.Results[0].Source.crmnamespace : '';

			crmSyncDataProvider.pluginName = 'sync';
			const searchRequest =
				{
					entity: `${crmNamespace}__Deal__c`,
					fields: [
						`${crmNamespace}__ClientContact__c`
					],
					criteria: {
						conditions: [
							{
								field: 'Id',
								operator: 'eq',
								value: this.options.sfdealcrmid
							}
						]
					}
				};
			let sfcrmid;
			try {
				const crmContact = await crmSyncDataProvider.proxy('api/sf/search', searchRequest);
				if (!crmContact.length) {
					return null;
				}
				sfcrmid = crmContact[0][`${crmNamespace}__ClientContact__c`];
				if (!sfcrmid) {
					return null;
				}
			} catch {
				return null;
			}
			const filters = [
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('termFacet.filter', {
					logicalName: 'sfcrmid',
					query: [sfcrmid],
					negative: false
				}).fillQuery(),
				sc.classes.get('search.relation', {
					entity: 'preference',
					alias: 'preference',
					filter: [sc.classes.get('termFacet.filter', {
						logicalName: 'hascollaborationroom',
						query: [false]
					}).fillQuery()],
					output: 'item'
				}).toFilter(),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'contactsystemuseridsystemuser.avatarid.id' },
					{ logicalname: 'recordstate.id' },
					{ logicalname: 'salutation' },
					{ logicalname: 'firstname' },
					{ logicalname: 'lastname' },
					{ logicalname: 'account' },
					{ logicalname: 'jobtitle' },
					{ logicalname: 'contactid' }
				]).fillQuery()
			];
			const res = await Search(['contact'], filters);
			if (res.Results.length !== 0) {
				this.selectedContact = {
					displayName: res.Results[0].Name,
					data: res.Results[0]
				};
			}
		},
		async getContactLookupWrapperOptions() {
			const defaultFilteredValue = await this.getContactFilteredDefaultValue();
			return {
				field: 'preferencecontact',
				entity: this.entity,
				defaultFilteredValue,
				lookupOptions: this.getContactLookupOptions()
			};
		},
		async validation() {
			const validationResult = await this.validate(this.stateKey);
			let error = null;
			if (!validationResult.isValid) {
				error = validationResult.message;
				return error;
			}
			const selectedPref = this.selectedPreference && this.selectedPreference.data;
			if (selectedPref && selectedPref.Source && selectedPref.Source.hascollaborationroom) {
				const { Results: crResults } = await Search(['collaborationroom'],
					[
						sc.classes.get('offsetSize.filter', 1),
						sc.classes.get('termFacet.filter', {
							logicalName: 'collaborationroomid',
							query: [selectedPref.Source.collaborationroomid.id]
						}).fillQuery(),
						sc.classes.get('selectedFields.filter', [
							{ logicalname: 'name' },
							{ logicalname: 'collaborationroomowneridsystemuser.fullname' },
							{ logicalname: 'collaborationroomid' }
						]).fillQuery()
					]
				);
				const cr = crResults[0].Source;
				const crName = cr.name;
				const crOwner = cr.collaborationroomowneridsystemuser.fullname;
				const crid = cr.collaborationroomid;
				error = await this.localization.getLabelForCurrentLanguage('addPreferenceDialog.preference.alreadyused.error',
					[crid, crName, crOwner]);
				return error;
			}
			return error;
		},
		async save() {
			const validationError = await this.validation();

			if (validationError) {
				this.showValidationError(validationError);
				return;
			}

			const isRoomExist = await this.isRoomExist();
			if (isRoomExist) {
				return;
			}

			this.progress.total = this.contacts.length + 3;
			sc.events.emit('vue.progressDialog.open', this.progress);
			const editData = this.$store.state[this.stateKey].entityData;
			editData.sfdealcrmid = this.options.sfdealcrmid;
			const cr = await this.createRecord('collaborationroom', editData);
			const crEntityRef = sc.classes.get('entityReference', cr);
			this.parentRecord = crEntityRef;
			const promises = [this.createChat(cr).then(this.inviteParticipants)];
			if (this.selectedPreference) {
				promises.push(this.updateRecord('preference', this.selectedPreference.data.Id, {
					collaborationroomid: {
						id: cr.collaborationroomid,
						logicalname: 'collaborationroom'
					}
				}));
			} else {
				promises.push(this.createPreference(crEntityRef));
			}
			await Promise.all(promises)
				.catch((e) => sc.utils.errorMessage.byResponse(e));
			this.navigateToEntityDetails(crEntityRef);
		},
		createChat(cr) {
			const data = {
				collaborationroomid: {
					id: cr.collaborationroomid,
					logicalname: 'collaborationroom'
				},
				public: true,
				name: cr.name,
				teamid: cr.teamid,
				coownerteamid: cr.coownerteamid,
				brokerteamid: cr.brokerteamid
			};

			return this.createRecord('chat', data);
		},
		createPreference(crEntityRef) {
			const data = {
				name: crEntityRef.id,
				collaborationroomid: crEntityRef.toObject()
			};

			return this.createRecord('preference', data);
		}
	}
};
</script>
<style src="../create-room-from-crm.less" scoped></style>
