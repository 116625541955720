import router from '@/router';

export default {
    async enabled(options, context, selection) {
        return context.eval(options.enable);
    },
    async execute(options, context, selection) {
        let sel = selection[0].Type === 'marketingcampaignmember'
            ? selection[0].Source.marketingcampaignmembermarketingcampaignidmarketingcampaign
            : selection[0];

        router.push({
            name: 'email-campaign-details',
            params: {
                set: 'email-campaign',
                name: sel.name || sel.Name,
                id: sel.marketingcampaignid || sel.Id,
                refreshEvents: options.refreshEvents,
                ...((sel.status || sel.Source.status) === 'Draft' && { edit: 'edit' })
            }
        });
    }
};
