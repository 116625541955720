<template>
	<div class="userProfileMenu-wrapper">
		<div :disabled="enable" :class="['user-profile-component', isAnonymous && 'anonymous']">
			<div v-if="isAnonymous" class="component-header">
				<button v-data-attr @click="redirectToSignIn">
					Sign In
				</button>
			</div>
			<div v-if="!isAnonymous" class="component-header" v-data-attr>
				<div class="profile-image" @click="openProfilePopup">
					<svg v-if="!userProfilePhoto" class="svg-icon">
						<use :xlink:href="`#layout-icons-${profileIconDefault}`" :class="profileIconDefault" />
					</svg>
					<div v-if="userProfilePhoto" class="image-node avatar" :style="{ 'background-image': `url(${userProfilePhoto})` }" />
				</div>
				<div :class="['openbtn-wrapper', menuState]" @click="openProfilePopup">
					<svg class="svg-icon">
						<use :xlink:href="`#layout-icons-${openProfileIcon}`" :class="openProfileIcon" />
					</svg>
				</div>
			</div>
			<div v-if="menuState == 'expanded'" :class="['user-profile-popup', menuState]">
				<div class="top-right-triangle" />
				<div class="header">
					<span class="h2">
						{{ fullName }}
					</span>
				</div>
				<div class="content">
					<MenuUserprofileContent
						:currentLanguage="currentLanguage"
						:currentCurrency="currentCurrency"
						:currentSom="currentSom"
					/>
					<slot />
				</div>
				<div class="footer">
					<div class="bunttons-container">
						<div class="signout-component">
							<a class="signout-button button clickable" @click="logout">
								<i class="layout-icons-signout"></i>
								<span>Sign Out</span>
							</a>
						</div>
						<div class="information">
							<p class="sc-version clickable" @click="openVersionInfoDialog">Information</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import '@/Bindings/ui-attribute.directive';
import MenuUserprofileMixin from './menu-userprofile.mixins';
import MenuUserprofileContent from './menu-userprofile-content';
import { Init as avatarInit } from '@/Data/avatar-service';


export default {
	name: 'menu-userprofile',
	mixins: [MenuUserprofileMixin],
	components: { MenuUserprofileContent },
	methods: {
		async openVersionInfoDialog() {
			sc.events.emit('vue.dialog.open', {
				title: await sc.classes.get('localization.dataProvider').localize('dialogues.versionInfo.dialog.title'),
				component: 'version-info-dialog',
				maximizedWidth: '20%',
				minimumWidth: '300',
				allowPin: false,
				allowMinimize: false
			});
		},
		openProfilePopup() {
			this.toggleMenu();

			if (this.menuState === 'expanded') {
				this.outsideClickListener = $('body').on('click', (event) => {
					if (!$(event.target).closest('.user-profile-component').length) {
						this.closeMenu();
						this.outsideClickListener.off();
					}
				});
			}
		}
	},
	computed: {
		openProfileIcon() {
			return this.menuState === 'collapsed' ? this.btnIcons.down : this.btnIcons.up;
		}
	},
	async created() {
		this.btnIcons = {
			down: 'ms-arrow-down',
			up: 'ms-arrow-up'
		};

		await avatarInit();
	}
};
</script>
<style src="./menu-userprofile.less" scoped></style>
