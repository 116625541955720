import { isActive, checkPrivilege } from './Methods/action.methods';
import { isUndefined } from '@acx-xms/data-functions/dist';

export default {
	async enabled(options, evaluationContext, selection) {
		if (selection && selection.length === 1) {
			const active = await isActive(evaluationContext, selection);

			if (!active) {
				return false;
			}
			const staticlistEntityLogicalName = evaluationContext.eval(options.staticlistEntityLogicalName || 'awx_adhoclist');
			const item = sc.classes.get('entityReference', selection[0]);

			if (item.logicalname !== staticlistEntityLogicalName) {
				return false;
			}
			return await checkPrivilege(item.logicalname, 'Write');
		}

		return false;
	},
	execute(options, context, selection) {
		// TODO: rework dialogue and action
		sc.classes.get('logger.processor').track('Static lists: edit');

		this.memberTypeName = options.memberTypeName ? context.eval(options.memberTypeName) : 'Source.awx_membertypecodecode.Code';
		const memberTypeCode = sc.utils.findProperty(selection[0], this.memberTypeName, true);

		const maximizedWidth = sc.classes.get('additionalParameters.controller').getValue('staticlist-edit-dialog-maximized-width', '90%');
		const maximizedHeight = sc.classes.get('additionalParameters.controller').getValue('staticlist-edit-dialog-maximized-height', '100%');

		sc.classes.get('staticLists.utils').getTypeMapping().done(function (mapping) {
			const displayName = isUndefined(memberTypeCode) ? '' : context.eval(mapping[memberTypeCode].pluralname);

			let title = '';
			sc.classes.get('localization.dataProvider')
				.getLabelForCurrentLanguage('staticListsPanel.editor.dialogTitle', [displayName]).then((text) => {
					title = text;
				});

			const dialogConfig = {
				component: 'entity.marketingList.editor',
				title,
				icon: 'entity-icons-awx_adhoclist-small',
				maximizedWidth,
				maximizedHeight,
				params: {
					entity: selection[0],
					memberSubtype: context.eval(options.memberSubtype),
					useTargetEntity: context.eval(options.useTargetEntity),
					targetEntityFilter: options.targetEntityFilter,
					membersOrder: options.membersOrder,
					allowSelection: options.allowSelection,
					defaultSearchFields: options.defaultSearchFields,
					lookupFilters: options.lookupFilters
				}
			};
			sc.events.emit('dialog.open', dialogConfig);
		});
	}
};
