var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "data-attr",
          rawName: "v-data-attr",
          value: { options: _vm.options },
          expression: "{ options }"
        }
      ],
      class: ["filter-info-panel open", { loading: _vm.isLoading }]
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("header", [
          _c(
            "div",
            { staticClass: "back-button", on: { click: _vm.closePanel } },
            [
              _c("svg", { staticClass: "svg-icon svg-15" }, [
                _c("use", {
                  staticClass: "action hover action-icons-back-button",
                  attrs: { "xlink:href": "#action-icons-back-button" }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "h5" }, [
            _vm._v("\n\t\t\t\t" + _vm._s(_vm.caption) + "\n\t\t\t")
          ]),
          _vm._v(" "),
          !_vm.options.disableResetFilter
            ? _c("div", { staticClass: "actions" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "data-attr",
                        rawName: "v-data-attr",
                        value: {
                          options: _vm.options,
                          stateNamespace: "clear"
                        },
                        expression: "{ options, stateNamespace: 'clear' }"
                      }
                    ],
                    staticClass: "action-wrapper filters-reset-hover-parent",
                    on: { click: _vm.clear }
                  },
                  [
                    _c("svg", { staticClass: "svg-icon svg-20" }, [
                      _c("use", {
                        staticClass: "action hover action-icons-filters-reset",
                        attrs: { "xlink:href": "#action-icons-filters-reset" }
                      })
                    ])
                  ]
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        !_vm.isLoading
          ? _c(
              "section",
              { staticClass: "body visible" },
              [
                _vm.innerComponent
                  ? _c(_vm.innerComponent, {
                      key: _vm.logicalName,
                      tag: "component",
                      staticClass: "filter-info-component",
                      attrs: {
                        options: _vm.options,
                        evaluationContext: _vm.evaluationContext,
                        stateNamespace: _vm.stateNamespace
                      },
                      on: {
                        filterError: function($event) {
                          _vm.$emit("filterError", $event)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "buttonsMenu" }, [
                  _vm.showApplyButton()
                    ? _c(
                        "button",
                        {
                          staticClass: "applyButton",
                          attrs: { type: "button" },
                          on: { click: _vm.apply }
                        },
                        [_vm._v("\n\t\t\t\t\tApply\n\t\t\t\t")]
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.isLoading ? _c("div", { staticClass: "loading-overlay" }) : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }