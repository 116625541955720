/* eslint no-fallthrough: ["error", { "commentPattern": "break[\\s\\w]*omitted" }] */
import Vue from 'vue';
import helperMethods from './../../Components/ComponentSet/component-set-evaluation-context-helper.methods';

export default {
	namespaced: true,
	state() {
		return {
			uiFilters: {},
			filters: {},
			order: {},
			defaultFilters: {},
			defaultUiFilters: {},
			selectedFilters: {},
			statistic: {},
			filterInfoPanelOptions: null,
			selection: {},
			selectionIds: {},
			searchQuery: null,
			searchQueryFilter: null,
			paginationParams: {},
			showFilterPanel: false,
			recordsToDeactivate: [],
			favorites: {},
			searchResults: {
				crlisting: {},
				cravailability: {},
				chatparticipant: {},
				listing: {},
				availability: {}
			},
			selectedcrResult: [],
			filtersReady: {},
			sharedSearchSelectionExport: null
		};
	},
	getters: {
		filtersReady(state) {
			return (logicalname) => {
				return !state.filtersReady[logicalname] || Object.values(state.filtersReady[logicalname]).every(val => Object.values(val).every(v => (v.mustWait && v.isReady) || !v.mustWait));
			};
		},
		getSelection(state) {
			return state.selection;
		},
		fieldFromSharedSelection(state) {
			return (entity, source, target, value, field) => {
				/*
					entity: target state entity
					source: source state entity
					target: source state entity target field
					value: value for search in source state
					field: field for search in source state
				*/
				return state.selection[entity].map(() => state.selection[source].find(el => el[field] === value)[target]);
			};
		},
		getSharedSearchSelectionExport(state) {
			return state.sharedSearchSelectionExport;
		},
		isRecordSelected(state) {
			return (record) => {
				return state.selectionIds[record.Type] && state.selectionIds[record.Type][record.Id];
			};
		},
		getInfoPanelOptions(state) {
			return state.filterInfoPanelOptions;
		},
		showFilterPanel(state) {
			return state.showFilterPanel;
		},
		getFilterValue(state) {
			const _getFilter = ({ entity, logicalName }) => {
				if (!state.filters[entity]) return null;
				return state.filters[entity][logicalName] || null;
			};
			const _getUiFilter = ({ entity, logicalName }) => {
				if (!state.uiFilters[entity]) return null;
				return state.uiFilters[entity][logicalName] || null;
			};
			return ({ entity, logicalName }) => {
				return _getUiFilter({
					entity,
					logicalName
				}) || _getFilter({
					entity,
					logicalName
				});
			};
		},
		getStatistic(state) {
			return (entity) => {
				return state.statistic[entity] || [];
			};
		},
		getFilters(state) {
			return (entity) => {
				return Object.values(state.filters[entity] || {}).filter((f) => { return f; });
			};
		},
		getUiFilters(state) {
			return (entity) => {
				return Object.values(state.uiFilters[entity] || {}).filter((f) => { return f; });
			};
		},
		getOrder(state) {
			return (entity) => {
				return state.order[entity];
			};
		},
		getSelectedFilters(state) {
			return (entity) => {
				return state.selectedFilters[entity] || [];
			};
		},
		getSearchQuery(state) {
			return state.searchQuery;
		},
		getSearchQueryFilter(state) {
			return state.searchQueryFilter;
		},
		getFiltersRaw(state) {
			return (entity) => {
				if (entity) {
					return state.filters[entity];
				}
				return state.filters;
			};
		},
		getUiFiltersRaw(state) {
			return (entity) => {
				return state.uiFilters[entity];
			};
		},
		getPaginationParams(state) {
			return state.paginationParams;
		},
		isFavorite(state) {
			return (id) => {
				return state.favorites[id];
			};
		},
		filtersChanged(state) {
			if (state.filters || state.uiFilters) {
				const defaultFilters = state.defaultFilters ? JSON.parse(JSON.stringify(state.defaultFilters)) : {};
				const defaultUiFilters = state.defaultUiFilters ? JSON.parse(JSON.stringify(state.defaultUiFilters)) : {};
				if (JSON.stringify(defaultFilters) !== JSON.stringify(state.filters) || JSON.stringify(defaultUiFilters) !== JSON.stringify(state.uiFilters)) {
					return true;
				}
			}
			return false;
		},
		getSearchResults(state) {
			return (key) => {
				return state.searchResults[key];
			};
		},
		isRecordPreselected(state) {
			return (record) => {
				switch (record.Type) {
				case 'listing': {
					const crlisting = state.selectedcrResult.find(item => item.listingid === record.Id);
					if (crlisting) {
						return record.Source.availabilities.length
							? checkListingWithAvailabilities(crlisting)
							: 'readonlyPreselected';
					}
					// break omitted
				}
				case 'availability': {
					if (state.selectedcrResult.some(item => item.availabilities
						.some(availabilityid => availabilityid === record.Id)
					)) {
						return 'readonlyPreselected';
					}
					// break omitted
				}
				default: {
					return 'default';
				}
				}

				function checkListingWithAvailabilities(crlisting) {
					const searchResultsAvailability = state.searchResults.availability[record.Id];
					let filteredCrAvailabilities = [];
					if (searchResultsAvailability) {
						filteredCrAvailabilities = crlisting.availabilities.filter(availability =>
							searchResultsAvailability.some(searchResultItem => searchResultItem.Id === availability)
						);
					}
					if (!filteredCrAvailabilities.length) return 'partiallySelected';
					return filteredCrAvailabilities.length < searchResultsAvailability.length ? 'partiallySelected' : 'readonlyPreselected';
				}
			};
		}
	},
	mutations: {
		fillFiltersReady(state, data) {
			data.filters.forEach(filter => {
				if (!state.filtersReady[data.entity]) {
					Vue.set(state.filtersReady, data.entity, {});
				}
				if (!state.filtersReady[data.entity][data.filterName]) {
					Vue.set(state.filtersReady[data.entity], data.filterName, {});
				}
				Vue.set(state.filtersReady[data.entity][data.filterName], filter, {
					isReady: false,
					mustWait: true
				});
			});
		},
		setFilterReady(state, data) {
			Vue.set(state.filtersReady[data.entity][data.filterName][data.logicalName], 'isReady', data.value);
		},
		setFilterWait(state, data) {
			Vue.set(state.filtersReady[data.entity][data.filterName][data.logicalName], 'mustWait', data.value);
		},
		setSharedSearchSelectionExport(state, data) {
			state.sharedSearchSelectionExport = data;
		},
		addOrUpdateFilter(state, data) {
			if (!state.filters[data.entity]) { Vue.set(state.filters, data.entity, {}); }
			Vue.set(state.filters[data.entity], data.logicalName, data.filter);
		},
		addOrUpdateUiFilter(state, data) {
			if (!state.uiFilters[data.entity]) { Vue.set(state.uiFilters, data.entity, {}); }
			Vue.set(state.uiFilters[data.entity], data.logicalName, data.filter);
		},
		addDefaultFilter(state, data) {
			if (!state.defaultFilters[data.entity]) { Vue.set(state.defaultFilters, data.entity, {}); }
			Vue.set(state.defaultFilters[data.entity], data.logicalName, data.filter);
		},
		addDefaultUiFilter(state, data) {
			if (!state.defaultUiFilters[data.entity]) { Vue.set(state.defaultUiFilters, data.entity, {}); }
			Vue.set(state.defaultUiFilters[data.entity], data.logicalName, data.filter);
		},
		resetDefaultFilters(state, data) {
			Vue.set(state.defaultFilters, data.entity, {});
			Vue.set(state.defaultUiFilters, data.entity, {});
		},
		addOrUpdateOrder(state, data) {
			Vue.set(state.order, data.entity, data.filter);
		},
		setRecordsToDeactivate(state, recordsToDeactivate) {
			state.recordsToDeactivate = state.recordsToDeactivate.concat(recordsToDeactivate);
		},
		excludeRecordsToDeactivate(state, exludedIds) {
			if (exludedIds.length) {
				state.recordsToDeactivate = state.recordsToDeactivate.filter(id => !exludedIds.includes(id));
			}
		},
		resetFilters(state) {
			if (state.filters) {
				const filters = state.defaultFilters ? JSON.parse(JSON.stringify(state.defaultFilters)) : {};
				Vue.set(state, 'filters', filters);
			}
			if (state.uiFilters) {
				const filters = state.defaultUiFilters ? JSON.parse(JSON.stringify(state.defaultUiFilters)) : {};
				Vue.set(state, 'uiFilters', filters);
			}
			if ('sharedSearch' in state.searchResults.listing) {
				delete state.searchResults.listing.sharedSearch;
				state.sharedSearchSelectionExport = null;
			}
		},
		deleteSingleFilter(state, data) {
			delete state.filters[data.entity][data.logicalName];
		},
		replaceFilters(state, { entity, filters }) {
			if (state.filters) {
				Vue.set(state.filters, entity, filters);
			}
		},
		replaceUiFilters(state, { entity, uiFilters }) {
			if (state.uiFilters) {
				Vue.set(state.uiFilters, entity, uiFilters);
			}
		},
		replaceAllFilters(state, { entity, filters }) {
			if (state.filters) {
				Vue.set(state.filters, entity, filters);
			}
		},
		setStatistic(state, { entity, statistic }) {
			state.statistic[entity] = statistic;
		},
		registerSelectedFilter(state, { entities, filter }) {
			entities.forEach((entity) => {
				if (!state.selectedFilters[entity]) Vue.set(state.selectedFilters, entity, []);
				const existing = state.selectedFilters[entity].filter((f) => {
					// data could be anything, so need to stringify
					return JSON.stringify(f.data) === JSON.stringify(filter.data);
				});
				if (existing.length === 0) {
					state.selectedFilters[entity].push(filter);
				}
			});
		},
		openInfoPanel(state, options) {
			const newFilterLogicalName = options ? helperMethods.eval(null, options.logicalName) : null;
			const oldFilterLogicalName = state.filterInfoPanelOptions ? helperMethods.eval(null, state.filterInfoPanelOptions.logicalName) : null;
			state.filterInfoPanelOptions = newFilterLogicalName !== oldFilterLogicalName ? options : null;
		},
		changeSelection(state, options) {
			let types = options.records.map(record => record.Type);
			types = Array.from(new Set(types));
			types.forEach((type) => {
				if (!state.selection[type]) Vue.set(state.selection, type, []);
				if (!state.selectionIds[type]) Vue.set(state.selectionIds, type, {});

				const records = options.records.filter(record => record.Type === type);
				records.forEach((record) => {
					if (options.select) {
						// todo record refresh needed here
						if (!state.selectionIds[type][record.Id]) {
							Vue.set(state.selectionIds[type], record.Id, record);
							state.selection[type].push(record);
						}
					} else {
						Vue.delete(state.selectionIds[type], record.Id);

						const targetIndex = state.selection[type].findIndex((item) => { return item.Id === record.Id; });
						if (targetIndex >= 0) {
							state.selection[type].splice(targetIndex, 1);
						}
					}
				});
			});
		},
		cleanSelection(state) {
			state.selection = {};
			state.selectionIds = {};
		},
		setSearchQuery(state, queryFilter) {
			Vue.set(state, 'searchQuery', queryFilter);
		},
		setSearchQueryFilter(state, queryFilter) {
			Vue.set(state, 'searchQueryFilter', queryFilter);
		},
		setPaginationParams(state, params) {
			Vue.set(state, 'paginationParams', params);
		},
		toggleFiltersPanel(state, force) {
			state.showFilterPanel = force !== undefined ? force : !state.showFilterPanel;
		},
		addToFavorites(state, { id, value }) {
			value ? Vue.set(state.favorites, id, value) : Vue.delete(state.favorites, id);
		},
		setSearchResults(state, { key, nestedKey, value }) {
			Vue.set(state.searchResults[key], nestedKey, value);
		},
		updateSharedSearchSelectionExport(state, data) {
			Vue.set(state.searchResults.listing, 'sharedSearch', data);
		},
		clearSearchResults(state) {
			Object.keys(state.searchResults).forEach(key => {
				Vue.set(state.searchResults, key, {});
			});
		},
		setSelectedcrResult(state, data) {
			Vue.set(state, 'selectedcrResult', data);
		}
	}
};
