export default {
	parameters(options) {
		return [
			this.scope,
			options.name,
			this.eval(options.scopeVariable),
			options.value,
			this.eval
		];
	},
	async parametersAsync(options) {
		return [
			this.scope,
			options.name,
			await this.evalAsync(options.scopeVariable),
			options.value,
			this.eval

		];
	},
	evaluate(scopes, name, scope, inner, builder) {
		scopes.queue(name, scope);
		const result = builder(inner);
		scopes.dequeue(name);
		return result;
	}
};
