import { isActive } from './Methods/action.methods';
import { isArray } from '@acx-xms/data-functions/dist';

export default {
	async enabled(options, evaluationContext, selection) {
		return selection && selection.length === 1 && await isActive(evaluationContext, selection);
	},
	async execute(options, context, selection) {
		const localizations = sc.classes.get('localization.dataProvider');
		let parentRecord = context.eval(options.parentRecordRef) || selection;
		if (isArray(parentRecord)) {
			parentRecord = parentRecord[0];
		}
		let defaultChat = context;
		if (isArray(defaultChat)) {
			defaultChat = defaultChat[0];
		}

		sc.events.emit('vue.dialog.open', {
			title: await localizations.getLabelForCurrentLanguage('inviteParticipantsPlugin.title'),
			component: 'invite.participants.dialog',
			evaluationContext: context,
			maximizedWidth: '90%',
			maximizedHeight: '100%',
			importAction: options.importAction,
			refreshNamespace: context.eval(options.refreshNamespace),
			multiGroup: !!context.eval(options.multiGroup),
			lookupFilters: options.lookupFilters,
			showSkip: context.eval(options.showSkip),
			userEntity: context.eval(options.userEntity),
			userListTemplate: options.userListTemplate,
			userLookupTemplate: options.userLookupTemplate,
			parentRecord,
			defaultChat,
			selectedGroup: context.eval(options.selectedGroup || null)
		});
	}
};
