let localizations;

export default {
	parameters(options) {
		console.error('localizedLabel must be awaited');
	},
	async parametersAsync(options) {
		if (!localizations) {
			localizations = sc.classes.get('localization.dataProvider');
		}
		return [await localizations.getLabel(sc.UILanguage.code, options.key)];
	},
	evaluate(label) {
		return label;
	}
};
