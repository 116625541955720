var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.withCheckAll
    ? _c(
        "div",
        {
          directives: [
            {
              name: "data-attr",
              rawName: "v-data-attr",
              value: {
                options: _vm.$parent.options,
                stateNamespace: "select-all-" + _vm.checkAllState
              },
              expression:
                "{ options: $parent.options, stateNamespace: `select-all-${checkAllState}` }"
            }
          ],
          staticClass: "checkbox-wrapper toggle-all",
          on: { click: _vm.onSelectAll }
        },
        [
          _c(
            "div",
            { class: ["virtual-checkbox clickable", _vm.checkAllState] },
            [
              _c("svg", { staticClass: "svg-icon" }, [
                _c("use", {
                  class: _vm.iconClasses,
                  attrs: { "xlink:href": _vm.icon }
                })
              ])
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }