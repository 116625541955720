var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.prepareComponentName(_vm.options.component.$type),
    _vm._b(
      { tag: "component" },
      "component",
      {
        options: _vm.options.component,
        evaluationContext: _vm.evaluationContext
      },
      false
    ),
    [
      _vm._t("title-slot", null, { slot: "title-slot" }),
      _vm._v(" "),
      _vm._t("toggle-slot", null, { slot: "toggle-slot" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }