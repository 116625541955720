var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "theme-override_modern-plugins layout-marketspace layout-blank",
      attrs: { id: "page-wrapper" }
    },
    [
      _c(_vm.componentName, { tag: "component" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialogmanager-footer" },
        [_c("old-dialog-manager"), _vm._v(" "), _c("dialog-manager")],
        1
      ),
      _vm._v(" "),
      _c("absolute-menu-popup"),
      _vm._v(" "),
      _c("absolute-template-popup")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }