var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "data-attr",
          rawName: "v-data-attr",
          value: { options: _vm.options },
          expression: "{ options }"
        }
      ],
      class: ["menu-item clickable", _vm.displayStyle]
    },
    [
      _c("span", { staticClass: "icon-wrapper" }, [
        _vm.icon
          ? _c("svg", { staticClass: "svg-icon svg-20" }, [
              _c("use", {
                class: _vm.icon,
                attrs: { "xlink:href": "#action-icons-" + _vm.icon }
              }),
              _vm._v(" "),
              _c("use", {
                class: _vm.icon + "-border",
                attrs: { "xlink:href": "#action-icons-" + _vm.icon + "-border" }
              }),
              _vm._v(" "),
              _c("use", {
                class: _vm.icon + "-border",
                attrs: {
                  "xlink:href": "#action-icons-" + _vm.icon + "-partially"
                }
              })
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.text
        ? _c("span", { staticClass: "title" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.text) + "\n\t")
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }