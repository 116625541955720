<template>
	<div class="multiline-label" v-if="visible">
		<div class="label-wrapper" :class="cssClass">
			<div class="stripped-text-wrapper min-width" :class="{'full-text': showMore, 'preserve': preserveWhiteSpace}">
				<div class="stripped-text-label">{{text}}</div>
				<div class="icon-wrapper more-text" v-if="!showMore" @click="showMoreAction">
					<svg class='svg-icon layout-icons-letter-more-grey'>
						<use xlink:href='#layout-icons-letter-more-grey'></use>
					</svg>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'control-multilinelabel',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return { showMore: false };
	},
	created() {
		this.text = this.evaluationContext.eval(this.options.text);
		this.cssClass = this.options.cssClass ? this.evaluationContext.eval(this.options.cssClass) : [];
		this.visible = this.options.visible !== void 0 ? this.evaluationContext.eval(this.options.visible) : true;
		this.preserveWhiteSpace = this.options.preserveWhiteSpace !== void 0 ? this.evaluationContext.eval(this.options.preserveWhiteSpace) : false;
	},
	methods: {
		showMoreAction() {
			this.showMore = true;
		}
	}
};
</script>
<style src="./multiline-label.less" scoped></style>
