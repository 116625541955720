var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.results.length > 0
    ? _c("div", { staticClass: "component-innersearch-results base" }, [
        _c("div", { staticClass: "content-wrapper" }, [
          _c("div", { staticClass: "content" }, [
            _vm.isMounted
              ? _c(_vm.prepareComponentName(_vm.content.$type), {
                  tag: "div",
                  staticClass: "content-inherit-height",
                  attrs: {
                    options: _vm.content,
                    cssClass: "inner-search-results-base",
                    results: _vm.results
                  }
                })
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "loading-wrapper"
          },
          [_c("div", { staticClass: "loading-overlay" })]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }