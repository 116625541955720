import ExpressionField from './field';
import ExpressionHasCRMRole from './hasCRMRole';
import ExpressionIsRecordOwner from './isRecordOwner';
import ExpressionNull from './null';
import ExpressionQueryFirstResult from './queryFirstResult';
import ExpressionScope from './scope';
import ExpressionScopeAccessor from './scopeAccessor';
import ExpressionSource from './source';
import ExpressionValue from './value';
import ExpressionUser from './user';

export default {
	resolve() {
		return {
			'expression.field': ExpressionField,
			'expression.hasCRMRole': ExpressionHasCRMRole,
			'expression.isRecordOwner': ExpressionIsRecordOwner,
			'expression.null': ExpressionNull,
			'expression.queryFirstResult': ExpressionQueryFirstResult,
			'expression.scope': ExpressionScope,
			'expression.scopeAccessor': ExpressionScopeAccessor,
			'expression.source': ExpressionSource,
			'expression.value': ExpressionValue,
			'expression.user': ExpressionUser
		};
	}
};
