import publishToListingPortalBase from './Methods/action.methods.publishToListingPortalBase';

export default {
	async enabled(options, evaluationContext, selection, fullSelection) {
		if (fullSelection && fullSelection.listing) {
			selection = fullSelection.listing;
		}
		const selectedRecords = publishToListingPortalBase.getSelectedRecords(evaluationContext, selection) || [];
		const isEnabled = await publishToListingPortalBase.enabled(options, evaluationContext, selection);

		return isEnabled && selectedRecords.some(x => !x.Source.publishedtolistingportal);
	},
	async execute(options, context, selection, fullSelection) {
		if (fullSelection && fullSelection.listing) {
			selection = fullSelection.listing;
		}

		await publishToListingPortalBase.execute(options, context, selection, true);
	}
};
