<template>
	<div class="current-rating" @click="setRating" :class="[cssClass, {'clicked': clicked}]">
		<svg class="svg-icon" v-if="!isLoading">
			<use :xlink:href="'#layout-icons-'+ icon"></use>
		</svg>
		<div class="loading-wrapper" v-else>
			<svg class='svg-icon'>
				<use xlink:href='#layout-icons-favorite-spinner' class='layout-icons-favorite-spinner'></use>
			</svg>
		</div>
	</div>
</template>
<script>
import {
	Create, Update, Search
} from '@acx-xms/data-functions/dist';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import { Get as GetItem } from '@/Data/DataProviders/userStorageDataProvider';

export default {
	name: 'control-rating',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			icon: this.evaluationContext.eval(this.options.icon),
			clicked: false,
			isLoading: false
		};
	},
	created() {
		this.cssClass = this.evaluationContext.eval(this.options.cssClass);
		this.entityRef = this.evaluationContext.eval(this.options.entityRef);
		this.refreshEvent = this.evaluationContext.eval(this.options.refreshEvent);
		this.scrollNamespace = this.evaluationContext.eval(this.options.scrollNamespace);
	},
	methods: {
		async updateRating(val) {
			this.isLoading = true;
			const getChatsPromise = Search(['chat'], [
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('termFacet.filter', {
					logicalName: 'collaborationroomid.id',
					query: [this.evaluationContext.eval({
						$type: 'expression.field',
						name: 'collaborationroomid.id',
						schema: 'source'
					})]
				}).fillQuery(),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'chatparticipants.chatparticipantsystemuseridsystemuser.systemuserid' },
					{ logicalname: 'chatparticipants.name' },
					{ logicalname: 'chatparticipants.chatid' },
					{ logicalname: 'chatparticipants.contactid' },
					{ logicalname: 'chatparticipants.nonregistered' },
					{ logicalname: 'chatparticipants.chatparticipantcontactidcontact.fullname' }
				]).fillQuery()
			]);
			const updatePromise = Update(this.entityRef.logicalname, this.entityRef.id, { rating: val });
			const [{ Results: chats }, { systemuserid }] = await Promise.all([getChatsPromise, GetUserInfo(), updatePromise]);
			const lspath = `${this.$route.params.layout}_${this.$route.params.type}_${this.$route.params.id}`;
			const userName = await GetItem(lspath);
			const chatparticipants = chats[0].Source.chatparticipants.filter(participant =>
				participant.chatparticipantsystemuseridsystemuser && (participant.chatparticipantsystemuseridsystemuser.systemuserid === systemuserid) && ((participant.name === userName) || !userName)
			);
			const participant = chatparticipants[0];
			const messageBody = {
				type: 'chatmessage',
				messagetext: `has set interest rating for Listing ${this.evaluationContext.entity.name}`,
				chatid: participant.chatid,
				contactid: participant.contactid,
				contactname: participant.nonregistered !== true ? participant.chatparticipantcontactidcontact.fullname : participant.name,
				messagetype: 'Action',
				visibleto: 'All Users',
				createdonfromui: new Date()
			};
			await Create('chatmessage', messageBody);
			this.clicked = false;
			this.isLoading = false;
			this.refreshEvent && this.$root.$emit(this.refreshEvent);
		},
		setRating() {
			this.clicked = true;
			this.$root.$emit('templatePopup.open', this.$el, 'rating', {
				name: 'rating-tooltip',
				params: {
					offsetLeft: 0,
					offsetTop: 0,
					updateRating: this.updateRating
				},
				wrapperClass: 'dropdown-wrapper'
			}, 'mousedown');
			this.$root.$once('templatePopup.closed.rating', () => {
				this.clicked = false;
			});
			this.scrollNamespace && this.$root.$once(this.scrollNamespace + '.scrolled', () => {
				this.$root.$emit('templatePopup.close.rating');
				this.clicked = false;
			});
		}
	}
};
</script>
<style src="./rating.less" scoped></style>
