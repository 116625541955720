<template>
	<div id="page-wrapper" class="layout-marketspace">
		<div class="marketspace-layout-mobile-wrapper">
			<div class="marketspace-menu-wrapper" v-if="!isAnonymous">
				<mobile-menu-marketspace	:items="menu.menu.item"
											:state="mainMenuState"
											:userProfileMenu="userProfileMenu"
											:isAnonymous="isAnonymous"
											@route-clicked="switchMenuState"
											:class="mainMenuState"
											:marketspaceHeaderMenu="marketspaceHeaderMenu" />
			</div>
			<div class="marketspace-menu-overlay" v-if="mainMenuState === 'iconsAndText'"></div>
			<div class="marketspace-anon-logo-wrapper" v-if="isAnonymous">
				<svg class='svg-icon'>
					<use xlink:href="#tenant-logo-anon-logo"></use>
				</svg>
			</div>
			<div class="marketspace-content-wrapper" v-bind:class="[mainMenuState,!isAnonymous?'hasMenu':'']">
				<div class="main-content-area adaptive">
					<div class="marketspace-components-area adaptive">
						<div v-if="ready" class="component">
							<router-view :key="$route.fullPath" :defaultRoute="defaultMenuItem"></router-view>
							<keep-alive include="mobile-route-search-results">
								<router-view :key="$route.fullPath" name="mobile" :defaultRoute="defaultMenuItem">
									<div slot="msMenu" class="collapse-menu-btn" @click="switchMenuState" v-if="!isAnonymous">
										<svg class='svg-icon'>
											<use xlink:href="#layout-icons-ms-btn-menu" class='icon-ms-btn-menu'></use>
										</svg>
									</div>
								</router-view>
							</keep-alive>
						</div>
					</div>
					<component	@closePanel="closeSidePanel"
								v-clickoutside="{ visible: sidePanelActive, callback: toggleSidePanel}"
								class="side-panel"
								v-bind="sidePanelOptions"
								:is="sidePanelComponent"
								v-if="sidePanelActive" />
				</div>
			</div>
			<div class="dialogmanager-footer">
				<old-dialog-manager></old-dialog-manager>
				<dialog-manager></dialog-manager>
			</div>
			<absolute-menu-popup></absolute-menu-popup>
			<absolute-template-popup></absolute-template-popup>
		</div>
	</div>
</template>
<script>
import MobileMenuMarketspace from '@/Components//Menu/menu-marketspace/mobile-menu-marketspace';
import MsConversationsPanel from '@/Components//ConversationsPanel/ms-conversations-panel';
import LayoutMarketspaceMixin from '@/Components/Layout/layout.marketspace.mixins';

export default {
	name: 'mobile-layout-marketspace',
	mixins: [LayoutMarketspaceMixin],
	components: {
		MobileMenuMarketspace,
		MsConversationsPanel
	},
	created() {
		this.mainMenuState = 'iconsOnly';
	},
	methods: {
		switchMenuState() {
			const newState = (this.mainMenuState === 'iconsOnly') ? 'iconsAndText' : 'iconsOnly';
			this.mainMenuState = newState;
			sc.classes.get('localStorage.dataProvider').set('mainMenuState', newState);
			if (this.mainMenuState === 'iconsAndText') {
				this.outsideClickListener = $('body').on('click', (event) => {
					if (!$(event.target).closest('.marketspace-menu-wrapper').length && !$(event.target).closest('.collapse-menu-btn').length) {
						this.outsideClickListener.off();
						this.closeMenu();
					}
				});
			}
		},
		closeMenu() {
			this.mainMenuState = 'iconsOnly';
			sc.classes.get('localStorage.dataProvider').set('mainMenuState', this.mainMenuState);
		}
	}
};
</script>
<style src="./mobile-layout-marketspace.less" scoped></style>
<style src="@/Components/Layout/ui-elements.less" scoped></style>
