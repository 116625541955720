var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.views
    ? _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: (_vm.views || []).length > 1,
              expression: "(views || []).length > 1"
            }
          ],
          staticClass: "view-switchers-container"
        },
        _vm._l(_vm.views, function(view, index) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "data-attr",
                  rawName: "v-data-attr",
                  value: {
                    options: _vm.$parent.options,
                    stateNamespace: view.title
                  },
                  expression:
                    "{ options: $parent.options, stateNamespace: view.title}"
                }
              ],
              key: view.title + "-" + index,
              class: [
                "switch-button clickable",
                { selected: view.name === _vm.selectedView.name }
              ],
              attrs: { title: view.title },
              on: {
                click: function($event) {
                  _vm.changeSelection(view.name)
                }
              }
            },
            [
              _c("svg", { staticClass: "svg-icon" }, [
                _c("use", {
                  class: "layout-icons-ms-view-" + view.viewIcon,
                  attrs: {
                    "xlink:href": "#layout-icons-ms-view-" + view.viewIcon
                  }
                })
              ])
            ]
          )
        })
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }