<template>
	<div class='email-campaign-step-root step'>
		<div :class="['wizard-step-header', { 'details': isDetails }]">
			<div class='wizard-step-header-title'>
				Preview
			</div>
			<div class='wizard-step-header-buttons'>
				<svg class='svg-icon' :class='{"svg-icon-disabled":templateProcess}' @click='refreshTemplate' v-if='!isDetails'>
					<use xlink:href='#action-icons-ec-wizard-refresh'></use>
				</svg>
				<svg class='svg-icon' :class='{"svg-icon-disabled":templateProcess}' @click='editTemplate' v-if='!isDetails'>
					<use xlink:href='#action-icons-ms-entity-edit'></use>
				</svg>
			</div>
		</div>
		<div :class="['wizard-step-content', { 'details': isDetails }]">
			<transition name='fade' mode='out-in'>
				<Loader v-if='templateProcess' :height='`100%`' :text='loaderText' />
				<div v-else-if='(!templateProcess && !hasArtifact)' class='preview-error'>
					<Error :text='errorText' :button='errorBtn' @click='regenerateArtifactAction'/>
				</div>
			</transition>
			<div id="preview-iframe-wrapper">
				<iframe frameborder="0" height="100%" ref="previewIframe" class="wizard-preview-iframe"></iframe>
			</div>
		</div>
	</div>
</template>

<script>
import { wizardMixin } from '@/Components/Wizard/Mixins/wizardMixin';
import { mapActions, mapState } from 'vuex';
import Loader from '../../Components/Loader/loader';
import Error from '../../Components/WizardError/wizardError';
export default {
	name: 'ec-step-five',
	mixins: [wizardMixin],
	components: {
		Loader,
		Error
	},
	data() {
		return {
			iframeHeight: 0,
			loading: true
		};
	},
	computed: {
		...mapState('wizard', ['templateProcess', 'steps', 'regenerateLater']),
		getFrameSrc() {
			return this.getStepConfig.frameSrc || null;
		},
		hasArtifact() {
			return this.steps.find(i => i.name === 'template').artifact;
		},
		loaderText() {
			return !this.hasArtifact ? 'Artifact generation is in progress. Please wait...' : 'Please wait...';
		},
		errorText() {
			if ((this.withEditMode || this.regenerateLater) && !this.templateProcess && !this.hasArtifact && this.isCollectionSelected) {
				return 'The publication doesn\'t seem to exist anymore';
			} else {
				return 'Please, select a Collection';
			}
		},
		errorBtn() {
			return { enable: ((this.withEditMode || this.regenerateLater) && !this.hasArtifact && this.isCollectionSelected) || false };
		}
	},
	methods: {
		...mapActions('wizard', ['actionTemplateHandler', 'updateRecordList', 'processCollectionForArtifact', 'regenerateArtifact', 'removeError']),
		editTemplate() {
			if (!this.templateProcess) this.actionTemplateHandler('edit');
		},
		refreshTemplate() {
			if (!this.templateProcess) {
				this.iframeHeight = 0;
				this.actionTemplateHandler('refresh');
			}
		},
		updateIframe(data) {
			const myFrame = this.$refs.previewIframe;
			/* styles for scrollbar */
			myFrame.srcdoc = data || '';
			myFrame.onload = () => {
				/* set body height like preview element height */
				myFrame.contentDocument.body.style.height = `${myFrame.scrollHeight}px`;
				/* add listener after iframe loaded */
				window.addEventListener('resize', this.iframeResizeHandler);
			};
		},
		iframeResizeHandler() {
			/* set body height like preview element height */
			this.$refs.previewIframe.contentDocument.body.style.height = `${this.$refs.previewIframe.scrollHeight}px`;
		},
		regenerateArtifactAction() {
			this.regenerateArtifact();
			this.removeError({
				name: 'artifactError',
				step: 'summary'
			});
		}
	},
	watch: {
		getFrameSrc(data) {
			this.updateIframe(data);
		}
	},
	mounted() {
		this.setNavigation({
			prev: true,
			next: false
		});
		this.$nextTick(function () {
			if (this.getFrameSrc) this.updateIframe(this.getFrameSrc);
		});
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.iframeResizeHandler);
	}
};
</script>

<style lang='less' scoped>
@import '../../Assets/Styles/default.less';
@import './preview.less';
</style>
