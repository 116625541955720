import coDataProvider from '@/Data/DataProviders/coDataprovider';


export default {
    async enabled(options, evaluationContext, selection) {
        return true;
    },
    execute(options, context, selection, fullSelection) {
        coDataProvider.openEntityDetails(selection[0].Id, selection[0].Type);
    }
};
