<template>
	<div :class="{ 'gallery-wrapper-details': isListingPortalDetails, 'gallery-wrapper': !isListingPortalDetails }">
		<div class="svg-top-icon-wrapper" v-if="isPrimary">
			<svg class="svg-icon svg-icon-top svg-12">
				<use xlink:href="#entity-icons-primary-user-crown"></use>
			</svg>
		</div>
		<image-gallery-small-base :currentIndex="currentIndex"
								  :showArrows="showArrows"
								  :images="images"
								  :reference="reference"
								  :placeholderEntityName="placeholderEntityName"
								  :isCustomImagePlaceholder="options.isCustomImagePlaceholder"
								  :galleryStylesObj="galleryStylesObj"
								  :isLinkToPrevent="isLinkToPrevent"
								  :showLink="showLink"
								  :alt="alt"
								  @next="next"
								  @prev="prev"
								  @click.native="emitOpenEvent"
								  @openDialog="openDialog">
		</image-gallery-small-base>
	</div>
</template>
<script>
import ImageGallerySmallMixin from './image-gallery-small.mixins.js';
export default {
	name: 'control-image-gallery-small',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			currentIndex: 0,
			showArrows: false,
			images: [],
			reference: null,
			placeholderEntityName: '',
			isLinkToPrevent: false,
			isListingPortalDetails: false,
			alt: '',
			isPrimary: false,
			showLink: false
		};
	},
	mixins: [ImageGallerySmallMixin],
	methods: {
			 updateImages() {
			const images = this.evaluationContext.eval(this.options.items) || [];
			this.setImages(images);
		},
		emitOpenEvent() {
			if (this.options.dataForLink) {
				const ref = this.evaluationContext.eval(this.options.dataForLink.entityRef);
				const namespace = this.evaluationContext.eval(this.options.dataForLink.namespace);
				this.$root.$emit(namespace + '.selectSingleItem', {
					ref,
					templateRef: this.options.dataForLink.templateRef || null
				});
			}
		}
	},
	async created() {
		await this.init();
		this.updateImages();
		this.showArrows = this.images.length > 1;
		this.isPrimary = this.options.isPrimary && this.evaluationContext.eval(this.options.isPrimary);
		this.isLinkToPrevent = this.options.layoutsToPreventLink
			? !!this.options.layoutsToPreventLink.find(item => this.$route.params.layout.indexOf(item) !== -1)
			: false;
		this.showLink = this.options.showLink;
		this.alt = this.options.alt && this.evaluationContext.eval(this.options.alt);
		this.isListingPortalDetails = this.options.isListingPortalDetails;
	}
};
</script>
<style scoped>
	.gallery-wrapper {
        height: 42%;
	}
	.gallery-wrapper .svg-top-icon-wrapper {
		padding: 0;
		top: 8px;
		right: 8px;
	}
	.gallery-wrapper-details {
		height: 100%;
	}
</style>
