import { isActive, checkPrivilege } from './Methods/action.methods';
import { isUndefined } from '@acx-xms/data-functions/dist';

export default {
	async enabled(o, evaluationContext, selection) {
		if (!await isActive(evaluationContext, selection)) {
			return false;
		}

		const def = $.Deferred();

		this.parentLogicalname = o.parentLogicalname ? evaluationContext.eval(o.parentLogicalname) : null;
		const childLogicalName = o.childLogicalName ? evaluationContext.eval(o.childLogicalName) : null;
		const componentSetName = evaluationContext.eval(o.componentsetName);
		const subtype = evaluationContext.eval(o.subtype);

		if (!this.parentLogicalname || !childLogicalName) def.resolve(false);

		const tmplPromise = sc.classes.get('entityConfiguration.dataProvider').getTemplate(this.parentLogicalname, 'edit', subtype);
		const privilegePromise = checkPrivilege(this.parentLogicalname, 'Create');
		const componentSetPromise = sc.classes.get('marketspaceComponents.dataProvider').getComponentSetById(componentSetName);
		$.when(tmplPromise, privilegePromise, componentSetPromise).then((template, isAllowed, componentset) => {
			this.template = template;
			this.addListingComponent = componentset;
			def.resolve(!isUndefined(template) && isAllowed && !isUndefined(componentset));
		});
		return def.promise();
	},
	async execute(options, context, selection, fullSelection) {
		let parameters = {
			entity: this.parentLogicalname,
			template: this.template,
			addListingComponent: this.addListingComponent,
			externalAspects: [],
			searchResultComponentName: options.searchResultComponentName,
			combinedNamespace: context.eval(options.namespace) + '.' + context.eval(options.internalNamespace)
		};
		const title = await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('CreateDealRoomPlugin.dialogTitle');

		if ((fullSelection && fullSelection.length) || selection.length) {
			const listingSelection = (fullSelection ? fullSelection.listing : selection)[0];
			parameters = {
				...parameters,
				dealroomListing: {
					id: listingSelection.Id,
					logicalname: listingSelection.Type
				},
				dealroomProperty: listingSelection.Source.propertyid,
				availabilitiesSelection: fullSelection ? fullSelection.availability : listingSelection.Source.availabilities
			};
		}

		sc.events.emit('vue.dialog.open', {
			component: 'create.dealroom.dialog',
			evaluationContext: context,
			logicalname: 'dealroom',
			maximizedWidth: '75%',
			maximizedHeight: '100%',
			title,
			params: parameters
		});
	}
};
