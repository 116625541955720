var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "smart-image-gallery-mobile",
      class: { "not-clickable": _vm.withExtendedGallery },
      style: { width: _vm.width, height: _vm.height },
      on: { click: _vm.openDialog }
    },
    [
      !_vm.parentEntityLogicalName && _vm.items.length == 0
        ? _c("div", { staticClass: "info-message" }, [
            _c("b", {
              staticClass: "info-message-title",
              attrs: {
                "data-bind":
                  "localization: { key: 'imageGallery.noDataMessage'}"
              }
            })
          ])
        : _vm.galleryOptions
        ? _c(
            "control-image-gallery-small-mobile",
            _vm._b(
              {},
              "control-image-gallery-small-mobile",
              {
                evaluationContext: _vm.evaluationContext,
                options: _vm.galleryOptions
              },
              false
            )
          )
        : _vm._e(),
      _vm._v(" "),
      _c("i", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading"
          }
        ],
        staticClass: "loading-overlay"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }