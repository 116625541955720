<template>
	<div
		v-show="pages.length || showWrapperIfEmpty"
		v-data-attr
		:class="['marketspace-pagination-wrapper', { 'no-pages': pages.length < 2 }]"
	>
		<div class="pagination">
			<div class="pagination-arrow prev" @click="previous">
				<div :class="['navigation-item', { disabled: isSelected(1) }]">
					<a class="pagination-item-arrow" rel="prev">
						<svg class="svg-icon svg-10">
							<use xlink:href="#layout-icons-ms-arrow-left" />
						</svg>
					</a>
					<a
						v-localization="{ key: 'common.dictionary.pagination.previous' }"
						v-data-attr
						class="text"
						rel="prev"
					/>
				</div>
			</div>
			<ul>
				<li
					v-for="(page, index) in pages"
					:key="`${page.number}-${index}`"
					@click.prevent.stop="selectPage(page.number)"
				>
					<span v-if="page.number == elipsis" class="navigation-item page-button disabled">
						{{ elipsis }}
					</span>
					<a
						v-else
						v-data-attr
						:href="getPaginationUrl(page)"
						:rel="rel(pages, page)"
						:class="['navigation-item page-button', { selected: page.selected }]"
					>
						{{ page.number }}
					</a>
				</li>
			</ul>
			<div class="pagination-arrow next" @click="next">
				<div :class="['navigation-item', { disabled: isSelected(lastPage) }]">
					<a
						v-localization="{ key: 'common.dictionary.pagination.next' }"
						v-data-attr
						class="text"
						rel="next"
					/>
					<a class="pagination-item-arrow" rel="next">
						<svg class="svg-icon svg-10">
							<use xlink:href="#layout-icons-ms-arrow-right" />
						</svg>
					</a>
				</div>
			</div>
			<div
				v-if="total"
				:style="{ visibility: showLoadMoreVisibility }"
				class="load-more-wrapper"
			>
				<button
					v-data-attr
					type="submit"
					@click="loadMore"
				>
					<span v-localization="{ key: 'mainLayout.loadMore' }" />
				</button>
			</div>
		</div>

		<div v-if="total" class="results-label">
			{{ label }}
		</div>
	</div>
</template>
<script>
import '@/Bindings/ui-attribute.directive';
import PaginationMixins from './pagination.mixins';

export default {
	name: 'pagination',
	mixins: [PaginationMixins]
};
</script>
<style src="./pagination.less" scoped />
