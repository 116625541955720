<template>
	<div class="marketspace-menu">
		<div class="user-profile-wrapper" v-show="state === 'iconsAndText'">
			<component :isAnonymous="isAnonymous" v-bind:params="{userProfileMenu}" :is="'mobile-' + userProfileMenusMap[userProfileMenu.$type]" />
		</div>
		<div class="items-container">
			<div class="items-wrapper" v-perfectscroll="{ enable: true }">
				<div class="items" v-for="(item,index) in items" @click="toggleMenu" :key="index">
					<route-menu-item-svg :class="state" v-bind:options="item" />
				</div>
				<template v-if="marketspaceHeaderMenu">
					<!--as we can not define opacity for css variables-->
					<div class="separator"></div>
					<div class="main-header-menu-wrapper">
						<menu-marketspace-header :item="marketspaceHeaderMenu.item" />
					</div>
				</template>
			</div>
		</div>
	</div>
</template>
<script>
import MobileMenuUserprofile from '../menu-marketspace-userprofile/mobile-menu-userprofile.vue';
import MobileMenuUserprofileWithViewprofile from '../menu-marketspace-userprofile/mobile-menu-userprofile-with-viewprofile.vue';
import RouteMenuItemSvg from './../Items/route-menu-item-svg.vue';
import MenuMarketspaceHeader from '@/Components/Menu/menu-marketspace-header/menu-marketspace-header.vue';

export default {
	name: 'mobile-menu-marketspace',

	props: {
		userProfileMenu: Object,
		items: Array,
		state: String,
		isAnonymous: Boolean,
		marketspaceHeaderMenu: Object
	},

	components: {
		RouteMenuItemSvg,
		MobileMenuUserprofile,
		MobileMenuUserprofileWithViewprofile,
		MenuMarketspaceHeader
	},

	methods: {
		toggleMenu() {
			this.$emit('route-clicked');
		}
	},

	created() {
		this.userProfileMenusMap = {
			'menu.userProfileMenuWithViewProfile': 'menu-userprofile-with-viewprofile',
			'menu.userProfileMenu': 'menu-userprofile'
		};
	}
};
</script>
<style src="./mobile-menu-marketspace.less" scoped></style>
