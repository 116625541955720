import { isNullOrUndefined } from '@acx-xms/data-functions/dist';
export default {
	parameters(options) {
		if (isNullOrUndefined(options.name)) {
			return [null];
		}
		const path = options.name.split('.');
		if (!!options.schema && options.schema !== 'root') {
			path.unshift(options.schema);
		}
		return [this.get(path)];
	},
	evaluate(field) {
		return field;
	}
};
