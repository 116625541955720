<template>
	<div class="field-group" v-show="visible" :class="cssClass">
		<h5 :class="titleCssClass" v-show="title">{{title}}</h5>
		<div class="field-group-content sc-grid" :class="'columns-' + options.columns">
			<div class="grid-cell" :class="'column-span-' + control.colspan" :style="{height: control.wrapperHeight || 'auto'}" v-for="(control,index) in options.control" :key="index">
				<control :name="control.$type" :contentProps="control" :evaluationContext="evaluationContext"></control>
			</div>
		</div>
		<div class="loading-overlay" v-show="isLoading"></div>
	</div>
</template>
<script>

export default {
	name: 'entitytemplate-fieldgroup',
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: { Control: () => import('./../Entity/control.vue') },
	data() {
		return {
			visible: this.options.visible !== void 0 ? this.evaluationContext.eval(this.options.visible) : true,
			// TODO: remove title if unused
			cssClass: (this.options.cssClass) ? this.evaluationContext.eval(this.options.cssClass) : '',
			titleCssClass: (this.options.titleCssClass) ? this.evaluationContext.eval(this.options.titleCssClass) : '',
			title: (this.options.title) ? this.evaluationContext.eval(this.options.title) : '',
			loadingNamespace: (this.options.loadingNamespace) ? this.evaluationContext.eval(this.options.loadingNamespace) : '',
			isLoading: false

		};
	},
	created() {
		if (this.loadingNamespace) {
			this.fork = sc.events.fork();
			this.fork.on(this.loadingNamespace, (isLoading) => {
				this.isLoading = isLoading;
			});
		}
		const localizationDataProvider = sc.classes.get('localization.dataProvider');
		localizationDataProvider.getLabelForCurrentLanguage('common.dictionary.fieldGroup.hide').then((text) => {
			this.hideText = text;
		});
		localizationDataProvider.getLabelForCurrentLanguage('common.dictionary.fieldGroup.viewAll').then((text) => {
			this.viewAllText = text;
		});
	},
	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
		}
	}
};
</script>
<style scoped>
	.field-group {
		position: relative;
	}

	.flex-field-group > .field-group-content {
		overflow: hidden;
		display: flex;
	}

		.flex-field-group > .field-group-content > .grid-cell:first-child {
			display: contents;
		}

		.flex-field-group > .field-group-content > .grid-cell:last-child {
			width: 100%;
			padding-left: 30px;
		}
</style>
