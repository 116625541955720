import { showToastOrInfoDialog, Delete } from '@acx-xms/data-functions/dist';

export default {
	async enabled(options, context, selection) {
		return true;
	},
	async execute(options, context, selection) {
		const refreshEvent = context.eval(options.refreshEvent);
		const loadingEvent = context.eval(options.loadingEvent);

		const emitLoading = (isLoading) => {
			loadingEvent && sc.events.emit(loadingEvent, isLoading);
		};

		try {
			emitLoading(true);
			await Delete(context.entity.logicalname, context.entity.id);
			refreshEvent && sc.events.emit(refreshEvent);
			await showToastOrInfoDialog({
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey)
			});
		} catch (err) {
			sc.utils.errorMessage.byResponse(err);
		}

		emitLoading(false);
	}
};
