var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.roomType
    ? _c(
        "div",
        { staticClass: "create-room-from-crm-wrapper" },
        [
          _vm.roomType === "dealroom"
            ? _c("CreateDealroomFromCrm", { attrs: { options: _vm.roomData } })
            : _vm.roomType === "collaborationroom"
            ? _c("CreateCollaborationroomFromCrm", {
                attrs: { options: _vm.roomData }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }