import {
	isActive, checkPrivilege, mapParametersObject
} from './Methods/action.methods';
import { isUndefined } from '@acx-xms/data-functions/dist';
// TODO: remove this action as it uses knockout dialogue
export default {
	async enabled(options, evaluationContext, selection) {
		const def = $.Deferred();
		if (selection && selection.length === 1) {
			if (!await isActive(evaluationContext, selection)) {
				return false;
			}

			const subtype = evaluationContext.eval(options.subtype);

			const item = sc.classes.get('entityReference', selection[0]);
			const tmplPromise = sc.classes.get('entityConfiguration.dataProvider').getTemplate(item.logicalname, 'edit', subtype);

			const privilegePromise = checkPrivilege(item.logicalname, 'Write');

			const entityConfigurationPromise = sc.classes.get('entityConfiguration.dataProvider').fetchEntity(item.logicalname);
			$.when(tmplPromise, privilegePromise, entityConfigurationPromise).then((template, isAllowed, entityConfig) => {
				if (!entityConfig) {
					def.resolve(false);
				}

				const inactiveStateCode = evaluationContext.eval((entityConfig.closeCompleteStatuses3 || {}).inactiveStateCode);

				if (!inactiveStateCode.id) {
					def.resolve(false);
				}

				const checkActive = function (item) {
					const recordstate = sc.utils.findProperty(item, 'source.recordstate', true);
					// check if it is undefined for the entities that don't have this field (e.g. systemuser)
					return recordstate === undefined || recordstate === null || recordstate.id !== inactiveStateCode.id;
				};

				const selectionHasSourceData = selection && selection.every(item => { return !!item.Source; });
				const isAllActive = selection && !selectionHasSourceData
					? true
					: selection.every(checkActive);

				def.resolve(!isUndefined(template) && isAllowed && isAllActive);
			});
		} else {
			def.resolve(false);
		}
		// return $.Deferred(function (def) { def.resolve(false); }).promise();
		return def.promise();
	},
	execute(options, context, selection) {
		if (selection && selection.length) {
			const parameters = mapParametersObject(options.parameter, context);
			if (!parameters.entity) parameters.entity = sc.classes.get('entityReference', selection[0]);
			parameters.subtype = context.eval(options.subtype);
			// methods.log(parameters.entity.logicalname || parameters.entity.Type);

			const dialogConfig = {
				component: 'entity.editor',
				params: parameters
			};
			if (context && context.$dialog && !parameters.isInTab) {
				context.$dialog.apply(dialogConfig);
			} else {
				sc.events.emit('dialog.open', dialogConfig);
			}
		}
	}
};
