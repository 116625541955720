<template>
	<component v-bind:is="prepareComponentName(options.component.$type)" v-bind="{options:options.component, evaluationContext:evaluationContext }">
		<slot name="title-slot" slot="title-slot" />
		<slot name="toggle-slot" slot="toggle-slot" />
	</component>
</template>
<script>
import ComponentSearchResults from '@/Components/ComponentSet/SearchResults/component-search-results';
import TabSearchResults from '@/Components/ComponentSet/SearchResults/tab-search-results/tab-search-results';
import ComponentSetComponentInnerSearchresults from '@/Components/ComponentSet/SearchResults/component-set-component-inner-search-results';
import ComponentSetComponentInnerSearchresultsBase from '@/Components/ComponentSet/SearchResults/component-set-component-inner-search-results-base';
import { prepareComponentName as prepCompName } from '@acx-xms/data-functions/dist';

export default {
	name: 'control-vuecontrol-wrapper',
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: {
		'component-set-componentinnersearchresults': ComponentSetComponentInnerSearchresults,
		'component-set-componentinnersearchresultbase': ComponentSetComponentInnerSearchresultsBase,
		'component-set-tabsearchresults': TabSearchResults,
		'component-set-componentsearchresults': ComponentSearchResults
	},
	data() {
		return {};
	},
	created() {
	},
	methods: {
		prepareComponentName(name) {
			return prepCompName(name);
		}
	}
};
</script>
