let defaultCurrency;
export default {
	parameters(options) {
		console.error('defaultSom must be awaited');
	},
	async parametersAsync(options) {
		if (!defaultCurrency) {
			defaultCurrency = sc.classes.get('uom.dataProvider').getStorageDefaultTransactionCurrency();
		}
		return [
			options.isEntityRef,
			await defaultCurrency
		];
	},
	evaluate(isref, currency) {
		if (isref && currency) {
			return sc.classes.get('entityReference', {
				id: currency.Id,
				name: currency.Name,
				logicalname: 'transactioncurrency'
			});
		} else {
			return currency;
		}
	}
};
