var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "li",
        {
          class: [
            "filter",
            {
              selected: _vm.inlineValue,
              open: _vm.isExpanded
            }
          ]
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "data-attr",
                  rawName: "v-data-attr",
                  value: {
                    options: _vm.options,
                    stateNamespace: _vm.options.displayName.value
                  },
                  expression:
                    "{ options, stateNamespace: options.displayName.value }"
                }
              ],
              class: [
                "filter-opener items-opener",
                { hasInlineView: _vm.hasInlineView }
              ],
              on: { click: _vm.openFilter }
            },
            [
              _c("span", { staticClass: "filter-name-truncate" }, [
                _c("span", [
                  _vm._v("\n\t\t\t\t" + _vm._s(_vm.displayName) + "\n\t\t\t")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "icon-wrapper" }, [
                _c("svg", { staticClass: "svg-icon svg-15" }, [
                  _c("use", {
                    staticClass: "hover layout-icons-filter-opener",
                    attrs: { "xlink:href": "#layout-icons-filter-opener" }
                  })
                ])
              ]),
              _vm._v(" "),
              _vm.inlineValue
                ? _c("p", { staticClass: "inline-text filter-name-truncate" }, [
                    _vm._v("\n\t\t\t" + _vm._s(_vm.asyncInlineValue) + "\n\t\t")
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _vm.inlineView
            ? _c(_vm.inlineView, {
                tag: "component",
                attrs: {
                  options: _vm.options,
                  stateNamespace: _vm.stateNamespace,
                  evaluationContext: _vm.evaluationContext
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }