var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "layout-marketspace-embedded",
      attrs: { id: "page-wrapper" }
    },
    [
      _c(
        "div",
        { staticClass: "marketspace-layout-wrapper" },
        [
          _c("div", { staticClass: "marketspace-content-wrapper" }, [
            _c("div", { staticClass: "main-content-area adaptive" }, [
              _c(
                "div",
                { staticClass: "marketspace-components-area adaptive" },
                [
                  _vm.ready
                    ? _c(
                        "div",
                        { staticClass: "component" },
                        [
                          _c("router-view", {
                            key: _vm.$route.path,
                            attrs: { defaultRoute: _vm.defaultMenuItem }
                          }),
                          _vm._v(" "),
                          _c(
                            "keep-alive",
                            { attrs: { include: "route-search-results" } },
                            [
                              _c(
                                "router-view",
                                {
                                  key: _vm.$route.path,
                                  attrs: {
                                    defaultRoute: _vm.defaultMenuItem,
                                    name: "route",
                                    filterPanelVisible: false
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "favorites" },
                                      slot: "favorites"
                                    },
                                    [_c("menu-favorites")],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dialogmanager-footer" },
            [_c("old-dialog-manager"), _vm._v(" "), _c("dialog-manager")],
            1
          ),
          _vm._v(" "),
          _c("toast-pop-up"),
          _vm._v(" "),
          _c("absolute-menu-popup"),
          _vm._v(" "),
          _c("absolute-template-popup")
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }