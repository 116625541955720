﻿import * as userStorageDataProvider from '@/Data/DataProviders/userStorageDataProvider';
import { GetUserInfo, OnAvatarUpdate as AuthServiceOnAvatarUpdate } from '@/Data/Auth/authentication-service';

let avatarRef = null;

export async function Init() {
	let userInfo = await GetUserInfo();
	avatarRef = userInfo.avatarid;
}

export function GetAvatar() {
	return avatarRef;
}

export function OnAvatarUpdate(ref) {
	avatarRef = ref;
	AuthServiceOnAvatarUpdate(ref);
}
