import TotalCounter from './Common/total-counter/total-counter';
import ChartWrapper from './Charts/chart-wrapper';
import PeriodBehaviors from './date-period-behaviors';
import { GetUserInfo } from '@/Data/Auth/authentication-service';

export default {
	components: {
		TotalCounter,
		ChartWrapper
	},
	data() {
		return {
			behaviors: PeriodBehaviors.Behaviors,
			datePeriod: null,
			croomData: null,
			droomData: null,
			inquiryData: null,
			croomParticipantsData: null,
			droomParticipantsData: null,
			croomDoughnutData: null,
			droomDoughnutData: null,
			inquiryDoughnutData: null,
			options: {
				legend: { display: false },
				scales: { yAxes: [{ ticks: { beginAtZero: true } }] },
				maintainAspectRatio: false
			},
			doughnutOptions: {
				legend: {
					position: 'right',
					labels: {
						generateLabels(chart) {
							const data = chart.data;
							if (data.labels.length && data.datasets.length) {
								return data.labels.map((label, i) => {
									const meta = chart.getDatasetMeta(0);
									const ds = data.datasets[0];
									const arc = meta.data[i];
									const custom = (arc && arc.custom) || {};
									const getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
									const arcOpts = chart.options.elements.arc;
									const fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
									const stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
									const bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

									label = label.length < 50 ? label : label.substring(0, 47) + '...';
									return {
										text: label,
										fillStyle: fill,
										strokeStyle: stroke,
										lineWidth: bw,
										hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
										index: i
									};
								});
							} else {
								return [];
							}
						}
					}

				},
				maintainAspectRatio: false
			},
			horizontalBarChartOptions: {
				legend: {
					position: 'right',
					labels: {
						generateLabels(chart) {
							const data = chart.data;
							if (data.labels.length && data.datasets.length) {
								return data.datasets.map((dataset, i) => {
									const meta = chart.getDatasetMeta(i);
									const arc = meta.data[i];
									const custom = (arc && arc.custom) || {};
									const getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
									const arcOpts = chart.options.elements.arc;
									const fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(dataset.backgroundColor, i, arcOpts.backgroundColor);
									const stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(dataset.borderColor, i, arcOpts.borderColor);
									const bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(dataset.borderWidth, i, arcOpts.borderWidth);

									const label = dataset.label.length < 50 ? dataset.label : dataset.label.substring(0, 47) + '...';
									return {
										datasetIndex: i,
										text: label,
										fillStyle: fill,
										strokeStyle: stroke,
										lineWidth: bw,
										// TODO: possibly find a better way, because index is different on defferent data
										hidden: dataset._meta[Object.keys(dataset._meta)[0]].hidden
									};
								});
							} else {
								return [];
							}
						}
					}
				},
				maintainAspectRatio: false
			},
			chartStyles: {
				width: '570px',
				height: '310px',
				position: 'relative'
			},
			horizontalBarStyles: {
				width: '100%',
				padding: '0 30px',
				height: '310px',
				display: 'flex',
				'box-sizing': 'border-box'

			},
			isLoading: false,
			trendsLoading: false
		};
	},

	async created() {
		const style = getComputedStyle(document.body);
		this.color14 = style.getPropertyValue('--color14').trim();
        this.color15 = style.getPropertyValue('--color15').trim();
        this.color16 = style.getPropertyValue('--color16').trim();
        this.color17 = style.getPropertyValue('--color17').trim();
        this.color18 = style.getPropertyValue('--color18').trim();
        this.color19 = style.getPropertyValue('--color19').trim();
		this.color20 = style.getPropertyValue('--color-favorites').trim();
		this.doughnutColors = [this.color14, this.color15, this.color16, this.color17, this.color18, this.color19, this.color20];
		this.sizeFilter = sc.classes.get('offsetSize.filter', 99999);

		this.trendsLoading = true;
		const userInfo = await GetUserInfo();
		this.systemuserid = userInfo.systemuserid;
		this.trendsLoading = false;

		this.OnPeriodChange(PeriodBehaviors.DefaultBehavior);
	},
	methods: {
		async loadByChunks(entities, filters) {
			let chunkRes = [];
			const totalRes = [];
			let fromValue = 0;
			const chunkSize = 999;

			const loadChunk = async () => {
				chunkRes = await sc.classes.get('edge.dataProvider').search({
					filters: [
						...filters,
						sc.classes.get('offsetFrom.filter', fromValue),
						sc.classes.get('offsetSize.filter', chunkSize)
					],
					entities,
					withDefaultFilters: false
				});

				if (chunkRes.Results.length === chunkSize) {
					totalRes.push(chunkRes.Results);
					fromValue += chunkSize;
					await loadChunk();
				} else if (chunkRes.Results.length < chunkSize) {
					totalRes.push(chunkRes.Results);
				}
			};

			await loadChunk();
			return totalRes.flat();
		},
		async loadEntityStats(bounds, entities, filters = []) {
			if (bounds) {
				const dateFilter = sc.classes.get('search.dateFacetFilter', {
					logicalName: 'createdon',
					item: [{
						$type: 'search.dateFacetItem',
						min: bounds.from,
						max: bounds.to
					}],
					includeEmpty: false
				}, null).toFilter();

				filters.push(dateFilter);
			}

			filters.push(sc.classes.get('selectedFields.filter', [{ logicalname: 'createdon' }]));
			const res = await this.loadByChunks(entities, filters);

			return res;
		},
		OnPeriodClick() {
			// todo - refactor sortpanel dropdown to be more generic
			this.$root.$emit('templatePopup.open', this.$refs.opener, 'sortPanel', {
				name: 'sortpanel-dropdown',
				params: {
					filterFields: this.behaviors.map((b) => {
						return Object.assign(b, {
							displayName: b.title,
							isSelected: b.value === this.datePeriod.value
						});
					}),
					onSelect: this.OnPeriodChange,
					offsetLeft: 10,
					offsetTop: -5
				},
				wrapperClass: 'dropdown-wrapper'
			}, 'mousedown');
		},
		async getCommonData(bounds, filters = []) {
			const entities = ['collaborationroom', 'dealroom'];
			const dateFilter = sc.classes.get('search.dateFacetFilter', {
				logicalName: 'createdon',
				item: [{
					$type: 'search.dateFacetItem',
					min: bounds.from,
					max: bounds.to
				}],
				includeEmpty: false
			}, null).toFilter();
			const selectedFieldsFilter = sc.classes.get('selectedFields.filter', [{ logicalname: 'createdon' }]);
			const res = await this.loadByChunks(entities, [dateFilter, selectedFieldsFilter, ...filters]);

			return res;
		},
		getDRFilters(filters = []) {
			const f = sc.classes.get('search.termFacetFilter', {
				logicalName: 'listingid.id',
				item: [{
					$type: 'search.termFacetItem',
					value: null
				}],
				negative: true
			}, null);

			return [...[f.toFilter()], ...filters];
		},
		async loadParticipants(bounds, filters = []) {
			const dateFilter = sc.classes.get('search.dateFacetFilter', {
				logicalName: 'createdon',
				item: [{
					$type: 'search.dateFacetItem',
					min: bounds.from,
					max: bounds.to
				}],
				includeEmpty: false
			}, null).toFilter();
			const selectedFieldsFilter = sc.classes.get('selectedFields.filter', [
				{ logicalname: 'createdon' },
				{ logicalname: 'chatparticipantchatidchat.collaborationroomid' },
				{ logicalname: 'chatparticipantchatidchat.dealroomid' }
			]);
			const res = await this.loadByChunks(['chatparticipant'], [dateFilter, selectedFieldsFilter, ...filters]);

			const crooms = [];
			const drooms = [];
			res.forEach((record) => {
				if (!record.Source.chatparticipantchatidchat) {
					return;
				}

				record.Source.chatparticipantchatidchat.collaborationroomid && crooms.push(record);
				record.Source.chatparticipantchatidchat.dealroomid && drooms.push(record);
			});
			return {
				crooms,
				drooms
			};
		},
		async loadEntityRelations({ facetName, entity }, filters) {
			const resp = await this.loadStatistic(facetName, entity, filters);
			const labels = [];
			const data = [];
			resp.TermFacets.filter(facet => facet.LogicalName === facetName)[0].Items.forEach((item) => {
				labels.push(item.Term);
				data.push(item.Count);
			});
			return {
				labels,
				datasets: [
					{
						backgroundColor: this.doughnutColors,
						data
					}
				]
			};
		},

		async loadStatistic(facetName, entity, additionalFilters = [], facetCount = 7) { // here
			const behavior = PeriodBehaviors.GetBehavior(this.datePeriod);
			const bounds = behavior.GetDateBounds();
			const dateFilter = sc.classes.get('search.dateFacetFilter', {
				logicalName: 'createdon',
				item: [{
					$type: 'search.dateFacetItem',
					min: bounds.from,
					max: bounds.to
				}],
				includeEmpty: false
			}, null).toFilter();
			const size = sc.classes.get('offsetSize.filter', 0);
			const selectedFieldsFilter = sc.classes.get('selectedFields.filter', [
				{ logicalname: 'createdon' }
			]);
			const selectedFilter = sc.classes.get('selectedTermFacet.filter', facetName, facetCount, false);

			additionalFilters.push(dateFilter);

			return await sc.classes.get('edge.dataProvider').search({
				filters: [...additionalFilters, size, selectedFieldsFilter, selectedFilter],
				entities: [entity]
			});
		}
	}
};
