<template>
	<div v-perfectscroll="{ enable: true }" class="marketspace-menu">
		<div v-for="(item, index) in items" class="items" :key="index">
			<route-menu-item-svg
				:class="state"
				:options="item"
				:title="setTooltip(item.text.value)"
			/>
		</div>
	</div>
</template>
<script>
import RouteMenuItemSvg from './../Items/route-menu-item-svg.vue';

export default {
	name: 'menu-marketspace',
	props: {
		items: Array,
		state: String
	},
	components: { RouteMenuItemSvg },
	methods: {
		setTooltip(message) {
			return this.state === 'iconsOnly' ? message : '';
		}
	}
};
</script>
<style src="./menu-marketspace.less" scoped></style>
