import ValidatorBase from './base';
export default class extends ValidatorBase {
	async validate(entityData, context) {
		const number = context.eval(this.options.value);
		return {
			field: await this.customFieldName() || this.field,
			result: !entityData[this.field] || entityData[this.field] >= number,
			message: this.message ? await context.evalAsync(this.message) : await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('entity.control.minValueValidator.msg', [number])
		};
	}
}
