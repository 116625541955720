var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "perfectscroll",
          rawName: "v-perfectscroll",
          value: { enable: true },
          expression: "{ enable: true }"
        }
      ],
      staticClass: "marketspace-menu"
    },
    _vm._l(_vm.items, function(item, index) {
      return _c(
        "div",
        { key: index, staticClass: "items" },
        [
          _c("route-menu-item-svg", {
            class: _vm.state,
            attrs: { options: item, title: _vm.setTooltip(item.text.value) }
          })
        ],
        1
      )
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }