var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "breadcrumbs" }, [
    _c(
      "div",
      {
        staticClass: "breadcrumb-item",
        on: {
          click: function($event) {
            _vm.navigate(2)
          }
        }
      },
      [
        _c("span", { staticClass: "icon" }, [
          _c("svg", { staticClass: "svg-icon svg-12 back-button" }, [
            _c("use", {
              attrs: { "xlink:href": "#layout-icons-details-header-back" }
            })
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "breadcrumb-item parent-item",
        on: {
          click: function($event) {
            _vm.navigate(1)
          }
        }
      },
      [
        _c("div", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.firstItem.title))
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "breadcrumb-item" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.secondItem.title))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }