export class Timer {
	constructor(callback, interval) {
		let timerId; let startTime; let remaining = 0;
		let state = 0;

		this.pause = () => {
			if (state !== 1) return;

			remaining = interval - (new Date() - startTime);
			window.clearInterval(timerId);
			timerId = null;
			state = 2;
		};

		this.resume = () => {
			if (state !== 2) return;

			state = 3;
			window.setTimeout(this.timeoutCallback, remaining);
		};

		this.timeoutCallback = () => {
			if (state !== 3) return;

			callback();

			startTime = new Date();
			timerId = window.setInterval(callback, interval);
			state = 1;
		};

		this.destroy = () => {
			window.clearInterval(timerId);
			timerId = null;
		};

		startTime = new Date();
		timerId = window.setInterval(callback, interval);
		state = 1;
	}
}
