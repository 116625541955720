import { isActive, checkPrivilege } from './Methods/action.methods';
import checkContactMethods from './Methods/action.methods.checkContact';

export default {
	async enabled(options, context, selection) {
		const field = context.eval(options.fieldToCheckValidData);
		const regexp = context.eval(options.regexpPattern);
		const filteredSelection = checkContactMethods.filterContact(selection, field, regexp);

		if (!filteredSelection || !filteredSelection.length || !await isActive(context, filteredSelection)) {
			return Promise.resolve(false);
		}

		const logicalName = filteredSelection[0].Type || filteredSelection[0].logicalname;
		const promises = [];

		promises.push(sc.classes.get('entityConfiguration.dataProvider').fetchEntity(logicalName));
		promises.push(checkPrivilege('staticlistmember', 'Read'));
		promises.push(checkPrivilege('staticlistmember', 'Create'));

		return Promise.all(promises).then(([entity, readAllowed, createAllowed]) => {
			return readAllowed && createAllowed && !!entity.staticListMemberName;
		});
	},
	async execute(options, context, selection) {
		const componentSet = await sc.classes.get('marketspaceComponents.dataProvider').getComponentSetById(context.eval(options.componentsetName));
		const title = await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('actions.marketingList.addMember.title');
		const maximizedWidth = sc.classes.get('additionalParameters.controller').getValue('addToList-dialog-width', '800px');
		const maximizedHeight = sc.classes.get('additionalParameters.controller').getValue('addToList-dialog-height', '90%');

		const field = context.eval(options.fieldToCheckValidData);
		const regexp = context.eval(options.regexpPattern);
		const filteredSelection = checkContactMethods.filterContact(selection, field, regexp);

		if (filteredSelection.length < selection.length) {
			const someContactsSkipped = await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('actions.addContact.someSkipped');
			checkContactMethods.showWarningDialogForContacts(someContactsSkipped);
		}

		sc.events.emit('vue.dialog.open', {
			icon: 'entity-icons-deal-small',
			title,
			allowPin: false,
			component: 'staticlists-ms-add-to-list',
			maximizedWidth,
			maximizedHeight,
			params: Object.assign({}, options, {
				selection: filteredSelection.map((item) => {
					return item.Id ? sc.classes.get('entityReference', item) : item;
				}),
				componentOptions: componentSet.component[0]
			})
		});
	}
};
