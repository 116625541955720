var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "searchResults-map-view" }, [
    _vm.isActive
      ? _c(
          "div",
          {
            directives: [
              {
                name: "mapSearchResults",
                rawName: "v-mapSearchResults",
                value: {
                  results: _vm.results,
                  selection: _vm.selection,
                  getAreaSelection: _vm.getAreaSelection,
                  setAreaSelection: _vm.setAreaSelection,
                  clearSelection: _vm.clearSelection,
                  getEntityCoords: _vm.getEntityCoords,
                  onAreaSelectionChanged: _vm.onAreaSelectionChanged,
                  areaSelectionEnabled: _vm.areaSelectionEnabled,
                  drawMode: _vm.drawMode,
                  draw: _vm.draw,
                  namespace: _vm.combinedNamespace,
                  stateNamespace: _vm.stateNamespace,
                  fullScreenCallback: _vm.fullScreenCallback,
                  targetEntity: _vm.targetEntity,
                  enableFullscreenControl: _vm.options.enableFullscreenControl,
                  enableDrawingTool: _vm.options.enableDrawingTool,
                  mapTooltipName: "mobile-map-tooltip",
                  zoomOnDoubleclick: _vm.options.zoomOnDoubleclick,
                  mapShiftLeft: 65,
                  mapShiftTop: 35,
                  isMobile: true,
                  gestureHandling: "cooperative"
                },
                expression:
                  "{\n\t\t\t results: results,\n\t\t\t selection: selection,\n\t\t\t getAreaSelection: getAreaSelection,\n\t\t\t setAreaSelection: setAreaSelection,\n\t\t\t clearSelection: clearSelection,\n\t\t\t getEntityCoords: getEntityCoords,\n\t\t\t onAreaSelectionChanged: onAreaSelectionChanged,\n\t\t\t areaSelectionEnabled: areaSelectionEnabled,\n\t\t\t drawMode: drawMode,\n\t\t\t draw: draw,\n\t\t\t namespace: combinedNamespace,\n\t\t\t stateNamespace: stateNamespace,\n\t\t\t fullScreenCallback: fullScreenCallback,\n\t\t     targetEntity: targetEntity,\n\t\t\t enableFullscreenControl: options.enableFullscreenControl,\n\t\t\t enableDrawingTool: options.enableDrawingTool,\n\t\t\t mapTooltipName: 'mobile-map-tooltip',\n\t\t\t zoomOnDoubleclick: options.zoomOnDoubleclick,\n\t\t\t mapShiftLeft: 65,\n\t\t\t mapShiftTop: 35,\n\t\t\t isMobile: true,\n\t\t\t gestureHandling: 'cooperative'\n\t\t }"
              }
            ],
            staticClass: "map-search-results",
            class: { fullscreen: _vm.mapFullScreen }
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isMapReady,
                  expression: "!isMapReady"
                }
              ],
              staticClass: "loading-overlay"
            })
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "scMap" }, [
      _c("div", { staticClass: "sc-map-holder" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }