<template>
	<div v-if="!isDetails" class="email-campaign-footer-root">
		<section class="footer">
			<WizardButton
				@click="cancelCampaign"
				class="footer-btn"
				:text="isForceExit ? 'exit' : 'cancel'"
			/>
			<WizardButton
				class="footer-btn"
				text="Save as Draft"
				:disabled="templateProcess || (!isEdited && withEditMode)"
				@click='saveAsDraft'
			/>
			<WizardButton
				v-if="visible"
				:disabled="!isWizardValid"
				class="footer-btn"
				text="Send Test"
				icon="action"
				@click="openSendTestDialog"
			>
				<svg class="wizard-email-icon">
					<use xlink:href="#action-icons-wizard-email"></use>
				</svg>
			</WizardButton>
			<WizardButton
				v-if="activeStepNumber !== 1"
				:disabled="!isDisabled.prev"
				class="footer-btn"
				text="back"
				icon="back"
				@click="changeStep()"
			>
				<svg class="wizard-arrow">
					<use xlink:href="#action-icons-ms-entity-arrow"></use>
				</svg>
			</WizardButton>
			<WizardButton
				v-if="!visible"
				:disabled="isNextDisabled"
				class="footer-btn"
				text="next"
				color="blue"
				icon="next"
				@click="changeStep('next')"
			>
				<svg class="wizard-arrow">
					<use xlink:href="#action-icons-ms-entity-arrow"></use>
				</svg>
			</WizardButton>
			<WizardButton
				v-if="visible"
				:disabled="!isWizardValid"
				color="blue"
				class="footer-btn"
				text="Send"
				@click='checkDataStatus'
			>
				<svg class="wizard-flag">
					<use xlink:href="#action-icons-ms-entity-flag"></use>
				</svg>
			</WizardButton>
		</section>
	</div>
</template>

<script>
import {
	mapActions, mapGetters, mapState
} from 'vuex';
import { wizardMixin } from '@/Components/Wizard/Mixins/wizardMixin';
import coDataProvider from '@/Data/DataProviders/coDataprovider';
import WizardButton from '../Buttons/NavBtn/navBtn';

export default {
	name: 'email-campaign-footer',
	components: { WizardButton },
	mixins: [wizardMixin],
	computed: {
		...mapState('wizard', ['templateProcess']),
		...mapGetters('wizard', ['isTemplateSelected', 'isWizardValid', 'isLimitError', 'isForceExit']),
		isDisabled() {
			return (
				(this.getStepConfig && this.getStepConfig.nav) || {
					prev: true,
					next: true
				}
			);
		},
		visible() {
			return this.activeStepNumber === 5;
		},
		isNextDisabled() {
			if (this.activeStepNumber === 2 && !this.isTemplateSelected) {
				return true
			} else if (this.activeStepNumber === 3 && this.isLimitError) {
				return true
			} else return false
		},
		itHasPrevRoute() {
			/* TODO: refactor to global router event? */
			const DEFAULT_ROUTE = '/';
			const MINIMUM_ROUTE_LENGTH = 1;
			const route = this.$route.params.previous || DEFAULT_ROUTE;
			return (route !== DEFAULT_ROUTE) && (route.length > MINIMUM_ROUTE_LENGTH);
		}
	},
	methods: {
		...mapActions('wizard', ['checkDataStatus', 'saveAsDraft', 'back', 'updateEditedFlag']),
		cancelCampaign() {
			if (this.isForceExit) {
				this.back(!this.itHasPrevRoute);
				return;
			}
			if (this.isEdited && !this.isDetails) {
				sc.events.emit('vue.dialog.open', {
					icon: 'action-icons-search-save',
					title: 'Quit campaign',
					component: 'confirm.dialog',
					maximizedWidth: '450px',
					maximizedHeight: 'auto',
					message: 'All unsaved data will be lost. Would you like to leave?',
					okLabel: 'Yes',
					cancelLabel: 'No',
					onSubmit: async () => {
						this.updateEditedFlag(false);
						const artifacts = this.$store.state.wizard.generatedArtifactsIds.length && this.$store.state.wizard.generatedArtifactsIds || null;
						if (this.$route.params && this.$route.params.edit) {
							this.$store.state.wizard.campaignWithArtifactStatus && artifacts.shift();
							artifacts && artifacts.length && Promise.all(artifacts.map(id => coDataProvider.deleteArtifact(id)));
						} else artifacts && Promise.all(artifacts.map(id => coDataProvider.deleteArtifact(id)));
						this.back(!this.itHasPrevRoute);
					}
				});
			} else {
				this.back(!this.itHasPrevRoute);
			}
		},
		openSendTestDialog() {
			this.$root.$emit('vue.dialog.open', {
				icon: '',
				title: 'Send Test Email',
				component: 'send-test-email-dialog',
				maximizedWidth: '800px',
				maximizedHeight: '479px',
				submitLabel: 'Send Test Email',
				cancelLabel: 'Cancel',
				templateEntity: 'contact',
				lookupTemplate: {
					$type: 'control.template',
					subtype: 'wizard-send-test-lookup',
					usage: 'custom'
				},
				lookupFilters: [{
					$type: 'search.fieldFilter',
					kind: 'Default',
					field: [{
						$type: 'search.field',
						logicalName: 'email',
						boost: 100
					}],
					query: [{
						$type: 'expression.text',
						value: '@'
					}],
					logicalName: 'email'
				}],
				tableTemplate: {
					$type: 'control.template',
					subtype: 'wizard-send-test-table',
					usage: 'custom'
				}
			});
		}

	}
};
</script>

<style scoped lang='less'>
@import './footer.less';
</style>
