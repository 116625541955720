<template>
	<div class="theme-details" v-if="rows.length">
		<div class="content">
			<div class="header">
				<div class="title h1">Color Themes</div>
			</div>
			<div class="rows content-scroll-wrapper" v-perfectscroll="{enable: true, onScroll: onScroll}">
				<DetailsRowControl v-for="row in rows" v-bind:style="{height: row.height}" :key="row.$key" v-bind:evaluationContext="evaluationContext" v-bind:row="row"></DetailsRowControl>
			</div>
		</div>
	</div>
</template>
<script>
import { generateId } from '@acx-xms/data-functions/dist';
import '@/Bindings/perfectscroll.directive';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
const DetailsRowControl = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/ComponentSet/Details/details-row-control');
export default {
	components: { DetailsRowControl },
	props: { options: Object },
	data() {
		return {
			evaluationContext: createEvaluationContext({}),
			rows: []
		};
	},
	created() {
		this.configDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		this.fork = sc.events.fork();
		this.initTemplate();
		this.$root.$on(['entity.changed', 'entity.created', 'entity.deleted'], this.checkAutoRefresh);
		this.fork.on(['entity.changed', 'entity.created', 'entity.deleted'], this.checkAutoRefresh);
	},
	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
		}
		this.$root.$off(['entity.changed', 'entity.created', 'entity.deleted'], this.checkAutoRefresh);
	},
	methods: {
		onScroll() {
			this.$root.$emit('mainSearch.themes.list.scrolled');
		},
		updateThemeDetails() {
			for (const row of this.rows) {
				row.$key = generateId();
			}
		},
		checkAutoRefresh(data) {
			if (data.entity.logicalname === 'theme') {
				this.fork.emit('mainSearch.themes.list.searching');
			}
		},
		async initTemplate() {
			let template;
			try {
				template = await this.configDataProvider.getTemplate('theme', 'detail');
			} catch (ex) {
				return;
			}
			if (template && template.content && template.content.details) {
				this.rows = template.content.details.row.map((row) => {
					return {
						...row,
						...{ $key: generateId() }
					};
				});
			}
		}
	}
};
</script>
<style src="./theme-details.less" scoped></style>
