<template>
	<div class="marketspace-pagination-wrapper" v-show="pages.length" v-bind:class="{'no-pages':(pages.length<2)}">
		<div class="pagination">
			<div class="pagination-arrow prev" @click="previous">
				<div class="navigation-item" v-bind:class="{'disabled':isSelected(1) }">
					<span class="pagination-item-arrow">
						<svg class="svg-icon svg-10">
							<use xlink:href="#layout-icons-ms-arrow-left"></use>
						</svg>
					</span>
				</div>
			</div>
			<ul>
				<li v-for="(page, index) in pages" v-on:click="selectPage(page.number)" :key="index">
					<div class="navigation-item page-button" v-bind:class="{'disabled':page.number == elipsis, 'selected': page.selected}">{{page.number}}</div>
				</li>
			</ul>
			<div class="pagination-arrow next" @click="next">
				<div class="navigation-item" v-bind:class="{'disabled':isSelected(lastPage)}">
					<span class="pagination-item-arrow">
						<svg class="svg-icon svg-10">
							<use xlink:href="#layout-icons-ms-arrow-right"></use>
						</svg>
					</span>
				</div>
			</div>
			<div class="load-more-wrapper" v-bind:style="{visibility: showLoadMoreVisibility}" v-if="total>0">
				<button type="submit" @click="loadMore"><span v-localization="{key:'mainLayout.loadMore'}"></span></button>
			</div>
		</div>

		<div v-if="total>0" class="results-label">{{label}}</div>
	</div>

</template>
<script>
import PaginationMixins from './pagination.mixins';
export default {
	name: 'mobile-pagination',
	mixins: [PaginationMixins],
	computed: {
		pages: function () {
			this.lastPage = Math.ceil(this.total / this.pageSize);
			const firstActivePage = this.activePages[0];
			const lastActivePage = this.activePages[this.activePages.length - 1];
			let middlePage = lastActivePage;
			const pages = [];

			if (this.lastPage <= this.maxLength) {
				for (let i = 1; i <= this.lastPage; i++) {
					pages.push({
						number: i,
						selected: firstActivePage <= i && lastActivePage >= i
					});
				}
			} else {
				if (middlePage < 4) {
					for (let i = 1; i <= 3; i++) {
						pages.push({
							number: i,
							selected: firstActivePage <= i && lastActivePage >= i
						});
					}
				} else {
					pages.push({
						number: 1,
						selected: firstActivePage <= 1 && lastActivePage >= 1
					});
					pages.push({
						number: this.elipsis,
						selected: lastActivePage >= 4 && firstActivePage < this.lastPage - 2 && this.activePages.length > 1
					});
					if (this.lastPage - middlePage <= 2) {
						middlePage = this.lastPage - 2;
					}
					pages.push({
						number: middlePage,
						selected: firstActivePage <= middlePage && lastActivePage >= middlePage
					});
				}
				if (this.lastPage - middlePage > 2) {
					pages.push({
						number: this.elipsis,
						selected: false
					});
					pages.push({
						number: this.lastPage,
						selected: firstActivePage <= this.lastPage && lastActivePage >= this.lastPage
					});
				} else {
					for (let i = middlePage + 1; i <= this.lastPage; i++) {
						pages.push({
							number: i,
							selected: firstActivePage <= i && lastActivePage >= i
						});
					}
				}
			}

			this.showLoadMoreVisibility = !(lastActivePage === this.lastPage) && (pages.length > 1) ? 'visible' : 'hidden';
			return pages;
		}
	}
};
</script>
<style src="./pagination.less" scoped></style>
