import updateWithParameterBase from './Methods/action.entity.updateWithParameterBase';

export default {
	async enabled(options, evaluationContext, selection) {
		return updateWithParameterBase.enabled(options, evaluationContext, selection);
	},
	async execute(options, context, selection) {
		updateWithParameterBase.execute(options, context, selection);
	}
};
