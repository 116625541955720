import BaseModule from './baseModule';
import { GetUserInfo, GetValidLicenses } from '@/Data/Auth/authentication-service';
import { Create, Search } from '@acx-xms/data-functions/dist';
import { Get, Set } from '@/Data/DataProviders/userStorageDataProvider';

function getDiffDays(date1, date2) {
	date1 = new Date(date1);
	date2 = new Date(date2);
	const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
	const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

	return Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
}

async function getLicenseAndSendNotification(systemuserid, isCheckedToday) {
	return GetValidLicenses().then(async (results) => {
		const _results = _.cloneDeep(results);
		if (_results.Total) {
			const item = _results.Results.pop();
			const lsKey = 'licenseId';
			const lsItem = await Get(lsKey);
			await Set(lsKey, item.Id);
			if (lsItem !== item.Id) {
				isCheckedToday = false;
			}
			const diff = getDiffDays(Date.now(), item.Source.validto);
			const toSend = [1, 3, 5].some(item => item === diff);
			if (toSend && !isCheckedToday) {
				Create('notification', {
					headings: { 'en-us': 'License Expiration' },
					recepientid: {
						id: systemuserid,
						logicalname: 'systemuser'
					},
					contents: { 'en-us': `Your license will expire in ${diff} day(s). Please contact your Organization Admin.` },
					notificationtype: {
						id: 'b08df37b-a8b1-400d-ab44-dcb76fe99294',
						logicalname: 'lookupnotificationtype'
					}
				});
			}
		}
	});
}
async function getParticipantsRequestAcsessAndSendNotification(systemuserid, isCheckedToday) {
	if (isCheckedToday) {
		return;
	};
	const logicalFilter = {
		$type: 'search.logicalFilter',
		operator: {
			$type: 'search.or',
			operator: [
				{
					$type: 'search.operand',
					filter: [
						{
							$type: 'search.termFacetFilter',
							item: [
								{
									$type: 'search.termFacetItem',
									value: true
								}],
							logicalName: 'isroomowner'
						}]
				},
				{
					$type: 'search.operand',
					filter: [
						{
							$type: 'search.termFacetFilter',
							item: [
								{
									$type: 'search.termFacetItem',
									value: true
								}],
							logicalName: 'coowner'
						}]
				}

			]
		}
	};
	const ownerChats = await Search(['chatparticipant'], [
		sc.classes.get('offsetFrom.filter', 0),
		sc.classes.get('offsetSize.filter', 999),
		sc.classes.get('termFacet.filter', {
			logicalName: 'systemuserid.id',
			query: [systemuserid]
		}).fillQuery(),
		sc.classes.get('search.logicalFilter', logicalFilter).toFilter(),
		sc.classes.get('selectedFields.filter', [
			{ logicalname: 'chatid.id' }
		]).fillQuery()
	]);
	if (ownerChats.Results.length) {
		const chatIds = ownerChats.Results.map(cp => cp.Source.chatid.id);
		const to = new Date();
		to.setDate(to.getDate() - 4);
		to.setHours(0, 0, 0, 0);
		const requestedParticipants = await Search(['chatparticipant'], [
			sc.classes.get('offsetFrom.filter', 0),
			sc.classes.get('offsetSize.filter', 999),
			sc.classes.get('termFacet.filter', {
				logicalName: 'chatid.id',
				query: chatIds
			}).fillQuery(),
			sc.classes.get('termFacet.filter', {
				logicalName: 'requestedaccess',
				query: [true]
			}).fillQuery(),
			sc.classes.get('dateTimeFacet.filter', {
				logicalName: 'modifiedon',
				query: [{
					IsChecked: true,
					Range: {
						From: null,
						To: to.toJSON()
					}
				}]
			}).fillQuery(),
			sc.classes.get('selectedFields.filter', [
				{ logicalname: 'chatparticipantchatidchat.collaborationroomid' },
				{ logicalname: 'chatparticipantchatidchat.dealroomid' },
				{ logicalname: 'chatid.id' },
				{ logicalname: 'chatparticipantcontactidcontact.fullname' }
			]).fillQuery()
		]);
		if (requestedParticipants.Results.length) {
			requestedParticipants.Results.forEach(async (result) => {
				const roomid = result.Source.chatparticipantchatidchat.collaborationroomid || result.Source.chatparticipantchatidchat.dealroomid;
				const room = result.Source.chatparticipantchatidchat.collaborationroomid ? 'Collaboration' : 'Deal';
				const roomLogicalName = (result.Source.chatparticipantchatidchat.collaborationroomid || result.Source.chatparticipantchatidchat.dealroomid).logicalname;

				const rod = await Search(['roomopendata'],
					[
						sc.classes.get('offsetFrom.filter', 0),
						sc.classes.get('offsetSize.filter', 1),
						sc.classes.get('termFacet.filter', {
							logicalName: 'roomid.id',
							query: [roomid.id]
						}).fillQuery(),
						sc.classes.get('selectedFields.filter', [
							{ logicalname: 'roomnumber' },
							{ logicalname: 'roomname' }
						]).fillQuery()
					]
				);
				const { roomnumber, roomname } = rod.Results[0].Source;

				Create('notification', {
					userInfo: JSON.stringify({
						content: {
							chatId: result.Source.chatid.id,
							navigationRef: result.Source.chatparticipantchatidchat[`${roomLogicalName}id`]
						}
					}),
					headings: { 'en-us': 'Pending Access Request' },
					recepientid: {
						id: systemuserid,
						logicalname: 'systemuser'
					},
					contents: { 'en-us': `You have not approved nor rejected access request of ${result.Source.chatparticipantcontactidcontact.fullname} to your ${room} Room ${roomnumber}, ${roomname} for more than 5 days now` },
					notificationtype: {
						id: '18d8a8dd-4cc5-4d30-acea-5189d6683183',
						logicalname: 'lookupnotificationtype'
					}
				});
			});
		}
	}
}
export default class extends BaseModule {
	constructor() {
		super(true);
	}

	async install() {
		const { systemuserid } = await GetUserInfo();
		const lsKey = 'systemNotificationSentDate';
		const lsItem = await Get(lsKey); let lastCheckedDate; let diff; let isCheckedToday = false;
		if (lsItem) {
			lastCheckedDate = JSON.parse(lsItem);
			diff = getDiffDays(Date.now(), lastCheckedDate);
			isCheckedToday = diff < 1;
		}
		if (!isCheckedToday) {
			await Set(lsKey, JSON.stringify(new Date(Date.now()))); // set date of last check
		}
		return Promise.all([getLicenseAndSendNotification(systemuserid, isCheckedToday), getParticipantsRequestAcsessAndSendNotification(systemuserid, isCheckedToday)]).then(() => true);
	}
}
