export default {
	async enabled(options, evaluationContext, selection) {
		return true;
	},
	async execute(options, context, selection) {
		sc.events.emit(context.eval(options.event), {
			payload: context.eval(options.payload),
			loadingEvent: context.eval(options.loadingEvent),
			refreshEvent: context.eval(options.refreshEvent)
		});
	}
};
