export default {
	async enabled(options, evaluationContext, selection) {
		return Promise.resolve(true);
	},
	async execute(options, context, selection) {
		const localizations = sc.classes.get('localization.dataProvider');

		sc.events.emit('vue.dialog.open', {
			title: await localizations.getLabelForCurrentLanguage('dialogues.mapping.edit'),
			allowPin: false,
			component: 'mapping-edit-dialog',
			maximizedWidth: '80%',
			maximizedHeight: '100%',
			params: {
				evaluationContext: context,
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey)
			}
		});
	}
};
