<template>
	<div class="image-list" v-if="images.length > 0">
		<div v-if="!reference" class="wrapper">
			<div class="image-node" v-for="image in images" :key="image.src">
				<img v-if="image && image.src && !image.error" :src="image.src" @error="image.error = true" />
				<img v-else-if="image && image.src && image.error" src="/Images/broken-file-icon-small.png" class="broken-image" />
				<svg v-else class="svg-icon svg-20">
					<use :xlink:href="placeholderEntityName"></use>
				</svg>
			</div>
		</div>
		<router-link v-else :to="reference" class="wrapper clickable">
			<div class="image-node" v-for="image in images" :key="image.src">
				<img v-if="image && image.src && !image.error" :src="image.src" @error="image.error = true" />
				<img v-else-if="image && image.src && image.error" src="/Images/broken-file-icon-small.png" class="broken-image" />
				<svg v-else class="svg-icon svg-20">
					<use :xlink:href="placeholderEntityName"></use>
				</svg>
			</div>
		</router-link>
		<div class="ellipsis" v-if="ellipsisEnabled">
			<control-svg-icon v-bind:options="{ name: 'layout-icons-ellipsis' }"></control-svg-icon>
		</div>
	</div>
	<div class="image-list" v-else>
		<div class="wrapper">
			<div class="no-results-message">
				<span v-localization="{ key: 'common.dictionary.noMatchesFound' }"></span>
			</div>
		</div>
	</div>
</template>
<script>
import { recordLink } from '@acx-xms/data-functions/dist';
const ControlSvgIcon = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './control-svg-icon');
export default {
	name: 'control-image-list',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			images: [],
			reference: null,
			placeholderEntityName: null,
			ellipsisEnabled: false
		};
	},
	components: { ControlSvgIcon },
	created() {
		const images = this.evaluationContext.eval(this.options.items) || [];
		this.placeholderEntityName = '#entity-icons-' + this.evaluationContext.eval(this.options.placeholderEntityName);
		if (this.options.recordReference) {
			const recordreference = this.evaluationContext.eval(this.options.recordReference);
			this.reference = recordLink(this.$route, recordreference);
		}

		const token = localStorage.getItem(sc.classes.get('authentication').getTokenKey(sc.cluster.key));
		for (let i = 0; i < images.length; i++) {
			if (!images[i]) {
				this.images.push({
					src: null,
					error: false
				});
				continue;
			}
			images[i].error = false;
			let url = sc.classes.get('edge.dataProvider').getImageUrl('image', images[i].imageid, 'thumbnail');
			url += '?token=' + token;
			if (images[i].primary) {
				this.images.unshift({
					src: url,
					error: false
				});
			} else {
				this.images.push({
					src: url,
					error: images[i].error
				});
			}
		}
		if (this.images.length > this.options.size) {
			this.ellipsisEnabled = true;
			this.images = this.images.slice(0, this.options.size - 1);
		}
	}
};
</script>
<styles src="./control-image-list-styles.less" />
