import { Update, SendRequest } from '@acx-xms/data-functions/dist';

export default {
	async enabled(options, evaluationContext, selection) {
		return selection && selection.length;
	},
	async execute(options, context, selection) {
		const localizations = sc.classes.get('localization.dataProvider');
		if (!Array.isArray(selection)) {
			selection = [selection];
		}
		const refreshEvents = context.eval(options.refreshEvents) || [];
		const loadingEvent = context.eval(options.loadingEvent);
		const emitLoading = (isLoading) => {
			loadingEvent && sc.events.emit(loadingEvent, isLoading);
		};
		let errorOccured = false;
		let accessuserid = null;
		emitLoading(true);
		const promiseArray = [];
		selection.forEach(s => {
			const ref = sc.classes.get('entityReference', s);
			const promise = Update(ref.logicalname, ref.id, { anonymousaccess: true }).then(res => {
				const body = {
					Scope: [
						'edge.*.read',
						'edge.*.create',
						'edge.*.write',
						'edge.*.share',
						'edge.*.delete',
						`edge.maintenance.impersonation.${res.accessuserid.id}`
					],
					IsApplicationOnly: true,
					ExpirationTime: '5555.01:01:01.00',
					JWTSubject: { name: `Invitation to ${res.name}` }

				};
				accessuserid = res.accessuserid;
				return SendRequest('/api/PublicAccess/GenerateToken', 'POST', body);
			}).then(res => {
				return Update(ref.logicalname, ref.id, {
					accesstoken: res.EncodedContent,
					accesstokenexpirationdate: new Date(res.ExpirationTime),
					accesstokeninvitationid: {
						id: res.Subject.jti,
						logicalname: 'jwtsubject'
					}
				});
			}).catch(error => {
				errorOccured = true;
				sc.events.emit('dialog.error', {
					title: sc.classes.get('localization.dataProvider').localize('common.dictionary.loginScreen.dialog.loginError'),
					message: error.message
				});
				return Update(ref.logicalname, ref.id, { anonymousaccess: false });
			});
			promiseArray.push(promise);
		});
		await Promise.all(promiseArray);
		emitLoading(false);
		refreshEvents.forEach(refreshEvent => {
			sc.events.emit(refreshEvent);
		});
		if (selection.length === 1 && !errorOccured) {
			sc.events.emit('vue.dialog.open', {
				title: await localizations.getLabelForCurrentLanguage('dialogues.manageAccessToRoom.title'),
				component: 'manage.access.to.room',
				maximizedWidth: '80%',
				maximizedHeight: '100%',
				parameters: { accessuserid }
			});
		}
	}
};
