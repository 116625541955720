<template>
	<div class="link-entity-reference" v-if="visible" v-bind:class="combinedCssClasses">
		<div @click="emitOpenEvent" class="entity-link clickable" v-if="ref && showLink">
			<div class="entity-reference-item">
				<div class="entity-image" v-if="icon">
					<svg class='svg-icon svg-15'>
						<use :xlink:href="'#' + icon"></use>
					</svg>
				</div>
				<span class="name" v-bind:class="{'without-image':icon}">{{text}}</span>
			</div>
		</div>
		<div class="entity-link" v-bind:class="{'not-clickable': true}" v-if="!ref || !showLink">
			<div class="entity-reference-item">
				<div class="entity-image" v-if="icon">
					<svg class='svg-icon svg-15'>
						<use :xlink:href="'#' + icon"></use>
					</svg>
				</div>
				<span class="name" v-bind:class="{'without-image':icon}">{{text}}</span>
			</div>
		</div>
	</div>
</template>
<script>
// TODO: investigate if we need to load styles like this
import '@acx-xms/controls/LinkReference/link-reference.less';
export default {
	name: 'control-namespacedreference',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			showLink: false,
			visible: this.options.visible !== void 0 ? this.evaluationContext.eval(this.options.visible) : true,
			text: this.options.text ? this.evaluationContext.eval(this.options.text) : '',
			icon: this.options.icon ? this.evaluationContext.eval(this.options.icon) : null,
			combinedCssClasses: ''
		};
	},
	methods: {
		emitOpenEvent() {
			const namespace = this.evaluationContext.eval(this.options.namespace);
			this.$root.$emit(namespace + '.selectSingleItem', {
				ref: this.ref,
				templateRef: this.options.templateRef || null,
				parentView: this.options.parentView,
				keepParentView: this.options.keepParentView
			});
		}
	},
	async created() {
		this.ref = (this.options.value) ? this.evaluationContext.eval(this.options.value) : this.evaluationContext.entity;
		this.combinedCssClasses = [this.options.wrap || '', this.options.cssClass ? this.evaluationContext.eval(this.options.cssClass) : 'p2'].join(' ');
		this.showLink = this.options.showLink ? await this.evaluationContext.evalAsync(this.options.showLink) : false;
	}
};
</script>
<!--<style src="../LinkReference/link-reference.less" scoped></style>-->
<!--TODO: when this control moved to package - change links-->
<!--<style src="~@acx-xms/controls/LinkReference/link-reference.less" scoped></style>-->
