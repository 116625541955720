<template>
	<div class="component-search-results-details">
		<div class="content" v-if="!data && isLoading"></div>
		<div class="content" v-if="!!data && !isLoading && !error">
			<div class="header">
				<component :is="breadcrumbsComponentName" :breadcrumbs="breadcrumbs" class="inner"></component>
				<action-menu :options="actionMenuComponent"
							 class="without-padding without-border"
							 v-if="actionMenuComponent"
							 :record="data"
							 :evaluationContext="evaluationContext"
							 :key="actionMenuComponent.$key"></action-menu>
				<control v-if="options.switchDetails"
						 :name="options.switchDetails.$type"
						 :contentProps.once="options.switchDetails"
						 :evaluationContext="evaluationContext"></control>
			</div>
			<div class="rows content-scroll-wrapper" v-perfectscroll="{enable: true, onScroll: onScroll}">
				<DetailsRowControl v-for="row in rows" v-bind:style="{height: row.height}" :key="row.$key" v-bind:evaluationContext="evaluationContext" v-bind:row="row"></DetailsRowControl>
			</div>
		</div>
		<div class="no-data" v-if="!data && !isLoading && error" v-localization="{key: 'common.dictionary.recordDetails.noAccess'}"></div>

		<div class="loading-wrapper" v-if="isLoading">
			<div class="loading-overlay"></div>
		</div>
	</div>
</template>
<script>
/* eslint no-unused-vars: 0 */
import helperMethods from '../component-set-evaluation-context-helper.methods';
import ActionMenu from '../../Menu/action-menu';
import PerfectScroll from '../../../Bindings/perfectscroll.directive';
import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import ExtendedBreadcrumbs from '../breadcrumbs/extended-breadcrumbs';
import { generateId, Get } from '@acx-xms/data-functions/dist';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
const DetailsColumnControl = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './details-column-control');
const DetailsRowControl = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './details-row-control');
const DetailsColumnControlNamespaced = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './details-column-control-namespaced');
const Control = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Entity/control');

export default {
	name: 'component-search-details',
	props: {
		options: Object,
		breadcrumbs: Array
	},
	data() {
		return {
			isMounted: false,
			evaluationContext: null,
			rows: [],
			actionMenuComponent: null,
			displayDetails: false,
			isLoading: false,
			data: null,
			error: false,
			breadcrumbsComponentName: this.options.breadcrumbsComponentName || 'breadcrumbs'
		};
	},
	components: {
		DetailsColumnControl,
		DetailsColumnControlNamespaced,
		ActionMenu,
		DetailsRowControl,
		Breadcrumbs,
		ExtendedBreadcrumbs,
		Control
	},
	created() {
		this.fork = sc.events.fork();
		this.actionMenuComponent = {
			menu: this.options.menu,
			toolbarActions: this.options.actionToolbar,
			namespace: this.options.namespace || '',
			internalNamespace: this.options.internalNamespace || '',
			entityType: (this.options.entityref && this.options.entityref.logicalname) || '',
			$type: 'layoutcomponent.marketspaceActionMenu',
			$key: generateId()
		};
		this.internalNamespace = helperMethods.eval(this.evaluationContext, this.options.internalNamespace);
		this.combinedNamespace = `${helperMethods.eval(null, this.options.namespace)}.${this.internalNamespace}`;
		this.rows = this.options.row.map(row => {
			return {
				...row,
				...{ $key: generateId() }
			};
		});
		this.fork.on('refreshDetailsMenu', this.refreshDetailsMenu);
		this.$root.$on(this.combinedNamespace + '.searching', () => {
			this.getDetailsData(this.options.entityref);
		});
		this.fork.on(this.combinedNamespace + '.searching', () => {
			this.getDetailsData(this.options.entityref);
		});
		// proxy to parent instead of namespaces
		this.$on('.navigateBreadcrumbs', (index) => {
			this.$parent.$emit('.navigateBreadcrumbs', index);
		});
		this.$root.$on('entity.changed', this.onEntityChange);
	},
	mounted() {
		this.isLoading = true;
		this.getDetailsData(this.options.entityref);
	},
	beforeDestroy() {
		this.$root.$off(this.combinedNamespace + '.searching');
		this.$root.$off('entity.changed', this.onEntityChange);
		// clear breadcrumbs for all internal searchresults
		this.$root.$emit(this.internalNamespace + '.clearBreadcrumbs');
		if (this.fork) {
			this.fork.dispose();
		}
	},
	methods: {
		refreshDetailsMenu(record) {
			this.data = record;
			this.$set(this.actionMenuComponent, '$key', generateId());
			this.evaluationContext = createEvaluationContext(record);
		},
		onEntityChange(data) {
			if (this.data.Id === data.entity.id) {
				// TODO: review this stuff
				setTimeout(async () => {
					this.getDetailsData(data.entity);
					this.refreshDetailsMenu(this.data);
				}, 3000);
			}
		},
		onScroll() {
			this.$root.$emit(this.options.namespace + '.' + this.options.internalNamespace + '.scrolled');
		},
		async getDetailsData(entityref) {
			this.isLoading = true;
			try {
				const data = await Get(entityref.logicalname, entityref.id);
				this.evaluationContext = createEvaluationContext(data);
				this.data = data;
				const ref = sc.classes.get('entityReference', data);
				let title = ref.name;
				if (this.options.breadCrumbsTitle) {
					title = this.evaluationContext.eval(this.options.breadCrumbsTitle);
				}

				// if we specify custom breadcrumbsComponentName - that means we are navigating to availability
				if (this.options.breadcrumbsComponentName) {
					const stateKey = this.evaluationContext.eval(this.options.switchDetails.stateKey);
					const _items = this.$store.getters[stateKey + '/getSearchResults']('crlisting');
					let items = [];
					Object.entries(_items).forEach(([key, value]) => {
						items = [...items, ...value];
					});
					const crlisting = items.find(item => item.Source.listingid.id === data.Source.listingid.id);
					const entityref = sc.classes.get('entityReference', crlisting);
					const parentTemplate = this.options.parentTemplateRef;
					const parentBread = {
						id: entityref.id,
						logicalName: entityref.logicalname,
						title: entityref.name,
						options: {
							...parentTemplate.content.details,
							...{
								$key: null,
								entityref
							}
						}
					};
						// if we navigate directly to cravailability - we need to push parent crlisting to breadcrumbs
					if (this.breadcrumbs.length === 1) {
						this.$parent.$emit('pushBreadcrumb', parentBread);
					} else {
						// if we navigate from crlisting - we need to replace parent crlisting with crlisting of current cravailability in breadcrumbs
						const index = this.breadcrumbs.length === 2 ? 1 : 2;
						this.$parent.$emit('replaceBreadcrumbs', parentBread, index);
					}
				}

				const bread = {
					id: ref.id,
					logicalName: ref.logicalname,
					title,
					options: this.options
				};

				if (this.options.breadcrumbsOptions === 'replace') {
					this.$parent.$emit('replaceBreadcrumbs', bread, 1);
				} else {
					this.$parent.$emit('pushBreadcrumb', bread);
				}
			} catch (error) {
				this.error = true;
				this.data = null;
			}
			this.isLoading = false;
		}
	}
};
</script>
<style>
	.loading-wrapper .loading-overlay {
		background-color: transparent;
	}

	.component-search-results-details > .content > .rows > .row > .column.highlights .column-content .flexible-field-wrapper {
		min-height: unset;
	}
</style>
<style src="./search-details.less" scoped></style>
