var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "counter",
      class: ["step-counter", { disabled: _vm.disableCounter }]
    },
    [
      _vm._l(_vm.preparedSteps, function(step) {
        return _c(
          "div",
          {
            key: step.index,
            class: [
              "step",
              {
                disabled: step.disabled,
                disabledFilled: step.disabledFilled,
                filled: step.filled,
                invalid: step.invalid,
                active: step.active,
                animated: step.animated
              }
            ]
          },
          [
            _c(
              "div",
              {
                staticClass: "state",
                on: {
                  click: function($event) {
                    _vm.setActive(step.index)
                  }
                }
              },
              [
                step.filled || step.invalid || step.disabledFilled
                  ? _c("div", { staticClass: "state-icon" }, [
                      _c("svg", { staticClass: "icon" }, [
                        step.filled || step.disabledFilled
                          ? _c("use", {
                              attrs: { "xlink:href": "#ui-icon-success" }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        step.invalid
                          ? _c("use", {
                              attrs: { "xlink:href": "#ui-icon-attention" }
                            })
                          : _vm._e()
                      ])
                    ])
                  : _c("div", { staticClass: "index" }, [
                      _vm._v("\n\t\t\t\t" + _vm._s(step.index) + "\n\t\t\t")
                    ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "caption" }, [
              _c("div", { staticClass: "caption-wrapper" }, [
                _c("span", [
                  _vm._v("\n\t\t\t\t\t" + _vm._s(step.caption) + "\n\t\t\t\t")
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.active === step.index
              ? _c("div", { staticClass: "fake-pointer" }, [
                  _c("div", { staticClass: "icon" })
                ])
              : _vm._e()
          ]
        )
      }),
      _vm._v(" "),
      _c("div", { ref: "pointer", staticClass: "pointer" }, [
        _c("div", { staticClass: "icon" })
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }