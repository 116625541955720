<template>
	<!-- tooltip-->
	<div v-bind:class="[cssClass, 'control-svg-image']">
		<div class="svg-top-icon-wrapper" v-if="visible && topIcon">
			<svg class="svg-icon svg-icon-top svg-12">
				<use :xlink:href="'#entity-icons-'+topIcon"></use>
			</svg>
		</div>
		<image-placeholder :isCustomImagePlaceholder="options.isCustomImagePlaceholder"
						   :style="{width: identifyPixelWidth() + widthUom, height: identifyPixelHeight() + heightUom}"
						   :cssClass="svgClass()"
						   :placeholderEntityName="'#entity-icons-' + placeholderEntityName"
						   :alt="options.alt"
						   v-if="visible && !imgObj">
		</image-placeholder>
		<div v-else-if="visible && imgObj" v-bg-image="imgObj" @click="imageClick" :style="{cursor: imageCursor}"></div>
	</div>
</template>
<script>
import ImageMixins from './images.mixins';
import helperMethods from './../../ComponentSet/component-set-evaluation-context-helper.methods';
import * as tracking from '@/Data/trackingModule';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'control-svg-image',
	mixins: [ImageMixins],
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			visible: (this.options.visible) !== void 0 ? this.evaluationContext.eval(this.options.visible) : true,
			topIcon: this.evaluationContext.eval(this.options.topIcon) || null,
			cssClass: (this.options.cssClass) ? helperMethods.eval(this.evaluationContext, this.options.cssClass) : '',
			placeholderEntityName: this.evaluationContext.eval(this.options.placeholderEntityName) || this.evaluationContext.entity.logicalname,
			imgObj: null,
			placeholderClass: '',
			widthUom: this.options.widthUom || 'px',
			heightUom: this.options.heightUom || 'px',
			allowFullscreenPreview: this.options.allowFullscreenPreview ? this.evaluationContext.eval(this.options.allowFullscreenPreview) : false,
			localEvaluationContext: this.evaluationContext
		};
	},
	computed: {
		imageCursor() {
			return this.allowFullscreenPreview ? 'pointer' : 'default';
		}
	},
	created() {
		const subtype = this.evaluationContext.eval(this.options.subtype);
		this.placeholderClass = ['entity-icons', this.placeholderEntityName, subtype, this.options.size].filter(Boolean).join('-');
		this.processImageUrl();
		this.$root.$on('co.thumbnails.loaded', (templates) => {
			const template = templates.find(template => {
				if (template.templateId) {
					return template.templateId === this.localEvaluationContext.datasource.templateId;
				} else if (template.templateIds) {
					return template.templateIds[0] === this.localEvaluationContext.datasource.templateIds && this.localEvaluationContext.datasource.templateIds[0];
				}
				return false;
			});
			if (template) {
				this.localEvaluationContext = createEvaluationContext(template);
				if (this.options.imageUrl) {
					this.imgObj = this.defaultImgObj();
					this.imgObj.src = helperMethods.eval(this.localEvaluationContext, this.options.imageUrl);
				}
			}
		});
	},
	beforeDestroy() {
		this.$root.$off('co.thumbnails.loaded');
	},
	methods: {
		async imageClick() {
			if (!this.allowFullscreenPreview) { return; }

			let url = await this.formImageUrl(this.options, 'original');
			url += '?token=' + localStorage.getItem(sc.classes.get('authentication').getTokenKey(sc.cluster.key));
			this.$root.$emit('dialog.fullscreen.open', {
				name: 'image-fullscreen-preview',
				options: { url }
			});
			tracking.AddUserActivity('image.opened', 'image details opened', this.evaluationContext.eval(this.options.imageRef));
		}
	}
};
</script>
<style src="./images.mixins.less" scoped></style>
