import Vue from 'vue';
Vue.directive('clickoutside', (() => {
	const detach = (element, clickHandler) => {
		const state = stateMap.get(element);
		if (state) {
			clickHandler.removeEventListener(event, state.documentClick);
			element.removeEventListener(event, state.stopProp);
		}
	};
	let event = 'click';
	const stateMap = new WeakMap();
	return {
		inserted(element, binding) {
			const clickTarget = binding.value.clickTarget || element;
			let clickHandler = clickTarget && clickTarget.closest('.clickoutside');
			if (!clickHandler || clickTarget === clickHandler) {
				clickHandler = document;
			}
			const callback = binding.value.callback;
			element.classList.add('clickoutside');
			event = binding.value.event || event;

			const state = {
				documentClick() {
					element.click(); // fire inner clickoutside events
					callback();
					clickHandler.removeEventListener(event, state.documentClick);
					element.removeEventListener(event, state.stopProp);
				},
				stopProp(e) {
					e.stopPropagation();
				}
			};
			clickHandler.addEventListener(event, state.documentClick);
			element.addEventListener(event, state.stopProp);
			stateMap.set(element, state);
		},
		componentUpdated(element, binding) {
			const clickTarget = binding.value.clickTarget || element;
			let clickHandler = clickTarget && clickTarget.parentNode.closest('.clickoutside');
			const value = binding.value.visible;
			if (!clickHandler) {
				clickHandler = document;
			}
			if (!value) {
				detach(element, clickHandler);
			}
		},
		unbind(element, binding) {
			const clickTarget = binding.value.clickTarget || element;
			let clickHandler = clickTarget.parentNode && clickTarget.parentNode.closest('.clickoutside');
			if (!clickHandler) {
				clickHandler = document;
			}
			detach(element, clickHandler);
		}
	};
})());
