<template>
	<div class="searchResults-thumbnailview" :class="cssClass">
		<div class="searchResults-thumbnail-view-header tableview-header" v-if="results && results.length && options.withCheckAll">
			<ControlSelectAll :stateNamespace="stateNamespace"
							  :results="results"
							  :withCheckAll="options.withCheckAll"
							  :isParent="options.isParent"
							  :targetEntity="targetEntity" />
			<div class="p2">
				<span v-localization="{ key: 'common.dictionary.selectAll' }"></span>
			</div>
		</div>
		<div class="perfectscroll-wrapper" v-perfectscroll="{enable: true, refreshScroll: paginationParams.activePages.length < 2, onScroll}" v-if="results&&results.length">
			<div class="sc-flex" v-flex-row="{}">
				<div class="item" v-bind:style="additionalStyles" v-for="item in results" :key="recordKey(item)">
					<a v-if="options.enableNavigation" :href="isLinkToPrevent ? void(0) : formReferehceUrl(item)"
					   @click.stop="checkEnableNavigation($event, item)">
						<VirtualCheckbox v-bind:record="item"
										 v-bind:stateNamespace="stateNamespace"
										 v-bind:isParent="options.isParent"
										 v-bind:syncSelection="options.syncSelection"
										 v-bind:evaluationContext="wrapResult(item)"
										 v-if="options.allowSelection"></VirtualCheckbox>
						<div class="item-wrapper">
							<control v-if="options.galleryComponent" v-bind:name="options.galleryComponent.$type"
									 v-bind:contentProps="options.galleryComponent"
									 v-bind:evaluationContext="wrapResult(item)"></control>

							<div class="info">
								<control v-bind:name="options.contentComponent.$type"
										 v-bind:contentProps="options.contentComponent"
										 v-bind:evaluationContext="wrapResult(item)"></control>
							</div>
						</div>
					</a>
					<div v-else class="disable-navigation">
						<VirtualCheckbox v-bind:record="item"
										 v-bind:stateNamespace="stateNamespace"
										 v-bind:isParent="options.isParent"
										 v-bind:syncSelection="options.syncSelection"
										 v-bind:evaluationContext="wrapResult(item)"
										 v-if="options.allowSelection"></VirtualCheckbox>
						<div class="item-wrapper">
							<control v-if="options.galleryComponent" v-bind:name="options.galleryComponent.$type"
									 v-bind:contentProps="options.galleryComponent"
									 v-bind:evaluationContext="wrapResult(item)"></control>

							<div class="info">
								<control v-bind:name="options.contentComponent.$type"
										 v-bind:contentProps="options.contentComponent"
										 v-bind:evaluationContext="wrapResult(item)"></control>
							</div>
						</div>
					</div>
				</div>
			</div>
			<pagination class="bottomed"
						:is="paginationComponent"
						:pageSize="paginationParams.pageSize"
						:total="paginationParams.total"
						:from="paginationParams.from"
						:activePages="paginationParams.activePages"></pagination>
		</div>
		<div class="no-results-message" v-show="!results || !results.length">
			<div style="width: 100%" v-localization="{ key: 'common.dictionary.noMatchesFound' }"></div>
			<div v-if="hasNoMatchesFoundSubTitle" :style="{ width: '100%' }" v-localization="{ key: 'common.dictionary.noMatchesFoundSubTitle' }"></div>
		</div>
	</div>
</template>
<script>
/* eslint no-unused-vars: 0 */
/* eslint vue/no-unused-components: 0 */
import FlexRowDirective from './../../../../../Bindings/flex-row.directive';
import Pagination from '../../../pagination/pagination';
import MobilePagination from '../../../pagination/mobile-pagination';
import ComponentMixins from '../../../../component.mixins';
import ControlSelectAll from '@/Components/Control/Checkboxes/control-select-all';
import VirtualCheckbox from '@/Components/Control/Checkboxes/virtual-checkbox';
import { recordLink } from '@acx-xms/data-functions/dist';
import { createEvaluationContext, evaluate } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'component-set-search-results-thumbnailview',
	props: {
		options: Object,
		results: Array,
		paginationParams: Object,
		stateNamespace: String,
		hasNoMatchesFoundSubTitle: Boolean
	},
	data() {
		return {
			columns: 1,
			images: [],
			combinedNamespace: this.options.namespace + '.' + this.options.internalNamespace,
			targetEntity: evaluate(this.options.targetEntity),
			paginationComponent: this.options.paginationComponent ? evaluate(this.options.paginationComponent) : 'Pagination',
			itemHeight: this.options.itemHeight && evaluate(this.options.itemHeight),
			parentScrollNamespace: this.options.parentScrollNamespace,
			cssClass: this.options.cssClass
		};
	},
	components: {
		Control: () => import('./../../../../Entity/control.vue'),
		Pagination,
		MobilePagination,
		ControlSelectAll,
		VirtualCheckbox
	},
	created() {
		this.$on('.pageChanged', (activePages) => {
			// todo use state managment
			this.$parent.$emit('.pageChanged', activePages);
			this.parentScrollNamespace && this.paginationParams.activePages.length < 2 && this.$root.$emit(this.parentScrollNamespace + '.scrollTabToTop');
		});
		this.isLinkToPrevent = this.options.layoutsToPreventLink
			? this.options.layoutsToPreventLink.find(item => this.$route.params.layout.indexOf(item) !== -1)
			: false;
	},
	computed: {
		additionalStyles() {
			const styles = {};
			if (this.itemHeight) {
				styles.height = this.itemHeight;
			}
			return styles;
		}
	},
	methods: {
		onScroll() {
			this.combinedNamespace && this.$root.$emit(this.combinedNamespace + '.scrolled');
		},
		recordKey(item) {
			return ComponentMixins.recordKey(item);
		},
		wrapResult(result) {
			return createEvaluationContext(result);
		},
		checkEnableNavigation(e, item) {
			e.preventDefault();

			this.options.enableNavigation && this.$router.push(this.formReferehceUrl(item));
		},
		formReferehceUrl(item) {
			if (!this.options.enableNavigation) {
				return '#';
			}
			let linkReferenceValue = sc.classes.get('entityReference', item);

			if (this.options.linkReferenceValue) {
				const context = this.wrapResult(item);
				linkReferenceValue = sc.classes.get('entityReference', context.eval(this.options.linkReferenceValue));
			}

			return recordLink(this.$route, linkReferenceValue, undefined);
		}
	}
};
</script>
<style src='./search-results-thumbnail-view.less' scoped></style>
