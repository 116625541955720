export default {
	async enabled(options, evaluationContext, selection) {
		if (selection && selection.length === 1) {
			const systemuserserviceaccountCount = sc.utils.findProperty(selection[0], 'Source.RelationCounts.systemuserserviceaccount');
			// if 0 or undefined - then return true
			return !systemuserserviceaccountCount;
		} else {
			return false;
		}
	},
	async execute(options, evaluationContext, selection) {
		sc.events.emit('vue.dialog.open', {
			component: 'add-service-dialog',
			maximizedWidth: '300px',
			maximizedHeight: 'max-content',
			title: 'Add account'
		});
	}
};
