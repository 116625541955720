import { isActive } from './Methods/action.methods';
import {
	Create, Update, Search
} from '@acx-xms/data-functions/dist';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import { Get as GetItem } from '@/Data/DataProviders/userStorageDataProvider';
import router from '@/router';

export default {
	async enabled(options, evaluationContext, selection) {
		return selection && selection.length > 0 && await isActive(evaluationContext, selection);
	},
	async execute(options, context, selection) {
		const refreshEvent = context.eval(options.refreshEvent);
		const loadingEvent = context.eval(options.loadingEvent);
		const emitLoading = (isLoading) => {
			loadingEvent && sc.events.emit(loadingEvent, isLoading);
		};
		emitLoading(true);
		const updateParameters = {};
		options.updateData.forEach((data) => {
			updateParameters[context.eval(data.field)] = context.eval(data.value);
		});
		const getChatsPromise = Search(['chat'], [
			sc.classes.get('offsetSize.filter', 1),
			sc.classes.get('termFacet.filter', {
				logicalName: 'collaborationroomid.id',
				query: [context.eval({
					$type: 'expression.field',
					name: 'collaborationroomid.id',
					schema: 'source'
				})]
			}).fillQuery(),
			sc.classes.get('selectedFields.filter', [
				{ logicalname: 'chatparticipants.chatparticipantsystemuseridsystemuser.systemuserid' },
				{ logicalname: 'chatparticipants.name' },
				{ logicalname: 'chatparticipants.chatid' },
				{ logicalname: 'chatparticipants.contactid' },
				{ logicalname: 'chatparticipants.nonregistered' },
				{ logicalname: 'chatparticipants.chatparticipantcontactidcontact.fullname' },
				{ logicalname: 'chatparticipants.chatparticipantchatidchat.collaborationroomid' },
				{ logicalname: 'chatparticipants.systemuserid.id' },
				{ logicalname: 'chatparticipants.systemuserid.logicalname' },
				{ logicalname: 'chatparticipants.ispending' },
				{ logicalname: 'chatparticipants.nonregistered' },
				{ logicalname: 'chatcollaborationroomidcollaborationroom.crnumber' },
				{ logicalname: 'chatcollaborationroomidcollaborationroom.name' }
			]).fillQuery()
		]);
		const entityReference = options.entityReference ? context.eval(options.entityReference) : sc.classes.get('entityReference', selection[0]);
		const updatePromise = Update(entityReference.logicalname, entityReference.id, updateParameters, null, { syncProcessMode: 'All' });
		const [{ Results: chats }, { systemuserid }] = await Promise.all([getChatsPromise, GetUserInfo(), updatePromise]);
		const lspath = `${router.currentRoute.params.layout}_${router.currentRoute.params.type}_${router.currentRoute.params.id}`;
		const userName = await GetItem(lspath);
		const chatparticipants = chats[0].Source.chatparticipants;
		const participant = chatparticipants.filter(cp =>
			cp.chatparticipantsystemuseridsystemuser && (cp.chatparticipantsystemuseridsystemuser.systemuserid === systemuserid) && ((cp.name === userName) || !userName)
		).pop();
		const roomData = chats[0].Source.chatcollaborationroomidcollaborationroom;
		const messageBody = {
			type: 'chatmessage',
			messagetext: `has set ${context.entity.name} as Primary Listing`,
			chatid: participant.chatid,
			contactid: participant.contactid,
			contactname: participant.nonregistered !== true ? participant.chatparticipantcontactidcontact.fullname : participant.name,
			createdonfromui: new Date(),
			messagetype: 'Action',
			visibleto: 'All Users'
		};
		const notificationBody = (recepient) => {
			return {
				userInfo: JSON.stringify({
					relatedRecord: participant.contactid,
					content: {
						chatId: participant.chatid.id,
						navigationRef: participant.chatparticipantchatidchat.collaborationroomid
					}
				}),
				headings: { 'en-us': 'Primary Listing Set' },
				recepientid: recepient,
				contents: { 'en-us': `${participant.name} has set Primary status to a Listing in your Collaboration Room ${roomData.crnumber}, ${roomData.name}` },
				notificationtype: {
					id: '63ef34ff-cc6e-4a57-8826-d0cde07843fe',
					logicalname: 'lookupnotificationtype'
				}
			};
		};
		const potentialRecepients = chatparticipants.filter(cp => cp.systemuserid && cp.systemuserid.id !== systemuserid && !cp.ispending && cp.nonregistered !== true);
		const notificationDisabledByUsers = [];
		// to check if notification is disabled for chat participants
		const notificationPromises = potentialRecepients.map(recepient => {
			const primaryListingNotificationId = '5d85a548-720e-49ad-8d56-7831ef428ab9';
			const promise = Search(['systemusernotificationssettings'], [
				sc.classes.get('offsetSize.filter', 1),
				sc.classes.get('termFacet.filter', {
					logicalName: 'ownerid.id',
					query: [recepient.systemuserid.id]
				}).fillQuery(),
				sc.classes.get('termFacet.filter', {
					logicalName: 'notificationtype.id',
					query: [primaryListingNotificationId]
				}).fillQuery(),
				sc.classes.get('termFacet.filter', {
					logicalName: 'enabled',
					query: [false]
				}).fillQuery(),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'ownerid.id' }
				]).fillQuery()
			]);
			return promise.then(data => {
				if (data.Results.length) {
					notificationDisabledByUsers.push(data.Results[0].Source.ownerid.id);
				}
			});
		});
		await Promise.all(notificationPromises);
		const correctRecepients = notificationDisabledByUsers.length === 0
			? potentialRecepients
			: potentialRecepients.filter(recepient => {
				let isCorrectRecepient = true;
				notificationDisabledByUsers.forEach(id => {
					if (id === recepient.systemuserid.id) {
						isCorrectRecepient = false;
					}
				});
				if (isCorrectRecepient) {
					return recepient;
				}
				return false;
			});
		const promiseArray = [Create('chatmessage', messageBody)];
		if (correctRecepients) {
			correctRecepients.forEach(recepient =>
				promiseArray.push(Create('notification', notificationBody(recepient.systemuserid)))
			);
		}
		Promise.all(promiseArray);
		setTimeout(async () => {
			emitLoading(false);
			refreshEvent && sc.events.emit(refreshEvent);
		}, 500);
	}
};
