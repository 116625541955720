<template>
	<!-- tooltip-->
	<div class="column" v-bind:class="cssClass" v-if="visible">
		<control v-bind:name="item.$type" v-bind:contentProps="item" v-bind:evaluationContext="evaluationContext" v-for="item in controls" :key="item.$key"></control>
	</div>
</template>
<script>
import helperMethods from './../ComponentSet/component-set-evaluation-context-helper.methods';

export default {
	name: 'control-column',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			visible: this.options.visible !== void 0 ? this.evaluationContext.eval(this.options.visible) : true,
			controls: [],
			cssClass: this.evaluationContext.eval(this.options.cssClass) || ''
		};
	},
	components: { Control: () => import('./../Entity/control.vue') },
	created() {
		this.controls = _.cloneDeep(this.options.control);
		if (this.controls && this.controls.length && this.options.parentData) {
			this.controls.forEach(control => {
				control.parentData = this.options.parentData;
				control.$key = helperMethods.getRandomArbitary(1, 10000000000000000).toFixed();
			});
		}
	}
};
</script>
