<template>
	<div
		v-if="withCheckAll"
		v-data-attr="{ options: $parent.options, stateNamespace: `select-all-${checkAllState}` }"
		class="checkbox-wrapper toggle-all"
		@click="onSelectAll"
	>
		<div :class="['virtual-checkbox clickable', checkAllState]">
			<svg class="svg-icon">
				<use
					:xlink:href="icon"
					:class="iconClasses"
				/>
			</svg>
		</div>
	</div>
</template>
<script>
/* eslint vue/no-mutating-props: 0 */
// TODO review
import '@/Bindings/ui-attribute.directive';

const STATES = {
	unselected: 'unselected',
	indeterminate: 'indeterminate',
	selected: 'selected'
};
const RECORDSTATE = ['default', 'partiallySelected'];
const ICONS = {
	partially: 'action-icons-selection-checked-partially',
	full: 'layout-icons-ms-checkbox'
};

export default {
	name: 'control-select-all',
	props: {
		results: Array,
		stateNamespace: String,
		withCheckAll: Boolean,
		isParent: Boolean,
		syncSelection: Boolean,
		targetEntity: Array
	},
	created() {
		this.$root.$on('disableResult', (id) => {
			this.results = this.results.filter(result => result.Id !== id);
		});
	},
	beforeDestroy() {
		this.$root.$off('disableResult');
	},
	methods: {
		onSelectAll() {
			if (!this.results.length) return;

			switch (this.checkAllState) {
			case STATES.unselected:
			case STATES.indeterminate:
				// select all visible elements
				this.$store.commit(`${this.stateNamespace}/changeSelection`, {
					records: this.filteredResults,
					select: true
				});
				if (this.isParent && this.syncSelection) {
					this.results.forEach((record) => {
						this.$root.$emit(`${this.stateNamespace}.syncSelection.parent.${record.Id}`, true);
					});
				}
				break;
			case STATES.selected:
				// unselect all visible elements
				this.$store.commit(`${this.stateNamespace}/changeSelection`, {
					records: this.filteredResults,
					select: false
				});
				if (this.isParent && this.syncSelection) {
					this.results.forEach((record) => {
						this.$root.$emit(`${this.stateNamespace}.syncSelection.parent.${record.Id}`, false);
					});
				}
				break;
			}
		}
	},
	computed: {
		filteredResults() {
			return this.results.filter(record => {
				const recordState = this.$store.getters[`${this.stateNamespace}/isRecordPreselected`](record);
				return RECORDSTATE.includes(recordState);
			}) || [];
		},
		checkAllState() {
			if (!this.selectedRecords || !this.selectedRecords.length || !this.inCurrentSelection) {
				return STATES.unselected;
			} else if (this.selectedRecords && this.inCurrentSelection && !this.sameSelection) {
				return STATES.indeterminate;
			}
			return STATES.selected;
		},
		selection() {
			return this.$store.getters[`${this.stateNamespace}/getSelection`];
		},
		selectedRecords() {
			let result = [];
			this.targetEntity.forEach(entity => {
				if (this.selection[entity]) result = result.concat(this.selection[entity]);
			});
			return result;
		},
		sameSelection() {
			const filtered = this.filteredResults && this.filteredResults.map(element => element.Id);
			const selected = this.selectedRecords && this.selectedRecords.map(element => element.Id);
			/* Every element on page must be in selection */
			return filtered.every(element => selected.includes(element));
		},
		inCurrentSelection() {
			const filtered = this.filteredResults && this.filteredResults.map(element => element.Id);
			const selected = this.selectedRecords && this.selectedRecords.map(element => element.Id);
			/* One element on page must be in selection */
			return selected.some(element => filtered.includes(element));
		},
		isIndeterminate() {
			return this.checkAllState === STATES.indeterminate;
		},
		icon() {
			return this.isIndeterminate ? `#${ICONS.partially}` : `#${ICONS.full}`;
		},
		iconClasses() {
			return this.isIndeterminate ? ICONS.partially : ICONS.full;
		}
	}
};
</script>
<style src="./virtual-checkbox.less" scoped></style>
