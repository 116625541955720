<template>
	<div class="flexible-container" v-if="!isLoading" v-show="visible" :style="{ 'flex-direction': flexDirection, 'flex-wrap': wrap }" :class="getCssClassValue(customOptions.cssClass)">
		<div class="flexible-item" v-for="(item, index) in customOptions.item" :key="index" :class="getCssClassValue(item.cssClass)" v-show="item.control.visible" :style="getStyleProperties(item)">
			<control :name="item.control.$type" :contentProps="item.control" :evaluationContext="evaluationContext"></control>
		</div>
	</div>
</template>
<script>
export default {
	name: 'control-flexiblecontainer',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			// workaround: someone is modifying props at some point. Possibly the root cause is documents search results (2 search results on 1 page).
			// See Bug 149319: Folders.The folder icon isn't displayed, the folder name isn't clickable in search results
			customOptions: _.cloneDeep(this.options),
			flexDirection: null, // this.options.direction ? this.options.direction : 'row',
			visible: false,
			wrap: '',
			isLoading: true
		};
	},
	components: { Control: () => import('@/Components/Entity/control.vue') },
	async created() {
		this.wrap = this.customOptions.wrap || 'wrap';
		this.visible = this.customOptions.visible !== void 0 ? await this.evaluationContext.evalAsync(this.customOptions.visible) : true;
		const calcVisible = async () => {
			for (const [, item] of this.customOptions.item.entries()) {
				const visible = await this.evaluationContext.evalAsync(item.control.visible !== void 0 ? item.control.visible : true);
				item.control.visible = visible;
			}
		};
		await calcVisible();
		this.isLoading = false;
	},
	mounted() {
		this.flexDirection = this.customOptions.direction ? this.customOptions.direction : 'row';
	},
	methods: {
		getCssClassValue(cssClass) {
			return this.evaluationContext.eval(cssClass);
		},
		getStyleProperties(item) {
			const obj = {
				width: item.width,
				'flex-grow': item.grow,
				'min-width': item.minWidth
			};
			if (item.shrink) {
				obj['flex-shrink'] = item.shrink;
			}
			return obj;
		}
	}
};
</script>
<style src="./control-flexiblecontainer.less" scoped></style>
