import { render, staticRenderFns } from "./mobile-menu-userprofile-with-viewprofile.vue?vue&type=template&id=25dc1d4c&"
import script from "./mobile-menu-userprofile-with-viewprofile.vue?vue&type=script&lang=js&"
export * from "./mobile-menu-userprofile-with-viewprofile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\SCCore\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('25dc1d4c', component.options)
    } else {
      api.reload('25dc1d4c', component.options)
    }
    module.hot.accept("./mobile-menu-userprofile-with-viewprofile.vue?vue&type=template&id=25dc1d4c&", function () {
      api.rerender('25dc1d4c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Scripts/Vue/Components/Menu/menu-marketspace-userprofile/mobile-menu-userprofile-with-viewprofile.vue"
export default component.exports