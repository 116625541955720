import { render, staticRenderFns } from "./AppWrapper.vue?vue&type=template&id=037b14a0&"
import script from "./AppWrapper.vue?vue&type=script&lang=js&"
export * from "./AppWrapper.vue?vue&type=script&lang=js&"
import style0 from "./AppWrapper.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./global.less?vue&type=custom&index=0&blockType=styles&issuerPath=D%3A%5Ca%5C1%5Cs%5CSCCore%5CScripts%5CVue%5CAppWrapper.vue"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\SCCore\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('037b14a0', component.options)
    } else {
      api.reload('037b14a0', component.options)
    }
    module.hot.accept("./AppWrapper.vue?vue&type=template&id=037b14a0&", function () {
      api.rerender('037b14a0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Scripts/Vue/AppWrapper.vue"
export default component.exports