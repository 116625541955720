var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "breadcrumbs" }, [
    _c("div", { staticClass: "breadcrumb-item", on: { click: _vm.navigate } }, [
      _c("span", { staticClass: "icon" }, [
        _c("svg", { staticClass: "svg-icon svg-12 back-button" }, [
          _c("use", {
            attrs: { "xlink:href": "#layout-icons-details-header-back" }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.firstItem.title))])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "breadcrumb-item" },
      [
        _c("h1", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.secondItem.title))
        ]),
        _vm._v(" "),
        _vm.label
          ? _c("ControlLabel", {
              attrs: {
                evaluationContext: _vm.label.context,
                options: _vm.label.options
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }