import {
	mapState, mapActions, mapGetters
} from 'vuex';


const VALIDATION_PATTERN =  /^[a-zA-Z0-9!#$%&\/\\'*+=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&\/\\'*+=?^_`{|}~-]+)*?$/m;
export const wizardValidation = {
	computed: {
		...mapState('wizard', ['steps']),
		...mapGetters('wizard.navigation', ['getFilled']),
		...mapGetters('wizard', ['isTemplateSelected', 'geteSelectedTemplate']),
		isArtifactAvailable() {
			return this.steps.find(i => i.name === 'template').artifact;
		},
		getCollectionType() {
			return this.steps.find(i => i.name === 'template').collectionType || null;
		},
		isSetupStepValid() {
			const form = this.steps.find(i => i.name === 'setup').form;
			const formFields = Object.keys(form);
			const validation = formFields.filter(i => i !== 'preview_text').reduce((a, i) => {
				const input = form[i].value.length;
				const limit = ['campaign_name', 'from_name'].some(key => key === i) ? 100 : 150;
				
				if (i === 'email') {
					if (input > 0 && input <= limit && VALIDATION_PATTERN.test(form[i].value)) {
						return [...a];
					} else {
						return [...a, {
							input: i,
							value: form[i].value
						}];
					}
				} else {
					return input > 0 && input <= limit
						? [...a]
						: [...a, {
							input: i,
							value: form[i].value
						}];
				}
			}, []);
			return !validation.length;
		},
		isTemplateStepValid() {
			return this.isTemplateSelected || !!this.isArtifactAvailable;
		},
        listings() {
            return (this.$store.state && this.$store.state['wizard.listing'] &&
            this.$store.state['wizard.listing'].selection &&
            this.$store.state['wizard.listing'].selection.listing &&
            Array.isArray(this.$store.state['wizard.listing'].selection.listing) &&
            !!this.$store.state['wizard.listing'].selection.listing.length &&
            this.$store.state['wizard.listing'].selection.listing) || 
            (!!this.$store.state.wizard.steps.find(i => i.name === 'collection').selection.listing.length && 
            this.$store.state.wizard.steps.find(i => i.name === 'collection').selection.listing) || null;
        },
        availabilities() {
            return (this.$store.state && this.$store.state['wizard.listing'] &&
            this.$store.state['wizard.listing'].selection &&
            this.$store.state['wizard.listing'].selection.availability &&
            Array.isArray(this.$store.state['wizard.listing'].selection.availability) &&
            !!this.$store.state['wizard.listing'].selection.availability.length &&
            this.$store.state['wizard.listing'].selection.availability) || 
            (!!this.$store.state.wizard.steps.find(i => i.name === 'collection').selection.availability && 
            !!this.$store.state.wizard.steps.find(i => i.name === 'collection').selection.availability.length && 
            this.$store.state.wizard.steps.find(i => i.name === 'collection').selection.availability) || null;
        },
        isLimitError() {
            let LISTINGS = this.listings;
            let AVAILABILITIES = this.availabilities;
            let TYPE = this.getCollectionType;
            if (TYPE === 'listing' && LISTINGS && LISTINGS.length > 100){
                return true
            } else if (TYPE === 'availability' && AVAILABILITIES && AVAILABILITIES.length > 100) {
                return true
            }
            return false
        },
		isCollectionStepValid() {
            let TYPE = this.getCollectionType;
            let LISTINGS = this.listings;
            let AVAILABILITIES = this.availabilities;
			if (!TYPE || (TYPE === 'collection')) {
                return LISTINGS && LISTINGS.length > 0 && LISTINGS.length <= 100 || false
            } else if (TYPE === 'listing'){
                return LISTINGS && LISTINGS.length > 0 && LISTINGS.length <= 100 || false
            } else if (TYPE === 'availability') {
               return AVAILABILITIES && AVAILABILITIES.length > 0 && AVAILABILITIES.length <= 100 || false
            }
		},
		isRecepientsStepValid() {
			return (this.$store.state &&
				this.$store.state['wizard.ml'] &&
				this.$store.state['wizard.ml'].selection &&
				this.$store.state['wizard.ml'].selection.staticlist &&
				!!this.$store.state['wizard.ml'].selection.staticlist.length) || (!!this.$store.state.wizard.steps.find(i => i.name === 'recepients').selection.length) || false;
		},
		stepsValidationStatus() {
			return {
				1: this.isSetupStepValid,
				2: this.isTemplateStepValid,
				3: this.isCollectionStepValid,
				4: this.isRecepientsStepValid
			};
		}
	},
	watch: {
		stepsValidationStatus: function (newVal, oldVal) {
			const statuses = Object.keys(newVal).reduce((a, i) => {
				this.updateStepValidationStatus({
					index: i,
					status: (newVal[i] && true) || false
				});
				return newVal[i] ? [...a, Number(i)] : [...a];
			}, []);

			const validStatuses = statuses.filter(i => ![3, 4].includes(i));
			let disabledStatuses = [];

            if (validStatuses.includes(2)) {
                disabledStatuses = this.isLimitError ? [5] : {}
            } else {
                disabledStatuses = this.isArtifactAvailable ? [] : [5]
            }
            
			const disabledFilled = [];

			[3, 4].forEach((i) => {
				if (validStatuses.includes(2) || this.isArtifactAvailable) {
					if (statuses.includes(i)) {
                        if (i === 4 && this.isLimitError) {
                            disabledFilled.push(i);
                        } else {
						    validStatuses.push(i);
                        }
					} else if (i === 4 && this.isLimitError) {
                        disabledStatuses.push(i);
                    }
				} else {
                    statuses.includes(i) ? disabledFilled.push(i) : disabledStatuses.push(i)
				}
			});

			const invalidStatuses = { 2: (!this.steps.find(i => i.name === 'template').isTempalteAvailable && !newVal['2']) || false };
			const filteredInvalidStatuses = Object.keys(invalidStatuses).reduce((a, i) => {
				return invalidStatuses[i] ? [...a, Number(i)] : [...a];
			}, []);
			this.setFilled(validStatuses || []);
			this.setDisabled(disabledStatuses);
			this.setDisabledFilled(disabledFilled);
			this.setInvalid(filteredInvalidStatuses);
			// change collection step-counter title
			this.changeStepName();
            // Limit error
            if (this.isLimitError) {
                this.updateLimitError(true);
                this.setErrors([{
                    text: 'The max number of 100 records was chosen.',
                    type: 'warning',
                    close: true,
                    step: 'collection',
                    name: 'maxSelectionAmount',
                }])
            } else {
                this.removeError({name: 'maxSelectionAmount', step: 'collection'})
                this.updateLimitError(false);
            }
            // Availability template type without availabilities
            if (this.getCollectionType === 'availability' && this.listings && this.listings.length > 0 && !this.availabilities) {
                this.setErrors([{
                    text: 'It seems like no listings with availabilities were selected. For the “Availability” template type, only listings that contain availabilities must be used for successful report generation. Ensure that your selection meets this requirement before starting report generating.',
                    type: 'warning',
                    close: true,
                    step: 'collection',
                    name: 'emptyAvailabilitiesError',
                }])
                this.updateCollectionLimitWarning(true)
            } else {
                this.removeError({name: 'emptyAvailabilitiesError', step: 'collection'});
                this.updateCollectionLimitWarning(false)
            }
		}
	},
	methods: {
		...mapActions('wizard', ['updateActiveStep', 'updateNavigation', 'updateStepValidationStatus', 'setErrors', 'removeError', 'updateLimitError', 'updateCollectionLimitWarning']),
		...mapActions('wizard.navigation', ['setActive', 'setSteps', 'setDisabled', 'setFilled', 'setDisabledFilled', 'setInvalid', 'setCaptions'])
	},
	mounted() {
		if (this.steps[2].selection.listing.length || this.steps[2].selection.availability.length) {
			this.$nextTick(() => { this.changeStepName(); });
		}
	}
};
