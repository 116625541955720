<template>
	<div class="breadcrumbs">
		<div class="breadcrumb-item" @click="navigate">
			<span class="icon">
				<svg class='svg-icon svg-12 back-button'>
					<use xlink:href='#layout-icons-details-header-back'></use>
				</svg>
			</span>
			<div class="title">{{ firstItem.title }}</div>
		</div>
		<div class="breadcrumb-item">
			<h1 class="title">{{ secondItem.title }}</h1>
			<ControlLabel v-if="label" :evaluationContext="label.context" :options="label.options" />
		</div>
	</div>
</template>
<script>

import { controlLabel } from '@acx-xms/controls';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'control-breadcrumbs',
	components: { ControlLabel: controlLabel },
	props: {
		evaluationContext: Object,
		breadcrumbs: Array,
		isDeactivated: {
			type: Boolean,
			default: false
		},
		labelOptions: Object
	},
	data() {
		return {
			firstItem: this.breadcrumbs[this.breadcrumbs.length - 2],
			secondItem: this.breadcrumbs[this.breadcrumbs.length - 1]
		};
	},
	computed: {
		label() {
			return this.isDeactivated
				? this.inactive
				: {
					...this.labelOptions,
					context: createEvaluationContext({})
				};
		},
		inactive() {
			return {
				context: this.evaluationContext || this.$parent.evaluationContext || createEvaluationContext({}),
				options: {
					visible: { $type: 'expression.true' },
					cssClass: {
						$type: 'expression.text',
						value: 'inactive ml10'
					},
					text: {
						$type: 'expression.text',
						value: 'Inactive'
					}
				}
			};
		}
	},
	methods: {
		navigate() {
			this.$parent.$emit('.navigateBreadcrumbs');
		}
	}
};
</script>
<style src="./breadcrumbs.less" scoped></style>
