<template>
	<div class="breadcrumbs">
		<div class="breadcrumb-item" @click="navigate(2)">
			<span class="icon">
				<svg class='svg-icon svg-12 back-button'>
					<use xlink:href='#layout-icons-details-header-back'></use>
				</svg>
			</span>
		</div>
		<div class="breadcrumb-item parent-item" @click="navigate(1)">
			<div class="title">{{ firstItem.title }}</div>
		</div>
		<div class="breadcrumb-item">
			<div class="title">{{ secondItem.title }}</div>
		</div>
	</div>
</template>
<script>

export default {
	name: 'control-extended-breadcrumbs',
	props: { breadcrumbs: Array },
	data() {
		return {
			firstItem: this.breadcrumbs[this.breadcrumbs.length - 2],
			secondItem: this.breadcrumbs[this.breadcrumbs.length - 1]
		};
	},
	methods: {
		navigate(index) {
			this.$parent.$emit('.navigateBreadcrumbs', index);
		}
	}
};
</script>
<style src="./breadcrumbs.less" scoped></style>
<style src="./extended-breadcrumbs.less" scoped></style>
