var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "create-collaborationroom-from-crm" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay"
    }),
    _vm._v(" "),
    _c("div", { staticClass: "header" }, [
      _c("h2", {
        directives: [
          {
            name: "localization",
            rawName: "v-localization",
            value: { key: "CreateRoom.createCR.title" },
            expression: "{key: 'CreateRoom.createCR.title'}"
          }
        ]
      })
    ]),
    _vm._v(" "),
    !_vm.isLoading
      ? _c(
          "div",
          {
            directives: [
              {
                name: "perfectscroll",
                rawName: "v-perfectscroll",
                value: { enable: true },
                expression: "{enable: true}"
              }
            ],
            staticClass: "body"
          },
          [
            _c(
              "div",
              { staticClass: "editor" },
              [
                _vm.editorTemplate
                  ? _c("control", {
                      attrs: {
                        name: _vm.editorTemplate.$type,
                        contentProps: _vm.editorTemplate,
                        evaluationContext: _vm.controlContext
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("h5", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "CreateRoom.contactsTable.title" },
                  expression: "{key: 'CreateRoom.contactsTable.title'}"
                }
              ],
              staticClass: "title"
            }),
            _vm._v(" "),
            !_vm.syncContactProcessId
              ? _c(
                  "div",
                  { staticClass: "entity-list" },
                  [
                    _vm.userListTemplate
                      ? _c("control", {
                          staticClass: "formcontrol-template",
                          attrs: {
                            name: _vm.userListTemplate.$type,
                            contentProps: _vm.userListTemplate,
                            evaluationContext: _vm.evaluationContext,
                            initResults: _vm.contacts
                          }
                        })
                      : _vm.noContactInCRM
                      ? _c("div", { staticClass: "p13" }, [
                          _vm._v(_vm._s(_vm.noContactInCRM))
                        ])
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.syncContactProcessId
              ? _c("SyncProgress", {
                  attrs: { processId: _vm.syncContactProcessId },
                  on: {
                    onSyncCompleted: function($event) {
                      _vm.onSyncContactCompleted(true)
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("h2", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "addPreferenceDialog.crm.title" },
                  expression: "{key: 'addPreferenceDialog.crm.title'}"
                }
              ],
              staticClass: "title"
            }),
            _vm._v(" "),
            _c("div", { staticClass: "add-preference editor" }, [
              _c("div", { staticClass: "flexible-item" }, [
                _c(
                  "div",
                  { staticClass: "field-wrapper" },
                  [
                    _c("div", {
                      directives: [
                        {
                          name: "localization",
                          rawName: "v-localization",
                          value: { key: "addPreferenceDialog.contact.lookup" },
                          expression:
                            "{key: 'addPreferenceDialog.contact.lookup'}"
                        }
                      ],
                      staticClass: "field-title"
                    }),
                    _vm._v(" "),
                    _c("control-lookup-editor", {
                      attrs: {
                        showRequired: false,
                        options: _vm.getContactLookupOptions()
                      },
                      model: {
                        value: _vm.selectedContact,
                        callback: function($$v) {
                          _vm.selectedContact = $$v
                        },
                        expression: "selectedContact"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flexible-item" }, [
                _c("div", { staticClass: "field-wrapper" }, [
                  _c("div", {
                    directives: [
                      {
                        name: "localization",
                        rawName: "v-localization",
                        value: { key: "addPreferenceDialog.preference.lookup" },
                        expression:
                          "{key: 'addPreferenceDialog.preference.lookup'}"
                      }
                    ],
                    staticClass: "field-title"
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "add-preference" },
                    [
                      _c("control-lookup-editor", {
                        attrs: {
                          defaultValue: _vm.preferenceDefaultValue,
                          showRequired: false,
                          options: _vm.getPreferenceLookupOptions()
                        },
                        model: {
                          value: _vm.selectedPreference,
                          callback: function($$v) {
                            _vm.selectedPreference = $$v
                          },
                          expression: "selectedPreference"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ])
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "dialog-buttons" }, [
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.buttons.cancel" },
              expression: "{key: 'common.dictionary.buttons.cancel'}"
            }
          ],
          staticClass: "cancel",
          attrs: { type: "button" },
          on: { click: _vm.cancel }
        }),
        _vm._v(" "),
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.buttons.save" },
              expression: "{key: 'common.dictionary.buttons.save'}"
            }
          ],
          attrs: { type: "button" },
          on: { click: _vm.save }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }