import { previewConfigTemplate } from '@/Data/DataProviders/templatesDataProvider';
import { mapParametersObject } from './Methods/action.methods';
import { showToastOrInfoDialog } from '@acx-xms/data-functions/dist';

export default {
	async enabled() {
		return true;
	},
	async execute(options, context, selection) {
		const parameters = mapParametersObject(options.parameter, context);
		const isDirty = options.isDirty;
		if (!parameters.entity && selection && selection.length) {
			parameters.entity = sc.classes.get('entityReference', selection[0]);
		}
		if (isDirty) {
			const localizations = sc.classes.get('localization.dataProvider');
			sc.events.emit('vue.dialog.open', {
				icon: 'action-icons-search-save',
				title: await localizations.getLabelForCurrentLanguage('common.dictionary.confirmDlgCaption'),
				component: 'confirm.dialog',
				maximizedWidth: '450px',
				maximizedHeight: 'auto',
				evaluationContext: context,
				onSubmit: () => sc.events.emit('saveTemplate', {
					isPreviewAction: true,
					id: parameters.entity.id
				}),
				message: await localizations.getLabelForCurrentLanguage('common.dictionary.pleaseSaveChanges'),
				okLabel: await localizations.getLabelForCurrentLanguage('common.dictionary.buttons.save')
			});
		} else {
			if (parameters.entity && parameters.entity.id) {
				try {
					await previewConfigTemplate(parameters.entity.id);
					await showToastOrInfoDialog({
						toastMessageKey: context.eval(options.toastMessageKey || 'common.dictionary.configPreviwed'),
						informationDialogTextKey: context.eval(options.informationDialogTextKey)
					});
				} catch (e) {
					sc.events.emit('dialog.error', e);
				}
			}
		}
	}
};
