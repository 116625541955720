<template>
	<virtual-check-box :record="evaluationContext.Source"
					   :stateNamespace="namespace"
					   :isParent="options.isParent"
					   :syncSelection="options.syncSelection"
					   :evaluationContext="evaluationContext"
					   v-if="visible" />
</template>
<script>
import VirtualCheckBox from './virtual-checkbox';
import { isUndefined } from '@acx-xms/data-functions/dist';

export default {
	name: 'control-virtual-checkbox',
	data() {
		return {
			namespace: this.evaluationContext.eval(this.options.stateNamespace),
			visible: isUndefined(this.options.visible) ? true : this.options.visible
		};
	},
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: { VirtualCheckBox }
};
</script>
