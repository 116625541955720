<template>
    <div :class="cssClass">
        <div class="control-container"
             v-for="(item, index) in options.control"
             v-if="innerContext"
			 :key="index">
            <control :name="item.$type"
                     :contentProps="item"
                     :evaluationContext="innerContext">
            </control>
        </div>
    </div>
</template>
<script>
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'control-owndatasource',
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: { Control: () => import('./../Entity/control.vue') },
	data() {
		return {
			cssClass: this.options.cssClass ? this.evaluationContext.eval(this.options.cssClass) : [],
			innerContext: {},
			dataSource: this.evaluationContext.eval(this.options.dataSource)
		};
	},
	created() {
		this.innerContext = createEvaluationContext(this.dataSource);
	}
};
</script>
