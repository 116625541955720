var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible"
        }
      ],
      staticClass: "flexible-field-wrapper",
      class: _vm.css,
      style: {
        width: _vm.options.controlWidth,
        "min-width": _vm.options.minWidth
      }
    },
    [
      _vm.options.icon
        ? _c("div", { staticClass: "icon-wrapper" }, [
            _c("i", { class: "form-icons-" + _vm.options.icon })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.title
        ? _c(
            "div",
            {
              staticClass: "field-title",
              class: _vm.titleClass,
              style: { width: _vm.options.titleWidth }
            },
            [_vm._v(_vm._s(_vm.title))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "field-value",
          style: { width: "calc(100% - " + _vm.options.titleWidth + ")" }
        },
        [
          _c("control", {
            attrs: {
              name: _vm.options.value.$type,
              contentProps: _vm.options.value,
              evaluationContext: _vm.evaluationContext
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }