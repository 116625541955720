import { Search } from '@acx-xms/data-functions/dist';
const ControlLookupEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Lookup/control-lookup-editor');

export default {
	data() {
		return {
			selectedPreference: null,
			selectedContact: null
		};
	},
	components: { ControlLookupEditor },
	async created() {
		const configurationDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		this.preferenceLookupTemplate = await configurationDataProvider.getTemplate('preference', 'list');
		this.userLookupTemplate = await configurationDataProvider.getTemplate('contact', 'custom', 'vue-list');
		this.localization = sc.classes.get('localization.dataProvider');
	},
	methods: {
		getPreferenceLookupOptions() {
			const filters = [
				// TODO: get selected fields from xml
				sc.classes.get('termFacet.filter', {
					logicalName: 'hascollaborationroom',
					query: [false]
				}).fillQuery(),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'name' },
					{ logicalname: 'preferencesaleorleaselookupsaleorlease.name' },
					{ logicalname: 'preferencepropertytypelookuppropertytype.name' },
					{ logicalname: 'propertytype' },
					{ logicalname: 'preferencecontactidcontact.fullname' },
					{ logicalname: 'areaminimum' },
					{ logicalname: 'areamaximum' },
					{ logicalname: 'priceminimum' },
					{ logicalname: 'pricemaximum' },
					{ logicalname: 'collaborationroomid' },
					{ logicalname: 'hascollaborationroom' },
					{ logicalname: 'contactid.id' }
				]).fillQuery()
			];
			if (this.selectedContact) {
				filters.push(sc.classes.get('termFacet.filter', {
					logicalName: 'contactid.id',
					query: [this.selectedContact.id || this.selectedContact.data.Source.contactid]
				}).fillQuery());
			}
			return {
				fetchData: (searchQuery, startFrom, pageSize) => { return this.fetchPreference(searchQuery, startFrom, pageSize, filters); },
				template: this.preferenceLookupTemplate
			};
		},
		getContactLookupOptions() {
			const filters = [
				sc.classes.get('search.relation', {
					entity: 'preference',
					alias: 'preference',
					filter: [
						sc.classes.get('termFacet.filter', {
							logicalName: 'hascollaborationroom',
							query: [false]
						}).fillQuery(),
						sc.classes.get('termFacet.filter', {
							logicalName: 'recordstate.id',
							query: ['9e817a63-452c-e811-8320-7824af3b452f'],
							negative: true
						}).fillQuery()
					],
					output: 'item'
				}).toFilter(),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'contactsystemuseridsystemuser.avatarid.id' },
					{ logicalname: 'recordstate.id' },
					{ logicalname: 'salutation' },
					{ logicalname: 'firstname' },
					{ logicalname: 'lastname' },
					{ logicalname: 'account' },
					{ logicalname: 'jobtitle' },
					{ logicalname: 'contactid' }
				]).fillQuery(),
				sc.classes.get('termFacet.filter', {
					logicalName: 'serviceuser',
					query: [true],
					negative: true
				}).fillQuery()
			];
			return {
				fetchData: (searchQuery, startFrom, pageSize) => {
					return this.fetchData('contact', searchQuery, startFrom, pageSize, filters);
				},
				template: this.userLookupTemplate,
				singleSelectionOnArray: (newValue, oldValue) => {
					if (newValue && ((oldValue && (newValue.data.Source.contactid !== oldValue.data.Source.contactid)) || !oldValue)) { this.selectedPreference = null; }
				}
			};
		},
		fetchPreference(searchQuery, startFrom, pageSize, filters) {
			return this.fetchData('preference', searchQuery, startFrom, pageSize, filters, ['name', 'preferencecontactidcontact.fullname']);
		},

		fetchData(entity, searchQuery, startFrom = 0, pageSize = 1000, filters = [], searchFields = []) {
			const fieldFilterOptions = { query: searchQuery };
			const predefinedFilters = [
				sc.classes.get('offsetFrom.filter', startFrom),
				sc.classes.get('offsetSize.filter', pageSize),
				sc.classes.get('termFacet.filter', {
					logicalName: 'recordstate.id',
					query: ['9e817a63-452c-e811-8320-7824af3b452f'],
					negative: true
				}).fillQuery(),
				...(searchQuery && [sc.classes.get('field.filter', fieldFilterOptions).fillQuery()]),
				...filters
			];

			if (searchQuery && searchFields.length) {
				fieldFilterOptions.logicalName = searchFields.map(f => {
					return { logicalName: f };
				});
				fieldFilterOptions.additionalOptions = { kind: 'PhrasePrefix' };
			} else { fieldFilterOptions.logicalName = '_all'; }

			return Search([entity], predefinedFilters).then(({ Results, Total }) => {
				return {
					total: Total,
					items: Results.map(res => {
						return {
							displayName: res.Name,
							data: res
						};
					})
				};
			});
		},
		getSelectedFields() {

		}
	},

	watch: {
		selectedContact: function (contact) {
			if (contact && contact.data.Source.Relations) {
				const preferenceRelation = contact.data.Source.Relations.preference;
				if (preferenceRelation.length === 1) {
					const preference = preferenceRelation[0];
					this.selectedPreference = {
						displayName: preference.name,
						data: {
							Id: preference.preferenceid,
							Source: preference
						}
					};
				}
			}
		}
	}
};
