/* eslint no-throw-literal: 0 */
import ajaxErrorHandlerModule from './ajaxErrorHandler.module';
import dateTimeLocalizationModule from './datetime-localization.module';
import koComponentLoaderModule from './ko.componentLoader.module';
import localizationModule from './localization.module';
import loggerModule from './logger.module';
import nativeEventRouterModule from './nativeEventRouter.module';
import scMapModule from './scMap.module';
import stringToPickListLoadSettingsModule from './stringToPickList.loadSetttings.module';
import userPrefetchModule from './userPrefetch.module';
import SystemNotificationsModule from './systemNotifications.module';

import { Deferred } from '@acx-xms/data-functions/dist';

// localization module is required in some modules
// userPrefetch - the longest module to install
// by installing these 2 modules first - it saves up to 0.3s of app loading
const classesMapping = {
	'localization.module': localizationModule,
	'userPrefetch.module': userPrefetchModule,
	'ajaxErrorHandler.module': ajaxErrorHandlerModule,
	'datetime-localization.module': dateTimeLocalizationModule,
	'ko.componentLoader.module': koComponentLoaderModule,
	'logger.module': loggerModule,
	'nativeEventRouter.module': nativeEventRouterModule,
	'scMap.module': scMapModule,
	'stringToPickList.loadSetttings.module': stringToPickListLoadSettingsModule,
	'systemNotifications.module': SystemNotificationsModule
};

function modulesInstall(modulesStack, def, prefix) {
	// enumerate all modules in stack and install all modules with resolved depencies
	// subscribe to the done and fail to continue initialization when this will be done
	// all modules should return promise
	let deferredCount = 0;
	let pending = 0;
	Object.entries(modulesStack).forEach(([key, module]) => {
		if (modulesStack[key] === true) {
			// module is already installed
		} else if (modulesStack[key] === false) {
			// module still initializing
			deferredCount++;
		} else {
			let depenciesResolved = true;

			if (module.requires && module.requires.length) {
				for (const d in module.requires) {
					const depency = module.requires[d];
					if (!Object.prototype.hasOwnProperty.call(modulesStack, depency)) {
						def.reject('Cannot install ' + key + ' service, because of unresolvable depency on ' + depency);
					}
					if (modulesStack[depency] !== true) {
						depenciesResolved = false;
						break;
					}
				}
			}
			pending++;
			if (depenciesResolved) {
				console.log('Starting ' + key + ' service...');
				const res = module.install();
				if (res && res.done) { // remove after all dataproviders refactored
					pending--;
					modulesStack[key] = false; // mark module as initializing
					res.done(() => {
						modulesStack[key] = true; // mark module as started
						console.log(key + ' service has been started');
						modulesInstall(modulesStack, def, prefix);
					}).fail(reason => {
						throw 'Service initialization has been stopped: ' + JSON.stringify(reason);
					});
				} else if (res && res.then) {
					pending--;
					modulesStack[key] = false; // mark module as initializing
					res.then(() => {
						modulesStack[key] = true; // mark module as started
						console.log(key + ' service has been started');
						modulesInstall(modulesStack, def, prefix);
					}).catch(reason => {
						throw 'Service initialization has been stopped: ' + reason.toString();
					});
				} else {
					throw 'module.install() should return a promise!';
				}
			}
		}
	});

	if (pending === 0 && deferredCount === 0) {
		console.log(prefix + ': All services have been installed');
		def.resolve();
	}
}

export default async function () {
	const sync = {}; const deferred = {};
	Object.keys(classesMapping).forEach(key => {
		const module = new classesMapping[key]();
		const target = module.deferred ? deferred : sync;
		// 74665
		let requiredModules = ['ajaxErrorHandler.module', 'datetime-localization.module', 'localization.module', 'logger.module', 'nativeEventRouter.module', 'ko.componentLoader.module', 'userPrefetch.module'];
		if (sc.cluster.modules) {
			requiredModules = requiredModules.concat(sc.cluster.modules);
		}
		if (requiredModules.indexOf(key) > -1) {
			target[key] = module;
		}
	});

	const processStack = (stack, prefix) => {
		const def = Deferred();
		modulesInstall(stack, def, prefix);
		return def.promise;
	};

	await processStack(sync, 'Sync modules');
	if (Object.keys(deferred).length) {
		setTimeout(() => {
			processStack({
				...deferred,
				...sync
			}, 'Deferred modules');
		}, 1000);
	}
}
