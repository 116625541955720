export default {
	parameters(options) {
		return [
			this.eval(options.uriComponent)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.uriComponent)
		];
	},
	evaluate(s) {
		if (s === void 0 || s === null) return '';
		if (typeof s !== 'string') s = s.toString();
		return encodeURIComponent(s);
	}
};
