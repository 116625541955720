import {
	getFullRecordLink, emailLink, generateGuid, CreateBulk
} from '@acx-xms/data-functions/dist';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import inviteParticipantsDataProvider from '@/Data/DataProviders/inviteParticipantsDataProvider';

export default {
	methods: {
		async emailLink(invited = []) {
			const emails = invited.map((contact) => {
				return contact.Source.email;
			});

			const { fullname, jobtitle } = await GetUserInfo(true);
			const linkToRecord = getFullRecordLink(this.$route, this.parentRecord);
			const emailBody = await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('inviteParticipantsPlugin.emailBody', [linkToRecord, fullname, jobtitle]);
			const entityName = await this.getEntityDisplayName(this.parentRecord.logicalname);

			const subj = 'Invitation to ' + this.parentRecord.name + ' ' + entityName;
			const url = emailLink(subj, encodeURIComponent(emailBody), emails);
			window.location.href = url;
		},
		async sendNoReplyEmail(invited) {
			const { fullname, jobtitle } = await GetUserInfo(true);
			const linkToRecord = getFullRecordLink(this.$route, this.parentRecord);
			const [entityName, htmlBody] = await Promise.all([
				await this.getEntityDisplayName(this.parentRecord.logicalname),
				await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('inviteParticipantsPlugin.emailBody.html', [linkToRecord, fullname, jobtitle])
			]);
			const subject = 'Invitation to ' + this.parentRecord.name + ' ' + entityName;
			const records = invited.filter(contact => !!contact.Source.email).map((contact) => {
				return	{
					id: generateGuid(),
					url: 'email',
					method: 'POST',
					body: {
						to: contact.Source.email,
						html: htmlBody,
						subject
					}
				};
			});
			if (records.length) {
				await CreateBulk(records, { async: true });
			}
		},
		openEmailInvitationDialog(chatEntity, invitedContacts = [], invited, onButtonClick = () => { }) {
			if (!invitedContacts.length) {
				return;
			}

			inviteParticipantsDataProvider.createChatMessage(chatEntity, invitedContacts);

			sc.events.emit('dialog.confirm', 'dialogs.emailInvitation.message', () => {
				this.sendNoReplyEmail(invited);
				onButtonClick();
			}, onButtonClick, onButtonClick,
			undefined, 'dialogs.yes', 'dialogs.no', 'dialogs.emailInvitation.title');
		},
		async getEntityDisplayName(logicalname) {
			const configEntityName = await sc.classes.get('entityConfiguration.dataProvider').getEntityCaptions([logicalname], 'singular');
			if (configEntityName && configEntityName[0]) {
				return configEntityName[0];
			} else {
				return null;
			}
		}
	}
};
