/* eslint  no-unused-vars: 0 */
import { Ping } from '@/Data/Auth/authentication-service';

export async function SubscribeOnlineOfflineConnection(namespace, callback) {
	onlineOfflineSubscribers[namespace] = callback;
}

export async function SubscribeOnlineOnly(namespace, callback) {
	onlineSubscribers[namespace] = callback;
}

export async function SubscribeOfflineOnly(namespace, callback) {
	offlineSubscribers[namespace] = callback;
}

export async function UnSubscribeOnlineOfflineConnection(namespace, callback) {
	delete onlineOfflineSubscribers[namespace];
	delete onlineSubscribers[namespace];
	delete offlineSubscribers[namespace];
}

export async function UnSubscribeOnlineConnection(namespace, callback) {
	delete onlineSubscribers[namespace];
}

export async function UnSubscribeOfflineConnection(namespace, callback) {
	delete offlineSubscribers[namespace];
}

export const NETWORKSTATE = {
	ONLINE: 'online',
	OFFLINE: 'offline'
};

const onlineOfflineSubscribers = {};
const onlineSubscribers = {};
const offlineSubscribers = {};

const dispatchEvents = (iterationObject, event) => {
	for (const [key, value] of Object.entries(iterationObject)) {
		try {
			value && value(event);
		} catch (ex) {
			console.error(ex);
		}
	}
};

const checkOnlineStatus = async () => {
	try {
		const online = await Ping();
		return online.status >= 200 && online.status < 300; // either true or false
	} catch (err) {
		return false; // definitely offline
	}
};

let lastonline = true;
let onlineEventDispatched = false;
let offlineEventDispatched = false;
let onlineTimerChanged = false;
let offlineTimerChanged = false;
let intervalId = null;

const intervalCheckFunction = async () => {
	const isOnline = await checkOnlineStatus();

	if (!lastonline && isOnline) {
		if (!onlineEventDispatched) {
			dispatchEvents(onlineOfflineSubscribers, NETWORKSTATE.ONLINE);
			dispatchEvents(onlineSubscribers, NETWORKSTATE.ONLINE);

			onlineEventDispatched = true;
			offlineEventDispatched = false;
		}

		if (!onlineTimerChanged) {
			clearInterval(intervalId);
			checkOnlineStatusInterval(60000);

			onlineTimerChanged = true;
			offlineTimerChanged = false;
		}
	}

	if (lastonline && !isOnline) {
		if (!offlineEventDispatched) {
			dispatchEvents(onlineOfflineSubscribers, NETWORKSTATE.OFFLINE);
			dispatchEvents(offlineSubscribers, NETWORKSTATE.OFFLINE);

			onlineEventDispatched = false;
			offlineEventDispatched = true;
		}

		if (!offlineTimerChanged) {
			clearInterval(intervalId);
			checkOnlineStatusInterval(5000);

			offlineTimerChanged = true;
			onlineTimerChanged = false;
		}
	}

	lastonline = isOnline;
};

const checkOnlineStatusInterval = (interval) => {
	intervalId = setInterval(intervalCheckFunction, interval);
};

checkOnlineStatusInterval(60000);

window.addEventListener('online', () => {
	if (!onlineEventDispatched) {
		dispatchEvents(onlineOfflineSubscribers, NETWORKSTATE.ONLINE);
		dispatchEvents(onlineSubscribers, NETWORKSTATE.ONLINE);

		onlineEventDispatched = true;
		offlineEventDispatched = false;
	}
});

window.addEventListener('offline', () => {
	if (!offlineEventDispatched) {
		dispatchEvents(onlineOfflineSubscribers, NETWORKSTATE.OFFLINE);
		dispatchEvents(offlineSubscribers, NETWORKSTATE.OFFLINE);

		onlineEventDispatched = false;
		offlineEventDispatched = true;
	}
});

const onlineworker = new Worker(`${window.location.origin}/js/workers/sleepdetector.js`);
onlineworker.onmessage = function (ev) {
	if (ev && ev.data === 'online') {
		dispatchEvents(onlineOfflineSubscribers, NETWORKSTATE.ONLINE);
		dispatchEvents(onlineSubscribers, NETWORKSTATE.ONLINE);
	}
};

window.addEventListener('beforeunload', () => {
	onlineworker && onlineworker.terminate();
});
