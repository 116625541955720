var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["component-search-results is-parent", _vm.cssClass] },
    [
      _vm.details
        ? _c("ComponentSearchDetails", {
            key: _vm.details.$key,
            attrs: { breadcrumbs: _vm.breadcrumbs, options: _vm.details }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.options.filterPanel
        ? _c("FiltersPanel", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.details && _vm.showFilterPanel,
                expression: "!details && showFilterPanel"
              }
            ],
            attrs: {
              options: _vm.options.filterPanel,
              evaluationContext: _vm.filterPanelEvaluationContext,
              isLoading: _vm.isLoading,
              entityName: _vm.logicalName,
              stateNamespace: _vm.stateNamespace
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.details
        ? _c("div", { staticClass: "search-results-wrapper" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.hideHeader,
                    expression: "!hideHeader"
                  }
                ],
                staticClass: "header"
              },
              [
                _c(
                  "div",
                  { staticClass: "region" },
                  [
                    _vm.options.filterPanel
                      ? _c(
                          "div",
                          {
                            class: [
                              "filter-button active",
                              { open: !!_vm.showFilterPanel }
                            ],
                            on: { click: _vm.toggleFilterPanel }
                          },
                          [
                            _c("svg", { staticClass: "svg-icon" }, [
                              _c("use", {
                                staticClass: "ms-filter-icon",
                                attrs: {
                                  "xlink:href": "#layout-icons-ms-filter-icon"
                                }
                              })
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._t("title-slot", [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.title))
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._t("toggle-slot", [
                      _vm.withToggleAll
                        ? _c("ResultsToggle", {
                            staticClass: "toggle-padding",
                            attrs: {
                              namespace: _vm.namespace,
                              internalNamespace: _vm.internalNamespace,
                              toggleNamespace: _vm.toggleNamespace,
                              isParent: true
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("ComponentSetSearchResultsSelectedRecords", {
                      attrs: {
                        stateNamespace: _vm.stateNamespace,
                        showSelectedCount: _vm.showSelectedCount,
                        targetEntity: _vm.targetEntity,
                        overridedNames: _vm.overridedNames
                      }
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "region" },
                  [
                    !_vm.hideSearchField
                      ? _c("SearchField", {
                          attrs: { searchString: _vm.searchQuery }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.options.sortByFields
                      ? _c("SortPanel", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.sortByFields,
                              expression: "sortByFields"
                            }
                          ],
                          staticClass: "sortby-filter-wrapper",
                          attrs: {
                            fields: _vm.options.sortByFields,
                            entityName: _vm.logicalName,
                            evaluationContext: _vm.evaluationContext,
                            scrollNamespace: _vm.scrollNamespace,
                            namespace: _vm.namespace,
                            internalNamespace: _vm.internalNamespace,
                            stateNamespace: _vm.stateNamespace,
                            saveViewState: _vm.options.saveViewState,
                            defaultSelectedView: _vm.selectedViewItem,
                            item: _vm.items && _vm.items[0]
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("ViewSwitcher", {
                      attrs: {
                        items: _vm.items,
                        defaultSelectedView: _vm.selectedViewItem,
                        stateNamespace: _vm.stateNamespace,
                        saveViewState: _vm.options.saveViewState
                      }
                    }),
                    _vm._v(" "),
                    _vm._t("reactive-menu", [
                      _vm.actionMenuComponent &&
                      (_vm.actionMenuComponent.menu ||
                        _vm.actionMenuComponent.toolbarActions)
                        ? _c("ReactiveActionMenu", {
                            class: {
                              "without-border":
                                !_vm.sortByFields &&
                                (_vm.items || []).length < 2
                            },
                            attrs: {
                              options: _vm.actionMenuComponent,
                              evaluationContext: _vm.evaluationContext,
                              stateNamespace: _vm.stateNamespace
                            }
                          })
                        : _vm._e()
                    ])
                  ],
                  2
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isCollapsed,
                    expression: "!isCollapsed"
                  }
                ],
                class: [
                  "content-wrapper",
                  { withPanel: _vm.showFilterPanel, loading: _vm.isLoading }
                ]
              },
              [
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("keep-alive", [
                      _c(_vm.content.name, {
                        tag: "div",
                        class: [
                          "component-view",
                          {
                            "with-footer": _vm.showPaging && !!_vm.results.total
                          }
                        ],
                        attrs: {
                          options: _vm.content,
                          results: _vm.results.results,
                          evaluationContext: _vm.evaluationContext,
                          stateNamespace: _vm.stateNamespace,
                          combinedNamespace: !_vm.options.filterPanel
                            ? _vm.combinedNamespace
                            : "",
                          paginationParams: _vm.paginationParams
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isLoading,
                          expression: "isLoading"
                        }
                      ],
                      staticClass: "loading-overlay"
                    })
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }