<template>
	<control :name="options.control.$type"
			 :contentProps="options.control"
			 :evaluationContext="innerContext"
			 :class="cssClass"
			 :key="item ? item.Id : ''"
			 v-if="innerContext">
	</control>
</template>
<script>
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'control-vuex-getter',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			cssClass: this.options.cssClass ? this.evaluationContext.eval(this.options.cssClass) : [],
			item: null,
			vuexModule: null,
			vuexGetter: null
		};
	},
	computed: {
		innerContext() {
			if (!this.vuexModule || !this.vuexGetter) { return null; }

			let item = this.filterValue
				? this.$store.getters[this.vuexModule + '/' + this.vuexGetter](this.filterValue)
				: this.$store.getters[this.vuexModule + '/' + this.vuexGetter];

			if (!item) { return null; }

			if (this.propertyToCreateContext) { item = item[this.propertyToCreateContext]; }

			this.item = item;
			return createEvaluationContext(this.resultsExtender
				? {
					...item,
					...this.resultsExtender
				}
				: item);
		}
	},
	components: { Control: () => import('./../Entity/control.vue') },
	created() {
		this.filterValue = this.evaluationContext.eval(this.options.filterValue || null);
		this.propertyToCreateContext = this.evaluationContext.eval(this.options.propertyToCreateContext || null);
		this.resultsExtender = this.options.resultsExtender ? this.evaluationContext.eval(this.options.resultsExtender) : null;
		this.vuexModule = this.evaluationContext.eval(this.options.vuexModule || null);
		this.vuexGetter = this.evaluationContext.eval(this.options.vuexGetter || null);
	},
	mounted() {
	},
	beforeDestroy() {
	}
};
</script>
