var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.background
        ? _c(
            "div",
            { staticClass: "template-background" },
            [
              _vm.template
                ? _c("control", {
                    attrs: {
                      name: _vm.background.$type,
                      contentProps: _vm.background,
                      evaluationContext: _vm.evaluationContext
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "control",
        _vm._b(
          {
            attrs: {
              name: _vm.options.content.$type,
              contentProps: _vm.options.content,
              evaluationContext: _vm.evaluationContext
            }
          },
          "control",
          _vm.$attrs,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }