import Vue from 'vue';
import ScMap from '../Components/scMap/scMap';
import scMapUtils from '../Components/scMap/utils/mapUtils';
import { isNumber, isArray } from '@acx-xms/data-functions/dist';
import { evaluate } from '@/Data/EvaluationContext/evaluationContext';

Vue.directive('mapSearchResults', (() => {
	let map;
	let mainSearchConfig;
	let mapMarkers = [];
	let drawingTool;
	let lastTooltipLocation;
	let fork;
	let selectedResults = [];
	let results = [];

	const updateMarkerIcons = (items) => {
		if (!map) return;
		const ids = items.map(obj => obj.Id);
		const selectedMarkers = [];
		mapMarkers.forEach(item => {
			const isSelected = ids.indexOf(item.id) > -1;
			const icon = isSelected ? 'selected' : 'default';
			item.marker.setIcon(icon);
			item.marker.setListners(isSelected);
			isSelected && selectedMarkers.push(item.marker._marker);
		});

		map.clusterer.setSelection(selectedMarkers);
	};
	const showToltip = (vnode, element, e, markerLocation, entities, binding) => {
		if (JSON.stringify(lastTooltipLocation) !== JSON.stringify(markerLocation)) {
			lastTooltipLocation = markerLocation;

			const emitClosePopup = () => {
				lastTooltipLocation && vnode.context.$root.$emit('templatePopup.close.maptooltip');
			};
			emitClosePopup();

			map.once('zoom_changed', emitClosePopup);
			window.onhashchange = emitClosePopup;
			$(window).on('resize', emitClosePopup);

			setTimeout(() => {
				vnode.context.$root.$emit('templatePopup.open', e.target, 'maptooltip', {
					name: binding.mapTooltipName || 'map-tooltip',
					params: {
						namespace: 'maptooltip',
						entities,
						tooltipSettings: mainSearchConfig.tooltipSettings,
						applyTooltipClass: (className) => {
							console.warn('apply className to the tooltip');
							// tooltip.__tooltipElem.addClass(className);
						},
						offsetLeft: 20,
						offsetTop: 12,
						mapShiftLeft: binding.mapShiftLeft || 0,
						mapShiftTop: binding.mapShiftTop || 0,
						isMobile: binding.isMobile,
						closeCallback: () => {
							lastTooltipLocation = null;
							map && map.off('zoom_changed');
							window.onhashchange = null;
							$(window).off('resize', emitClosePopup);
						},
						openCallback: function (fn) {
							const left = element.clientWidth / 4;
							const loc = map.locationToPoint(markerLocation.lat, markerLocation.lng);
							const newloc = map.pointToLocation(loc.x + left + this.mapShiftLeft, loc.y + this.mapShiftTop);
							map.setCenter(newloc.lat(), newloc.lng());

							fn && setTimeout(fn, 50);
						}
					}
				}, 'mousedown');
			}, 0);
		} else {
			lastTooltipLocation = null;
		}
	};
	const updateMarkers = async (binding, vnode, element) => {
		if (!map) return;

		let newMarkers;
		let markerIcons = {
			default: {
				url: '/Images/marker-idle.png',
				width: 33,
				height: 46
			},
			selected: {
				url: '/Images/marker-active.png',
				width: 33,
				height: 46
			}
		};
		const mapSettings = await sc.classes.get('settings.dataProvider').get('mapSearchResults-settings');
		const configuredIcons = scMapUtils.getPushpinsStyles(mapSettings.pushpins, 'single');

		if (configuredIcons) {
			markerIcons = configuredIcons;
		}
		// Filter entities with Location data
		results = results.filter(entity => {
			const location = binding.getEntityCoords(entity);
			return location && isNumber(location.lat) && isNumber(location.lng);
		});
		map.removeMarkers(mapMarkers.map(obj => obj.marker));
		mapMarkers.length = 0;
		newMarkers = map.createMarkers(results.map(entity => {
			const location = binding.getEntityCoords(entity);

			return {
				title: entity.Name,

				location: {
					lat: location.lat,
					lng: location.lng
				},

				icon: selectedResults.indexOf(entity.Id) > -1
					? 'selected'
					: 'default',

				icons: JSON.parse(JSON.stringify(markerIcons)),

				meta: { entity }
			};
		}));

		if (newMarkers) {
			if (!isArray(newMarkers)) {
				newMarkers = [newMarkers];
			}

			// Create tooltips for each marker
			newMarkers.forEach(marker => {
				marker.on('click', (e) => {
					showToltip(vnode, element, e, marker.getLocation(), [marker.meta.entity], binding);
				});
			});
			// Store created markers
			mapMarkers = newMarkers.map(marker => {
				return {
					id: marker.meta.entity.Id,
					marker
				};
			});
		}

		if (mapMarkers.length) {
			map.fitTo(mapMarkers.map(item => {
				return item.marker.getLocation();
			}));
		}
	};

	return {
		async inserted(element, bnd, vnode) {
			const binding = bnd.value;
			const mapMarkers = [];
			const $element = $(element);
			results = binding.results || [];

			fork = sc.events.fork();
			fork.on(binding.namespace + '.selectionChanged', (data) => {
				selectedResults = data.selection;
				updateMarkerIcons(data.selection);
			});
			fork.on(binding.namespace + '.entity.searched', async (data) => {
				results = data.results;

				await updateMarkers(binding, vnode, element);
				updateMarkerIcons(selectedResults);
			});

			let mapConfig = null;
			if (binding.mapSettingsName) {
				mapConfig = await sc.classes.get('settings.dataProvider').get(binding.mapSettingsName);
			} else {
				mapConfig = await sc.classes.get('settings.dataProvider').get('mapSearchResults-settings');
			}
			mainSearchConfig = {
				hideLoadMoreLink: false,
				...mapConfig
			};
			const hideLoadMoreLink = evaluate(mainSearchConfig.hideLoadMoreLink);

			if (hideLoadMoreLink) {
				$element.find('.map-search-results-actionBar .loadMore a').addClass('withoutLoadMoreLink');
				$element.find('.modern-map-search-results-actionBar .loadMore a').addClass('withoutLoadMoreLink');
			}

			const clusterStyles = scMapUtils.getPushpinsStyles(mainSearchConfig.pushpins, 'multi');
			try {
				map = await new ScMap({
					placeholderElem: $element.find('.sc-map-holder')[0],
					zoomOnDoubleclick: binding.zoomOnDoubleclick,
					clusterMarkers: true,
					clusterOptions: {
						zoomOnClick: false,
						styles: clusterStyles
					},
					mapConfig,
					maxZoom: binding.maxZoom,
					fullScreenCallback: binding.fullScreenCallback,
					enableFullscreenControl: binding.enableFullscreenControl,
					// custom fullscreen control is not needed on mobile
					enableCustomFullscreenControl: binding.isMobile ? !binding.isMobile : true,
					gestureHandling: binding.gestureHandling
				}).initMap();
			} catch (err) {
				console.warn('mapSearchResults binding: Failed to instantiate the scMap', err);
			}
			setTimeout(async () => {
				await updateMarkers(binding, vnode, element);
			}, 1);
			/* Set `selected` icons for markers */
			setTimeout(() => {
				updateMarkerIcons(binding.selection);
			}, 1);

			/*
				* Listen clicks on clusters and show tooltips
				* */
			map.clusterer.on('click', (cluster) => {
				const rawEntities = results;
				const markerEntities = cluster.markers && cluster.markers.filter((m) => {
					return m && rawEntities.indexOf(m.meta.entity) !== -1;
				}).map((m) => {
					return m.meta.entity;
				}).filter(Boolean)
					.valueOf();

				if (markerEntities.length === 0) {
					return;
				}

				showToltip(vnode, element, cluster, cluster.location, markerEntities, binding);
			});

			const drawingOptions = {
				clickable: false,
				strokeColor: mainSearchConfig.polygon.strokeColor,
				strokeWeight: mainSearchConfig.polygon.strokeWeight,
				strokeStyle: mainSearchConfig.polygon.strokeStyle,
				strokeOpacity: mainSearchConfig.polygon.strokeOpacity
			};

			if (!mainSearchConfig.drawingTool || mainSearchConfig.drawingTool.$type === 'plugins.mapSearchResults.googleDrawingTool') {
				const drawingToolOptions = {
					...{
						circle: true,
						clear: true,
						polygon: true,
						rectangle: true,
						customButtons: true
					},
					...mainSearchConfig.drawingTool
				};

				if (binding.enableDrawingTool !== undefined) {
					drawingToolOptions.enableDrawingTool = binding.enableDrawingTool;
				}
				drawingTool = map.createDrawingTool({
					setAreaSelection: binding.setAreaSelection,
					drawingTool: drawingToolOptions,
					clearSelection: binding.clearSelection,
					...drawingOptions
				});
				binding.getAreaSelection(drawingTool.drawShape.bind(drawingTool));

				$('.modern-map-search-results-actionBar, .map-search-results-actionBar').find('ul').hide();

				// Redraw area selection
				binding.onAreaSelectionChanged((value) => {
					if (!binding.areaSelectionEnabled) { return; }
					drawingTool.drawShape(value);
				});
			}

			fork.on(binding.stateNamespace + '.mapSearchResults.areaSelectionFilter-setPolygon-enableFilter', (coords) => {
				drawingTool && drawingTool.drawShape(coords);
			});
			fork.on([binding.stateNamespace + '.mapSearchResults.areaSelectionFilter-setPolygon-disableFilter',
				binding.stateNamespace + '.mapSearchResults.areaSelectionFilter-clearPolygon'], () => {
				drawingTool && drawingTool.clear();
			});

			// trigger resize after transition animation
			setTimeout(() => {
				if (!map) {
					return;
				}
				if (map.resize && map.isAuthenticated()) {
					map.resize();
					if (mapMarkers && mapMarkers.length > 0) {
						map.fitTo(mapMarkers.map((mapMarker) => {
							return mapMarker.marker.getLocation();
						}));
					}
					vnode.context.$root.$emit(binding.stateNamespace + '.map-ready', (coords) => {
						drawingTool && drawingTool.drawShape(coords);
					});
				}
			}, 350);
		},

		update(element, bnd, vnode) {
			const selection = bnd.value.selection;
			if (selection) {
				selectedResults = selection;
				updateMarkerIcons(selection);
			}
		},

		unbind(element) {
			if (fork) {
				fork.dispose();
				fork = undefined;
			}
			if (map) {
				map.dispose();
				map = undefined;
			}

			mainSearchConfig = undefined;
			drawingTool = undefined;

			mapMarkers = [];
			selectedResults = [];
			results = [];
		}
	};
})());
