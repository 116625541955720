export default {
	async enabled() {
		return true;
	},
	async execute(options, context, selection) {
		if (options && options.callBack) {
			options.callBack(options.actionType);
		}
	}
};
