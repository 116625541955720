<template>
	<!-- tooltip-->
	<action-menu class="centered-menu without-border" :options="menuObj" :record="menuObj.selection" :evaluationContext="context" v-if="menuObj && visible" :key="key"></action-menu>
</template>
<script>
import ActionMenu from './../Menu/action-menu';
import { generateId } from '@acx-xms/data-functions/dist';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'control-actionmenu-wrapper',
	components: { ActionMenu },
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			menuObj: {
				menu: this.options.actionmenu,
				namespace: this.evaluationContext.eval(this.options.namespace) || '',
				internalNamespace: this.evaluationContext.eval(this.options.internalNamespace) || '',
				entityType: this.evaluationContext.eval(this.options.logicalname),
				selection: this.evaluationContext.eval(this.options.recordRef),
				refreshEvent: this.evaluationContext.eval(this.options.refreshEvent) || '',
				enable: true
			},
			visible: this.options.visible !== void 0 ? this.evaluationContext.eval(this.options.visible) : true,
			context: this.evaluationContext,
			key: generateId()
		};
	},
	async created() {
		if (this.options.actionMenuRefreshEvent) {
			this.fork = sc.events.fork();
			this.fork.on('refreshDetailsMenu', (record) => {
				this.context = createEvaluationContext(record);
				this.menuObj.selection = this.context.entity;
				this.key = generateId();
			});
		}
		if (this.options.enable) {
			this.menuObj.enable = await this.evaluationContext.evalAsync(this.options.enable);
		}
	},
	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
		}
	}
};
</script>
