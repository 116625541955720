export default {
	parameters(options) {
		return [
			this.eval(options.argument),
			this.eval(options.separator)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument),
			await this.evalAsync(options.separator)
		];
	},
	evaluate(argument, separator) {
		if (!argument) {
			return [];
		}
		return argument.split(separator);
	}
};
