var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "accept-screen-wrapper" }, [
    _c("div", { staticClass: "accept-dialog-wrapper" }, [
      _c("div", { staticClass: "header" }, [
        _vm._v("Terms of Use and Privacy Policy update")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "message-wrapper" }, [
        _vm._v("\n\t\t\tPlease confirm that you accept "),
        _c("br"),
        _vm._v(" our "),
        _c(
          "a",
          {
            attrs: { href: _vm.ppLink },
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.download(_vm.tosLink, "Terms Of Use")
              }
            }
          },
          [_vm._v("Terms of Use")]
        ),
        _vm._v(" and "),
        _c(
          "a",
          {
            attrs: { href: _vm.tosLink },
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.download(_vm.ppLink, "Privacy Policy")
              }
            }
          },
          [_vm._v("Privacy Policy")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "buttons" }, [
        _c("button", { attrs: { type: "submit" }, on: { click: _vm.accept } }, [
          _vm._v("Accept")
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }