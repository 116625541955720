<template>
	<div class="grouped" v-bind:class="'columns-' + options.columns">
		<div class="grouped-content" v-for="(group,index) in options.group" :key="index">
			<control v-bind:name="group.$type" v-bind:contentProps="group" v-bind:evaluationContext="evaluationContext"></control>
		</div>
	</div>
</template>
<script>
export default {
	name: 'entitytemplate-grouped',
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: { Control: () => import('./../Entity/control.vue') },
	data() {
		return {};
	},
	async created() {
		// TODO: remove after TS templates are fixed
		if (this.options.query) {
			for (const query of this.options.query) {
				await this.evaluationContext.emitQuery(query.name, query);
			}
		}
	}
};
</script>
