<template>
	<div class="image-gallery-mobile" v-if="images.length > 0">
		<div v-if="!reference" class="wrapper" @scroll="onScrollDebounced">
			<div class="image-node" v-for="(image, index) in images" v-bind:style="{ height: imageHeight }" :key="index">
				<div class="bg-image" :style="{'background-image': `url(${image})`}"></div>
			</div>
		</div>
		<router-link v-else-if="reference" :to="reference" class="wrapper clickable">
			<div class="image-node" v-for="(image, index) in images" :key="index" v-bind:style="{ height: imageHeight }">
				<div class="bg-image" :style="{'background-image': `url(${image})`}"></div>
			</div>
		</router-link>
		<div v-if="options.showCounter || images.length > 1" class="images-counter p2">{{currentImage}} of {{images.length}}</div>
	</div>
	<div class="svg-thumbnail" v-else-if="images.length == 0" v-bind:style="{ height: imageHeight }">
		<svg class="svg-icon svg-20">
			<use :xlink:href="placeholderEntityName"></use>
		</svg>
	</div>
</template>
<script>
import { recordLink } from '@acx-xms/data-functions/dist';
export default {
	name: 'control-image-gallery-small-mobile',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			images: [],
			reference: null,
			placeholderEntityName: null,
			imageHeight: this.options.imageHeight ? this.evaluationContext.eval(this.options.imageHeight) : 'auto',
			currentImage: 1
		};
	},
	methods: {
		onScroll(e) {
			const imageNodes = Array.prototype.slice.call(e.target.querySelectorAll('.image-node'));
			for (const [index, node] of imageNodes.entries()) {
				if (Math.round(node.offsetLeft) >= Math.round(e.target.scrollLeft)) {
					this.currentImage = index + 1;
					break;
				}
			}
		}
	},
	created() {
		const images = this.evaluationContext.eval(this.options.items) || [];
		this.onScrollDebounced = _.debounce(this.onScroll, 150);
		this.placeholderEntityName = '#entity-icons-' + this.evaluationContext.eval(this.options.placeholderEntityName);
		if (this.options.recordReference) {
			const recordreference = this.evaluationContext.eval(this.options.recordReference);
			this.reference = recordLink(this.$route, recordreference);
		}

		const token = localStorage.getItem(sc.classes.get('authentication').getTokenKey(sc.cluster.key));
		for (let i = 0; i < images.length; i++) {
			const id = images[i].imageid || images[i].Id;
			let url = sc.classes.get('edge.dataProvider').getImageUrl('image', id, 'fine');
			url += '?token=' + token;
			if (images[i].primary) {
				this.images.unshift(url);
			} else {
				this.images.push(url);
			}
		}
	}
};
</script>
<style src="./control-image-gallery-small-mobile-styles.less" scoped></style>
