var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "svg-icon_wrapper" }, [
        _c(
          "svg",
          {
            class: [_vm.cssClass, "svg-icon", "svg-20"],
            style: _vm.iconStyleObject
          },
          [
            _c("use", {
              attrs: { "xlink:href": "#" + _vm.name, fill: _vm.options.color }
            })
          ]
        ),
        _vm._v(" "),
        _vm.text
          ? _c("span", { class: ["text", _vm.textCssClass] }, [
              _vm._v(_vm._s(_vm.text))
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }