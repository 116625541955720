<template>
	<div v-if="imagesForDisplay.length" :class="['group-image-wrapper', cssClass]">
		<div :class="'group-image_'+ (imagesForDisplay.length + (restItemsLength > 0 ? 1: 0))">
			<div v-if="restItemsLength" class="rest-items">
				<div class="circle" :style="{width: identifyPixelSize() + 'px', height: identifyPixelSize()+ 'px'}" />
				<div class="rest-items-count">
					<span class="p7">+</span>
					<span class="p7">{{ restItemsLength }}</span>
				</div>
			</div>
			<div
				v-image="{
					src: image,
					width: identifyPixelSize(),
					height: identifyPixelSize(),
					placeholder: placeholderClass
				}"
				v-for="(image,index) in imagesForDisplay"
				:key="index"
			/>
		</div>
	</div>
</template>
<script>
import helperMethods from './../../ComponentSet/component-set-evaluation-context-helper.methods';
import ImageMixins from './images.mixins';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'control-images',
	mixins: [ImageMixins],
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			imagesForDisplay: [],
			placeholderClass: '',
			restItemsLength: 0,
			cssClass: (this.options.cssClass) ? helperMethods.eval(this.evaluationContext, this.options.cssClass) : ''
		};
	},
	created() {
		// move to methods
		const placeholderEntityName = this.evaluationContext.eval(this.options.placeholderEntityName) || '';
		const subtype = this.evaluationContext.eval(this.options.subtype) || '';

		this.placeholderClass = subtype === ''
			? 'entity-icons-' + placeholderEntityName + '-' + this.options.size
			: 'entity-icons-' + placeholderEntityName + '-' + this.subtype + '-' + this.options.size;
		// move to methods
		this.systemusers = [];
		

		let items = this.evaluationContext.eval(this.options.items);
		const sortBy = this.evaluationContext.eval(this.options.sortBy);

		this.items = _.sortBy(items, function (item) {
			return !item[sortBy];
		});

		this.buildImageUrls(this.items, this.evaluationContext);

		this.$root.$on(['entity.changed', 'entity.created', 'entity.deleted'], this.checkForUpdateImage);
	},

	methods: {
		checkForUpdateImage(data) {
			if (data.entity.logicalname !== 'avatar') {
				return;
			}

			//TODO: rework
			if (data.record && this.systemusers.indexOf(data.record.systemuserid.id) != -1) {
				let index = this.items.findIndex(item => item.systemuserid.id === data.record.systemuserid.id);
				if (index != -1) {
					this.items[index].systemuseravatarid.id = data.entity.id;
					if (this.items[index].chatparticipantsystemuseridsystemuser && this.items[index].chatparticipantsystemuseridsystemuser.avatarid) {
						this.items[index].chatparticipantsystemuseridsystemuser.avatarid.id = data.entity.id;
						this.items[index].chatparticipantsystemuseridsystemuser.avatarid.logicalname = 'avatar';
					}

					this.buildImageUrls(this.items);
				}
			}
		},

		buildImageUrls (refs) {
			const promises = [];

			refs.forEach(ref => {
				const context = createEvaluationContext(ref);
				ref.systemuserid && this.systemusers.indexOf(ref.systemuserid.id) === -1 && this.systemusers.push(ref.systemuserid.id);
				const entityReference = sc.classes.get('entityReference', context.eval(this.options.avatarReference));

				if (entityReference && !entityReference.isEmpty) {
					promises.push(
						sc.classes.get('entityConfiguration.dataProvider')
							.fetchEntity(entityReference.logicalname)
							.then(data => {
								const controllerName = data.metadata.detail.controllerName;
								return sc.classes.get('edge.dataProvider').getImageUrl(controllerName, entityReference.id, this.imageSize);
							})
					);
				} else {
					const smartIconText = context.eval(this.options.smartIconText);
					const imageFontSize = () => {
						switch (this.options.size) {
							case 'small':
								return 18;
							case 'medium':
								return 26;
							default:
								return 26;
						}
					};
					promises.push(
						sc.classes.get('smartIcon.dataProvider', this.evaluationContext.eval)
							.getImageUrl(smartIconText, this.identifyPixelSize(), imageFontSize(), 'Segoe UI Semibold')
							.then(smartImageUrl => smartImageUrl)
					);
				}
			});
			Promise.all(promises).then((images) => {
				const imagesLength = images.length;
				const displayImagesLength = imagesLength > 0 && imagesLength > 3 ? 2 : imagesLength;
				this.imagesForDisplay = _.take(images, displayImagesLength).reverse();
				this.restItemsLength = imagesLength > displayImagesLength ? imagesLength - displayImagesLength : 0;
			});
		}
	},

	beforeDestroy() {
		this.$root.$off(['entity.changed', 'entity.created', 'entity.deleted'], this.checkForUpdateImage);
	}
};
</script>
<style>
	/*todo make themes*/
	.group-image-wrapper {
		display: inline-block;
		width: 60px;
		height: 40px;
	}

	.group-image-wrapper .group-image_1 {
		position: absolute;
		left: -10px
	}

	.group-image-wrapper .group-image_2 {
		position: absolute;
		left: -5px
	}

	.group-image-wrapper .image-node {
		border: 1px solid #fff;
		border-radius: 50%;
		position: absolute;
		box-sizing: border-box;
	}

	.group-image-wrapper .image-node img {
		border-radius: 50%;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}

	.group-image-wrapper .image-node:nth-child(3) {
		left: 0;
	}

	.group-image-wrapper .image-node:nth-child(2) {
		left: 10px;
	}

	.group-image-wrapper .image-node:nth-child(1) {
		left: 20px;
	}

	.rest-items {
		position: absolute;
		left: 20px;
	}

	.rest-items .circle {
		border-radius: 50%;
		background-color: var(--color11);
	}

	.rest-items-count {
		position: absolute;
		right: 3px;
		top: 6px;
		z-index: 1;
	}

	.rest-items-count span {
		display: block;
	}
</style>
