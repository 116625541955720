/* eslint no-useless-escape: 0 */
export default {
	filterContact(selection, field, pattern) {
		const regexpPattern = pattern || '^c_guid_[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}';
		const fieldToCheck = field || 'email';
		return selection.filter((item) => {
			if (!item.Source) {
				return item;
			}

			const fld = item.Source[fieldToCheck] || '';
			return !fld.match(regexpPattern);
		});
	},

	showWarningDialogForContacts(message) {
		sc.events.emit('dialog.open', {
			title: 'Warning',
			allowMinimize: false,
			allowMaximize: false,
			allowPin: false,
			allowClose: false,
			component: 'dialog.info',
			params: {
				message,
				buttons: [
					{ title: 'OK' }
				]
			}
		});
	}
};
