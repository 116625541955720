import ActionResolver from '../../Actions/actionResolver';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
import { everyAsync, isNullOrUndefined } from '@acx-xms/data-functions/dist';
export default {
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			hasCombinedNamespace: this.options && (this.options.namespace && this.options.internalNamespace),
			hasScrollNamespace: this.options && this.options.scrollNamespace
		};
	},
	computed: {
		context() {
			return this.evaluationContext || createEvaluationContext({ Type: (this.options && this.entityType) || '' });
		}
	},
	created() {
		this.actionResolver = ActionResolver();
		this.combinedNamespace = (this.hasScrollNamespace && this.options.scrollNamespace) || (this.hasCombinedNamespace &&
			(this.options.namespace + '.' + this.options.internalNamespace));
	},
	methods: {
		async fillActionArrayAsync(items, context, selection = [], arrayName, fullselection) {
			if (!items) {
				return Promise.resolve();
			}
			const _fullSelection = _.cloneDeep(fullselection);
			let _selection = _.cloneDeep(selection);
			this[arrayName] = [];
			// todo - hotfix for default-toolbarActions, find better solution
			const promiseArr = [];
			for (const [i, item] of items.entries()) {
				const ignoreEntities = item.action.ignoreEntitySelection;
				if (ignoreEntities && ignoreEntities.length && _fullSelection) {
					ignoreEntities.forEach(entity => {
						if (_fullSelection[entity] && _fullSelection[entity].length) {
							delete _fullSelection[entity];
						}
						_selection = _selection.filter(sel => (sel.Type || sel.logicalname) !== entity);
					});
				}
				const action = this.actionResolver.resolve(item.action.$type);
				// need in case of 1 unselected entity from 2 selected
				if (!_selection.length && _fullSelection) { // it wont work if it is more then 2 selected entities
					const nextSelection = Object.values(_fullSelection).find(sel => sel.length);
					if (nextSelection) {
						_selection = nextSelection;
					}
				}
				const localContext = (item.action.source && item.action.source === 'singleSelection' && _selection !== null && _selection.length > 0)
					? createEvaluationContext(_selection[0])
					: context;
				if (!action) return;
				let preenabled = true;
				if (item.action.enable) {
					if (_selection.length && item.action.source !== 'singleSelection') {
						// for configured enable conditions
						preenabled = await everyAsync(_selection, async (sel) => {
							const selectionItemContext = createEvaluationContext(sel);
							return await selectionItemContext.evalAsync(item.action.enable);
						});
					} else {
						preenabled = await localContext.evalAsync(item.action.enable);
					}
				}

				const isSeveralEntities = _fullSelection && Object.keys(_fullSelection).length !== 1;
				if (isSeveralEntities) {
					Object.keys(_fullSelection).forEach(selectionKey => {
						if (!_fullSelection[selectionKey].length) {
							delete _fullSelection[selectionKey];
						}
					});
				}
				if ((item.action.source === 'singleSelection' && (isSeveralEntities || _selection.length !== 1)) ||
					(item.action.source === 'noSelection' && _selection.length !== 0)) {
					preenabled = false;
				}

				if (preenabled) {
					const actionSelection = item.action.source === 'noSelection' ? [] : (item.action.source === 'configuration' ? [localContext.eval(item.action.selection)] : _selection);
					promiseArr.push(action.enabled(item.action, localContext, actionSelection, _fullSelection).then((enabled) => {
						if (enabled) {
							// avoiding menu item dublicates for reactive menu with sub entity
							let existingMenuItem = this[arrayName].find(item => item.index === i);
							if (existingMenuItem) {
								existingMenuItem = {
									menuItem: item,
									index: i
								};
							} else {
								this[arrayName].push({
									menuItem: item,
									index: i
								});
							}
						}
					}));
				}
			}
			return Promise.all(promiseArr).then(() => {
				this[arrayName].sort((a, b) => { return a.index - b.index; });
			});
		},
		executeAction(options, context, selection = [], fullSelection) {
			const _fullSelection = _.cloneDeep(fullSelection);
			let _selection = _.cloneDeep(selection);

			const ignoreEntities = options.ignoreEntitySelection;
			if (ignoreEntities && ignoreEntities.length && _fullSelection) {
				ignoreEntities.forEach(entity => {
					if (_fullSelection[entity] && _fullSelection[entity].length) {
						delete _fullSelection[entity];
					}
				});
			}
			if (ignoreEntities && ignoreEntities.length && _selection.length) {
				_selection = _selection.filter(sel => !ignoreEntities.some(entity => entity === (sel.Type || sel.logicalname)));
				if (!_selection.length) {
					// we clear selection if our selection is from wrong entity so we can take needed selection from fullselection
					// todo: rethink of filtering it in actions or just refactor this mixin
					_selection = null;
				}
			}
			// if we select listing - availability comes as last selection and our selection is empty, in fillActionArrayAsync we filter fullSelection
			if (isNullOrUndefined(_selection) && _fullSelection && Object.values(_fullSelection).length) {
				_selection = Object.values(_fullSelection)[0];
			}
			const action = this.actionResolver.resolve(options.$type);
			// pass refreshEvent from actionMenuWrapper to action.execute
			if (this.options && this.options.refreshEvent && !Object.prototype.hasOwnProperty.call(options, 'refreshEvent')) {
				options.refreshEvent = this.options.refreshEvent;
			}
			const localContext = (options.source && options.source === 'singleSelection' && !context.eval(options.keepParentContext) && !isNullOrUndefined(_selection))
				? createEvaluationContext(_selection[0])
				: context;

			if (action) {
				const actionSelection = options.source === 'noSelection' ? [] : (options.source === 'configuration' ? [localContext.eval(options.selection)] : _selection);
				// TODO: when refactor - fix issue when we execute action for the 2nd time (i.e. import contacts). Evaluation context is empty in that case
				action.execute(options, localContext, actionSelection, _fullSelection);
			}
		}
	}
};
