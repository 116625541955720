var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    {
      staticClass: "toast-group",
      attrs: {
        tag: "div",
        css: _vm.animate,
        "enter-active-class": "animated slideInUp",
        "leave-active-class": "animated fadeOut"
      }
    },
    _vm._l(_vm.toastArr, function(toast) {
      return _c("div", { key: toast.idx, staticClass: "toast-pop-up" }, [
        _c("p", { staticClass: "p9 text" }, [_vm._v(_vm._s(toast.text))]),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "close",
            on: {
              click: function($event) {
                $event.stopPropagation()
                _vm.forceClose(toast)
              }
            }
          },
          [
            _c("svg", { staticClass: "svg-icon" }, [
              _c("use", {
                attrs: { "xlink:href": "#layout-icons-dialog-close" }
              })
            ])
          ]
        )
      ])
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }