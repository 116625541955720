import ValidatorBase from './base';
export default class extends ValidatorBase {
	async validate(entityData, context) {
		const data = entityData[this.field];
		let result;

		if (Array.isArray(data)) {
			result = !!data.filter(f => !f.errors.length).length;
		} else {
			result = !!data;
		}

		return {
			field: await this.customFieldName() || this.field,
			result,
			message: await context.evalAsync(this.message) || 'Field is required'
		};
	}
}
