<template>
	<div class="no-access">
		You do not have access rights to this record or it is deactivated
	</div>
</template>
<script>

export default { name: 'no-access-wrapper' };
</script>
<style src="./no-access-wrapper.less" scoped></style>
