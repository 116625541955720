import { isActive } from './Methods/action.methods';

export default {
	async enabled(options, evaluationContext, selection) {
		return selection.length > 0 && await isActive(evaluationContext, selection);
	},
	async execute(options, context, selection, fullSelection) {
		let sel = [];
		if (fullSelection) {
			Object.keys(fullSelection).forEach(key => {
				sel = sel.concat(fullSelection[key]);
			});
		} else {
			sel = selection;
		}

		const parameters = (options.parameter || []).reduce((result, param) => {
			result[param.name] = context.eval(param.value);
			return result;
		}, {});
		if (sel.length === 1) {
			const entityData = parameters.entityData || sel[0];
			const displaySubject = parameters.displaySubject;

			const ref = sc.classes.get('entityReference', sel[0]);
			const subject = displaySubject ? sc.utils.findProperty(entityData, 'name', true) : '';
			sc.utils.mailTo.invokeUrl(sc.utils.mailTo.emailLink(ref, subject, null, options.layoutsRedirectToMain));
		} else {
			sc.events.emit('vue.dialog.open', {
				title: await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('dialogues.email.link.title'),
				component: 'email-link-dialog',
				state: 'hidden',
				maximizedWidth: '90%',
				maximizedHeight: '100%',
				params: {
					selection: sel,
					toastMessageKey: context.eval(options.toastMessageKey),
					informationDialogTextKey: context.eval(options.informationDialogTextKey)
				}
			});
		}
	}
};
