export default class {
	constructor(field, options, context) {
		this.field = field;
		this.options = options;
		this.context = context;
		this.message = this.options.message;
		this.customFieldName = async () => this.options.customFieldName ? await context.evalAsync(this.options.customFieldName) : null;
	}

	validate() {
		throw new Error('validate() should be defined');
	}
}
