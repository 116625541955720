var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.images.length > 0
    ? _c(
        "div",
        { staticClass: "image-list" },
        [
          !_vm.reference
            ? _c(
                "div",
                { staticClass: "wrapper" },
                _vm._l(_vm.images, function(image) {
                  return _c(
                    "div",
                    { key: image.src, staticClass: "image-node" },
                    [
                      image && image.src && !image.error
                        ? _c("img", {
                            attrs: { src: image.src },
                            on: {
                              error: function($event) {
                                image.error = true
                              }
                            }
                          })
                        : image && image.src && image.error
                        ? _c("img", {
                            staticClass: "broken-image",
                            attrs: { src: "/Images/broken-file-icon-small.png" }
                          })
                        : _c("svg", { staticClass: "svg-icon svg-20" }, [
                            _c("use", {
                              attrs: { "xlink:href": _vm.placeholderEntityName }
                            })
                          ])
                    ]
                  )
                })
              )
            : _c(
                "router-link",
                {
                  staticClass: "wrapper clickable",
                  attrs: { to: _vm.reference }
                },
                _vm._l(_vm.images, function(image) {
                  return _c(
                    "div",
                    { key: image.src, staticClass: "image-node" },
                    [
                      image && image.src && !image.error
                        ? _c("img", {
                            attrs: { src: image.src },
                            on: {
                              error: function($event) {
                                image.error = true
                              }
                            }
                          })
                        : image && image.src && image.error
                        ? _c("img", {
                            staticClass: "broken-image",
                            attrs: { src: "/Images/broken-file-icon-small.png" }
                          })
                        : _c("svg", { staticClass: "svg-icon svg-20" }, [
                            _c("use", {
                              attrs: { "xlink:href": _vm.placeholderEntityName }
                            })
                          ])
                    ]
                  )
                })
              ),
          _vm._v(" "),
          _vm.ellipsisEnabled
            ? _c(
                "div",
                { staticClass: "ellipsis" },
                [
                  _c("control-svg-icon", {
                    attrs: { options: { name: "layout-icons-ellipsis" } }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _c("div", { staticClass: "image-list" }, [
        _c("div", { staticClass: "wrapper" }, [
          _c("div", { staticClass: "no-results-message" }, [
            _c("span", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "common.dictionary.noMatchesFound" },
                  expression: "{ key: 'common.dictionary.noMatchesFound' }"
                }
              ]
            })
          ])
        ])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }