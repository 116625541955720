var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.detailsProcess
      ? _c(
          "div",
          {
            class: [
              "email-campaign-root",
              {
                "email-campaign-root-preview":
                  _vm.activeStepNumber === 5 && _vm.logged
              }
            ]
          },
          [
            _c("WizardHeader", {
              attrs: {
                loading: _vm.isLoading || !_vm.logged,
                breadcrumbsOptions: _vm.breadcrumbsOptions
              }
            }),
            _vm._v(" "),
            _vm.logged && _vm.isErrors ? _c("ErrorsManager") : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "email-campaign-root-wrapper" },
              [
                _vm.emailCampaignProcess
                  ? _c("div", {
                      staticClass:
                        "reportingservice-overlay loading-overlay large"
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeStepNumber === 5 && _vm.logged
                  ? _c("PreviewSidebar")
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "email-campaign-root-content" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "email-campaign-steps",
                        style:
                          "height: calc(100vh - " + _vm.heightHandler + "px)"
                      },
                      [
                        !_vm.isLoading
                          ? [
                              _vm.logged
                                ? _c(
                                    "transition",
                                    { attrs: { name: "fade", mode: "out-in" } },
                                    [
                                      _vm.getStepName
                                        ? _c(_vm.getStepName, {
                                            tag: "component"
                                          })
                                        : _c("div", [_vm._v("Loading...")])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.logged
                                ? _c("Error", {
                                    staticClass: "login-error",
                                    attrs: {
                                      text: _vm.error.text,
                                      button: _vm.error.btn
                                    },
                                    on: { click: _vm.login }
                                  })
                                : _vm._e()
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isLoading ||
                        _vm.isInitialization ||
                        _vm.isInitializationMCI
                          ? _c("div", { staticClass: "loading-overlay" }, [
                              _vm.isInitialization
                                ? _c("div", [
                                    _vm._v(
                                      "Your authorization to Composer Online is in progress. Please wait."
                                    )
                                  ])
                                : _vm.isInitializationMCI
                                ? _c("div", [
                                    _vm._v(
                                      "Your authorization to MailChimp is in progress. Please wait."
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm.isServerError
                          ? _c("div", [
                              _c("div", [
                                _vm._v(
                                  "Couldn't sign in to Composer Online. Please try again later."
                                )
                              ])
                            ])
                          : _vm._e()
                      ],
                      2
                    ),
                    _vm._v(" "),
                    !_vm.isLoading && _vm.logged ? _c("WizardFooter") : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _c("div", { staticClass: "loading-overlay details-overlay" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }