export default {
	async enabled(options, evaluationContext, selection) {
		return Promise.resolve(true);
	},
	async execute(options, context, selection) {
		const lookupFilters = options.lookupFilters;
		const sel = context.eval(options.selection);
		const refreshEvent = context.eval(options.refreshEvent);
		const localizations = sc.classes.get('localization.dataProvider');

		sc.events.emit('vue.dialog.open', {
			title: await localizations.getLabelForCurrentLanguage('dialogues.assignLicense.dialogueTitle'),
			allowPin: false,
			component: 'assign-license-dialog',
			maximizedWidth: '500px',
			maximizedHeight: '400px',
			params: {
				evaluationContext: context,
				lookupFilters,
				userRef: {
					id: sel.id,
					logicalname: sel.logicalname
				},
				refreshEvent,
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey)
			}
		});
	}
};
