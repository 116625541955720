<template>
	<div id="page-wrapper" class="layout-marketspace-embedded">
		<div class="marketspace-layout-wrapper">
			<div class="marketspace-content-wrapper">
				<div class="main-content-area adaptive">
					<div class="marketspace-components-area adaptive">
						<div  v-if="ready" class="component">
							<router-view :key="$route.path" :defaultRoute="defaultMenuItem"></router-view>
							<keep-alive include="route-search-results">
								<router-view :key="$route.path" :defaultRoute="defaultMenuItem" name="route" :filterPanelVisible="false">
									<div slot="favorites"><menu-favorites></menu-favorites></div>
								</router-view>
							</keep-alive>
						</div>
					</div>
				</div>
			</div>
			<div class="dialogmanager-footer">
				<old-dialog-manager></old-dialog-manager>
				<dialog-manager></dialog-manager>
			</div>
			<toast-pop-up></toast-pop-up>
			<absolute-menu-popup></absolute-menu-popup>
			<absolute-template-popup></absolute-template-popup>
		</div>
	</div>
</template>
<script>

import MenuFavorites from '@/Components/Menu/menu-app-bar/menu-favorites.vue';
import LayoutMarketspaceBaseMixin from '@/Components/Layout/layout.marketspace.base.mixins';

export default {
	name: 'layout-marketspace-embedded',
	mixins: [LayoutMarketspaceBaseMixin],
	components: { MenuFavorites },
	created() {
		this.defaultMenuItem = {
			displayName: 'Listings',
			path: 'listing-search-results'
		};
	}
};
</script>
<!--TODO: create separate less file-->
<style src="./layout-marketspace-embedded.less" scoped></style>
<style src="@/Components/Layout/ui-elements.less" scoped></style>
