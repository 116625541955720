import { isArray } from '@acx-xms/data-functions/dist';

export default {
	parameters(options) {
		return [this.eval(options.argument)];
	},
	async parametersAsync(options) {
		return [await this.evalAsync(options.argument)];
	},
	evaluate(arg) {
		if (isArray(arg)) {
			return !!arg.length; // do this to process empty array to false
		}
		if (arg === '00000000-0000-0000-0000-000000000000') {
			return false;
		}
		return !!arg;
	}
};
