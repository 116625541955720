const setArray = (value) => {
	let result;
	if(Array.isArray(value)) result = value;
	else {
		if(typeof value === 'object') result = Object.values(value);
		else result = [parseInt(value)];
	}
	return result;
};

const state = () => ({
	steps: 5,
	active: 1,
	disabled: [],
	disabledFilled: [],
	filled: [],
	invalid: [],
	captions: {}
});
const getters = {
	getSteps: state => state.steps,
	getActive: state => state.active,
	getDisabled: state => state.disabled,
	getDisabledFilled: state => state.disabledFilled,
	getFilled: state => state.filled,
	getInvalid: state => state.invalid,
	getCaptions: state => state.captions
};
const mutations = {
	SET_STEPS(state, value) {
		state.steps = value;
	},
	SET_ACTIVE(state, value) {
		state.active = value;
	},
	SET_DISABLED(state, value) {
		state.disabled = value;
	},
	SET_DISABLED_FILLED(state, value) {
		state.disabledFilled = value;
	},
	SET_FILLED(state, value) {
		state.filled = value;
	},
	SET_INVALID(state, value) {
		state.invalid = value;
	},
	SET_CAPTIONS(state, value) {
		state.captions = value;
	}
};
const actions = {
	setSteps({commit}, value) {
		if(parseInt(value)) commit('SET_STEPS', value);
	},
	setActive({commit}, value) {
		if(parseInt(value)) commit('SET_ACTIVE', value);
	},
	setDisabled({commit}, value) {
		commit('SET_DISABLED', setArray(value));
	},
	setDisabledFilled({commit}, value) {
		commit('SET_DISABLED_FILLED', setArray(value));
	},
	setFilled({commit}, value) {
		commit('SET_FILLED', setArray(value));
	},
	setInvalid({commit}, value) {
		commit('SET_INVALID', setArray(value));
	},
	setCaptions({commit}, value) {
		if(typeof value === 'object' && !Array.isArray(value)) {
			commit('SET_CAPTIONS', value);
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}