import {
	generateGuid, Create, CreateBulk, Search
} from '@acx-xms/data-functions/dist';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	async enabled(options, evaluationContext, selection) {
		return true;
	},

	async execute(options, context, selection) {
		const componentSet = await sc.classes.get('marketspaceComponents.dataProvider').getComponentSetById(context.eval(options.componentsetName));
		const members = await Search('staticlistmember',
			[
				sc.classes.get('offsetSize.filter', 10000),
				sc.classes.get('termFacet.filter', {
					logicalName: 'staticlist.id',
					query: [context.entity.id]
				}).fillQuery(),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'recordstate.id' },
					{ logicalname: 'entityreference' }
				]).fillQuery()
			]);
		const entityRefs = (members.Results || []).map(member =>
			member.Source.entityreference
		);
		const listings = entityRefs.filter(ref => ref.logicalname === 'listing').map(ref => ref.id);
		const listingFilter = {
			data: listings.length ? listings : ['00000000-0000-0000-0000-000000000000'],
			entity: 'listing',
			requestLabel: 'Ids'
		};
		const availabilities = entityRefs.filter(ref => ref.logicalname === 'availability').map(ref => ref.id);
		const availabilityFilter = {
			data: availabilities.length ? availabilities : ['00000000-0000-0000-0000-000000000000'],
			entity: 'availbility',
			requestLabel: 'Ids'
		};
		sc.events.emit('vue.dialog.open', {
			icon: 'entity-icons-deal-small',
			title: 'Copy Static List',
			allowPin: false,
			component: 'ms.addlisting.dialog',
			maximizedWidth: '90%',
			maximizedHeight: '100%',
			evaluationContext: createEvaluationContext({}),
			params: {
				componentSet,
				modalNamespace: 'addlistingpopup',
				searchResultComponentName: options.searchResultComponentName,
				defaultFilters: {
					listing: listingFilter,
					availability: availabilityFilter
				},
				modalDialogCallback: (listings, availabilities, name) => {
					return this.addListings(listings, (availabilities && availabilities.length > 0 ? availabilities : []), name)
						.then(() => sc.events.emit(context.eval(options.refreshNamespace) + '.searching'));
				},
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey),
				customName: ''
			}
		});
	},
	async addListings(listings, availabilities, name) {
		const record = await Create('staticlist',
			{
				name,
				membertype: {
					id: '4d8a18bd-ac8a-428b-90c6-7ffe70f8034a',
					logicalname: 'lookupmembertype'
				}
			});
		this.parentRef = sc.classes.get('entityReference', record);
		const records = [...listings, ...availabilities].map(entity => {
			const memberRef = sc.classes.get('entityReference', entity);
			return {
				id: generateGuid(),
				method: 'POST',
				url: 'staticlistmember',
				body: {
					staticlist: {
						id: record.staticlistid,
						logicalname: 'staticlist'
					},
					entityreference: memberRef,
					name: memberRef.name,
					type: 'staticlistmember'
				}
			};
		});
		await CreateBulk(records, { async: true });
	}
};
