import ExpressionCalculateColor from './calculateColor';
import ExpressionCapitalizeLetters from './capitalizeLetters';
import ExpressionConcat from './concat';
import ExpressionCString from './cstring';
import ExpressionEncodeURIComponent from './encodeURIComponent';
import ExpressionEntityName from './entityName';
import ExpressionFormatDate from './formatDate';
import ExpressionFormatNumber from './formatNumber';
import ExpressionLabel from './label';
import ExpressionLocalizedLabel from './localizedLabel';
import ExpressionLocalizedMetadataLabel from './localizedMetadataLabel';
import ExpressionMetadataLabel from './metadataLabel';
import ExpressionSplit from './split';
import ExpressionStringSubstring from './substring';
import ExpressionText from './text';
import ExpressionTimespan from './timespan';
import ExpressionTrim from './trim';
import ExpressionOpenFileUrl from './openFileUrl';

export default {
	resolve() {
		return {
			'expression.calculateColor': ExpressionCalculateColor,
			'expression.capitalizeLetters': ExpressionCapitalizeLetters,
			'expression.concat': ExpressionConcat,
			'expression.cstring': ExpressionCString,
			'expression.encodeURIComponent': ExpressionEncodeURIComponent,
			'expression.entityName': ExpressionEntityName,
			'expression.formatDate': ExpressionFormatDate,
			'expression.formatNumber': ExpressionFormatNumber,
			'expression.label': ExpressionLabel,
			'expression.localizedLabel': ExpressionLocalizedLabel,
			'expression.localizedMetadataLabel': ExpressionLocalizedMetadataLabel,
			'expression.metadataLabel': ExpressionMetadataLabel,
			'expression.string.split': ExpressionSplit,
			'expression.string.substring': ExpressionStringSubstring,
			'expression.text': ExpressionText,
			'expression.timespan': ExpressionTimespan,
			'expression.trim': ExpressionTrim,
			'expression.openFileUrl': ExpressionOpenFileUrl
		};
	}
};
