var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVue && _vm.isConfigToolMode
    ? _c(
        "div",
        { staticClass: "ctwrapper" },
        [
          _vm.isClicked ? _c("div", { staticClass: "highlighted" }) : _vm._e(),
          _vm._v(" "),
          _c(
            _vm.prepareComponentName(_vm.name),
            _vm._b(
              {
                directives: [
                  {
                    name: "data-attr",
                    rawName: "v-data-attr",
                    value: { options: _vm.contentProps },
                    expression: "{ options: contentProps }"
                  }
                ],
                tag: "component",
                staticClass: "control-component",
                attrs: {
                  options: _vm.contentProps,
                  evaluationContext: _vm.evaluationContext
                },
                nativeOn: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.onClick($event)
                  }
                }
              },
              "component",
              _vm.$attrs,
              false
            )
          )
        ],
        1
      )
    : _vm.isVue
    ? _c(
        _vm.prepareComponentName(_vm.name),
        _vm._b(
          {
            directives: [
              {
                name: "data-attr",
                rawName: "v-data-attr",
                value: { options: _vm.contentProps },
                expression: "{ options: contentProps }"
              }
            ],
            tag: "component",
            staticClass: "control-component",
            attrs: {
              options: _vm.contentProps,
              evaluationContext: _vm.evaluationContext
            }
          },
          "component",
          _vm.$attrs,
          false
        )
      )
    : !_vm.isVue
    ? _c("div", {
        directives: [
          {
            name: "kocomponent",
            rawName: "v-kocomponent",
            value: _vm.componentWrapper(_vm.contentProps),
            expression: "componentWrapper(contentProps)"
          },
          {
            name: "data-attr",
            rawName: "v-data-attr",
            value: { options: _vm.contentProps },
            expression: "{ options: contentProps }"
          }
        ],
        staticClass: "control-component"
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }