<template>
	<div class="searchResults-map-view">
		<div
			v-show="!mapFullScreen"
			:style="{ width: resultsWidth }"
			class="results"
		>
			<component
				v-if="options.component"
				:is="prepareComponentName(options.component.$type)"
				v-bind="{ options: componentOptions, paginationParams, evaluationContext, results, stateNamespace }"
			/>
		</div>
		<div
			v-if="isActive"
			v-mapSearchResults="resultsOptions"
			:class="['map-search-results', { fullscreen: mapFullScreen }]"
		>
			<div class="scMap">
				<div class="sc-map-holder"></div>
			</div>
			<div v-show="!isMapReady" class="loading-overlay"></div>
		</div>
	</div>
</template>
<script>
import { prepareComponentName as prepCompName } from '@acx-xms/data-functions/dist';
import { evaluate } from '@/Data/EvaluationContext/evaluationContext';
import ComponentSetSearchResultsMapViewBase from './search-results-map-view-base.mixins';
const ComponentSetSearchResultsThumbnailview = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '../thumbnail-view/search-results-thumbnail-view.vue');
const ComponentSetSearchResultsRowsview = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '../rows-view/search-results-rows-view.vue');

export default {
	name: 'component-set-search-results-map-view',
	mixins: [ComponentSetSearchResultsMapViewBase],
	computed: {
		componentOptions() {
			return {
				...this.options.component,
				targetEntity: this.targetEntity,
				isParent: this.options.isParent,
				namespace: this.options.namespace,
				internalNamespace: this.options.internalNamespace
			};
		},
		resultsOptions() {
			return {
				results: this.results,
				selection: this.selection,
				getAreaSelection: this.getAreaSelection,
				setAreaSelection: this.setAreaSelection,
				clearSelection: this.clearSelection,
				getEntityCoords: this.getEntityCoords,
				onAreaSelectionChanged: this.onAreaSelectionChanged,
				areaSelectionEnabled: this.areaSelectionEnabled,
				drawMode: this.drawMode,
				draw: this.draw,
				namespace: this.combinedNamespace,
				stateNamespace: this.stateNamespace,
				fullScreenCallback: this.fullScreenCallback,
				targetEntity: this.targetEntity,
				enableFullscreenControl: this.options.enableFullscreenControl,
				enableDrawingTool: this.options.enableDrawingTool,
				mapSettingsName: this.mapSettingsName
			};
		}
	},
	data() {
		return {
			resultsWidth: this.options.resultsWidth ? evaluate(this.options.resultsWidth) : '60%',
			mapSettingsName: this.options.mapSettingsName
		};
	},
	components: {
		'component-set-searchresultsthumbnailview': ComponentSetSearchResultsThumbnailview,
		'component-set-searchresultsrowsview': ComponentSetSearchResultsRowsview
	},
	methods: {
		prepareComponentName(name) {
			return prepCompName(name);
		}
	}
};
</script>
<style src="./search-results-map-view.less" scoped></style>
