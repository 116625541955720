export default {
	parameters(options) {
		return [
			this.eval(options.argument),
			this.eval(options.path)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument),
			await this.evalAsync(options.path)
		];
	},
	evaluate(array, path) {
		return array.map(item => {
			if (path) {
				item = sc.utils.findProperty(item, path, true);
			}
			return item;
		});
	}
};
