<template>
	<action-menu-base :evaluationContext="context"
						:menuItemsToolbar="toolbarActions"
						:menuItemsDropdown="dropDownActions"
						:hasDropDownItems="hasDropDownItems"
						:combinedNamespace="combinedNamespace"
						:enable="options.enable"
						@execute="executeCallback">
	</action-menu-base>
</template>
<script>
import ActionMenuBase from './action-menu-base';
import MenuMixins from './menu.mixins';
import HelperMethods from './../ComponentSet/component-set-evaluation-context-helper.methods'; // mixin?
export default {
	name: 'action-menu',
	props: { record: Object },
	mixins: [MenuMixins],
	components: { ActionMenuBase },
	data() {
		return {
			hasDropDownItems: false,
			toolbarActions: [],
			dropDownActions: []
		};
	},
	methods: {
		executeCallback(action) {
			this.executeAction(action, this.context, [this.record]);
		}
	},
	created() {
		const isMenuEnabled = HelperMethods.eval(this.context, this.options &&
				this.options.menu && this.options.menu.enable !== undefined
			? this.options.menu.enable
			: true);

		const isToolbarActionsEnabled = HelperMethods.eval(this.context, this.options && this.options.toolbarActions &&
				this.options.toolbarActions.enable !== undefined
			? this.options.toolbarActions.enable
			: true);

		if (this.options && this.options.menu && isMenuEnabled) {
			this.hasDropDownItems = true;
			this.fillActionArrayAsync(this.options.menu.item, this.context, [this.record], 'dropDownActions');
		}

		if (this.options && this.options.toolbarActions && isToolbarActionsEnabled) {
			this.fillActionArrayAsync(this.options.toolbarActions.item, this.context, [this.record], 'toolbarActions');
		}
	}
};
</script>
