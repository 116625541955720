import {
	createEvaluationContext, evaluate, evaluateAsync, evaluateAsyncFake
} from '@/Data/EvaluationContext/evaluationContext';

export default {
	eval(evalContext, expr, defaultValue) {
		if (!expr) {
			return defaultValue;
		}
		return evalContext ? evalContext.eval(expr) : evaluate(expr);
	},
	async evalAsync(evalContext, expr, defaultValue) {
		if (!expr) {
			return defaultValue;
		}
		return evalContext ? await evalContext.evalAsync(expr) : await evaluateAsync(expr);
	},
	evalAsyncFake(evalContext, expr, defaultValue) {
		if (!expr) {
			return defaultValue;
		}
		return evalContext ? evalContext.evalAsyncFake(expr) : evaluateAsyncFake(expr);
	},
	getRandomArbitary(min, max) {
		return Math.random() * (max - min) + min;
	},
	wrapResult(result) {
		return createEvaluationContext(result);
	}
};
