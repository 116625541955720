// TODO: use this methods in create-dealroom-dialog.vue
export default {
	methods: {
		async createDealRoomWithRelations(data, listing) {
			const listingRef = sc.classes.get('entityReference', listing);
			const shareddocRef = await this.createSharedDocument(data);
			const propertyRef = listing.Source.propertyid;
			const dealroom = await this.createDealRoom(shareddocRef, data.name, listingRef, propertyRef);
			const dealroomRef = sc.classes.get('entityReference', dealroom);
			this.parentRecord = dealroomRef;

			if (shareddocRef) {
				await this.updateRecord(shareddocRef.logicalname, shareddocRef.id, { dealroomid: dealroomRef });
			}

			const chat = await this.createChat(dealroom);
			await this.inviteParticipants(chat);

			return dealroom;
		},

		async createDRAvailabilities(availabilities, dealroom) {
			const promises = [];
			const dealroomRef = sc.classes.get('entityReference', dealroom);

			availabilities.forEach(availability => {
				promises.push(this.createRecord('dravailability', {
					type: 'dravailability',
					dealroomid: dealroomRef,
					availabilityid: sc.classes.get('entityReference', availability),
					name: availability.Name,
					teamid: dealroom.teamid,
					brokerteamid: dealroom.brokerteamid
				}));
			});

			return await Promise.all(promises);
		},

		async updateDealRoom(dealroomRef, listingRef) {
			if (listingRef) {
				return await this.updateRecord(dealroomRef.logicalname, dealroomRef.id, { listingid: listingRef });
			}
		},

		async createDealRoom(shareddoc, drName, listingRef, propertyRef) {
			const dealroomBody = {
				name: drName,
				listingid: listingRef,
				sfdealcrmid: this.options.sfdealcrmid,
				propertyid: propertyRef
			};

			if (shareddoc) {
				dealroomBody.ndaid = sc.classes.get('entityReference', shareddoc);
			}

			return await this.createRecord('dealroom', dealroomBody);
		},

		async createChat(dealroom) {
			return await this.createRecord('chat', {
				dealroomid: {
					id: dealroom.dealroomid,
					logicalname: 'dealroom'
				},
				name: dealroom.name,
				public: true,
				teamid: dealroom.teamid,
				coownerteamid: dealroom.coownerteamid,
				brokerteamid: dealroom.brokerteamid,
				isroomwithnda: !!dealroom.ndaid,
				ndaid: dealroom.ndaid,
				type: 'chat'
			});
		},

		async createSharedDocument(data) {
			let documentRef = null;
			let documentTitle = '';

			if (!this.file && !data.ndaid.selectedFile) {
				return;
			}

			if (!data.ndaid.isExisting && this.file) {
				const document = await this.createRecord('document',
					{
						category: 'NDA',
						filename: data.ndaid.uploadedFileName,
						title: data.ndaid.uploadedFileName.split('.').slice(0, -1).join('.')

					}, this.file);

				documentRef = sc.classes.get('entityReference', document);
				documentTitle = document.title;
			} else {
				documentRef = sc.classes.get('entityReference', data.ndaid.selectedFile.data);
				documentTitle = documentRef.name;
			}

			const sharedDocBody = {
				type: 'shareddocument',
				title: documentTitle,
				documentid: documentRef,
				nda: true
			};

			const sharedDoc = await this.createRecord(sharedDocBody.type, sharedDocBody);

			return sc.classes.get('entityReference', sharedDoc);
		}
	}
};
