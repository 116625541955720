var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-wrapper" }, [
    _c("div", { class: ["option", _vm.cssClass] }, [
      _c("div", {
        directives: [
          {
            name: "localization",
            rawName: "v-localization",
            value: { key: "userPanel.userLanguage" },
            expression: "{ key: 'userPanel.userLanguage' }"
          }
        ],
        staticClass: "title"
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.currentLanguage,
            expression: "currentLanguage"
          },
          { name: "data-attr", rawName: "v-data-attr" }
        ],
        class: _vm.cssClass,
        attrs: { type: "text", disabled: "" },
        domProps: { value: _vm.currentLanguage },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.currentLanguage = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { class: ["option", _vm.cssClass] }, [
      _c("div", {
        directives: [
          {
            name: "localization",
            rawName: "v-localization",
            value: { key: "userPanel.userCurrency" },
            expression: "{ key: 'userPanel.userCurrency' }"
          }
        ],
        staticClass: "title"
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          { name: "data-attr", rawName: "v-data-attr" },
          {
            name: "model",
            rawName: "v-model",
            value: _vm.currentCurrency,
            expression: "currentCurrency"
          }
        ],
        class: _vm.cssClass,
        attrs: { type: "text", disabled: "" },
        domProps: { value: _vm.currentCurrency },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.currentCurrency = $event.target.value
          }
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }