<template>
	<div class="svg-icon_wrapper" v-if="visible">
		<svg
			:class="[cssClass, 'svg-icon', 'svg-20']"
			:style="iconStyleObject"
		>
			<use :xlink:href="'#'+name" :fill="options.color"/>
		</svg>
		<span :class="['text', textCssClass]" v-if="text">{{text}}</span>
	</div>
</template>
<script>
import helperMethods from './../../ComponentSet/component-set-evaluation-context-helper.methods';
export default {
	name: 'control-svg-icon',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			customSize: 1,
			name: (this.options.name) ? helperMethods.eval(this.evaluationContext, this.options.name) : '',
			cssClass: (this.options.cssClass) ? helperMethods.eval(this.evaluationContext, this.options.cssClass) : '',
			text: '',
			textCssClass: (this.options.textCssClass) ? helperMethods.eval(this.evaluationContext, this.options.textCssClass) : '',
			visible: this.options.visible !== void 0 ? helperMethods.eval(this.evaluationContext, this.options.visible) : true,
			iconStyleObject: ''
		};
	},
	async created() {
		this.text = (this.options.text) ? await helperMethods.evalAsync(this.evaluationContext, this.options.text) : '';
		const width = this.options.customSize || this.options.customWidth;
		const height = this.options.customSize || this.options.customHeight;
		const widthUom = this.options.widthUom || 'px';
		const heightUom = this.options.heightUom || 'px';

			this.iconStyleObject = {
				...(width && { 'width': width + widthUom }),
				...(height && { 'height': height + heightUom }),
				...(this.options.bgColor && { 'background-color': this.options.bgColor })
			}
		}
	}
</script>
<style lang="less" scoped>
	.svg-icon_wrapper {
		width: 100%;
		display: inline-block;

		/* TODO: move to global styles */
		.p10 {
			padding: 10px;
		}
		.circle {
			border-radius: 50%;
		}
	}
</style>
