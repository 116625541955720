<template>
	<div class="wrapper">
		<router-view></router-view>
	</div>

</template>
<script>
// todo import all vue layouts in future

export default { name: 'app-wrapper' };
</script>
<style>
	.wrapper {
		width: 100%;
		height: 100%;
	}
</style>
<styles src="./global.less" />
