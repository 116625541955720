var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-manager" }, [
    _c(
      "div",
      { staticClass: "dialogues-area" },
      _vm._l(_vm.dialogues, function(dialog) {
        return _vm.isMounted
          ? _c("old-dialog-wrapper", {
              key: dialog.id,
              attrs: { initDialog: dialog },
              on: {
                "set-active": function($event) {
                  _vm.setActiveById($event)
                }
              }
            })
          : _vm._e()
      })
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }