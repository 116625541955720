import ExpressionEq from '../boolean/eq';

export default {
	parameters(options) {
		return [
			this.eval(options.argument),
			this.eval(options.path),
			this.eval(options.value)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument),
			await this.evalAsync(options.path),
			await this.evalAsync(options.value)
		];
	},
	evaluate(array, path, value) {
		return array.find(item => {
			if (path) {
				item = sc.utils.findProperty(item, path, true);
			}
			return ExpressionEq.evaluate(item, value);
		}) || null;
	}
};
