<template>
	<div
		v-if="visible"
		:class="['filter-group filter-group-toggle-panel', {
			selected: isSelected,
			open: isExpanded,
			collapsible: collapsible
		}]"
	>
		<div
			v-data-attr="{ options, stateNamespace: name }"
			class="panel-title panel-opener"
			@click="toggle"
		>
			<h3 class="panel-opener">
				{{ name }}
			</h3>
			<div v-if="collapsible" class="icon-holder">
				<svg class="svg-icon svg-15">
					<use
						xlink:href="#layout-icons-filter-group-collapse"
						class="hover"
					/>
				</svg>
			</div>
		</div>
		<ul v-show="isExpanded" class="filter-items filter-group-content">
			<component
				v-for="filter in options.filter"
				:key="evalLogicalName(filter)"
				:is="getInlineComponentName(filter)"
				:stateNamespace="stateNamespace"
				:options="filter"
				:filters="filters"
				:evaluationContext="evaluationContext"
			/>
		</ul>
	</div>
</template>
<script>
import '@/Bindings/ui-attribute.directive';
import FilterInline from './../filter-inline/filter-inline';
import FilterInlineAsync from './../filter-inline/filter-inline-async';
import FilterMixins from './../filter.mixins';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';

export default {
	name: 'filters-group',
	mixins: [FilterMixins],
	props: {
		options: Object,
		evaluationContext: Object,
		filters: Object,
		stateNamespace: String
	},
	data() {
		return {
			visible: true,
			isSelected: false,
			isExpanded: this.options.expanded ? helperMethods.eval(this.evaluationContext, this.options.expanded) : true,
			collapsible: true,
			name: helperMethods.eval(this.evaluationContext, this.options.categoryName)
		};
	},
	components: {
		FilterInline,
		FilterInlineAsync
	},
	created() {
		this.$on('openInfoPanel', function (options) {
			this.$parent.$emit('openInfoPanel', options);
		});
	},
	methods: {
		toggle() {
			this.isExpanded = !this.isExpanded;
		},
		evalLogicalName() {
			return helperMethods.eval(this.evaluationContext, this.options.logicalName);
		}
	}
};
</script>
<style src="./filters-group.less" scoped></style>
