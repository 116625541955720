const getters = {
	stepsAmount: state => state.steps.length,
	activeStepNumber: state => state.steps.length > 0 ? state.steps.find(i => i.active).index + 1 : null,
	isTemplateSelected(state) {
		const templatestate = state.steps.find(i => i.name === 'template').templates;
		return templatestate.length && templatestate.some(i => i.selected);
	},
	isWizardValid: state => !state.templateProcesstate && state.steps.filter(i => i.name !== 'summary').every(i => i.valid) && state.steps.find(i => i.name === 'template').artifact,
	initListingSelection: state => state.steps[2].selection.listing,
	initMlSelection: state => state.steps[3].selection,
	isEdited: state => state.campaign.isEdited,
	isSaved: state => state.campaign.isSaved,
	campaignStatus: state => state.campaign.status,
	campaignName: state => state.campaign.name,
	geteSelectedTemplate(state) {
		const templatestate = state.steps.find(i => i.name === 'template').templates;
		return (templatestate.length && templatestate.find(i => i.selected)) || null;
	},
	isErrors: state => state.errors.length || state.steps.find(i => i.active).errors.length,
	isTestEdited: state => state.testEmail.edited,
	isLimitError: state => state.steps.find(i => i.name === 'template').limitError,
	campaignProcessStatus: state => state.emailCampaignProcess,
	isForceExit: state => state.forceExit,
	getCollectionType: state => state.steps.find(i => i.name === 'template').collectionType,
	getMCUserID: state => state.steps.find(i => i.name === 'setup').mcUser.mcUserId,
	isPrefilled: state => state.prefilled,
	getUserDomains: state => state.steps.find(i => i.name === 'setup').domains
};

export default getters;
