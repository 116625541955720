<template>
	<div class="searchResults-thumbnailview-columned" :class="cssClass">
		<div class="perfectscroll-wrapper" v-perfectscroll="{enable: true,  refreshScroll: paginationParams.activePages.length < 2, onScroll}" v-if="results && results.length">
			<div class="sc-flex">
				<div class="item" v-for="item in results" :key="recordKey(item)">
					<div class="item-wrapper">
						<control v-bind:name="options.contentComponent.$type"
								 v-bind:contentProps="options.contentComponent"
								 v-bind:evaluationContext="wrapResult(item)"></control>
					</div>
				</div>
			</div>
			<pagination class="bottomed"
						:pageSize="paginationParams.pageSize"
						:total="paginationParams.total"
						:from="paginationParams.from"
						:activePages="paginationParams.activePages"></pagination>
		</div>
		<div class="no-results-message" v-show="!results||!results.length">
			<div v-bind:style="{ width: '100%' }" v-localization="{ key: 'common.dictionary.noMatchesFound' }"></div>
		</div>
	</div>
</template>
<script>
import ComponentMixins from '../../../../component.mixins';
import Pagination from '../../../pagination/pagination';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'component-set-search-results-thumbnail-view-columned',
	props: {
		options: Object,
		results: Array,
		paginationParams: Object
	},
	data() {
		return {
			columns: 1,
			combinedNamespace: this.options.namespace + '.' + this.options.internalNamespace,
			cssClass: this.options.cssClass
		};
	},
	created() {
		this.$on('.pageChanged', (activePages) => {
			this.$parent.$emit('.pageChanged', activePages);
		});
	},
	components: {
		Control: () => import('./../../../../Entity/control.vue'),
		Pagination
	},
	methods: {
		recordKey(item) {
			return ComponentMixins.recordKey(item);
		},
		wrapResult: function (result) {
			return createEvaluationContext(result);
		},
		onScroll() {
			this.combinedNamespace && this.$root.$emit(this.combinedNamespace + '.scrolled');
		}
	}
};
</script>
<style src='./search-results-thumbnailview-columned.less' scoped></style>
