<template>
	<div :class="cssClass" v-if="visible">
		<template v-if="topIcon && topIcon.length">
			<div class="svg-top-icon-wrapper" v-for="(icon, index) in topIcon" v-show="icon" :key="index">
				<svg class="svg-icon svg-icon-top svg-12">
					<use :xlink:href="'#entity-icons-'+icon"></use>
				</svg>
			</div>
		</template>
		<div v-if="ready && imgObj" :class="['image-node', {'smart-image': !!menuOnHover}]" v-bg-image="imgObj" @click="executeAction">
			<div class="hover-overlay" v-if="!!menuOnHover">
				<div class="text-on-hover h2">{{menuOnHoverText}}</div>
			</div>
		</div>
	</div>
</template>
<script>
import helperMethods from './../../ComponentSet/component-set-evaluation-context-helper.methods';
import ImageMixins from './images.mixins';
import ActionResolver from '@/Actions/actionResolver';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'smart-image',
	mixins: [ImageMixins],
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			visible: false,
			topIcon: null,
			cssClass: (this.options.cssClass) ? helperMethods.eval(this.evaluationContext, this.options.cssClass) : '',
			imgObj: null,
			ready: false,
			menuOnHover: this.options.menuOnHover || null
		};
	},
	async created() {
		this.visible = (this.options.visible) !== void 0 ? this.evaluationContext.evalAsync(this.options.visible) : true;
		this.topIcon = this.options.topIcon ? this.options.topIcon.map(topIcon => this.evaluationContext.eval(topIcon)) : null;
		this.avatarowner = this.options.avatarowner ? this.evaluationContext.eval(this.options.avatarowner) : null;
		this.dataProvider = sc.classes.get('smartIcon.dataProvider', this.evaluationContext.eval);
		this.fontSize = this.options.fontSize;
		this.menuOnHoverText = this.menuOnHover ? this.evaluationContext.eval(this.menuOnHover.text) : '';
		this.smartIconText = this.evaluationContext.eval(this.options.smartIconText);
		this.smartIconText = (typeof this.smartIconText === 'string') ? this.smartIconText.toUpperCase() : this.smartIconText;
		this.smartIconText = encodeURIComponent(this.smartIconText);
		this.ref = this.evaluationContext.eval(this.options.imageRef);
		this.updateImage();
		if (this.ref && this.ref.logicalname) {
			this.$root.$on(['entity.changed', 'entity.created', 'entity.deleted'], this.checkForUpdateImage);
		}
		if (this.menuOnHover) {
			this.action = ActionResolver().resolve(this.menuOnHover.action.$type);
		}
	},

	beforeDestroy() {
		this.$root.$off(['entity.changed', 'entity.created', 'entity.deleted'], this.checkForUpdateImage);
	},

	methods: {
		checkForUpdateImage(data) {
			let fn = () => {
				this.ref = sc.classes.get('entityReference', data.entity);
				this.updateImage();
			};

			if (data.entity.logicalname === this.ref.logicalname) {
				if (data.entity.logicalname === 'avatar' && data.record) {
					if (this.avatarowner && data.record.systemuserid.id === this.avatarowner.id) {
						fn();
					}
				} else {
					fn();
				}
			}
		},
		updateImage() {
			const imageFontSize = () => {
				if (this.fontSize && this.fontSize > 0) {
					return this.fontSize;
				}
				switch (this.options.size) {
				case 'small':
					return 18;
				case 'medium':
					return 26;
				default:
					return 26;
				}
			};
			this.processImageUrl(this.ref);
			if (!this.imgObj || !this.imgObj.src) {
				this.dataProvider.getImageUrl(this.smartIconText,
					// todo - maybe we need both dimentions for smarticon
					this.identifyPixelWidth(),
					imageFontSize(),
					'Segoe UI Semibold').done((imageUrl) => {
					this.imgObj = {
						src: imageUrl,
						width: this.identifyPixelWidth(),
						height: this.identifyPixelHeight(),
						placeholder: ''
					};
					this.ready = true;
				});
			} else {
				this.ready = true;
			}
		},
		executeAction() {
			if (!this.action) return;
			const options = this.menuOnHover.action;
			const selection = this.evaluationContext.eval(options.selection);
			const localContext = createEvaluationContext(selection);
			if (this.action) {
				this.action.execute(options, localContext, selection);
			}
		}
	}
};
</script>
<style src="./images.mixins.less" scoped></style>
<style src="./smart-image.less" scoped></style>
