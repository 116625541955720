<template>
	<div class="searchResults-tableView">
		<div class="searchResults-tableView-header tableview-header" v-if="results && results.length && options.withCheckAll">
			<control-select-all :stateNamespace="stateNamespace"
								:results="results"
								:withCheckAll="options.withCheckAll"
								:isParent="options.isParent"
								:targetEntity="targetEntity" />
			<div class="p2">
				<span v-localization="{ key: 'common.dictionary.selectAll' }"></span>

			</div>
		</div>
		<div class="perfectscroll-wrapper"
			 v-bind:class="options.cssClass"
			 v-perfectscroll="{enable: true, refreshScroll: paginationParams? paginationParams.activePages.length < 2 : true, onScroll}"
			 v-if="results&&results.length">
			<div class="sc-flex">
				<div class="item" v-for="item in results" :key="recordKey(item)">
					<div class="item-wrapper">
						<div class="info">
							<control :name="options.contentComponent.$type"
									 :contentProps="options.contentComponent"
									 :evaluationContext="getContext(item)"></control>
						</div>
					</div>
				</div>
			</div>
			<mobile-pagination class="bottomed" v-if="paginationParams"
							   :pageSize="paginationParams.pageSize"
							   :total="paginationParams.total"
							   :from="paginationParams.from"
							   :activePages="paginationParams.activePages"></mobile-pagination>
		</div>
		<div class="no-results-message" v-show="!results || !results.length">
			<div :style="{ width: '100%' }" v-localization="{ key: 'common.dictionary.noMatchesFound' }"></div>
			<div v-if="hasNoMatchesFoundSubTitle" :style="{ width: '100%' }" v-localization="{ key: 'common.dictionary.noMatchesFoundSubTitle' }"></div>
		</div>
	</div>
</template>
<script>
import MobilePagination from '@/Components/ComponentSet/pagination/mobile-pagination';
import ControlSelectAll from '@/Components/Control/Checkboxes/control-select-all';
import ComponentMixins from '@/Components/component.mixins';
import { createEvaluationContext, evaluate } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'component-set-mobile-search-results-table-view',
	props: {
		options: Object,
		results: Array,
		paginationParams: Object,
		stateNamespace: String,
		hasNoMatchesFoundSubTitle: Boolean
	},
	mixins: [ComponentMixins],
	data() {
		return {
			columns: 1,
			images: [],
			combinedNamespace: this.options.namespace + '.' + this.options.internalNamespace,
			targetEntity: evaluate(this.options.targetEntity),
			parentScrollNamespace: this.options.parentScrollNamespace
		};
	},
	components: {
		Control: () => import('./../../../../Entity/control.vue'),
		MobilePagination,
		ControlSelectAll
	},
	created() {
		this.$on('.pageChanged', (activePages) => {
			// todo use state managment
			this.$parent.$emit('.pageChanged', activePages);
			this.parentScrollNamespace && this.paginationParams.activePages.length < 2 && this.$root.$emit(this.parentScrollNamespace + '.scrollTabToTop');
		});
	},
	methods: {
		onScroll() {
			this.combinedNamespace && this.$root.$emit(this.combinedNamespace + '.scrolled');
		},
		recordKey(item) {
			return ComponentMixins.recordKey(item);
		},
		getContext(item) {
			const evaluatuionContext = createEvaluationContext(item);
			evaluatuionContext.Source = item;
			return evaluatuionContext;
		}
	}
};
</script>
<style src='./mobile-search-results-table-view.less' scoped></style>
