const CHATPARTICIPANT_FIELDS = [
	'chatid.id',
	'lastreadmessagedate',
	'chatparticipantchatidchat.collaborationroomid',
	'chatparticipantchatidchat.dealroomid',
	'chatparticipantchatidchat.recordstate',
	'ndasignindate',
	'chatparticipantcontactidcontact.systemuserid',
	'recordstate',
	'requestedaccess',
	'ispending',
	'isroomowner'
];
const LICENSE_FIELDS = ['istrial', 'validto'];
const CRM_SETTINGS_FIELDS = ['domainurl', 'crmnamespace'];
const CONFIG_TEMPLATES_FIELDS = [
	'configtemplateid',
	'entity',
	'templatetype',
	'active',
	'configuration',
	'content',
	'selectedfields'
];

const THEME_FIELDS = ['logobigid', 'logosmallid', 'logoanonymousid'];
const COLORS_COUNT = 21;
for (let i = 0; i <= COLORS_COUNT; i++) {
	THEME_FIELDS.push('color' + i);
}

export {
	CHATPARTICIPANT_FIELDS, LICENSE_FIELDS, CRM_SETTINGS_FIELDS, CONFIG_TEMPLATES_FIELDS, THEME_FIELDS
};
