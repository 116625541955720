import { isActive, mapParametersObject } from './Methods/action.methods';

export default {
	async enabled(options, evaluationContext, selection) {
		return await isActive(evaluationContext, selection);
	},

	async execute(options, context, selection) {
		const localizations = sc.classes.get('localization.dataProvider');
		const parameters = mapParametersObject(options.parameter, context);

		if (parameters.parentRecord) {
			parameters.predefinedFields = {};
			parameters.predefinedFields[parameters.parentRecord.logicalname + 'id'] = parameters.parentRecord;
		}
		const entityData = await sc.classes.get('entityConfiguration.dataProvider').fetchEntity(context.eval(options.logicalname));
		const entityLabel = await context.evalAsync(entityData.displayName);
		const title = parameters.externalTitle || await localizations.getLabelForCurrentLanguage('entity.editor.titleCreate', [entityLabel]);

		const maxWidth = entityData.editDialogWidth ? context.eval(entityData.editDialogWidth) : '80%';
		const maxHeight = entityData.editDialogHeight ? context.eval(entityData.editDialogHeight) : '100%';
		sc.events.emit('vue.dialog.open', {
			icon: 'action-icons-search-save',
			title,
			allowPin: true,
			component: options.component || 'vue.editor',
			maximizedWidth: maxWidth,
			maximizedHeight: maxHeight,
			evaluationContext: context,
			logicalname: options.logicalname,
			subtype: options.subtype,
			toastMessageKey: context.eval(options.toastMessageKey),
			informationDialogTextKey: context.eval(options.informationDialogTextKey),
			parameters
		});
	}
};
