var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "component-search-results-details" }, [
    !_vm.data && _vm.isLoading
      ? _c("div", { staticClass: "content" })
      : _vm._e(),
    _vm._v(" "),
    !!_vm.data && !_vm.isLoading && !_vm.error
      ? _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c(_vm.breadcrumbsComponentName, {
                tag: "component",
                staticClass: "inner",
                attrs: { breadcrumbs: _vm.breadcrumbs }
              }),
              _vm._v(" "),
              _vm.actionMenuComponent
                ? _c("action-menu", {
                    key: _vm.actionMenuComponent.$key,
                    staticClass: "without-padding without-border",
                    attrs: {
                      options: _vm.actionMenuComponent,
                      record: _vm.data,
                      evaluationContext: _vm.evaluationContext
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.options.switchDetails
                ? _c("control", {
                    attrs: {
                      name: _vm.options.switchDetails.$type,
                      contentProps: _vm.options.switchDetails,
                      evaluationContext: _vm.evaluationContext
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "perfectscroll",
                  rawName: "v-perfectscroll",
                  value: { enable: true, onScroll: _vm.onScroll },
                  expression: "{enable: true, onScroll: onScroll}"
                }
              ],
              staticClass: "rows content-scroll-wrapper"
            },
            _vm._l(_vm.rows, function(row) {
              return _c("DetailsRowControl", {
                key: row.$key,
                style: { height: row.height },
                attrs: { evaluationContext: _vm.evaluationContext, row: row }
              })
            })
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.data && !_vm.isLoading && _vm.error
      ? _c("div", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.recordDetails.noAccess" },
              expression: "{key: 'common.dictionary.recordDetails.noAccess'}"
            }
          ],
          staticClass: "no-data"
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.isLoading
      ? _c("div", { staticClass: "loading-wrapper" }, [
          _c("div", { staticClass: "loading-overlay" })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }