var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c(
        "div",
        { staticClass: "component-tab-search-results" },
        [
          _vm.cachedComponents.length
            ? [
                _c(
                  "keep-alive",
                  { attrs: { include: _vm.cachedComponents } },
                  _vm._l(
                    _vm.tabs.filter(function(tab) {
                      return tab.selected
                    }),
                    function(tab) {
                      return _c(
                        _vm.prepareComponentName(tab.component.$type),
                        {
                          key: tab.title,
                          tag: "component",
                          attrs: {
                            options: tab.component,
                            evaluationContext: _vm.evaluationContext,
                            isSelected: tab.selected
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "tabs",
                              attrs: { slot: "title-slot" },
                              slot: "title-slot"
                            },
                            _vm._l(_vm.tabs, function(tab) {
                              return _c(
                                "span",
                                {
                                  key: tab.title,
                                  class: [
                                    "title",
                                    tab.selected && "selected",
                                    tab.titleCssClass
                                  ],
                                  on: {
                                    click: function($event) {
                                      _vm.selectTab(tab)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(tab.title))]
                              )
                            })
                          ),
                          _vm._v(" "),
                          _c("div", {
                            attrs: { slot: "toggle-slot" },
                            slot: "toggle-slot"
                          })
                        ]
                      )
                    }
                  )
                )
              ]
            : _vm._l(_vm.filteredTabs, function(tab) {
                return _c(
                  _vm.prepareComponentName(tab.component.$type),
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: tab.selected,
                        expression: "tab.selected"
                      }
                    ],
                    key: tab.title,
                    tag: "component",
                    attrs: {
                      options: tab.component,
                      evaluationContext: _vm.evaluationContext,
                      isSelected: tab.selected
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "tabs",
                        attrs: { slot: "title-slot" },
                        slot: "title-slot"
                      },
                      _vm._l(_vm.tabs, function(tab) {
                        return _c(
                          "span",
                          {
                            key: tab.title,
                            class: [
                              "title",
                              tab.selected && "selected",
                              tab.titleCssClass
                            ],
                            on: {
                              click: function($event) {
                                _vm.selectTab(tab)
                              }
                            }
                          },
                          [_vm._v(_vm._s(tab.title))]
                        )
                      })
                    ),
                    _vm._v(" "),
                    _c("div", {
                      attrs: { slot: "toggle-slot" },
                      slot: "toggle-slot"
                    })
                  ]
                )
              })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }