/* eslint no-case-declarations: 0 */
import helperMethods from './../ComponentSet/component-set-evaluation-context-helper.methods';
import { isNumber, isFunction } from '@acx-xms/data-functions/dist';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	methods: {
		getSelectedFilter() {
			const size = this.options.facetCount ? helperMethods.eval(this.evaluationContext, this.options.facetCount) : 20;
			if (this.options.searchValue) {
				return null;
			}

			switch (this.options.$type) {
			case 'entity.entityFilter.idsTermFacet':
			case 'entity.entityFilter.termFacet':
				return sc.classes.get('selectedTermFacet.filter', this.logicalName, size, !this.disableCustomItems);
			case 'entity.entityFilter.complex.numericFacet':
			case 'entity.entityFilter.numericFacet':
				return sc.classes.get('selectedNumericFacet.filter', this.logicalName, size, null, { recalculateLimits: this.recalculacteLimits });
			case 'entity.entityFilter.dateTimeFacet':
			case 'entityFilter.complex.dateTimeFacet':
				return sc.classes.get('selectedDateTimeFacet.filter', this.logicalName, size);
			default: return null;
			}
		},
		formInlineValue(value) {
			if (!value) return null;
			const numResult = (data) => {
				let numresult = '';
				if (data.Range.From) numresult += data.Range.From;
				if (data.Range.To) numresult += ' - ' + data.Range.To;
				return numresult;
			};
			switch (this.options.$type) {
			case 'entity.entityFilter.idsTermFacet':
			case 'entity.entityFilter.termFacet':
				return !this.options.itemDisplayName
					? value.items.join(', ')
					: value.items.map((item) => {
						return createEvaluationContext({ Term: item }).eval(this.options.itemDisplayName);
					}).join(', ');
			case 'entity.entityFilter.field':
			case 'entity.entityFilter.complex.field':
				return value.query;
			case 'entity.entityFilter.complex.numericFacet':
			case 'entity.entityFilter.numericFacet':
				return numResult(value.items[0]);

			case 'entity.entityFilter.dateTimeFacet':
			case 'entity.entityFilter.complex.dateTimeFacet':
				let dateresult = '';
				if (value.items[0].Range.From) dateresult += new Date(value.items[0].Range.From).toLocaleDateString();
				if (value.items[0].Range.To) dateresult += ' - ' + new Date(value.items[0].Range.To).toLocaleDateString();
				return dateresult;
			case 'entity.entityFilter.complex.boolTermFacet':
			case 'entity.entityFilter.boolTermFacet':
			case 'entity.entityFilter.favoritesTermFacet':
				return null;
			default: return null;
			}
		},
		formAsyncInlineValue(value) {
			const loadItems = (items) => {
				const ids = sc.classes.get('search.idsFilter', { id: items });
				const entityName = helperMethods.eval(this.evaluationContext, this.options.entityLogicalName);

				sc.classes.get('edge.dataProvider').search({
					filters: [
						ids,
						sc.classes.get('offsetSize.filter', 999),
						sc.classes.get('selectedFields.filter', [
							{ logicalname: 'name' },
							{ logicalname: 'fullname' }
						])
					],
					entities: [entityName]
				}).done((response) => {
					const inlineValues = [];
					response.Results.forEach((result) => {
						const index = items.findIndex((facet) => {
							return facet === result.Id;
						});
						if (index >= 0) {
							if (this.options.entityField) {
								const localEval = createEvaluationContext(result);
								inlineValues.push(helperMethods.eval(localEval, this.options.entityField));
							} else {
								inlineValues.push(result.Name);
							}
						}
					});
					this.asyncInlineValue = inlineValues.join(', ');
				});
			};
			switch (this.options.$type) {
			case 'entity.entityFilter.idsTermFacet':
				loadItems(value.items);
				break;
			}
		},
		getInlineComponentName(options) {
			switch (options.$type) {
			case 'entity.entityFilter.idsTermFacet':
				return 'filter-inline-async';
			default: return 'filter-inline';
			}
		},
		async getPredefinedFilter() {
			const mapFacets = () => {
				return this.options.predefinedFacets.map((facet) => {
					const checked = helperMethods.eval(this.evaluationContext, facet.isChecked);
					if (!checked) return null;
					return helperMethods.eval(this.evaluationContext, facet.value);
				}).filter((f) => { return f; });
			};
			switch (this.options.$type) {
			case 'entity.entityFilter.idsTermFacet':
			case 'entity.entityFilter.termFacet':
				return {
					requestLabel: 'TermFacetFilters',
					data: {
						logicalName: this.logicalName,
						items: mapFacets()
					}
				};
			case 'entity.entityFilter.complex.boolTermFacet':
			case 'entity.entityFilter.numericFacet':
			case 'entity.entityFilter.boolTermFacet':
			case 'entity.entityFilter.favoritesTermFacet':
				const selected = helperMethods.eval(this.evaluationContext, this.options.defaultValue);
				let value = null;
				let defValue = null;
				if (this.options.searchValue) {
					defValue = sc.classes.get(this.options.searchValue.$type, this.options.searchValue, createEvaluationContext(this._uiFltrValue)).toFilter();
				} else {
					defValue = this.options.value ? await helperMethods.evalAsync(this.evaluationContext, this.options.value) : null;
					defValue = {
						requestLabel: 'TermFacetFilters',
						data: {
							logicalName: this.logicalName,
							items: [defValue]
						}
					};
				}
				if (selected && defValue) {
					value = defValue;
				}
				return value;
			case 'entity.entityFilter.dateTimeFacet':
			default: break;
			}
		},
		getURLPredefinedFilter(values) {
			if (!values.length) {
				return;
			}
			let value1 = values[0];
			let value2 = values.length === 2 ? values[1] : null;

			// Validate values
			switch (this.options.$type) {
			case 'entity.entityFilter.idsTermFacet':
				if (!sc.utils.isGuidValid(value1)) { value1 = null; }

				break;
			case 'entity.entityFilter.boolTermFacet':
				if (value1 === 'true') {
					value1 = true;
				} else if (value1 === 'false') {
					value1 = false;
				} else {
					value1 = null;
				}
				break;
			case 'entity.entityFilter.complex.dateTimeFacet':
			case 'entity.entityFilter.dateTimeFacet':
				value1 = new Date(value1).getTime() ? value1 : null;
				value2 = new Date(value2).getTime() ? value2 : null;
				break;
			case 'entity.entityFilter.complex.numericFacet':
			case 'entity.entityFilter.numericFacet':
				value1 = !isNaN(value1) ? value1 : null;
				value2 = !isNaN(value2) ? value2 : null;
			}

			if (!value1 && !value2) {
				return null;
			}
			const setChildFilter = (filterFunc) => {
				if (this.options.childEntity) {
					this.options.childEntity.forEach((entity) => {
						this.$store.commit(this.stateNamespace + '/addOrUpdateFilter',
							{
								entity,
								logicalName: this.options.childLogicalName,
								filter: filterFunc(this.options.childLogicalName)
							});
					});
				}
			};
			switch (this.options.$type) {
			case 'entity.entityFilter.idsTermFacet':
			case 'entity.entityFilter.termFacet':
				return {
					requestLabel: 'TermFacetFilters',
					data: {
						logicalName: this.logicalName,
						items: values
					}
				};
			case 'entity.entityFilter.boolTermFacet':
				if (value1 === void 0 || value1 === null) {
					return null;
				}

				return {
					requestLabel: 'TermFacetFilters',
					data: {
						logicalName: this.logicalName,
						items: [value1]
					}
				};
			case 'entity.entityFilter.complex.dateTimeFacet':
				const getDateTimeFacet = (logicalName) => {
					return {
						requestLabel: 'DateTimeFacetFilters',
						data: {
							logicalName,
							items: [
								{
									isChecked: true,
									Range: {
										From: value1,
										To: value2
									}
								}
							],
							negative: false
						}
					};
				};
				setChildFilter(getDateTimeFacet);
				return getDateTimeFacet(this.logicalName);
			case 'entity.entityFilter.dateTimeFacet':
				return {
					requestLabel: 'DateTimeFacetFilters',
					data: {
						logicalName: this.logicalName,
						items: [
							{
								isChecked: true,
								Range: {
									From: value1,
									To: value2
								}
							}
						],
						negative: false
					}
				};
			case 'entity.entityFilter.complex.numericFacet':
				const getNumericFacet = (logicalName) => {
					return {
						requestLabel: 'NumericFacetFilters',
						data: {
							logicalName,
							items: [
								{
									isChecked: true,
									Range: {
										...(value1 && { From: value1 }),
										...(value2 && { To: value2 }),
										FromIncluded: true,
										ToIncluded: true
									}
								}
							],
							negative: false
						}
					};
				};
				setChildFilter(getNumericFacet);
				return getNumericFacet(this.logicalName);
			case 'entity.entityFilter.numericFacet':
				return {
					requestLabel: 'NumericFacetFilters',
					data: {
						logicalName: this.logicalName,
						items: [
							{
								isChecked: true,
								Range: {
									...(value1 && { From: value1 }),
									...(value2 && { To: value2 }),
									FromIncluded: true,
									ToIncluded: true
								}
							}
						],
						negative: false
					}
				};

			default: return null;
			}
		},
		async getSearchFilter(logicalName) {
			let items = null; let fltr = null; let tmpFltr = null; let isEnabled = false;

			switch (this.options.$type) {
			case 'entity.entityFilter.idsTermFacet':
			case 'entity.entityFilter.termFacet':
				items = this.facets.filter((item) => {
					return item.checked;
				}).map((item) => {
					return {
						$type: 'search.termFacetItem',
						value: item.value
					};
				});
				tmpFltr = sc.classes.get('search.termFacetFilter', {
					logicalName,
					item: items,
					includeEmpty: this.includeEmpty
				}, this.evaluationContext);
				isEnabled = tmpFltr.enable();
				fltr = tmpFltr.toFilter();
				break;
			case 'entity.entityFilter.complex.numericFacet':
			case 'entity.entityFilter.numericFacet':
				tmpFltr = sc.classes.get('search.numericFacetFilter', {
					logicalName,
					item: [{
						$type: 'search.numericFacetItem',

						// line casting for from and to is necessary for the case when the value is 0
						min: isNumber(this.from) ? this.from.toString() : this.from,
						max: isNumber(this.to) ? this.to.toString() : this.to,

						includeMin: true,
						includeMax: true,
						precision: this.precision
					}],
					includeEmpty: this.includeEmpty
				}, this.evaluationContext);
				isEnabled = tmpFltr.enable();
				fltr = tmpFltr.toFilter();
				break;
			case 'entity.entityFilter.field':
			case 'entity.entityFilter.complex.field':
				tmpFltr = sc.classes.get('field.filter', {
					query: this.query || '',
					logicalName,
					additionalOptions: {
						kind: this.options.kind || 'RelevancePrefixAll',
						maxExpansions: this.maxExpansions
					}
				}, this.evaluationContext);
				isEnabled = tmpFltr.hasValue();
				fltr = tmpFltr.fillQuery();
				break;
			case 'entity.entityFilter.complex.boolTermFacet':
			case 'entity.entityFilter.boolTermFacet':
			case 'entity.entityFilter.favoritesTermFacet':
				let value = null;

				if (this.options.value) {
					value = await helperMethods.evalAsync(this.evaluationContext, this.options.value);
				} else {
					value = 'True';
				}
				tmpFltr = sc.classes.get('search.termFacetFilter', {
					logicalName,
					item: [{
						$type: 'search.termFacetItem',
						value
					}]
				}, this.evaluationContext);
				isEnabled = tmpFltr.enable();
				fltr = tmpFltr.toFilter();
				break;
			case 'entity.entityFilter.complex.dateTimeFacet':
			case 'entity.entityFilter.dateTimeFacet':
				tmpFltr = sc.classes.get('search.dateFacetFilter', {
					logicalName,
					item: [{
						$type: 'search.dateFacetItem',
						min: this.from && new Date(this.from.getFullYear(), this.from.getMonth(), this.from.getDate()).addHours(0).addMinutes(0).addSeconds(0),
						max: this.to && new Date(this.to.getFullYear(), this.to.getMonth(), this.to.getDate()).addHours(23).addMinutes(59).addSeconds(59)
					}],
					includeEmpty: this.includeEmpty
				}, this.evaluationContext);
				isEnabled = tmpFltr.enable();
				fltr = tmpFltr.toFilter();
				break;
			case 'entity.entityFilter.boolGeoPolygon':
				if (this.value) {
					tmpFltr = (this.filterType === 'geoDistanceFacet'
						?							sc.classes.get('search.geoDistanceFilter', {
							logicalName,
							value: this.value
						}, this.evaluationContext)
						:							sc.classes.get('search.geoPolygonFacetFilter', {
							logicalName,
							value: this.value
						}, this.evaluationContext));
					isEnabled = isFunction(tmpFltr.enable) ? tmpFltr.enable() : tmpFltr.enable;
					fltr = tmpFltr.toFilter();
				}
				break;
			default: break;
			}

			this._uiFltrValue = this.options.searchValue && fltr;
			return this.options.searchValue && isEnabled
				? sc.classes.get(this.options.searchValue.$type, this.options.searchValue, createEvaluationContext(this._uiFltrValue)).toFilter()
				: fltr;
		},
		getInfoComponentName: function (options) {
			switch (options.$type) {
			case 'entity.entityFilter.idsTermFacet':
				return 'filter-info-panels-ids-list';
			case 'entity.entityFilter.termFacet':
				return 'filter-info-panels-list';
			case 'entity.entityFilter.numericFacet':
			case 'entity.entityFilter.complex.numericFacet':
				return 'filter-info-panels-slider';
			case 'entity.entityFilter.dateTimeFacet':
			case 'entity.entityFilter.complex.dateTimeFacet':
				return 'filter-info-panels-date-range';
			case 'entity.entityFilter.field':
			case 'entity.entityFilter.complex.field':
				return 'filter-info-panels-field';
			case 'entity.filter.savedSearch':
				return 'saved-search-info-panel';
			default:
				return 'filter-info-panels-list';
			}
		},
		getInlineView() {
			switch (this.options.$type) {
			case 'entity.entityFilter.boolField':
			case 'entity.entityFilter.complex.boolTermFacet':
			case 'entity.entityFilter.boolTermFacet':
				return 'bool-inline-view';
			case 'entity.entityFilter.boolGeoPolygon':
				return 'geo-polygon-inline-view';
			case 'entity.entityFilter.favoritesTermFacet':
				return 'favorites-inline-view';
			default: return null;
			}
		},
		addTermFacetStatistic(statistic, newItem) {
			switch (this.options.$type) {
			case 'entity.entityFilter.idsTermFacet':
			case 'entity.entityFilter.termFacet':
				const filteredTerms = statistic.TermFacets.filter((filter) => {
					return filter.LogicalName === this.logicalName;
				});

				if (filteredTerms[0] && filteredTerms[0].Items) {
					filteredTerms[0].Items.push(newItem);
				}
				break;
			}

			return statistic;
		},
		readStatistic: function (statistic) {
			const readNumeric = (logicalName) => {
				const filteredNumeric = statistic.NumericFacets.filter((filter) => {
					return filter.LogicalName === logicalName;
				});
				return filteredNumeric.length > 0 ? filteredNumeric[0] : {};
			};
			switch (this.options.$type) {
			case 'entity.entityFilter.idsTermFacet':
			case 'entity.entityFilter.termFacet':
				const filteredTerms = statistic.TermFacets.filter((filter) => {
					return filter.LogicalName === this.logicalName;
				});
				return filteredTerms.length > 0 ? this.formFacets(filteredTerms[0].Items) : [];
			case 'entity.entityFilter.numericFacet':
			case 'entity.entityFilter.complex.numericFacet':
				return readNumeric(this.logicalName);
			default: return null;
			}
		},
		formFacets: function (items, checked) {
			return items.map((item) => {
				const facet = {
					count: item.Count,
					value: item.Term,
					// todo add display name rules
					displayName: item.Term,
					// todo - apply values
					checked: checked || false
				};

				if (this.options.itemDisplayName) {
					// todo - replace with new context when ready
					facet.displayName = createEvaluationContext(item).eval(this.options.itemDisplayName);
				}
				return facet;
			});
		},
		applyFilterValue(value) {
			const applyItems = (items) => {
				items.forEach((value) => {
					const index = this.facets.findIndex((facet) => { return facet.value === value; });
					if (index >= 0) {
						this.facets[index].checked = true;
					} else {
						let displayName;

						if (this.options.itemDisplayName) {
							displayName = ko.createEvaluationContext({ Term: value }).eval(this.options.itemDisplayName);
						} else {
							displayName = value;
						}

						this.facets.push({
							count: 0,
							value,
							displayName,
							checked: true
						});
					}
				});
			};

			const applyRange = (items, isDate) => {
				if (!items) return;
				this.from = isDate && items[0].Range.From ? new Date(items[0].Range.From) : items[0].Range.From;
				this.to = isDate && items[0].Range.To ? new Date(items[0].Range.To) : items[0].Range.To;
				this.initialFrom = this.from;
				this.initialTo = this.to;
			};

			switch (this.options.$type) {
			case 'entity.entityFilter.dateTimeFacet':
			case 'entity.entityFilter.complex.dateTimeFacet':
				// because we recieve a string from saved search record
				applyRange(value.data.items, true);
				break;
			case 'entity.entityFilter.complex.numericFacet':
			case 'entity.entityFilter.numericFacet':
				applyRange(value.data.items);
				break;
			case 'entity.entityFilter.idsTermFacet':
			case 'entity.entityFilter.termFacet':
				applyItems(value.data.items);
				break;
			case 'entity.entityFilter.field':
			case 'entity.entityFilter.complex.field':
				this.query = value.data.query;
				break;
			}
		},
		resetFilter() {
			const resetItems = () => {
				this.facets && this.facets.forEach(facet => { facet.checked = false; });
			};
			const resetRange = () => {
				this.from = null;
				this.to = null;
				this.initialFrom = null;
				this.initialTo = null;
			};
			const resetNumeric = () => {
				this.from = this.options.searchValue ? null : this.min;
				this.to = this.options.searchValue ? null : this.max;
				this.set('from', this.from);
				this.set('to', this.to);
			};
			const resetGeoPoligon = () => {
				this.fork.emit(this.stateNamespace + '.mapSearchResults.areaSelectionFilter-reset', { clearPolygon: true });
			};

			this.includeEmpty = false;
			switch (this.options.$type) {
			case 'entity.entityFilter.numericFacet':
			case 'entity.entityFilter.complex.numericFacet':
				resetNumeric();
				break;
			case 'entity.entityFilter.dateTimeFacet':
			case 'entity.entityFilter.complex.dateTimeFacet':
				resetRange();
				break;
			case 'entity.entityFilter.field':
			case 'entity.entityFilter.complex.field':
				this.query = '';
				break;
			case 'entity.entityFilter.idsTermFacet':
			case 'entity.entityFilter.termFacet':
				resetItems();
				break;
			case 'entity.entityFilter.boolGeoPolygon':
				resetGeoPoligon();
				break;
			}
		},
		emitFilterChanged(filter) {
			this.$store.commit(this.stateNamespace + '/addOrUpdateFilter', {
				entity: this.entity,
				logicalName: this.logicalName,
				filter
			});
			if (this.options.searchValue) {
				this.$store.commit(this.stateNamespace + '/addOrUpdateUiFilter', {
					entity: this.entity,
					logicalName: this.logicalName,
					filter: this._uiFltrValue
				});
			}

			if (['entity.entityFilter.complex.boolTermFacet',
				'entity.entityFilter.complex.numericFacet',
				'entity.entityFilter.complex.field',
				'entity.entityFilter.complex.dateTimeFacet'].indexOf(this.options.$type) >= 0) {
				let childFilter = null;

				if (filter && this.options.childSearchValue) {
					const chldFltr = sc.classes.get(this.options.childSearchValue.$type, this.options.childSearchValue, createEvaluationContext(this._uiFltrValue)).toFilter();
					this.options.childEntity.forEach((entity) => {
						this.$store.commit(this.stateNamespace + '/addOrUpdateFilter', {
							entity,
							logicalName: this.options.childLogicalName,
							filter: chldFltr
						});
					});
				} else if (this.options.childEntity && filter) {
					childFilter = JSON.parse(JSON.stringify(filter));

					if (this.options.collectionName) {
						childFilter = childFilter.data.data;
					} else {
						childFilter.data.logicalName = this.options.childLogicalName;
					}

					this.options.childEntity.forEach((entity) => {
						this.$store.commit(this.stateNamespace + '/addOrUpdateFilter', {
							entity,
							logicalName: this.options.childLogicalName,
							filter: childFilter
						});
					});
				} else if (this.options.childLogicalName && this.options.childEntity && this.options.childEntity.length) {
					this.options.childEntity.forEach((entity) => {
						this.$store.commit(this.stateNamespace + '/addOrUpdateFilter', {
							entity,
							logicalName: this.options.childLogicalName,
							filter: null
						});
					});
				}
			}
			this.$root.$emit(this.stateNamespace + 'search');
		},
		openFilter() {
			this.$store.commit(this.stateNamespace + '/openInfoPanel', this.options);
		},
		async setPredefinedFilters() {
			if (this.options.predefinedFacets || this.options.defaultValue) {
				const filter = await this.getPredefinedFilter();
				this.$store.commit(this.stateNamespace + '/addOrUpdateFilter', {
					entity: this.entity,
					logicalName: this.logicalName,
					filter
				});
				this.$store.commit(this.stateNamespace + '/addDefaultFilter', {
					entity: this.entity,
					logicalName: this.logicalName,
					filter
				});
			}

			this.setFiltersFromQueryString();
		},
		setFiltersFromQueryString() {
			const query = this.$route.query;
			let value = query[this.logicalName];

			if (value && value.length) {
				value = value.split(',');
				const filter = this.getURLPredefinedFilter(value);

				if (filter) {
					this.$store.commit(this.stateNamespace + '/addOrUpdateFilter', {
						entity: this.entity,
						logicalName: this.logicalName,
						filter
					});
				}
			}
		}
	}
};
