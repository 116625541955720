import { Search } from '@acx-xms/data-functions/dist';
import enabled from './Methods/action.staticlist.enableBase';

export default {
	enabled,

	async execute(options, context, selection) {
		const componentSet = await sc.classes.get('marketspaceComponents.dataProvider').getComponentSetById(context.eval(options.componentsetName));
		const members = await Search('staticlistmember',
			[
				sc.classes.get('offsetSize.filter', 10000),
				sc.classes.get('termFacet.filter', {
					logicalName: 'staticlist.id',
					query: [context.entity.id]
				}).fillQuery(),
				sc.classes.get('selectedFields.filter', [
					{ logicalname: 'entityreference' }
				]).fillQuery()
			]);
		const entityRefs = (members.Results || []).map(member =>
			member.Source.entityreference
		);
		const listings = entityRefs.filter(ref => ref.logicalname === 'listing').map(ref => ref.id);
		const listingFilter = {
			data: listings.length ? listings : ['00000000-0000-0000-0000-000000000000'],
			entity: 'listing',
			requestLabel: 'Ids'
		};
		const availabilities = entityRefs.filter(ref => ref.logicalname === 'availability').map(ref => ref.id);
		const availabilityFilter = {
			data: availabilities.length ? availabilities : ['00000000-0000-0000-0000-000000000000'],
			entity: 'availbility',
			requestLabel: 'Ids'
		};

		sc.events.emit('vue.dialog.open', {
			icon: 'entity-icons-deal-small',
			title: 'Static List Details',
			cancelBtnText: 'close',
			allowPin: false,
			component: 'ms.addlisting.dialog',
			maximizedWidth: '90%',
			maximizedHeight: '100%',
			evaluationContext: context,
			params: {
				componentSet,
				modalNamespace: 'addlistingpopup',
				searchResultComponentName: options.searchResultComponentName,
				defaultFilters: {
					listing: listingFilter,
					availability: availabilityFilter
				},
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey)
			}
		});
	}
};
