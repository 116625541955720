import { render, staticRenderFns } from "./control-image-gallery-small-base.vue?vue&type=template&id=4a9534f2&"
import script from "./control-image-gallery-small-base.vue?vue&type=script&lang=js&"
export * from "./control-image-gallery-small-base.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./control-image-gallery-small-styles.less?vue&type=custom&index=0&blockType=styles&issuerPath=D%3A%5Ca%5C1%5Cs%5CSCCore%5CScripts%5CVue%5CComponents%5CControl%5CImages%5CImageGallery%5Ccontrol-image-gallery-small-base.vue"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\SCCore\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('4a9534f2', component.options)
    } else {
      api.reload('4a9534f2', component.options)
    }
    module.hot.accept("./control-image-gallery-small-base.vue?vue&type=template&id=4a9534f2&", function () {
      api.rerender('4a9534f2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Scripts/Vue/Components/Control/Images/ImageGallery/control-image-gallery-small-base.vue"
export default component.exports