var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mobile-menu-userprofile",
    {
      attrs: {
        isAnonymous: _vm.isAnonymous,
        params: { userProfileMenu: _vm.userProfileMenu },
        initMenuState: _vm.initMenuState
      }
    },
    [
      _c("div", { staticClass: "buttons" }, [
        _c("button", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "userPanel.viewProfile" },
              expression: "{key: 'userPanel.viewProfile'}"
            }
          ],
          staticClass: "clickable",
          on: {
            click: function($event) {
              _vm.showUserDetails()
            }
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }