import ScMapGoogleMediator from '../scMap.google.mediator';

export default class {
	constructor(map, options) {
		if (!map) {
			throw new Error('scMapPolyline.init: `map` property is not defined');
		}
		this.map = map;

		this.options = {
			strokeColor: '#000',
			strokeWeight: 2,
			...options
		};
		const shapeOptions = {
			map: this.map._map,
			strokeWeight: this.options.strokeWeight,
			strokeColor: this.options.strokeColor,
			strokeStyle: this.options.strokeStyle,
			strokeOpacity: this.options.strokeOpacity,
			clickable: this.options.clickable
		};

		if (this.options.strokeStyle === 'dashed') {
			shapeOptions.strokeOpacity = 0;

			shapeOptions.icons = [{
				icon: {
					path: 'M 0,-' + (5 - this.options.strokeWeight / 2) + ' 0,' + (5 - this.options.strokeWeight / 2),
					strokeWeight: this.options.strokeWeight,
					strokeOpacity: 1,
					scale: 1
				},

				offset: '0',
				repeat: 15 + this.options.strokeWeight + 'px'
			}];
		}

		this._shape = new google.maps.Polyline(shapeOptions);

		this._path = this._shape.getPath();

		this.mediator = new ScMapGoogleMediator({
			target: this._shape,
			context: this
		});
	}

	push(lat, lng) {
		this._path.push(new google.maps.LatLng(lat, lng));
	}

	clear() {
		this._path.clear();
	}

	getPath() {
		return this._path.getArray().map(item => {
			return {
				lat: item.lat(),
				lng: item.lng()
			};
		});
	}

	setPath(coords) {
		this._path.clear();
		coords.forEach(coord => {
			const latLon = new google.maps.LatLng(coord.lat, coord.lng);
			this._path.push(latLon);
		});
	}

	remove() {
		this.mediator.dispose();
		this._shape.setMap(null);
	}
}
