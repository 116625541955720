import ScMapGoogleProvider from './scMap.google.provider';
export default class {
	constructor(options) {
		this.data = { options };
	}

	async initMap() {
		let data = await this.fetchConfiguration(this.data);
		data = await this.fetchProviderConfiguration(data);
		data = await this.getProvider(data);
		return data;
	}

	fetchConfiguration(data) {
		const settingsProvider = sc.classes.get('settings.dataProvider');
		return settingsProvider.get('scMap-settings').then((mainConfig) => {
			data.mainConfig = mainConfig;
			return data;
		});
	}

	fetchProviderConfiguration(data) {
		const settingsProvider = sc.classes.get('settings.dataProvider');
		return Promise.all([
			settingsProvider.get('scmap-' + data.mainConfig.defaultProvider + '-settings'),
			settingsProvider.getEnvironmentSettings()
		]).then(([providerConfig, environmentConfig]) => {
			data.providerConfig = providerConfig;
			data.providerConfig.apiKey = environmentConfig[data.mainConfig.defaultProvider + 'ApiKey'];
			return data;
		});
	}

	getProvider(data) {
		const provider = new ScMapGoogleProvider({
			...data.options,
			...data.mainConfig
		}, data.providerConfig);

		if (data.options.dataProviderOnly) {
			return $.when(provider);
		}
		return provider.fetchApi(data.mainConfig).then(() => {
			provider._start();
			return provider;
		});
	}
}
