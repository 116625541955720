var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.options.hide,
          expression: "!options.hide"
        },
        { name: "data-attr", rawName: "v-data-attr" }
      ],
      staticClass: "marketspace-conversation-menu",
      attrs: { title: "Chat Messages" },
      on: { click: _vm.openConversation }
    },
    [
      _c("svg", { staticClass: "svg-icon svg-20" }, [
        _c("use", {
          staticClass: "icon",
          attrs: { "xlink:href": "#action-icons-ms-menu-messages" }
        })
      ]),
      _vm._v(" "),
      _vm.count ? _c("Counter", { attrs: { count: _vm.count } }) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }