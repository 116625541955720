const format = (value, precision) => {
	const d = CultureInfo.numberFormat.decimalSeparator;
	const s = CultureInfo.numberFormat.groupSeparator;
	const re = '\\d(?=(\\d{3})+' + (precision > 0 ? '\\D' : '$') + ')';
	let num = value.toFixed(Math.max(0, Math.trunc(precision)));

	num = num.replace('.', d);
	num = num.replace(new RegExp(re, 'g'), '$&' + s);

	return num;
};
export default {
	parameters(options) {
		let prefix = '';
		let sufix = '';

		switch (options.uom) {
		case 'none':
			break;
		case 'money':
			// TODO: implement multicurrency here. See old formatNumber expression
			prefix = '$';
			break;
		case 'area':
		case 'linear':
			sufix = 'sf';
			break;
		case 'distance':
			sufix = 'ft';
			break;
		case 'land':
			sufix = 'ac';
			break;
		default:
			throw new Error(`Unknown UOM type: ${options.uom}`);
		}

		return [
			this.eval(options.argument),
			options.precision,
			prefix,
			sufix
		];
	},
	async parametersAsync(options) {
		let prefix = '';
		let sufix = '';

		switch (options.uom) {
		case 'none':
			break;
		case 'money':
			// TODO: implement multicurrency here
			prefix = '$';
			break;
		case 'area':
		case 'linear':
			sufix = 'sf';
			break;
		case 'distance':
			sufix = 'ft';
			break;
		case 'land':
			sufix = 'ac';
			break;
		default:
			throw new Error(`Unknown UOM type: ${options.uom}`);
		}

		return [
			await this.evalAsync(options.argument),
			options.precision,
			prefix,
			sufix
		];
	},
	evaluate(arg, precision, prefix, sufix) {
		const num = parseFloat(arg);

		if (isNaN(num)) {
			return '';
		}
		let result = prefix + format(num, precision);
		if (sufix) {
			result += ' ' + sufix;
		}
		return result;
	}
};
