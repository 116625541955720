import {
	isActive, isAllowedEntity, mapParametersObject
} from './Methods/action.methods';
import store from '@/States/ms-state';

export default {
	async enabled(options, evaluationContext, selection, fullselection) {
		return isAllowedEntity(options.allowedEntityTypes, fullselection) && await isActive(evaluationContext, selection);
	},

	async execute(options, context, selection) {
		const localizations = sc.classes.get('localization.dataProvider');
		const parameters = {
			predefinedFields: mapParametersObject(options.parameter, context),
			entity: context.eval(options.entityRef),
			layoutsRedirectToMain: context.eval(options.layoutsRedirectToMain)
		};
		if (options.stateKeyForPredefined) {
			const lookupPredefinedFields = store.state[options.stateKeyForPredefined].lookupPredefinedFields;
			if (lookupPredefinedFields.parentRecord) {
				lookupPredefinedFields[`${lookupPredefinedFields.parentRecord.logicalname}id`] = lookupPredefinedFields.parentRecord;
				delete lookupPredefinedFields.parentRecord;
			}
			parameters.predefinedFields = {
				...parameters.predefinedFields,
				...lookupPredefinedFields
			};
		}
		const entityData = await sc.classes.get('entityConfiguration.dataProvider').fetchEntity(context.eval(options.logicalname));
		const entityLabel = await context.evalAsync(entityData.displayName);
		// TODO: need to rework here.
		// The problem is that sometimes we need the action to be enabled only on single selection but we don't need to use this selection
		// Consider storing singleSelection parameter rather in separate element than in 'options.source' to avoid selection confsuion
		// and use 'noSelection' only to tell that we don't need to use selection in the action.
		if (!parameters.entity && options.source !== 'noSelection' && selection && selection.length &&
			// May be need to unify what we store in selection (full record or entity reference)
			(selection[0].Type || selection[0].logicalname) === entityData.logicalName) {
			parameters.entity = sc.classes.get('entityReference', selection[0]);
		}

		let title;
		if (parameters.predefinedFields && parameters.predefinedFields.externalTitle) {
			title = parameters.predefinedFields.externalTitle;
			delete parameters.predefinedFields.externalTitle;
		} else {
			if (parameters.entity && parameters.entity.id) {
				title = await localizations.getLabelForCurrentLanguage('entity.editor.titleEdit', [entityLabel]);
			} else {
				title = await localizations.getLabelForCurrentLanguage('entity.editor.titleCreate', [entityLabel]);
			}
		}
		if (parameters.predefinedFields && parameters.predefinedFields.refreshNamespace) {
			parameters.refreshNamespace = context.eval(parameters.predefinedFields.refreshNamespace);
			delete parameters.predefinedFields.refreshNamespace;
		}
		const maxWidth = options.dialogWidth || (entityData.editDialogWidth ? context.eval(entityData.editDialogWidth) : '80%');
		const maxHeight = options.dialogHeight || (entityData.editDialogHeight ? context.eval(entityData.editDialogHeight) : '100%');
		const scroll = options.scroll ? context.eval(options.scroll) : true;
		sc.events.emit('vue.dialog.open', {
			icon: 'action-icons-search-save',
			title,
			component: options.editorType || 'vue.editor',
			maximizedWidth: maxWidth,
			maximizedHeight: maxHeight,
			evaluationContext: context,
			logicalname: options.logicalname,
			relatedEntityLogicalName: options.relatedEntityLogicalName,
			modalNamespace: options.modalNamespace,
			stateNamespace: options.stateNamespace,
			predefinedAspectsFields: options.predefinedAspectsFields,
			subtype: options.subtype,
			toastMessageKey: context.eval(options.toastMessageKey),
			informationDialogTextKey: context.eval(options.informationDialogTextKey),
			stateField: options.stateField,
			mode: options.mode,
			scroll,
			parameters,
			asyncSave: context.eval(options.asyncSave || false),
			asyncSaveToastMsgKey: await context.evalAsync(options.asyncSaveToastMsgKey || '')
		});
	}
};
