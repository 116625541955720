<template>
	<div :class="['toggle-wrapper', { parent: isParent }]">
		<div v-show="visible" class="toggle-buttons-container display-modifier">
			<div
				v-show="uncollapseVisibility"
				v-data-attr="{ options: $parent.options, stateNamespace: 'expand-button' }"
				class="toggle-button"
				@click="expand"
			>
				<span>&#x2b;</span>
			</div>
			<div
				v-show="collapseVisibility"
				v-data-attr="{ options: $parent.options, stateNamespace: 'collapse-button' }"
				class="toggle-button"
				@click="collapse"
			>
				<span>&ndash;</span>
			</div>
		</div>
	</div>
</template>
<script>
import '@/Bindings/ui-attribute.directive';

export default {
	name: 'results-toggle',
	props: {
		namespace: String,
		internalNamespace: String,
		toggleNamespace: String,
		isParent: Boolean,
		initCollapsed: Boolean
	},
	data() {
		return {
			visible: !!this.toggleNamespace,
			collapsed: this.initCollapsed || false
		};
	},
	created() {
		this.fork = sc.events.fork();
		if (!this.isParent) {
			this.fork.on(this.namespace + '.' + this.toggleNamespace + '.toggleResults', (state) => {
				// force inner toggle state
				this.toggle(state);
			});
		}
	},
	computed: {
		collapseVisibility() {
			return this.isParent ? true : !this.collapsed;
		},
		uncollapseVisibility() {
			return this.isParent ? true : this.collapsed;
		}
	},
	methods: {
		toggle(state) {
			if (!this.isParent) {
				this.$parent.$emit('toggleResults', state);
			} else {
				this.fork.emit(`${this.namespace}.${this.toggleNamespace}.toggleResults`, state);
			}

			this.collapsed = state;
		},
		expand() {
			this.toggle(false);
		},

		collapse() {
			this.toggle(true);
		}
	},
	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
		}
	}
};
</script>
<style src='./results-toggle.less' scoped></style>
