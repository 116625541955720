var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        {
          directives: [
            {
              name: "targeted-popup",
              rawName: "v-targeted-popup",
              value: { target: _vm.target },
              expression: "{ target }"
            }
          ],
          staticClass: "action-menu-container"
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "clickoutside",
                  rawName: "v-clickoutside",
                  value: {
                    visible: _vm.visible,
                    callback: _vm.toggleMenu,
                    clickTarget: _vm.clickTarget
                  },
                  expression:
                    "{ visible: visible, callback: toggleMenu, clickTarget}"
                }
              ],
              staticClass: "action-menu"
            },
            [
              _c("div", { staticClass: "dropdown-wrapper" }, [
                _c("div", { staticClass: "top-right-triangle" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "items" },
                  _vm._l(_vm.items, function(item) {
                    return _c("menu-item", {
                      key: _vm.getKey(item),
                      attrs: {
                        options: item.menuItem,
                        evaluationContext: _vm.evaluationContext
                      },
                      nativeOn: {
                        click: function($event) {
                          _vm.executeCallback &&
                            _vm.executeCallback(item.menuItem.action)
                        }
                      }
                    })
                  })
                )
              ])
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }