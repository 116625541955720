var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "current-rating",
      class: [_vm.cssClass, { clicked: _vm.clicked }],
      on: { click: _vm.setRating }
    },
    [
      !_vm.isLoading
        ? _c("svg", { staticClass: "svg-icon" }, [
            _c("use", { attrs: { "xlink:href": "#layout-icons-" + _vm.icon } })
          ])
        : _c("div", { staticClass: "loading-wrapper" }, [
            _c("svg", { staticClass: "svg-icon" }, [
              _c("use", {
                staticClass: "layout-icons-favorite-spinner",
                attrs: { "xlink:href": "#layout-icons-favorite-spinner" }
              })
            ])
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }