var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible && _vm.isEnabled && !_vm.isLoading
    ? _c(
        "div",
        {
          staticClass: "actionButton clickable",
          class: _vm.styles,
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.click($event)
            }
          }
        },
        [
          _vm.icon
            ? _c("span", { staticClass: "icon-wrapper" }, [
                _c("svg", { staticClass: "svg-icon", class: _vm.size }, [
                  _c("use", {
                    attrs: { "xlink:href": "#action-icons-" + _vm.icon }
                  })
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.caption))])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }