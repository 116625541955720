import router from '@/router';
import { getBaseRouteUrl } from '@acx-xms/data-functions/dist';

export default {
	async enabled(options, evaluationContext, selection) {
		return true;
	},
	async execute(options, context, selection) {
		const path = context.eval(options.path);
		router.push(`${getBaseRouteUrl(router.currentRoute)}/${path}`);
		options.resetFilters && sc.events.emit('routeUpdated');
	}
};
