var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "config-tool-wrapper" },
    [
      !_vm.isLoading && !_vm.isChildLoading
        ? _c(
            "div",
            { staticClass: "header" },
            [
              _vm.breadcrumbs
                ? _c("breadcrumbs", { attrs: { breadcrumbs: _vm.breadcrumbs } })
                : _vm._e(),
              _vm._v(" "),
              _vm.actionMenuComponent
                ? _c("action-menu", {
                    staticClass: "without-padding without-border",
                    attrs: {
                      options: _vm.actionMenuComponent,
                      record: _vm.templateRecord,
                      evaluationContext: _vm.evaluationContext
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c("config-tool", {
            attrs: { templateRecord: _vm.templateRecord },
            on: {
              onLoadingChange: _vm.onLoadingChange,
              onIsDirtyChange: _vm.onIsDirtyChange
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading || _vm.isChildLoading,
            expression: "isLoading || isChildLoading"
          }
        ],
        staticClass: "loading-overlay"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }