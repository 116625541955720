var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.cssClass, "control-svg-image"] },
    [
      _vm.visible && _vm.topIcon
        ? _c("div", { staticClass: "svg-top-icon-wrapper" }, [
            _c("svg", { staticClass: "svg-icon svg-icon-top svg-12" }, [
              _c("use", {
                attrs: { "xlink:href": "#entity-icons-" + _vm.topIcon }
              })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.visible && !_vm.imgObj
        ? _c("image-placeholder", {
            style: {
              width: _vm.identifyPixelWidth() + _vm.widthUom,
              height: _vm.identifyPixelHeight() + _vm.heightUom
            },
            attrs: {
              isCustomImagePlaceholder: _vm.options.isCustomImagePlaceholder,
              cssClass: _vm.svgClass(),
              placeholderEntityName:
                "#entity-icons-" + _vm.placeholderEntityName,
              alt: _vm.options.alt
            }
          })
        : _vm.visible && _vm.imgObj
        ? _c("div", {
            directives: [
              {
                name: "bg-image",
                rawName: "v-bg-image",
                value: _vm.imgObj,
                expression: "imgObj"
              }
            ],
            style: { cursor: _vm.imageCursor },
            on: { click: _vm.imageClick }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }