var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasData
    ? _c(_vm.chartName, {
        tag: "component",
        staticClass: "control-component",
        attrs: { options: _vm.options, data: _vm.data, styles: _vm.styles }
      })
    : !_vm.hasData
    ? _c("div", { staticClass: "no-results-message" }, [
        _c("div", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.noMatchesFound" },
              expression: "{ key: 'common.dictionary.noMatchesFound' }"
            }
          ],
          staticClass: "p13"
        })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }