import { isActive, mapParametersObject } from './Methods/action.methods';

export default {
	async enabled(options, context, selection) {
		return selection && selection.length === 1 && await isActive(context, selection);
	},
	async execute(options, context, selection) {
		if (selection.length) {
			const parameters = mapParametersObject(options.parameter, context);
			const preferenceData = parameters.entityData || selection[0];

			sc.events.emit('vue.dialog.open', {
				title: await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('addPreferenceDialog.title'),
				component: 'add-preference-dialog',
				maximizedWidth: '450px',
				maximizedHeight: '390px',
				preferenceData,
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey),
				refreshEvent: context.eval(options.refreshEvent)
			});
		}
	}
};
