import { isAllowedDeactivation, mapParametersObject } from './action.methods';

export default {
	enabled(options, evaluationContext, selection) {
		return isAllowedDeactivation(options, evaluationContext, selection);
	},
	execute(options, context, selection) {
		const parameters = mapParametersObject(options.parameter, context);
		// for deactivation of cr on listing detils (selection there is crlisting so we manually pass the selection to action)
		let sel = context.eval(options.selection) || selection;
		const relateFromRecord = options.relateFromRecord && context.eval(options.relateFromRecord);
		if (!Array.isArray(sel)) {
			sel = [sel];
		}
		sc.events.emit('vue.dialog.open', {
			localizedTitle: parameters.titleMessageKey || 'common.dictionary.confirmDeactivationDlgCaption',
			component: 'deactivate-dialog',
			maximizedHeight: options.documentRelatedRecordsTemplate ? options.dialogHeight || '400px' : 'auto',
			maximizedWidth: options.dialogWidth || '500px',
			documentRelatedRecordsTemplate: options.documentRelatedRecordsTemplate,
			restrictedToDeactivateRecordsListTemplate: options.restrictedToDeactivateRecordsListTemplate,
			evaluationContext: context,
			params: {
				entities: sel,
				isDeactivation: true,
				parameters,
				refreshEvent: options.refreshEvent,
				isFolder: options.isFolder && context.eval(options.isFolder),
				fieldsToCheck: options.fieldsToCheck,
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey),
				lookupFilters: options.lookupFilters,
				relateFromRecord,
				acceptCallback: options.acceptCallback
			}
		});
	}
};
