import ScMapGoogleMediator from './scMap.google.mediator';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import mapUtils from './utils/mapUtils';

export default class {
	constructor(map, options) {
		if (!map) {
			throw new Error('scMapClusterer.init: Argument `map` is required');
		}
		this.map = map;
		this.pushspinsSettings = this.map.options.mapConfig.pushpins;
		this.options = {
			map: this.map.options.mapConfig,
			zoomOnClick: true,
			styles: [],
			calculator: void 0,
			maxZoom: void 0,
			...options
		};
		const clusterOptions = {
			zoomOnClick: this.options.zoomOnClick,
			imagePath: location.origin + sc.utils.getStaticContentUrl('/Images/map-clusters/m'),
			...(this.options.styles.length && { styles: this.options.styles }),
			renderer: {
				render: cluster => {
					const hasSelectedMarkersInCluster = this.selectedMarkers.some(selectedMarker =>
						cluster.markers.some(clusterMarker => clusterMarker === selectedMarker)
					);
					let colors = {
						idle: this.pushspinsSettings.markerCluster.cssVariableName,
						hover: this.pushspinsSettings.markerClusterHover.cssVariableName
					};
					if (this.selectedMarkers.length && hasSelectedMarkersInCluster) {
						colors = {
							idle: this.pushspinsSettings.markerClusterActive.cssVariableName,
							hover: this.pushspinsSettings.markerClusterActiveHover.cssVariableName
						};
					}
					const svg = mapUtils.buildSVG('multi', mapUtils.getColorByName(colors.idle), 32, 32);
					const hoveredSvg = mapUtils.buildSVG('multi', mapUtils.getColorByName(colors.hover), 32, 32);
					const marker = new google.maps.Marker({
						position: cluster.position,
						icon: {
							url: svg,
							scaledSize: new google.maps.Size(32, 32)
						},
						label: {
							text: String(cluster.count),
							color: 'white',
							fontSize: '1rem'
						}
					});

					google.maps.event.addListener(marker, 'mouseover', () => marker.setIcon({
						url: hoveredSvg,
						scaledSize: new google.maps.Size(32, 32)
					}));
					google.maps.event.addListener(marker, 'mouseout', () => marker.setIcon({
						url: svg,
						scaledSize: new google.maps.Size(32, 32)
					}));
					google.maps.event.addListener(marker, 'click', (event) => { this.event_element = event.domEvent.target; }); /* Set target for custom event */
					return marker;
				}
			},
			onClusterClick: () => ({}) /* Can't use this event, because it runs later that custom event */
		};
		const resultedOptions = {
			map: this.map._map,
			markers: [],
			...clusterOptions
		};
		this._clusterer = new MarkerClusterer(resultedOptions);

		this.mediator = new ScMapGoogleMediator({
			target: this._clusterer,
			context: this,
			events: ['clusteringbegin', 'clusteringend'],
			filterTargetEvent: {
				click: (cluster) => {
					const location = cluster.position;
					const markers = cluster.markers.map(marker => this.map.__markers.find(item => item._marker === marker));
					return {
						location: {
							lat: location.lat(),
							lng: location.lng()
						},
						markers,
						target: this.event_element
					};
				}
			}
		});
	}

	addMarker(marker, noRedraw) {
		this._clusterer.addMarker(marker._marker, noRedraw);
	}

	removeMarker(marker, noRedraw) {
		this._clusterer.removeMarker(marker._marker, noRedraw);
	}

	getListeners(marker, listeners) {
		return listeners.filter(listener => google.maps.event.hasListeners(marker, listener));
	}

	setSelection(selectedMarkers) {
		this._clusterer.clusters.forEach(cluster => {
			let hasSelectedItems;
			cluster.markers.length > 1 && cluster.markers.forEach(marker => {
				const hasSelecteMarkers = selectedMarkers.some(sm => sm === marker);
				const listeners = this.getListeners(cluster.marker, ['mouseout, mouseover']);
				listeners.forEach(listener => google.maps.event.clearListeners(cluster.marker, listener));

				if (hasSelecteMarkers) {
					cluster.marker.setIcon({
						url: mapUtils.buildSVG('multi', mapUtils.getColorByName(this.pushspinsSettings.markerClusterActive.cssVariableName)),
						scaledSize: new google.maps.Size(32, 32)
					});
					google.maps.event.addListener(cluster.marker, 'mouseout', () =>
						cluster.marker.setIcon({
							url: mapUtils.buildSVG('multi', mapUtils.getColorByName(this.pushspinsSettings.markerClusterActive.cssVariableName)),
							scaledSize: new google.maps.Size(32, 32)
						})
					);
					google.maps.event.addListener(cluster.marker, 'mouseover', () =>
						cluster.marker.setIcon({
							url: mapUtils.buildSVG('multi', mapUtils.getColorByName(this.pushspinsSettings.markerClusterActiveHover.cssVariableName)),
							scaledSize: new google.maps.Size(32, 32)
						})
					);

					hasSelectedItems = true;
				} else if (!hasSelectedItems) {
					cluster.marker.setIcon({
						url: mapUtils.buildSVG('multi', mapUtils.getColorByName(this.pushspinsSettings.markerCluster.cssVariableName)),
						scaledSize: new google.maps.Size(32, 32)
					});
					google.maps.event.addListener(cluster.marker, 'mouseout', () =>
						cluster.marker.setIcon({
							url: mapUtils.buildSVG('multi', mapUtils.getColorByName(this.pushspinsSettings.markerCluster.cssVariableName)),
							scaledSize: new google.maps.Size(32, 32)
						})
					);
					google.maps.event.addListener(cluster.marker, 'mouseover', () =>
						cluster.marker.setIcon({
							url: mapUtils.buildSVG('multi', mapUtils.getColorByName(this.pushspinsSettings.markerClusterHover.cssVariableName)),
							scaledSize: new google.maps.Size(32, 32)
						})
					);
				}
			});
		});

		this.selectedMarkers = selectedMarkers;
	}
}
