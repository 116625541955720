<template>
	<li
		v-if="visible"
		:class="['filter', {
			selected: inlineValue,
			open: isExpanded
		}]"
	>
		<div
			v-data-attr="{ options, stateNamespace: options.displayName.value }"
			:class="['filter-opener items-opener', {
				hasInlineView: hasInlineView,
				disabled: isDisabled
			}]"
			@click="openFilter"
		>
			<span class="filter-name-truncate">
				<span>
					{{ displayName }}
				</span>
			</span>
			<div class="icon-wrapper">
				<svg class="svg-icon svg-15">
					<use xlink:href="#layout-icons-filter-opener" class="hover layout-icons-filter-opener" />
				</svg>
			</div>
			<p v-if="inlineValue" class="inline-text filter-name-truncate">
				{{ inlineValue }}
			</p>
		</div>
		<component
			v-if="inlineView"
			:is="inlineView"
			:options="options"
			:stateNamespace="stateNamespace"
			:evaluationContext="evaluationContext"
			v-model:isDisabled="isDisabled"
		/>
	</li>
</template>
<script>
/* eslint vue/no-unused-components: 0 */
/* eslint vue/no-v-model-argument: 0 */
// TODO review and fix
import '@/Bindings/ui-attribute.directive';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import FilterMixins from './../filter.mixins';
const BoolInlineView = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../inline-views/bool-inline-view');
const FavoritesInlineView = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../inline-views/favorites-inline-view');
const GeoPolygonInlineView = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../inline-views/geo-polygon-inline-view');

export default {
	name: 'filter-inline',
	mixins: [FilterMixins],
	props: {
		options: Object,
		evaluationContext: Object,
		stateNamespace: String
	},
	components: {
		BoolInlineView,
		FavoritesInlineView,
		GeoPolygonInlineView
	},
	computed: {
		inlineValue() {
			const value = this.$store.getters[this.stateNamespace + '/getFilterValue']({
				entity: this.entity,
				logicalName: this.logicalName
			});
			return (value) ? this.formInlineValue(value.data) : '';
		},
		isExpanded() {
			const options = this.$store.getters[this.stateNamespace + '/getInfoPanelOptions'];
			return (options) ? this.logicalName === helperMethods.eval(this.evaluationContext, options.logicalName) : false;
		}
	},
	data() {
		return {
			displayName: '',
			visible: true,
			hasInlineView: false,
			inlineView: null,
			isDisabled: false
		};
	},
	async created() {
		this.logicalName = helperMethods.eval(this.evaluationContext, this.options.logicalName);
		this.recalculacteLimits = !!helperMethods.eval(this.evaluationContext, this.options.recalculacteLimits);
		// todo be sure filter is used only for one entity
		this.entity = this.options.entities[0];
		this.$store.commit(this.stateNamespace + '/setFilterWait', {
			filterName: 'filters',
			entity: this.entity,
			logicalName: this.logicalName,
			value: true
		});
		this.inlineView = this.getInlineView();
		const selectedFilter = this.getSelectedFilter();
		if (selectedFilter) {
			this.entities = this.options.entities;
			this.$store.commit(this.stateNamespace + '/registerSelectedFilter', {
				entities: this.entities,
				filter: selectedFilter
			});
		}

		this.visible = (this.options.isVisible) ? await helperMethods.evalAsync(this.evaluationContext, this.options.isVisible) : true;
		await this.setPredefinedFilters();
		this.displayName = await helperMethods.evalAsync(this.evaluationContext, this.options.displayName);
		this.$store.commit(this.stateNamespace + '/setFilterReady', {
			filterName: 'filters',
			entity: this.entity,
			logicalName: this.logicalName,
			value: true
		});
	}
};
</script>
<style src="./filter-inline.less" scoped></style>
