<template>
	<div class="ms-panel popup-shaded notification-panel">
		<div class="panel-list">
			<div class="tabs">
				<div class="tab-title" :class="{ active: tab.active }" v-for="(tab, index) in tabsData" @click="loadTabData(tab)" :key="index">
					<p class="text">{{tab.title}}</p>
				</div>
				<div v-if="!isLoading && tabContent.length" class="clear-all-btn" v-localization="{key:'common.dictionary.clearAll'}" @click="clearAll"></div>
			</div>
			<div class="tab-content" v-perfectscroll="{ addMouseoverEvent: true, refreshScroll: false }">
				<template v-if="!isLoading && tabContent.length">
					<div v-for="(tabEntity, index) in tabContent" :key="tabEntity.id" class="notification-wrapper">
						<control class="striped"
								 @click.native.prevent="openEntity(tabEntity)"
								 :name="currentTab.messageTemplate.$type"
								 :contentProps.once="currentTab.messageTemplate"
								 :class="{ 'without-hyperlink': tabEntity.withoutHyperlink}"
								 :evaluationContext="getContext(tabEntity)" />
						<div class="delete-wrapper"
							 @click.prevent="deleteEntity(tabEntity, index)">
							<svg class="svg-icon">
								<use xlink:href='#layout-icons-dialog-close'></use>
							</svg>
						</div>
						<div class="loading-overlay" v-show="isNotificationOnDelete(tabEntity.id)"></div>
					</div>
				</template>

				<div class="no-results-message" v-show="!isLoading && !tabContent.length">
					<div v-localization="{ key: isPushNotificationsEnabled ? 'common.dictionary.noMatchesFound': 'notificationPanel.disabledNotificationMessage', args: [' ']}"></div>
				</div>
				<div class="loading-overlay" v-show="isLoading"></div>
				<div class="items-footer" v-show="loadMore">
					<div class="loading-overlay inline"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
/* eslint vue/no-mutating-props: 0 */
import PanelMixin from './panel.mixin';
import {
	recordLink, generateGuid, Search, Get, Delete, CreateBulk
} from '@acx-xms/data-functions/dist';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import { GetCrmSetting } from '@/Data/DataProviders/crmSyncDataProvider.js';
import * as userStorageDataProvider from '@/Data/DataProviders/userStorageDataProvider';

export default {
	name: 'ms-notifications-panel',
	mixins: [PanelMixin],
	data() {
		return {
			loadMore: false,
			deletingItemIds: [],
			// TODO: rework notification types, get them from EDGE
			notificationTypes: {
				newchatparticipant: '08433102-9bfd-425e-9db9-814846baf233',
				newchatparticipantentered: '5c0e7573-eafb-4e5b-9c6d-901dd05275fb',
				sharedsavedsearch: '8c7f1c12-824e-4804-8a37-910630bb77af',
				requestofaccess: '9007fd85-b3e7-4d90-aea2-ff00a05d3fd8',
				roomclosed: '21b9347e-76e2-45b6-bb56-f80be220a0d4',
				participantleft: 'aeb2ef7e-8b63-4fcb-a3b9-34fc0f59da47',
				participantremoved: 'fb36883f-ed98-468f-9209-0534626f27d3',
				accessrequestapproved: 'f34cbdf6-e957-4d12-8a68-5c9f74e64350',
				shareddocumentdownloaded: '712c7f79-4fd2-4d97-98a6-904edf9f615c',
				shareddocumentuploaded: '63ef34ff-cc6e-4a57-8826-d0cde07843fe',
				ndaaccepted: '970be9ff-1ca3-4dbe-b03d-59bbc75edfbb',
				newinquiry: 'c13f3232-4bef-4fd0-b0eb-60597643a426',
				licenseexpiring: 'b08df37b-a8b1-400d-ab44-dcb76fe99294',
				primarylistingset: '5d85a548-720e-49ad-8d56-7831ef428ab9',
				roomrequestaccess: '18d8a8dd-4cc5-4d30-acea-5189d6683183',
				roomreactivated: '481d6a94-707d-4cf8-91ac-58af076d44ad',
				madecoowner: '1d158b6f-0fef-4cc1-8e90-a0a914a140a2',
				removedcoowner: 'da60f503-5ee3-4679-a347-e902ea50aa16',
				newlistingadded: '2d091365-a01c-4568-af68-416c8b36da37'
			}
		};
	},
	props: {
		evaluationContext: Object,
		tabs: Array,
		entity: Object,
		notifications: Array,
		isPushNotificationsEnabled: Boolean,
		notificationsWithoutHyperLink: Array
	},
	methods: {
		async loadDataOnCreate() {
			if (this.tabsData.length) {
				await this.loadTabDataBase(this.tabsData[0], 15);
			}
		},
		isNotificationOnDelete(id) {
			return this.deletingItemIds.some(item => item === id);
		},

		async loadTabData(tab, onPageLoad, chunkSize = 999) {
			this.currentTab = tab;
			this.selectedEntity = null;

			let notifications = this.notifications.sort(function (a, b) {
				return new Date(b.createdon) - new Date(a.createdon);
			});

			const storedDeletingNotifications = await userStorageDataProvider.Get('deletingNotifications');
			if (storedDeletingNotifications && storedDeletingNotifications.length) {
				notifications = notifications.filter(notification => !storedDeletingNotifications.some(item => item === notification.id));
			}

			const notificationChunks = this.splitByChunk(notifications, chunkSize);
			let notificationsRelatedData = [];
			let notificationsWithoutHyperlink = [
				this.notificationTypes.sharedsavedsearch,
				this.notificationTypes.participantremoved,
				this.notificationTypes.licenseexpiring
			];

			const loadChunkData = async (chunkNumber = 0) => {
				if (notificationChunks.length && notificationChunks.length > chunkNumber) {
					const notificationChunk = notificationChunks[chunkNumber];

					if (chunkNumber !== 0) {
						this.loadMore = true;
					}

					if (this.notificationsWithoutHyperLink && this.notificationsWithoutHyperLink.length) {
						notificationsWithoutHyperlink = [...notificationsWithoutHyperlink, ...this.notificationsWithoutHyperLink];
					}

					const relatedData = await this.getRelatedData(notificationChunk);

					if (this.currentTab.key === tab.key) {
						notificationChunk.forEach(notification => {
							const relatedEntityRef = this.getRelatedEntityRef(notification);
							if (relatedEntityRef) {
								const relatedEntityData = relatedData.find(x => x.Id === relatedEntityRef.id);
								relatedEntityData && (notification.relatedEntityData = relatedEntityData);
							}

							if (notificationsWithoutHyperlink.includes(notification.data.notificationtype.id)) {
								notification.withoutHyperlink = true;
							} else {
								notification.withoutHyperlink = false;
							}
						});
					}

					notificationsRelatedData = [...notificationsRelatedData, ...notificationChunk];
					onPageLoad(notificationsRelatedData);
					await loadChunkData(chunkNumber + 1);
				} else {
					this.isLoading = this.loadMore = false;
				}
			};

			loadChunkData();
		},
		prepareTabData(tab) {
			let key = 0;

			return {
				key: key++,
				active: false,
				title: tab.title && this.evalContext.eval(tab.title),
				messageTemplate: tab.messageTemplate
			};
		},
		async deleteEntity(notification, index) {
			this.deletingItemIds.push(notification.id);
			const storedDeletingNotifications = await userStorageDataProvider.Get('deletingNotifications') || [];
			userStorageDataProvider.Set('deletingNotifications', [...storedDeletingNotifications, notification.id]);
			await Delete('notification', notification.id);
			this.tabsContent.Notifications.splice(index, 1);
			sc.events.emit('setNotifications', this.tabsContent.Notifications);
			this.deletingItemIds.splice(this.deletingItemIds.indexOf(notification.id), 1);
		},

		async clearAll() {
			const { systemuserid } = await GetUserInfo();

			let notificationsIds = await Search(['notification'], [
				sc.classes.get('offsetSize.filter', 9999).fillQuery(),
				sc.classes.get('termFacet.filter', {
					logicalName: 'recepientid.id',
					query: [systemuserid]
				}).fillQuery(),
				sc.classes.get('selectedFields.filter', []).fillQuery()
			]);
			notificationsIds = notificationsIds.Results.map(notif => notif.Id);

			const records = [];
			notificationsIds.forEach(id => {
				const record = {
					id: generateGuid(),
					method: 'DELETE',
					url: `notification/${id}/Delete`
				};
				records.push(record);
			});
			const arrayOfRecordArrays = [];
			const size = 500;
			for (let i = 0; i < records.length; i += size) {
				arrayOfRecordArrays.push(records.slice(i, i + size));
			}
			arrayOfRecordArrays.forEach(recordArray => CreateBulk(recordArray));
			this.tabsContent.Notifications = [];
			sc.events.emit('setNotifications', []);
			userStorageDataProvider.Set('deletingNotifications', notificationsIds);
		},

		async openEntity(notification) {
			let excludedNotifications = [
				this.notificationTypes.participantremoved
			];
			if (this.notificationsWithoutHyperLink && this.notificationsWithoutHyperLink.length) {
				excludedNotifications = [...excludedNotifications, ...this.notificationsWithoutHyperLink];
			}

			const notificationTypeId = notification.data.notificationtype.id;
			// Open inquiry in SF
			if (sc.utils.hasCRMsettings && notificationTypeId === this.notificationTypes.newinquiry) {
				const crmsettings = await GetCrmSetting();
				const edgeInquery = await Get(notification.data.userInfo.content.navigationRef.logicalname, notification.data.userInfo.content.navigationRef.id);
				const crmId = edgeInquery ? edgeInquery.Source.sfcrmid : null;

				if (crmsettings.Results.length && crmId) {
					const domainurl = crmsettings.Results[0].Source.domainurl;
					const url = `${domainurl}/${crmId}`;
					const win = window.open(url, '_blank');
					win.focus();
				}

				return;
			}

			const entityReference = notification.data.userInfo && notification.data.userInfo.content ? notification.data.userInfo.content.navigationRef : null;

			if (entityReference && entityReference.logicalname && entityReference.id && !excludedNotifications.includes(notificationTypeId)) {
				this.$router.push(recordLink(this.$route, {
					id: entityReference.id,
					logicalname: entityReference.logicalname
				}));
			}
		},
		async getRelatedData(notifications) {
			const contactIds = [];
			const dealRoomIds = [];
			const chatIds = [];

			notifications.forEach(notification => {
				const relatedEntityRef = this.getRelatedEntityRef(notification);

				if (relatedEntityRef) {
					switch (relatedEntityRef.logicalname) {
					case 'contact': {
						relatedEntityRef.id && contactIds.push(relatedEntityRef.id);
						break;
					}
					case 'chat': {
						relatedEntityRef.id && chatIds.push(relatedEntityRef.id);
						break;
					}
					case 'dealroom': {
						relatedEntityRef.id && dealRoomIds.push(relatedEntityRef.id);
						break;
					}
					}
				}
			});

			const buildFilters = (ids) => {
				const filters = [
					sc.classes.get('offsetFrom.filter', 0),
					sc.classes.get('offsetSize.filter', ids.length),
					sc.classes.get('search.idsFilter', { id: ids }).toFilter()
				];

				return filters;
			};

			const promises = [];
			let searchResults = [];
			const edgeResponseHandler = response => {
				searchResults = [...searchResults, ...response.Results];
			};

			if (chatIds.length) {
				const chatFilters = buildFilters(chatIds);

				chatFilters.push(sc.classes.get('search.relation', {
					entity: 'chatparticipant',
					alias: 'chatparticipants',
					output: 'item'
				}).toFilter());

				promises.push(Search(['chat'], chatFilters).then(edgeResponseHandler));
			}
			if (dealRoomIds.length) {
				promises.push(Search(['dealroom'], buildFilters(dealRoomIds)).then(edgeResponseHandler));
			}
			if (contactIds.length) {
				promises.push(Search(['contact'], buildFilters(contactIds)).then(edgeResponseHandler));
			}

			await Promise.all(promises);

			return searchResults;
		},
		getRelatedEntityRef(notification) {
			let path = 'data.userInfo.content';
			const notificationtypeId = notification.data.notificationtype.id;
			let logicalName;
			// notification types that used "chat" entity for display icons
			if ([this.notificationTypes.newchatparticipant, this.notificationTypes.newchatparticipantentered, this.notificationTypes.roomclosed, this.notificationTypes.participantleft,
				this.notificationTypes.participantremoved, this.notificationTypes.accessrequestapproved, this.notificationTypes.shareddocumentdownloaded, this.notificationTypes.shareddocumentuploaded,
				this.notificationTypes.shareddocumentdownloaded, this.notificationTypes.roomrequestaccess, this.notificationTypes.shareddocumentuploaded, this.notificationTypes.ndaaccepted,
				this.notificationTypes.roomreactivated, this.notificationTypes.madecoowner, this.notificationTypes.removedcoowner, this.notificationTypes.newlistingadded].indexOf(notificationtypeId) > -1) {
				const navigationRefLogicalName = sc.utils.findProperty(notification, 'data.userInfo.content.navigationRef.logicalname', true);
				if (navigationRefLogicalName === 'collaborationroom') {
					path += '.chatId';
					logicalName = 'chat';
				} else {
					path += '.navigationRef.id';
					logicalName = 'dealroom';
				}
			} else if ([this.notificationTypes.newinquiry].indexOf(notificationtypeId) > -1) {
				return null;
			} else {
				// notification types that used "contact" entity for display icon
				path += '.contact.id';
				logicalName = 'contact';
			}

			return {
				id: sc.utils.findProperty(notification, path, true),
				logicalname: logicalName
			};
		}
	}
};
</script>
<style src="./ms-panel-styles.less"></style>
