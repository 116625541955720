/* eslint prefer-promise-reject-errors: 0 */

import axios from 'axios';
import { GetToken, RefreshToken } from '@/Data/Auth/authentication-service';

const authAxios = axios.create();
authAxios.interceptors.request.use((config) => {
	const token = GetToken(sc.clusterKey());
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
});

authAxios.interceptors.response.use((response) => {
	return response;
}, function (error) {
	const originalRequest = error.config;
	if (error.response) {
		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;

			return RefreshToken().then(res => {
				if (res && res.status === 200) {
					originalRequest.headers.Authorization = 'Bearer ' + GetToken(sc.clusterKey());
					return axios(originalRequest);
				}
			});
		}

		if (error.response.status === 503) {
			return Promise.reject({
				message: error.response.data,
				status: error.response.status
			});
		}
	}
	return Promise.reject(error);
});
export default authAxios;
