export default {
	props: {
		pageSize: Number,
		total: Number,
		from: Number,
		activePages: Array,
		showPaging: {
			type: Boolean,
			default: true
		},
		showWrapperIfEmpty: {
			type: Boolean,
			default: false
		},
		maxLength: {
			type: Number,
			default: 5
		} // allowed 3 or 5
	},
	data() {
		return {
			lastPage: 1,
			elipsis: '...',
			showLoadMoreVisibility: 'visible'
			// maxLength: this.maxLengthProps
		};
	},
	computed: {
		label() {
			let result = '';
			if (!this.total) {
				result = '0 Results are shown';
			} else {
				let to = this.from + this.pageSize * this.activePages.length;
				to = to > this.total ? this.total : to;
				result = 'Results ' + (this.from + 1) + '-' + to + ' are shown. Total is ' + this.total + '.';
			}

			return result;
		},
		pages() {
			this.lastPage = Math.ceil(this.total / this.pageSize);
			const firstActivePage = this.activePages[0];
			const lastActivePage = this.activePages[this.activePages.length - 1];
			let middlePage = lastActivePage;
			const pages = [];

			if (this.lastPage <= this.maxLength) {
				for (let i = 1; i <= this.lastPage; i++) {
					pages.push({
						number: i,
						selected: firstActivePage <= i && lastActivePage >= i
					});
				}
			} else {
				if (middlePage < this.maxLength) {
					for (let i = 1; i <= this.maxLength; i++) {
						pages.push({
							number: i,
							selected: firstActivePage <= i && lastActivePage >= i
						});
					}
				} else {
					pages.push({
						number: 1,
						selected: firstActivePage <= 1 && lastActivePage >= 1
					});
					pages.push({
						number: this.elipsis,
						selected: lastActivePage >= this.maxLength && firstActivePage < this.lastPage - 4 && this.activePages.length > 2
					});
					if (this.lastPage - middlePage <= 3) {
						middlePage = this.lastPage - 3;
					}
					if (this.maxLength === 5) {
						pages.push({
							number: middlePage - 1,
							selected: firstActivePage <= middlePage - 1 && lastActivePage >= middlePage - 1
						});
					}
					pages.push({
						number: middlePage,
						selected: firstActivePage <= middlePage && lastActivePage >= middlePage
					});
					if (this.maxLength === 5) {
						if (middlePage < this.lastPage) {
							pages.push({
								number: middlePage + 1,
								selected: firstActivePage <= middlePage + 1 && lastActivePage >= middlePage + 1
							});
						}
					}
				}
				if (this.lastPage - middlePage > 3) {
					pages.push({
						number: this.elipsis,
						selected: false
					});
					pages.push({
						number: this.lastPage,
						selected: firstActivePage <= this.lastPage && lastActivePage >= this.lastPage
					});
				} else {
					for (let i = middlePage + 2; i <= this.lastPage; i++) {
						pages.push({
							number: i,
							selected: firstActivePage <= i && lastActivePage >= i
						});
					}
				}
			}

			this.showLoadMoreVisibility = !(lastActivePage === this.lastPage) && (pages.length > 1) ? 'visible' : 'hidden';
			return pages;
		}
	},
	created() {
		if (this.maxLength !== 3 && this.maxLength !== 5) {
			throw new Error('Pagination maxLength can be equal 3 or 5 only');
		}
	},
	methods: {
		isSelected(page) {
			return (this.activePages.indexOf(page) >= 0);
		},
		previous() {
			if (this.activePages[0] > 1) this.selectPage(this.activePages[0] - 1);
		},
		next() {
			const last = this.activePages[this.activePages.length - 1];
			if (last < this.lastPage) this.selectPage(last + 1);
		},
		loadMore() {
			this.selectPage(this.activePages[this.activePages.length - 1] + 1, true);
		},
		selectPage(number, push) {
			if (number === this.elipsis) return;
			let activePages = this.activePages.slice();
			if (push) {
				activePages.push(number);
			} else {
				activePages = [number];
			}
			this.$parent.$emit('.pageChanged', activePages);
		},
		rel(pages, page) {
			let prevIdx;
			let nextIdx;
			pages.forEach((p, idx) => {
				if (p.selected) {
					prevIdx = idx - 1;
					nextIdx = idx + 1;
				}
			});

			const isPrev = pages[prevIdx] && pages[prevIdx].number === page.number && !page.selected;
			const isNext = pages[nextIdx] && pages[nextIdx].number === page.number && !page.selected;
			if (isNext) {
				return 'next';
			}
			if (isPrev) {
				return 'prev';
			}
		},
		getPaginationUrl(page) {
			return page.number === 1 ? this.$route.path : `${this.$route.path}/page=${page.number}`;
		}
	}
};
