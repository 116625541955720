<template>
	<div :class="['step-counter', {disabled: disableCounter}]" ref="counter">
		<div
			v-for="step in preparedSteps"
			:key="step.index"
			:class="['step', {
				disabled: step.disabled,
				disabledFilled: step.disabledFilled,
				filled: step.filled,
				invalid: step.invalid,
				active: step.active,
				animated: step.animated
			}]"
		>
			<div class="state" @click="setActive(step.index)">
				<div v-if="step.filled || step.invalid || step.disabledFilled" class="state-icon" >
					<svg class="icon">
						<use v-if="step.filled || step.disabledFilled" xlink:href="#ui-icon-success" />
						<use v-if="step.invalid" xlink:href="#ui-icon-attention" />
					</svg>
				</div>
				<div v-else class="index">
					{{ step.index }}
				</div>
			</div>
			<div class="caption">
				<div class="caption-wrapper">
					<span>
						{{ step.caption }}
					</span>
				</div>
			</div>
			<div v-if="active === step.index" class="fake-pointer">
				<div class="icon"></div>
			</div>
		</div>
		<div class="pointer" ref="pointer">
			<div class="icon"></div>
		</div>
	</div>
</template>

<script>
/* eslint-disable no-return-assign */
export default {
	name: 'step-counter',
	props: {
		namespace: {
			type: String,
			required: true
		},
		disableCounter: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({ preparedSteps: [] }),
	computed: {
		count() {
			return this.$store.getters[`${this.namespace}/getSteps`];
		},
		active() {
			return this.$store.getters[`${this.namespace}/getActive`];
		},
		disabled() {
			return this.$store.getters[`${this.namespace}/getDisabled`];
		},
		disabledFilled() {
			return this.$store.getters[`${this.namespace}/getDisabledFilled`];
		},
		filled() {
			return this.$store.getters[`${this.namespace}/getFilled`];
		},
		invalid() {
			return this.$store.getters[`${this.namespace}/getInvalid`];
		},
		captions() {
			return this.$store.getters[`${this.namespace}/getCaptions`];
		}
	},
	watch: {
		active: {
			handler(value) {
				this.setActive(value);
			}
		},
		disabled: {
			handler(value) {
				this.setDisabled(value);
			},
			deep: true
		},
		disabledFilled: {
			handler(value) {
				this.setDisabledFilled(value);
			},
			deep: true
		},
		captions: {
			handler(value) {
				this.setCaptions(value);
			},
			deep: true
		},
		filled: {
			handler(value) {
				this.setFilled(value);
			},
			deep: true
		},
		invalid: {
			handler(value) {
				this.setInvalid(value);
			},
			deep: true
		}
	},
	methods: {
		setActive(value) {
			const target = this.preparedSteps.find(element => element.index === value);
			if (target.disabled || target.disabledFilled) return;

			this.preparedSteps.map(element => element.active = false);
			/* set on needed element */
			target.active = true;
			this.$emit('active', value);
			this.$emit('input', value);
			this.$nextTick(() => {
				/* prevent bug with pointer */
				this.movePointer();
			});
		},
		setDisabled(items) {
			/* remove disabled state from all elements */
			this.preparedSteps.map(element => element.disabled = false);
			items.forEach(item => {
				/* set on needed */
				this.preparedSteps.find(element => element.index === item).disabled = true;
			});
		},
		setDisabledFilled(items) {
			/* remove disabled state from all elements */
			this.preparedSteps.map(element => element.disabledFilled = false);
			items.forEach(item => {
				/* set on needed */
				this.preparedSteps.find(element => element.index === item).disabledFilled = true;
			});
		},
		setFilled(items) {
			/* remove filled state from all elements */
			this.preparedSteps.map(element => element.filled = false);
			items.forEach(item => {
				/* set on needed */
				this.preparedSteps.find(element => element.index === item).filled = true;
			});
		},
		setInvalid(items) {
			/* remove invalid state from all elements */
			this.preparedSteps.map(element => element.invalid = false);
			items.forEach(item => {
				/* set on needed */
				this.preparedSteps.find(element => element.index === item).invalid = true;
			});
		},
		setCaptions(items) {
			Object.keys(items).forEach(item => {
				/* set names by needed ID in array */
				this.preparedSteps.find(element => element.index === +item).caption = items[item];
				this.animate(item);
			});
		},
		movePointer() {
			const pointer = this.$refs.pointer;
			const target = this.$refs.counter.querySelector('.active');
			if (target) {
				pointer.style.opacity = '1';
				/* Animate pointer */
				pointer.style.transform = `translateX(${target.offsetLeft}px)`;
				setTimeout(() => {
					pointer.style.opacity = '0';
				}, 200);
			}
		},
		animate(number) {
			const target = this.preparedSteps.find(element => element.index === +number);
			target.animated = true;
			setTimeout(() => {
				target.animated = false;
			}, 250);
		}
	},
	created() {
		for (let i = 1; i <= this.count; i++) {
			this.preparedSteps.push({
				index: i,
				caption: '',
				disabled: false,
				disabledFilled: false,
				filled: false,
				invalid: false,
				active: false,
				animated: false
			});
		}
	},
	mounted() {
		/* initial methods */
		this.setDisabled(this.disabled);
		this.setDisabledFilled(this.disabledFilled);
		this.setCaptions(this.captions);
		this.setFilled(this.filled);
		this.setInvalid(this.invalid);
		this.setActive(this.active);
	}
};
</script>

<style lang='less'>
@import './step-counter.less';
</style>
