<template>
	<!--tooltip-->
	<div class="label-wrapper" v-if="visible" :class="cssClass">
		<a v-if="enable" class="control-link" v-bind:href="targetUrl" v-bind:target="hrefTarget" onclick="javascript: event.stopPropagation();">{{displayText}}</a>
		<span v-if="!enable" class="control-link">{{displayText}}</span>
	</div>
</template>
<script>
export default {
	name: 'control-link',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			visible: this.options.visible !== void 0 ? this.evaluationContext.eval(this.options.visible) : true,
			enable: this.options.enable ? this.evaluationContext.eval(this.options.enable) : true,
			cssClass: this.options.cssClass ? this.evaluationContext.eval(this.options.cssClass) : [],
			displayText: '',
			hrefTarget: '',
			targetUrl: ''
		};
	},
	created() {
		switch (this.options.protocol) {
		case 'tel':
		case 'http':
		case 'ftp':
		case 'mailto':
			this.hrefTarget = '_blank';
			break;
		default:
			this.hrefTarget = '_self';
		}

		const href = this.evaluationContext.eval(this.options.href);
		const protocol = this.options.protocol;

		if (href && protocol) {
			switch (protocol) {
			case 'mailto': {
				this.targetUrl = `${protocol}:${href}`;
				break;
			}
			case 'tel':
			case 'callto': {
				this.targetUrl = `${protocol}:${href.replace(/\s/g, '')}`;
				break;
			}
			case 'http': {
				const hasProtocol = href.indexOf('http://') !== -1 || href.indexOf('https://') !== -1;
				this.targetUrl = new URL(hasProtocol ? href : `${protocol}://${href}`).toString();
				break;
			}
			default: {
				this.targetUrl = href;
			}
			}
		}
		this.displayText = this.evaluationContext.eval(this.options.text) || href;
	}
};
</script>
<style scoped>
	.control-link {
		font-size: 12px;
		color: var(--color1);
		font-weight: 400;
		padding-right: 1px;
		display: inline-block;
		max-width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
</style>
