var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: [
        "email-campaign-nav-btn-root",
        {
          disabled: _vm.disabled,
          blue: _vm.color === "blue",
          back: _vm.icon === "back",
          action: _vm.icon === "action"
        }
      ],
      attrs: { disabled: _vm.disabled },
      on: { click: _vm.clickHandler }
    },
    [_vm._v("\n    " + _vm._s(_vm.text) + "\n    "), _vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }