const state = () => ({
	templateProcess: false,
	emailCampaignProcess: false,
	detailsProcess: false,
	delayCampaignSend: false,
	regenerateLater: false,
	entryPointStatus: false,
	errorPopupStatus: false,
	campaignWithArtifactStatus: false,
	errors: [],
	steps: [
		{
			index: 0,
			name: 'setup',
			errors: [],
			valid: false,
			systemuser: {},
			domains: [],
			mcUser: {
				accountType: 'MAIL_CHIMP',
				createdAt: 0,
				defaultMailListId: '',
				email: '',
				mcName: '',
				mcUserId: 0,
				plan: 'forever_free',
				updatedAt: 0,
				userId: ''
			},
			form: {
				campaign_name: {
					value: '',
					valid: false,
					title: 'Campaign Name *',
					type: 'text',
					limit: 100
				},
				subject: {
					value: '',
					valid: false,
					title: 'Subject *',
					type: 'text',
					limit: 150
				},
				preview_text: {
					value: '',
					valid: false,
					title: 'Preview Text',
					type: 'text',
					limit: 150
				},
				from_name: {
					value: '',
					valid: true,
					title: 'From name *',
					type: 'text',
					limit: 100
				},
				email: {
					value: '',
					valid: true,
					title: 'From email address *',
					type: 'email',
					splitted: true,
					limit: 50
				}
			},
			active: false,
			nav: {
				prev: true,
				next: true
			}
		},
		{
			index: 1,
			name: 'template',
			collectionType: '',
			isTempalteAvailable: true,
			errors: [],
			valid: false,
			templates: [],
			recordsList: [],
			artifact: null,
			limitError: false,
			active: false,
			nav: {
				prev: true,
				next: true
			}
		},
		{
			index: 2,
			name: 'collection',
			errors: [],
			valid: false,
			collectionForRegeneration: [],
			selection: {
				listing: [],
				availability: []
			},
			active: false,
			nav: {
				prev: true,
				next: true
			}
		},
		{
			index: 3,
			name: 'recepients',
			errors: [],
			valid: false,
			selection: [],
			active: false,
			recepientsForRegeneration: [],
			collectionLimitWarning: false,
			nav: {
				prev: true,
				next: true
			}
		},
		{
			index: 4,
			name: 'summary',
			errors: [],
			frameSrc: '',
			editTemplateStatusComputed: false,
			active: false,
			nav: {
				prev: true,
				next: true
			}
		}
	],
	campaign: {
		id: null,
		edgeId: null,
		isSaved: false,
		isEdited: false,
		isSavingInterrupted: false,
		status: '',
		name: '',
		xmsAccountId: '',
		mciAccountId: ''
	},
	isDetails: false,
	generatedArtifactsIds: [],
	forceExit: false,
	prefilled: false
});

export default state;
