var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "formcontrol-panel", class: _vm.css },
        _vm._l(_vm.options.control, function(item, index) {
          return _c(
            "div",
            { key: index, staticClass: "control-container" },
            [
              _c("control", {
                attrs: {
                  name: item.$type,
                  contentProps: item,
                  evaluationContext: _vm.evaluationContext
                }
              })
            ],
            1
          )
        })
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }