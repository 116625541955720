import Vue from 'vue';
import ScMap from '../Components/scMap/scMap';
import scMapUtils from '../Components/scMap/utils/mapUtils';
import { isNumber } from '@acx-xms/data-functions/dist';

Vue.directive('controlMap', (() => {
	let mapInstance;

	return {
		async inserted(el, bind, vnode) {
			const mapConfig = await sc.classes.get('settings.dataProvider').get('mapSearchResults-settings');
			const mapOptions = {
				placeholderElem: el.querySelector('.scMap-holder'),
				zoomOnDoubleclick: true,
				hideMapType: bind.value.hideMapType,
				hideStreetView: bind.value.hideStreetView,
				maxZoom: bind.value.maxZoom,
				zoom: bind.value.defaultZoom,
				gestureHandling: bind.value.gestureHandling,
				mapConfig
			};

			const map = await new ScMap(mapOptions).initMap();
			mapInstance = map;
			const pushPinIcons = scMapUtils.getPushPins(mapConfig.pushpins);
			const markerIcons = {
				default: {
					url: scMapUtils.buildSVG('single', scMapUtils.getColorByName(pushPinIcons.markerActive.cssVariableName), pushPinIcons.markerActive.imageWidth, pushPinIcons.markerActive.imageHeight),
					width: pushPinIcons.markerActive.imageWidth,
					height: pushPinIcons.markerActive.imageHeight
				},
				hover: {
					url: scMapUtils.buildSVG('single', scMapUtils.getColorByName(pushPinIcons.markerActiveHover.cssVariableName), pushPinIcons.markerHover.imageWidth, pushPinIcons.markerHover.imageHeight),
					width: pushPinIcons.markerActiveHover.imageWidth,
					height: pushPinIcons.markerActiveHover.imageHeight
				}
			};
			const lat = bind.value.lat;
			const lng = bind.value.lng;
			if (!isNumber(lat) || !isNumber(lng)) {
				return;
			}
			const marker = map.createMarkers({
				location: {
					lat,
					lng
				},
				icons: markerIcons,

				icon: 'default'
			});
			map.fitTo(marker.getLocation());
		},

		unbind() {
			mapInstance && mapInstance.dispose();
		}
	};
})());
