<template>
	<div class="control-map" v-bind:style="{ width: width || '100%', height: height || 'auto' }">
		<div v-if="title" class="column-title">{{ title }}</div>
		<div v-on:wheel.stop.prevent class="map-container" v-controlMap="{
			 lat: lat,
			 lng: long,
			 hideMapType: hideMapType,
			 hideStreetView: hideStreetView,
			 maxZoom: maxZoom,
			 defaultZoom: defaultZoom,
			 gestureHandling
			}">
			<div class="scMap">
				<div class="scMap-holder"></div>
			</div>
		</div>
	</div>
</template>

<script>
import './../../../Bindings/controlMap.directive';
import helperMethods from './../../ComponentSet/component-set-evaluation-context-helper.methods';

export default {
	name: 'control-map',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			lat: helperMethods.eval(this.evaluationContext, this.options.lat),
			long: helperMethods.eval(this.evaluationContext, this.options.lng),
			width: this.options.width,
			height: this.options.height,
			title: this.options.title ? helperMethods.eval(this.evaluationContext, this.options.title) : null,
			hideMapType: this.options.hideMapType,
			hideStreetView: this.options.hideStreetView,
			maxZoom: this.options.maxZoom,
			defaultZoom: this.options.defaultZoom,
			gestureHandling: this.options.gestureHandling ? helperMethods.eval(this.evaluationContext, this.options.gestureHandling) : null
		};
	},
	created() {
	}
};
</script>

<styles src="./control-map-styles.less" scoped />
