var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.enabled,
          expression: "enabled"
        },
        {
          name: "data-attr",
          rawName: "v-data-attr",
          value: _vm.options,
          expression: "options"
        }
      ],
      class: ["menu-item clickable", _vm.displayStyle],
      attrs: { clickBuble: "false" },
      on: { click: _vm.execute }
    },
    [
      _c("span", { staticClass: "icon-wrapper" }, [
        _c(
          "svg",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.icon,
                expression: "icon"
              }
            ],
            staticClass: "svg-icon svg-20"
          },
          [
            _c("use", {
              class: _vm.icon,
              attrs: { "xlink:href": "#action-icons-" + _vm.icon }
            }),
            _vm._v(" "),
            _c("use", {
              class: _vm.icon + "-border",
              attrs: { "xlink:href": "#action-icons-" + _vm.icon + "-border" }
            }),
            _vm._v(" "),
            _c("use", {
              class: _vm.icon + "-border",
              attrs: {
                "xlink:href": "#action-icons-" + _vm.icon + "-partially"
              }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "title" }, [
        _vm._v("\n\t\t" + _vm._s(_vm.text) + "\n\t")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }