import ImagePlaceholder from './ImagePlaceholder/image-placeholder';
import helperMethods from './../../ComponentSet/component-set-evaluation-context-helper.methods';

export default {
	methods: {
		identifyPixelWidth() {
			if (this.options.size === 'custom') {
				const prop = this.options.customWidth ? this.options.customWidth : this.options.customSize;
				return helperMethods.eval(this.localEvaluationContext || this.evaluationContext, prop);
			} else {
				return this.identifyPixelSize();
			}
		},

		identifyPixelHeight() {
			if (this.options.size === 'custom') {
				const prop = this.options.customHeight ? this.options.customHeight : this.options.customSize;
				return helperMethods.eval(this.localEvaluationContext || this.evaluationContext, prop);
			} else {
				return this.identifyPixelSize();
			}
		},

		identifyPixelSize() {
			switch (this.options.size) {
			case 'icon':
				return 20;
			case 'small':
				return 40;
			case 'medium':
				return 60;
			case 'large':
				return 90;
			default:
				throw new Error('Unknown image size: ' + this.options.size);
			}
		},

		svgClass() {
			switch (this.options.placeholderSize) {
			case 'icon':
				return 'svg-15';
			case 'small':
				return 'svg-20';
			case 'medium':
				return 'svg-30';
			case 'large':
				return 'svg-70';
			case 'custom':
				return 'svg-20';
			default:
				throw new Error('Unknown image size: ' + this.options.size);
			}
		},

		buildImageUrl(ref) {
			const imageSize = (this.options.imageSize) ? this.options.imageSize : 'thumbnail';

			sc.classes.get('entityConfiguration.dataProvider').fetchEntity(ref.logicalname).then((result) => {
				const controllerName = result.metadata.detail.controllerName;
				let url = '';
				if (ref && !ref.isEmpty) {
					url = sc.classes.get('edge.dataProvider').getImageUrl(controllerName, ref.id, imageSize);
					this.imgObj = this.defaultImgObj();
					this.imgObj.src = url;
				}
			});
		},

		processImageUrl(ref) {
			if (this.options.imageRef) {
				ref = ref || helperMethods.eval(this.localEvaluationContext || this.evaluationContext, this.options.imageRef);

				/* if has reference */
				if (ref && ref.id) {
					this.buildImageUrl(ref);
				}
			} else
			if (this.options.imageUrl) {
				this.imgObj = this.defaultImgObj();
				this.imgObj.src = helperMethods.eval(this.localEvaluationContext || this.evaluationContext, this.options.imageUrl);
			}
		},

		defaultImgObj() {
			const fit = this.options.fit ? (this.localEvaluationContext || this.evaluationContext).eval(this.options.fit) : 'cover'; // default
			const mandatoryToken = this.options.mandatoryToken ? (this.localEvaluationContext || this.evaluationContext).eval(this.options.mandatoryToken) : true;
			return {
				src: null,
				width: this.identifyPixelWidth(),
				// todo : it looks imageHeight should be reviewed
				height: this.options.imageHeight || this.identifyPixelHeight(),
				placeholder: this.placeholderClass,
				fit,
				mandatoryToken,
				alt: (this.localEvaluationContext || this.evaluationContext).eval(this.options.alt) || null
			};
		},

		// we need to refactor code above to use this method
		async formImageUrl(options, imageSize) {
			if (options.imageRef) {
				const ref = (this.localEvaluationContext || this.evaluationContext).eval(options.imageRef);
				if (ref && ref.id) {
					const meta = await sc.classes.get('entityConfiguration.dataProvider').fetchEntity(ref.logicalname);
					const controllerName = meta.metadata.detail.controllerName;
					if (ref && !ref.isEmpty) {
						if (!imageSize) {
							imageSize = options.imageSize ? options.imageSize : 'thumbnail';
						}
						return sc.classes.get('edge.dataProvider').getImageUrl(controllerName, ref.id, imageSize);
					}
				}
			} else if (options.imageUrl) {
				return (this.localEvaluationContext || this.evaluationContext).eval(options.imageUrl);
			}
			return null;
		}
	},

	components: { ImagePlaceholder }
};
