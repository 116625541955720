import RouteSearchResultsBaseMixins from './route-search-results.base.mixins';
import { getOrgSettings } from '@acx-xms/data-functions/dist';
import { GetPerConfig, SetPerConfig } from '@/Data/DataProviders/userStorageDataProvider';

export default {
	mixins: [RouteSearchResultsBaseMixins],
	props: { defaultRoute: Object },
	data() {
		return {
			isActive: true,
			metaTitle: '',
			meta: [],
			companyTitle: '',
			queryParamsChanged: false
		};
	},
	metaInfo() {
		return {
			title: this.metaTitle,
			meta: this.meta
		};
	},
	computed: {
		filtersReady() {
			return this.$store.getters[this.stateNamespace + '/filtersReady'](this.logicalName);
		}
	},
	watch: {
		companyTitle(newVal) {
			if (this.logicalName === 'listing') {
				this.metaTitle = `Real Estate Property Listings | ${newVal}`;
				this.meta = [
					{
						name: 'description',
						content: `${newVal} real estate MarketPlace. Search for property listing for sale or lease.`
					},
					{
						name: 'keywords',
						content: `${newVal}, listings, real estate, properties, sale, leasing`
					}
				];
			}
		}
	},
	async created() {
		const filtersFromStore = this.$store.state.initFilters;
		const filters = Object.keys(filtersFromStore).reduce((acc, index) => {
			if (index !== 'searchQuery') acc[index] = filtersFromStore[index];
			return acc;
		}, {});
		Object.keys(filters).forEach(key => {
			filters[key].forEach(filter => {
				this.$store.commit(`${this.stateNamespace}/addOrUpdateFilter`, {
					entity: key,
					logicalName: filter.data.logicalName || filter.requestLabel,
					filter
				});
			});
		});
		if (filtersFromStore.searchQuery) {
			this.searchQuery = filtersFromStore.searchQuery;
			this.sharedSearchCustomQuery = true;
		}
		if ('searchQuery' in filtersFromStore && !filtersFromStore.searchQuery) this.$store.commit(`${this.stateNamespace}/setSharedSearchSelectionExport`, true);
		this._setChangesToStore = true;
		// this we need in case activated was not called
		this.fork = sc.events.fork();
		this.secondFork = sc.events.fork();
		this.secondFork.on(this.combinedNamespace + '.searching', () => {
			this.searchAndRedirectToFirstPage();
		});
		// TODO: implement multiple refresh events for all actions and replace old refreshEvent and handle them.
		// TODO: this.options is empty in anonymous portal
		this.options && this.options.eventsToSubscribe && this.options.eventsToSubscribe.forEach(event => this.secondFork.on(event, this.searchAndRedirectToFirstPage));
		const orgset = await getOrgSettings();
		this.companyTitle = orgset.orgdisplayname || '';
	},
	async activated() {
		// TODO: when refactor fork - make more intuitive way of determining if fork was disposed
		if (!(this.fork && this.fork.listeners)) {
			this.fork = sc.events.fork();
		}
		const breadcrumbs = [{
			title: this.title,
			route: { path: this.$route.fullPath }
		}];
		sessionStorage.setItem('routes.breadcrumbs', JSON.stringify(breadcrumbs));
		this.$on('.pageChanged', this._onPageChangedHandler);

		this.fork.on(this.combinedNamespace + '.loading', (isLoading) => {
			this.isLoading = isLoading;
		});
		this.$on('.sortChanged', this.searchAndRedirectToFirstPage);
		this.$on('.removeSearchSubscriptions', this.unsubscribeSearchSubscribtions);
		this.$on('.changeSearchSubscriptions', this.initSearchSubscribtions);
		this.initSearchSubscribtions();

		this.$root.$on(['entity.changed', 'entity.created', 'entity.deleted'], this.onEntityCallback);

		// todo change using vue events
		this.fork.on(['entity.changed', 'entity.created', 'entity.deleted'], this.onEntityCallback);
		this.isActive = true;
		this.unwatch = this.$watch('$route.query', (newVal, oldVal) => {
			this.queryParamsChanged = true;
		});
		window.onpopstate = () => {
			if (this.queryParamsChanged) {
				const page = +this.$route.query.page || 1;
				this._onPageChangedHandler([page]);
				this.queryParamsChanged = false;
			}
		};

		let recordsToHide = await GetPerConfig('hiddenRecords') || [];
		recordsToHide = recordsToHide.filter(record => Math.abs(Date.now() - record.time) < 1 * 1000 * 60);
		await SetPerConfig('hiddenRecords', recordsToHide);

		/* force search on activate */
		if (this.options.searchOnEnter) this.doSearch({ initSearch: true });
	},

	deactivated() {
		this.unwatch && this.unwatch();
		window.onpopstate = null;

		if (this.fork) {
			this.fork.dispose();
		}
		// Sometimes on page init deactivated is called when parent is destroyed.
		this.stateNamespace && this.$store.commit(this.stateNamespace + '/cleanSelection');
		this.$root.$off(['entity.changed', 'entity.created', 'entity.deleted'], this.onEntityCallback);
		this.unsubscribeSearchSubscribtions();
		this.$off('.pageChanged', this._onPageChangedHandler);
		this.$off('.sortChanged', this.searchAndRedirectToFirstPage);
		this.isActive = false;
	},

	mounted() {
		if (this.autoSearch) {
			this.unwatchFiltersReady = this.$watch('filtersReady', (newVal, oldVal) => {
				if (newVal) {
					this.doSearch({ initSearch: true });
					this.unwatchFiltersReady();
				}
			}, { immediate: true });
		}
	},
	beforeDestroy() {
		if (this.secondFork) {
			this.secondFork.dispose();
		}
	},
	methods: {
		onEntityCallback(data) {
			if (this.targetEntity.includes(data.entity.logicalname)) {
				this.searchAndRedirectToFirstPage(true);
			}
		},
		initSearchSubscribtions() {
			// TODO: when refactor fork - make more intuitive way of determining if fork was disposed
			// we need this when we change folder view (i.e. from shared to my folders) and we are on different search results so activated hook wasn't triggered
			if (!(this.fork && this.fork.listeners)) {
				this.fork = sc.events.fork();
			}
			this.fork.on(this.combinedNamespace + '.getSearchResults', (callback) => {
				callback(this.results);
			});
			// todo - replace with vue event bus
			// todo remove when migrate edit dialog to vue
			this.$root.$on(this.combinedNamespace + '.searching', () => {
				this.searchAndRedirectToFirstPage();
			});

			if (this.options && this.options.filterPanel) {
				this.$root.$on(this.stateNamespace + 'search', (...params) => {
					this.searchAndRedirectToFirstPage(...params);
				});
			}
		},
		unsubscribeSearchSubscribtions() {
			this.$root.$off(this.stateNamespace + 'search');
			this.$root.$off(this.combinedNamespace + '.searching');
		}
	}
};
