var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "data-attr",
          rawName: "v-data-attr",
          value: { options: _vm.$parent.options, stateNamespace: "input" },
          expression: "{ options: $parent.options, stateNamespace: 'input' }"
        }
      ],
      class: [
        "search-field-wrapper inline-block",
        {
          focused: _vm.searchFocused
        }
      ]
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchQuery,
            expression: "searchQuery"
          },
          {
            name: "localization",
            rawName: "v-localization",
            value: {
              key: _vm.placeholderLocalizationKey,
              attrs: ["placeholder"]
            },
            expression:
              "{ key: placeholderLocalizationKey, attrs: ['placeholder'] }"
          }
        ],
        staticClass: "search-box",
        attrs: { maxlength: _vm.maxlength, type: "text" },
        domProps: { value: _vm.searchQuery },
        on: {
          keyup: function($event) {
            if (
              !("button" in $event) &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.search($event)
          },
          focus: function($event) {
            _vm.searchFocused = true
          },
          blur: function($event) {
            _vm.searchFocused = false
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.searchQuery = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "search-icon-wrapper" }, [
        _c("svg", { staticClass: "svg-icon", on: { click: _vm.search } }, [
          _c("use", {
            staticClass: "layout-icons-ms-search",
            attrs: { "xlink:href": "#layout-icons-ms-search" }
          })
        ]),
        _vm._v(" "),
        _c(
          "svg",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.searchQuery,
                expression: "searchQuery"
              }
            ],
            staticClass: "svg-icon svg-20 clear-button",
            on: { click: _vm.clearSearchBox }
          },
          [
            _c("use", {
              staticClass: "layout-icons-textbox-delete",
              attrs: { "xlink:href": "#layout-icons-textbox-delete" }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }