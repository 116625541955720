var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-accessbylink-wrapper" },
    [
      !_vm.noAccess
        ? [
            _vm.userName && !_vm.error
              ? _c(
                  "div",
                  {
                    staticClass: "layout-marketspace",
                    attrs: { id: "page-wrapper" }
                  },
                  [
                    _c("div", { staticClass: "marketspace-layout-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "marketspace-anon-logo-wrapper" },
                        [
                          _c(
                            "div",
                            { staticClass: "marketspace-anon-logo" },
                            [
                              !_vm.anonymousLogoUrl
                                ? _c("svg", { staticClass: "svg-icon" }, [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href": "#tenant-logo-anon-logo"
                                      }
                                    })
                                  ])
                                : _c("image-wrapper", {
                                    attrs: {
                                      url: _vm.anonymousLogoUrl,
                                      width: "100%",
                                      height: "80px",
                                      fit: "cover"
                                    }
                                  })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "component-header" },
                            [
                              _c("MenuConversation", {
                                attrs: {
                                  options: { enabled: true, hide: true }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                { on: { click: _vm.redirectToSignIn } },
                                [_vm._v("Sign In")]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "marketspace-content-wrapper" },
                        [
                          _c(
                            "div",
                            { staticClass: "main-content-area adaptive" },
                            [
                              _vm.ready
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "marketspace-components-area adaptive"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "component" },
                                        [
                                          _c("router-view", {
                                            key: _vm.$route.path,
                                            attrs: { accessByLink: false }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dialogmanager-footer" },
                      [
                        _c("old-dialog-manager"),
                        _vm._v(" "),
                        _c("dialog-manager")
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("toast-pop-up"),
                    _vm._v(" "),
                    _c("absolute-menu-popup"),
                    _vm._v(" "),
                    _c("absolute-template-popup")
                  ],
                  1
                )
              : !_vm.userName
              ? _c("div", { staticClass: "initialization-wrapper" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isLoading,
                          expression: "!isLoading"
                        }
                      ],
                      staticClass: "dialog"
                    },
                    [
                      _c("logo"),
                      _vm._v(" "),
                      !_vm.error
                        ? _c("accessbylink-login-form", {
                            on: { input: _vm.onUserNameSubmitted }
                          })
                        : _c("confirm-dialog", {
                            attrs: { options: _vm.confirmDlgOptions }
                          })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._m(0)
                ])
              : _vm._e()
          ]
        : _c(
            "div",
            { staticClass: "no-access" },
            [_c("details-no-access", { attrs: { requestedAccess: null } })],
            1
          ),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading"
          }
        ],
        staticClass: "loading-overlay large"
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "credits" }, [
      _c("span", [
        _vm._v("Powered by "),
        _c("a", { attrs: { target: "_blank", href: "https://ascendix.com" } }, [
          _vm._v("Ascendix Technologies")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }