import { isNullOrUndefined, isArray } from '@acx-xms/data-functions/dist';

export default {
	parameters(options) {
		return [
			this.eval(options.value)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.value)
		];
	},
	evaluate(array) {
		if (isNullOrUndefined(array)) {
			return [];
		}
		if (isArray(array)) {
			return array;
		}
		return [array];
	}
};
