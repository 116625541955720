<template>
	<div
		v-if="severalSortFields"
		v-data-attr="{ options: $parent.options, stateNamespace: 'sort' }"
		class="sortby-filter-container inline-block"
	>
		<div class="sort-panel">
			<svg
				class="svg-icon toggle-sortpanel"
				ref="sortPanel"
				@click.prevent.stop="openSortPanel"
			>
				<use xlink:href="#layout-icons-ms-sort" class="layout-icons-ms-sort" />
			</svg>
		</div>
	</div>
</template>
<script>
import '@/Bindings/ui-attribute.directive';
import SortPanelMixins from './sortpanel.mixins';
import { Get, Set } from '@/Data/DataProviders/userStorageDataProvider';

export default {
	name: 'sortpanel',
	props: {
		fields: Object,
		stateNamespace: String,
		entityName: String,
		scrollNamespace: String,
		namespace: String,
		internalNamespace: String,
		saveViewState: Boolean,
		defaultSelectedView: Object,
		item: Object
	},
	mixins: [SortPanelMixins],
	data() {
		return {
			selectedView: this.defaultSelectedView || this.item,
			opened: false,
			filterFields: [],
			defaultFields: void 0,
			isMultiple: false,
			severalSortFields: false
		};
	},
	computed: {
		combinedNamespace() {
			return this.scrollNamespace || `${this.namespace}.${this.internalNamespace}`;
		},
		sortingStateKey() {
			const key = this.selectedView && this.selectedView.namespace;
			return `sorting_state_${key}`;
		}
	},
	methods: {
		async sort(field, skipSearch) {
			if (this.predefinedFields.length) {
				field = Array.isArray(field) ? field : [field];
				field.unshift(...this.predefinedFields);
			}
			const fieldToUpdate = field.length > 1 ? field.filter(f => f.visible) : field;
			this.updateSelected(fieldToUpdate);
			const filter = this.getOrderByFilter(field, this.isMultiple, !!this.predefinedFields.length);
			this.$store.commit(`${this.stateNamespace}/addOrUpdateOrder`, {
				entity: this.entityName,
				logicalName: 'orderBy.filter',
				filter
			});

			if (!skipSearch) this.$parent.$emit('.sortChanged');

			if (this.saveViewState) await Set(this.sortingStateKey, field);
		},
		openSortPanel(e) {
			this.$root.$emit('templatePopup.open', this.$el, 'sortPanel', {
				name: 'sortpanel-dropdown',
				params: {
					filterFields: this.filterFields,
					onSelect: this.sort,
					offsetLeft: 0,
					offsetTop: 0
				},
				wrapperClass: 'dropdown-wrapper'
			}, 'mousedown');
			this.$root.$once(`${this.combinedNamespace}.scrolled`, () => {
				this.$root.$emit('templatePopup.close.sortPanel');
			});
		}
	},
	async created() {
		// No need to separate orderby filters because they are managed in one place
		this.$store.commit(`${this.stateNamespace}/setFilterWait`, {
			filterName: 'order',
			logicalName: 'order',
			entity: this.entityName,
			value: true
		});
		const sortingState = await Get(this.sortingStateKey);

		this.updateSelected = (fields) => {
			if (!Array.isArray(fields)) {
				fields = [fields];
			}

			if (this.isMultiple) {
				fields.forEach((item) => {
					item.isSelected = !item.isSelected;
				});
			} else {
				const fn = (array, newValue) => {
					array.forEach((item) => {
						item.isSelected = newValue;
					});
				};

				fn(this.filterFields, false);
				fn(fields, true);
			}
		};

		this.severalSortFields = this.fields.field && this.fields.field.length > 1;
		this.isMultiple = this.fields.allowMultiple;

		this.filterFields = this.fields && this.fields.field && this.evalOrderByField(this.fields.field);
		this.defaultFields = this.getDefaultOrderByField(this.filterFields);
		if (sortingState) {
			this.filterFields.forEach(item => item.isSelected === false);
			const sortIndex = this.filterFields.findIndex(item => item.displayName === sortingState.displayName);
			this.filterFields[sortIndex] = sortingState;
			this.defaultFields = sortingState;
		}

		this.predefinedFields = this.evalOrderByField(this.fields.field.filter(f => !f.visible));

		if (this.defaultFields) await this.sort(this.defaultFields, true);

		this.$store.commit(`${this.stateNamespace}/setFilterReady`, {
			filterName: 'order',
			logicalName: 'order',
			entity: this.entityName,
			value: true
		});
	}
};
</script>
<style src="./sortpanel.less" scoped></style>
