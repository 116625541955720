import { showToastOrInfoDialog, Search } from '@acx-xms/data-functions/dist';

// TODO: may be use action.entity.delete instead
export default {
	enabled(options, context, [selection]) {
		return $.Deferred(function (def) {
			def.resolve(sc.user && sc.user.hasPrivilege(selection.Type || selection.logicalname, 'Delete'));
		}).promise();
	},
	async execute(options, context, [selection]) {
		const localizations = sc.classes.get('localization.dataProvider');
		const dataProvider = sc.classes.get('edge.dataProvider');
		let messageKey = 'dialogues.savedSearch.delete';
		if (selection && selection.Source && selection.Source.ReadAccessList && selection.Source.ReadAccessList.Shared && selection.Source.ReadAccessList.Shared.length) {
			messageKey = 'dialogues.savedSearch.deleteShared';
		}
		sc.events.emit('vue.dialog.open', {
			icon: 'action-icons-search-save',
			title: await localizations.getLabelForCurrentLanguage('common.dictionary.confirmDlgCaption'),
			component: 'confirm.dialog',
			maximizedWidth: '450px',
			maximizedHeight: 'auto',
			evaluationContext: context,
			onSubmit: async () => {
				const deleteRelatedEntities = options.deleteRelatedEntities; let relatedRecords = [];
				if (deleteRelatedEntities) {
					relatedRecords = await Search(deleteRelatedEntities.relatedEntityName, [
						sc.classes.get('offsetSize.filter', 10000),
						sc.classes.get('termFacet.filter', {
							logicalName: deleteRelatedEntities.relatedEntityFilterLogicalName,
							query: [context.entity.id]
						}).fillQuery(),
						sc.classes.get('selectedFields.filter', [
							{ logicalname: 'ownerid.id' },
							{ logicalname: 'recordstate.id' }
						]).fillQuery()
					]);
					relatedRecords = relatedRecords.Results;
				}
				if (relatedRecords.length) {
					const promiseArr = [];
					for (const record of relatedRecords) {
						promiseArr.push(dataProvider.deleteEntity(record.Type, record.Id));
					}
					await Promise.all(promiseArr);
				}
				dataProvider.deleteEntity(selection.Type || selection.logicalname, selection.Id || selection.id)
					.then(async () => await showToastOrInfoDialog({
						toastMessageKey: context.eval(options.toastMessageKey),
						informationDialogTextKey: context.eval(options.informationDialogTextKey)
					}));
			},
			message: await localizations.getLabelForCurrentLanguage(messageKey, [context.eval(options.entityTitleName), selection.Name || selection.name]),
			okLabel: await localizations.getLabelForCurrentLanguage('common.dictionary.buttons.delete')
		});
	}
};
