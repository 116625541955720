import ValidatorBase from './base';
export default class extends ValidatorBase {
	constructor(...params) {
		super(...params);
		const pattern = this.context.eval(this.options.pattern);
		this.regexp = new RegExp(pattern);
	}

	async validate(entityData, context) {
		const fldData = entityData[this.field];
		return {
			field: await this.customFieldName() || this.field,
			result: (fldData) ? this.regexp.test(fldData) : true,
			message: this.message ? await context.evalAsync(this.message) : ''
		};
	}
}
