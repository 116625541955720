import Vue from 'vue';
// this directive is necessary to show last row of flex box correctly
Vue.directive('flex-row', (() => {
	const insertEmpty = function (element) {
		const empty1 = document.createElement('div');
		empty1.className = 'item';
		element.appendChild(empty1);

		const empty2 = empty1.cloneNode(true);
		element.appendChild(empty2);

		const empty3 = empty1.cloneNode(true);
		element.appendChild(empty3);

		const empty4 = empty1.cloneNode(true);
		element.appendChild(empty4);

		const empty5 = empty1.cloneNode(true);
		element.appendChild(empty5);
	};
	return {
		inserted: function (element, binding) {
			insertEmpty(element);
		}

	};
})());
