import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext'; import router from '@/router';
import store from '@/States/ms-state';
import { isActive } from './Methods/action.methods';

export default {
	async enabled(options, evaluationContext, selection) {
		if (options.enable) {
			const context = createEvaluationContext();
			return await context.eval(options.enable);
		} else return selection.length > 0 && await isActive(evaluationContext, selection);
	},
	execute(options, context, selection, fullSelection) {
		const stateSelection = store.getters['mainSearch.listings/getSelection'];
		let selectItems;
		if (stateSelection && stateSelection.listing && !!Object.keys(stateSelection.listing).length) {
			selectItems = { ...stateSelection };
		} else if (selection.some(i => i && i.Type === 'listing')) {
			selectItems = {
				listing: [...selection],
				availability: []
			};
		} else if ((selection.some(i => i && i.logicalname === 'staticlist')) || (selection.some(i => i && i.Type === 'staticlist'))) {
			selectItems = !selection.some(i => i.Source) ? [context.datasource] : [...selection];
		}

		router.push({
			name: 'wizard',
			params: {
				layout: router.currentRoute.params.layout,
				set: 'email-campaign',
				selection: selectItems,
				entryPoint: router.currentRoute.fullPath,
				refreshEvents: options.refreshEvents
			}
		});
		options.resetFilters && sc.events.emit('routeUpdated');
	}
};
