<template>
	<div class="license-info" v-if="hasTrialLicense" :class="css">{{text}}</div>
</template>
<script>
import { GetValidLicenses } from '@/Data/Auth/authentication-service';
export default {
	name: 'license-info',
	data() {
		return {
			hasTrialLicense: false,
			text: '',
			css: ''

		};
	},
	async created() {
		const validLicenses = await GetValidLicenses();
		const trialLicenses = validLicenses.Results.filter(item => item.Source.istrial);
		this.hasTrialLicense = validLicenses.Results.every(i => i.Source.istrial);
		if (!this.hasTrialLicense || !trialLicenses.length) {
			return;
		}

		const item = trialLicenses.pop();
		const diff = Math.ceil((new Date(item.Source.validto) - new Date(Date.now())) / (1000 * 60 * 60 * 24));

		this.css = diff >= 15 ? 'green' : 'red';
		this.text = `${diff} ${diff > 1 ? 'days' : 'day'} remaining in trial`;
	},
	methods: {
		executeCallback(action) {
			this.executeAction(action, this.context, []);
		}
	}
};
</script>
<style src="./license-info.less" scoped></style>
