<template>
    <div class="error-wrapper">
        <div class="message-wrapper">
            <div class="icon">
                <i class="landing-icon-no-config-icon"></i>
            </div>
            <div class="error">{{errorMessage || 'Something went wrong. Please contact your Administrator.'}}</div>
        </div>
        <div class="footer-wrapper">
            <slot></slot>
        </div>

    </div>
</template>
<script>
export default {
	name: 'base-error-wrapper',
	props: { errorMessage: String }
};
</script>
<style src="./error-wrapper.less" scoped></style>
