<template>
    <button
        :disabled='disabled'
        :class='[
            "email-campaign-nav-btn-root",
            { 
                disabled: disabled, 
                blue: color === "blue", 
                back: icon === "back",
                action: icon === "action"
            },
        ]'
        @click='clickHandler'
    >
        {{ text }}
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'email-campaign-nav-btn',
    props: {
        text: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: ''
        }
    },
    methods: {
        clickHandler() {
            this.$emit('click');
        },
    },
};
</script>

<style scoped lang='less'>
@import './navBtn.less';
</style>
