import BaseModule from './baseModule';

export default class extends BaseModule {
	install() {
		// TODO: remove after getting rid of JQuery
		$(window).ajaxError((ev, request, settings) => {
			if (settings.url.toLowerCase().indexOf('/api/logger/') !== -1) {
				// ignore all errors from logger to avoid recursion
				return;
			}

			if (request.status >= 200 && request.status < 300) {
				return;
			}

			let info = '';
			let logFunction = console.error;

			if (request.status === 0) {
				info += '<AJAX operation has been interrupted>';
				logFunction = console.warn;
			} else if (request.status) {
				info += '<' + request.status + '>';
			} else {
				info += '<No Server Connection>';
			}
			info += '[' + settings.type + ']' + settings.url;
			logFunction(info);
		});
		return Promise.resolve();
	}
}
