/* eslint no-async-promise-executor: 0 */
/* eslint prefer-promise-reject-errors: 0 */
import {
	isActive, checkPrivilege, mapParametersObject
} from './Methods/action.methods';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import store from '@/States/ms-state';

export default {
	async enabled(options, evaluationContext, selection) {
		const active = await isActive(evaluationContext, selection);

		if (!active) {
			return false;
		}

		const promise = new Promise(async (resolve, reject) => {
			if (options.selection && (!selection || selection.length === 0)) {
				reject('Invalid selection');
			}
			this.subtype = evaluationContext.eval(options.subtype);
			const sel = evaluationContext.eval(options.selection);
			const tmplPromise = sc.classes.get('entityConfiguration.dataProvider').getTemplate('document', 'edit', this.subtype);
			const privilegePromise = checkPrivilege('document', 'Create');
			const promiseArray = [tmplPromise, privilegePromise];
			const isDealRoom = sel && sel.Type === 'dealroom';
			const userInfo = await GetUserInfo();
			const isGuest = userInfo.CRMRoles.some(role => role.Name === 'Guest');
			if (isDealRoom && isGuest) {
				promiseArray.push(store.getters['сonversationMenuInit'].promise);
			}

			Promise.all(promiseArray)
				.then(([tmpl, isAllowed]) => {
					this.tmpl = tmpl;
					let hasSignedNDA = true;
					if (isDealRoom && isGuest) {
						const currentUserParticipant = (store.getters.getUserChatParticipants || []).find(cp => sel.Id === cp.dealroomid && cp.systemuserid === userInfo.systemuserid);
						hasSignedNDA = sel.Source.ndaid
							? currentUserParticipant && currentUserParticipant.ndasignindate
							: true;
					}
					resolve(tmpl !== void 0 && isAllowed && hasSignedNDA);
				})
				.catch(e => reject(e));
		});

		return promise;
	},

	async execute(options, context, selection) {
		// TODO: review xsd and xml: lots of paramaters are unused and misleading
		const parameters = mapParametersObject(options.parameter, context);
		const lookupPredefinedFields = options.stateKeyForPredefined && store.state[options.stateKeyForPredefined].lookupPredefinedFields;
		const params = Object.assign({
			entity: sc.classes.get('entityReference', { logicalname: 'document' }),
			template: this.tmpl,
			parentRecord: sc.classes.get('entityReference', selection && selection.length ? selection[0] : context.entity),
			lookupPredefinedFields

		}, parameters);

		sc.events.emit('vue.dialog.open', {
			title: await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('CreateDocument.dialogTitle'),
			component: 'create-shareddocument-editor',
			maximizedWidth: '80%',
			maximizedHeight: '100%',
			documentRelatedRecordsTemplate: options.documentRelatedRecordsTemplate,
			params,
			toastMessageKey: context.eval(options.toastMessageKey),
			informationDialogTextKey: context.eval(options.informationDialogTextKey)
		});
	}
};
