var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items
    ? _c(
        "div",
        { staticClass: "control-container" },
        _vm._l(_vm.items, function(item) {
          return _c("control", {
            key: item,
            attrs: {
              name: _vm.options.control.$type,
              contentProps: _vm.options.control,
              evaluationContext: _vm.createContext(item)
            }
          })
        })
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }