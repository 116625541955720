<template>
	<div id="page-wrapper" class="theme-override_modern-plugins layout-marketspace layout-blank">
		<component :is="componentName"></component>
		<div class="dialogmanager-footer">
			<old-dialog-manager></old-dialog-manager>
			<dialog-manager></dialog-manager>
		</div>
		<absolute-menu-popup></absolute-menu-popup>
		<absolute-template-popup></absolute-template-popup>
	</div>
</template>
<script>
import DialogManager from '@/Components/Dialog/dialog-manager';
import OldDialogManager from '@/Components/Dialog/old-dialog-manager';
import MarketspaceHeaderMenu from '@/Components/Menu/menu-marketspace-header/menu-marketspace-header.vue';
import AbsoluteMenuPopup from '@/Components/Menu/absolute-menu-popup';
import AbsoluteTemplatePopup from '@/Components/Popup/absolute-template-popup';
import ManageImages from '@/Components/ManageImagesDialog/manage-images';
import Crmsync from '@/Components/CrmSync/crmsync';
import Sync from '@/Components/Sync/sync';
import * as tracking from '@/Data/trackingModule';

export default {
	name: 'layout-marketspace-host',
	components: {
		DialogManager,
		OldDialogManager,
		MarketspaceHeaderMenu,
		AbsoluteMenuPopup,
		AbsoluteTemplatePopup,
		ManageImages,
		Crmsync,
		Sync
	},
	props: { name: String },
	computed: {
		componentName() {
			return this.$route.params.config || this.name;
		}
	},

	async created() {
		await tracking.Init();
	}
};
</script>
