<template>
	<div @click="executeMenuAction" class="icon-action-wrapper">
		<svg class="svg-icon svg-20" :class="cssClass" :style="iconStyleObject">
			<use :xlink:href="'#'+name"></use>
		</svg>
		<i class="loading-overlay" v-show="isLoading"></i>
	</div>
</template>
<script>
import helperMethods from './../../../ComponentSet/component-set-evaluation-context-helper.methods';
import ActionResolver from '@/Actions/actionResolver';
export default {
	name: 'control-svg-icon-action',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			customSize: 1,
			isLoading: false
		};
	},
	created() {
		this.fork = sc.events.fork();
		this.actionResolver = ActionResolver();
		const width = this.options.customSize || this.options.customWidth;
		const height = this.options.customSize || this.options.customHeight;
		const widthUom = this.options.widthUom || 'px';
		const heightUom = this.options.heightUom || 'px';

		this.iconStyleObject = {
			...(width && { width: width + widthUom }),
			...(height && { height: height + heightUom })
		};

		// todo: we need to implement promises here
		if (this.options.action.loadingEvent) {
			const eventName = this.evaluationContext.eval(this.options.action.loadingEvent);
			this.fork.on(eventName, (status) => {
				this.isLoading = status;
			});
		}
	},
	computed: {
		cssClass() {
			return (this.options.cssClass) ? helperMethods.eval(this.evaluationContext, this.options.cssClass) : '';
		},
		name() {
			return (this.options.icon) ? helperMethods.eval(this.evaluationContext, this.options.icon) : '';
		}
	},
	methods: {
		async executeMenuAction() {
			// to prevent multiple execution
			if (this.isLoading) return;
			const action = this.actionResolver.resolve(this.options.action.$type);
			if (!action) return;
			let preenabled = true;
			if (this.options.action.enable) {
				preenabled = this.evaluationContext.eval(this.options.action.enable);
			}
			if (preenabled) {
				const action = this.actionResolver.resolve(this.options.action.$type);
				if (action) {
					action.execute(this.options.action, this.evaluationContext, [this.evaluationContext.entity]);
				}
			}
		}
	},
	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
		}
	}
};
</script>
<style src="./control-svg-icon-action.less" scoped></style>
