<template>
    <div class="marketspace-knowledge-base-menu" @click="onClick" title="Help Center">
        <svg class='svg-icon svg-20'>
            <use xlink:href="#action-icons-ms-knowledge-base" class="icon"></use>
        </svg>
    </div>
</template>
<script>
export default {
	name: 'menu-knowledge-base',
	methods: {
		onClick() {
			window.open('https://help.ascendix.com/marketspace-help-center/', '_blank');
		}
	}
};
</script>
<style src="./menu-knowledge-base.less" scoped></style>
