const mutations = {
	UPDATE_ACTIVE_STEP(state, data) {
		state.steps.forEach((i, index) => data - 1 === index ? i.active = true : i.active = false);
	},
	UPDATE_STEP_NAVIGATION(state, data) {
		state.steps.find(i => i.active).nav = { ...data };
	},
	UPDATE_FORM_FIELD(state, data) {
		const fields = state.steps[0].form;
		fields[data.name].value = data.value;
		// TODO: Rework temporary validation
		fields[data.name].valid = data.value.length > 2;
		state.steps.find(i => i.index === data.step - 1).valid = Object.values(fields).every(i => i.valid);
	},
	UPDATE_FRAME_SRC(state, data) {
		state.steps.find(i => i.index === 4).frameSrc = data;
	},
	UPDATE_STEP_VALIDATION_STATUS(state, data) {
		state.steps.find(i => i.index === Number(data.index) - 1).valid = data.status;
	},
	UPDATE_MC_USER(state, data) {
		/* fixed losed reactivity */
		Object.keys(data).forEach(key => { state.steps[0].mcUser[key] = data[key]; });
	},
	UPDATE_ARTIFACT(state, data) {
		state.steps.find(i => i.index === 1).artifact = data;
	},
	UPDATE_GENERATED_ARTIFACTS_IDS(state, data) {
		if (state.generatedArtifactsIds.indexOf(data) === -1) {
			state.generatedArtifactsIds.push(data);
		}
	},
	UPDATE_CAMPAIGN_WITH_ARTIFACT_STATUS(state, data) {
		state.campaignWithArtifactStatus = data;
	},
	UPDATE_CAMPAIGN(state, data) {
		state.campaign.id = data;
	},
	UPDATE_SYSTEM_USER(state, data) {
		Object.assign(state.steps[0].systemuser, data);
	},
	PREPARE_LISTING_SELECTION(state, data) {
		state.steps[2].selection = data;
	},
	PREPARE_AVAILABILITY_SELECTION(state, data) {
		state.steps[2].selection = {
			...state.steps[2].selection,
			availability: data
		};
	},
	PREPARE_ML_SELECTION(state, data) {
		state.steps[3].selection = data.staticlist
			? data.staticlist
			: data.map((i) => {
				return {
					...i,
					Name: i.name || i.Name,
					Id: i.id || i.Id,
					Type: i.logicalname || i.Type
				};
			});
	},
	UPDATE_EC_PROCESS_STATUS(state, data) {
		state.emailCampaignProcess = data;
	},
	UPDATE_ENTRY_POINT_STATUS(state, data) {
		state.entryPointStatus = data;
	},
	UPDATE_RECORD_LIST(state, data) {
		const list = {
			...data,
			itemsCount: data.items.length,
			itemsLimitToGenerateCO: 50,
			isVisible: (data.entityName === 'listing') || !'listing',
			// matchedToTemplate: (data.entityName === 'listing') || !'listing'
			matchedToTemplate: true
		};
		state.steps.find(i => i.index === 1).recordsList.push(list);
	},
	UPDATE_TEMPLATE_PROCESS_STATUS(state, data) {
		state.templateProcess = data;
	},
	UPDATE_TEMPLATE_AVAILABLE(state, data) {
		state.steps.find(i => i.name === 'template').isTempalteAvailable = data;
	},
	DELETE_TEMPLATE(state, data) {
		const filteredTemplates = state.steps.find(i => i.name === 'template').templates.filter(i => i.templateId !== data);
		state.steps.find(i => i.name === 'template').templates = filteredTemplates;
	},
	UPDATE_TEMPLATES(state, data) {
		const templateList = state.steps.find(i => i.name === 'template').templates;
		const selectedTemplate = templateList.find(i => i.selected) && templateList.find(i => i.selected) || null;
		const selectedTemplateId = selectedTemplate && selectedTemplate.templateId || null;
		const filteredList = data.reduce((a, i) => {
			return [...a, {
				...i,
				selected: i.templateId === selectedTemplateId
			}];
		}, []);

		if (selectedTemplate && !filteredList.some(i => i.templateId === selectedTemplateId)) {
			const activeDeletedTemplate = selectedTemplate;
			activeDeletedTemplate.deleted = true;
			activeDeletedTemplate.selected = false;
			filteredList.unshift(activeDeletedTemplate);
			if (!state.steps.find(s => s.name === 'template').errors.some(i => i.name === 'templateExist')) {
				state.steps.find(s => s.name === 'template').errors.push({
					text: 'Previously selected template doesn’t exist. Please select another one.',
					type: 'warning',
					close: true,
					step: 'template',
					name: 'templateExist'
				});
			}
		}
		const deletedTemplate = templateList.find(i => i.deleted);
		if (!state.steps.find(i => i.name === 'template').isTempalteAvailable && deletedTemplate && !selectedTemplate) {
			deletedTemplate.selected = false;
			filteredList.unshift(deletedTemplate);
		}
		state.steps.find(i => i.name === 'template').templates = filteredList;
	},
	CLEAR_TEMPLATES(state) {
		const length = state.steps.find(i => i.name === 'template').templates.length;
		state.steps.find(i => i.name === 'template').templates.splice(0, length);
	},
	UPDATE_SELECTED_TEMPLATE(state, data) {
		state.steps.find(i => i.name === 'template').templates.forEach(template => template.templateId === data ? template.selected = true : template.selected = false);
	},
	UPDATE_SAVED_FLAG(state, data) {
		state.campaign.isSaved = data;
	},
	UPDATE_EDITED_FLAG(state, data) {
		state.campaign.isEdited = data;
	},
	UPDATE_DETAILS_FLAG(state, data) {
		state.isDetails = data;
	},
	UPDATE_CAMPAIGN_STATUS(state, data) {
		state.campaign.status = data;
	},
	UPDATE_CAMPAIGN_NAME(state, data) {
		state.campaign.name = data;
	},
	UPDATE_DETAILS_PROCESS_STATUS(state, data) {
		state.detailsProcess = data;
	},
	SET_ERRORS(state, data) {
		data.forEach((e) => {
			if (e.step) {
				if (!state.steps.find(s => s.name === e.step).errors.some(i => i.name === e.name)) state.steps.find(s => s.name === e.step).errors.push(e);
			} else {
				if (!state.errors.some(i => i.name === e.name)) state.errors.push(e);
			}
		});
	},
	REMOVE_ERROR(state, data) {
		data.step ? state.steps.find(i => i.name === data.step).errors = state.steps.find(i => i.name === data.step).errors.filter(i => i.name !== data.name) : state.errors = state.errors.filter(i => i.name !== data.name);
	},
	UPDATE_CAMPAIGN_EDGE_ID(state, data) {
		state.campaign.edgeId = data;
	},
	UPDATE_CAMPAIGN_MCI_ID(state, data) {
		state.campaign.id = data;
	},
	UPDATE_XMS_ACCOUNT_ID(state, data) {
		state.campaign.xmsAccountId = data;
	},
	UPDATE_MCI_ACCOUNT_ID(state, data) {
		state.campaign.mciAccountId = data;
	},
	UPDATE_SAVING_STATUS(state, data) {
		state.isSavingInterrupted = data;
	},
	UPDATE_COLLECTION_TYPE(state, data) {
		state.steps.find(i => i.name === 'template').collectionType = data;
	},
	UPDATE_DELAY_SEND_STATUS(state, data) {
		state.delayCampaignSend = data;
	},
	UPDATE_REGENERATE_LATER(state, data) {
		state.regenerateLater = data;
	},
	RESET_WIZARD_STATE(state) {
		state.templateProcess = true;
		state.emailCampaignProcess = false;
		state.campaign.id = null;
		state.campaign.edgeId = null;
		state.campaign.isSaved = false;
		state.campaign.isEdited = false;
		state.delayCampaignSend = false;
		state.regenerateLater = false;
		state.entryPointStatus = false;
		state.errorPopupStatus = false;
		state.campaignWithArtifactStatus = false;
		state.steps[0].systemuser = {};
		state.steps[0].domains = [];
		state.steps[0].mcUser = {};
		state.steps[2].selection.listing = [];
		state.steps[2].selection.availability = [];
		state.steps[2].collectionForRegeneration = [];
		state.steps[1].templates = [];
		state.steps[1].recordsList = [];
		state.steps[1].collectionType = '';
		state.steps[1].artifact = null;
		state.steps[1].limitError = false;
		state.steps[1].artifactAlias = null;
		state.steps[3].selection = [];
		state.steps[3].errors = [];
		state.steps[3].recepientsForRegeneration = [];
		state.steps[4].frameSrc = '';
		state.steps[4].editTemplateStatusComputed = false;
		state.steps.map(i => {
			i.valid = false;
		});
	},
	UPDATE_LIMIT_ERROR(state, data) {
		state.steps.find(i => i.name === 'template').limitError = data;
	},
	SET_FORCE_EXIT(state, data) {
		state.forceExit = data;
	},
	UPDATE_COLLECTION_LIMIT_WARNING(state, data) {
		state.steps.find(i => i.name === 'collection').collectionLimitWarning = data;
	},
	UPDATE_ARTIFACT_ALIAS(state, data) {
		state.steps.find(i => i.name === 'template').artifactAlias = data;
	},
	UPDATE_COLLECTION_FOR_REGENERATION(state, data) {
		state.steps.find(i => i.name === 'collection').collectionForRegeneration = data;
	},
	UPDATE_RECEPIENTS_FOR_REGENERATION(state, data) {
		state.steps.find(i => i.name === 'recepients').recepientsForRegeneration = data;
	},
	UPDATE_ERROR_POPUP_STATUS(state, data) {
		state.errorPopupStatus = data;
	},
	UPDATE_PREFILLED(state, value) {
		state.prefilled = value;
	},
	SET_DOMAINS(state, data) {
		const oldDomains = state.steps.find(i => i.name === 'setup').domains;
		data.forEach((i) => {
			if (!oldDomains.some(d => d.displayName === i.displayName)) {
				state.steps.find(i => i.name === 'setup').domains.push(i)
			}
		});
		if (data.some(i => i.selected)) {
			const selectedItemName = data.find(i => i.selected).displayName;
			state.steps.find(i => i.name === 'setup').domains.forEach((i) => {
				i.selected = i.displayName === selectedItemName ? true : false;
			})
		}
	}
};

export default mutations;
