<template>
	<div class="accept-screen-wrapper">
		<div class="accept-dialog-wrapper">
			<div class="header">Terms of Use and Privacy Policy update</div>
			<div class="message-wrapper">
				Please confirm that you accept <br> our <a :href="ppLink" @click.prevent="download(tosLink,'Terms Of Use')">Terms of Use</a> and <a :href="tosLink" @click.prevent="download(ppLink, 'Privacy Policy')">Privacy Policy</a>
			</div>
			<div class="buttons">
				<button type="submit" @click="accept">Accept</button>
			</div>
		</div>
		<div class="loading-overlay" v-show="isLoading"></div>
	</div>
</template>
<script>
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import {
	downloadExternalUrl, Create, Update
} from '@acx-xms/data-functions/dist';

export default {
	name: 'accept-privacy-and-terms',
	data() {
		return {
			ppLink: '',
			tosLink: '',
			isLoading: false
		};
	},
	async created() {
		const environmentSettings = await sc.classes.get('settings.dataProvider').getEnvironmentSettings(sc.cluster.key);
		this.ppLink = environmentSettings.privacyPolicyUrl;
		this.tosLink = environmentSettings.termsOfUseUrl;
	},
	methods: {
		async accept() {
			this.isLoading = true;
			let user = this.$route.params.user;
			if (!user) {
				// in case user refrshes page - we need to get userInfo
				user = await GetUserInfo();
			}
			const now = new Date();
			try {
				await Update('systemuser', user.Id, { acceptedterms: now });
				Create('termsandpolicyacceptance', {
					name: 'Accepted terms and privacy policy for: ' + user.fullname, // mandatory field on EDGE
					systemuserid: {
						logicalname: 'systemuser',
						id: user.Id
					},
					email: user.email,
					privacypolicyurl: this.ppLink,
					policyacceptancedatetime: now,
					termsofuseurl: this.tosLink,
					termsofusepublicationdate: now
				});
			} catch (error) {
				sc.utils.errorMessage.byResponse(error);
			}
			this.isLoading = false;
			this.$router.replace(this.prevRoute.fullPath);
		},
		download(source, name) {
			downloadExternalUrl(source, name);
		}
	},
	// save prev route
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.prevRoute = from;
			next();
		});
	}
};
</script>
<style src="./accept-privacy-and-terms.less" scoped></style>
