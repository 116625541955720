/* eslint eqeqeq: 0 */
import helperMethods from './../component-set-evaluation-context-helper.methods';
import ProcessQuery from './../../../Data/GroupedQueriesExtender';
import MsSearchResultsState from './../../../States/modules/ms-search-results-state';
import HandlersMixin from './handlersMixin';
import * as tracking from '@/Data/trackingModule';
import { createEvaluationContext, evaluate } from '@/Data/EvaluationContext/evaluationContext';
import { GetPerConfig, SetPerConfig } from '@/Data/DataProviders/userStorageDataProvider';

export default {
	mixins: [HandlersMixin],
	methods: {
		buildRelationsFilter() {
			if (sc.utils.findProperty(this.options, 'relations.relation', true)) {
				const relations = this.options.relations.relation.map(relation => {
					return sc.classes.get(relation.$type, relation).toFilter();
				});
				return relations;
			}
		},
		buildFilter(filter, context) {
			const getFilterType = (type) => {
				switch (type) {
				case 'search.complex.dateFacetFilter':
					return 'search.dateFacetFilter';
				case 'search.complex.numericFacetFilter':
					return 'search.numericFacetFilter';
				default:
					return type;
				}
			};

			const filterType = getFilterType(filter.$type);
			const buildedFilter = sc.classes.get(filterType, filter, context).toFilter();
			if (buildedFilter) {
				buildedFilter.entity = filter.entity || this.logicalName;
			}
			return buildedFilter;
		},
		buildFilters(filters, context) {
			if (filters) {
				let searchFilters = filters.map(filter => this.buildFilter(filter, context)).filter(filter => Boolean(filter));

				const relationFilters = this.buildRelationsFilter();
				if (relationFilters !== void 0) {
					searchFilters = searchFilters.concat(relationFilters);
				}
				return searchFilters;
			} else {
				return [];
			}
		},

		initSearchableFields() {
			let filter = { logicalName: '_all' };

			if (this.options.defaultSearchFields) {
				filter = {
					additionalOptions: {
						kind: this.options.defaultSearchFields.searchFilterKind,
						boost: {
							phrase: this.options.defaultSearchFields.phraseBoost,
							phrasePrefix: this.options.defaultSearchFields.phrasePrefixBoost,
							term: this.options.defaultSearchFields.termBoost
						}
					},
					logicalName: this.options.defaultSearchFields.field && this.options.defaultSearchFields.field.map(field => {
						return {
							logicalName: field.value,
							boost: field.boost || 1
						};
					})
				};
			}

			this._currentFilter = filter;

			this.innerSearchResults = this.options.innerSearchResults;
		},
		openRecordDetails(details, ref, parentView = null) {
			const detailsToApply = {
				...details,
				...{
					namespace: this.options.namespace,
					internalNamespace: this.options.internalNamespace,
					entityref: ref,
					breadcrumbsKey: this.breadcrumbsKey,
					$key: ref.id,
					parentView
				}
			};
			this.details = detailsToApply;
		},
		parseStatistic(data) {
			return {
				NumericFacets: data.NumericFacets,
				TermFacets: data.TermFacets,
				DateTimeFacets: data.DateTimeFacets
			};
		},
		async groupedSearch(filter = []) {
			this.loading = true;
			const withSelectedFilters = this.evaluationContext.eval(this.options.withSelectedFilters) || false;
			const groupBySource = this.options.groupBySource ? this.evaluationContext.eval(this.options.groupBySource) : null;
			const filters = this.stateNamespace ? this.$store.getters[this.stateNamespace + '/getFilters'](this.logicalName) : [];
			const selectedFilters = withSelectedFilters && this.stateNamespace ? this.$store.getters[this.stateNamespace + '/getSelectedFilters'](this.logicalName) : [];
			const initFilters = this.options.initFilters ? this.buildFilters(this.options.initFilters.filter, this.evaluationContext) : [];
			const newFilters = filters.concat(
				initFilters,
				selectedFilters,
				filter, /* custom filter */
				sc.classes.get('offsetFrom.filter', 0),
				sc.classes.get('offsetSize.filter', this.pageSize),
				this.orderByFilters || []
			);
			let response = null;
			if (groupBySource && this.options.groupByTarget) {
				response = await ProcessQuery(this.logicalName, newFilters, this.options.switchOffNestedCollectionType, this.options.selectedFieldsSectionType);

				this.results = response.Results.filter((record) => {
					const context = helperMethods.wrapResult(record);
					return helperMethods.eval(context, this.options.groupByTarget) === groupBySource;
				});

				if (this.results.length && this.stateNamespace) {
					const parsed = this.parseStatistic(response);
					this.$store.commit(this.stateNamespace + '/setStatistic', {
						entity: this.logicalName,
						statistic: parsed
					});
				}
			} else {
				response = await sc.classes.get('edge.dataProvider').search({
					filters: newFilters,
					entities: [this.logicalName]
				});
				this.results = response.Results;
				if (this.results.length && this.stateNamespace) {
					const parsed = this.parseStatistic(response);
					this.$store.commit(this.stateNamespace + '/setStatistic', {
						entity: this.logicalName,
						statistic: parsed
					});
				}
			}
			if (this.options.passResultsToStore) {
				this.$store.commit(this.stateNamespace + '/setSearchResults', {
					key: this.logicalName,
					nestedKey: this.getKey(),
					value: this.results
				});
			}
			if (this.combinedNamespace && this.fork) {
				this.fork.emit(this.combinedNamespace + '.entity.searched', { results: this.results });
			}
			this.loading = false;
		},

		subscribeOnSearchFieldEvents() {
			this.$on('clear-search-query', () => {
				this.searchQuery = '';
				this.prevSearchQuery && this.prevSearchQuery.length && this.doSearch({ initSearch: false });
			});
			this.$on('execute-search', async (searchQuery) => {
				this.searchQuery = searchQuery;
				await this.doSearch({ initSearch: false });
				this._changeURLToFirstPage();
			});
			this.$on('set-content', (content) => {
				this.content = content;
			});
			this.$on('set-with-toggle', (withToggleAll) => {
				this.withToggleAll = withToggleAll;
			});
		},

		initialization() {
			this.componentsMapping = {
				'component-set.searchResultsTableView': 'component-set-search-results-table-view',
				'component-set.searchResultsDocumentTreeView': 'component-set-search-results-document-tree-view',
				'component-set.mobileSearchResultsTableView': 'component-set-mobile-search-results-table-view',
				'component-set.searchResultsThumbnailView': 'component-set-search-results-thumbnailview',
				'component-set.searchResultsMapView': 'component-set-search-results-map-view',
				'component-set.searchResultsMapOnlyView': 'component-set-search-results-map-only-view',
				'component-set.mobileSearchResultsMapOnlyView': 'component-set-mobile-search-results-map-only-view',
				'component-set.searchResultsThumbnailViewColumned': 'component-set-search-results-thumbnailview-columned',
				'component-set.searchResultsWithPreview': 'component-set-search-results-with-preview'
			};

			this.items = this.options.component && this.options.component.map(component => {
				this.withToggleAll = component.withToggleAll;
				return Object.assign(component, {
					title: helperMethods.eval(this.evaluationContext, component.title),
					viewIcon: helperMethods.eval(this.evaluationContext, component.viewIcon),
					namespace: this.namespace,
					settings: component.settings,
					internalNamespace: this.internalNamespace,
					modalNamespace: this.modalNamespace,
					targetEntity: this.targetEntity,
					enableFullscreenControl: component.enableFullscreenControl,
					enableDrawingTool: component.enableDrawingTool,
					isParent: true,
					total: this.paginationParams.total,
					name: this.componentsMapping[component.$type]
				});
			});

			this.actionMenuComponent = {
				$type: 'layoutcomponent.marketspaceActionMenu',
				menu: this.options.actionmenu,
				sharemenu: this.options.sharemenu,
				toolbarActions: this.options.toolbarActions,
				namespace: this.namespace,
				internalNamespace: this.internalNamespace,
				modalNamespace: this.modalNamespace,
				entityType: this.subTargetEntity ? [this.subTargetEntity, ...this.targetEntity] : this.targetEntity,
				scrollNamespace: this.scrollNamespace
			};
		},

		async initVariables() {
			this.searchQuery = '';

			this.internalNamespace = helperMethods.eval(this.evaluationContext, this.options.internalNamespace);
			this.namespace = helperMethods.eval(this.evaluationContext, this.options.namespace);
			this.combinedNamespace = this.namespace + '.' + this.internalNamespace;
			this.stateNamespace = helperMethods.eval(this.evaluationContext, this.options.stateNamespace);
			this.$root.$on(this.stateNamespace + '.targetEntityChange', this.processEntity);
			if (!this.$store.state[this.stateNamespace]) {
				this.$store.registerModule(this.stateNamespace, MsSearchResultsState);
			}

			this.modalNamespace = helperMethods.eval(this.evaluationContext, this.options.modalNamespace);

			this.itemsPerPage = this.options.itemsPerPage
				? this.options.itemsPerPage.itemsCountPerPage
				: [{
					value: 10,
					default: true
				}];
			this.hideHeader = helperMethods.eval(this.evaluationContext, this.options.hideHeader);
			this.hideSearchField = helperMethods.eval(this.evaluationContext, this.options.hideSearchField);
			this.showPaging = helperMethods.eval(this.evaluationContext, this.options.showPaging);
			this.targetEntity = this.options.targetEntity.map(entity => helperMethods.eval(null, entity));
			this.subTargetEntity = helperMethods.eval(this.evaluationContext, this.options.subTargetEntity);
			this.logicalName = this.targetEntity[0];
			this.autoSearch = this.options.autoSearch;
			this.trackUserActivity = this.options.trackUserActivity;
			this.showSelectedCount = helperMethods.eval(this.evaluationContext, this.options.showSelectedCount);
			this.toggleNamespace = helperMethods.eval(this.evaluationContext, this.options.toggleNamespace) || null;
			this.stateKey = helperMethods.eval(this.evaluationContext, this.options.stateKey) || null;
			this.sortByFields = this.options.sortByFields ? helperMethods.eval(this.evaluationContext, this.options.sortByFields.visible || true) : false;
			this.title = helperMethods.eval(this.evaluationContext, this.options.title);
			this.visible = this.options.visible !== void 0 ? await helperMethods.evalAsync(this.evaluationContext, this.options.visible) : true;
			this.scrollNamespace = helperMethods.eval(this.evaluationContext, this.options.scrollNamespace);
			this.refreshOnPaginationEmptyResultsOnInit = this.options.refreshOnPaginationEmptyResultsOnInit;
			this.overridedNames = this.options.overridedNames ? helperMethods.eval(this.evaluationContext, this.options.overridedNames) : null;

			this.prevSearchQuery = this.searchQuery; // remove
		},
		_loadLastSearchResults(filtersToApply, withDefaultFilters) {
			const promise = this.getSearchResults(filtersToApply, withDefaultFilters);
			this.lastPromise = promise;
			return new Promise((resolve, reject) => {
				promise
					.then(response => {
						if (promise == this.lastPromise) {
							this.lastPromise = null;
							resolve(response);
						}
					}, (error) => {
						if (promise == this.lastPromise) {
							this.lastPromise = null;
							reject(error);
						}
					});
			});
		},
		_changeURLToFirstPage() {
			const query = { ...this.$route.query };
			query.page && delete query.page;
			this.$router.push({ query });
		},
		searchAndRedirectToFirstPage(changePage, customQuery, initSearch) {
			this._changeURLToFirstPage();
			this._search(changePage, customQuery, initSearch, true);
		},
		async _search(changePage, customQuery, initSearch, clearSelection = false) {
			this.isLoading = true;

			/* TODO: research where bug is - stateNamespace saves from old component */
			if (!this.$store.state[this.stateNamespace]) return;

			/* Only if needed, in other cases - save selection */
			if (clearSelection) this.$store.commit(this.stateNamespace + '/cleanSelection');

			if (!changePage && !initSearch) {
				this.paginationParams.from = 0;
				this.paginationParams.activePages = [1];
			}
			let recordsToHide = await GetPerConfig('hiddenRecords') || [];

			this._setChangesToStore && this.$store.commit(this.stateNamespace + '/setPaginationParams', this.paginationParams);

			const filters = this.$store.getters[this.stateNamespace + '/getFilters'](this.logicalName);
			const selectedFilters = this.$store.getters[this.stateNamespace + '/getSelectedFilters'](this.logicalName);
			const order = this.$store.getters[this.stateNamespace + '/getOrder'](this.logicalName);
			const size = this.paginationParams.activePages.length * this.paginationParams.pageSize;

			let filtersToApply = [
				...filters,
				...selectedFilters,
				order,
				sc.classes.get('offsetFrom.filter', this.paginationParams.from),
				sc.classes.get('offsetSize.filter', size),
				sc.classes.get('resultsFormat.filter', this.options.searchFormat || 'Hit')
			];
			if (recordsToHide.length) {
				recordsToHide = recordsToHide.filter(record => Math.abs(Date.now() - record.time) < 1 * 1000 * 60);
				const ids = [];
				recordsToHide.forEach(record => {
					if (record.logicalname === this.logicalName) ids.push(record.id);
				});
				await SetPerConfig('hiddenRecords', recordsToHide);
				if (ids.length) {
					filtersToApply.push(sc.classes.get('termFacet.filter', {
						logicalName: `${this.logicalName}id`,
						query: ids,
						negative: true
					}));
				}
			}
			if (customQuery) {
				this.prevSearchQuery = customQuery;
				this.searchQuery = customQuery;
				this.$emit('set-search-query', this.searchQuery);
			}
			if (this.sharedSearchCustomQuery) {
				this.$emit('set-search-query', this.searchQuery);
				this.sharedSearchCustomQuery = false;
			}
			this.$store.commit(this.stateNamespace + '/setSearchQuery', this.searchQuery);
			if (this._currentFilter && this.searchQuery) {
				const searchQueryFilter = sc.classes.get('field.filter', $.extend({ query: this.searchQuery }, this._currentFilter));
				this._setChangesToStore && this.$store.commit(this.stateNamespace + '/setSearchQueryFilter', searchQueryFilter);
				filtersToApply.push(searchQueryFilter);
			} else {
				this._setChangesToStore && this.$store.commit(this.stateNamespace + '/setSearchQueryFilter', null);
			}

			filtersToApply = filtersToApply.filter(filter => filter);
			this._loadLastSearchResults(filtersToApply, this.options.withDefaultFilters)
				.then((response) => {
					const parsed = this.parseStatistic(response);
					this.$store.commit(this.stateNamespace + '/setStatistic', {
						entity: this.logicalName,
						statistic: parsed
					});
					this._onSearchDone(response.Results, response.Total, initSearch);
					if (this._currentFilter) {
						this.fork.emit(this.combinedNamespace + '.searchQueryChanged', {
							query: this.searchQuery,
							filter: this._currentFilter
						});
					}
				})
				.finally(() => { this.isLoading = false; });
		},
		getSearchResults(filtersToApply, withDefaultFilters) {
			return sc.classes.get('edge.dataProvider').search({
				filters: filtersToApply,
				entities: this.targetEntity,
				withDefaultFilters: withDefaultFilters === void 0 || withDefaultFilters,
				switchOffNestedCollectionType: this.options.switchOffNestedCollectionType,
				selectedFieldsSectionType: this.options.selectedFieldsSectionType
			});
		},
		doSearch({ initSearch }) {
			this.forceSearch = true;
			this.content && this._search(undefined, undefined, initSearch);
			this.prevSearchQuery = this.searchQuery;
		},

		_onSearchDone(data, totalCount, initSearch) {
			const from = this.paginationParams.from;

			totalCount = totalCount || 0;
			const size = (data) ? data.length : 0;
			this.paginationParams.total = totalCount;

			this.results = {
				logicalName: this.logicalName,
				results: data || [],
				total: totalCount,
				from,
				size,
				searchQuery: this.searchQuery
			};
			(this.options.handlers || []).forEach(name => this.execHandler(name));

			this.fork.emit(this.combinedNamespace + '.entity.searched', this.results);

			if (this.options.passResultsToStore) {
				this.$store.commit(this.stateNamespace + '/setSearchResults', {
					key: this.logicalName,
					nestedKey: this.getKey(),
					value: data
				});
			}
			if (this.$store.getters[this.stateNamespace + '/getSharedSearchSelectionExport']) {
				this.$store.commit(this.stateNamespace + '/updateSharedSearchSelectionExport', data);
			}
			this.forceSearch = false;
			this.$root.$emit(this.stateNamespace + '.entity.searched', this.results);

			initSearch && this._initSearchCallbackOnDone(this.results);
		},

		_initSearchCallbackOnDone(data) {
			if (this.refreshOnPaginationEmptyResultsOnInit && this.$route.query.page && this.$route.query.page > 1 && !data.results.length) {
				window.location = this.$route.path;
			}
		},

		initItemsPerPageAndPagionation() {
			this.availablePageSizes = this.itemsPerPage && this.itemsPerPage.map(item => { return parseInt(item.value); });
			const defaultItem = this.itemsPerPage && this.itemsPerPage.filter(item => item.default === true);

			this.paginationParams.pageSize = defaultItem.length ? parseInt(defaultItem[0].value) : this.availablePageSizes[0];
		},

		initFilters() {
			// init context for filterPanel
			if (this.options.parentData) {
				this.filterPanelEvaluationContext = createEvaluationContext(this.options.parentData);
			}
			// add filters to store in order to know what filters should be awaited
			if (this.options.filterPanel) {
				const filterGroups = this.options.filterPanel.filters.group.filter(group => group.$type !== 'entity.savedSearchGroup');
				const filters = filterGroups
					.flatMap(group => group.filter.filter(filter => filter.entities.includes(this.logicalName)))
					.map(filter => evaluate(filter.logicalName));
				this.$store.commit(this.stateNamespace + '/fillFiltersReady', {
					entity: this.logicalName,
					filters,
					filterName: 'filters'
				});
				if (this.subTargetEntity) {
					const subfilters = filterGroups
						.flatMap(group => group.filter.filter(filter => filter.entities.includes(this.subTargetEntity)))
						.map(filter => evaluate(filter.logicalName));
					this.$store.commit(this.stateNamespace + '/fillFiltersReady', {
						entity: this.subTargetEntity,
						filters: subfilters,
						filterName: 'filters'
					});
				}
			}
			const isDefaultSortBy = this.options.sortByFields && this.options.sortByFields.field.some(field => evaluate(field.default));
			if (isDefaultSortBy) {
				this.$store.commit(this.stateNamespace + '/fillFiltersReady', {
					entity: this.logicalName,
					filters: ['order'],
					filterName: 'order'
				});
			}
			// TODO: consider storing and waiting filters below as well
			// init initfilters
			if (Object.keys(this.options.initFilters || {}).length) {
				this.options.initFilters.filter.forEach((f) => {
					this.buildAndCommitFiltersToStore(f.entity || this.logicalName, f);
				});
				if (Object.keys(this.$store.state.initFilters).length) {
					this.$root.$on(this.stateNamespace + '.map-ready', () => {
						const value = {
							entity: this.logicalName,
							filters: this.$store.state[this.stateNamespace].filters[this.logicalName]
						};
						this.$store.commit(this.stateNamespace + '/replaceFilters', value);
						if (this.subTargetEntity) {
							const subvalue = {
								entity: this.subTargetEntity,
								filters: this.$store.state[this.stateNamespace].filters[this.subTargetEntity]
							};
							this.$store.commit(this.stateNamespace + '/replaceFilters', subvalue);
						}
					});
				}
			}
			if (this.options.filtersToApply) {
				this.options.filtersToApply.filter.forEach((f) => {
					this.buildAndCommitFiltersToStore(f.entity || this.logicalName, f, false);
				});
			}
		},

		buildAndCommitFiltersToStore(entity, filter, isDefault = true) {
			const buildedFilter = this.buildFilter(filter, this.filterPanelEvaluationContext);
			const isEnabled = this.filterPanelEvaluationContext && filter.enable ? this.filterPanelEvaluationContext.eval(filter.enable) : true;
			const uiFilterValue = filter.searchValue && buildedFilter;
			const adjustedFilter = filter.searchValue && isEnabled
				? sc.classes.get(filter.searchValue.$type, filter.searchValue, createEvaluationContext(uiFilterValue)).toFilter()
				: buildedFilter;
			if (isEnabled) {
				if (adjustedFilter) {
					this.commitFiltersToStore(entity, filter.logicalName, adjustedFilter, isDefault);
				}

				if (filter.searchValue) {
					isDefault && this.$store.commit(this.stateNamespace + '/addDefaultUiFilter', {
						entity,
						logicalName: filter.logicalName,
						filter: uiFilterValue
					});
					this.$store.commit(this.stateNamespace + '/addOrUpdateUiFilter', {
						entity,
						logicalName: filter.logicalName,
						filter: uiFilterValue
					});
				}

				if (['search.complex.numericFacetFilter', 'search.complex.dateFacetFilter'].indexOf(filter.$type) >= 0) {
					if (filter && filter.childSearchValue) {
						const childFilter = sc.classes.get(filter.childSearchValue.$type, filter.childSearchValue, createEvaluationContext(uiFilterValue)).toFilter();
						filter.childEntity.forEach((entity) => {
							this.commitFiltersToStore(entity, filter.childLogicalName, childFilter, isDefault);
						});
					} else {
						const childFilter = { ...buildedFilter };
						childFilter.data = {
							...childFilter.data,
							logicalName: filter.childLogicalName
						};

						filter.childEntity.forEach((childEntity) => {
							this.commitFiltersToStore(childEntity, childFilter.data.logicalName, childFilter, isDefault);
						});
					}
				}
			}
		},
		commitFiltersToStore(entity, filterLogicalName, filter, isDefault = true) {
			isDefault && this.$store.commit(this.stateNamespace + '/addDefaultFilter', {
				entity,
				logicalName: filterLogicalName,
				filter
			});
			this.$store.commit(this.stateNamespace + '/addOrUpdateFilter', {
				entity,
				logicalName: filterLogicalName,
				filter
			});
		},

		trackActivity() {
			let activityType;
			let name;
			if (this.paginationParams.activePages.length > 1) {
				activityType = this.title + '. Loadmore. Start page: ' + this.paginationParams.activePages[0] + '. Page number: ' + this.paginationParams.activePages[this.paginationParams.activePages.length - 1];
				name = 'page.loadmore.' + this.logicalName;
			} else {
				activityType = this.title + '. Page navigation to ' + this.paginationParams.activePages[0];
				name = 'page.navigation.' + this.logicalName;
			}
			tracking.AddUserActivity(name, activityType);
		},

		getKey() {
			// parentEntityId
			return `${this.evaluationContext.entity.id}`;
		}
	},
	beforeRouterLeave(from, to, next) {
		this.$store.commit(this.stateNamespace + '/setSearchQuery', this.searchQuery);
		next();
	}
};
