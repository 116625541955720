export default {
	parameters(options) {
		return [this.eval(options.value)];
	},
	async parametersAsync(options) {
		return [await this.evalAsync(options.value)];
	},
	evaluate(src) {
		return sc.classes.get('entityReference', src);
	}
};
