var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "searchResults-documentTree" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "perfectscroll",
                rawName: "v-perfectscroll",
                value: { enabled: true },
                expression: "{ enabled:true }"
              }
            ],
            staticClass: "folder-tree"
          },
          [
            _c(
              "div",
              [
                _vm.options.logicalname
                  ? _c("FolderTreeMain", {
                      attrs: {
                        watchSearchResults: _vm.options.watchSearchResults,
                        srNamespace: _vm.folderSrNamespace,
                        logicalname: _vm.options.logicalname,
                        treeLogicalName: _vm.options.treeLogicalName,
                        rootName: _vm.rootName,
                        namespace: this.options.namespace,
                        configTreeFilters: _vm.configTreeFilters
                      },
                      on: {
                        treeClicked: _vm.folderClickedHandler,
                        setBreadcrumbs: _vm.setBreadcrumbs
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm.options.sharedlogicalname
                  ? _c("SharedFolderTree", {
                      attrs: {
                        srNamespace: _vm.sharedFolderSrNamespace,
                        folderSrNamespace: _vm.folderSrNamespace,
                        sharedTreeLogicalName:
                          _vm.options.sharedTreeLogicalName,
                        sharedlogicalname: _vm.options.sharedlogicalname
                      },
                      on: {
                        setBreadcrumbs: _vm.setBreadcrumbs,
                        sharedTreeClicked: _vm.sharedClickedHandler
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "results-wrapper" },
          [
            _vm.breadcrumbs.length
              ? _c("TreeBreadcrumbs", {
                  attrs: {
                    maxBreadcrumbsLength: _vm.options.maxBreadcrumbsLength,
                    stateNamespace: _vm.stateNamespace,
                    breadcrumbs: _vm.breadcrumbs
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  "table-wrapper",
                  { "m-top": !_vm.breadcrumbs.length, grid: _vm.hasTagList }
                ]
              },
              [
                _vm.hasTagList
                  ? _c("TagList", {
                      attrs: {
                        stateNamespace: _vm.stateNamespace,
                        entityNamespace:
                          _vm.options.targetEntity &&
                          _vm.options.targetEntity[0]
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("ControlTableView", {
                  class: _vm.cssClass || "",
                  attrs: {
                    options: _vm.component,
                    stateNamespace: _vm.stateNamespace,
                    evaluationContext: _vm.evaluationContext,
                    initResults: _vm.results
                  }
                }),
                _vm._v(" "),
                _vm.paginationParams
                  ? _c("Pagination", {
                      attrs: {
                        pageSize: _vm.paginationParams.pageSize,
                        total: _vm.paginationParams.total,
                        from: _vm.paginationParams.from,
                        showPaging: _vm.paginationParams.showPaging,
                        activePages: _vm.paginationParams.activePages
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }