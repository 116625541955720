<template>
	<component v-if="isReady" :is="componentName" class="discovery-component"></component>
</template>
<script>
import StaticDiscovery from '@/Components/Initialization/Discovery/static-discovery';
import ExternalDiscovery from '@/Components/Initialization/Discovery/external-discovery';
import axios from 'axios';
export default {
	name: 'discovery-wrapper',
	components: {
		StaticDiscovery,
		ExternalDiscovery
	},
	data() {
		return {
			isReady: false,
			componentName: null
		};
	},
	async created() {
		this.$store.commit('changeInitMessage', 'Getting discovery info');

		try {
			const typeResp = await axios.get('/api/Discovery');
			const parts = typeResp.data.split('.');
			const type = parts[parts.length - 1].replace('DiscoveryService', '').toLowerCase();
			this.componentName = `${type}-discovery`;
			this.isReady = true;
			this.$store.commit('changeInitMessage', null);
		} catch (e) {
			this.$store.commit('changeInitMessage', e.message);
		}
	}
};
</script>
