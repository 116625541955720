import ExpressionScope from '../misc/scope';

export default {
	parameters(options) {
		return [
			this.eval(options.argument),
			options.itemScope,
			this.scope,
			options.mapper,
			this.eval
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument),
			options.itemScope,
			this.scope,
			options.mapper,
			this.eval

		];
	},
	evaluate(array, scopekey, scopes, expr, builder) {
		return array.map((item) => {
			return ExpressionScope.evaluate(scopes, scopekey, item, expr, builder);
		});
	}
};
