export default {
	parameters(options) {
		return options.argument.map(this.eval);
	},
	async parametersAsync(options) {
		return await Promise.all(options.argument.map(await this.evalAsync));
	},
	evaluate(left, right) {
		return JSON.stringify(left) !== JSON.stringify(right);
	}
};
