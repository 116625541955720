<template>

		<control	class="formcontrol-template" v-if="visible && template"
					:name="template.$type"
					:contentProps="template"
					:evaluationContext="evaluationContext"></control>

</template>
<script>

export default {
	name: 'control-template',
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: { Control: () => import('./../Entity/control.vue') },
	data() {
		return {
			visible: this.options.visible !== void 0 ? this.evaluationContext.eval(this.options.visible) : true,
			template: null
		};
	},
	created() {
		const self = this;
		let entityName;
		if (this.options.entity) {
			entityName = this.options.entity;
		}
		if (!entityName) {
			entityName = this.evaluationContext.entity.logicalname;
		}

		sc.classes.get('entityConfiguration.dataProvider')
			.getTemplate(entityName, this.options.usage, this.options.subtype)
			.done(function (template) {
				self.template = template;
			});
	}
};
</script>
