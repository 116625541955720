<template>
	<div class="image-gallery" v-if="images.length > 0" :style='galleryStylesObj' @click="$emit('openDialog')" :class="{'no-hover': !reference}">
		<div v-if="showArrows" class="image-gallery-arrow left" @click.prevent="$emit('prev', $event)">
			<svg class="svg-icon svg-15">
				<use xlink:href="#layout-icons-ms-arrow-left"></use>
			</svg>
		</div>
		<div v-if="showArrows" class="image-gallery-arrow right" @click.prevent="$emit('next', $event)">
			<svg class="svg-icon svg-15">
				<use xlink:href="#layout-icons-ms-arrow-right"></use>
			</svg>
		</div>
		<transition name="fade" mode="out-in">
			<div v-if="!reference" class="wrapper">
				<div class="image-node" :class="{'broken-image-wrapper': image.error}" v-if="index == currentIndex" v-for="(image,index) in imagesComputed" :key="image.imageid">
					<img v-if="!image.error" class="bg-image" :alt="alt" :src="image.url" @error="image.error = true;" />
					<img v-else src="/Images/broken-file-icon-small.png" class="broken-image" />
				</div>
			</div>
			<router-link v-else-if="reference && !isLinkToPrevent && showLink" :to="reference" class="wrapper clickable">
				<div class="image-node" :class="{'broken-image-wrapper': image.error}" v-if="index==currentIndex" v-for="(image,index) in imagesComputed" :key="image.imageid">
					<img v-if="!image.error" class="bg-image" :alt="alt" :src="image.url" @error="image.error = true;" />
					<img v-else src="/Images/broken-file-icon-small.png" class="broken-image" />
				</div>
			</router-link>
			<a v-else-if="reference && (isLinkToPrevent || !showLink)" class="wrapper not-clickable" @click="redirect">
				<div class="image-node" :class="{'broken-image-wrapper': image.error}" v-if="index==currentIndex" v-for="(image,index) in imagesComputed" :key="image.imageid">
					<img v-if="!image.error" class="bg-image" :alt="alt" :src="image.url" @error="image.error = true;" />
					<img v-else src="/Images/broken-file-icon-small.png" class="broken-image" />
				</div>
			</a>
		</transition>
	</div>
	<image-placeholder v-else-if="images.length == 0"
					   :isCustomImagePlaceholder="isCustomImagePlaceholder"
					   cssClass="svg-20"
					   :alt="alt"
					   :placeholderEntityName="placeholderEntityName">
	</image-placeholder>
</template>
<script>
const ImagePlaceholder = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '../ImagePlaceholder/image-placeholder');
export default {
	name: 'control-image-gallery-small-base',
	props: {
		currentIndex: Number,
		showArrows: Boolean,
		galleryStylesObj: Object,
		isCustomImagePlaceholder: Boolean,
		isLinkToPrevent: Boolean,
		showLink: Boolean,
		alt: String,
		images: Array,
		reference: { type: String },
		placeholderEntityName: { type: String }
	},
	methods: {
		redirect() {
			if (!this.showLink) {
				return;
			}
			this.$router.push(this.reference);
		}
	},
	computed: {
		imagesComputed() {
			const images = this.images;
			const hasOrderField = images.some(image => image.hasOwnProperty('order'));

			if (hasOrderField) {
				images.sort((a, b) => {
					return (parseInt(a.order) || 0) - (parseInt(b.order) || 0);
				});
			}

			return images;
		}
	},
	components: { ImagePlaceholder }
};
</script>
<styles src="./control-image-gallery-small-styles.less" />
