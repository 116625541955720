import * as localStorageDataProvider from '@/Data/DataProviders/localStorageDataProvider';
import { isString } from '@acx-xms/data-functions/dist';
import { GetUserInfo } from '@/Data/Auth/authentication-service';

export async function Set(key, value) {
	return localStorageDataProvider.Set(await _makeSpecificKey(key), value);
}

export async function Get(key) {
	return localStorage.getItem(await _makeSpecificKey(key));
}

export async function Remove(key) {
	localStorage.removeItem(await _makeSpecificKey(key));
}

export async function SetPerConfig(key, value) {
	return localStorageDataProvider.Set(await _makeSpecificKey(key, sc.cluster.configKey), value);
}

export async function GetPerConfig(key) {
	return localStorage.getItem(await _makeSpecificKey(key, sc.cluster.configKey));
}

export async function RemovePerConfig(key) {
	localStorage.removeItem(await _makeSpecificKey(key, sc.cluster.configKey));
}

async function _makeSpecificKey(key, additionalKey) {
	if (!isString(key) || !isString(additionalKey || '')) { throw Error('Invalid parameter \'key\'. Should be a string type'); }

	const userinfo = await GetUserInfo();
	return [sc.clusterKey().toLowerCase(), userinfo.Id, additionalKey, key].filter(Boolean).join('_');
}
