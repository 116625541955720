var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "searchResults-thumbnailview", class: _vm.cssClass },
    [
      _vm.results && _vm.results.length && _vm.options.withCheckAll
        ? _c(
            "div",
            {
              staticClass:
                "searchResults-thumbnail-view-header tableview-header"
            },
            [
              _c("ControlSelectAll", {
                attrs: {
                  stateNamespace: _vm.stateNamespace,
                  results: _vm.results,
                  withCheckAll: _vm.options.withCheckAll,
                  isParent: _vm.options.isParent,
                  targetEntity: _vm.targetEntity
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "p2" }, [
                _c("span", {
                  directives: [
                    {
                      name: "localization",
                      rawName: "v-localization",
                      value: { key: "common.dictionary.selectAll" },
                      expression: "{ key: 'common.dictionary.selectAll' }"
                    }
                  ]
                })
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.results && _vm.results.length
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "perfectscroll",
                  rawName: "v-perfectscroll",
                  value: {
                    enable: true,
                    refreshScroll: _vm.paginationParams.activePages.length < 2,
                    onScroll: _vm.onScroll
                  },
                  expression:
                    "{enable: true, refreshScroll: paginationParams.activePages.length < 2, onScroll}"
                }
              ],
              staticClass: "perfectscroll-wrapper"
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "flex-row",
                      rawName: "v-flex-row",
                      value: {},
                      expression: "{}"
                    }
                  ],
                  staticClass: "sc-flex"
                },
                _vm._l(_vm.results, function(item) {
                  return _c(
                    "div",
                    {
                      key: _vm.recordKey(item),
                      staticClass: "item",
                      style: _vm.additionalStyles
                    },
                    [
                      _vm.options.enableNavigation
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.isLinkToPrevent
                                  ? void 0
                                  : _vm.formReferehceUrl(item)
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.checkEnableNavigation($event, item)
                                }
                              }
                            },
                            [
                              _vm.options.allowSelection
                                ? _c("VirtualCheckbox", {
                                    attrs: {
                                      record: item,
                                      stateNamespace: _vm.stateNamespace,
                                      isParent: _vm.options.isParent,
                                      syncSelection: _vm.options.syncSelection,
                                      evaluationContext: _vm.wrapResult(item)
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "item-wrapper" },
                                [
                                  _vm.options.galleryComponent
                                    ? _c("control", {
                                        attrs: {
                                          name:
                                            _vm.options.galleryComponent.$type,
                                          contentProps:
                                            _vm.options.galleryComponent,
                                          evaluationContext: _vm.wrapResult(
                                            item
                                          )
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "info" },
                                    [
                                      _c("control", {
                                        attrs: {
                                          name:
                                            _vm.options.contentComponent.$type,
                                          contentProps:
                                            _vm.options.contentComponent,
                                          evaluationContext: _vm.wrapResult(
                                            item
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "disable-navigation" },
                            [
                              _vm.options.allowSelection
                                ? _c("VirtualCheckbox", {
                                    attrs: {
                                      record: item,
                                      stateNamespace: _vm.stateNamespace,
                                      isParent: _vm.options.isParent,
                                      syncSelection: _vm.options.syncSelection,
                                      evaluationContext: _vm.wrapResult(item)
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "item-wrapper" },
                                [
                                  _vm.options.galleryComponent
                                    ? _c("control", {
                                        attrs: {
                                          name:
                                            _vm.options.galleryComponent.$type,
                                          contentProps:
                                            _vm.options.galleryComponent,
                                          evaluationContext: _vm.wrapResult(
                                            item
                                          )
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "info" },
                                    [
                                      _c("control", {
                                        attrs: {
                                          name:
                                            _vm.options.contentComponent.$type,
                                          contentProps:
                                            _vm.options.contentComponent,
                                          evaluationContext: _vm.wrapResult(
                                            item
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ]
                  )
                })
              ),
              _vm._v(" "),
              _c(_vm.paginationComponent, {
                tag: "pagination",
                staticClass: "bottomed",
                attrs: {
                  pageSize: _vm.paginationParams.pageSize,
                  total: _vm.paginationParams.total,
                  from: _vm.paginationParams.from,
                  activePages: _vm.paginationParams.activePages
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.results || !_vm.results.length,
              expression: "!results || !results.length"
            }
          ],
          staticClass: "no-results-message"
        },
        [
          _c("div", {
            directives: [
              {
                name: "localization",
                rawName: "v-localization",
                value: { key: "common.dictionary.noMatchesFound" },
                expression: "{ key: 'common.dictionary.noMatchesFound' }"
              }
            ],
            staticStyle: { width: "100%" }
          }),
          _vm._v(" "),
          _vm.hasNoMatchesFoundSubTitle
            ? _c("div", {
                directives: [
                  {
                    name: "localization",
                    rawName: "v-localization",
                    value: { key: "common.dictionary.noMatchesFoundSubTitle" },
                    expression:
                      "{ key: 'common.dictionary.noMatchesFoundSubTitle' }"
                  }
                ],
                style: { width: "100%" }
              })
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }