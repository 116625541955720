var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "control-map",
      style: { width: _vm.width || "100%", height: _vm.height || "auto" }
    },
    [
      _vm.title
        ? _c("div", { staticClass: "column-title" }, [
            _vm._v(_vm._s(_vm.title))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "controlMap",
              rawName: "v-controlMap",
              value: {
                lat: _vm.lat,
                lng: _vm.long,
                hideMapType: _vm.hideMapType,
                hideStreetView: _vm.hideStreetView,
                maxZoom: _vm.maxZoom,
                defaultZoom: _vm.defaultZoom,
                gestureHandling: _vm.gestureHandling
              },
              expression:
                "{\n\t\t lat: lat,\n\t\t lng: long,\n\t\t hideMapType: hideMapType,\n\t\t hideStreetView: hideStreetView,\n\t\t maxZoom: maxZoom,\n\t\t defaultZoom: defaultZoom,\n\t\t gestureHandling\n\t\t}"
            }
          ],
          staticClass: "map-container",
          on: {
            wheel: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
            }
          }
        },
        [_vm._m(0)]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "scMap" }, [
      _c("div", { staticClass: "scMap-holder" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }