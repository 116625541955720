<template>
	<div
		v-show="enabled"
		v-data-attr="options"
		:class="['menu-item clickable', displayStyle]"
		clickBuble="false"
		@click="execute"
	>
		<span class="icon-wrapper">
			<svg class="svg-icon svg-20" v-show="icon">
				<use
					:xlink:href="'#action-icons-' + icon"
					:class="icon"
				/>
				<use
					:xlink:href="'#action-icons-' + icon + '-border'"
					:class="icon + '-border'"
				/>
				<use
					:xlink:href="'#action-icons-' + icon + '-partially'"
					:class="icon + '-border'"
				/>
			</svg>
		</span>
		<span class="title">
			{{ text }}
		</span>
	</div>
</template>
<script>
import '@/Bindings/ui-attribute.directive';
import { getBaseRouteUrl } from '@acx-xms/data-functions/dist';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'route-menu-item-svg',
	props: { options: Object },
	data() {
		return {
			text: '',
			enabled: this.enable,
			icon: null,
			isActive: this.options.default ? 'active' : ''
		};
	},
	watch: {
		$route(to) {
			this.isActive = to.params.componentSet === this.route || to.name === this.route ? 'active' : '';
		}
	},
	computed: {
		displayStyle() {
			return `${this._displayStyle} ${this.isActive}`;
		}
	},
	methods: {
		execute() {
			const page = this.$store.getters.getPaginationState(this.route);
			const path = page ? `${getBaseRouteUrl(this.$route)}/${this.route}?page=${page}` : `${getBaseRouteUrl(this.$route)}/${this.route}`;
			this.$router.push(path);
		}
	},
	async created() {
		this.context = createEvaluationContext();

		this.route = this.context.eval(this.options.action.route) || null;
		if (this.options.display === 'auto') {
			this._displayStyle = this.icon ? 'image' : 'text';
		} else {
			this._displayStyle = this.options.display;
		}
		this.text = this.options.text ? this.context.eval(this.options.text) : '';
		this.icon = this.options.icon ? this.context.eval(this.options.icon) : null;
		this.enabled = this.options.action.enable ? await this.context.evalAsync(this.options.action.enable) : true;
		this.isActive = this.$route.params.componentSet === this.route || this.$route.name === this.route ? 'active' : '';
	}
};
</script>
<style src="./route-menu-item.less" scoped />
