<template>
  <div
    v-data-attr="{ options: $parent.options, stateNamespace: 'input' }"
    :class="['search-field-wrapper inline-block', {
      focused: searchFocused
    }]"
  >
    <input
      v-model="searchQuery"
      v-localization="{ key: placeholderLocalizationKey, attrs: ['placeholder'] }"
      :maxlength="maxlength"
      type="text"
      class="search-box"
      @keyup.enter="search"
      @focus="searchFocused = true"
      @blur="searchFocused = false"
    />
    <div class="search-icon-wrapper">
      <svg class="svg-icon" @click="search">
        <use xlink:href="#layout-icons-ms-search" class="layout-icons-ms-search" />
      </svg>
      <svg
        v-show="searchQuery"
        class="svg-icon svg-20 clear-button"
        @click="clearSearchBox"
      >
        <use xlink:href="#layout-icons-textbox-delete" class="layout-icons-textbox-delete" />
      </svg>
    </div>
</div>
</template>
<script>
import '@/Bindings/ui-attribute.directive';

export default {
	name: 'search-field',
	props: {
		options: Object,
		maxlength: {
			type: Number,
			default: 255
		},
		searchString: {
			type: String,
			default: ''
		},
		placeholderLocalizationKey: {
			type: String,
			default: 'common.dictionary.search'
		}
	},
	data() {
		return {
			searchQuery: this.searchString || '',
			searchFocused: false
		};
	},
	created() {
		this.$parent.$on('set-search-query', (text) => {
			this.searchQuery = text;
		});
		if (this.options && this.options.debouncedWatchSearchQuery) {
			const debouncedSend = _.debounce(this.onSearchQueryChanged, this.options.debouncedWatchSearchQuery.delay);
			this.unwatch = this.$watch('searchQuery', () => {
				debouncedSend();
			});
		}
	},
	beforeDestroy() {
		if (this.unwatch) {
			this.unwatch();
		}
	},
	methods: {
		clearSearchBox() {
			this.searchQuery = '';
			this.$parent.$emit('clear-search-query');
		},
		search() {
			this.$parent.$emit('execute-search', this.searchQuery);
		},
		onSearchQueryChanged() {
			this.$parent.$emit('search-query-changed', this.searchQuery);
		}
	}
};
</script>
<style src="./search-field.less" scoped></style>
