export default class {
	constructor(deferred = false, requires = []) {
		this.deferred = deferred;
		this.requires = requires;
	}

	install() {
		throw new Error('install() should be implemented');
	}
}
