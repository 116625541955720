import { isActive, checkPrivilege } from './Methods/action.methods';

export default {
	async enabled(options, evaluationContext, selection) {
		return	selection.length > 0 &&
				await isActive(evaluationContext, selection) &&
				await checkPrivilege('staticlist', 'Write');
	},

	async execute(options, context, selection, fullSelection) {
		let sel = [];
		const title = await sc.classes.get('localization.dataProvider').getLabel('en', 'common.dictionary.saveStaticList');
		if (fullSelection && fullSelection.listing && fullSelection.listing.length) {
			fullSelection.listing.forEach(listing => {
				sel.push(listing);
				if (listing.Source.availabilities.length) {
					sel = sel.concat(listing.Source.availabilities);
				}
			});
		} else {
			sel = selection.concat((selection[0].Source.availabilities || []));
		}
		sc.events.emit('vue.dialog.open', {
			component: 'add-to-staticlist-dialog',
			icon: 'entity-icons-awx_adhoclist-small',
			maximizedWidth: '500px',
			maximizedHeight: '400px',
			title,
			params: {
				selection: sel,
				refreshNamespace: context.eval(options.refreshNamespace),
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey)
			}
		});
	}
};
