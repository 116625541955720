var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("action-menu-base", {
    attrs: {
      evaluationContext: _vm.context,
      menuItemsToolbar: _vm.toolbarActions,
      menuItemsDropdown: _vm.dropDownActions,
      hasDropDownItems: _vm.hasDropDownItems,
      shareActions: _vm.shareActions,
      hasShareActions: _vm.hasShareActions,
      combinedNamespace: _vm.combinedNamespace
    },
    on: { execute: _vm.executeCallback }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }