export default {
	parameters(options) {
		return [
			this.eval(options.argument),
			options.from,
			options.to
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument),
			options.from,
			options.to
		];
	},
	// By default if no from or to where specified it capitalizes first letter
	evaluate(s, from, to) {
		if (typeof s !== 'string' || from > to) return '';
		return s.slice(0, from) + s.slice(from, to).toUpperCase() + s.slice(to, s.length);
	}
};
