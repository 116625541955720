<template>
	<div class="dashboard-wrapper">
		<div class="content">
			<div class="header">
				<div class="h1">Dashboard</div>
				<div class="period-wrapped" :class="isLoading && 'disabled'" v-if="datePeriod" v-on="!isLoading ? { click: OnPeriodClick } : {}">
					<svg class='svg-icon svg-20'>
						<use xlink:href="#layout-icons-calendar-marker"></use>
					</svg>
					<span>{{datePeriod.title}}</span>
					<svg class="svg-icon svg-10" ref="opener">
						<use xlink:href="#layout-icons-ms-arrow-down"></use>
					</svg>
				</div>
			</div>
			<div class="content-scroll-wrapper" v-perfectscroll="{enable: true}">
				<div class="totals-wrapper" v-if="!trendsLoading">
					<div class="totals-pair">
						<total-counter entity="inquiry" :color="color17" label="inquiries" :filters="inquiryLogicalFilter()"></total-counter>
						<total-counter entity="collaborationroom" :color="color14" label="collaboration rooms" :filters="getOwnRecordsFilter()"></total-counter>
					</div>
					<div class="totals-pair">
						<total-counter entity="dealroom" :color="color15" label="deal rooms" :filters="getDRFilters(getOwnRecordsFilter())"></total-counter>
						<total-counter entity="chatparticipant" :color="color16" label="invitees" :filters="getInviteesFilter()"></total-counter>
					</div>
				</div>
				<div class="charts-wrapper" v-if="!trendsLoading && !isLoading">
					<div class="chart-wrapper">
						<div class="header">New Inquiries</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="inquiryData" :options="options" :styles="chartStyles" :chartName="'bar-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">Top Listings by Number of Inquiries</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="inquiryDoughnutData" :options="doughnutOptions" :styles="chartStyles" :chartName="'doughnut-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">New Collaboration Rooms</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="croomData" :options="options" :styles="chartStyles" :chartName="'bar-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">New Deal Rooms</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="droomData" :options="options" :styles="chartStyles" :chartName="'bar-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">New Participants in Collaboration Rooms</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="croomParticipantsData" :options="options" :styles="chartStyles" :chartName="'bar-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">New Participants in Deal Rooms</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="droomParticipantsData" :options="options" :styles="chartStyles" :chartName="'bar-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">Top 7 Listings by Collaboration Rooms</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="croomDoughnutData" :options="doughnutOptions" :styles="chartStyles" :chartName="'doughnut-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">Top 7 Listings by Deal Rooms</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="droomDoughnutData" :options="doughnutOptions" :styles="chartStyles" :chartName="'doughnut-chart'"></chart-wrapper>
						</div>
					</div>
				</div>
				<div class="loading-wrapper" v-if="isLoading || trendsLoading">
					<h2>Loading charts...</h2>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import DashboardMixin from './dashboard.mixin';
import PeriodBehaviors from './date-period-behaviors';

export default {
	name: 'broker-dashboard',
	mixins: [DashboardMixin],
	data() {
		return { trendsLoading: false };
	},
	methods: {
		inquiryLogicalFilter() {
			const logicalFilter = {
				$type: 'search.logicalFilter',
				operator: {
					$type: 'search.or',
					operator: [
						{
							$type: 'search.operand',
							filter: [
								{
									$type: 'search.termFacetFilter',
									item: [
										{
											$type: 'search.termFacetItem',
											value: [this.systemuserid]
										}],
									logicalName: 'inquirylistingbroker1idcontact.systemuserid.id'
								}]
						},
						{
							$type: 'search.operand',
							filter: [
								{
									$type: 'search.termFacetFilter',
									item: [
										{
											$type: 'search.termFacetItem',
											value: [this.systemuserid]
										}],
									logicalName: 'inquirylistingbroker2idcontact.systemuserid.id'
								}]
						},
						{
							$type: 'search.operand',
							filter: [
								{
									$type: 'search.termFacetFilter',
									item: [
										{
											$type: 'search.termFacetItem',
											value: [this.systemuserid]
										}],
									logicalName: 'inquirylistingbroker3idcontact.systemuserid.id'
								}]
						}

					]
				}
			};
			return [sc.classes.get('search.logicalFilter', logicalFilter).toFilter()];
		},
		async OnPeriodChange(period) {
			this.datePeriod = period;
			const behavior = PeriodBehaviors.GetBehavior(this.datePeriod);
			const dateBounds = behavior.GetDateBounds();
			this.isLoading = true;
			const promises = [];

			promises.push(this.loadEntityStats(dateBounds, ['inquiry'], this.inquiryLogicalFilter()).then(inquiries => {
				this.inquiryData = behavior.GroupData(inquiries, this.color17);
			}));

			promises.push(this.getCommonData(dateBounds, this.getOwnRecordsFilter()).then(records => {
				const collaborationrooms = records.filter((record) => { return record.Type === 'collaborationroom'; });
				this.croomData = behavior.GroupData(collaborationrooms, this.color18); // ok

				const dealrooms = records.filter((record) => { return record.Type === 'dealroom'; });
				this.droomData = behavior.GroupData(dealrooms, this.color19);
			}));

			promises.push(this.loadParticipants(dateBounds, this.getOwnRecordsFilter()).then(participants => {
				this.croomParticipantsData = behavior.GroupData(participants.crooms, this.color18);
				this.droomParticipantsData = behavior.GroupData(participants.drooms, this.color19);
			}));

			promises.push(this.loadEntityRelations({
				facetName: 'inquirylistingidlisting.name',
				entity: 'inquiry'
			}, this.inquiryLogicalFilter()).then(inquiryDoughnutData => {
				this.inquiryDoughnutData = inquiryDoughnutData;
			}));

			promises.push(this.loadEntityRelations({
				facetName: 'crlistinglistingidlisting.name',
				entity: 'crlisting'
			}, this.getOwnRecordsFilter('crlistingcollaborationroomidcollaborationroom')).then(croomDoughnutData => {
				this.croomDoughnutData = croomDoughnutData;
			}));

			promises.push(this.loadEntityRelations({
				facetName: 'dealroomlistingidlisting.name',
				entity: 'dealroom'
			}, this.getOwnRecordsFilter()).then(droomDoughnutData => {
				this.droomDoughnutData = droomDoughnutData;
			}));

			try {
				await Promise.all(promises);
			} catch (e) {
				sc.utils.errorMessage.byResponse(e);
			} finally {
				this.isLoading = false;
			}
		},

		getInviteesFilter() {
			const filters = [
				sc.classes.get('search.termFacetFilter', {
					logicalName: 'chatparticipantchatidchat.recordstate.id',
					item: [{
						$type: 'search.termFacetItem',
						value: '8d113fa8-3015-4060-a107-14cedcd19dd3'
					}]
				}, null).toFilter(),
				sc.classes.get('search.termFacetFilter', {
					logicalName: 'recordstate.id',
					item: [{
						$type: 'search.termFacetItem',
						value: '8d113fa8-3015-4060-a107-14cedcd19dd3'
					}]
				}, null).toFilter()
			];

			return [...filters, ...this.getOwnRecordsFilter()];
		},

		getOwnRecordsFilter(prefix) {
			let createdbyName = 'createdby.id';
			let owneridName = 'ownerid.id';
			if (prefix) {
				createdbyName = `${prefix}.${createdbyName}`;
				owneridName = `${prefix}.${owneridName}`;
			}
			const logicalFilter = {
				$type: 'search.logicalFilter',
				operator: {
					$type: 'search.or',
					operator: [
						{
							$type: 'search.operand',
							filter: [
								{
									$type: 'search.termFacetFilter',
									item: [
										{
											$type: 'search.termFacetItem',
											value: [this.systemuserid]
										}],
									logicalName: createdbyName
								}]
						},
						{
							$type: 'search.operand',
							filter: [
								{
									$type: 'search.termFacetFilter',
									item: [
										{
											$type: 'search.termFacetItem',
											value: [this.systemuserid]
										}],
									logicalName: owneridName
								}]
						}

					]
				}
			};

			return [sc.classes.get('search.logicalFilter', logicalFilter).toFilter()];
		}
	}
};
</script>
<style src="./dashboard.less" scoped></style>
