var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showSelectedCount
    ? _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.selectedText,
              expression: "selectedText"
            }
          ],
          staticClass: "selected-records-text mobile"
        },
        [_vm._v("\n\t" + _vm._s(_vm.selectedText) + "\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }