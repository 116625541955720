<template>
	<counter v-if="count" :count="count"></counter>
</template>
<script>
const Counter = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './counter');
export default {
	name: 'unreadmessagescounter',
	props: {
		options: Object,
		evaluationContext: Object
	},
	computed: {
		count() {
			return (this.$store.state.counters &&
					this.$store.state.counters[this.state] &&
					this.$store.state.counters[this.state][this.chatid] &&
					this.$store.state.counters[this.state][this.chatid].counter) || 0;
		}
	},
	created() {
		this.chatid = this.evaluationContext.eval(this.options.chatid);
		this.state = this.evaluationContext.eval(this.options.state) || 'counters';
	},
	components: { Counter }
};
</script>
