import ScMapGoogleMediator from './scMap.google.mediator';
import {
	isObject, isString, isNumber
} from '@acx-xms/data-functions/dist';

export default class {
	constructor(map, options) {
		if (!map) {
			throw new Error('scMap.marker.init: `map` property is not defined');
		}
		this.map = map;
		this.icons = { default: void 0 };
		this.options = {
			title: void 0,
			location: void 0,
			icon: 'default'
		};
		this.__validateAndSetupOptions(options);
		const markerOptions = {
			title: this.options.title,
			map: this.map._map,
			position: new google.maps.LatLng(this.options.location.lat, this.options.location.lng)
		};

		if (this.icons[this.options.icon]) {
			markerOptions.icon = { url: this.icons[this.options.icon].url };
		}

		this._marker = new google.maps.Marker(markerOptions);
		this.setListners();

		this.mediator = new ScMapGoogleMediator({
			target: this._marker,
			context: this
		});

		this.mediator.bubbleEventsTo(this.map.mediator, ['mousedown']);
	}

	__validateIconProp(icon) {
		return isObject(icon) && isString(icon.url) && isNumber(icon.width) && isNumber(icon.height);
	}

	__validateAndSetupOptions(options) {
		if (isString(options.title)) {
			this.options.title = options.title;
		}

		if (!isObject(options.location)) {
			console.error('scMap.marker.init: `location` property is not defined');
		} else if (!isNumber(options.location.lat) || !isNumber(options.location.lng)) {
			console.error('scMap.marker.init: Invalid `location` property');
		}

		this.options.location = options.location;

		if (isObject(options.icons)) {
			Object.keys(options.icons).forEach(key => {
				if (this.__validateIconProp(options.icons[key])) {
					this.icons[key] = {
						url: options.icons[key].url,
						width: options.icons[key].width,
						height: options.icons[key].height
					};
				}
			});
		}

		if (isString(options.icon)) {
			if (this.__validateIconProp(this.icons[options.icon])) {
				this.options.icon = options.icon;
			}
		}

		if (isObject(options.tooltip)) {
			if (options.tooltip.content) {
				this.tooltip.content = options.tooltip.content;
			}

			if (isString(options.tooltip.showOn)) {
				this.tooltip.showOn = options.tooltip.showOn;
			}
		}

		this.meta = options.meta;
	}

	setIcon(iconName) {
		const icon = this.icons[iconName];

		if (!icon) {
			console.warn('scMap.marker.setIcon: Icon `' + iconName + '` is not defined');
			return;
		}

		this.options.icon = iconName;

		this._marker.setIcon({ url: icon.url });
	}

	getLocation() {
		const latLng = this._marker.getPosition();

		return {
			lat: latLng.lat(),
			lng: latLng.lng()
		};
	}

	getListeners(marker, listeners) {
		return listeners.filter(listener => google.maps.event.hasListeners(marker, listener));
	}

	setListners(isSelected = false) {
		const listeners = this.getListeners(this._marker, ['mouseover', 'mouseout']);
		listeners.forEach(listener => google.maps.event.clearListeners(this._marker, listener));

		if (isSelected) {
			this._marker.addListener('mouseover', () => this.setIcon('selectedHover'));
			this._marker.addListener('mouseout', () => this.setIcon('selected'));
		} else {
			this._marker.addListener('mouseover', () => this.setIcon('hover'));
			this._marker.addListener('mouseout', () => this.setIcon('default'));
		}
	}
}
