export default {
	props: {
		stateNamespace: String,
		showSelectedCount: Boolean,
		targetEntity: Array,
		overridedNames: Object,
		withParent: {
			/*
				prop for showing children selection with parent selection
				if false - show selections independently from each other
			*/
			type: Boolean,
			default: true
		}
	},
	data() {
		return { selectedText: '' };
	},
	computed: {
		selection() {
			return this.$store.getters[this.stateNamespace + '/getSelection'];
		}
	},
	watch: {
		selection: {
			handler: async function (newVal) {
				const parentTextArr = [];
				for (const entity of this.targetEntity) {
					const count = newVal[entity] ? Object.keys(newVal[entity]).length : 0;
					if (this.withParent && !count) { /* only if parent needed and has no selection */
						this.selectedText = null;
						return;
					} else if (count) { /* only if has selection */
						const entityName = await this.getSelectedEntitiesCaption(entity, count);
						parentTextArr.push([count, entityName]);
					}
				}
				let parentText = null;
				if (parentTextArr.length) {
					parentText = parentTextArr.length === 1
						? await this.localizationDataProvider.getLabelForCurrentLanguage('mainLayout.searchResult.selectedTextParent', parentTextArr[0])
						: await this.localizationDataProvider.getLabelForCurrentLanguage('mainLayout.searchResult.selectedMultipleTextParent', parentTextArr.reduce((acc, val) => acc.concat(val), []));
				}
				const childrenEntities = Object.keys(newVal).filter((logicalName) => {
					return this.targetEntity.indexOf(logicalName) === -1;
				});
				const firstChild = childrenEntities.length ? childrenEntities[0] : null;
				if (firstChild) {
					const children = newVal[firstChild] ? Object.keys(newVal[firstChild]).length : 0;
					if (children > 0) {
						const childEntity = await this.getSelectedEntitiesCaption(newVal[firstChild][Object.keys(newVal[firstChild])[0]].Type, children);
						const childText = await this.localizationDataProvider.getLabelForCurrentLanguage('mainLayout.searchResult.selectedTextChild', [children, childEntity]);
						this.selectedText = childText + ' ' + parentText;
						return;
					}
				}
				this.selectedText = parentText;
			},
			deep: true
		}
	},
	created() {
		this.localizationDataProvider = sc.classes.get('localization.dataProvider');
		this.entityConfigurationDataProvider = sc.classes.get('entityConfiguration.dataProvider');
	},
	methods: {
		async getSelectedEntitiesCaption(type, count) {
			const number = count === 1 ? 'singular' : 'plural';
			return (this.overridedNames && this.overridedNames[number]) || (await this.entityConfigurationDataProvider.getEntityCaptions([type], number))[0];
		}
	}
};
