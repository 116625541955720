<template>
	<div class="smart-image-gallery-mobile"
		 v-bind:class="{'not-clickable':withExtendedGallery}"
		 v-bind:style="{ width, height }"
		 @click="openDialog">
		<div class="info-message" v-if="!parentEntityLogicalName && items.length == 0">
			<b class="info-message-title" data-bind="localization: { key: 'imageGallery.noDataMessage'}"></b>
		</div>
		<control-image-gallery-small-mobile v-else-if="galleryOptions" v-bind="{ evaluationContext, options: galleryOptions }"></control-image-gallery-small-mobile>
		<i class="loading-overlay" v-show="isLoading"></i>
	</div>
</template>
<script>
import SmartImageGalleryMixin from './smart-image-gallery.mixins.js';
const ControlImageGallerySmallMobile = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../control-image-gallery-small-mobile');

export default {
	name: 'smart-image-gallery',
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: { ControlImageGallerySmallMobile },
	mixins: [SmartImageGalleryMixin],
	computed: {
		current() {
			return (this.items.length > 0)
				? {
					src: this.items[this.currentIndex].preview,
					width: this.width,
					height: this.height,
					imageFit: this.options.imageFitType
				}
				: null;
		}
	},
	data() {
		return {
			withExtendedGallery: this.evaluationContext.eval(this.options.withExtendedGallery),
			items: [],
			currentIndex: 0,
			isLoading: true,
			parentEntityLogicalName: this.options.parentLogicalName ? this.evaluationContext.eval(this.options.parentLogicalName) : '',
			galleryOptions: null,
			parentRef: null
		};
	},
	created() {
		this.parentRef = this.evaluationContext.eval(this.options.parentRef);
		this.parentEntityLogicalName = this.parentEntityLogicalName || (this.parentRef || {}).logicalname;
		this.width = this.options.width;
		this.height = this.options.height;
		this.targetEntityName = this.evaluationContext.eval(this.options.targetEntityName);
		this.updateImages();
		this.fork = sc.events.fork();
		// todo remove after migrate to vue events
		this.fork.on(['entity.changed', 'entity.created', 'entity.deleted'], (data) => {
			if (data.entity.logicalname === this.targetEntityName) {
				this.updateImages();
			}
		});
	},
	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
		}
	},
	methods: {
		async updateImages() {
			this.isLoading = true;
			this.setImages([]);
			this.galleryOptions = null;
			const dataSources = this.options.dataSources.map((provider) => {
				return sc.classes.get(provider.$type, provider, this.evaluationContext, [this.targetEntityName], this.options.subtype);
			});
			for (let i = 0; i < dataSources.length; i++) {
				await dataSources[i].search().done((response) => {
					if (response.hasData) {
						this.setImages(response.data);
					} else {
						this.setImages([]);
					}
				});
			}
		},
		setImages: function (images) {
			this.items = images;
			this.galleryOptions = {
				imageHeight: this.height,
				placeholderEntityName: this.parentEntityLogicalName,
				items: images,
				showCounter: true
			};
			this.isLoading = false;
			this.$root.$emit('imageGalleryUpdate', this.items);
		}

	}
};
</script>
<style src="./smart-image-gallery-mobile.less" scoped></style>
