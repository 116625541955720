<template>
	<div class="dashboard-wrapper">
		<div class="content">
			<div class="header">
				<div class="h1">Admin Dashboard</div>
				<div class="period-wrapped" :class="isLoading && 'disabled'" v-if="datePeriod" v-on="!isLoading ? { click: OnPeriodClick } : {}">
					<svg class='svg-icon svg-20'>
						<use xlink:href="#layout-icons-calendar-marker"></use>
					</svg>
					<span>{{datePeriod.title}}</span>
					<svg class="svg-icon svg-10" ref="opener">
						<use xlink:href="#layout-icons-ms-arrow-down"></use>
					</svg>
				</div>
			</div>
			<div class="content-scroll-wrapper" v-perfectscroll="{enable: true}">
				<div class="totals-wrapper">
					<div class="totals-pair">
						<total-counter entity="listing" :color="color14" label="listings" :filters="getListingFilters()"></total-counter>
						<total-counter entity="availability" :color="color15" label="availabilities"></total-counter>
					</div>
					<div class="totals-pair">
						<total-counter entity="inquiry" :color="color17" label="inquiries"></total-counter>
						<total-counter entity="contact" :color="color16" label="contacts"></total-counter>
					</div>
					<div class="totals-pair">
						<total-counter entity="collaborationroom" :color="color18" label="collaboration rooms"></total-counter>
						<total-counter entity="dealroom" :color="color19" label="deal rooms" :filters="getDRFilters()"></total-counter>
					</div>
					<div class="totals-pair">
						<total-counter entity="systemuser" custom-icon="#action-icons-ms-menu-guestusers" :color="color20" label="guests" :filters="getUsersFilters('Guests')"></total-counter>
						<total-counter entity="systemuser" :color="color14" label="Internal Users" :filters="getUsersFilters('Brokers')"></total-counter>
					</div>
				</div>
				<div class="loading-wrapper" v-if="isLoading">
					<h2>Loading charts...</h2>
				</div>
				<div class="charts-wrapper" v-else>
					<div class="chart-wrapper">
						<div class="header">New Inquiries</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="inquiryData" :options="options" :styles="chartStyles" :chartName="'bar-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">Top Listings by Number of Inquiries</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="inquiryDoughnutData" :options="doughnutOptions" :styles="chartStyles" :chartName="'doughnut-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">New Listings</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="listingData" :options="options" :styles="chartStyles" :chartName="'bar-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">New Guests</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="guestData" :options="options" :styles="chartStyles" :chartName="'bar-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">New Collaboration Rooms</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="croomData" :options="options" :styles="chartStyles" :chartName="'bar-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">New Deal Rooms</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="droomData" :options="options" :styles="chartStyles" :chartName="'bar-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">New Participants in Collaboration Rooms</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="croomParticipantsData" :options="options" :styles="chartStyles" :chartName="'bar-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">New Participants in Deal Rooms</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="droomParticipantsData" :options="options" :styles="chartStyles" :chartName="'bar-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">Top 7 Listings by Collaboration Rooms</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="croomDoughnutData" :options="doughnutOptions" :styles="chartStyles" :chartName="'doughnut-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">Top 7 Listings by Deal Rooms</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="droomDoughnutData" :options="doughnutOptions" :styles="chartStyles" :chartName="'doughnut-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">Users Activity</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="userActivityData" :options="horizontalBarChartOptions" :styles="horizontalBarStyles" :chartName="'horizontal-bar-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">Top 7 Guests by Activity</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="topActiveGuests" :options="doughnutOptions" :styles="chartStyles" :chartName="'doughnut-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">Top 7 Collaboration Rooms by Guests Attention</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="topCRsByGuest" :options="doughnutOptions" :styles="chartStyles" :chartName="'doughnut-chart'"></chart-wrapper>
						</div>
					</div>
					<div class="chart-wrapper">
						<div class="header">Top 7 Deal Rooms by Guests Attention</div>
						<div class="chart-component-wrapper">
							<chart-wrapper :data="topDRsByGuest" :options="doughnutOptions" :styles="chartStyles" :chartName="'doughnut-chart'"></chart-wrapper>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import DashboardMixin from './dashboard.mixin';
import PeriodBehaviors from './date-period-behaviors';

export default {
	name: 'dashboard',
	mixins: [DashboardMixin],
	data() {
		return {
			listingData: null,
			guestData: null,
			userActivityData: null,
			topActiveGuests: null,
			topCRsByGuest: null,
			topDRsByGuest: null
		};
	},
	methods: {
		async loadListings(bounds) {
			const entities = ['listing'];
			const filters = [];

			if (bounds) {
				const dateFilter = sc.classes.get('search.dateFacetFilter', {
					logicalName: 'createdon',
					item: [{
						$type: 'search.dateFacetItem',
						min: bounds.from,
						max: bounds.to
					}],
					includeEmpty: false
				}, null).toFilter();

				filters.push(dateFilter);
			}

			filters.push(this.sizeFilter);
			filters.push(sc.classes.get('selectedFields.filter', [{ logicalname: 'createdon' }]));
			const resp = await sc.classes.get('edge.dataProvider').search({
				filters: filters.concat(this.getListingFilters()),
				entities,
				withDefaultFilters: false
			});

			return resp.Results;
		},
		getListingFilters() {
			const filters = [];
			const removedFilter = sc.classes.get('search.termFacetFilter', {
				logicalName: 'isremoved',
				item: [{
					$type: 'search.termFacetItem',
					value: 'True'
				}],
				negative: true
			}, null);

			filters.push(removedFilter.toFilter());

			const dummyFilter = sc.classes.get('search.termFacetFilter', {
				logicalName: 'dummy',
				item: [{
					$type: 'search.termFacetItem',
					value: 'True'
				}],
				negative: true
			}, null);

			filters.push(dummyFilter.toFilter());

			return filters;
		},
		getUsersFilters(group) {
			const filters = [];

			const groupFilter = sc.classes.get('search.termFacetFilter', {
				logicalName: 'systemusergroupidgroup.name',
				item: [{
					$type: 'search.termFacetItem',
					value: group
				}]
			}, null);

			filters.push(groupFilter.toFilter());

			const disabledFilter = sc.classes.get('search.termFacetFilter', {
				logicalName: 'isdisabled',
				item: [{
					$type: 'search.termFacetItem',
					value: 'false'
				}]
			}, null);

			filters.push(disabledFilter.toFilter());

			return filters;
		},
		async loadGuests(bounds) {
			const dateFilter = sc.classes.get('search.dateFacetFilter', {
				logicalName: 'createdon',
				item: [{
					$type: 'search.dateFacetItem',
					min: bounds.from,
					max: bounds.to
				}],
				includeEmpty: false
			}, null).toFilter();
			const groupFilter = sc.classes.get('search.termFacetFilter', {
				logicalName: 'systemusergroupidgroup.name',
				item: [{
					$type: 'search.termFacetItem',
					value: 'Guests'
				}]
			}, null).toFilter();
			const selectedFieldsFilter = sc.classes.get('selectedFields.filter', [
				{ logicalname: 'createdon' },
				{ logicalname: 'systemusergroupidgroup.name' }
			]);
			const resp = await sc.classes.get('edge.dataProvider').search({
				filters: [dateFilter, groupFilter, this.sizeFilter, selectedFieldsFilter],
				entities: ['systemuser'],
				withDefaultFilters: false
			});
			return resp.Results;
		},
		async loadTopGuestsActivity(facetLookup, idsEntity, nameField = 'name') {
			const facetName = facetLookup;
			const entity = 'useractivity';
			const additionalFilters = [
				sc.classes.get('termFacet.filter', {
					logicalName: 'securitygroup',
					query: ['Guest']
				}),
				sc.classes.get('termFacet.filter', {
					logicalName: 'targetrecordlogicalname',
					query: [idsEntity]
				})
			];
			const resp = await this.loadStatistic(facetName, entity, additionalFilters);
			const data = [];
			const labels = [];
			const promiseArr = [];

			resp.TermFacets.filter(facet => facet.LogicalName === facetName)[0].Items.forEach((item) => {
				promiseArr.push(
					sc.classes.get('edge.dataProvider').search({
						filters: [
							sc.classes.get('offsetSize.filter', 1),
							sc.classes.get('selectedFields.filter', [
								{ logicalname: 'name' },
								{ logicalname: 'fullname' }
							]),
							sc.classes.get('termFacet.filter', {
								logicalName: `${idsEntity}id`,
								query: [item.Term]
							})
						],
						entities: [idsEntity],
						withDefaultFilters: false
					})
				);
				data.push(item.Count);
			});

			const facetItemsData = await Promise.all(promiseArr);
			facetItemsData.forEach(({ Results: data }) => data.length && labels.push(data[0].Source[nameField]));

			return {
				labels,
				datasets: [
					{
						backgroundColor: this.doughnutColors,
						data
					}
				]
			};
		},
		async loadUserActivityData() {
			const facetName = 'name';
			const entity = 'useractivity';
			const colors = [this.color19, this.color16, this.color15];
			const labelMapping = {
				'login.successful': 'Logged in',
				'token.refresh': 'Token Refresh',
				'details.opened.listing': 'Listings Viewed',
				'details.opened.collaborationroom': 'Col. Rooms Entered',
				'details.opened.dealroom': 'Deal Rooms Entered ',
				'imagegallery.open': 'Images Gallery Open',
				'imagegallery.navigate.next': 'Image Navigate',
				'download.document': 'Downloads'
			};

			let labels = Object.keys(labelMapping);
			const getGroupsStatistic = async () => {
				const groups = ['Broker', 'Guest', 'Anonymous User'];
				const promises = [];
				groups.forEach(g => {
					const resp = this.loadStatistic(
						facetName,
						entity,
						[
							sc.classes.get('termFacet.filter', {
								logicalName: 'securitygroup',
								query: [g]
							})
						],
						100
					).then(response => {
						return {
							group: g,
							response
						};
					});
					promises.push(resp);
				});
				const sortLablesByBrokerActivity = (groupsStatistic) => {
					if (groupsStatistic && groupsStatistic.length) {
						const brokerData = groupsStatistic[0];
						if (brokerData.response.TermFacets.length) {
							const borkerTopActivities = brokerData.response
								.TermFacets[0].Items.map(x => x.Term)
								.filter(f => labels.indexOf(f) !== -1);

							labels.forEach(lbl => {
								if (borkerTopActivities.indexOf(lbl) === -1) {
									borkerTopActivities.push(lbl);
								}
							});

							labels = borkerTopActivities;
						}
					};
				};

				const groupsStatistic = await Promise.all(promises);
				sortLablesByBrokerActivity(groupsStatistic);
				return groupsStatistic;
			};

			const groupsStatistic = await getGroupsStatistic();
			const datasets = [];
			groupsStatistic.forEach((statistic, i) => {
				const data = [];
				labels.forEach(label => {
					const facetItem = statistic.response.TermFacets.filter(facet => facet.LogicalName === facetName)[0].Items.find((item) => {
						return item.Term === label;
					});

					if (facetItem) {
						data.push(facetItem.Count);
					} else {
						data.push(0);
					}
				});

				datasets.push({
					label: statistic.group,
					backgroundColor: colors[i],
					data
				});
			});

			return {
				labels: labels.map(l => labelMapping[l] || l),
				datasets
			};
		},
		async OnPeriodChange(period) {
			this.datePeriod = period;
			const behavior = PeriodBehaviors.GetBehavior(this.datePeriod);
			const dateBounds = behavior.GetDateBounds();
			this.isLoading = true;
			const promises = [];

			promises.push(this.getCommonData(dateBounds).then(records => {
				const collaborationrooms = records.filter((record) => { return record.Type === 'collaborationroom'; });
				this.croomData = behavior.GroupData(collaborationrooms, this.color18);

				const dealrooms = records.filter((record) => { return record.Type === 'dealroom'; });
				this.droomData = behavior.GroupData(dealrooms, this.color19);
			}));
			promises.push(this.loadEntityStats(dateBounds, ['inquiry']).then(inquiries => {
				this.inquiryData = behavior.GroupData(inquiries, this.color17);
			}));
			promises.push(this.loadEntityStats(dateBounds, ['listing'], this.getListingFilters()).then(listings => {
				this.listingData = behavior.GroupData(listings, this.color14);
			}));
			promises.push(this.loadGuests(dateBounds).then((guests) => {
				this.guestData = behavior.GroupData(guests, this.color20);
			}));

			promises.push(this.loadParticipants(dateBounds).then(participants => {
				this.croomParticipantsData = behavior.GroupData(participants.crooms, this.color18);
				this.droomParticipantsData = behavior.GroupData(participants.drooms, this.color19);
			}));

			promises.push(this.loadEntityRelations({
				facetName: 'crlistinglistingidlisting.name',
				entity: 'crlisting'
			}).then(croomDoughnutData => {
				this.croomDoughnutData = croomDoughnutData;
			}));

			promises.push(this.loadEntityRelations({
				facetName: 'dealroomlistingidlisting.name',
				entity: 'dealroom'
			}).then(droomDoughnutData => {
				this.droomDoughnutData = droomDoughnutData;
			}));

			promises.push(this.loadEntityRelations({
				facetName: 'inquirylistingidlisting.name',
				entity: 'inquiry'
			}).then(inquiryDoughnutData => {
				this.inquiryDoughnutData = inquiryDoughnutData;
			}));

			promises.push(this.loadTopGuestsActivity('systemuserid.id', 'systemuser', 'fullname').then(topActiveGuests => {
				this.topActiveGuests = topActiveGuests;
			}));

			promises.push(this.loadTopGuestsActivity('targetrecordid', 'collaborationroom').then(topCRsByGuest => {
				this.topCRsByGuest = topCRsByGuest;
			}));

			promises.push(this.loadTopGuestsActivity('targetrecordid', 'dealroom').then(topDRsByGuest => {
				this.topDRsByGuest = topDRsByGuest;
			}));

			promises.push(this.loadUserActivityData().then(userActivityData => {
				this.userActivityData = userActivityData;
			}));

			try {
				await Promise.all(promises);
			} catch (e) {
				sc.utils.errorMessage.byResponse(e);
			} finally {
				this.isLoading = false;
			}
		}
	}
};
</script>
<style src="./dashboard.less" scoped></style>
