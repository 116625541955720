<template>
	<div class="coauth-dialog">
		<div class="loading-overlay large"></div>
	</div>
</template>
<script>
import { Set } from '@/Data/DataProviders/localStorageDataProvider';

export default {
	name: 'coauth-dialog',
	created() {
		if (this.$route.query.token) {
			Set([this.$route.params.clusterKey.toLowerCase(), this.$route.params.id, this.$route.params.config, 'co-auth-key'].join('_'), this.$route.query.token);
			if (this.$route.params.mci === 'mci') {
				Set([this.$route.params.clusterKey.toLowerCase(), this.$route.params.id, this.$route.params.config, 'mci-auth'].join('_'), true);
			}
		} else {
			Set([this.$route.params.clusterKey.toLowerCase(), this.$route.params.id, this.$route.params.config, 'mci-auth'].join('_'), true);
		}
		window.close();
	}
};
</script>
