import { isNullOrUndefined, isArray } from '@acx-xms/data-functions/dist';

export default {
	parameters(options) {
		return [
			this.eval(options.argument)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument)
		];
	},
	evaluate(array) {
		if (isNullOrUndefined(array)) {
			return 0;
		}
		if (!isArray(array)) {
			throw new Error('expression.array.sum: Argument is not an array');
		}
		return array.reduce((result, item) => {
			item = parseFloat(item);
			if (isNaN(item)) {
				return result;
			}
			return result + item;
		}, 0);
	}
};
