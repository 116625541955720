<template>
	<!--tooltip-->
	<div
		v-data-attr="{ options }"
		:class="['menu-item clickable', displayStyle]"
	>
		<span class="icon-wrapper">
			<svg v-if="icon" class="svg-icon svg-20">
				<use
					:xlink:href="`#action-icons-${icon}`"
					:class="icon"
				/>
				<use
					:xlink:href="`#action-icons-${icon}-border`"
					:class="`${icon}-border`"
				/>
				<use
					:xlink:href="`#action-icons-${icon}-partially`"
					:class="`${icon}-border`"
				/>
			</svg>
		</span>
		<span v-if="text" class="title">
			{{ text }}
		</span>
	</div>
</template>
<script>
import '@/Bindings/ui-attribute.directive';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';

export default {
	name: 'menu-item',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			text: '',
			icon: (this.options.icon) ? helperMethods.eval(this.evaluationContext, this.options.icon) : '',
			displayStyle: null
		};
	},
	async created() {
		this.text = (this.options.text) ? await helperMethods.evalAsync(this.evaluationContext, this.options.text) : '';
		if (this.options.display === 'auto') {
			this.displayStyle = this.icon ? 'image' : 'text';
		} else {
			this.displayStyle = this.options.display;
		}
	}
};
</script>
