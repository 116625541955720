<template>
	<div class="logo-wrapper">
		<div :class="{'logo': true, 'iconsOnly': true, 'active': state==='iconsOnly'}">
			<svg class="iconsOnly" v-show="!smallLogoUrl">
				<use :xlink:href="'#tenant-logo-' + logoIcon +'-compact'"></use>
			</svg>
			<image-wrapper :url="smallLogoUrl" v-if="smallLogoUrl" width="81px" height="80px" fit="contain"></image-wrapper>
		</div>

		<div :class="{'logo': true, 'active': state!=='iconsOnly'}">
			<svg class="iconsOnly" v-show="!bigLogoUrl">
				<use :xlink:href="'#tenant-logo-' + logoIcon"></use>
			</svg>
			<image-wrapper :alt="alt" :url="bigLogoUrl" v-if="bigLogoUrl" width="100%" height="80px" fit="cover"></image-wrapper>
		</div>
	</div>
</template>
<script>
import ImageWrapper from '@/Components/Common/image-wrapper';
import { formLogoUrl } from '@acx-xms/data-functions/dist';
export default {
	name: 'tenant-logo',
	props: {
		state: String,
		logoIcon: String,
		alt: String
	},
	components: { ImageWrapper },
	data() {
		return { isLogoLoading: true };
	},
	computed: {
		bigLogoUrl() {
			return this.$store.getters.logobigid ? formLogoUrl(this.$store.getters.logobigid) : null;
		},
		smallLogoUrl() {
			return this.$store.getters.logosmallid ? formLogoUrl(this.$store.getters.logosmallid) : null;
		}
	},
	methods: {
		onLogoLoaded() {
			this.isLogoLoading = false;
		}
	}
};
</script>
<style src="./tenant-logo.less" scoped></style>
