/* eslint no-unused-vars: 0 */
import Vue from 'vue';
import router from './router';
import store from './States/ms-state';
import AppWrapper from './AppWrapper';
import ClickOutsideDirective from './Bindings/clickoutside.directive';
import draggable from 'vuedraggable';
import { ParselinksDirective } from '@acx-xms/components/dist';
// import _ from 'lodash';

new Vue({
	router,
	store,
	render: (h) => h(AppWrapper)
}).$mount('#page-wrapper');

Vue.component('draggable', draggable);
Vue.directive('parselinks', ParselinksDirective);
Vue.directive('parselinks', ParselinksDirective);
