<template>
	<div class="ms-panel ms-conversations-panel popup-shaded">
		<div v-if="isLoading" class="loading-overlay" />
		<div class="panel-list">
			<div class="tabs">
				<div
					v-for="tab in tabsData"
					:key="tab.key"
					:class="['tab-title', { active: tab.active }]"
					@click="loadTabDataBase(tab)"
				>
					<p class="text">{{ tab.title }}</p>
					<Counter v-if="totalCounter && totalCounter[tab.logicalname]" :count="totalCounter[tab.logicalname]" />
				</div>
			</div>
			<div class="tab-content" v-perfectscroll="{ addMouseoverEvent: true, refreshScroll: false }">
				<template v-if="tabContent.length">
					<Control
						v-for="tabEntity in tabContent"
						:key="tabEntity.Id + '_' + tabEntity.Version"
						:name="currentTab.messageTemplate.$type"
						:contentProps="currentTab.messageTemplate"
						:class="['striped', { active: selectedEntity &&  tabEntity.Id === selectedEntity.Id }]"
						:evaluationContext="getContext(tabEntity)"
						@click.native.prevent="openDetails(tabEntity)"
					/>
				</template>
				<div v-else-if="!tabContent.length && !isLoading" class="no-results-message">
					<div :style="{ width: '100%' }" v-localization="{ key: 'common.dictionary.noMatchesFound' }" />
				</div>
			</div>
		</div>
		<MsConversationDetails
			v-if="selectedEntity"
			v-bind="{ entity: selectedEntity, evaluationContext: getContext(selectedEntity), detailsTemplate: currentTab.detailsTemplate }"
			:key="selectedEntity.Id"
			@closeDetails="closeDetails"
		/>
	</div>
</template>
<script>
import PanelMixin from './panel.mixin';
import {
	Search, generateGuid, RECORD_STATE
} from '@acx-xms/data-functions/dist';
import { GetUserInfo } from '@/Data/Auth/authentication-service';

const ROLES = { guest: 'Guest' };

export default {
	name: 'ms-conversations-panel',
	mixins: [PanelMixin],
	props: {
		evaluationContext: Object,
		tabs: Array,
		entity: Object,
		messageType: String
	},
	data: () => ({ entityTabs: null }),
	computed: {
		totalCounter() {
			return this.$store.getters['counters/chatTotalCount'] || false;
		},
		getChatsCount() {
			return this.$store.getters['counters/getChatsCount'];
		}
	},
	watch: {
		getChatsCount: {
			async handler() {
				await this.loadTabDataBase(this.currentTab, 15);
			}
		}
	},
	methods: {
		async loadTabData(tab, onPageLoad) {
			if (!this.tabsData.length) {
				this.isLoading = false;
				return;
			}
			this.selectedEntity = null;
			for (const t of this.tabsData) t.active = false;
			if (!tab) {
				tab = this.tabsData[0];
				this.currentTab = tab;
			}
			tab.active = true;
			let tabContent = [];

			if (!sc.user.hasPrivilege('chatparticipant', ['Read'])) {
				this.isLoading = false;
				return;
			}

			const chatidToMessage = {};
			let hasItems = false;
			const items = this.$store.getters['counters/getAllMessages'];

			items.forEach(item => {
				if (!item.message.Source.chatmessagechatidchat[tab.logicalname + 'id']) return;

				chatidToMessage[item.chatid] = item.message.Source;
				hasItems = true;
			});

			if (!hasItems) {
				if (this.currentTab.key === tab.key) tabContent = [];
				this.isLoading = false;
				return;
			}

			const filters = [
				sc.classes.get('offsetFrom.filter', 0),
				sc.classes.get('offsetSize.filter', 999),
				sc.classes.get('search.relation', {
					entity: 'chatparticipant',
					alias: 'chatparticipants',
					output: 'item'
				}).toFilter(),
				...tab.filters,
				sc.classes.get('orderBy.filter', { query: tab.sortBy }),
				sc.classes.get('termFacet.filter', {
					logicalName: 'chatid',
					items: Object.keys(chatidToMessage)
				}),
				sc.classes.get('termFacet.filter', {
					logicalName: 'chatparticipants.recordstate.id',
					items: [RECORD_STATE.ACTIVE]
				})
			];

			const userInfo = await GetUserInfo();
			const isGuest = userInfo.CRMRoles.some(role => role.Name === ROLES.guest);

			if (isGuest) {
				filters.push(
					sc.classes.get('termFacet.filter', {
						logicalName: 'chatparticipants.ispending',
						items: ['false']
					})
				);
			}

			if (this.currentTab.key === tab.key) {
				const chatResults = await Search([this.entityLogicalName], filters);
				tabContent = chatResults.Results
					.filter(item => item.Source.chatparticipants.filter(cp => !cp.ispending).length)
					.map(item => {
						item.Source.availableMessageid = chatidToMessage[item.Id];
						return item;
					}).sort(
						(a, b) => (a.Source.availableMessageid.createdonfromui < b.Source.availableMessageid.createdonfromui)
							? 1
							: ((b.Source.availableMessageid.createdonfromui < a.Source.availableMessageid.createdonfromui) ? -1 : 0)
					);
			}

			onPageLoad(tabContent);
			this.totalCounter && this.unwatch && this.unwatch();
		},
		prepareTabData(tab) {
			return {
				key: generateGuid(),
				active: false,
				title: tab.title && this.evalContext.eval(tab.title),
				logicalname: tab.title && this.evalContext.eval(tab.logicalname),
				filters: tab.filter.map(f => sc.classes.get(f.$type, f, this.evalContext).toFilter()),
				messageTemplate: tab.messageTemplate,
				detailsTemplate: tab.detailsTemplate,
				sortBy: tab.sortBy
					? tab.sortBy.map(field => {
						return {
							logicalName: field.logicalName && this.evalContext.eval(field.logicalName),
							value: field.isDescending
						};
					})
					: []
			};
		},
		/* set tabs config without loading chats */
		setTabs(data) {
			if (data) this.tabsData = data.map(tab => this.prepareTabData(tab));
			else this.isLoading = false;
		},
		/* set entity name */
		setEntity(data) {
			if (data) this.entityLogicalName = this.evalContext.eval(data);
		},
		async loadDataOnCreate() {
			if (this.tabsData.length && this.totalCounter) {
				await this.loadTabDataBase(this.tabsData[0], 15);
			}
		}
	},
	async created() {
		this.isLoading = true; /* start loading before any data is received */

		if (this.tabs) this.setTabs(this.tabs); /* set preconfigured tabs */
		else {
			/* load and set configurations */
			this.entityTabs = await sc.classes.get('settings.dataProvider').get('conversationSettings');
			this.setEntity(this.entityTabs.entity);
			this.setTabs(this.entityTabs.tab);
			if (this.tabsData.length && this.totalCounter) {
				await this.loadTabDataBase(this.tabsData[0], 15);
			}
		}

		/* load chat on tabs when get messages count */
		this.unwatch = this.$watch('totalCounter', async () => {
			if (this.tabsData.length) {
				await this.loadTabDataBase(this.tabsData[0], 15);
			}
		});
	}
};
</script>
<style src="./ms-panel-styles.less"></style>
<style src="./ms-conversations-panel-styles.less"></style>
