<template>
    <div class='email-campaign-errors-manager'>
        <div :class="['error', `error-${e.type}`]" v-for='(e, key) in getErrors' :key='key'>
            {{e.text}}
            <svg class='error-close' v-if='e.close' @click='removeError({name: e.name, step: e.step || null})'>
                <use xlink:href='#layout-icons-dialog-close'></use>
            </svg>
        </div>
    </div>
</template>

<script>;

import { mapState, mapActions, mapGetters } from 'vuex';
export default {
    name: 'email-campaign-errors',
    computed: {
        ...mapState('wizard', ['steps', 'errors']),
        getErrors() {
            return [...this.errors, ...this.steps.find(i => i.active).errors];
        }
    },
    methods: {
        ...mapActions('wizard', ['removeError']),
    }
};
</script>

<style scoped lang='less'>
.email-campaign-errors-manager {
    margin: 0 0 15px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    display: flex;

    .error-close {
        position: absolute;
        right: 16px;
        width: 12px;
        height: 12px;
        cursor: pointer;
    }

    .error {
        height: 43px;
        padding: 0 32px 0 16px;
        margin: 0 0 10px 0;
        border-radius: 4px;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        position: relative;

        &:last-child {
            margin: 0;
        }

        &-warning {
            border: 1px solid var(--color24);
            background: rgba(244, 150, 66, 0.15);
        }

        &-error {
            border: 1px solid var(--color23);
            background: rgba(255, 113, 113, 0.19);
        }
    }
}
</style>