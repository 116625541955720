import Vue from 'vue';
import _ from 'lodash'; // remove later
import PerfectScrollbar from 'perfect-scrollbar';
import { isUndefined, isMobile } from '@acx-xms/data-functions/dist';

class PerfectScrollBase {
	constructor() {
		this.stateMap = new WeakMap();
		this.scrollToBottomEvent = new CustomEvent('scrollToBottom');
		this.scrollToTopEvent = new CustomEvent('scrollToTop');
		this.updateScrollEvent = new CustomEvent('updateScroll');
		this.onScroll = _.throttle((element, binding) => {
			if (binding.value.onScroll) {
				binding.value.onScroll();
			}
			if (element.scrollHeight <= element.scrollTop + element.offsetHeight + 1) {
				element.dispatchEvent(this.scrollToBottomEvent);
			}
			if (element.scrollTop === 0) {
				element.dispatchEvent(this.scrollToTopEvent);
			}
		}, 100);
	}

	_scrollToTheTop(element) {
		element.scrollTop = 0;
	}

	inserted(element, binding, vnode) {
		element.classList.add('perfectscroll-wrapper');
		if (binding.value.scrollToTopEvent) {
			vnode.context.$on(binding.value.scrollToTopEvent, () => {
				this._scrollToTheTop(element);
			});
		}
	}

	componentUpdated(element, options) {
		if (options.value.refreshScroll) {
			this._scrollToTheTop(element);
		}
	}

	unbind(element, binding) { }
}

class PerfectScrollMobile extends PerfectScrollBase {
	inserted(element, binding, vnode) {
		super.inserted(element, binding, vnode);
		element.addEventListener('scroll', () => {
			this.onScroll(element, binding);
		});
	}
}

class PerfectScrollDesktop extends PerfectScrollBase {
	inserted(element, binding, vnode) {
		super.inserted(element, binding, vnode);

		const options = { scrollXMarginOffset: 3 };
		options.enable = isUndefined(binding.value.enable) ? true : binding.value.enable;
		options.addMouseoverEvent = !!binding.value.addMouseoverEvent;
		options.onceMouseover = isUndefined(binding.value.onceMouseover) ? true : binding.value.onceMouseover;
		if (binding.handlers) options.handlers = binding.hanndlers;
		if (binding.suppressScrollX) options.suppressScrollX = binding.suppressScrollX;
		if (binding.suppressScrollY) options.suppressScrollY = binding.suppressScrollY;
		if (!options.enable) return;
		element.addEventListener('ps-scroll-y', () => {
			this.onScroll(element, binding);
		});
		const ps = new PerfectScrollbar(element, options);
		this.stateMap.set(element, ps);
		if (options.addMouseoverEvent) {
			element.addEventListener('mouseover', _.throttle(
				() => {
					if (!element.classList.contains('ps--active-x')) {
						ps.update();
					}
				},
				500), { once: options.onceMouseover });
		}
		if (binding.value.updateScrollEvent) {
			vnode.context.$on(binding.value.updateScrollEvent, () => {
				ps.update();
			});
		}
		if (binding.value.refreshEvent) {
			element.addEventListener(binding.value.refreshEvent, function () {
				ps.update();
			});
		}
	}

	componentUpdated(element, options) {
		const ps = this.stateMap.get(element);
		if (ps) {
			ps.update();
			super.componentUpdated(element, options);
		}
	}

	unbind(element, binding) {
		const ps = this.stateMap.get(element);
		if (ps) {
			ps.destroy();
			this.stateMap.delete(element);
			binding.value.refreshEvent && element.removeEventListener(binding.value.refreshEvent, function () {
				ps.update();
			});
		}
	}
}

Vue.directive('perfectscroll', (() => {
	const ps = isMobile() ? new PerfectScrollMobile() : new PerfectScrollDesktop();
	return {
		inserted: function (element, binding, vnode) {
			ps.inserted(element, binding, vnode);
		},
		componentUpdated: function (element, options) {
			ps.componentUpdated(element, options);
		},
		unbind: function (element, binding) {
			ps.unbind(element, binding);
		}
	};
})());
