var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "accessbylink-login-form" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model.trim",
          value: _vm.userName,
          expression: "userName",
          modifiers: { trim: true }
        }
      ],
      attrs: { type: "text", placeholder: "Your name" },
      domProps: { value: _vm.userName },
      on: {
        keyup: function($event) {
          if (
            !("button" in $event) &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.submit($event)
        },
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.userName = $event.target.value.trim()
        },
        blur: function($event) {
          _vm.$forceUpdate()
        }
      }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "tooltip-wrapper" }, [
      _c(
        "span",
        {
          staticClass: "tooltip-icon",
          class: { opened: _vm.showTooltip },
          on: { click: _vm.toggleTooltip }
        },
        [
          _c("svg", { staticClass: "svg-icon svg-16" }, [
            _c("use", {
              attrs: { "xlink:href": "/Images/login-info.svg#login-info" }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _vm.showTooltip
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "clickoutside",
                  rawName: "v-clickoutside",
                  value: {
                    visible: _vm.showTooltip,
                    callback: _vm.closeTooltip
                  },
                  expression: "{ visible: showTooltip, callback: closeTooltip}"
                }
              ],
              staticClass: "tooltip"
            },
            [
              _c("p", [
                _vm._v(
                  "\n\t\t\t\tYour name should be from 1 to 80 characters long\n\t\t\t"
                )
              ])
            ]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.notificationText.length > 0
      ? _c("div", { staticClass: "notifications-area" }, [
          _c("span", [_vm._v(_vm._s(_vm.notificationText))])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "sign-in p13" }, [
        _c("span", [
          _vm._v("Have an account? "),
          _c("a", { attrs: { href: _vm.getLoginURL() } }, [_vm._v("Sign in")])
        ])
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "submit button",
          attrs: { type: "submit" },
          on: { click: _vm.submit }
        },
        [_vm._v("Enter Without Registration")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }