import { GetUserInfo } from '@/Data/Auth/authentication-service';
import { userFake } from '@/Data/Auth/fakeExpressionsController';

let userInfo;
export default {
	userFake,
	parameters(options) {
		// todo: think of proper error handling
		console.error('hasCRMRole must be awaited');
	},
	async parametersAsync(options) {
		if (!userInfo) {
			userInfo = GetUserInfo();
		}
		const user = await userInfo;
		return [user.CRMRoles.some(role => role.Name === options.name)];
	},
	parametersAsyncFake(options) {
		return [userFake.CRMRoles.some(role => role.Name === options.name)];
	},
	evaluate(items) {
		return items;
	}
};
