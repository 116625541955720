import ExpressionCbool from '../boolean/cbool';

export default {
	parameters(options) {
		return [
			this.eval(options.condition),
			this.eval(options.than),
			this.eval(options.else)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.condition),
			await this.evalAsync(options.than),
			await this.evalAsync(options.else)
		];
	},
	evaluate(condition, truePath, falsePath) {
		condition = ExpressionCbool.evaluate(condition);
		return condition ? truePath : falsePath;
	}
};
