var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "label-wrapper", class: _vm.cssClass }, [
        _vm.enable
          ? _c(
              "a",
              {
                staticClass: "control-link",
                attrs: {
                  href: _vm.targetUrl,
                  target: _vm.hrefTarget,
                  onclick: "javascript: event.stopPropagation();"
                }
              },
              [_vm._v(_vm._s(_vm.displayText))]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.enable
          ? _c("span", { staticClass: "control-link" }, [
              _vm._v(_vm._s(_vm.displayText))
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }