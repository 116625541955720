<template>
	<div v-if="visible" v-targeted-popup="{ target }" class="action-menu-container">
		<div v-clickoutside="{ visible: visible, callback: toggleMenu, clickTarget}" class="action-menu">
			<div class="dropdown-wrapper">
				<div class="top-right-triangle" />
				<div class="items">
					<menu-item :options="item.menuItem"
						v-for="item in items"
						:key="getKey(item)"
						:evaluationContext="evaluationContext"
						@click.native="executeCallback && executeCallback(item.menuItem.action)"
					/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
/* eslint no-unused-vars: 0 */
import TargetedPopup from './../../Bindings/targeted-popup.directive';
import HelperMethods from './../ComponentSet/component-set-evaluation-context-helper.methods';

export default {
	name: 'absolute-menu-popup',
	components: { MenuItem: () => import('./Items/menu-item') },
	data() {
		return {
			items: [],
			visible: false,
			target: null,
			clickTarget: null
		};
	},
	methods: {
		createAbsolutePopUp(e, namespace, items, evaluationContext, executeCallback) {
			!this.target && e.stopPropagation && e.stopPropagation();
			this.items = items;
			this.evaluationContext = evaluationContext;
			this.namespace = namespace;
			this.clickTarget = e.target;
			this.target = e.target;
			this.executeCallback = (action) => {
				executeCallback(action);
				this.toggleMenu();
			};
			this.visible = true;
			window.addEventListener('resize', this.toggleMenu);
			this.$root.$once(this.namespace + '.absoluteMenu.close', () => {
				this.visible = false;
				this.evaluationContext = null;
				this.namespace = '';
				this.items = [];
				this.executeCallback = null;
			});
			this.$store.commit('changeMenuPopupKey', namespace);
		},
		toggleMenu() {
			window.removeEventListener('resize', this.toggleMenu);
			this.visible = false;
			this.evaluationContext = null;
			this.namespace = '';
			this.items = [];
			this.target = null;
			this.$root.$emit('absoluteMenu.closed');
			this.$store.commit('changeMenuPopupKey', '');
		},
		getKey() {
			return HelperMethods.getRandomArbitary(1, 10000000000000000).toFixed();
		}
	},
	created() {
		this.$root.$on('absoluteMenu.open', this.createAbsolutePopUp);
		this.$router.beforeEach((to, from, next) => {
			if (this.visible) {
				this.toggleMenu();
			}
			next();
		});
		this.fork = sc.events.fork();

		this.fork.on('entity.changed', this.toggleMenu);
	},
	beforeDestroy() {
		this.fork && this.fork.dispose();
		this.$root.$off('absoluteMenu.open', this.createAbsolutePopUp);
	}
};
</script>
<style src="./action-menu.less" scoped></style>
