export default {
	parameters(options) {
		let url = sc.classes.get('edge.dataProvider').getFileDataUrl(this.eval(options.entityReference));
		if (url) {
			url += (url.indexOf('?') > -1) ? '&' : '?';
			url += 'token=' + localStorage.getItem(sc.classes.get('authentication').getTokenKey(sc.cluster.key));
		}

		return [url];
	},
	evaluate(field) {
		return field;
	}
};
