import BaseModule from './baseModule';
import { getScript } from '@acx-xms/data-functions/dist';

export default class extends BaseModule {
	async install() {
		// TODO: change CultureInfo to navigator.language #158299
		let localCode = window.CultureInfo && window.CultureInfo.cultureCode;
		// Fix like: 'en' to 'en-EN'
		if (localCode) {
			if (localCode.indexOf('-') < 0) {
				localCode = localCode + '-' + localCode.toUpperCase();
			}

			try {
				await getScript(sc.utils.getStaticContentUrl('/js/datejs/date-' + localCode + '.js'));
			} catch {
				await getScript(sc.utils.getStaticContentUrl('/js/datejs/date.js'));
			}
		} else {
			await getScript(sc.utils.getStaticContentUrl('/js/datejs/date.js'));
		}

		if (window.CultureInfo && window.CultureInfo.formatPatterns) {
			Date.CultureInfo.formatPatterns = window.CultureInfo.formatPatterns;
		} else {
			Date.CultureInfo.formatPatterns.dateTime = Date.CultureInfo.formatPatterns.shortDate + ' ' + Date.CultureInfo.formatPatterns.shortTime;
		}

		Date.CultureInfo.formatPatterns.dateTime.replace(' h:', ' hh:');
		// fix - date.js cant recognize '1:00 # h:mm', works with '1:00 # hh:mm'
	}
}
