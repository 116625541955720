let entityConfiguration;

export default {
	parameters(options) {
		console.error('localizedMetadataLabel must be awaited');
	},
	async parametersAsync(options) {
		if (!entityConfiguration) {
			entityConfiguration = sc.classes.get('entityConfiguration.dataProvider');
		}

		const entity = options.entityLogicalName === '$' || !options.entityLogicalName ? this.entity.logicalname : options.entityLogicalName;
		const field = options.field;
		const language = options.languageCode || sc.UILanguage.code;
		let label;

		if (field) {
			const data = await entityConfiguration.getFieldsCaption(entity, field);
			const fieldData = data[field] || {};
			const res = fieldData[language] || fieldData.base;
			label = res || entity;
		} else {
			const data = await entityConfiguration.getEntityCaptionsFromMetadata([entity], options.isPlural ? 'plural' : 'singular');
			const entityData = data[0] || {};
			const res = entityData[language] || entityData.base;
			label = res || entity;
		}
		return [label];
	},
	evaluate(label) {
		return label;
	}
};
