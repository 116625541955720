import { isActive } from './Methods/action.methods';

export default {
	async enabled(o, evaluationContext, selection) {
		return await isActive(evaluationContext, selection);
	},
	execute(options, context, selection, fullSelection) {
		sc.events.emit('sidePanel.open', 'ms-conversations-panel', {
			evaluationContext: context,
			tabs: options.tab,
			entity: options.entity
		});
	}
};
