var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [{ name: "data-attr", rawName: "v-data-attr" }],
      staticClass: "checkbox-wrapper",
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.clickHandler($event)
        }
      }
    },
    [
      _c(
        "div",
        {
          class: [
            "virtual-checkbox clickable",
            { selected: _vm.selected },
            _vm.checkboxState,
            _vm.cssClass
          ]
        },
        [
          _c("svg", { staticClass: "svg-icon" }, [
            _vm.checkboxState === "partiallySelected" && !_vm.selected
              ? _c("use", {
                  staticClass: "action-icons-selection-checked-partially",
                  attrs: {
                    "xlink:href": "#action-icons-selection-checked-partially"
                  }
                })
              : _c("use", {
                  staticClass: "layout-icons-ms-checkbox",
                  attrs: { "xlink:href": "#layout-icons-ms-checkbox" }
                })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }