export default {
	parameters(options) {
		options.function.argument = options.argument;
		return [
			this.eval(options.function)
		];
	},
	async parametersAsync(options) {
		options.function.argument = options.argument;
		return [
			await this.evalAsync(options.function)
		];
	},
	evaluate(results) {
		return results;
	}
};
