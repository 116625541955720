import { isActive } from './Methods/action.methods';

export default {
	enabled(options, evaluationContext, selection) {
		return isActive(evaluationContext, selection);
	},

	async execute(options, context, selection) {
		const localizations = sc.classes.get('localization.dataProvider');
		const _selection = context.eval(options.selection) || selection[0];
		const parameters = {
			group: sc.utils.findProperty(_selection, 'Source.systemusergroupidgroup', true),
			rolemembers: sc.utils.findProperty(_selection, 'Source.rolemembers', true).map(rolemember => {
				return {
					name: rolemember.rolename,
					rolememberid: rolemember.rolememberid
				};
			}),
			combinedNamespace: options.namespace + '.' + options.internalNamespace,
			systemuserid: _selection.Id,
			logicalname: _selection.Type
		};
		sc.events.emit('vue.dialog.open', {
			title: await localizations.getLabelForCurrentLanguage('dialogues.manageSecurityRoles.title'),
			component: 'manage.security.roles.dialog',
			maximizedWidth: '80%',
			maximizedHeight: '100%',
			evaluationContext: context,
			parameters,
			toastMessageKey: context.eval(options.toastMessageKey),
			informationDialogTextKey: context.eval(options.informationDialogTextKey)
		});
	}
};
