export default {
	parameters(options) {
		return [
			this.eval(options.argument),
			options.format,
			options.pattern
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument),
			options.format,
			options.pattern
		];
	},
	evaluate(argument, format, pattern) {
		if (!argument) {
			return '';
		}
		const date = new Date(argument);
		switch (format) {
		case 'custom':
			if (!pattern) {
				throw new Error('formatDate expression: Pattern is not defined for custom format.');
			}
			return date.toString(pattern);
		case 'dateOnly':
			date.addMinutes(date.getTimezoneOffset());
			return date.toString(CultureInfo.formatPatterns.shortDate);
		default:
			return date.toString(CultureInfo.formatPatterns[format]);
		}
	}
};
