var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "gallery-wrapper-details": _vm.isListingPortalDetails,
        "gallery-wrapper": !_vm.isListingPortalDetails
      }
    },
    [
      _vm.isPrimary
        ? _c("div", { staticClass: "svg-top-icon-wrapper" }, [
            _c("svg", { staticClass: "svg-icon svg-icon-top svg-12" }, [
              _c("use", {
                attrs: { "xlink:href": "#entity-icons-primary-user-crown" }
              })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("image-gallery-small-base", {
        attrs: {
          currentIndex: _vm.currentIndex,
          showArrows: _vm.showArrows,
          images: _vm.images,
          reference: _vm.reference,
          placeholderEntityName: _vm.placeholderEntityName,
          isCustomImagePlaceholder: _vm.options.isCustomImagePlaceholder,
          galleryStylesObj: _vm.galleryStylesObj,
          isLinkToPrevent: _vm.isLinkToPrevent,
          showLink: _vm.showLink,
          alt: _vm.alt
        },
        on: { next: _vm.next, prev: _vm.prev, openDialog: _vm.openDialog },
        nativeOn: {
          click: function($event) {
            return _vm.emitOpenEvent($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }