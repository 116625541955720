<template>
	<div
		v-if="visible && currentcomponentset"
		class="component-search-results is-parent"
	>
		<template v-if="options && options.filterPanel">
			<FiltersPanel
				v-show="showFilterPanel"
				:options="options.filterPanel"
				:evaluationContext="filterPanelEvaluationContext"
				:isLoading="isLoading"
				:entityName="logicalName"
				:stateNamespace="stateNamespace"
			/>
		</template>
		<div class="search-results-wrapper">
			<div v-show="!hideHeader" class="header">
				<div class="region">
					<div
						v-if="options.filterPanel"
						v-data-attr="{ options: options, stateNamespace: 'button' }"
						:class="['filter-button active', { open: !!showFilterPanel }]"
						@click="toggleFilterPanel()"
					>
						<svg class="svg-icon">
							<use
								class="ms-filter-icon"
								xlink:href="#layout-icons-ms-filter-icon"
							/>
						</svg>
					</div>
					<!--TODO: review these slots in all components - most probably we don't need them-->
					<slot name="title-slot">
						<h1 class="title h1">
							{{ title }}
						</h1>
					</slot>
					<slot name="toggle-slot">
						<ResultsToggle
							v-if="withToggleAll"
							:namespace="namespace"
							:internalNamespace="internalNamespace"
							:toggleNamespace="toggleNamespace"
							isParent
							class="toggle-padding"
						/>
					</slot>
					<ComponentSetSearchResultsSelectedRecords
						:stateNamespace="stateNamespace"
						:showSelectedCount="showSelectedCount"
						:targetEntity="targetEntity"
						:withParent="options.selectedTextWithParent"
						:overridedNames="overridedNames"
					/>
				</div>
				<div class="region">
					<SearchField v-if="!hideSearchField" />
					<template v-if="options.sortByFields">
						<sortpanel
							v-show="sortByFields"
							:fields="options.sortByFields"
							:entityName="logicalName"
							:evaluationContext="evaluationContext"
							:scrollNamespace="scrollNamespace"
							:namespace="namespace"
							:internalNamespace="internalNamespace"
							:stateNamespace="stateNamespace"
							class="sortby-filter-wrapper"
						/>
					</template>
					<slot name="favorites" />
					<ViewSwitcher :items="items" :stateNamespace="stateNamespace" />

					<ReactiveActionMenu
						v-if="actionMenuComponent && (actionMenuComponent.menu || actionMenuComponent.toolbarActions)"
						:options="actionMenuComponent"
						:stateNamespace="stateNamespace"
						:class="{ 'without-border': !sortByFields && (items || []).length < 2 }"
						:evaluationContext="evaluationContext"
					/>
				</div>
			</div>
			<div
				v-show="!isCollapsed"
				:class="['content-wrapper', {
					withPanel: showFilterPanel,
					loading: isLoading
				}]"
			>
				<div class="content">
					<!-- TODO: remove results.results after we migrate all views to vue-->
					<keep-alive>
						<div
							:class="['content-inherit-height', { 'with-footer': !!results.total } ]"
							:is="content.name"
							:options="content"
							:isActive="isActive"
							:results="results.results"
							:stateNamespace="stateNamespace"
							:combinedNamespace="!options.filterPanel ? combinedNamespace : ''"
							:paginationParams="paginationParams"
						/>
					</keep-alive>
					<div class="loading-overlay" v-show="isLoading" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
// TODO: investigate if we need to load styles like this
import '@acx-xms/styles-mixins/style.mixins.less';
import '@/Bindings/ui-attribute.directive';
import RouteSearchResultsMixins from './route-search-results.mixins';

export default {
	name: 'route-search-results',
	mixins: [RouteSearchResultsMixins]
};
</script>
<style src="./search-results.less" scoped></style>
<!--<style src="./../../style.mixins.less" scoped></style>-->
<!--TODO:investigate for future-->
<!--<style src="acx-xms/styles-mixins/style.mixins.less" scoped></style>-->
