<template>
	<div class="dialog-content-wrapper">
		<div class="loading-overlay large" v-show="isLoading"></div>
		<div class="confirm-dialog">
			<div class="dialog-content-inner">
				<div class="p4" v-html="options.message"></div>
			</div>

			<div class="footer">
				<button type="button"
                        v-if="!options.hideCancel"   
						class="cancel"
						v-data-attr="{title: options.title, $type: 'dialog.button.submit'}"
						@click="cancel"
						>
					{{ cancelLabel }}
				</button>
				<button v-if="options.customLabel && options.onCustomSubmit"
						type="button"
						class="custom-button cancel"
						v-data-attr="{title: options.title, $type: 'dialog.button.submit'}"
						@click="onCustomSubmit">
					{{ options.customLabel }}
				</button>
				<button type="button"
					v-data-attr="{title: options.title, $type: 'dialog.button.cancel'}"
					@click="ok">
					{{ okLabel }}
				</button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'confirm-dialog',
	props: {
		/**
			 * @property String options.message: Confirm message
			 * @property Function options.onSubmit: A callback to fire on OK button. May return either plain value or Promise.
			 * @property String options.modalNamespace: A namespace to emit events with.
			 * @property String options.okEvent: An event name to fire after OK action is done.
			 * @property String options.cancelEvent: An event name to fire on cancel.
			 * @property String options.okLabel: A text label on Ok button.
			*/
		options: Object
	},
	data() {
		return {
			isLoading: false,
			okLabel: this.options.okLabel,
			cancelLabel: this.options.cancelLabel
		};
	},
	created() {
		if (!this.okLabel) {
			sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('common.dictionary.buttons.ok').then(label => {
				this.okLabel = label;
			});
		}
		if (!this.cancelLabel) {
			sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('common.dictionary.buttons.cancel').then(label => {
				this.cancelLabel = label;
			});
		}
	},
	methods: {
		ok() {
			const onSubmitPromise = this.options.onSubmit && this.options.onSubmit();
			this.isLoading = true;
			Promise.resolve(onSubmitPromise).then(result => {
				this.options.okEvent && this.$root.emit(`${this.options.modalNamespace$}.${this.options.okEvent}`, result);
				this.options.refreshEvents && this.options.refreshEvents.forEach(event => this.$root.$emit(event));
			}).catch(err => {
				console.error(err);
			}).finally(() => {
				this.isLoading = false;
				this.close();
			});
		},
		cancel() {
			this.options.onCancel && this.options.onCancel();
			this.options.cancelEvent && this.$root.emit(`${this.options.modalNamespace$}.${this.options.cancelEvent}`);
			this.close();
		},
		onCustomSubmit() {
			this.options.onCustomSubmit && this.options.onCustomSubmit();
			this.close();
		},
		close() {
			this.$parent.$emit('close');
		}
	}
};
</script>
<style src="./confirm-dialog.less" scoped></style>
