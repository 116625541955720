var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "control-image-gallery",
      class: { "not-clickable": _vm.withExtendedGallery },
      style: { width: _vm.width, height: _vm.height },
      on: { click: _vm.openDialog }
    },
    [
      _c(
        "div",
        { staticClass: "gallery" },
        [
          _vm.items.length
            ? _c("div", { style: { width: _vm.width, height: _vm.height } }, [
                _vm.current
                  ? _c("div", {
                      directives: [
                        {
                          name: "bg-image",
                          rawName: "v-bg-image",
                          value: _vm.current,
                          expression: "current"
                        }
                      ],
                      key: _vm.current.id
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "image-controlbox-wrapper" }, [
                  _c("div", { staticClass: "control-image-rotator" }, [
                    _c(
                      "div",
                      {
                        staticClass: "image-rotator-wrapper prev",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.selectPrevious($event)
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "svg-icon",
                            class: { disabled: _vm.currentIndex === 0 }
                          },
                          [
                            _c("use", {
                              attrs: {
                                "xlink:href": "#action-icons-triangle-1-w"
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "image-rotator-wrapper next",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.selectNext($event)
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "svg-icon",
                            class: {
                              disabled: _vm.currentIndex >= _vm.items.length - 1
                            }
                          },
                          [
                            _c("use", {
                              attrs: {
                                "xlink:href": "#action-icons-triangle-1-e"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "image-count-info-wrapper" }, [
                    _c("span", {
                      directives: [
                        {
                          name: "localization",
                          rawName: "v-localization",
                          value: {
                            key: "imageGallery.control.imageCountInfo",
                            args: [_vm.currentIndex + 1, _vm.items.length]
                          },
                          expression:
                            "{key:'imageGallery.control.imageCountInfo', args:[currentIndex+1, items.length]}"
                        }
                      ],
                      staticClass: "image-count-info"
                    })
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.parentEntityLogicalName && _vm.items.length == 0 && !_vm.isLoading
            ? _c("control-svg-image", {
                style: { width: _vm.width, height: _vm.height },
                attrs: {
                  options: {
                    placeholderEntityName: _vm.parentEntityLogicalName,
                    isCustomImagePlaceholder:
                      _vm.options.isCustomImagePlaceholder,
                    size: "custom",
                    placeholderSize: "medium",
                    customSize: 100,
                    widthUom: "%",
                    heightUom: "%",
                    alt: this.alt
                  },
                  evaluationContext: _vm.evaluationContext
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.parentEntityLogicalName && _vm.items.length == 0
            ? _c("div", { staticClass: "info-message" }, [
                _c("b", {
                  staticClass: "info-message-title",
                  attrs: {
                    "data-bind":
                      "localization: { key: 'imageGallery.noDataMessage'}"
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoading,
                expression: "isLoading"
              }
            ],
            staticClass: "loading-overlay"
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }