<template>
	<div v-if="isVue && isConfigToolMode"
		 class="ctwrapper">
		<div v-if="isClicked"
			 class="highlighted" />
		<component	v-bind="$attrs"
					v-data-attr="{ options: contentProps }"
					:is="prepareComponentName(name)"
					:options="contentProps"
					:evaluationContext="evaluationContext"
					class="control-component"
					@click.native.stop="onClick" />
	</div>
	<component	v-else-if="isVue"
				v-bind="$attrs"
				v-data-attr="{ options: contentProps }"
				:is="prepareComponentName(name)"
				:options="contentProps"
				:evaluationContext="evaluationContext"
				class="control-component" />
	<div v-else-if="!isVue"
		 v-kocomponent="componentWrapper(contentProps)"
		 v-data-attr="{ options: contentProps }"
		 class="control-component" />
</template>

<script>
/* eslint no-unused-vars: 0 */
import '@/Bindings/ui-attribute.directive';
import entitytemplateGrouped from '../EntityTemplate/entitytemplate-grouped';
import entitytemplateFieldgroup from '../EntityTemplate/entitytemplate-fieldgroup';
import entitytemplateTemplate from '../EntityTemplate/entitytemplate-template';
import entitytemplateThumbnailannotated from '../EntityTemplate/entitytemplate-thumbnailannotated';
import controlFieldwrapper from '../Control/FieldWrapper/control-fieldwrapper';
import controlFlexiblefieldwrapper from '../Control/FieldWrapper/control-flexiblefieldwrapper';
import controlVuecontrolWrapper from '../Control/control-vuecontrol-wrapper';
import controlPanel from '../Control/Panel/control-panel';
import controlIframe from '../Control/Iframe/control-iframe';
import controlTemplate from '../Entity/control-template';
import controlConfigTemplate from '../Entity/control-configtemplate';
// import controlLabel from '../Control/control-label'
import {
	controlLabel,
	controlLinkreference
} from '@acx-xms/controls';
import controlAsyncControl from '../Control/control-async-control';
import controlArray from '../Control/control-arrayOfControls';
import controlVuexGetter from '../Control/control-vuex-getter';
import controlOwndatasource from '../Control/control-ownDataSource';
import controlLink from '../Control/control-link';
// import controlLinkreference from '../Control/LinkReference/link-reference'
import controlNamespacedReference from '../Control/NamespacedReference/namespaced-reference';
import controlSvgImage from '../Control/Images/control-svg-image';
import controlSvgIcon from '../Control/Images/control-svg-icon';
import controlSvgImageReference from '../Control/Images/control-svg-image-reference';
import controlImages from '../Control/Images/control-images';
import controlActionmenuWrapper from '../Control/control-actionmenu-wrapper';
import controlScrollablepanel from '../Control/control-scrollablepanel';
import controlColumn from '../Control/control-column';
import controlActionbutton from '../Control/control-actionbutton';
import controlFlexiblecontainer from '../Control/FlexibleContainer/control-flexiblecontainer';
import ControlImageGallerySmall from '@/Components/Control/Images/ImageGallery/control-image-gallery-small';
import ImageGallerySmallWithDataSource from '@/Components/Control/Images/ImageGallery/control-image-gallery-small-withDataSource';
import ControlImageGallerySmallMobile from '@/Components/Control/Images/ImageGallery/control-image-gallery-small-mobile';
import ControlSvgIconAction from '@/Components/Control/Images/SvgIconAction/control-svg-icon-action';
import ControlRating from '@/Components/Control/Rating/rating';
import ControlImageList from '../Control/Images/control-image-list';
import ControlMap from '../Control/Map/control-map';
import ControlTableView from '../Control/Table/control-table-view';
import ControlMobileTableView from '../Control/Table/control-mobile-table-view';
import SmartImage from '../Control/Images/smart-image';
import SmartImageGallery from '../Control/Images/ImageGallery/smart-image-gallery/smart-image-gallery';
import SmartImageGalleryMobile from '../Control/Images/ImageGallery/smart-image-gallery/smart-image-gallery-mobile';
import UnreadMessagesCounter from '../Control/UnreadMessagesCounter/counter-wrapper';
import ControlVirtualCheckBox from '../Control/Checkboxes/control-virtual-checkbox';
import ComponentSetMobileSearchResultsTableView from '@/Components/ComponentSet/SearchResults/Views/table-view/component-set-mobile-search-results-table-view';
import ControlMultilineLabel from '../Control/multilineLabel/multiline-label';
import ControlFavorites from '../Control/Favorites/control-favorites';

import { prepareComponentName as prepCompName } from '@acx-xms/data-functions/dist';
const controlChat = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '../Control/Chat/control-chat');
const ControlColorSet = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '../Control/ColorSet/control-color-set');
const ControlStringeditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/Controls/StringEditor/control-stringeditor');
const ControlNumericeditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/Controls/NumericEditor/control-numericeditor');
const ControlDateeditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/Controls/DateEditor/control-dateeditor');
const ControlTexteditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/Controls/TextEditor/control-texteditor');
const ControlColorPicker = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/Controls/ColorPicker/control-color-picker');
const ControlHidden = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/Controls/control-hidden');
const FileDownload = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '../Control/FileDownload/file-download');
const OpenFolder = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '../Control/OpenFolder/open-folder');
const FileDownloadInform = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '../Control/FileDownload/file-download-inform');
const ControlFileDraganddropEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/Controls/DragAndDropEditor/control-file-draganddrop-editor');
const ControlFileTableEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/Controls/DragAndDropEditor/control-file-table-editor');
const ControlNdandafileEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/Controls/NDAFileEditor/control-ndafile-editor');
const controlDocumentMultiuploadEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/Controls/NDAFileEditor/control-document-multiupload-editor');
const ControlStringtopicklist = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/Controls/StringToPicklist/control-stringtopicklist');
const ControlOptionseditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/Controls/OptionsEditor/control-optionseditor');
const ControlGeocoordinatesEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/Controls/GeocoordinatesEditor/control-geocoordinateseditor');
const SwitchRecordDetails = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '../Control/SwitchRecordDetails/control-switch-record-details');
const SearchResultDetails = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/ComponentSet/Details/component-search-details');
const SearchResultDetailsRow = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/ComponentSet/Details/details-row-control');
const SearchResultDetailsColumn = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/ComponentSet/Details/details-column-control');
// todo - refactor this mess with lookups
const ControlLookupEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/Controls/Lookup/control-lookup-editor-wrapper');
const ControlLookupFolderEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/Controls/Lookup/control-lookup-folder-editor-wrapper');
const ControlFileUploadEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/Controls/NDAFileEditor/control-file-upload-editor');
const ControlBooleanEditor = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Editor/Controls/BooleanEditor/control-boolean-editor');

const ControlManageNotifications = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '../Control/ManageNotifications/control-manage-notifications');
const ControlMappingDetailsTable = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '../Dialogues/MappingEditDialog/mapping-edit-dialog');

export default {
	name: 'control',
	props: {
		name: String,
		contentProps: Object,
		evaluationContext: Object
	},
	components: {
		entitytemplateGrouped,
		entitytemplateFieldgroup,
		entitytemplateThumbnailannotated,
		controlFieldwrapper,
		controlFlexiblefieldwrapper,
		controlChat,
		controlPanel,
		controlLabel,
		controlLink,
		controlIframe,
		controlLinkreference,
		controlArray,
		controlAsyncControl,
		ControlRating,
		controlVuexGetter,
		controlOwndatasource,
		controlTemplate,
		entitytemplateTemplate,
		ControlImageGallerySmall,
		ControlImageGallerySmallMobile,
		ControlImageList,
		controlSvgImage,
		controlImages,
		controlSvgImageReference,
		controlScrollablepanel,
		controlColumn,
		controlActionbutton,
		ControlMap,
		ControlHidden,
		ControlStringeditor,
		ControlNumericeditor,
		ControlDateeditor,
		ControlTexteditor,
		ControlStringtopicklist,
		ControlOptionseditor,
		ControlFavorites,
		ControlSvgIconAction,
		'control-multilinelabel': ControlMultilineLabel,
		'control-colorpicker': ControlColorPicker,
		'control-colorset': ControlColorSet,
		'control-tableview': ControlTableView,
		'control-mobiletableview': ControlMobileTableView,
		'control-actionmenuwrapper': controlActionmenuWrapper,
		'control-namespacedreference': controlNamespacedReference,
		'component-set-vuecontrolcomponentwrapper': controlVuecontrolWrapper,
		'control-configtemplate': controlConfigTemplate,
		controlFlexiblecontainer,
		// todo - synchronize after we get rid of xsd
		'control-smart-image': SmartImage,
		'control-smartimage': SmartImage,
		controlSvgIcon,
		'control-svgicon': controlSvgIcon,
		'plugins-smarticonplugin-smartcontrolimagegallery': SmartImageGallery,
		'imagegallery-image-gallery-small-withdatasource': ImageGallerySmallWithDataSource,
		'plugins-smarticonplugin-smartcontrolimagegallerymobile': SmartImageGalleryMobile,
		'control-filedownload': FileDownload,
		'control-openfolder': OpenFolder,
		'control-filedraganddropeditor': ControlFileDraganddropEditor,
		'control-filetableeditor': ControlFileTableEditor,
		'control-filedownloadinform': FileDownloadInform,
		'control-unreadmessagescounter': UnreadMessagesCounter,
		'control-ndafileeditor': ControlNdandafileEditor,
		'control-documentmultiuploadeditor': controlDocumentMultiuploadEditor,
		'control-virtualcheckbox': ControlVirtualCheckBox,
		'control-lookupeditor': ControlLookupEditor,
		'control-lookupfoldereditor': ControlLookupFolderEditor,
		'control-fileuploadeditor': ControlFileUploadEditor,
		'control-booleaneditor': ControlBooleanEditor,
		'control-geocoordinateseditor': ControlGeocoordinatesEditor,
		'control-switchrecorddetails': SwitchRecordDetails,
		'control-managenotifications': ControlManageNotifications,
		'control-mappingdetailstable': ControlMappingDetailsTable,
		'control-searchresultdetails': SearchResultDetails,
		'control-searchresultdetailsrow': SearchResultDetailsRow,
		'control-searchresultdetailscolumn': SearchResultDetailsColumn
	},
	data() {
		return {
			isVue: true,
			isClicked: false
		};
	},
	computed: {
		isConfigToolMode() {
			return !!this.contentProps.$id;
		}
	},
	created() {
		const koComponents = [
			// "control.chatEntityReference", // move from legacy if it wiil be needed
			'control.entityReference'];
		if (koComponents.indexOf(this.name) > -1) {
			this.isVue = false;
		}
		if (this.isConfigToolMode) {
			this.$root.$on('config-tool-click', this.onClick);
		}
	},
	beforeDestroy() {
		if (this.isConfigToolMode) {
			this.$root.$off('config-tool-click', this.onClick);
		}
	},
	methods: {
		prepareComponentName(name) {
			return prepCompName(name);
		},
		componentWrapper(component) {
			return {
				$bindProperties: {
					name: component.$type,
					params: [component, this.evaluationContext, {
						$propertyBag: {},
						$evaluationContext: this.evaluationContext
					}]
				}
			};
		},
		onClick(event, value, id) {
			if (this.contentProps.$id && id && id !== this.contentProps.$id) {
				this.isClicked = false;
			} else {
				// if we click twice on the same element - div.highlighted is clicked instead of our element
				if (!value && !event.target.classList.contains('highlighted')) {
					this.$root.$emit('config-tool-click', event, true, this.contentProps.$id);
					this.isClicked = true;
					this.specifiedElement = event && event.target;
					if (this.specifiedElement) {
						document.addEventListener('click', this.onDocumentClick);
					}
				}
			}
		},
		onDocumentClick(event) {
			const isClickInside = this.specifiedElement.contains(event.target);
			if (!isClickInside && !event.target.classList.contains('highlighted')) {
				document.removeEventListener('click', this.onDocumentClick);
				this.isClicked = false;
			}
		}
	}
};
</script>
<style lang="css" scoped>
	.ctwrapper {
		position: relative;
	}

	.highlighted {
		position: absolute;
		top: -2px;
		bottom: -2px;
		left: -2px;
		right: -2px;
		border: 2px solid var(--color2);
		border-radius: 5px;
	}
</style>
