<template>
	<div class="searchResults-map-view">
		<div v-bind:class="{ fullscreen: mapFullScreen }" v-if="isActive" class="map-search-results" v-mapSearchResults="{
				 results: results,
				 selection: selection,
				 getAreaSelection: getAreaSelection,
				 setAreaSelection: setAreaSelection,
				 clearSelection: clearSelection,
				 getEntityCoords: getEntityCoords,
				 onAreaSelectionChanged: onAreaSelectionChanged,
				 areaSelectionEnabled: areaSelectionEnabled,
				 drawMode: drawMode,
				 draw: draw,
				 namespace: combinedNamespace,
				 stateNamespace: stateNamespace,
				 fullScreenCallback: fullScreenCallback,
			     targetEntity: targetEntity,
				 enableFullscreenControl: options.enableFullscreenControl,
				 enableDrawingTool: options.enableDrawingTool,
				 mapTooltipName: 'mobile-map-tooltip',
				 zoomOnDoubleclick: options.zoomOnDoubleclick,
				 mapShiftLeft: 65,
				 mapShiftTop: 35,
				 isMobile: true,
				 gestureHandling: 'cooperative'
			 }">
			<div class="scMap">
				<div class="sc-map-holder"></div>
			</div>
			<div v-show="!isMapReady" class="loading-overlay"></div>
		</div>
	</div>
</template>
<script>
import ComponentSetSearchResultsMapViewBase from './search-results-map-view-base.mixins';

export default {
	name: 'component-set-mobile-search-results-map-only-view',
	mixins: [ComponentSetSearchResultsMapViewBase]
};
</script>

<style src="./mobile-search-results-map-view.less" scoped></style>
