/* eslint prefer-promise-reject-errors: 0 */
import { SendRequest } from '@acx-xms/data-functions/dist';
import { GetPerConfig } from '@/Data/DataProviders/userStorageDataProvider';
import coAuthDataProvider from '@/Data/DataProviders/coAuthDataprovider';

const composeronlinePath = '/api/plugins/endpoint/co';
let coURL = '';

function sendRequest(request) {
	let { type, url, body = null, query = {} } = request;
	url = composeronlinePath + '/' + url;
	query.configKey = sc.cluster.configKey;

	return SendRequest(url, type, body, null, query);
}

function mapTemplate(template) {
	const mergedTemplate = {
		isCO: true,
		inputTypes: template.mainEntity,
		templateId: template.templateId,
		templateName: template.templateName,
		targetOutputFormats: 'pdf,html', // template.templateType
		preview: template.thumbnailContent ? 'data:image/png;base64,' + template.thumbnailContent : null,
		creatorId: template.creatorId
	};
	return mergedTemplate;
}

function mapTemplates(data) {
	if (data) {
		data.htmlTemplates = data.htmlTemplates.map(mapTemplate);
	}
	return data;
}

export default {
	async getToken() {
		return GetPerConfig('co-auth-key');
	},
	async getCoURL() {
		if (!coURL) {
			coURL = await sendRequest({
				type: 'get',
				url: 'geturl'
			});
		}
		return coURL;
	},
	async openCo(queryParams = {}) {
		const [response, token] = await Promise.all([this.getCoURL(), this.getToken()]);
		let query = '';
		for (let param in queryParams) {
			query += `&${param}=${queryParams[param]}`;
		}
		window.open(`${response}ohc/app/page/templates?ct=${token}${query}`, '_blank');
	},
	async openEntityDetails(id, entityType) {
		const [response, token] = await Promise.all([this.getCoURL(), this.getToken()]);
		window.open(`${response}ohc/app/page/${entityType}/${id}/?ct=${token}`, '_blank');
	},
	async searchTemplates(filters, includeThumbnail = false) {
		return sendRequest({
			type: 'post',
			url: 'proxy',
			body: filters,
			query: {
				q: `api/v4/html-templates/search?includeThumbnail=${includeThumbnail}&v=${Date.now()}`,
				authorization: await this.getToken()

			}
		}).then(mapTemplates);
	},

	async getTemplateById(id) {
		return sendRequest({
			type: 'get',
			url: 'proxy',
			query: {
				q: `api/v4/html-templates/${id}`,
				authorization: await this.getToken()
			}
		}).then(mapTemplate);
	},

	async getTemplateImageById(id, name, offset = 0) {
		if (!name) {
			throw Error('name is required');
		}
		const filters = {
			offset,
			limit: 10,
			searchType: 'ALL',
			nameFilter: {
				exactMatch: true,
				value: name
			}
		};
		const templates = await this.searchTemplates(filters, true);
		if (!templates.htmlTemplates.length && !offset) {
			return null;
		}

		const result = templates.htmlTemplates.find(template => template.templateId === id);

		return result || await this.getTemplateImageById(id, name, offset++);
	},

	// generateReport
	// job html, get artifact, jobpdf

	async generateHtml(data) {
		// job html
		let combineRequest = token => {
			return {
				type: 'post',
				url: 'proxy',
				body: data,
				query: {
					q: 'api/v1/jobs/html/start',
					authorization: token
				}
			};
		};
		try {
			return  await sendRequest(combineRequest(await this.getToken()));
		}
		catch (e) {
			if (e.status === 401) {
				await coAuthDataProvider.refreshToken().catch(async () => {
					await coAuthDataProvider.authenticate(
						`${location.origin}/${sc.clusterKey()}/${sc.configurationKey()}/coauth`, false
					);
				});

				return await sendRequest(combineRequest(await this.getToken()));
			}
			throw e;
		}
		
	},

	async generatePdf(artifactId) {
		return sendRequest({
			type: 'post',
			url: 'proxy',
			body: { artifactId },
			query: {
				q: 'api/v1/jobs/pdf/start',
				authorization: await this.getToken()
			}
		});
	},

	async getJobStatus(jobId, maxRetryCount = 24) {
		return new Promise((resolve, reject) => {
			let retryCount = 0;
			const exec = async () => {
				retryCount++;
				if (retryCount > maxRetryCount) {
					reject('Reached max retry attepmts');
				}

				const res = await sendRequest({
					type: 'get',
					url: ['proxy'],
					query: {
						q: `api/v1/jobs/status/${jobId}`,
						authorization: await this.getToken()
					}
				});
				switch (res.status) {
					case 'completed': {
						resolve(res);
						break;
					}
					case 'failed': {
						reject({
							jobstatus:'Get job failed',
							details: res
						})
						break;
					}
					default:
						setTimeout(exec, 5000);
						break;
				}
			};
			exec();
		});
	},
	async getArtifactByAlias(alias) {
		return sendRequest({
			type: 'get',
			url: 'proxy',
			query: {
				q: `api/v4/artifacts/aliases/${alias}`,
				authorization: await this.getToken()
			}
		});
	},
	async deleteArtifact(id) {
		return sendRequest({
			type: 'delete',
			url: 'proxy',
			query: {
				q: `api/v4/artifacts/${id}`,
				authorization: await this.getToken()
			}
		});
	},
	async getArtifactContent(artifactId) {
		return sendRequest({
			type: 'get',
			url: 'proxy',
			query: {
				q: `api/v4/artifacts/${artifactId}/content?withmaps=true`,
				authorization: await this.getToken()
			}
		});
	},
};
