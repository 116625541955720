var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grouped", class: "columns-" + _vm.options.columns },
    _vm._l(_vm.options.group, function(group, index) {
      return _c(
        "div",
        { key: index, staticClass: "grouped-content" },
        [
          _c("control", {
            attrs: {
              name: group.$type,
              contentProps: group,
              evaluationContext: _vm.evaluationContext
            }
          })
        ],
        1
      )
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }