var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.configurations.length > 1
    ? _c(
        "div",
        {
          directives: [
            {
              name: "perfectscroll",
              rawName: "v-perfectscroll",
              value: { enable: true },
              expression: "{ enable: true }"
            }
          ],
          staticClass: "configurations"
        },
        _vm._l(_vm.configurations, function(config) {
          return _c(
            "p",
            {
              key: config,
              on: {
                click: function($event) {
                  _vm.SelectConfig(config)
                }
              }
            },
            [_vm._v(_vm._s(config))]
          )
        })
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }