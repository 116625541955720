var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-wrapper" }, [
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "h1" }, [_vm._v("Dashboard")]),
        _vm._v(" "),
        _vm.datePeriod
          ? _c(
              "div",
              _vm._g(
                {
                  staticClass: "period-wrapped",
                  class: _vm.isLoading && "disabled"
                },
                !_vm.isLoading ? { click: _vm.OnPeriodClick } : {}
              ),
              [
                _c("svg", { staticClass: "svg-icon svg-20" }, [
                  _c("use", {
                    attrs: { "xlink:href": "#layout-icons-calendar-marker" }
                  })
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.datePeriod.title))]),
                _vm._v(" "),
                _c("svg", { ref: "opener", staticClass: "svg-icon svg-10" }, [
                  _c("use", {
                    attrs: { "xlink:href": "#layout-icons-ms-arrow-down" }
                  })
                ])
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "perfectscroll",
              rawName: "v-perfectscroll",
              value: { enable: true },
              expression: "{enable: true}"
            }
          ],
          staticClass: "content-scroll-wrapper"
        },
        [
          !_vm.trendsLoading
            ? _c("div", { staticClass: "totals-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "totals-pair" },
                  [
                    _c("total-counter", {
                      attrs: {
                        entity: "inquiry",
                        color: _vm.color17,
                        label: "inquiries",
                        filters: _vm.inquiryLogicalFilter()
                      }
                    }),
                    _vm._v(" "),
                    _c("total-counter", {
                      attrs: {
                        entity: "collaborationroom",
                        color: _vm.color14,
                        label: "collaboration rooms",
                        filters: _vm.getOwnRecordsFilter()
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "totals-pair" },
                  [
                    _c("total-counter", {
                      attrs: {
                        entity: "dealroom",
                        color: _vm.color15,
                        label: "deal rooms",
                        filters: _vm.getDRFilters(_vm.getOwnRecordsFilter())
                      }
                    }),
                    _vm._v(" "),
                    _c("total-counter", {
                      attrs: {
                        entity: "chatparticipant",
                        color: _vm.color16,
                        label: "invitees",
                        filters: _vm.getInviteesFilter()
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.trendsLoading && !_vm.isLoading
            ? _c("div", { staticClass: "charts-wrapper" }, [
                _c("div", { staticClass: "chart-wrapper" }, [
                  _c("div", { staticClass: "header" }, [
                    _vm._v("New Inquiries")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chart-component-wrapper" },
                    [
                      _c("chart-wrapper", {
                        attrs: {
                          data: _vm.inquiryData,
                          options: _vm.options,
                          styles: _vm.chartStyles,
                          chartName: "bar-chart"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "chart-wrapper" }, [
                  _c("div", { staticClass: "header" }, [
                    _vm._v("Top Listings by Number of Inquiries")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chart-component-wrapper" },
                    [
                      _c("chart-wrapper", {
                        attrs: {
                          data: _vm.inquiryDoughnutData,
                          options: _vm.doughnutOptions,
                          styles: _vm.chartStyles,
                          chartName: "doughnut-chart"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "chart-wrapper" }, [
                  _c("div", { staticClass: "header" }, [
                    _vm._v("New Collaboration Rooms")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chart-component-wrapper" },
                    [
                      _c("chart-wrapper", {
                        attrs: {
                          data: _vm.croomData,
                          options: _vm.options,
                          styles: _vm.chartStyles,
                          chartName: "bar-chart"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "chart-wrapper" }, [
                  _c("div", { staticClass: "header" }, [
                    _vm._v("New Deal Rooms")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chart-component-wrapper" },
                    [
                      _c("chart-wrapper", {
                        attrs: {
                          data: _vm.droomData,
                          options: _vm.options,
                          styles: _vm.chartStyles,
                          chartName: "bar-chart"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "chart-wrapper" }, [
                  _c("div", { staticClass: "header" }, [
                    _vm._v("New Participants in Collaboration Rooms")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chart-component-wrapper" },
                    [
                      _c("chart-wrapper", {
                        attrs: {
                          data: _vm.croomParticipantsData,
                          options: _vm.options,
                          styles: _vm.chartStyles,
                          chartName: "bar-chart"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "chart-wrapper" }, [
                  _c("div", { staticClass: "header" }, [
                    _vm._v("New Participants in Deal Rooms")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chart-component-wrapper" },
                    [
                      _c("chart-wrapper", {
                        attrs: {
                          data: _vm.droomParticipantsData,
                          options: _vm.options,
                          styles: _vm.chartStyles,
                          chartName: "bar-chart"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "chart-wrapper" }, [
                  _c("div", { staticClass: "header" }, [
                    _vm._v("Top 7 Listings by Collaboration Rooms")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chart-component-wrapper" },
                    [
                      _c("chart-wrapper", {
                        attrs: {
                          data: _vm.croomDoughnutData,
                          options: _vm.doughnutOptions,
                          styles: _vm.chartStyles,
                          chartName: "doughnut-chart"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "chart-wrapper" }, [
                  _c("div", { staticClass: "header" }, [
                    _vm._v("Top 7 Listings by Deal Rooms")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "chart-component-wrapper" },
                    [
                      _c("chart-wrapper", {
                        attrs: {
                          data: _vm.droomDoughnutData,
                          options: _vm.doughnutOptions,
                          styles: _vm.chartStyles,
                          chartName: "doughnut-chart"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isLoading || _vm.trendsLoading
            ? _c("div", { staticClass: "loading-wrapper" }, [
                _c("h2", [_vm._v("Loading charts...")])
              ])
            : _vm._e()
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }