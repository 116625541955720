/* eslint camelcase: 0 */
import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';
import InitializationWrapper from '@/Components/Initialization/initialization-wrapper';
import InitializationPanel from '@/Components/Initialization/InitializationLoader/initialization-panel';
import ConfigurationWrapper from '@/Components/Initialization/configuration-wrapper';
import DiscoveryWrapper from '@/Components/Initialization/Discovery/discovery-wrapper';
import AcceptTermsOfUseAndPrivacyPolicy from '@/Components/Initialization/accept-privacy-and-terms';
import ErrorWrapper from '@/Components/Initialization/Error/error-wrapper';
import NoAccessWrapper from '@/Components/Initialization/Error/no-access-wrapper';
import LicenseErrorWrapper from '@/Components/Initialization/Error/license-error-wrapper';
import LogOutWrapper from '@/Components/Initialization/LogOut/logout-wrapper';
import ConfigurationsList from '@/Components/Initialization/Configurations/configurations-list';
import {
	Authenticate,
	GetToken,
	SetToken,
	StoreUrl,
	GetAvailableLayouts,
	GetAvailableConfigurations,
	GetUserInfo,
	GetMaintenanceStatus
} from '@/Data/Auth/authentication-service';
import RouteResults from '@/Components/ComponentSet/SearchResults/route-search-results';
import MobileRouteResults from '@/Components/ComponentSet/SearchResults/mobile-route-search-results';
import RouteDetails from '@/Components/ComponentSet/Details/route-search-details';
import ThemeDetails from '@/Components/Theme/Details/theme-details';
import PicklistDetails from '@/Components/PicklistValues/picklist-details';
import AdminDashboard from '@/Components/Dashboard/dashboard';
import BrokerDashboard from '@/Components/Dashboard/broker-dashboard';
import Wizard from '@/Components/Wizard/wizard';
import CreateRoomFromCrmWrapper from '@/Components/CreateRoomFromCrm/create-room-from-crm-wrapper';
import Store from '@/States/ms-state';
import ConfigTool from '@/Components/ConfigTool/config-tool-wrapper';
import jwt_decode from 'jwt-decode';
import CoauthDialog from '@/Components/Dialogues/ReportingserviceDialog/coauth-dialog';
import MaintenanceWrapper from '@/Components/Initialization/Error/maintenance-wrapper';
import { isMobile, getBaseRouteUrl } from '@acx-xms/data-functions/dist';

Vue.use(Router);
Vue.use(Meta, { refreshOnceOnNavigation: true });

export default new Router({
	mode: 'history',
	routes: [
		{
			path: '/logout',
			name: 'logout',
			component: LogOutWrapper
		},
		{
			path: '/acceptTermsOfUseAndPrivacyPolicy',
			name: 'acceptTermsOfUseAndPrivacyPolicy',
			component: AcceptTermsOfUseAndPrivacyPolicy
		},
		{
			path: '/no-access',
			name: 'no-access',
			component: NoAccessWrapper
		},
		{
			path: '/maintenance-error',
			name: 'maintenance',
			component: MaintenanceWrapper
		},
		{
			path: '/in-progress/:message',
			name: 'in-progress',
			component: InitializationPanel,
			props: (route) => ({ ...route.params })
		},
		{
			path: '',
			component: InitializationWrapper,
			children: [
				{
					path: '',
					name: 'discovery',
					component: DiscoveryWrapper
				},
				{
					path: 'error',
					name: 'error',
					component: ErrorWrapper
				},
				{
					path: 'licenseError',
					name: 'licenseError',
					component: LicenseErrorWrapper
				},
				{
					path: ':clusterKey',
					name: 'configurations',
					component: ConfigurationsList
				}]
		},
		{
			path: '/:clusterKey/:config/accessbylink',
			name: 'accessbylink',
			component: ConfigurationWrapper,
			beforeEnter: async (to, from, next) => {
				try {
					const JWTToken = to.query.accessToken;
					sc.cluster.key = to.params.clusterKey;
					if (JWTToken) {
						const decoded = jwt_decode(JWTToken);
						if (new Date(decoded.exp * 1000) > new Date()) {
							const impersonationToken = (decoded['http://schemas.ascendix.com/edge/identity/claims/scope'] || []).find(d => d.includes('impersonation')).split('.').pop();
							// we need to authenticate user on SC server, if it cannot be authenticated - we will go to catch block
							await Authenticate({
								JWTToken,
								impersonationToken,
								cluster: sc.cluster.key
							});
						}
					}
					to.params.layout = 'accessbylink';
					// TODO start: temporary fix, when auth request doesn't back 503
					await GetMaintenanceStatus();

					if (window.sessionStorage.getItem('redirect_url')) {
						window.sessionStorage.removeItem('redirect_url');
					}
					// TODO: end
					next();
				} catch (e) {
					if (e && e.message) {
						window.sessionStorage.setItem('redirect_url', window.location.href);
						try {
							const maintenanceStatus = typeof (e.message) === 'string' ? JSON.parse(e.message) : e.message;
							if (maintenanceStatus && maintenanceStatus.Code) {
								next({
									name: 'maintenance',
									query: {
										restore: e.message.Detail.Operation.toLowerCase() === 'restore' ? 'true' : 'false',
										redirect_url: window.sessionStorage.getItem('redirect_url')
									}
								});
								return;
							}
						} catch (ex) {
						}
					}
					// if token is discarded or invalid
					next('/no-access');
				}
			},
			children: [
				{
					path: ':type/:id/:componentSet?',
					name: 'details',
					component: RouteDetails
				}
			]
		},
		{
			path: '/:clusterKey/:config/coauth/:id/:mci?',
			name: 'coauth',
			component: CoauthDialog,
			children: [
				{
					path: ':type/:id/:componentSet?',
					name: 'details',
					component: RouteDetails
				}
			]
		},
		{
			path: '/:clusterKey/:config/:layout?',
			name: 'main',
			component: ConfigurationWrapper,
			beforeEnter: async (to, from, next) => {
				try {
					sc.cluster.key = to.params.clusterKey;
					let token = GetToken(to.params.clusterKey);
					// Check Anonymous Token before proceed
					if (token && to.params.clusterKey && to.params.clusterKey.toLocaleLowerCase().indexOf('anonymous') > -1) {
						const decodedSCToken = jwt_decode(token);
						const decodedEDGEToken = jwt_decode(decodedSCToken.Token);
						if (new Date(decodedEDGEToken.exp * 1000) < new Date()) {
							token = null;
							SetToken(to.params.clusterKey, null);
						}
					}
					if (!token) {
						if (to.params.config) {
							StoreUrl();
						}
						if (window.sessionStorage.getItem('redirect_url')) {
							window.sessionStorage.removeItem('redirect_url');
						}
						next(`/${to.params.clusterKey}`);
					} else {
						try {
							Store.commit('changeInitMessage', 'Getting authentication info');
							await GetMaintenanceStatus();

							const [availableLayouts, availableConfigurations, userInfo] = await Promise.all([GetAvailableLayouts(to.params.config), GetAvailableConfigurations(true), GetUserInfo()]);
							let path = ''; let config = '';
							const isAnonymous = userInfo.CRMRoles.some(role => role.Name === 'Anonymous User');

							const modifyPath = (path, value, position) => {
								const pathArr = path.split('/');
								pathArr.splice(position, 1, value);
								return pathArr.join('/');
							};

							const _isMobile = isMobile();
							config = _isMobile && availableConfigurations.includes(to.params.config + 'mobile') ? to.params.config + 'mobile' : to.params.config;

							if (!availableConfigurations.includes(config)) {
								config = _isMobile && availableConfigurations.includes(availableConfigurations[0] + 'mobile') ? availableConfigurations[0] + 'mobile' : availableConfigurations[0];
								path = modifyPath(to.path, config, 2);
							}
							if (!availableLayouts[to.params.layout] || (isAnonymous && !['embedded', 'listing-portal'].includes(to.params.layout))) {
								const layout = isAnonymous ? 'listing-portal' : 'main';
								path = modifyPath(path || to.path, layout, 3);
							}
							// needed if user tried to open secured or not existing config with parameters
							const tail = sessionStorage.getItem('routeTail');
							if (tail) {
								sessionStorage.removeItem('routeTail');
								path += `/${tail}`;
							}
							path ? next(path) : next();
						} catch (e) {
							// console.log(e);

							if (e.response && parseInt(e.response.status) === 401) {
								Store.commit('changeErrorMessage', 'We are sorry but we are not able to authenticate you. Please contact your administrator.');
								next('/error');
							}

							try {
								window.sessionStorage.setItem('redirect_url', window.location.href);
								const maintenanceStatus = typeof (e.message) === 'string' ? JSON.parse(e.message) : e.message;
								if (maintenanceStatus && maintenanceStatus.Code) {
									next({
										name: 'maintenance',
										query: {
											restore: e.message.Detail.Operation.toLowerCase() === 'restore' ? 'true' : 'false',
											redirect_url: window.sessionStorage.getItem('redirect_url')
										}
									});
									return;
								}
							} catch {
							}

							if (e.response && e.response.data === 'Configuration is not available') {
								Store.commit('changeErrorMessage', e.response.data);
								next('/error');
							} else if ((/localStorage|sessionStorage/).test(e.message)) {
								throw new Error(e);
							} else {
								next(`/${to.params.clusterKey}`);
							}
						}
					}
				} catch (e) {
					let errorMessage = e.message;
					if ((/localStorage|sessionStorage/).test(errorMessage)) {
						errorMessage = 'Please allow all cookies in your browser to continue and try again';
					}
					Store.commit('changeErrorMessage', errorMessage);
					next('/error');
				}
			},
			children: [
				{
					path: 'theme',
					name: 'theme-details',
					component: ThemeDetails,
					beforeEnter: (to, from, next) => {
						to.params.componentSet = 'theme';
						next();
					}
				},
				{
					path: 'picklist-values',
					name: 'picklist-values',
					component: PicklistDetails
				},
				{
					path: 'admin-dashboard',
					name: 'admin-dashboard',
					component: AdminDashboard
				},
				{
					path: 'broker-dashboard',
					name: 'broker-dashboard',
					component: BrokerDashboard
				},
				{
					path: 'config-tool/:id',
					name: 'config-tool',
					component: ConfigTool
				},
				{
					path: 'create-room/:id',
					name: 'create-room',
					component: CreateRoomFromCrmWrapper
				},
				{
					path: 'wizard/:set',
					name: 'wizard',
					component: Wizard,
					params: true,
					props: true,
					beforeEnter: (to, from, next) => {
						to.params.previous = from.path;
						next();
					}
				},
				{
					path: 'email-campaign/:name/:id/:edit?',
					name: 'email-campaign-details',
					component: Wizard,
					params: true,
					props: true,
					beforeEnter: (to, from, next) => {
						if (from.fullPath === '/') {
							const breadcrumbs = JSON.parse(sessionStorage.getItem('wizard.breadcrumbs'));
							to.params.entryPoint = (breadcrumbs && breadcrumbs.entryPoint) || `${getBaseRouteUrl(to)}/listing-search-results`;
							to.params.prevRouteName = (breadcrumbs && breadcrumbs.prevRouteName) || 'Property listings';
						} else {
							const prevName = from.params.componentSet || from.params.name;
							to.params.entryPoint = from.fullPath;
							to.params.backward = from.params.componentSet;
							to.params.prevRouteName = prevName === 'listing-search-results'
								? 'Property listings'
								: prevName.replace('-', ' ').replace(/^./, prevName[0].toUpperCase());
						}
						to.params.isDetails = !to.params.edit;
						to.params.set = 'email-campaign';
						next();
					}
				},
				{
					path: ':componentSet?',
					name: 'component',
					components: {
						route: RouteResults,
						mobile: MobileRouteResults
					}
				},
				{
					path: ':type/:name?/:id/:componentSet?',
					name: 'details',
					component: RouteDetails,
					beforeEnter: (to, from, next) => {
						const defaultMenuItemPath = Store.getters.defaultMenuItemPath;
						if (defaultMenuItemPath && !sc.utils.isGuidValid(to.params.id)) {
							next(defaultMenuItemPath);
						}
						next();
					}
				}
			]
		}
	]
});
