<template>
	<div class='wizard-preview-sidebar-root' v-perfectscroll="{enabled: true}">
		<div class='sidebar-items' :style="`height: calc(100vh - ${heightHandler}px)`">
			<div class='sidebar-item sidebar-item-setup'>
				<div class='sidebar-item-head'>
					Setup
					<svg class='svg-icon' @click="updateActiveStep(1)" v-if="!isDetails">
						<use xlink:href='#action-icons-ms-entity-edit'></use>
					</svg>
				</div>
				<div v-if="getSetupStep" class="sidebar-item-content">
					<div v-for="(item, key) in getSetupStep.form" :key="key" class="item-setup-field" >
						<div class="item-setup-field-name">{{ item.title }}</div>
						<div class="item-setup-field-value">{{ item.value }}<template v-if="key === 'email'">{{ getDomain }}</template>
						</div>
					</div>
				</div>
			</div>
			<div class='sidebar-item sidebar-item-template'>
				<div class='sidebar-item-head'>
					Template
					<svg class='svg-icon' @click="updateActiveStep(2)" v-if="!isDetails">
						<use xlink:href='#action-icons-ms-entity-edit'></use>
					</svg>
				</div>
				<div class='sidebar-item-content' v-if="getSteps && getSteps[0]">
					<div class="item-template">
						<div class="item-template-img" v-if="getTemplate">
							<!-- TODO: rework to component -->
							<img :src='getTemplate && getTemplate.preview ? getTemplate.preview : `/Images/co-default-thumbnail.png`' />
						</div>
						<div class="item-template-text">
							{{getTemplate && getTemplate.templateName || 'no template'}}
						</div>
					</div>
				</div>
			</div>
			<div class='sidebar-item sidebar-item-listing'>
				<div class='sidebar-item-head'>
					{{collectionTitle}}
					<svg class='svg-icon' @click="updateActiveStep(3)" v-if="!isDetails">
						<use xlink:href='#action-icons-ms-entity-edit'></use>
					</svg>
				</div>
				<div v-if="getCollection" class="sidebar-item-content">
					<div
						v-for="item in getCollection"
						:key="item.Id"
						class="item-listing"
					>
						<div class="item-listing-img">
							<img :src='getImage(item)' />
						</div>
						<div class="item-listing-desc">
							<div class="item-listing-name">{{ item.Name }}</div>
							<div v-if="item.Source[collectionAddress] && item.Source[collectionAddress].address" class="item-listing-location">{{ item.Source[collectionAddress].address }}</div>
							<div class="item-listing-type">{{ item.Source.usetype }}</div>
						</div>
					</div>
				</div>
				<div v-else class="item-empty">
					Please select a collection
				</div>
			</div>
			<div class='sidebar-item sidebar-item-list'>
				<div class='sidebar-item-head'>
					Marketing List
					<svg class='svg-icon' @click="updateActiveStep(4)" v-if="!isDetails">
						<use xlink:href='#action-icons-ms-entity-edit'></use>
					</svg>
				</div>
				<div class='sidebar-item-content' v-if='getMarketList'>
					<div class="item-list" v-for="(item, i) in getMarketList" :key="i">
						{{item && item.Name || item.Source.name}}
					</div>
				</div>
                <div class="item-empty" v-else>
                    Please select recipients
                </div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { wizardMixin } from '@/Components/Wizard/Mixins/wizardMixin';
export default {
	name: 'email-campaign-preview-sidebar',
	mixins: [wizardMixin],
	data: () => ({ email: '' }),
	watch: {
		'getSetupStep.mcUser': {
			immediate: true,
			deep: true,
			handler(value) {
				if (this.getSetupStep.form.email.value.split('@')[0].length <= 1) this.email = '';
				else this.email = value.email && value.email.split('@')[1];
			}
		}
	},
	computed: {
		...mapState('wizard', ['steps', 'errors']),
		...mapGetters('wizard', ['getCollectionType', 'getUserDomains']),
		getDomain() {
			const allDomains = this.steps.find(step => step.name === 'setup').domains;
			if (allDomains.length && allDomains.some(i => i.selected)) {
				return allDomains.find(i => i.selected).displayName;
			} else return '';
		},
		getSteps() {
			return this.steps;
		},
		getSetupStep() {
			return this.getSteps.length && this.getSteps.find(step => step.name === 'setup');
		},
		getTemplate() {
			return this.getSteps.find(i => i.name === 'template').templates.find(i => i.selected);
		},
		getMarketList() {
			return (this.$store.state &&
				this.$store.state['wizard.ml'] &&
				this.$store.state['wizard.ml'].selection &&
				this.$store.state['wizard.ml'].selection.staticlist &&
				!!this.$store.state['wizard.ml'].selection.staticlist.length &&
				this.$store.state['wizard.ml'].selection.staticlist) ||
				(!!this.$store.state.wizard.steps.find(i => i.name === 'recepients').selection.length &&
				this.$store.state.wizard.steps.find(i => i.name === 'recepients').selection) || null;
		},
		getCollection() {
			switch (this.getCollectionType) {
			case 'listing':
				return this.listings || null;
			case 'availability':
				return this.availabilities || null;
			default:
				return null;
			}
		},
		collectionImage() {
			return this.getCollectionType === 'listing' ? 'listingimages' : 'availabilityimages';
		},
		collectionAddress() {
			return this.getCollectionType === 'listing' ? 'listingpropertyidproperty' : 'availabilitypropertyproperty';
		},
		heightHandler() {
			const currentStepAmount = this.steps.find(i => i.active).errors.length + this.errors.length;
			const margin = (currentStepAmount > 1 && 30) || 15;
			return currentStepAmount ? currentStepAmount * 45 + margin + 194 : 194;
		},
		collectionTitle() {
			switch (this.getCollectionType) {
			case 'listing':
				return 'Listings';
			case 'availability':
				return 'Availabilites';
			default:
				return 'Collection';
			}
		}
	},
	methods: {
		buildImageUrl(id) {
			const token = localStorage.getItem(sc.classes.get('authentication').getTokenKey(sc.cluster.key));
			return `${sc.classes.get('edge.dataProvider').getImageUrl('image', id, 'thumbnail')}?token=${token}`;
		},
		getImage(value) {
			/* if listing - return listing images */
			if (this.getCollectionType === 'listing') {
				return value.Source && value.Source[this.collectionImage] && value.Source[this.collectionImage].length > 0 ? this.buildImageUrl(value.Source[this.collectionImage][0].imageid) : '/Images/custom-image-placeholder.jpg';
			} else {
				/* if availability */
				const images = value.Source && value.Source[this.collectionImage];
				const parent = this.listings.find(el => el.Id === value.Source.listingid.id);
				if (images.length) {
					/* if availability has images return it */
					return this.buildImageUrl(value.Source[this.collectionImage][0].imageid);
				} else if (value.parentContext && value.parentContext.Source.listingimages.length) {
					/* if availability hasn't images - return listing image */
					return this.buildImageUrl(value.parentContext.Source.listingimages[0].imageid);
				} else if (!value.parentContext && parent && parent.Source.listingimages.length) {
					/* if han't parent context - try to find images in listing */
					return this.buildImageUrl(parent.Source.listingimages[0].imageid);
				}
				/* if there are no images availability and the parent listing - return default */
				return '/Images/custom-image-placeholder.jpg';
			}
		}
	}
};
</script>

<style lang='less'>
@import './previewSidebar.less';
</style>
