import Vue from 'vue';

Vue.directive('image', (() => {
	const displayImage = function (element, value) {
		// after removing JQuery from this directive - remove everything that left in (move to Legacy, remove from gulpfile and make changes in Pipeline) Scripts -> JQuery -> Plugins
		const placeholderClass = value.placeholder;
		let srcUrl = value.src;
		const width = value.width;
		const height = value.height;
		const hideBrokenImage = value.hideBrokenImage;
		const fit = value.fit;
		const $element = $(element);

		$element.css({
			width,
			height
		});

		const $image = $element.find('img');
		const $entityIcon = $element.find('.entity-icon');
		const $loadIndicator = $element.find('.loading-overlay');

		if ($element.data.src === srcUrl) {
			return;
		}
		if (placeholderClass) {
			$entityIcon.addClass(placeholderClass);
		}

		if (srcUrl) {
			// token parameter to handle authentication
			srcUrl += (srcUrl.indexOf('?') > -1) ? '&' : '?';
			srcUrl += 'token=' + localStorage.getItem(sc.classes.get('authentication').getTokenKey(sc.cluster.key));
		}
		$image.data.src = srcUrl;
		$image.css('object-fit', fit);
		$image.hide();

		if (srcUrl) {
			$loadIndicator.show();
			$image.one('load', function () {
				$loadIndicator.visible() ? $loadIndicator.fadeOut(300) : $loadIndicator.hide();
				$entityIcon.removeClass(placeholderClass);
				$image.fadeIn(400);
			}).attr('src', srcUrl);

			$image.one('error', function () {
				$loadIndicator.visible() ? $loadIndicator.fadeOut(300) : $loadIndicator.hide();
				if (!hideBrokenImage) {
					$entityIcon.removeClass(placeholderClass);
					$entityIcon.hide();
					$entityIcon.addClass('broken-file-icon');
					$entityIcon.fadeIn(400);
				}
			});
		} else {
			$loadIndicator.hide();
		}
	};
	return {
		inserted: function (element, binding) {
			$(element).data.src = 'init';
			$(element).append('<img/><i class=\'entity-icon\'></i><i class=\'loading-overlay\' style=\'display: none;\'></i>');
			$(element).addClass('image-node');
			displayImage(element, binding.value);
		},
		update: function (element, binding) {
			displayImage(element, binding.value);
		}
	};
})());
