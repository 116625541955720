var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.innerContext
    ? _c("control", {
        key: _vm.item ? _vm.item.Id : "",
        class: _vm.cssClass,
        attrs: {
          name: _vm.options.control.$type,
          contentProps: _vm.options.control,
          evaluationContext: _vm.innerContext
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }