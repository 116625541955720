import { isActive, mapParametersObject } from './Methods/action.methods';

export default {
	async enabled(options, evaluationContext, selection) {
		const parameters = mapParametersObject(options.parameter, evaluationContext);
		if (!parameters.entity && (!selection || !selection.length)) {
			return false;
		}
		if (!await isActive(evaluationContext, selection)) {
			return false;
		}
		return true;
	},

	async execute(options, context, selection) {
		const localizations = sc.classes.get('localization.dataProvider');
		const parameters = mapParametersObject(options.parameter, context);
		if (!parameters.entity && selection && selection.length) {
			parameters.entity = sc.classes.get('entityReference', selection[0]);
		}
		const entityData = await sc.classes.get('entityConfiguration.dataProvider').fetchEntity(context.eval(options.logicalname));
		const title = await localizations.getLabelForCurrentLanguage('entity.editor.titleCreate', [await context.evalAsync(entityData.displayName)]);

		sc.events.emit('vue.dialog.open', {
			icon: 'action-icons-search-save',
			title,
			allowPin: true,
			component: 'vue.editor',
			maximizedWidth: '80%',
			maximizedHeight: '100%',
			evaluationContext: context,
			logicalname: options.logicalname,
			relatedEntityLogicalName: options.relatedEntityLogicalName,
			modalNamespace: options.modalNamespace,
			stateNamespace: options.stateNamespace,
			subtype: options.subtype,
			forceCreate: true,
			toastMessageKey: context.eval(options.toastMessageKey),
			informationDialogTextKey: context.eval(options.informationDialogTextKey),
			parameters
		});
	}
};
