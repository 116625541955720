import { isNull, isNullOrUndefined } from '@acx-xms/data-functions/dist';
import ExpressionCDate from './cdate';

export default {
	parameters(options) {
		return [
			this.eval(options.basicDate),
			this.eval(options.span)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.basicDate),
			await this.evalAsync(options.span)
		];
	},
	evaluate(date, span) {
		date = ExpressionCDate.evaluate(date);
		if (isNull(date)) {
			return null;
		}
		if (isNullOrUndefined(span)) {
			return date;
		}
		return date.add(span);
	}
};
