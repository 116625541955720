import { isActive } from './Methods/action.methods';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import store from '@/States/ms-state';

export default {
	async enabled(options, context, selection) {
		if (selection && selection.length === 1 && await isActive(context, selection)) {
			const userInfo = await GetUserInfo();
			const isOrgAdmin = userInfo.CRMRoles.some(role => role.Name === 'Organization Admin');
			const isGuest = userInfo.CRMRoles.some(role => role.Name === 'Guest');
			const conversationMenuInit = store.getters['сonversationMenuInit'];
			const selectedEntity = selection[0].Type || selection[0].logicalname;

			if (isOrgAdmin) {
				const isParticipant = await conversationMenuInit.promise.then(_ => {
					const chatParticipants = store.getters.getUserChatParticipants || [];
					const result = chatParticipants.some(cp => {
						const participantRoomRef = cp[selectedEntity + 'id'];
						return participantRoomRef && participantRoomRef === (selection[0].Id || selection[0].id);
					});
					return result;
				});
				return isParticipant;
			} else if (isGuest && selectedEntity === 'dealroom') {
				const hasSignedNDA = await conversationMenuInit.promise.then(_ => {
					const currentUserParticipant = (store.getters.getUserChatParticipants || []).find(cp => selection[0].Id === cp.dealroomid && cp.systemuserid === userInfo.systemuserid) || {};
					return selection[0].Source.ndaid ? currentUserParticipant.ndasignindate : true;
				});

				return hasSignedNDA;
			} else {
				return true;
			}
		}

		return false;
	},
	async execute(options, context, selection) {
		const sel = selection && selection.length && selection[0];
		if (!sel) {
			return;
		}
		sc.events.emit('vue.dialog.open', {
			localizedTitle: 'common.dictionary.confirmDlgCaption',
			component: 'leave-room-dialog',
			minimumHeight: 'auto',
			evaluationContext: context,
			params: {
				sel,
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey)
			}
		});
	}
};
