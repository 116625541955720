<template>
	<BaseErrorWrapper :errorMessage="errorMessage">
		<button v-if="!isEmbedded" type="submit" @click="back">Back</button>
	</BaseErrorWrapper>
</template>
<script>
import BaseErrorWrapper from './base-error-wrapper';
import ErrorMixin from './error.mixin';

export default {
	name: 'error-wrapper',
	components: { BaseErrorWrapper },
	mixins: [ErrorMixin],
	data() {
		return { isEmbedded: false };
	},
	created() {
		if (!this.errorMessage && !navigator.cookieEnabled) {
			// for embedded demo page when navigating via browser
			this.$store.commit('changeErrorMessage', 'Please allow all cookies in your browser to continue and try again');
		}
		if (this.errorMessage && this.errorMessage.includes('Please allow all cookies in your browser to continue and try again') && !(window === window.parent)) {
			this.isEmbedded = true;
		}
	},
	methods: {
		back() {
			this.$router.push(`/${this.$route.params.clusterKey || ''}`);
		}
	}
};
</script>
