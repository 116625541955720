<template>
	<!-- tooltip-->
	<div v-bind:class="cssClass" class="loading-wrapper" v-if="loading">
		<div class="loading-overlay"></div>
	</div>
	<control v-else-if="!loading && visible"
			 v-bind:name="options.control.$type"
			 v-bind:contentProps="options.control"
			 v-bind:evaluationContext="innerContext"
			 v-bind:class="cssClass"></control>

</template>
<script>
import ProcessQuery from './../../Data/GroupedQueriesExtender';
import helperMethods from './../../Components/ComponentSet/component-set-evaluation-context-helper.methods';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'control-async-control',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return {
			loading: true,
			innerContext: null,
			visible: true,
			cssClass: []
		};
	},
	components: { Control: () => import('./../Entity/control.vue') },
	created() {
		this.fork = sc.events.fork();
		this.visible = this.evaluationContext.eval(this.options.enable || true);
		this.cssClass = this.options.cssClass ? this.evaluationContext.eval(this.options.cssClass) : [];
		this.resultsExtender = this.options.resultsExtender ? this.evaluationContext.eval(this.options.resultsExtender) : null;
	},
	async mounted() {
		const enabled = (this.options.query.enable) ? await this.evaluationContext.evalAsync(this.options.query.enable) : true;
		if (!enabled) {
			this.innerContext = createEvaluationContext({}, this.options.withParentContext ? this.evaluationContext : undefined);
			this.loading = false;
		} else {
			this.query = await sc.classes.get(this.options.query.query.$type, this.options.query.query, this.evaluationContext);

			this.searchFn(await this.query.toQuery());
			if (this.options.refreshNamespace) {
				this.namespace = this.evaluationContext.eval(this.options.refreshNamespace);
				this.fork.on(this.namespace + '.searching', this.refresh);
				this.$root.$on(this.namespace + '.searching', this.refresh);
			}
		}
	},
	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
		}
		if (this.namespace) {
			this.$root.$off(this.namespace + '.searching', this.refresh);
		}
	},
	methods: {
		async searchFn(query) {
			this.loading = true;
			const groupBySource = this.options.groupBySource ? this.evaluationContext.eval(this.options.groupBySource) : null;

			try {
				if (groupBySource && this.options.groupByTarget) {
					const response = await ProcessQuery(query.entities[0], query.filters);
					const results = response.Results.filter((record) => {
						const context = helperMethods.wrapResult(record);
						return helperMethods.eval(context, this.options.groupByTarget) === groupBySource;
					});
					this.innerContext = createEvaluationContext(results[0], this.options.withParentContext ? this.evaluationContext : undefined);
				} else {
					const data = await sc.classes.get('edge.dataProvider').search(query);
					let result = (this.options.collection) ? data.Results : (data.Results.length > 0) ? data.Results[0] : {};
					if (this.resultsExtender) {
						result = {
							...result,
							...this.resultsExtender
						};
					}
					this.innerContext = createEvaluationContext(result, this.options.withParentContext ? this.evaluationContext : undefined);
				}
			} catch (e) {
				this.innerContext = createEvaluationContext({}, this.options.withParentContext ? this.evaluationContext : undefined);
				console.error(e);
			} finally {
				this.loading = false;
			}
		},
		async refresh() {
			this.loading = true;
			this.searchFn(await this.query.toQuery());
		}
	}
};
</script>
<style scoped>
	.loading-wrapper {
		position: relative;
		min-height: 16px;
		min-width: 16px;
	}

		.loading-wrapper .loading-overlay {
			background-color: transparent;
		}
</style>
