function DayBehavior(days) {
	const daysCount = days;
	return {
		GetDateBounds() {
			const to = new Date();
			const from = new Date(Date.now() - (daysCount - 1) * 24 * 60 * 60 * 1000);
			return {
				to,
				from
			};
		},
		GroupData(records, color) {
			const arr = [];
			for (let i = daysCount - 1; i >= 0; i--) {
				const date = new Date(Date.now() - i * 24 * 60 * 60 * 1000);
				arr.push({
					data: 0,
					label: date.toLocaleDateString('en-US', {
						month: 'numeric',
						day: 'numeric'
					})
				});
			}

			const today = new Date();
			today.setHours(0);
			today.setMinutes(0);
			today.setSeconds(0);
			records.forEach((record) => {
				const created = new Date(record.Source.createdon);
				created.setHours(0);
				created.setMinutes(0);
				created.setSeconds(0);
				const timeDiff = today - created;
				let index;
				// when timeDiff = 0 it means that record was created today and milliseconds values between "today" and "created" coincided
				if (timeDiff === 0) {
					index = daysCount - 1;
				} else {
					index = daysCount - Math.ceil(Math.abs(timeDiff) / (1000 * 60 * 60 * 24));
				}
				arr[index].data++;
			});
			return {
				labels: arr.map(r => r.label),
				datasets: [
					{
						label: 'New',
						backgroundColor: color,
						data: arr.map(r => r.data)
					}
				]
			};
		}
	};
}

const YearBehavior = {
	GetDateBounds() {
		const to = new Date();
		const from = new Date();
		from.setFullYear(to.getFullYear() - 1);
		return {
			to,
			from
		};
	},
	GroupData(records, color) {
		const arr = [];
		const date = new Date();
		for (let i = 11; i >= 0; i--) {
			arr.unshift({
				data: 0,
				label: date.toLocaleDateString('en-US', { month: 'short' })
			});
			date.setMonth(date.getMonth() - 1);
		}
		const currentMonth = new Date().getMonth();
		records.forEach((record) => {
			const createdMonth = new Date(record.Source.createdon).getMonth();
			const index = createdMonth > currentMonth
				? createdMonth - currentMonth - 1
				: 11 - (currentMonth - createdMonth);
			arr[index].data++;
		});
		return {
			labels: arr.map(r => r.label),
			datasets: [
				{
					label: 'New',
					backgroundColor: color,
					data: arr.map(r => r.data)
				}
			]
		};
	}
};

function GetBehavior(type) {
	switch (type.value) {
	case 'week': return DayBehavior(7);
	case 'month': return DayBehavior(30);
	case 'year': return YearBehavior;
	}
}

const Behaviors = [
	{
		title: 'This Year',
		value: 'year',
		visible: true
	},
	{
		title: 'This Month',
		value: 'month',
		visible: true
	},
	{
		title: 'This Week',
		value: 'week',
		visible: true
	}
];
const DefaultBehavior = {
	title: 'This Week',
	value: 'week'
};
export default {
	GetBehavior,
	Behaviors,
	DefaultBehavior
};
