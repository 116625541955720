import { changeStepHandler } from './helpers/changeStepHandler';
import {
	mapState, mapActions, mapGetters
} from 'vuex';
export const wizardMixin = {
	computed: {
		...mapState('wizard', ['steps']),
		...mapGetters('wizard', ['stepsAmount', 'activeStepNumber', 'isEdited', 'isSaved', 'geteSelectedTemplate', 'isForceExit', 'getMCUserID']),
		getSet() {
			return this.$route.params && this.$route.params.set ? this.$route.params.set : null;
		},
		getStepName() {
			return this.activeStepNumber ? `Step${this.activeStepNumber}` : null;
		},
		getStepsAmount() {
			return this.stepsAmount;
		},
		getStepConfig() {
			return this.steps ? this.steps.find(i => i.active) : null;
		},
		isDetails() {
			return this.$route.params && this.$route.params.isDetails;
		},
		withEditMode() {
			return !!(this.$route.params && this.$route.params.edit);
		},
		listings() {
			return (this.$store.state && this.$store.state['wizard.listing'] &&
            this.$store.state['wizard.listing'].selection &&
            this.$store.state['wizard.listing'].selection.listing &&
            Array.isArray(this.$store.state['wizard.listing'].selection.listing) &&
            !!this.$store.state['wizard.listing'].selection.listing.length &&
            this.$store.state['wizard.listing'].selection.listing) ||
            (!!this.$store.state.wizard.steps.find(i => i.name === 'collection').selection.listing.length &&
            this.$store.state.wizard.steps.find(i => i.name === 'collection').selection.listing) || null;
		},
		availabilities() {
			return (this.$store.state && this.$store.state['wizard.listing'] &&
            this.$store.state['wizard.listing'].selection &&
            this.$store.state['wizard.listing'].selection.availability &&
            Array.isArray(this.$store.state['wizard.listing'].selection.availability) &&
            !!this.$store.state['wizard.listing'].selection.availability.length &&
            this.$store.state['wizard.listing'].selection.availability) ||
            (!!this.$store.state.wizard.steps.find(i => i.name === 'collection').selection.availability &&
            !!this.$store.state.wizard.steps.find(i => i.name === 'collection').selection.availability.length &&
            this.$store.state.wizard.steps.find(i => i.name === 'collection').selection.availability) || null;
		},
		recipients() {
			return (this.$store.state &&
            this.$store.state['wizard.ml'] &&
            this.$store.state['wizard.ml'].selection &&
            this.$store.state['wizard.ml'].selection.staticlist &&
            !!this.$store.state['wizard.ml'].selection.staticlist.length &&
            this.$store.state['wizard.ml'].selection.staticlist) ||
            (!!this.$store.state.wizard.steps.find(i => i.name === 'recepients').selection.length &&
            this.$store.state.wizard.steps.find(i => i.name === 'recepients').selection) || null;
		},
		getCollectionType() {
			return this.steps.find(i => i.name === 'template').collectionType || null;
		},
		isCollectionSelected() {
			if (this.getCollectionType) {
				return this.getCollectionType === 'listing' ? !!this.listings : !!this.availabilities;
			} else return false;
		}
	},
	methods: {
		...mapActions('wizard', ['updateActiveStep', 'updateNavigation']),
		changeStep(data) {
			this.updateActiveStep(changeStepHandler(this.activeStepNumber, this.getStepsAmount, data));
		},
		setNavigation(data) {
			this.updateNavigation(data);
		},
		changeStepName() {
			const TYPES = {
				listing: 'listings',
				availability: 'availabilities'
			};
			const TYPED_NAME = this.geteSelectedTemplate && Object.keys(TYPES).includes(this.geteSelectedTemplate.inputTypes) && TYPES[this.geteSelectedTemplate.inputTypes];
			const STEP_NAME = Object.keys(TYPES).find(type => this.steps[2].selection[type].length);
			this.setCaptions({
				1: 'Setup',
				2: 'Template',
				3: (TYPED_NAME || STEP_NAME || this.getCollectionType) || 'Collection',
				4: 'Recipients',
				5: 'Summary'
			});
		}
	}
};
