<template>
	<div class="create-room-from-crm-wrapper" v-if="roomType">
		<CreateDealroomFromCrm v-if="roomType==='dealroom'" :options="roomData" />
		<CreateCollaborationroomFromCrm v-else-if="roomType==='collaborationroom'" :options="roomData" />
	</div>
</template>
<script>
import crmSyncDataProvider from '@/Data/DataProviders/crmSyncDataProvider.js';
import CreateDealroomFromCrm from './CreateDealRoomFromCrm/create-dealroom-from-crm';
import CreateCollaborationroomFromCrm from './CreateCollaborationRoomFromCrm/create-collaborationroom-from-crm';
import { getBaseRouteUrl } from '@acx-xms/data-functions/dist';

export default {
	name: 'create-room-from-crm-wrapper',
	props: { options: Object },
	components: {
		CreateDealroomFromCrm,
		CreateCollaborationroomFromCrm
	},
	data() {
		return {
			roomType: null,
			roomData: null
		};
	},

	async created() {
		crmSyncDataProvider.pluginName = 'sync';
		const roomData = await crmSyncDataProvider.getRoomData(this.$route.params.id);
		if (!roomData) {
			this.$router.replace({ path: `${getBaseRouteUrl(this.$route)}/listing-search-results` });
			return;
		}

		this.roomData = {
			dealName: roomData.dealName,
			sfPropertyId: roomData.propertyId,
			sfcontactIds: roomData.contactIds,
			sfdealcrmid: roomData.dealId,
			sfListingId: roomData.listingId
		};

		if (['TenantRepresentation', 'BuyerRepresentation'].indexOf(roomData.dealType) > -1) {
			this.roomType = 'collaborationroom';
		} else if (['LandlordRepresentation', 'SellerRepresentation'].indexOf(roomData.dealType) > -1) {
			this.roomType = 'dealroom';
		}
	}
};
</script>
<style>
	.create-room-from-crm-wrapper {
		height: 100%
	}
</style>
