import Vue from 'vue';

Vue.directive('kocomponent', {
	inserted: function (element, binding) {
		const valueObservable = binding.value;
		if (valueObservable.$bindProperties) {
			element.setAttribute('data-bind', 'component: $bindProperties');
		} else {
			element.setAttribute('data-bind', 'component: {name:$type, params: $data}');
		}

		ko.applyBindings(valueObservable, element);
	},
	update: function (element, binding) {
		const valueObservable = binding.value;
		ko.cleanNode(element);
		if (valueObservable.$bindProperties) {
			element.setAttribute('data-bind', 'component: $bindProperties');
		} else {
			element.setAttribute('data-bind', 'component: {name:$type, params: $data}');
		}

		ko.applyBindings(valueObservable, element);
	}
});
