<template>
	<div class="message-counter">
		<span class="count">{{croppedCount}}</span>
	</div>
</template>
<script>
export default {
	name: 'counter',
	props: {
		count: Number,
		maxCount: {
			type: Number,
			default: 9
		}
	},
	computed: {
		croppedCount() {
			return (this.count > this.maxCount) ? `${this.maxCount}+` : this.count;
		}
	}
};
</script>
<style src="./counter.less" scoped></style>
