import entityDeactivateBase from './Methods/action.entity.deactivateBase';
import { isUserCoownerOrOwner } from './Methods/action.methods';

export default {
	async enabled(options, evaluationContext, selection) {
		return await isUserCoownerOrOwner(evaluationContext) && entityDeactivateBase.enabled(options, evaluationContext, selection);
	},
	async execute(options, context, selection) {
		entityDeactivateBase.execute(options, context, selection);
	}
};
