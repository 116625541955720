<template>
	<div class="content-loader">
		<initialization-panel v-if="message" :message="message"></initialization-panel>
		<component v-if="!message" v-bind:is="layoutName" v-bind="layoutOptions" />
	</div>
</template>
<script>
import authAxios from '@/Data/auth-axios';
import InitializationPanel from '@/Components/Initialization/InitializationLoader/initialization-panel';
import { GetAvailableLayouts, LaunchModules } from '@/Data/Auth/authentication-service';
import { prepareComponentName, colorHandler } from '@acx-xms/data-functions/dist';
import LayoutMarketspace from '@/Components/Layout/Layouts/main/layout-marketspace.vue';
import LayoutMarketspaceMobile from '@/Components/Layout/Layouts/mobile/mobile-layout-marketspace.vue';
import LayoutMarketspaceHost from '@/Components/Layout/Layouts/host/layout-marketspace-host.vue';
import LayoutMarketspaceEmbedded from '@/Components/Layout/Layouts/embedded/layout-marketspace-embedded.vue';
import LayoutMarketspaceAccessbylink from '@/Components/Layout/Layouts/accessbylink/layout-marketspace-accessbylink.vue';
import themesDataProvider from '@/Data/DataProviders/themesDataProvider';
import { getCurrentConfigTemplates } from '@/Data/DataProviders/templatesDataProvider';
import { GetCrmSetting } from '@/Data/DataProviders/crmSyncDataProvider.js';
import {
	createEvaluationContext, evaluate, evaluateAsync, evaluateAsyncFake
} from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'configurations-wrapper',
	data() {
		return {
			layoutName: null,
			layoutOptions: null,
			message: ''
		};
	},
	components: {
		LayoutMarketspace,
		LayoutMarketspaceMobile,
		LayoutMarketspaceHost,
		LayoutMarketspaceEmbedded,
		LayoutMarketspaceAccessbylink,
		InitializationPanel
	},
	async created() {
		this.message = 'Loading selected configuration';
		// move to environment variables this after everything is migrated to ES6 modules
		sc.createEvaluationContext = createEvaluationContext;
		sc.evaluate = evaluate;
		sc.evaluateAsync = evaluateAsync;
		sc.evaluateAsyncFake = evaluateAsyncFake;
		const env = process.env.NODE_ENV || '';
		if (env.trim() === 'development') {
			// environment variables go here
			const [edgeFetch] = await Promise.all([import('@acx-xms/data-functions')]);
			sc.edgeFetch = edgeFetch;
		}
		try {
			const content = await this.getConfigData(this.$route.params.config);
			// no need to action here, there is router redirect happening
			if (!content) return;

			sc.cluster.isConfigLoaded = true;
			sc.cluster.configVersion = content.configVersion;
			sc.cluster.features = this.processFeatures(content.features);
			sc.cluster.idleExpirationTimeout = content.idleExpirationTimeout;
			sc.cluster.idleWarningTimeout = content.idleWarningTimeout;
			sc.cluster.langs = content.langs;
			sc.cluster.modules = content.modules;
			sc.cluster.trackUserActivities = content.trackUserActivities;

			content.pluginsSpriteUrl && content.pluginsSpriteUrl.forEach((item) => {
				const link = document.createElement('link');
				link.rel = 'stylesheet';
				link.href = item;
				document.head.appendChild(link);
			});

			content.overrideClasses && content.overrideClasses.forEach((pluginClass) => {
				document.getElementById('page-wrapper').classList.add(pluginClass);
			});

			const svg = document.documentElement;
			const svgNS = svg.namespaceURI;
			const el = document.createElementNS(svgNS, 'svg');
			el.innerHTML = content.svgSprite;
			document.body.appendChild(el);

			const availableLayouts = await GetAvailableLayouts(this.$route.params.config);

			const layout = availableLayouts[this.$route.params.layout];
			this.layoutName = prepareComponentName(layout.$type);
			this.layoutOptions = { ...layout };
			delete this.layoutOptions.$type;
			// todo - remove this and everything related
			// sc.classes.get('adaptiveController', content.compactModeParams);

			// apply css variables to root
			content.theme.common.forEach((item) => {
				if ((/__darken|__lighten/).test(item.name)) {
					colorHandler(item.name, item.value);
				} else {
					document.documentElement.style.setProperty('--' + item.name, item.value);
				}
			});
			// apply advanced css variables
			Object.keys(content.theme.advanced).forEach((key) => {
				content.theme.advanced[key].forEach((item) => {
					if ((/__darken|__lighten/).test(item.name)) {
						colorHandler(item.name, item.value, 'advanced', key);
					} else {
						document.documentElement.style.setProperty('--' + key + '_' + item.name, item.value);
					}
				});
			});

			// sc.classes.get('additionalParameters.controller', content.theme.additionalParameters);

			const stylesToLoad = 0;
			let pluginsToLoad = 0;
			this.message = 'Loading additional content';
			const finalize = async () => {
				// if sc.classes.complete is undefined - we already have loaded our app
				// TODO: rethink this
				if (sc.classes.complete) {
					sc.classes.complete();

					sc.events = sc.classes.get('events');
					await LaunchModules();

					if (sc.cluster.features) {
						sc.cluster.features.theme && await themesDataProvider.applyCurrentTheme();
						sc.cluster.features.configtemplates && await getCurrentConfigTemplates();

						const crmsettings = sc.cluster.features.crmsettings
							? await GetCrmSetting()
							: { Total: 0 };
						sc.utils.hasCRMsettings = !!crmsettings.Total;
					} else {
						sc.utils.hasCRMsettings = false;
					}
				}
				this.message = null;
			};
			if (content.pluginScriptUrls && content.pluginScriptUrls.length) {
				content.pluginScriptUrls.forEach((item) => {
					pluginsToLoad++;
					const script = document.createElement('script');
					script.src = item;
					script.onload = () => {
						pluginsToLoad--;
						if (pluginsToLoad === 0) {
							if (sessionStorage.getItem('hash')) {
								window.location.hash = sessionStorage.getItem('hash');
								sessionStorage.removeItem('hash');
							}
							if (stylesToLoad === 0 && pluginsToLoad === 0) {
								finalize();
							}
						}
					};
					document.body.appendChild(script);
				});
			} else {
				finalize();
			}
		} catch (e) {
			this.$store.commit('changeErrorMessage', e.message);
			this.$router.push('/error');
			this.message = null;
		}

		/* Token deletion handling */
		window.addEventListener('storage', this.tokenDeletionHandling);
	},
	methods: {
		async getConfigData(config) {
			let configResp = null;
			try {
				configResp = await authAxios.get(`/api/Authentication/content/${config}/${this.$route.params.layout}`);
				// todo - remove it after everything is on vue
				sc.cluster.configKey = config;
			} catch (e) {
				// needed if user tried to open secured or not existing config with parameters
				const routeParams = this.$route.params;

				if (routeParams.type) {
					const tail = routeParams.type + (routeParams.name ? '/' + encodeURIComponent(routeParams.name) : '') + (routeParams.id ? '/' + routeParams.id : '');
					sessionStorage.setItem('routeTail', tail);
				}
				this.$router.replace(`/${this.$route.params.clusterKey}`);
				return;
			}

			return configResp && configResp.data;
		},

		processFeatures(features) {
			const defaultFeatures = {
				theme: true,
				crmsettings: true,
				configtemplates: true,
				license: true,
				trackuseractivity: true,
				termsofuseandprivacypolicy: true,
				pagePermissionsSettings: true
			};

			if (features.defaultfeatureset) {
				delete features.defaultfeatureset;
				return {
					...defaultFeatures,
					...features
				};
			} else {
				return features;
			}
		},

		tokenDeletionHandling(event) {
			const currentKey = sc.classes.get('authentication').getTokenKey(sc.cluster.key); /* Get auth token key */
			if (event.key === currentKey && !event.newValue) { /* Redirect on url if new auth token is null */
				window.location.replace(`${window.location.origin}/${this.$route.params.clusterKey}Anonymous`);
			}
		}
	},
	beforeDestroy() {
		/* preventing bug with removing events on reloading */
		window.removeEventListener('storage', this.tokenDeletionHandling);
	}
};
</script>
<style>
</style>
