var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ms-panel ms-conversations-panel popup-shaded" },
    [
      _vm.isLoading ? _c("div", { staticClass: "loading-overlay" }) : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "panel-list" }, [
        _c(
          "div",
          { staticClass: "tabs" },
          _vm._l(_vm.tabsData, function(tab) {
            return _c(
              "div",
              {
                key: tab.key,
                class: ["tab-title", { active: tab.active }],
                on: {
                  click: function($event) {
                    _vm.loadTabDataBase(tab)
                  }
                }
              },
              [
                _c("p", { staticClass: "text" }, [_vm._v(_vm._s(tab.title))]),
                _vm._v(" "),
                _vm.totalCounter && _vm.totalCounter[tab.logicalname]
                  ? _c("Counter", {
                      attrs: { count: _vm.totalCounter[tab.logicalname] }
                    })
                  : _vm._e()
              ],
              1
            )
          })
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "perfectscroll",
                rawName: "v-perfectscroll",
                value: { addMouseoverEvent: true, refreshScroll: false },
                expression: "{ addMouseoverEvent: true, refreshScroll: false }"
              }
            ],
            staticClass: "tab-content"
          },
          [
            _vm.tabContent.length
              ? _vm._l(_vm.tabContent, function(tabEntity) {
                  return _c("Control", {
                    key: tabEntity.Id + "_" + tabEntity.Version,
                    class: [
                      "striped",
                      {
                        active:
                          _vm.selectedEntity &&
                          tabEntity.Id === _vm.selectedEntity.Id
                      }
                    ],
                    attrs: {
                      name: _vm.currentTab.messageTemplate.$type,
                      contentProps: _vm.currentTab.messageTemplate,
                      evaluationContext: _vm.getContext(tabEntity)
                    },
                    nativeOn: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.openDetails(tabEntity)
                      }
                    }
                  })
                })
              : !_vm.tabContent.length && !_vm.isLoading
              ? _c("div", { staticClass: "no-results-message" }, [
                  _c("div", {
                    directives: [
                      {
                        name: "localization",
                        rawName: "v-localization",
                        value: { key: "common.dictionary.noMatchesFound" },
                        expression:
                          "{ key: 'common.dictionary.noMatchesFound' }"
                      }
                    ],
                    style: { width: "100%" }
                  })
                ])
              : _vm._e()
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm.selectedEntity
        ? _c(
            "MsConversationDetails",
            _vm._b(
              {
                key: _vm.selectedEntity.Id,
                on: { closeDetails: _vm.closeDetails }
              },
              "MsConversationDetails",
              {
                entity: _vm.selectedEntity,
                evaluationContext: _vm.getContext(_vm.selectedEntity),
                detailsTemplate: _vm.currentTab.detailsTemplate
              },
              false
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }