import {
	Create, Search, SendRequest
} from '@acx-xms/data-functions/dist';
import store from '@/States/ms-state';

export default {
	async enabled(options, evaluationContext, selection) {
		return true;
	},

	async execute(options, context, selection) {
		this.parentRef = context.entity;
		const componentSet = await sc.classes.get('marketspaceComponents.dataProvider').getComponentSetById(context.eval(options.componentsetName));
		const componentSetCreate = await sc.classes.get('marketspaceComponents.dataProvider').getComponentSetById(context.eval(options.createComponentsetName));
		const params = {};
		if (!this.parentRef.id) {
			const listingFilter = {
				data: ['00000000-0000-0000-0000-000000000000'],
				entity: 'listing',
				requestLabel: 'Ids'
			};
			const availabilityFilter = {
				data: ['00000000-0000-0000-0000-000000000000'],
				entity: 'availbility',
				requestLabel: 'Ids'
			};
			sc.events.emit('vue.dialog.open', {
				icon: 'entity-icons-deal-small',
				title: 'Create Static List',
				allowPin: false,
				component: 'ms.addlisting.dialog',
				maximizedWidth: '90%',
				maximizedHeight: '100%',
				evaluationContext: context,
				params: {
					componentSet: componentSetCreate,
					modalNamespace: 'addlistingpopup',
					searchResultComponentName: options.searchResultComponentName,
					defaultFilters: {
						listing: listingFilter,
						availability: availabilityFilter
					},
					modalDialogCallback: (listings, availabilities, name) => {
						return this.addListingsOnCreate(listings, (availabilities && availabilities.length > 0 ? availabilities : []), name)
							.then(() => sc.events.emit(context.eval(options.refreshNamespace) + '.searching'));
					},
					toastMessageKey: context.eval(options.toastMessageKey),
					informationDialogTextKey: context.eval(options.informationDialogTextKey),
					customName: ''
				}
			});
		}
		sc.events.emit('vue.dialog.open', {
			icon: 'entity-icons-deal-small',
			title: 'Add Listing',
			allowPin: false,
			component: 'ms.addlisting.dialog',
			maximizedWidth: '90%',
			maximizedHeight: '100%',
			evaluationContext: context,
			params: {
				componentSet,
				modalNamespace: 'addlistingpopup',
				searchResultComponentName: options.searchResultComponentName,
				modalDialogCallback: (listings, availabilities) => {
					return this.addListings(listings, (availabilities && availabilities.length > 0 ? availabilities : []))
						.then(() => sc.events.emit(context.eval(options.refreshNamespace) + '.searching'));
				},
				refreshNamespace: context.eval(options.refreshNamespace),
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey),
				...params
			}
		});
	},
	async addListingsOnCreate(listings, availabilities, name) {
		const record = await Create('staticlist',
			{
				name,
				membertype: {
					id: '4d8a18bd-ac8a-428b-90c6-7ffe70f8034a',
					logicalname: 'lookupmembertype'
				}
			}
		);
		this.parentRef = sc.classes.get('entityReference', record);
		const records = [...listings, ...availabilities].map(entity => {
			const memberRef = sc.classes.get('entityReference', entity);
			return {
				staticlist: {
					id: record.staticlistid,
					logicalname: 'staticlist'
				},
				entityreference: sc.classes.get('entityReference', memberRef),
				name: memberRef.name,
				type: 'staticlistmember'
			};
		});
		if (records.length) {
			SendRequest('/api/MarketingList/CreateMembers', 'POST', records);
		}
	},
	async addListings(listings, availabilities) {
		let records = [];
		const selected = [];
		const promiseArr = [];
		if (this.parentRef.id) {
			[...listings, ...availabilities].forEach((entity) => {
				const memberRef = sc.classes.get('entityReference', entity);
				promiseArr.push(Search('staticlistmember',
					[
						sc.classes.get('termFacet.filter', {
							logicalName: 'staticlist.id',
							query: [this.parentRef.id]
						}).fillQuery(),
						sc.classes.get('termFacet.filter', {
							logicalName: 'entityreference.id',
							query: [memberRef.id]
						}).fillQuery(),
						sc.classes.get('selectedFields.filter', []).fillQuery()
					]
				));
				selected.push(memberRef);
			});
			const searchData = await Promise.all(promiseArr);
			records = selected.filter((_, index) => searchData[index].Total === 0).map(record => {
				return {
					staticlist: {
						id: this.parentRef.id,
						logicalname: 'staticlist'
					},
					entityreference: record,
					name: record.name,
					type: 'staticlistmember'
				};
			});
		}
		if (records.length) {
			const createdMembers = await SendRequest('/api/MarketingList/CreateMembers', 'POST', records);
			createdMembers.forEach(({ body, status }) => {
				if (status === 200) {
					this.addListingsToStore(body.entityreference);
				}
			});
		} else {
			[...listings, ...availabilities].forEach(record => {
				const memberRef = sc.classes.get('entityReference', record);
				this.addListingsToStore(memberRef);
			});
		}
		// TODO: implement multiple refresh events for actions
		sc.events.emit('mainSearch.marketspace.staticlistedit.searching');
		sc.events.emit('mainSearch.marketspace.staticlistedit.availabilities.searching');
		sc.events.emit('mainSearch.marketspace.staticlists.searching');
	},
	addListingsToStore(record) {
		this.stateNamespace = 'mainSearch.marketspace.staticlistedit';
		const filters = store.getters[this.stateNamespace + '/getFiltersRaw'](record.logicalname);
		Object.keys(filters).forEach(f => {
			if (filters[f] && filters[f].requestLabel === 'Ids') {
				filters[f].data.push(record.id);
			}
		});
		store.commit(this.stateNamespace + '/replaceAllFilters', {
			entity: record.logicalname,
			filters
		});
	}
};
