import { mapAsync } from '@acx-xms/data-functions/dist';

export default {
	parameters(options) {
		return options.argument.map(this.eval);
	},
	async parametersAsync(options) {
		return await mapAsync(options.argument, this.evalAsync);
	},
	evaluate(left, right) {
		return left <= right;
	}
};
