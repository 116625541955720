import * as tracking from '@/Data/trackingModule';

export default {
	methods: {
		async openDialog() {
			const withExtendedGallery = this.evaluationContext.eval(this.options.withExtendedGallery);
			if (withExtendedGallery && this.items.length) {
				sc.events.emit('vue.dialog.open', {
					component: 'image-gallery-dialog',
					images: this.items,
					maximizedWidth: '80%',
					maximizedHeight: '100%',
					parentEntity: this.parentRef,
					evaluationContext: this.evaluationContext,
					menu: this.isItemsFromMainSource ? this.options.menu : null
				});

				tracking.AddUserActivity('imagegallery.open', 'Image gallery open', this.parentRef);
			}
		}
	}
};
