import Vue from 'vue';

export default {
	namespaced: true,
	state() {
		return {
			counters: null,
			notificationCounters: {},
			favoritesCounter: 0,
			messages: [],
			chatParticipant: null
		};
	},
	getters: {
		chatTotalCount: state => {
			const totalCounters = {};
			state.counters && Object.values(state.counters).forEach(({ counter, chatlogicalname }) => {
				if (totalCounters[chatlogicalname]) {
					totalCounters[chatlogicalname] += counter;
				} else {
					totalCounters[chatlogicalname] = counter;
				}
			});
			return (state.counters && totalCounters) || null;
		},
		getUnreadMessagesCount: state => {
			return (state.counters && Object.values(state.counters).reduce((acc, { counter }) => {
				return acc + counter;
			}, 0)) || 0;
		},
		getMessageByChatId: state => id => {
			return state.messages.filter(msg => msg.chatid === id).sort((a, b) => b.message.Source.createdonfromui - a.message.Source.createdonfromui)[0];
		},
		getAllMessages: state => {
			return state.messages;
		},
		getChatsCount: state => state.messages.length,
		getCounterByChatId: state => id => state.counters[id]
	},
	mutations: {
		setCounters(state, counters) {
			state.counters = counters;
		},
		addCounter(state, counters) {
			Object.keys(counters).forEach(key => {
				if (state.counters[key]) {
					Vue.set(state.counters[key], 'counter', state.counters[key].counter += counters[key].counter);
				} else {
					Vue.set(state.counters, key, counters[key]);
				}
			});
		},
		removeCounter(state, counters) {
			Object.keys(counters).forEach(key => {
				if (state.counters[key]) {
					Vue.set(state.counters[key], 'counter', state.counters[key].counter -= counters[key].counter);
					if (state.counters[key].counter === 0) {
						Vue.delete(state.counters[key]);
					}
				}
			});
		},
		removeAllCountersForChat(state, chatid) {
			if (state.counters[chatid]) {
				Vue.delete(state.counters, chatid);
			}
		},
		setMessages(state, messages) {
			if (!state.messages.length) {
				state.messages = messages;
			} else {
				const itemstopush = [];
				messages.forEach(newmsg => {
					const item = state.messages.find(msg => msg.chatid === newmsg.chatid);
					if (item) {
						item.message = newmsg.message;
					} else {
						itemstopush.push(newmsg);
					}
				});

				if (itemstopush.length) {
					state.messages.push.apply(state.messages, itemstopush);
				}
			}
		},
		/* Update message by filters */
		updateMessage(state, { filters, data }) {
			if (!state.messages.length || !Object.keys(filters).length) return;
			/* find first array by filters */
			const messageID = state.messages.findIndex(msg =>
				msg.chatid === filters.chatid && /* by chat id */
				msg.message.Source.contactid.id === filters.contactid && /* by contact id */
				new Date(msg.message.Source.createdonfromui).getTime() === new Date(filters.createdonfromui).getTime() /* by created date */
			);
			if (messageID < 0) return;
			/* replace finded message */
			state.messages.splice(messageID, 1, data);
		},
		addNotificationCounters(state, counters) {
			for (const c in counters) {
				state.notificationCounters[c] = counters[c];
			}
		},
		removeNotificationCounters(state) {
			state.notificationCounters = {};
		},
		setFavoritesCounter(state, counter) {
			state.favoritesCounter = counter;
		},
		changeFavoritesCounter(state, value) {
			state.favoritesCounter += value;
		},
		setChatParticipant(state, value) {
			state.chatParticipant = value;
		}
	}
};
