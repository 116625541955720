var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "searchResults-map-view" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.mapFullScreen,
            expression: "!mapFullScreen"
          }
        ],
        staticClass: "results",
        style: { width: _vm.resultsWidth }
      },
      [
        _vm.options.component
          ? _c(
              _vm.prepareComponentName(_vm.options.component.$type),
              _vm._b(
                { tag: "component" },
                "component",
                {
                  options: _vm.componentOptions,
                  paginationParams: _vm.paginationParams,
                  evaluationContext: _vm.evaluationContext,
                  results: _vm.results,
                  stateNamespace: _vm.stateNamespace
                },
                false
              )
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _vm.isActive
      ? _c(
          "div",
          {
            directives: [
              {
                name: "mapSearchResults",
                rawName: "v-mapSearchResults",
                value: _vm.resultsOptions,
                expression: "resultsOptions"
              }
            ],
            class: ["map-search-results", { fullscreen: _vm.mapFullScreen }]
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isMapReady,
                  expression: "!isMapReady"
                }
              ],
              staticClass: "loading-overlay"
            })
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "scMap" }, [
      _c("div", { staticClass: "sc-map-holder" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }