var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "component-search-results is-parent" }, [
    _c(
      "div",
      { staticClass: "component-search-results-details" },
      [
        _vm.component
          ? _c(
              _vm.prepareComponentName(_vm.component.template.content.$type),
              _vm._b(
                { tag: "component" },
                "component",
                {
                  scrollNamespace: _vm.component.scrollNamespace,
                  template: _vm.component.template,
                  defaultRoute: _vm.defaultRoute,
                  trackActivity: !!_vm.component.trackUserActivity,
                  accessByLink: _vm.accessByLink,
                  redirectRouteIfNotExist: _vm.redirectRouteIfNotExist
                },
                false
              )
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isLoading
          ? _c("div", { staticClass: "loading-wrapper" }, [
              _c("div", { staticClass: "loading-overlay" })
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.component && !_vm.isLoading
          ? _c("div", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "common.dictionary.recordDetails.noAccess" },
                  expression:
                    "{key: 'common.dictionary.recordDetails.noAccess'}"
                }
              ],
              staticClass: "no-data"
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }