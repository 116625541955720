import entityDeactivateBase from './Methods/action.entity.deactivateBase';

export default {
	async enabled(options, evaluationContext, selection) {
		return entityDeactivateBase.enabled(options, evaluationContext, selection);
	},
	async execute(options, context, selection, fullSelection) {
		if (options.takefullSelection) {
			let records = [];
			Object.values(fullSelection).forEach(selection => {
				records = selection.length && records.concat(selection);
			});
			selection = records;
		}
		entityDeactivateBase.execute(options, context, selection, fullSelection);
	}
};
