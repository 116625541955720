var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible"
            }
          ],
          staticClass: "flexible-container",
          class: _vm.getCssClassValue(_vm.customOptions.cssClass),
          style: { "flex-direction": _vm.flexDirection, "flex-wrap": _vm.wrap }
        },
        _vm._l(_vm.customOptions.item, function(item, index) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: item.control.visible,
                  expression: "item.control.visible"
                }
              ],
              key: index,
              staticClass: "flexible-item",
              class: _vm.getCssClassValue(item.cssClass),
              style: _vm.getStyleProperties(item)
            },
            [
              _c("control", {
                attrs: {
                  name: item.control.$type,
                  contentProps: item.control,
                  evaluationContext: _vm.evaluationContext
                }
              })
            ],
            1
          )
        })
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }