<template>
	<action-menu-base	:evaluationContext="context"
						:menuItemsToolbar="toolbarActions"
						:menuItemsDropdown="dropDownActions"
						:hasDropDownItems="hasDropDownItems"
						:shareActions="shareActions"
						:hasShareActions="hasShareActions"
						:combinedNamespace="combinedNamespace"
						@execute="executeCallback">
	</action-menu-base>
</template>
<script>
import ActionMenuBase from './action-menu-base';
import MenuMixins from './menu.mixins';

export default {
	name: 'reactive-action-menu',
	props: { stateNamespace: String },
	components: { ActionMenuBase },
	mixins: [MenuMixins],
	data() {
		return {
			hasDropDownItems: false,
			hasShareActions: false,
			toolbarActions: [],
			dropDownActions: [],
			shareActions: [],
			currentSelection: {},
			entityType: this.options.entityType && this.options.entityType[0]
		};
	},
	methods: {
		executeCallback(action) {
			this.executeAction(action, this.context, this.currentSelection[this.entityType], this.currentSelection);
		}
	},
	created() {
		this._dropDownPromiseArr = [];

		if (this.options && this.options.menu) {
			this.hasDropDownItems = true;
			this._dropDownPromiseArr.push(this.fillActionArrayAsync(this.options.menu.item, this.context, [], 'dropDownActions'));
		}

		if (this.options && this.options.toolbarActions) {
			this.fillActionArrayAsync(this.options.toolbarActions.item, this.context, [], 'toolbarActions');
		}

		if (this.options && this.options.sharemenu) {
			this.hasShareActions = true;
			this.fillActionArrayAsync(this.options.sharemenu.item, this.context, [], 'shareActions');
		}
	},
	mounted() {
		if (this.stateNamespace && this.options.menu) {
			this.$store.subscribe((mutation, state) => {
				if (mutation.type === this.stateNamespace + '/cleanSelection' || mutation.type === this.stateNamespace + '/changeSelection') {
					const logicalname = (mutation.payload && mutation.payload.records[0].Type) || null;
					if (!mutation.payload || !mutation.payload.records || this.options.entityType.includes(logicalname)) {
						this.entityType = logicalname;
						this.currentSelection = state[this.stateNamespace].selection;
						Promise.all(this._dropDownPromiseArr).then(() => {
							this.options.menu && this.fillActionArrayAsync(this.options.menu.item, this.context, this.currentSelection[logicalname], 'dropDownActions', this.currentSelection);
							this.options.toolbarActions && this.fillActionArrayAsync(this.options.toolbarActions.item, this.context, this.currentSelection[logicalname], 'toolbarActions', this.currentSelection);
							this.options.sharemenu && this.fillActionArrayAsync(this.options.sharemenu.item, this.context, this.currentSelection[logicalname], 'shareActions', this.currentSelection);
						});
					}
				}
			});
		}
	}
};
</script>
