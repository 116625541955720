var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.images.length > 0
    ? _c(
        "div",
        {
          staticClass: "image-gallery",
          class: { "no-hover": !_vm.reference },
          style: _vm.galleryStylesObj,
          on: {
            click: function($event) {
              _vm.$emit("openDialog")
            }
          }
        },
        [
          _vm.showArrows
            ? _c(
                "div",
                {
                  staticClass: "image-gallery-arrow left",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.$emit("prev", $event)
                    }
                  }
                },
                [
                  _c("svg", { staticClass: "svg-icon svg-15" }, [
                    _c("use", {
                      attrs: { "xlink:href": "#layout-icons-ms-arrow-left" }
                    })
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showArrows
            ? _c(
                "div",
                {
                  staticClass: "image-gallery-arrow right",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.$emit("next", $event)
                    }
                  }
                },
                [
                  _c("svg", { staticClass: "svg-icon svg-15" }, [
                    _c("use", {
                      attrs: { "xlink:href": "#layout-icons-ms-arrow-right" }
                    })
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [
              !_vm.reference
                ? _c(
                    "div",
                    { staticClass: "wrapper" },
                    _vm._l(_vm.imagesComputed, function(image, index) {
                      return index == _vm.currentIndex
                        ? _c(
                            "div",
                            {
                              key: image.imageid,
                              staticClass: "image-node",
                              class: { "broken-image-wrapper": image.error }
                            },
                            [
                              !image.error
                                ? _c("img", {
                                    staticClass: "bg-image",
                                    attrs: { alt: _vm.alt, src: image.url },
                                    on: {
                                      error: function($event) {
                                        image.error = true
                                      }
                                    }
                                  })
                                : _c("img", {
                                    staticClass: "broken-image",
                                    attrs: {
                                      src: "/Images/broken-file-icon-small.png"
                                    }
                                  })
                            ]
                          )
                        : _vm._e()
                    })
                  )
                : _vm.reference && !_vm.isLinkToPrevent && _vm.showLink
                ? _c(
                    "router-link",
                    {
                      staticClass: "wrapper clickable",
                      attrs: { to: _vm.reference }
                    },
                    _vm._l(_vm.imagesComputed, function(image, index) {
                      return index == _vm.currentIndex
                        ? _c(
                            "div",
                            {
                              key: image.imageid,
                              staticClass: "image-node",
                              class: { "broken-image-wrapper": image.error }
                            },
                            [
                              !image.error
                                ? _c("img", {
                                    staticClass: "bg-image",
                                    attrs: { alt: _vm.alt, src: image.url },
                                    on: {
                                      error: function($event) {
                                        image.error = true
                                      }
                                    }
                                  })
                                : _c("img", {
                                    staticClass: "broken-image",
                                    attrs: {
                                      src: "/Images/broken-file-icon-small.png"
                                    }
                                  })
                            ]
                          )
                        : _vm._e()
                    })
                  )
                : _vm.reference && (_vm.isLinkToPrevent || !_vm.showLink)
                ? _c(
                    "a",
                    {
                      staticClass: "wrapper not-clickable",
                      on: { click: _vm.redirect }
                    },
                    _vm._l(_vm.imagesComputed, function(image, index) {
                      return index == _vm.currentIndex
                        ? _c(
                            "div",
                            {
                              key: image.imageid,
                              staticClass: "image-node",
                              class: { "broken-image-wrapper": image.error }
                            },
                            [
                              !image.error
                                ? _c("img", {
                                    staticClass: "bg-image",
                                    attrs: { alt: _vm.alt, src: image.url },
                                    on: {
                                      error: function($event) {
                                        image.error = true
                                      }
                                    }
                                  })
                                : _c("img", {
                                    staticClass: "broken-image",
                                    attrs: {
                                      src: "/Images/broken-file-icon-small.png"
                                    }
                                  })
                            ]
                          )
                        : _vm._e()
                    })
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm.images.length == 0
    ? _c("image-placeholder", {
        attrs: {
          isCustomImagePlaceholder: _vm.isCustomImagePlaceholder,
          cssClass: "svg-20",
          alt: _vm.alt,
          placeholderEntityName: _vm.placeholderEntityName
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }