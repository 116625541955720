import { isArray } from '@acx-xms/data-functions/dist';

export default {
	parameters(options) {
		return [
			this.eval(options.value),
			this.eval(options.items)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.value),
			await this.evalAsync(options.items)
		];
	},
	evaluate(value, items) {
		if (!isArray(value)) {
			value = [value];
		}
		if (!isArray(items)) {
			items = [items];
		}

		return value.every(val => {
			return items.some(item => {
				return item === val;
			});
		});
	}
};
