<template>
	<div class="popup-template-control-wrapper"
			v-bind:style="{ width: popupWidth || 'auto' }"
			v-if="visible"
			:key="namespace"
			ref="popup"
			v-targeted-popup="{target, wrapperClass: wrapperClass, offsetLeft: offsetLeft, offsetTop: offsetTop, isMobile: options.params.isMobile, isLookup: isLookup}">
		<div class="popup-template-control" v-clickoutside="{ visible: visible, callback: hidePopup, event}">
			<component class="popup-component"
						:is="options.name"
						:options="options.params">
			</component>
		</div>
	</div>
</template>
<script>
const TargetedPopup = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../../Bindings/targeted-popup.directive');
const MapTooltip = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../Map/map-tooltip');
const MobileMapTooltip = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../Map/mobile-map-tooltip');
const ControlLookupSuggestion = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../Control/Lookup/control-lookup-suggestion');
const ControlLookupFolderSuggestion = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../Control/Lookup/control-lookup-folder-suggestion');
const SortpanelDropdown = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './../SortPanel/sortpanel-dropdown');
const RatingTooltip = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/Control/Rating/rating-tooltip');

export default {
	name: 'absolute-template-popup',
	components: {
		MapTooltip,
		MobileMapTooltip,
		ControlLookupSuggestion,
		SortpanelDropdown,
		ControlLookupFolderSuggestion,
		RatingTooltip,
		TargetedPopup
	},
	data() {
		return {
			options: null,
			visible: false,
			target: null,
			popupWidth: null,
			event: 'click',
			offsetLeft: 5,
			offsetTop: 0,
			wrapperClass: 'popup-template-control'
		};
	},
	created() {
		this.$root.$on('tooltipTemplateChanged', () => {
			this.$forceUpdate();
		});
		this.$router.beforeEach((to, from, next) => {
			if (this.visible) {
				this.hidePopup();
			}
			next();
		});
		this.$root.$on('templatePopup.open', (target, namespace, options, clickEventName, popupWidth, isLookup) => {
			if (this.visible) {
				this.hidePopup();
			}

			const fn = () => {
				this.namespace = namespace;
				this.offsetLeft = options.params.offsetLeft;
				this.offsetTop = options.params.offsetTop;
				this.target = target;
				this.popupWidth = popupWidth;
				this.options = options;
				this.visible = true;
				this.isLookup = isLookup;
				this.closeCallback = options.params.closeCallback;
				this.event = clickEventName || 'click';
				if (isLookup) {
					this.$on('itemsUpdated', () => {
						this.$forceUpdate();
					});
				}
				if (sc.utils.userAgent.userAgent.isMobile) {
					window.addEventListener('orientationchange', this.hidePopup);
				} else {
					window.addEventListener('resize', this.hidePopup);
				}
				this.$root.$once('templatePopup.close.' + this.namespace, () => {
					this.hidePopup();
				});

				if (options.wrapperClass && options.wrapperClass.length) {
					this.wrapperClass = options.wrapperClass;
				} else {
					this.wrapperClass = 'popup-template-control';
				}
			};

			if (options.params.openCallback) {
				options.params.openCallback(fn);
			} else {
				fn();
			}
		});
	},
	methods: {
		hidePopup() {
			this.$root.$emit('templatePopup.closed.' + this.namespace);
			this.$root.$off('templatePopup.close.' + this.namespace);
			window.removeEventListener('resize', this.hidePopup);
			window.removeEventListener('orientationchange', this.hidePopup);
			this.visible = false;
			this.options = null;
			this.namespace = '';
			if (this.closeCallback) {
				this.closeCallback();
				this.closeCallback = null;
			}
		}
	}
};
</script>
<style src="./absolute-template-popup.less" scoped></style>
