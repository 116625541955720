import MsEditorState from '@/States/modules/ms-editor-state';
import { getFullRecordLink, Get } from '@acx-xms/data-functions/dist';
export default {
	data() {
		return { stateKey: null };
	},
	computed: {
		changedFields() {
			return this.$store.getters[this.stateKey + '/changedFields'];
		},
		relatedEntityChangedFields() {
			return this.$store.getters[this.relatedEntityStateKey + '/changedFields'];
		}
	},
	methods: {
		async validate(stateKey, logicalName) {
			const result = {
				isValid: false,
				message: ''
			};
			try {
				await this.$store.dispatch(stateKey + '/validate');
				result.isValid = true;
			} catch (errors) {
				result.message = await sc.utils.getValidationMessage(logicalName || this.logicalName, errors);
			}
			return result;
		},

		getStateKey(logicalName) {
			return 'editor.' + logicalName;
		},

		checkIfModuleIsRegistered(logicalName) {
			const stateKey = this.getStateKey(logicalName);
			const state = this.$store.state[stateKey];

			return state ? stateKey : null;
		},

		registerModule(logicalName) {
			const stateKey = this.getStateKey(logicalName);
			if (!this.$store.state[stateKey]) {
				this.$store.registerModule(stateKey, MsEditorState);
			} else {
				this.$store.commit(stateKey + '/initEntity', {});
			}
			// todo - revisit
			this.$root.$on('fileUploaded', this.onFileUploaded);

			return stateKey;
		},
		onFileUploaded(file) {
			this.file = file;
		},
		showValidationError(message) {
			sc.events.emit('dialog.error', {
				title: this.localization.localize('entity.editor.validationError'),
				message
			});
		},
		notifyEntityChanged(eventName, record) {
			const entity = sc.classes.get('entityReference', record);
			this.$root.$emit(eventName, { entity, record });
		},
		async processAspects(stage, data, entityRef, aspects, context, additionalOptions) {
			const store = {};
			if (this.options.stateField) {
				const entityData = this.$store.state[this.stateKey].entityData;
				store[this.options.stateField] = entityData[this.options.stateField];
			}
			const options = {
				evaluationContext: context,
				entityRef,
				editorOptions: { subtype: this.subtype || '' },
				store,
				params: this.options.parameters || {},
				saveResult: data,
				options: additionalOptions,
				predefinedAspectsFields: this.predefinedAspectsFields
			};
			const aspectsStack = aspects.filter(aspect => {
				return aspect.stage === stage;
			});
			const promises = aspectsStack.map(async (aspect) => {
				await aspect.execute(options);
			});
			await Promise.all(promises);
		},
		async errorhandler(error) {
			this.isLoading = false;
			if (typeof error === 'object' && error.Code === 'UniqueConstraintViolation') {
				const { metadata, errorHyperlinked, fieldsToSkip = [] } = await this.configDataProvider.fetchEntity(this.logicalName);
				const metaFieldsObj = metadata.detail.fields;
				const entityFieldsObj = error.Detail.Entity;
				const errorFields = Object.keys(entityFieldsObj).map(field => {
					return (metaFieldsObj[field].name && metaFieldsObj[field].name.base) ? metaFieldsObj[field].name.base : this.logicalName;
				}).filter(item => fieldsToSkip.indexOf(item)).join(', ');
				try {
					const { Name: name } = await Get(this.logicalName, error.Detail.DuplicateId);
					let duplicateRecord = name;
					let needParsing = true;
					if (errorHyperlinked) {
						const recordHref = getFullRecordLink(this.$route, {
							id: error.Detail.DuplicateId,
							logicalname: this.logicalName
						});
						duplicateRecord = `<a href=${recordHref} target="_blank">${name}</a>`;
						needParsing = false;
					}
					const customError = await this.localization.localize('dialogues.duplicateError', [errorFields, duplicateRecord]);
					sc.utils.errorMessage.byMessage(customError, '', null, needParsing);
				} catch {
					sc.utils.errorMessage.byResponse(error);
				}
			} else {
				if (error && !error.disableErrorDialog) {
					sc.utils.errorMessage.byResponse(error);
				}
			}
		}
	},
	beforeDestroy() {
		if (this.$store.state[this.stateKey]) {
			this.$store.unregisterModule(this.stateKey);
		}
		this.$root.$off('fileUploaded', this.onFileUploaded);
	}
};
