import coAuthDataProvider from '@/Data/DataProviders/coAuthDataprovider';
import {
	deleteMCIUser, getMCIUrlSwagger, getMCIUserInfo
} from '@/Data/DataProviders/coMCIDataprovider';
import {
	resourceIsRichable, isNullOrUndefined, Search, Update
} from '@acx-xms/data-functions/dist';
import { GetPerConfig } from '@/Data/DataProviders/userStorageDataProvider';

export default {
	async enabled(options, evaluationContext, selection) {
		if (selection && selection.length === 1) {
			return selection[0].Source && selection[0].Source.inuse;
		} else {
			return Promise.resolve();
		}
	},
	async execute(options, evaluationContext, selection) {
		sc.events.emit('vue.dialog.open', {
			icon: 'action-icons-search-save',
			title: 'Delete Account',
			component: 'confirm.dialog',
			maximizedWidth: '450px',
			maximizedHeight: 'auto',
			evaluationContext,
			onSubmit: async () => {
				let [coAuthIsRichable, mciAuthIsRichable] = await Promise.all([
					resourceIsRichable(await coAuthDataProvider.getCOAuthURLSwagger()),
					resourceIsRichable(await getMCIUrlSwagger())
				]);
				mciAuthIsRichable = isNullOrUndefined(mciAuthIsRichable)
					? true
					: mciAuthIsRichable;

				if (!coAuthIsRichable || !mciAuthIsRichable) {
					this.isLoading = false;
					return;
				}
				let token = await GetPerConfig('co-auth-key');
				const deleteAuthToken = async (refresh) => {
					try {
						await this.processMciDeleteAuth(selection).then(() => {
							window.onstorage = null;
						});
					} catch (err) {
						if (refresh && (err.toString() === 'Error: JWT token is absent or invalid' || err.message === 'JWT token is absent or invalid')) {
							await this.refreshToken();
							await deleteAuthToken();
						} else {
							this.isLoading = false;
						}
						console.log(err);
					}
				};

				if (token) {
					deleteAuthToken(true);
				} else {
					await this.authenticate(false);
				}

				window.onstorage = async () => {
					const newToken = await GetPerConfig('co-auth-key');
					if (newToken && token !== newToken) {
						token = newToken;
						deleteAuthToken();
					} else if (newToken === 'undefined') {
						window.onstorage = null;
					}
				};
			},
			message: 'Are you sure you want to delete the account from the system? Previously created email campaigns will not be reachable',
			okLabel: 'Delete'
		});
	},
	async processMciDeleteAuth(selection) {
		let result;
		const { mcUserId } = await getMCIUserInfo();
		const id = mcUserId;
		if (!selection || !selection.length) {
			const { Results: accountResults } = await Search(
				'systemuserserviceaccount',
				[
					sc.classes.get('offsetSize.filter', 1000),
					sc.classes
						.get('termFacet.filter', {
							logicalName: 'serviceaccountuserid',
							query: [id]
						})
						.fillQuery(),
					sc.classes.get('termFacet.filter', {
						logicalName: 'inuse',
						query: [true]
					}).fillQuery(),
					sc.classes.get('selectedFields.filter', [{ logicalname: 'systemuserserviceaccountid' }]).fillQuery()
				]
			);
			result = accountResults && accountResults.length && accountResults[0];
		} else {
			result = selection[0];
		}

		if (result) {
			const delMciUser = async () => {
				try {
					return await deleteMCIUser();
				} catch {
					return false;
				}
			};
			await Promise.all([
				Update(result.Type, result.Id, { inuse: false }),
				delMciUser()
			]);
			sc.events.emit('refreshCurrentDetails');
		} else {
			console.log('Can\'t find systemuserserviceaccountid for current user');
		}
	},
	async authenticate(isWithMci) {
		await coAuthDataProvider.authenticate(`${window.location.origin}/${sc.clusterKey()}/${sc.configurationKey()}/coauth`, isWithMci);
	},
	async refreshToken() {
		try {
			await coAuthDataProvider.refreshToken();
		} catch {
			await this.authenticate(false);
		}
	}
};
