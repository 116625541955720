var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "email-campaign-errors-manager" },
    _vm._l(_vm.getErrors, function(e, key) {
      return _c("div", { key: key, class: ["error", "error-" + e.type] }, [
        _vm._v("\n        " + _vm._s(e.text) + "\n        "),
        e.close
          ? _c(
              "svg",
              {
                staticClass: "error-close",
                on: {
                  click: function($event) {
                    _vm.removeError({ name: e.name, step: e.step || null })
                  }
                }
              },
              [
                _c("use", {
                  attrs: { "xlink:href": "#layout-icons-dialog-close" }
                })
              ]
            )
          : _vm._e()
      ])
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }