var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "action-menu-wrapper inline-block" }, [
    _c("div", { staticClass: "action-menu" }, [
      _vm.shareActions && _vm.shareActions.length
        ? _c(
            "div",
            {
              directives: [{ name: "data-attr", rawName: "v-data-attr" }],
              class: [
                "dropdown-toggle",
                "share-actions",
                _vm.shareVisible,
                _vm.shareActions.length ? "enabled" : "disabled",
                { clickable: _vm.shareActions.length }
              ],
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.toggleMenu($event, "shareVisible", "shareActions")
                }
              }
            },
            [
              _c("svg", { staticClass: "svg-icon" }, [
                _c("use", {
                  staticClass: "action-icons-ms-entity-share",
                  attrs: { "xlink:href": "#action-icons-ms-entity-share" }
                })
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.menuItemsToolbar && _vm.menuItemsToolbar.length
        ? _c(
            "div",
            { staticClass: "toolbar-items" },
            _vm._l(_vm.menuItemsToolbar, function(item) {
              return _c("MenuItem", {
                key: _vm.getKey(item),
                attrs: {
                  options: item.menuItem,
                  evaluationContext: _vm.evaluationContext
                },
                nativeOn: {
                  click: function($event) {
                    _vm.executeCallback(item.menuItem.action)
                  }
                }
              })
            })
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasDropDownItems
        ? _c(
            "div",
            {
              directives: [{ name: "data-attr", rawName: "v-data-attr" }],
              class: [
                "dropdown-toggle",
                _vm.dropdownVisible,
                _vm.menuItemsDropdown.length && _vm.enable
                  ? "enabled"
                  : "disabled",
                { clickable: _vm.menuItemsDropdown.length }
              ],
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.toggleMenu($event, "dropdownVisible", "menuItemsDropdown")
                }
              }
            },
            [
              _c("svg", { staticClass: "svg-icon" }, [
                _c("use", {
                  staticClass: "layout-icons-ms-actions-toggle",
                  attrs: { "xlink:href": "#layout-icons-ms-actions-toggle" }
                })
              ])
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }