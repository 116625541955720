import { showToastOrInfoDialog } from '@acx-xms/data-functions/dist';

export default {
	async enabled() {
		return Promise.resolve(true);
	},
	async execute(options, context, selection) {
		selection = selection || options.selection;
		const entity = selection && context.eval(selection);
		const modalNamespace = options.modalNamespace && context.eval(options.modalNamespace);
		if (entity) {
			// todo switch to vue events
			sc.events.emit(`${modalNamespace}.share.deleteContact`, entity);
			await showToastOrInfoDialog({
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey)
			});
		}
	}
};
