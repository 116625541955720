import inviteParticipantsDataProvider from '@/Data/DataProviders/inviteParticipantsDataProvider';
import {
	getBaseRouteUrl, Create, Search, Update
} from '@acx-xms/data-functions/dist';
import InviteParticipantsMixins from '@/Components/InviteParticipants/invite-participants.mixins';
import crmSyncDataProvider from '@/Data/DataProviders/crmSyncDataProvider';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	data() {
		return {
			isLoading: false,
			contacts: [],
			syncContactProcessId: null,
			userListTemplate: null
		};
	},

	mixins: [InviteParticipantsMixins],
	async created() {
		try {
			crmSyncDataProvider.pluginName = 'sync';
			this.isLoading = true;
			this.localization = sc.classes.get('localization.dataProvider');
			this.entityConfigDataProvider = sc.classes.get('entityConfiguration.dataProvider');
			this.fork = sc.events.fork();
			this.sfcontactIds = this.options.sfcontactIds.filter(contactId => contactId !== null);

			if (this.sfcontactIds && this.sfcontactIds.length) {
				const inviteParticipantsTemplate = await this.entityConfigDataProvider
					.getTemplate('contact', 'custom', 'invite-participants-list');
				this.userListTemplate = inviteParticipantsTemplate && inviteParticipantsTemplate.content;
				this.userListTemplate.noResultsMessage = await this.localization.getLabelForCurrentLanguage('CreateRoom.contactsTable.noResultsMessage');
				await this.getContacts();

				if (!this.contacts || !this.contacts.length || this.contacts.length !== this.sfcontactIds.length) {
					this.syncContactProcessId = await this.startSync('Contact', this.sfcontactIds);
				}
			} else {
				this.noContactInCRM = await this.localization.getLabelForCurrentLanguage('CreateRoom.listingTable.noContactInCRM');
			}

			const crmSetting = await crmSyncDataProvider.getCrmSetting();
			if (crmSetting.Total) {
				this.crmNamespace = crmSetting.Results[0].Source.crmnamespace;
			}

			this.evaluationContext = createEvaluationContext();
			this.stateKey = this.registerModule(this.logicalName);
			this.storeState = this.$store.state[this.stateKey];
			this.editorTemplate = await this.entityConfigDataProvider
				.getTemplate(this.logicalName, 'edit', this.editorSubType);
			this.controlContext = createEvaluationContext({ Type: this.logicalName });
			await this.$store.dispatch(this.stateKey + '/setField',
				{
					name: 'name',
					value: this.options.dealName
				}
			);

			this.fork.on('inviteParticipants.deleteParticipant', this.deleteParticipant);
		} catch (e) {
			sc.utils.errorMessage.byResponse(e);
		} finally {
			this.isLoading = false;
		}
	},

	methods: {
		async getRecordsByCrmIds(entities, crmIds, field = 'sfcrmid') {
			if (crmIds && crmIds.length) {
				return await Search(entities, [
					sc.classes.get('offsetSize.filter', crmIds.length).fillQuery(),
					sc.classes.get('termFacet.filter', {
						logicalName: field,
						query: crmIds
					}).fillQuery(),
					sc.classes.get('selectedFields.filter', [
						{ logicalname: 'contactid' },
						{ logicalname: 'jobtitle' },
						{ logicalname: 'account' },
						{ logicalname: 'email' },
						{ logicalname: 'contactsystemuseridsystemuser' },
						{ logicalname: 'contactsystemuseridsystemuser' },
						{ logicalname: 'contactsystemuseridsystemuser.groupid.id' },
						{ logicalname: 'groupid.id' },
						{ logicalname: 'coowner' },
						{ logicalname: 'contactsystemuseridsystemuser.avatarid.id' },
						{ logicalname: 'firstname' },
						{ logicalname: 'lastname' },
						{ logicalname: 'fullname' }
					]).fillQuery()
				]);
			} else { return null; }
		},
		async createRecord(entity, data, file) {
			try {
				return await Create(entity, data, file);
			} catch (e) {
				sc.utils.errorMessage.byMessage(e);
			} finally {
				this.progress.processed++;
			}
		},
		async updateRecord(entity, id, data) {
			try {
				return await Update(entity, id, data);
			} catch (e) {
				sc.utils.errorMessage.byMessage(e);
			} finally {
				this.progress.processed++;
			}
		},
		deleteParticipant(entity) {
			this.contacts = this.contacts.filter(item => item.Id !== entity.id);
		},
		async inviteParticipants(chat) {
			if (!this.contacts || !this.contacts.length) {
				return;
			}

			const results = await inviteParticipantsDataProvider.saveContacts(chat, this.contacts, 'chatparticipant', 'chatid.id', 'contactid.id', false);

			const invatedChatParticipants = [];
			for (const [i, result] of results.entries()) {
				if (result.status === 'error') {
					sc.events.emit('dialog.error',
						{
							title: await this.localization.getLabelForCurrentLanguage('inviteParticipantsPlugin.error'),
							message: result.data.responseText + ' ' + this.contacts[i].Name
						});
				} else {
					invatedChatParticipants.push(result.data);
				}
			}

			if (invatedChatParticipants) {
				const invitedContactsIds = invatedChatParticipants.map(invatedChatParticipant => invatedChatParticipant.contactid.id);
				const invited = this.contacts.filter(contact => invitedContactsIds.indexOf(contact.Source.contactid) !== -1);

				this.openEmailInvitationDialog(chat, invatedChatParticipants, invited);
			}

			this.progress.processed += this.contacts.length;

			return invatedChatParticipants;
		},
		cancel() {
			this.$router.replace({ path: `${getBaseRouteUrl(this.$route)}/listing-search-results` });
		},
		navigateToEntityDetails(ref) {
			this.$router.replace({ path: `${getBaseRouteUrl(this.$route)}/${ref.logicalname}/${ref.id}` });
		},
		async isRoomExist() {
			const room = await this.getRecordsByCrmIds(['collaborationroom', 'dealroom'], [this.options.sfdealcrmid], 'sfdealcrmid');
			const isRoomExists = room && room.Results.length;

			if (isRoomExists) {
				this.showValidationError(await this.localization.getLabelForCurrentLanguage('CreateRoom.roomExists'));
			}

			return isRoomExists;
		},
		async startSync(crmLogicalName, crmIds) {
			try {
				return (await crmSyncDataProvider.startSync({
					crmIds,
					crmLogicalName
				})).processId;
			} catch (e) {
				console.error(e);
			}
		},
		async getContacts() {
			const contacts = await this.getRecordsByCrmIds(['contact'], this.sfcontactIds);

			if (contacts && contacts.Results) {
				this.contacts = contacts.Results;
			}
		},
		async onSyncContactCompleted(isCR) {
			this.syncContactProcessId = null;
			if (isCR) {
				await Promise.all([this.getContacts(), this.getContactFilteredDefaultValue()]);
			} else {
				await this.getContacts();
			}
		}
	},
	beforeDestroy() {
		this.fork && this.fork.dispose();
	}
};
