import { isActive } from './Methods/action.methods';
import { GetCrmSetting } from '@/Data/DataProviders/crmSyncDataProvider';

export default {
	async enabled(options, context, selection) {
		return selection && selection.length === 1 && await isActive(context, selection);
	},
	async execute(options, context, selection) {
		const crmId = context.eval(options.crmId);

		if (crmId) {
			const domainurl = await this._getDomainUrl();

			if (domainurl) {
				const url = `${domainurl}/${crmId}`;
				const win = window.open(url, '_blank');
				win.focus();
			}
		}
	},
	async _getDomainUrl() {
		if (!this.domainurl) {
			const crmsettings = await GetCrmSetting();

			if (crmsettings.Results.length) {
				this.domainurl = crmsettings.Results[0].Source.domainurl;
			}
		}

		return this.domainurl;
	},
	domainurl: null
};
