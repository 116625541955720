var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "link-entity-reference", class: _vm.combinedCssClasses },
        [
          _vm.ref && _vm.showLink
            ? _c(
                "div",
                {
                  staticClass: "entity-link clickable",
                  on: { click: _vm.emitOpenEvent }
                },
                [
                  _c("div", { staticClass: "entity-reference-item" }, [
                    _vm.icon
                      ? _c("div", { staticClass: "entity-image" }, [
                          _c("svg", { staticClass: "svg-icon svg-15" }, [
                            _c("use", {
                              attrs: { "xlink:href": "#" + _vm.icon }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "name",
                        class: { "without-image": _vm.icon }
                      },
                      [_vm._v(_vm._s(_vm.text))]
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.ref || !_vm.showLink
            ? _c(
                "div",
                {
                  staticClass: "entity-link",
                  class: { "not-clickable": true }
                },
                [
                  _c("div", { staticClass: "entity-reference-item" }, [
                    _vm.icon
                      ? _c("div", { staticClass: "entity-image" }, [
                          _c("svg", { staticClass: "svg-icon svg-15" }, [
                            _c("use", {
                              attrs: { "xlink:href": "#" + _vm.icon }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "name",
                        class: { "without-image": _vm.icon }
                      },
                      [_vm._v(_vm._s(_vm.text))]
                    )
                  ])
                ]
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }