import { isArray, isFunction } from '@acx-xms/data-functions/dist';

export default class {
	constructor(data) {
		this.events = ['click', 'dblclick', 'mousemove', 'mouseup', 'mousedown', 'mouseover', 'mouseout'];
		this.guid = sc.utils.guid();
		this.fork = sc.events.fork();
		if (isArray(data.events)) {
			this.events = [
				...this.events,
				...data.events
			];
		}
		this.__customListeners = [];
		if (data.target) {
			this.listeners = this.events.map(event => {
				return google.maps.event.addListener(data.target, event, (e) => {
					let eventData;

					if (data.filterTargetEvent && isFunction(data.filterTargetEvent[event])) {
						eventData = data.filterTargetEvent[event](e);
						eventData.target = eventData.target || (e.clusterIcon_ && e.clusterIcon_.div_);
					} else {
						if (e && e.latLng) {
							eventData = {
								lat: e.latLng.lat(),
								lng: e.latLng.lng()
							};

							// TODO: Rework passing clicked target to listener
							// current implementation allows us not to be depended on property of object #102237
							for (const key in e) {
								if (e[key] instanceof UIEvent) {
									eventData.target = e[key].target;
								}
							}
						}
					}
					const result = this.guid + '.' + event;
					this.fork.emit(result, eventData);
				});
			});
		}
		if (data.context) {
			data.context.listenTo = this.listenTo.bind(this); ;
			data.context.on = this.on.bind(this); ;
			data.context.once = this.once.bind(this); ;
			data.context.off = this.off.bind(this); ;
		}
	}

	bubbleEventsTo(targetMediator, events) {
		events.forEach(event => {
			this.on(event, (e) => {
				targetMediator.fork.emit(targetMediator.guid + '.' + event, e);
			});
		});
	}

	listenTo(target, event, handler) {
		this.__customListeners.push({
			target,
			event,
			handler
		});

		target.on(event, handler);
	}

	on(event, handler) {
		this.fork.listeners && this.fork.on(this.guid + '.' + event, handler);
	}

	once(event, handler) {
		this.fork.listeners && this.fork.once(this.guid + '.' + event, handler);
	}

	off(event, handler) {
		this.fork.listeners && this.fork.off(this.guid + '.' + event, handler);
	}

	dispose() {
		this.fork.dispose();

		this.__customListeners.forEach(item => {
			item.target.off(item.event, item.handler);
		});
		this.listeners.forEach(listener => {
			google.maps.event.removeListener(listener);
		});
	}
}
