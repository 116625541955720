import helperMethods from '../ComponentSet/component-set-evaluation-context-helper.methods';
import * as tracking from '@/Data/trackingModule';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import LayoutMarketspaceBaseMixin from './layout.marketspace.base.mixins';
import {
	recordLink, getBaseRouteUrl, formLogoUrl, getOrgSettings, Get, Update
} from '@acx-xms/data-functions/dist';

export default {
	mixins: [LayoutMarketspaceBaseMixin],

	// todo: remove after moving SC to Vue.js
	__ko_proto__: ko,
	props: {
		logoIcon: String,
		marketspaceHeaderMenu: Object,
		menu: Object,
		userProfileMenu: Object,
		conversationPanel: Object,
		hasNotificationPanel: Object,
		hasFavoritesPanel: Object
	},

	data() {
		return {
			mainMenuState: null,
			routesReady: false,
			isGuestConfig: false,
			isAnonymous: true,
			isOrgAdmin: false,
			isGuest: true,
			sidePanelActive: false,
			sidePanelComponent: null,
			sidePanelOptions: null,
			defaultMenuItem: null,
			trackingReady: false,
			isLoading: true,
			isOpenForIndexing: false,
			isListingPortal: false,
			companyTitle: ''
		};
	},

	methods: {
		toggleSidePanel() {
			this.sidePanelActive = !this.sidePanelActive;
			if (!this.sidePanelActive && this.sidePanelComponent === 'ms-conversations-panel') {
				this.$root.$emit('closeDetailsChat');
			}
		},

		closeSidePanel() {
			this.sidePanelActive = false;
		}
	},
	computed: {
		anonymousLogoUrl() {
			return this.$store.getters.logoanonymousid ? formLogoUrl(this.$store.getters.logoanonymousid) : null;
		}
	},
	async created() {
		// TODO: move to router when edge-fetch can work without sc.classes
		if (this.$route.fullPath.includes('sharedsearch')) {
			if (this.$route.params && sc.utils.isGuidValid(this.$route.params.id)) {
				await Get(this.$route.params.name, this.$route.params.id).then(res => {
					if (res) {
						const filters = JSON.parse(res.Source.filters);
						if (!('searchQuery' in filters)) filters.searchQuery = null;
						this.$store.commit('setInitFilters', filters);
					}
				}).finally(() => {
					this.$router.push({ path: `${getBaseRouteUrl(this.$route)}/${this.$route.params.type}` });
				});
			} else {
				this.$router.push({ path: `${getBaseRouteUrl(this.$route)}/${this.$route.params.type}` });
			}
		}
		// todo move to methods js
		const _getDefaultMenuItem = function (menu, menuItem) {
			if (menu && menu.item) {
				const defaultItem = menuItem && menuItem.find(item => item.default);
				if (defaultItem) {
					return {
						path: sc.utils.findProperty(defaultItem, 'action.route.value'),
						displayName: sc.utils.findProperty(defaultItem, 'text.value')
					};
				} else {
					return {
						path: sc.utils.findProperty(menu.item[0], 'action.route.value'),
						displayName: sc.utils.findProperty(menu.item[0], 'text.value')
					};
				}
			} else {
				return false;
			}
		};

		// accessbylink layout doesn't contain menu options
		if (this.menu) {
			this.defaultMenuItem = _getDefaultMenuItem(this.menu.menu, this.menu.menu.item);
			this.$store.commit('changeDefaultMenuItemPath', this.defaultMenuItem.path);
		}

		this.conversationPanelProps = {
			enabled: await helperMethods.evalAsync(null, this.conversationPanel && this.conversationPanel.enabled),
			messageType: helperMethods.eval(null, (this.conversationPanel && this.conversationPanel.messageType) || null)
		};
		this.notificationPanel = await helperMethods.evalAsync(null, this.hasNotificationPanel);
		this.favoritesPanel = this.hasFavoritesPanel ? await helperMethods.evalAsync(null, this.hasFavoritesPanel) : false;
		this.isGuestConfig = this.$route.params.config === 'MSGuest'; /* Get and compare config name */
		const userInfo = await GetUserInfo();
		this.isAnonymous = userInfo.CRMRoles.some(role => role.Name === 'Anonymous User');

		if (!this.isAnonymous && userInfo.servertimeid) {
			const clientTime = new Date().getTime();
			Update('servertime', userInfo.servertimeid.id, { time: new Date() }, null, { syncProcessMode: 'All' })
				.then(({ time }) => {
					const serverTimeReceived = new Date().getTime();
					const serverTime = new Date(time).getTime() - (serverTimeReceived - clientTime);
					this.$store.commit('setClientServerTimeDelta', (serverTime - clientTime));
					this.$store.getters.serverTimeInit.resolve();
				});
		}

		this.isGuest = userInfo.CRMRoles.some(role => role.Name === 'Guest');
		this.isOrgAdmin = userInfo.CRMRoles.some(role => role.Name === 'Organization Admin');
		this.isListingPortal = this.$router.currentRoute.path.indexOf('listing-portal') > -1;
		const orgSettings = await getOrgSettings();
		this.companyTitle = `${orgSettings.orgdisplayname || ''} logo`;
		this.isOpenForIndexing = orgSettings.enableseo;
		this.fork = sc.events.fork();
		// todo get rid of this listener after edit form migrates to vue
		this.fork.on('refreshSearch', (refreshNamespace) => {
			this.$root.$emit(refreshNamespace + '.searching');
		});

		this.fork.on('sidePanel.open', (componentName, options) => {
			this.sidePanelComponent = componentName;
			this.sidePanelOptions = options;
			this.sidePanelActive = true;
		});

		const showDetails = (ref, additionalKey) => {
			if (ref && ref.logicalname && ref.id) {
				if (additionalKey) {
					this.$router.push({ path: recordLink(this.$route, ref, [additionalKey]) });
				} else {
					this.$router.push({ path: recordLink(this.$route, ref) });
				}
			}
		};

		this.fork.on('showDetails', showDetails);
		this.$root.$on('showDetails', showDetails);

		await tracking.Init();
		this.trackingReady = true;
		this.updateSessionTimerId = setInterval(() => {
			tracking.UpdateSession(this.$route);
		}, tracking.UpdateSessionInterval);
		this.isLoading = false;
	},
	beforeDestroy() {
		clearInterval(this.updateSessionTimerId);
		this.fork.dispose();
	}
};
