import { isNullOrUndefined, isArray } from '@acx-xms/data-functions/dist';

export default {
	parameters(options) {
		return [
			this.eval(options.argument),
			this.eval(options.property)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument),
			await this.evalAsync(options.property)
		];
	},
	evaluate(array, property) {
		if (isNullOrUndefined(array)) {
			return [];
		}
		if (isArray(array)) {
			throw new Error('expression.array.pluck: Argument is not an array');
		}
		return array.map(property);
	}
};
