var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseErrorWrapper", { attrs: { errorMessage: _vm.errorMessage } }, [
    _c("button", { attrs: { type: "submit" }, on: { click: _vm.retry } }, [
      _vm._v("Retry")
    ]),
    _vm._v(" "),
    _c("button", { attrs: { type: "submit" }, on: { click: _vm.logOut } }, [
      _vm._v("Log out")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }