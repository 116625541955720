import Vue from 'vue';

function fadeOut(element, interval) {
	let op = 1;
	const timer = setInterval(() => {
		if (op <= 0.1) {
			clearInterval(timer);
			element.style.display = 'none';
		}
		element.style.opacity = op;
		element.style.filter = 'alpha(opacity=' + op * 100 + ')';
		op -= op * 0.1;
	}, interval);
}

function fadeIn(element, interval) {
	let op = 0.1;
	const timer = setInterval(() => {
		if (op >= 1) {
			clearInterval(timer);
		}
		element.style.opacity = op;
		element.style.filter = 'alpha(opacity=' + op * 100 + ')';
		op += op * 0.1;
	}, interval);
}

Vue.directive('bg-image', (() => {
	const displayImage = (element, value) => {
		element.innerHTML += '<img/><div class=\'bg-image\'></div><i class=\'entity-icon\'></i><i class=\'loading-overlay\' style=\'display: none;\'></i>';
		// after removing JQuery from this directive - remove everything that left in (move to Legacy, remove from gulpfile and make changes in Pipeline) Scripts -> JQuery -> Plugins
		const placeholderClass = value.placeholder;
		let srcUrl = value.src;
		const width = value.width;
		const height = value.height;
		const hideBrokenImage = value.hideBrokenImage;
		const fit = value.fit;
		const mandatoryToken = value.mandatoryToken;

		element.classList.add('image-node');
		element.style.position = 'relative';
		element.style.width = width ? width + 'px' : '100%';
		element.style.height = height ? height + 'px' : '100%';

		const image = element.querySelector('img');
		const bgImage = element.querySelector('.bg-image');
		const entityIcon = element.querySelector('.entity-icon');
		const loadIndicator = element.querySelector('.loading-overlay');

		switch (fit) {
		case 'fill':
			bgImage.classList.add('fill');
			break;
		case 'contain':
			bgImage.classList.add('contain');
			break;
		}
		if (placeholderClass) {
			entityIcon.classList.add(placeholderClass);
		}

		if (srcUrl) {
			// token parameter to handle authentication
			if	(mandatoryToken) {
				srcUrl += (srcUrl.indexOf('?') > -1) ? '&' : '?';
				srcUrl += 'token=' + localStorage.getItem(sc.classes.get('authentication').getTokenKey(sc.cluster.key));
			}
		} else {
			return;
		}

		image.setAttribute('src', srcUrl);
		bgImage.style['background-image'] = `url("${srcUrl}")`;
		if (value.alt) {
			bgImage.setAttribute('role', 'img');
			bgImage.setAttribute('alt', value.alt);
			image.setAttribute('alt', value.alt);
		}
		image.style.display = 'none';
		bgImage.style.opacity = 0;

		if (srcUrl) {
			loadIndicator.style.display = 'block';
			image.onload = () => {
				if (loadIndicator.style.display !== 'none') {
					fadeOut(loadIndicator, 30);
				}
				if (placeholderClass) {
					entityIcon.classList.remove(placeholderClass);
				}
				// image.parentNode.removeChild(image);
				fadeIn(bgImage, 40);
			};

			image.onerror = () => {
				if (loadIndicator.style.display !== 'none') {
					fadeOut(loadIndicator, 30);
				}
				if (!hideBrokenImage) {
					entityIcon.classList.remove(placeholderClass);
					entityIcon.style.display = 'none';
					entityIcon.classList.add('broken-file-icon');
					fadeIn(entityIcon, 40);

					element.style.display = 'flex';

					image.style.display = 'block';
					image.src = '/Images/broken-file-icon-small.png';
					image.style.width = 'initial';
					image.style.height = 'initial';
					image.style.margin = 'auto';

					bgImage.style.display = 'none';
				}
			};
		} else {
			loadIndicator.style.display = 'none';
		}
	};
	return {
		inserted: function (element, binding) {
			displayImage(element, binding.value);
		},
		update: function (element, binding) {
			displayImage(element, binding.value);
		}
	};
})());
