import ExpressionCnumeric from './cnumeric';
import ExpressionNumber from './number';
import ExpressionRound from './round';
import ExpressionSubtract from './subtract';
import ExpressionSum from './sum';

export default {
	resolve() {
		return {
			'expression.cnumeric': ExpressionCnumeric,
			'expression.number': ExpressionNumber,
			'expression.round': ExpressionRound,
			'expression.subtract': ExpressionSubtract,
			'expression.sum': ExpressionSum
		};
	}
};
