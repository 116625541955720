import {
	isActive, mapParametersObject, checkPrivilege
} from './Methods/action.methods';
import { isUndefined } from '@acx-xms/data-functions/dist';
// TODO: remove this action as it uses knockout dialogue
export default {
	async enabled(options, evaluationContext, selection) {
		const active = await isActive(evaluationContext, selection);

		if (!active) {
			return false;
		}

		const logicalName = evaluationContext.eval(options.logicalname);
		const entityConfigurationDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		if (!logicalName) {
			return $.Deferred(function (def) { def.resolve(false); }).promise();
		}

		// todo - change xsd to leave only boolean here, we do not need expresion
		if (options.selection && (!selection || selection.length === 0)) {
			return $.Deferred(function (def) { def.resolve(false); }).promise();
		}
		const def = $.Deferred();
		const tmplPromise = entityConfigurationDataProvider.getTemplate(logicalName, 'edit', evaluationContext.eval(options.subtype));
		const privilegePromise = checkPrivilege(logicalName, 'Create');

		$.when(tmplPromise, privilegePromise).then((template, isAllowed) => {
			def.resolve(!isUndefined(template) && isAllowed);
		});

		return def.promise();
	},
	execute(options, context, selection) {
		const parameters = mapParametersObject(options.parameter, context);
		const logicalName = context.eval(options.logicalname);
		parameters.entity = sc.classes.get('entityReference',
			{ logicalname: logicalName });
		parameters.subtype = context.eval(options.subtype);
		parameters.layoutsRedirectToMain = options.layoutsRedirectToMain;

		// todo - revisit after new actions for single and multiple selection implemented #91253
		if (Object.prototype.hasOwnProperty.call(parameters, 'parentRecord') && !parameters.parentRecord && selection.length > 0) {
			parameters.parentRecord = selection[0];
		}
		sc.classes.get('logger.processor').track('Create dialog opened for ' + logicalName);

		sc.events.emit('dialog.open', {
			component: 'entity.editor',
			minimumHeight: 'auto',
			params: parameters
		});
	}
};
