export default {
	parameters(options) {
		return [
			this.eval(options.argument)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument)
		];
	},
	evaluate(argument) {
		if (!argument) {
			return '';
		}
		const date = new Date(argument);

		if (isNaN(date)) {
			throw new Error('Invalid date');
		}

		let options = {};
		const now = new Date();
		const diff = now - date;
		const hours = Math.floor(diff / (1000 * 60 * 60));

		if (date < now.setMonth(now.getMonth() - 1)) {
			options = {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric'
			};
		} else if (hours >= 24) {
			options = {
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric'
			};
		} else {
			options = {
				hour: 'numeric',
				minute: 'numeric'
			};
		}

		return date.toLocaleString(navigator && navigator.language ? navigator.language : 'en-US', options);
	}
};
