<template>
	<div>
		<div class="template-background" v-if="background">
			<control	v-if="template"
						v-bind:name="background.$type"
						v-bind:contentProps.once="background"
						v-bind:evaluationContext="evaluationContext"></control>
		</div>
		<control	v-bind:name="options.content.$type"
					v-bind:contentProps.once="options.content"
					v-bind:evaluationContext="evaluationContext"
					v-bind="$attrs"></control>
	</div>
</template>
<script>
export default {
	name: 'entitytemplate-template',
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: { Control: () => import('./../Entity/control.vue') },
	data() {
		return { background: (this.options.background) ? this.evaluationContext.eval(this.options.background) : null };
	},
	async created() {
		if (this.options.query) {
			for (const query of this.options.query) {
				await this.evaluationContext.emitQuery(query.name, query);
			}
		}
	}
};
</script>
