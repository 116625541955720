var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dialog-content-wrapper" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay large"
    }),
    _vm._v(" "),
    _c("div", { staticClass: "confirm-dialog" }, [
      _c("div", { staticClass: "dialog-content-inner" }, [
        _c("div", {
          staticClass: "p4",
          domProps: { innerHTML: _vm._s(_vm.options.message) }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        !_vm.options.hideCancel
          ? _c(
              "button",
              {
                directives: [
                  {
                    name: "data-attr",
                    rawName: "v-data-attr",
                    value: {
                      title: _vm.options.title,
                      $type: "dialog.button.submit"
                    },
                    expression:
                      "{title: options.title, $type: 'dialog.button.submit'}"
                  }
                ],
                staticClass: "cancel",
                attrs: { type: "button" },
                on: { click: _vm.cancel }
              },
              [_vm._v("\n\t\t\t\t\t" + _vm._s(_vm.cancelLabel) + "\n\t\t\t\t")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.options.customLabel && _vm.options.onCustomSubmit
          ? _c(
              "button",
              {
                directives: [
                  {
                    name: "data-attr",
                    rawName: "v-data-attr",
                    value: {
                      title: _vm.options.title,
                      $type: "dialog.button.submit"
                    },
                    expression:
                      "{title: options.title, $type: 'dialog.button.submit'}"
                  }
                ],
                staticClass: "custom-button cancel",
                attrs: { type: "button" },
                on: { click: _vm.onCustomSubmit }
              },
              [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.options.customLabel) +
                    "\n\t\t\t\t"
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            directives: [
              {
                name: "data-attr",
                rawName: "v-data-attr",
                value: {
                  title: _vm.options.title,
                  $type: "dialog.button.cancel"
                },
                expression:
                  "{title: options.title, $type: 'dialog.button.cancel'}"
              }
            ],
            attrs: { type: "button" },
            on: { click: _vm.ok }
          },
          [_vm._v("\n\t\t\t\t\t" + _vm._s(_vm.okLabel) + "\n\t\t\t\t")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }