import { isActive } from './Methods/action.methods';

export default {
	async enabled(options, evaluationContext, selection) {
		return selection.length === 1 && await isActive(evaluationContext, selection);
	},
	execute(options, context, selection) {
		const deactivateEntityRef = context.eval(options.deactivateEntityRef);
		sc.events.emit('vue.dialog.open', {
			localizedTitle: 'common.dictionary.confirmDlgCaption',
			component: 'remove-document-dialog',
			params: {
				entity: selection[0],
				deactivateEntityRef,
				refreshEvent: context.eval(options.refreshEvent),
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey)
			}
		});
	}
};
