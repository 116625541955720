<template>
	<div class="field-wrapper" v-bind:class="css" v-show="visible">
		<div class='title-wrapper'>
			<div class="field-title" v-if="title">{{title}}</div>
			<div class='tooltip' v-if='tooltipText'>
					<span class="tooltip-icon">
						<svg class="svg-icon svg-16">
							<use xlink:href="/Images/login-info.svg#login-info"></use>
						</svg>
					</span>
            	<p class="tooltip-text">{{tooltipText}}</p>
        	</div>
		</div>
		<span class="field-value">
			<control v-bind:name="options.value.$type" v-bind:contentProps="options.value" v-bind:evaluationContext="evaluationContext"></control>
		</span>
		<p class="prompt" v-if="prompt">{{prompt}}</p>
	</div>
</template>
<script>
import FieldWrapperMixins from './fieldWrapper.mixins';
export default {
	name: 'control-fieldwrapper',
	mixins: [FieldWrapperMixins]
};
</script>
<style src='./control-fieldwrapper.less' scoped></style>
