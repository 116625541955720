import BaseModule from './baseModule';

export default class extends BaseModule {
	constructor() {
		super(false, ['localization.module']);
	}

	install() {
		return sc.classes.get('settings.dataProvider').get('scMap-settings').then(settings => {
			const config = {};
			if (settings.entityCoordsFields) {
				settings.entityCoordsFields.forEach(field => {
					config[field.entityName] = {
						refName: field.schema,
						extendedRef: field.extendedRef,
						latitudeName: field.latitudeName,
						longitudeName: field.longitudeName
					};
					if (field.extendedRef) {
						config[field.entityName].refName = field.refName;
					}
				});
			}
			sc.utils.entityCoords.set(config);
		});
	}
}
