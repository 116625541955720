var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "userProfileMenu-wrapper" }, [
    _c(
      "div",
      {
        class: ["user-profile-component", _vm.isAnonymous && "anonymous"],
        attrs: { disabled: _vm.enable }
      },
      [
        _vm.isAnonymous
          ? _c("div", { staticClass: "component-header" }, [
              _c(
                "button",
                {
                  directives: [{ name: "data-attr", rawName: "v-data-attr" }],
                  on: { click: _vm.redirectToSignIn }
                },
                [_vm._v("\n\t\t\t\tSign In\n\t\t\t")]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.isAnonymous
          ? _c(
              "div",
              {
                directives: [{ name: "data-attr", rawName: "v-data-attr" }],
                staticClass: "component-header"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "profile-image",
                    on: { click: _vm.openProfilePopup }
                  },
                  [
                    !_vm.userProfilePhoto
                      ? _c("svg", { staticClass: "svg-icon" }, [
                          _c("use", {
                            class: _vm.profileIconDefault,
                            attrs: {
                              "xlink:href":
                                "#layout-icons-" + _vm.profileIconDefault
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.userProfilePhoto
                      ? _c("div", {
                          staticClass: "image-node avatar",
                          style: {
                            "background-image":
                              "url(" + _vm.userProfilePhoto + ")"
                          }
                        })
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: ["openbtn-wrapper", _vm.menuState],
                    on: { click: _vm.openProfilePopup }
                  },
                  [
                    _c("svg", { staticClass: "svg-icon" }, [
                      _c("use", {
                        class: _vm.openProfileIcon,
                        attrs: {
                          "xlink:href": "#layout-icons-" + _vm.openProfileIcon
                        }
                      })
                    ])
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.menuState == "expanded"
          ? _c("div", { class: ["user-profile-popup", _vm.menuState] }, [
              _c("div", { staticClass: "top-right-triangle" }),
              _vm._v(" "),
              _c("div", { staticClass: "header" }, [
                _c("span", { staticClass: "h2" }, [
                  _vm._v("\n\t\t\t\t\t" + _vm._s(_vm.fullName) + "\n\t\t\t\t")
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c("MenuUserprofileContent", {
                    attrs: {
                      currentLanguage: _vm.currentLanguage,
                      currentCurrency: _vm.currentCurrency,
                      currentSom: _vm.currentSom
                    }
                  }),
                  _vm._v(" "),
                  _vm._t("default")
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "footer" }, [
                _c("div", { staticClass: "bunttons-container" }, [
                  _c("div", { staticClass: "signout-component" }, [
                    _c(
                      "a",
                      {
                        staticClass: "signout-button button clickable",
                        on: { click: _vm.logout }
                      },
                      [
                        _c("i", { staticClass: "layout-icons-signout" }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Sign Out")])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "information" }, [
                    _c(
                      "p",
                      {
                        staticClass: "sc-version clickable",
                        on: { click: _vm.openVersionInfoDialog }
                      },
                      [_vm._v("Information")]
                    )
                  ])
                ])
              ])
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }