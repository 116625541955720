<template>
	<div class="image-wrapper">
		<div class="loading-wrapper" v-if="isLogoLoading">
			<div class="loading-overlay"></div>
		</div>
		<img :alt="alt" :src="url" v-on:load="onLogoLoaded" @error="onLogoError" v-bind:width="width" v-bind:height="height" v-bind:style="{objectFit: fit}" />
	</div>
</template>
<script>

export default {
	name: 'image-wrapper',
	props: {
		url: String,
		width: String,
		height: String,
		fit: String,
		alt: String
	},
	data() {
		return { isLogoLoading: true };
	},
	methods: {
		onLogoLoaded() {
			this.isLogoLoading = false;
		},
		onLogoError() {
			this.isLogoLoading = false;
		}
	}
};
</script>
