import ExpressionAddSpan from './addSpan';
import ExpressionCdate from './cdate';
import ExpressionNow from './now';
import ExpressionRoundDate from './roundDate';
import ExpressionSpan from './span';
import ExpressionSubtractDate from './subtractDate';
import ExpressionToday from './today';

export default {
	resolve() {
		return {
			'expression.addSpan': ExpressionAddSpan,
			'expression.cdate': ExpressionCdate,
			'expression.now': ExpressionNow,
			'expression.roundDate': ExpressionRoundDate,
			'expression.span': ExpressionSpan,
			'expression.subtractDate': ExpressionSubtractDate,
			'expression.today': ExpressionToday
		};
	}
};
