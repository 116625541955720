import { isActive } from './Methods/action.methods';

export default {
	async enabled(options, evaluationContext, selection) {
		return await isActive(evaluationContext, selection);
	},
	async execute(options, context, selection) {
		const localizations = sc.classes.get('localization.dataProvider');
		sc.events.emit('vue.dialog.open', {
			icon: 'action-icons-search-save',
			title: await localizations.getLabelForCurrentLanguage('msExportPlugin.exprtToExcel'),
			component: 'ms.export.dialog',
			maximizedWidth: '90%',
			maximizedHeight: '100%',
			evaluationContext: context,
			stateNamespace: context.eval(options.stateNamespace),
			logicalName: context.eval(options.logicalname),
			maxRecordsToExport: context.eval(options.maxRecordsToExport),
			customFileName: options.customFileName && context.eval(options.customFileName),
			parentEntity: options.parentEntity && context.eval(options.parentEntity),
			relationField: options.relationField && context.eval(options.relationField),
			parentReference: options.parentReference && context.eval(options.parentReference),
			toastMessageKey: context.eval(options.toastMessageKey),
			informationDialogTextKey: context.eval(options.informationDialogTextKey)
		});
	}
};
