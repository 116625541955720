<template>
	<div class="favorites-wrapper" :class="cssClass" @click.prevent="onClick($event)">
		<svg class='svg-icon'  v-if="!isLoading">
			<use v-if="!!favoriteId" xlink:href='#layout-icons-favorite-true' class='layout-icons-favorite-true'></use>
			<use v-else xlink:href='#layout-icons-favorite-false' class='layout-icons-favorite-false'></use>
		</svg>
		<div class="loading-wrapper" v-else>
			<svg class='svg-icon'>
				<use xlink:href='#layout-icons-favorite-spinner' class='layout-icons-favorite-spinner'></use>
			</svg>
		</div>
	</div>

</template>
<script>
import { Create, Delete } from '@acx-xms/data-functions/dist';
export default {
	name: 'control-favorites',
	props: {
		options: Object,
		evaluationContext: Object
	},
	data() {
		return { isLoading: false };
	},
	computed: {
		favoriteId() {
			return this.$store.getters[this.stateNamespace + '/isFavorite'](this.recordRef.id);
		}
	},
	async created() {
		this.stateNamespace = this.evaluationContext.eval(this.options.stateNamespace);
		this.recordRef = this.evaluationContext.eval(this.options.recordRef);
		this.cssClass = this.evaluationContext.eval(this.options.cssClass);
		this.isAnonymous = await this.evaluationContext.evalAsync(this.options.isAnonymous);
		if (!this.isAnonymous && !this.$store.getters[this.stateNamespace + '/isFavorite'](this.recordRef.id)) {
			const favorites = this.evaluationContext.eval(this.options.nestedCollection);
			if (favorites.length) {
				this.$store.commit(this.stateNamespace + '/addToFavorites', {
					id: this.recordRef.id,
					value: favorites[0].favoriteid
				});
			}
		}
	},
	methods: {
		async onClick(e) {
			e.stopPropagation();
			if (this.isLoading) return;
			this.isLoading = true;
			if (this.favoriteId) {
				if (!this.isAnonymous) {
					try {
						await Delete('favorite', this.favoriteId);
					} catch (error) {
						// TODO: add error handler
					}
				}
				this.$store.commit(this.stateNamespace + '/addToFavorites', {
					id: this.recordRef.id,
					value: false
				});
				this.$store.commit('counters/changeFavoritesCounter', -1);
			} else {
				let favorite = {};
				if (!this.isAnonymous) {
					favorite = await Create('favorite', { entityid: this.recordRef });
				}
				this.$store.commit(this.stateNamespace + '/addToFavorites', {
					id: this.recordRef.id,
					value: favorite.favoriteid || true
				});
				this.$store.commit('counters/changeFavoritesCounter', 1);
			}
			this.isAnonymous && localStorage.setItem('favorites', JSON.stringify(Object.keys(this.$store.state[this.stateNamespace].favorites)));
			this.isLoading = false;
		}
	}
};
</script>
<style src="./control-favorites.less" scoped></style>
