var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "favorites-wrapper",
      class: _vm.cssClass,
      on: {
        click: function($event) {
          $event.preventDefault()
          _vm.onClick($event)
        }
      }
    },
    [
      !_vm.isLoading
        ? _c("svg", { staticClass: "svg-icon" }, [
            !!_vm.favoriteId
              ? _c("use", {
                  staticClass: "layout-icons-favorite-true",
                  attrs: { "xlink:href": "#layout-icons-favorite-true" }
                })
              : _c("use", {
                  staticClass: "layout-icons-favorite-false",
                  attrs: { "xlink:href": "#layout-icons-favorite-false" }
                })
          ])
        : _c("div", { staticClass: "loading-wrapper" }, [
            _c("svg", { staticClass: "svg-icon" }, [
              _c("use", {
                staticClass: "layout-icons-favorite-spinner",
                attrs: { "xlink:href": "#layout-icons-favorite-spinner" }
              })
            ])
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }