import { render, staticRenderFns } from "./component-set-search-results-selectedrecords.vue?vue&type=template&id=1720b477&scoped=true&"
import script from "./component-set-search-results-selectedrecords.vue?vue&type=script&lang=js&"
export * from "./component-set-search-results-selectedrecords.vue?vue&type=script&lang=js&"
import style0 from "./selectedrecords.less?vue&type=style&index=0&id=1720b477&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1720b477",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\SCCore\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('1720b477', component.options)
    } else {
      api.reload('1720b477', component.options)
    }
    module.hot.accept("./component-set-search-results-selectedrecords.vue?vue&type=template&id=1720b477&scoped=true&", function () {
      api.rerender('1720b477', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Scripts/Vue/Components/ComponentSet/SearchResults/Views/component-set-search-results-selectedrecords.vue"
export default component.exports