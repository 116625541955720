var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible"
        }
      ],
      ref: "tableView",
      class: [
        "control-tableView",
        _vm.cssClass,
        {
          allowSelection: _vm.options.allowSelection,
          empty: _vm.results && !_vm.results.length
        }
      ]
    },
    [
      _vm.title
        ? _c("h5", { staticClass: "table-title" }, [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
      _vm._v(" "),
      _vm.header.length > 0
        ? _c(
            "div",
            {
              class: [
                "tableview-header",
                { "with-check": _vm.options.withCheckAll }
              ]
            },
            [
              _vm.results.length
                ? _c("ControlSelectAll", {
                    attrs: {
                      stateNamespace: _vm.stateNamespace,
                      results: _vm.results,
                      withCheckAll: _vm.options.withCheckAll,
                      isParent: _vm.options.isParent,
                      syncSelection: _vm.options.syncSelection,
                      targetEntity: _vm.targetEntity
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.header, function(headerItem, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    class: ["cell", index === 0 && "first-cell"],
                    style: { width: headerItem.width }
                  },
                  [
                    _c("span", { class: headerItem.cssClass }, [
                      _vm._v(_vm._s(headerItem.title))
                    ])
                  ]
                )
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "tableview-content",
            { "only-content": !_vm.options.withSelectionList }
          ]
        },
        [
          _vm.options.withSelectionList &&
          _vm.stateNamespace &&
          _vm.targetEntity &&
          _vm.results &&
          _vm.results.length
            ? _c("TagList", {
                attrs: {
                  stateNamespace: _vm.stateNamespace,
                  entityNamespace: _vm.targetEntity && _vm.targetEntity[0],
                  placeholder: _vm.tagListPLaceholder
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "perfectscroll",
                  rawName: "v-perfectscroll",
                  value: {
                    enable: _vm.scrollEnabled,
                    onScroll: _vm.onScroll,
                    scrollToTopEvent: _vm.scrollToTopEvent
                  },
                  expression:
                    "{ enable: scrollEnabled, onScroll, scrollToTopEvent }"
                }
              ],
              staticClass: "content-scroll-wrapper"
            },
            [
              _vm._l(_vm.results, function(rowItem, index) {
                return _c("ControlTableViewRow", {
                  key: _vm.recordKey(rowItem),
                  class: { allowSelection: _vm.allowSelection },
                  attrs: {
                    rowItem: rowItem,
                    content: _vm.content,
                    options: _vm.rowOptions,
                    stateNamespace: _vm.stateNamespace,
                    evaluationContext: _vm.evaluationContext,
                    selectAll: _vm.selectAll,
                    "is-odd": index % 2 === 0
                  },
                  nativeOn: {
                    click: function($event) {
                      _vm.selectRow(rowItem)
                    }
                  }
                })
              }),
              _vm._v(" "),
              _vm.results && !_vm.results.length
                ? _c("div", { staticClass: "row no-results-message p13" }, [
                    _c(
                      "div",
                      { staticClass: "table-cell", style: { width: "100%" } },
                      [
                        _c("div", {
                          directives: [
                            {
                              name: "localization",
                              rawName: "v-localization",
                              value: {
                                key: "common.dictionary.noMatchesFound"
                              },
                              expression:
                                "{ key: 'common.dictionary.noMatchesFound' }"
                            }
                          ]
                        }),
                        _vm._v(" "),
                        _vm.noResultsMessage
                          ? _c("div", [_vm._v(_vm._s(_vm.noResultsMessage))])
                          : _vm._e()
                      ]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }