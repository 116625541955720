var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "component-search-results is-parent mobile" },
        [
          _vm.options.filterPanel
            ? _c("filters-panel", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showFilterPanel,
                    expression: "showFilterPanel"
                  }
                ],
                attrs: {
                  options: _vm.options.filterPanel,
                  evaluationContext: _vm.filterPanelEvaluationContext,
                  isLoading: _vm.isLoading,
                  entityName: _vm.logicalName,
                  stateNamespace: _vm.stateNamespace
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "search-results-wrapper" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.hideHeader,
                    expression: "!hideHeader"
                  }
                ],
                staticClass: "header"
              },
              [
                _c(
                  "div",
                  { staticClass: "top-wrapper" },
                  [
                    _vm._t("msMenu"),
                    _vm._v(" "),
                    _c("search-field", { staticClass: "mobile" }),
                    _vm._v(" "),
                    _vm.options.sortByFields
                      ? _c("sortpanel", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.sortByFields,
                              expression: "sortByFields"
                            }
                          ],
                          staticClass: "sortby-filter-wrapper mobile",
                          attrs: {
                            fields: _vm.options.sortByFields,
                            entityName: _vm.logicalName,
                            evaluationContext: _vm.evaluationContext,
                            scrollNamespace: _vm.scrollNamespace,
                            namespace: _vm.namespace,
                            internalNamespace: _vm.internalNamespace,
                            stateNamespace: _vm.stateNamespace
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("view-switcher", {
                      staticClass: "mobile",
                      attrs: {
                        items: _vm.items,
                        stateNamespace: _vm.stateNamespace
                      }
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "bottom-wrapper" }, [
                  _c(
                    "div",
                    { staticClass: "region" },
                    [
                      _vm.options.filterPanel
                        ? _c(
                            "div",
                            {
                              staticClass: "filter-button active",
                              class: { open: !!_vm.showFilterPanel },
                              on: {
                                click: function($event) {
                                  _vm.toggleFilterPanel()
                                }
                              }
                            },
                            [
                              _c("svg", { staticClass: "svg-icon" }, [
                                _c("use", {
                                  staticClass: "'ms-filter-icon'",
                                  attrs: {
                                    "xlink:href": "#layout-icons-ms-filter-icon"
                                  }
                                })
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._t("title-slot", [
                        _c("div", { staticClass: "title h1" }, [
                          _vm._v(_vm._s(_vm.title))
                        ])
                      ]),
                      _vm._v(" "),
                      _vm._t("toggle-slot", [
                        _vm.withToggleAll
                          ? _c("results-toggle", {
                              staticClass: "toggle-padding",
                              attrs: {
                                namespace: _vm.namespace,
                                internalNamespace: _vm.internalNamespace,
                                toggleNamespace: _vm.toggleNamespace,
                                isParent: true
                              }
                            })
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("MobileComponentSetSearchResultsSelectedRecords", {
                        attrs: {
                          stateNamespace: _vm.stateNamespace,
                          showSelectedCount: _vm.showSelectedCount,
                          targetEntity: _vm.logicalName
                        }
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "region" },
                    [
                      _vm.actionMenuComponent &&
                      (_vm.actionMenuComponent.menu ||
                        _vm.actionMenuComponent.toolbarActions)
                        ? _c("reactive-action-menu", {
                            staticClass: "mobile",
                            class: {
                              "without-border":
                                !_vm.sortByFields &&
                                (_vm.items || []).length < 2
                            },
                            attrs: {
                              options: _vm.actionMenuComponent,
                              stateNamespace: _vm.stateNamespace,
                              evaluationContext: _vm.evaluationContext
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isCollapsed,
                    expression: "!isCollapsed"
                  }
                ],
                staticClass: "content-wrapper",
                class: {
                  withPanel: _vm.showFilterPanel,
                  loading: _vm.isLoading
                }
              },
              [
                _c("div", { staticClass: "content" }, [
                  _c(_vm.content.name, {
                    tag: "div",
                    staticClass: "content-inherit-height",
                    class: { "with-footer": !!_vm.results.total },
                    attrs: {
                      options: _vm.content,
                      isActive: _vm.isActive,
                      hasNoMatchesFoundSubTitle: true,
                      results: _vm.results.results,
                      stateNamespace: _vm.stateNamespace,
                      paginationParams: _vm.paginationParams
                    }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isLoading,
                        expression: "isLoading"
                      }
                    ],
                    staticClass: "loading-overlay"
                  })
                ])
              ]
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }