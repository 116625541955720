var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "searchResults-tableView" }, [
    _vm.results && _vm.results.length && _vm.options.withCheckAll
      ? _c(
          "div",
          { staticClass: "searchResults-tableView-header tableview-header" },
          [
            _c("control-select-all", {
              attrs: {
                stateNamespace: _vm.stateNamespace,
                results: _vm.results,
                withCheckAll: _vm.options.withCheckAll,
                isParent: _vm.options.isParent,
                targetEntity: _vm.targetEntity
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "p2" }, [
              _c("span", {
                directives: [
                  {
                    name: "localization",
                    rawName: "v-localization",
                    value: { key: "common.dictionary.selectAll" },
                    expression: "{ key: 'common.dictionary.selectAll' }"
                  }
                ]
              })
            ])
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.results && _vm.results.length
      ? _c(
          "div",
          {
            directives: [
              {
                name: "perfectscroll",
                rawName: "v-perfectscroll",
                value: {
                  enable: true,
                  refreshScroll: _vm.paginationParams
                    ? _vm.paginationParams.activePages.length < 2
                    : true,
                  onScroll: _vm.onScroll
                },
                expression:
                  "{enable: true, refreshScroll: paginationParams? paginationParams.activePages.length < 2 : true, onScroll}"
              }
            ],
            staticClass: "perfectscroll-wrapper",
            class: _vm.options.cssClass
          },
          [
            _c(
              "div",
              { staticClass: "sc-flex" },
              _vm._l(_vm.results, function(item) {
                return _c(
                  "div",
                  { key: _vm.recordKey(item), staticClass: "item" },
                  [
                    _c("div", { staticClass: "item-wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "info" },
                        [
                          _c("control", {
                            attrs: {
                              name: _vm.options.contentComponent.$type,
                              contentProps: _vm.options.contentComponent,
                              evaluationContext: _vm.getContext(item)
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              })
            ),
            _vm._v(" "),
            _vm.paginationParams
              ? _c("mobile-pagination", {
                  staticClass: "bottomed",
                  attrs: {
                    pageSize: _vm.paginationParams.pageSize,
                    total: _vm.paginationParams.total,
                    from: _vm.paginationParams.from,
                    activePages: _vm.paginationParams.activePages
                  }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.results || !_vm.results.length,
            expression: "!results || !results.length"
          }
        ],
        staticClass: "no-results-message"
      },
      [
        _c("div", {
          directives: [
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "common.dictionary.noMatchesFound" },
              expression: "{ key: 'common.dictionary.noMatchesFound' }"
            }
          ],
          style: { width: "100%" }
        }),
        _vm._v(" "),
        _vm.hasNoMatchesFoundSubTitle
          ? _c("div", {
              directives: [
                {
                  name: "localization",
                  rawName: "v-localization",
                  value: { key: "common.dictionary.noMatchesFoundSubTitle" },
                  expression:
                    "{ key: 'common.dictionary.noMatchesFoundSubTitle' }"
                }
              ],
              style: { width: "100%" }
            })
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }