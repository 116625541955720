import themesDataProvider from '@/Data/DataProviders/themesDataProvider';

const _getIconItem = (settings) => {
	return {
		cssVariableName: settings.cssVariableName,
		imageHeight: settings.imageHeight || 46,
		imageWidth: settings.imageWidth || 33,
		textSize: settings.textSize || 14,
		textColor: settings.textColor || '#000',
		anchorTextShift: settings.anchorTextShift || {
			horizontal: 0,
			vertical: -7
		},
		lineHeight: settings.lineHeight || 46
	};
};
const mapUtils = {
	getPushPins(pushPinsSettings) {
		const result = {};

		if (!pushPinsSettings) {
			// if settings are not specified in configuration
			pushPinsSettings = {};
		}

		result.markerCluster = _getIconItem(pushPinsSettings.markerCluster || {});
		result.markerClusterActive = _getIconItem(pushPinsSettings.markerClusterActive || {});
		result.markerIdle = _getIconItem(pushPinsSettings.markerIdle || {});
		result.markerActive = _getIconItem(pushPinsSettings.markerActive || {});
		result.markerHover = _getIconItem(pushPinsSettings.markerHover);
		result.markerActiveHover = _getIconItem(pushPinsSettings.markerActiveHover);
		result.markerClusterHover = _getIconItem(pushPinsSettings.markerClusterHover);
		result.markerClusterActiveHover = _getIconItem(pushPinsSettings.markerClusterActiveHover);

		return result;
	},
	getPushpinsStyles(pushpinsSettings, pushpinsType) {
		const settings = this.getPushPins(pushpinsSettings);
		if (!settings) return;
		let result;

		if (pushpinsType === 'single') {
			result = {
				default: {
					url: this.buildSVG(pushpinsType, this.getColorByName(settings.markerIdle.cssVariableName), settings.markerIdle.imageWidth, settings.markerIdle.imageHeight),
					width: settings.markerIdle.imageWidth,
					height: settings.markerIdle.imageHeight
				},
				hover: {
					url: this.buildSVG(pushpinsType, this.getColorByName(settings.markerHover.cssVariableName), settings.markerHover.imageWidth, settings.markerHover.imageHeight),
					width: settings.markerHover.imageWidth,
					height: settings.markerHover.imageHeight
				},
				selected: {
					url: this.buildSVG(pushpinsType, this.getColorByName(settings.markerActive.cssVariableName), settings.markerActive.imageWidth, settings.markerActive.imageHeight),
					width: settings.markerActive.imageWidth,
					height: settings.markerActive.imageHeight
				},
				selectedHover: {
					url: this.buildSVG(pushpinsType, this.getColorByName(settings.markerActiveHover.cssVariableName), settings.markerActive.imageWidth, settings.markerActive.imageHeight),
					width: settings.markerActiveHover.imageWidth,
					height: settings.markerActiveHover.imageHeight
				}
			};
		}

		if (pushpinsType === 'multi') {
			result = [
				{
					url: this.buildSVG(pushpinsType, this.getColorByName(settings.markerCluster.cssVariableName),
						settings.markerCluster.imageWidth,
						settings.markerCluster.imageHeight),
					width: settings.markerCluster.imageWidth,
					height: settings.markerCluster.imageHeight,
					textSize: settings.markerCluster.textSize,
					textColor: settings.markerCluster.textColor,
					anchorIcon: [46, 15],
					anchorText: [settings.markerCluster.anchorTextShift.vertical, settings.markerCluster.anchorTextShift.horizontal],
					textLineHeight: settings.markerCluster.lineHeight,
					type: 'default'
				},
				{
					url: this.buildSVG(pushpinsType, this.getColorByName(settings.markerClusterActive.cssVariableName),
						settings.markerClusterActive.imageWidth,
						settings.markerClusterActive.imageHeight),
					width: settings.markerClusterActive.imageWidth,
					height: settings.markerClusterActive.imageHeight,
					textSize: settings.markerClusterActive.textSize,
					textColor: settings.markerClusterActive.textColor,
					anchorIcon: [46, 15],
					anchorText: [settings.markerCluster.anchorTextShift.vertical, settings.markerCluster.anchorTextShift.horizontal],
					textLineHeight: settings.markerCluster.lineHeight,
					type: 'active'
				},
				{
					url: this.buildSVG(pushpinsType, this.getColorByName(settings.markerClusterHover.cssVariableName),
						settings.markerClusterHover.imageWidth,
						settings.markerClusterHover.imageHeight),
					width: settings.markerClusterHover.imageWidth,
					height: settings.markerClusterHover.imageHeight,
					textSize: settings.markerClusterHover.textSize,
					textColor: settings.markerClusterHover.textColor,
					anchorIcon: [46, 15],
					anchorText: [settings.markerClusterHover.anchorTextShift.vertical, settings.markerClusterHover.anchorTextShift.horizontal],
					textLineHeight: settings.markerCluster.lineHeight,
					type: 'hover'
				},
				{
					url: this.buildSVG(pushpinsType, this.getColorByName(settings.markerClusterActiveHover.cssVariableName),
						settings.markerClusterActiveHover.imageWidth,
						settings.markerClusterActiveHover.imageHeight),
					width: settings.markerClusterActiveHover.imageWidth,
					height: settings.markerClusterActiveHover.imageHeight,
					textSize: settings.markerClusterActiveHover.textSize,
					textColor: settings.markerClusterActiveHover.textColor,
					anchorIcon: [46, 15],
					anchorText: [settings.markerClusterActiveHover.anchorTextShift.vertical, settings.markerClusterActiveHover.anchorTextShift.horizontal],
					textLineHeight: settings.markerCluster.lineHeight,
					type: 'activeHover'
				}
			];
		}

		return result;
	},
	buildSVG(svgType, color, width, height) {
		let svg;
		switch (svgType) {
		case 'single':
			svg = '<svg  width="' + width + '" height="' + height + '" version="1.1" baseProfile="tiny" id="pin_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"	 x="0px" y="0px" viewBox="0 0 31 42" xml:space="preserve"><path stroke="#FFFFFF" fill="' + color + '" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="100" d="M15.5,1	C7.51,1,1.01,7.5,1.01,15.49c0,9.91,12.96,24.47,13.52,25.08c0.52,0.58,1.42,0.58,1.94,0c0.55-0.61,13.52-15.17,13.52-25.08	C29.99,7.5,23.49,1,15.5,1z M15.5,22.77c-4.02,0-7.29-3.27-7.29-7.29s3.27-7.29,7.29-7.29s7.29,3.27,7.29,7.29	S19.52,22.77,15.5,22.77z"/></svg>';
			break;
		case 'multi':
			svg = '<svg version="1.1" baseProfile="tiny" id="Pin_x5F_round_1_"	 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 ' + width + ' ' + height + '"	 xml:space="preserve"><ellipse fill="' + color + '" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="100.0009" cx="15.5" cy="15.49" rx="13.99" ry="13.99"/></svg>';
			break;
		default:
			console.error('Invalid svgType');
		}

		const encoded = window.btoa(svg);
		return 'data:image/svg+xml;base64,' + encoded;
	},
	getColorByName(colorName) {
		const defaultColors = {
			color1: '#00aff0',
			color2: '#ffac69'
		};
		const hoverColors = {};
		const theme = themesDataProvider.getCurrentTheme();

		if (colorName.includes('--')) {
			hoverColors[colorName] = document.documentElement.style.getPropertyValue(colorName);
		}

		if (theme && theme.Source) {
			return (hoverColors[colorName] || '#' + theme.Source[colorName]);
		} else {
			return defaultColors[colorName];
		}
	}
};
export default mapUtils;
