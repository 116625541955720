export function Set(key, value) {
	switch (typeof key) {
	case 'object':
		if (key) {
			return _setMultipleKeys(key);
		}
		break;
	case 'string':
		return _setSingleKey(key, value);
	}
	throw Error('Wrong argument type in set() method of localStorage provider: ' + typeof key + '\n' + 'Allowed types: string, object');
}

export function Get(key) {
	return localStorage.getItem(key);
}

export function Remove(key) {
	return localStorage.removeItem(key);
}

export function Clear() {
	return localStorage.clear();
}

function _setMultipleKeys(obj) {
	const promises = [];
	for (const key in obj) {
		Object.prototype.hasOwnProperty.call(obj, key) && promises.push(_setSingleKey(key, obj[key]));
	}
	return Promise.all(promises);
}

function _setSingleKey(key, value) {
	return localStorage.setItem(key, value);
}
