import { isActive, isAllowedEntity } from './Methods/action.methods';

export default {
	async enabled(options, evaluationContext, selection, fullselection) {
		return isAllowedEntity(options.allowedEntityTypes, fullselection) && await isActive(evaluationContext, selection);
	},
	async execute(options, context, selection) {
		const relateToEntityName = options.relateToEntityName ? context.eval(options.relateToEntityName) : 'listing';
		const additionalFields = {};

		options.fieldAddToRequest && options.fieldAddToRequest.forEach(field => {
			additionalFields[field.fieldNameToAdd] = context.eval(field.fieldInRecord);
		});
		sc.events.emit('vue.dialog.open', {
			icon: '',
			title: context.eval(options.dialogTitle),
			allowPin: false,
			component: 'create.relation.dialog',

			maximizedWidth: options.dialogWidth || '500px',
			maximizedHeight: options.dialogHeight || '300px',
			documentRelatedRecordsTemplate: options.documentRelatedRecordsTemplate,
			editorTemplate: options.editorTemplate,
			logicalName: context.eval(options.logicalName),
			params: {
				evaluationContext: context,
				relateFromRecord: context.eval(options.relateFromRecord),
				relationRecordName: context.eval(options.relationRecordEntityName),
				onRelationExistsMessage: context.eval(options.onRelationExistsMessage),
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey),
				excludeAlreadyAddedItems: options.excludeAlreadyAddedItems,
				relateToEntityName,
				additionalFields
			}
		});
	}
};
