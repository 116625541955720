import entityDeactivateBase from './Methods/action.entity.deactivateBase';
import { getOrgSettings } from '@acx-xms/data-functions/dist';
import { removeUrl } from './Methods/action.methods.sitemapHelper';

export default {
	async enabled(options, evaluationContext, selection, fullSelection) {
		return entityDeactivateBase.enabled(options, evaluationContext, selection);
	},
	async execute(options, context, selection, fullSelection) {
		const orgSettings = await getOrgSettings(true);

		const callback = () => {
			selection.forEach(async item => {
				if (!item.Source.availabilitylistingidlisting.publishedtolistingportal) {
					return;
				}
				await removeUrl(item.Id, item.Name, item.Type);
			});
		};

		options.acceptCallback = orgSettings.enableseo && callback;
		entityDeactivateBase.execute(options, context, selection, fullSelection);
	}
};
