export default {
	parameters(options) {
		return [
			options.isUTC
		];
	},
	evaluate(isUTC) {
		const today = new Date();

		if (isUTC) {
			return new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
		}

		today.setHours(0, 0, 0, 0);
		return today;
	}
};
