import ExpressionCoalesce from './coalesce';
import ExpressionIf from './if';
import ExpressionSwitch from './switch';

export default {
	resolve() {
		return {
			'expression.coalesce': ExpressionCoalesce,
			'expression.if': ExpressionIf,
			'expression.switch': ExpressionSwitch
		};
	}
};
