var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible && _vm.template
    ? _c("control", {
        staticClass: "formcontrol-template",
        attrs: {
          name: _vm.template.$type,
          contentProps: _vm.template,
          evaluationContext: _vm.evaluationContext
        }
      })
    : !_vm.template
    ? _c("p", { staticClass: "p13" }, [
        _vm._v("Please add template with an appropriate type")
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }