import { isAllowedActivation } from './Methods/action.methods';
import { addUrl } from './Methods/action.methods.sitemapHelper';
import { getOrgSettings } from '@acx-xms/data-functions/dist';

export default {
	enabled(options, evaluationContext, selection, fullSelection) {
		return isAllowedActivation(options, evaluationContext, selection);
	},
	async execute(options, context, selection, fullSelection) {
		const orgSettings = await getOrgSettings(true);

		const callback = () => {
			selection.forEach(async item => {
				if (!item.Source.availabilitylistingidlisting.publishedtolistingportal) {
					return;
				}
				await addUrl(item.Id, item.Name, item.Type);
			});
		};

		sc.events.emit('vue.dialog.open', {
			localizedTitle: 'common.dictionary.confirmReactivationDlgCaption',
			component: 'deactivate-dialog',
			maximizedHeight: options.documentRelatedRecordsTemplate ? '400px' : 'auto',
			documentRelatedRecordsTemplate: options.documentRelatedRecordsTemplate,
			evaluationContext: context,
			params: {
				entities: selection,
				isDeactivation: false,
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey),
				acceptCallback: orgSettings.enableseo && callback
			}
		});
	}
};
