import { Search, SendRequest } from '@acx-xms/data-functions/dist';
import { CRM_SETTINGS_FIELDS } from '@/Data/selected-fields';

let _crmSetting;

export async function GetCrmSetting(force, getEmpty) {
	if (!_crmSetting || force) {
		const crmSetting = await Search(['crmsetting'], [
			sc.classes.get('offsetSize.filter', 1),
			sc.classes.get('selectedFields.filter', CRM_SETTINGS_FIELDS.map(field => ({ logicalname: field }))).fillQuery()
		]);

		_crmSetting = crmSetting;
	}

	return _crmSetting;
}

export default {
	pluginName: 'crmsync',

	sync(itemstosyncId) {
		return this.sendRequest({
			type: 'post',
			url: 'sync',
			body: { itemstosyncId }
		});
	},

	cancel(id) {
		return this.sendRequest({
			type: 'post',
			url: 'cancel',
			body: { id }
		});
	},

	getMeta(entityName) {
		return this.sendRequest({
			type: 'get',
			url: ['getmeta'],
			query: { entityName }
		}).then(data => {
			return { data };
		});
	},

	getRoomData(id) {
		return this.sendRequest({
			type: 'get',
			url: ['getroomdata'],
			query: { id }
		});
	},

	proxy(query, searchRequest) {
		return this.sendRequest({
			type: 'post',
			url: ['proxy'],
			query: { q: query },
			body: searchRequest
		});
	},

	startSync(request) {
		return this.sendRequest({
			type: 'post',
			url: ['startfromms'],
			body: request
		});
	},

	getState(processId, onStatusChange) {
		const exec = () => {
			return this.sendRequest({
				type: 'get',
				url: ['getstate'],
				query: { processId }
			}, true).then(result => {
				onStatusChange && onStatusChange(result.data);

				switch (result.status) {
				case 200: {
					return { data: result.data };
				}
				case 500: {
					return result.data;
				}
				default:
					return new Promise(resolve => setTimeout(resolve, 1000)).then(exec);
				}
			});
		};

		return exec();
	},

	createSFInquiry(fields) {
		return this.sendRequest({
			type: 'post',
			url: ['createinquiry'],
			body: fields
		});
	},
	getSfInquiryTypes(value) {
		return this.proxy('api/sf/search', {
			entity: 'RecordType',
			fields: ['Id', 'Name'],
			criteria: {
				conditions: [{
					field: 'SobjectType',
					operator: 'eq',
					value
				}]
			}
		});
	},

	getCrmSetting: GetCrmSetting,

	sendRequest(request, returnWithCode) {
		request.query = request.query || {};
		request.query.configKey = sc.cluster.configKey;
		const path = `/api/plugins/endpoint/${this.pluginName}/${request.url}`;

		return SendRequest(path, request.type, request.body, null, request.query, false, returnWithCode);
	}
};
