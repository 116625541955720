var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "perfectscroll",
          rawName: "v-perfectscroll",
          value: { enable: true },
          expression: "{ enable: true }"
        }
      ],
      staticClass: "organizations"
    },
    _vm._l(_vm.organizations, function(org) {
      return _c(
        "p",
        {
          key: org.id,
          on: {
            click: function($event) {
              _vm.SelectOrg(org)
            }
          }
        },
        [_vm._v(_vm._s(org.id))]
      )
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }