<template>
	<transition-group tag="div" class="toast-group"
					:css="animate"
					enter-active-class="animated slideInUp"
					leave-active-class="animated fadeOut">
		<div v-for="toast in toastArr" class="toast-pop-up" :key="toast.idx">
			<p class="p9 text">{{toast.text}}</p>
			<span class="close" @click.stop="forceClose(toast)">
				<svg class='svg-icon'>
					<use xlink:href='#layout-icons-dialog-close'></use>
				</svg>
			</span>
		</div>
	</transition-group>
</template>
<script>
	export default {
		name: 'toast-pop-up',
		data() {
			return {
				currentIdx: 1,
				animate: true,
				toastArr: []
			}
		},
		created() {
			this.fork = sc.events.fork();
			this.$root.$on('toast.open', this.open);
			this.fork.on('toast.open', this.open);
		},
		methods: {
			getDuration(text) {
				const wordsCount = text.split(' ').length;
				if (wordsCount <= 5) {
					return 3000;
				} else if (wordsCount <= 10) {
					return 5000;
				} else {
					return 10000;
				}
			},
			close(idx, delayedClose) {
				this.toastArr = this.toastArr.filter(toast => {
					return toast.idx !== idx
				})
				clearTimeout(delayedClose);
				this.$root.$emit('toastClosed');
			},
			forceClose({ idx, delayedClose }) {
				this.animate = false;
				this.close(idx, delayedClose);
				this.$nextTick(_ => this.animate = true);
			},
			open(text, time) {
				let idx = ++this.currentIdx;
				let duration = time || this.getDuration(text);
				this.toastArr.push({
					idx,
					text,
					delayedClose: setTimeout(() => {
						this.close(idx);
					}, duration)
				})
				this.animate = true;
			}
		},
		beforeDestroy() {
			if (this.fork) {
				this.fork.dispose();
			}
			this.$root.$off('toast.open', open);
		}
	}
</script>
<style src="./toast-pop-up.less" scoped></style>
