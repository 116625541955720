import { render, staticRenderFns } from "./extended-breadcrumbs.vue?vue&type=template&id=55345f2c&scoped=true&"
import script from "./extended-breadcrumbs.vue?vue&type=script&lang=js&"
export * from "./extended-breadcrumbs.vue?vue&type=script&lang=js&"
import style0 from "./breadcrumbs.less?vue&type=style&index=0&id=55345f2c&scoped=true&lang=css&"
import style1 from "./extended-breadcrumbs.less?vue&type=style&index=1&id=55345f2c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55345f2c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\SCCore\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('55345f2c', component.options)
    } else {
      api.reload('55345f2c', component.options)
    }
    module.hot.accept("./extended-breadcrumbs.vue?vue&type=template&id=55345f2c&scoped=true&", function () {
      api.rerender('55345f2c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Scripts/Vue/Components/ComponentSet/breadcrumbs/extended-breadcrumbs.vue"
export default component.exports