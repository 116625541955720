import { showToastOrInfoDialog, Get } from '@acx-xms/data-functions/dist';

export default {
	async enabled(options, evaluationContext, selection) {
		return true;
	},
	async execute(options, context, selection) {
		const fieldsToExport = options.fieldsToExport.split(',');
		const isDirty = options.isDirty;
		if (isDirty) {
			const localizations = sc.classes.get('localization.dataProvider');
			sc.events.emit('vue.dialog.open', {
				icon: 'action-icons-search-save',
				title: await localizations.getLabelForCurrentLanguage('common.dictionary.confirmDlgCaption'),
				component: 'confirm.dialog',
				maximizedWidth: '450px',
				maximizedHeight: 'auto',
				evaluationContext: context,
				onSubmit: () => sc.events.emit('saveTemplate', {
					callback: _downloadObjectAsJson,
					fieldsToExport
				}),
				message: await localizations.getLabelForCurrentLanguage('common.dictionary.pleaseSaveChanges'),
				okLabel: await localizations.getLabelForCurrentLanguage('common.dictionary.buttons.save')
			});
		} else {
			const entityRef = sc.classes.get('entityReference', selection[0]);
			const record = await Get(entityRef.logicalname, entityRef.id);
			const objectToExport = {};
			fieldsToExport.forEach(f => { objectToExport[f] = record.Source[f]; });
			_downloadObjectAsJson(objectToExport, objectToExport.name);
			await showToastOrInfoDialog({
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey)
			});
		}
	}
};
function _downloadObjectAsJson(exportObj, exportName) {
	const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj, null, 4));
	const downloadAnchorNode = document.createElement('a');
	downloadAnchorNode.setAttribute('href', dataStr);
	downloadAnchorNode.setAttribute('download', `${exportName}.json`);
	document.body.appendChild(downloadAnchorNode); // required for firefox
	downloadAnchorNode.click();
	downloadAnchorNode.remove();
}
