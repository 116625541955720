import { isArray } from '@acx-xms/data-functions/dist';

export default {
	parameters(options) {
		return [
			this.eval(options.argument)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument)
		];
	},
	evaluate(arg) {
		if (arg === undefined || arg === null || arg === '' || arg === '00000000-0000-0000-0000-000000000000' || (isArray(arg) && arg.length === 0)) {
			return false;
		}
		return true;
	}
};
