import { isActive } from './Methods/action.methods';

export default {
	async enabled(options, evaluationContext, selection) {
		const active = await isActive(evaluationContext, selection);

		if (!active || !selection || selection.length === 0 || selection.length > 100) {
			return false;
		}

		return true;
	},
	execute(options, context, selection, fullSelection) {
		let newSelection = [];
		if (fullSelection) {
			Object.values(fullSelection).forEach(sel => {
				newSelection = newSelection.concat(sel);
			});
		} else {
			newSelection = selection;
		}

		const generateFilter = (value, isSelected = true, visible = true, includeEmpty = false) => {
			return {
				isSelected,
				visible,
				includeEmpty,
				value
			};
		};

		const settings = {
			templatesPerPage: {
				$type: 'expression.number',
				value: 12
			},
			templateFilters: {
				inputType: {
					option: [
						generateFilter('listing'),
						generateFilter('availability')
					]
				},
				templateType: {
					visible: false,
					option: [generateFilter('email', true, false, false)]
				}
			},
			editorTemplate: {
				$type: 'control.template',
				usage: 'edit',
				entity: 'reports',
				subtype: 'marketing-campaign',
				colspan: 1
			}
		};

		sc.events.emit('vue.dialog.open', {
			title: sc.classes.get('localization.dataProvider').localize('dialogues.RS4.dialog.startEmailCampaignTitle'),
			maximizedWidth: '80%',
			maximizedHeight: '100%',
			minimumHeight: '480px',
			minimumWidth: '600px',
			component: 'reportingservice.uiw.dialog',
			params: {
				data: newSelection,
				settings,
				editorComponent: 'reportingservice-mci-editor',
				withMCIAuth: true,
				refreshEvents: options.refreshEvents || null
			}
		});
	}
};
