var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { class: _vm.cssClass },
        [
          _vm.topIcon && _vm.topIcon.length
            ? _vm._l(_vm.topIcon, function(icon, index) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: icon,
                        expression: "icon"
                      }
                    ],
                    key: index,
                    staticClass: "svg-top-icon-wrapper"
                  },
                  [
                    _c("svg", { staticClass: "svg-icon svg-icon-top svg-12" }, [
                      _c("use", {
                        attrs: { "xlink:href": "#entity-icons-" + icon }
                      })
                    ])
                  ]
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.ready && _vm.imgObj
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "bg-image",
                      rawName: "v-bg-image",
                      value: _vm.imgObj,
                      expression: "imgObj"
                    }
                  ],
                  class: ["image-node", { "smart-image": !!_vm.menuOnHover }],
                  on: { click: _vm.executeAction }
                },
                [
                  !!_vm.menuOnHover
                    ? _c("div", { staticClass: "hover-overlay" }, [
                        _c("div", { staticClass: "text-on-hover h2" }, [
                          _vm._v(_vm._s(_vm.menuOnHoverText))
                        ])
                      ])
                    : _vm._e()
                ]
              )
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }