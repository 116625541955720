import { isActive, isAllowedEntity } from './Methods/action.methods';

export default {
	async enabled(options, evaluationContext, selection, fullselection) {
		return isAllowedEntity(options.allowedEntityTypes, fullselection) && await isActive(evaluationContext, selection);
	},
	async execute(options, context, selection) {
		const relateFromRecord = context.eval(options.relateFromRecord);
		const logicalName = context.eval(options.logicalName);
		const dialogTitle = context.eval(options.dialogTitle);

		sc.events.emit('vue.dialog.open', {
			icon: '',
			title: dialogTitle,
			component: 'shared.to.dialog',

			maximizedWidth: options.dialogWidth || '500px',
			maximizedHeight: options.dialogHeight || '300px',
			documentRelatedRecordsTemplate: options.documentRelatedRecordsTemplate,
			logicalName,
			params: {
				evaluationContext: context,
				relateFromRecord
			}
		});
	}
};
