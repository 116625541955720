<template>
	<div class="sync-progress">
		<div class="stepWrapper" v-if="syncStatus!==null">
			<span :key="'percentage'" class="primary-label" v-localization="{key: 'crmSyncPlugin.percentage'}"></span>
			<span class="label">{{syncedRecordsPercentage}}</span>
			<div class="content">
				<div class="progress-bar-container" v-bind:style="{ width: syncedRecordsPercentage }"></div>
			</div>
		</div>
		<div class="sync-info">
		</div>
	</div>
</template>
<script>
import crmSyncDataProvider from '@/Data/DataProviders/crmSyncDataProvider.js';
export default {
	props: { processId: String },
	data() {
		return { syncStatus: null };
	},
	async created() {
		crmSyncDataProvider.pluginName = 'sync';
		try {
			await crmSyncDataProvider.getState(this.processId, this.onStatusChange);
		} catch (e) {
			if (e.status === 409) {
				this.syncStatus = { ApplicationErrors: [{ Message: ko.unwrap(this.localizationDataProvider.localize('error.processisrunning')) }] };
			}
		} finally {
			this.$emit('onSyncCompleted', this.syncStatus);
		}
	},
	computed: {
		syncedRecordsPercentage() {
			if (this.syncStatus !== null && this.syncStatus.Total > 0) {
				let processedCount = 0;
				const syncStatus = this.syncStatus;

				for (const key in this.syncStatus.StateEntities) {
					const value = this.syncStatus.StateEntities[key];
					processedCount += this.getProcessedCount(value);
				}

				const syncRecordsPercents = processedCount / syncStatus.Total * 60;
				const updateRelationshipsPercents = syncStatus.Relationships.Total > 0
					? this.getProcessedCount(syncStatus.Relationships) / syncStatus.Relationships.Total * 20
					: 0;
				const deactivatePercents = syncStatus.ItemsToDeactivate.Total > 0
					? this.getProcessedCount(syncStatus.ItemsToDeactivate) / syncStatus.ItemsToDeactivate.Total * 10
					: 0;
				const postSync = syncStatus.PostSync.Total > 0
					? this.getProcessedCount(syncStatus.PostSync) / syncStatus.PostSync.Total * 10
					: 0;

				return parseInt(syncRecordsPercents + updateRelationshipsPercents + deactivatePercents + postSync) + '%';
			} else {
				return 0 + '%';
			}
		}
	},
	methods: {
		getProcessedCount(data) {
			let count = 0;

			count += data.AlreadySynced;
			count += data.Failed;
			count += data.Interrupted;
			count += data.Updated;

			return count;
		},
		onStatusChange: function (data) {
			this.syncStatus = data;
		}
	}

};
</script>
<style src="./sync-progress.less" scoped></style>
