import { Update } from '@acx-xms/data-functions/dist';

export default {
	methods: {
		execHandler(name) {
			this[name] && this[name]();
		},
		processEmailCampaigns() {
			this.results.results.forEach(record => {
				// entity
				const TYPE = 'marketingcampaign';
				const path = (record.Type === TYPE && 'marketingcampaignmembermarketingcampaignidmarketingcampaign') || ''; /* campaign path */
				const source = record.Source[path] || record.Source;

				// records
				const campaigns = this.$store.getters.getProcessingIds(TYPE) || [];
				const hasProcessingRecods = campaigns.some(id => id === source.marketingcampaignid);

				// update behavior
				const STATUSES = {
					saving: 'Saving',
					draft: 'Draft'
				};
				if (source.status === STATUSES.saving && !hasProcessingRecods) {
					Update(TYPE, source.marketingcampaignid, { status: STATUSES.draft });
					source.status = STATUSES.draft;
				}
			});
		}
	}
};
