
export default {
	async enabled() {
		return true;
	},

	async execute(options, context, selection) {
		const componentSet = await sc.classes.get('marketspaceComponents.dataProvider').getComponentSetById(context.eval(options.componentsetName));

		sc.events.emit('vue.dialog.open', {
			icon: 'entity-icons-deal-small',
			title: 'Shared documents',
			allowPin: false,
			component: 'shareddocument-dialog',
			maximizedWidth: '90%',
			maximizedHeight: '100%',
			params: {
				componentSet,
				searchResultComponentName: options.searchResultComponentName,
				parentRef: context.entity,
				evaluationContext: context
			}
		});
	}
};
