import { refreshEvents } from '../../helpers/refreshEvents';

export const menuConfig = {
	enable: true,
	entityType: 'template',
	menu: {
		item: [
			{
				$type: 'menu.menuItem',
				display: 'text',
				default: false,
				text: {
					$type: 'expression.text',
					value: 'Open Statistics'
				},
				action: {
					$type: 'action.emailCampaign.report',
					enable: {
						$type: 'expression.eq',
						argument: [
							{
								$type: 'expression.field',
								name: 'status',
								schema: 'source'
							},
							{
								$type: 'expression.text',
								value: 'Sent'
							}
						]
					},
					selection: { $type: 'expression.source' },
					source: 'context',
					refreshEvents
				}
			},
			{
				$type: 'menu.menuItem',
				display: 'text',
				default: false,
				text: {
					$type: 'expression.text',
					value: 'Delete'
				},
				action: {
					$type: 'action.emailCampaign.delete',
					enable: {
						$type: 'expression.or',
						argument: [
							{
								$type: 'expression.eq',
								argument: [
									{
										$type: 'expression.field',
										name: 'status',
										schema: 'source'
									},
									{
										$type: 'expression.text',
										value: 'Sent'
									}
								]
							},
							{
								$type: 'expression.eq',
								argument: [
									{
										$type: 'expression.field',
										name: 'status',
										schema: 'source'
									},
									{
										$type: 'expression.text',
										value: 'Draft'
									}
								]
							}
						]
					},
					selection: { $type: 'expression.source' },
					source: 'context',
					refreshEvents
				}
			}
		]
	}
};
