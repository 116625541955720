/* eslint no-undef: 0 */
import BaseModule from './baseModule';
import { isObject } from '@acx-xms/data-functions/dist';

export default class extends BaseModule {
	install() {
		sc.localization = (sc.cluster.langs || []).map(language => {
			return {
				code: language.code,
				displayName: language.displayName,
				isDefault: language.default,
				current: false,
				icon: 'form-icons-' + language.code
			};
		});

		const dataProvider = sc.classes.get('localization.dataProvider');
		// TOOD: rethink
		return dataProvider.getCurrentLanguage().then(currentLanguage => {
			return dataProvider.getLocalizationTable().then(() => {
				const current = sc.localization.find(loc => loc.code === currentLanguage.code);
				current.current = true;
				Object.freeze(sc.localization);
				sc.UILanguage = currentLanguage;
				Object.freeze(sc.UILanguage);

				return currentLanguage;
			});
		})
			.fail(error => {
				if (isObject(error)) {
					const str = _getErrorText(error.status) + ' ' + _getErrorText(error.statusText) + ' ' + _getErrorText(error.responseText);
					throw str.trim() || 'An error has occured';
				} else {
					throw error;
				}
			});
	}
}
