var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sync-wrapper", class: _vm.crm }, [
    _c("div", { staticClass: "header" }, [
      _c("div", {
        directives: [
          {
            name: "localization",
            rawName: "v-localization",
            value: { key: "crmSyncPlugin.header.syncstatus" },
            expression: "{key: 'crmSyncPlugin.header.syncstatus'}"
          }
        ],
        staticClass: "h2"
      })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "body" },
      [
        _vm.stepNumber === 1
          ? _c("SyncProgress", {
              attrs: { processId: _vm.processId },
              on: { onSyncCompleted: _vm.onSyncCompleted }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.stepNumber === 2
          ? _c("div", { staticClass: "step2" }, [
              _vm.syncStatus.ApplicationErrors.length === 0 ||
              _vm.syncStatus.Total > 0
                ? _c("div", { staticClass: "stepWrapper" }, [
                    _c("span", {
                      directives: [
                        {
                          name: "localization",
                          rawName: "v-localization",
                          value: { key: "crmSyncPlugin.syncIsCompleted" },
                          expression: "{key: 'crmSyncPlugin.syncIsCompleted'}"
                        }
                      ],
                      key: "syncIsCompleted",
                      staticClass: "primary-label"
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "info" }, [
                      _vm.syncStatus.HasSkippedParentEnities
                        ? _c("div", { staticClass: "skipped-records-labels" }, [
                            _c("span", {
                              directives: [
                                {
                                  name: "localization",
                                  rawName: "v-localization",
                                  value: {
                                    key:
                                      "syncPlugin.warn.someRecordSkippedMessage1line"
                                  },
                                  expression:
                                    "{key: 'syncPlugin.warn.someRecordSkippedMessage1line'}"
                                }
                              ]
                            }),
                            _vm._v(" "),
                            _c("span", {
                              directives: [
                                {
                                  name: "localization",
                                  rawName: "v-localization",
                                  value: {
                                    key:
                                      "syncPlugin.warn.someRecordSkippedMessage2line"
                                  },
                                  expression:
                                    "{key: 'syncPlugin.warn.someRecordSkippedMessage2line'}"
                                }
                              ]
                            })
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      Object.keys(_vm.syncStatus.StateEntities).length
                        ? _c("div", { staticClass: "content sync-stasus" }, [
                            _c("div", { staticClass: "tableView" }, [
                              _c("div", { staticClass: "tableview-header" }, [
                                _c("div", {
                                  directives: [
                                    {
                                      name: "localization",
                                      rawName: "v-localization",
                                      value: {
                                        key: _vm.crm
                                          ? "crmSyncPlugin.tableHeader.entity" +
                                            "." +
                                            _vm.crm
                                          : "crmSyncPlugin.tableHeader.entity"
                                      },
                                      expression:
                                        "{key: crm ? 'crmSyncPlugin.tableHeader.entity' + '.' + crm : 'crmSyncPlugin.tableHeader.entity'}"
                                    }
                                  ]
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  directives: [
                                    {
                                      name: "localization",
                                      rawName: "v-localization",
                                      value: { key: "crmSyncPlugin.updated" },
                                      expression:
                                        "{key: 'crmSyncPlugin.updated'}"
                                    }
                                  ]
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  directives: [
                                    {
                                      name: "localization",
                                      rawName: "v-localization",
                                      value: { key: "crmSyncPlugin.synced" },
                                      expression:
                                        "{key: 'crmSyncPlugin.synced'}"
                                    }
                                  ]
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  directives: [
                                    {
                                      name: "localization",
                                      rawName: "v-localization",
                                      value: { key: "crmSyncPlugin.failed" },
                                      expression:
                                        "{key: 'crmSyncPlugin.failed'}"
                                    }
                                  ]
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  directives: [
                                    {
                                      name: "localization",
                                      rawName: "v-localization",
                                      value: {
                                        key: "crmSyncPlugin.interrupted"
                                      },
                                      expression:
                                        "{key: 'crmSyncPlugin.interrupted'}"
                                    }
                                  ]
                                })
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "perfectscroll",
                                      rawName: "v-perfectscroll",
                                      value: { enable: true },
                                      expression: "{enable: true}"
                                    }
                                  ],
                                  staticClass: "tableview-content"
                                },
                                _vm._l(_vm.syncStatus.StateEntities, function(
                                  entity,
                                  value
                                ) {
                                  return _c(
                                    "div",
                                    { key: value, staticClass: "row" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "column" },
                                        [
                                          _c("EntityTitle", {
                                            attrs: {
                                              logicalname: value,
                                              isSingular: false
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "column" }, [
                                        _c("span", [
                                          _vm._v(_vm._s(entity.Updated))
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "column" }, [
                                        _c("span", [
                                          _vm._v(_vm._s(entity.AlreadySynced))
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "column" }, [
                                        _c("span", [
                                          _vm._v(_vm._s(entity.Failed))
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "column" }, [
                                        _c("span", [
                                          _vm._v(_vm._s(entity.Interrupted))
                                        ])
                                      ])
                                    ]
                                  )
                                })
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.skippedRecords && _vm.skippedRecords.length
                        ? _c("div", { staticClass: "content" }, [
                            _c("div", { staticClass: "tableView" }, [
                              _c("div", { staticClass: "tableview-header" }, [
                                _c("div", {
                                  directives: [
                                    {
                                      name: "localization",
                                      rawName: "v-localization",
                                      value: {
                                        key:
                                          "crmSyncPlugin.tableHeader.failedRecords"
                                      },
                                      expression:
                                        "{key: 'crmSyncPlugin.tableHeader.failedRecords'}"
                                    }
                                  ]
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  directives: [
                                    {
                                      name: "localization",
                                      rawName: "v-localization",
                                      value: {
                                        key: "crmSyncPlugin.tableHeader.entity"
                                      },
                                      expression:
                                        "{key: 'crmSyncPlugin.tableHeader.entity'}"
                                    }
                                  ]
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  directives: [
                                    {
                                      name: "localization",
                                      rawName: "v-localization",
                                      value: {
                                        key: "crmSyncPlugin.tableHeader.id"
                                      },
                                      expression:
                                        "{key: 'crmSyncPlugin.tableHeader.id'}"
                                    }
                                  ]
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  directives: [
                                    {
                                      name: "localization",
                                      rawName: "v-localization",
                                      value: {
                                        key: "crmSyncPlugin.tableHeader.reason"
                                      },
                                      expression:
                                        "{key: 'crmSyncPlugin.tableHeader.reason'}"
                                    }
                                  ]
                                })
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "perfectscroll",
                                      rawName: "v-perfectscroll",
                                      value: { enable: true },
                                      expression: "{enable: true}"
                                    }
                                  ],
                                  staticClass: "tableview-content"
                                },
                                _vm._l(_vm.skippedRecords, function(
                                  skippedRecord
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: skippedRecord.Id,
                                      staticClass: "row"
                                    },
                                    [
                                      _c("div", { staticClass: "column" }, [
                                        _c("span", [
                                          _vm._v(_vm._s(skippedRecord.Name))
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "column" },
                                        [
                                          _c("EntityTitle", {
                                            attrs: {
                                              logicalname: skippedRecord.Entity,
                                              isSingular: true
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "column" }, [
                                        _c("span", [
                                          _vm._v(_vm._s(skippedRecord.Id))
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "column" }, [
                                        _c("span", [
                                          _vm._v(_vm._s(skippedRecord.Reason))
                                        ])
                                      ])
                                    ]
                                  )
                                })
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  ])
                : _vm.syncStatus.ApplicationErrors.length > 0
                ? _c("div", { staticClass: "stepWrapper" }, [
                    _c("span", {
                      directives: [
                        {
                          name: "localization",
                          rawName: "v-localization",
                          value: { key: "crmSyncPlugin.syncIsFailed" },
                          expression: "{key: 'crmSyncPlugin.syncIsFailed'}"
                        }
                      ],
                      key: "syncIsCompleted",
                      staticClass: "primary-label"
                    }),
                    _vm._v(" "),
                    _c("span", {
                      directives: [
                        {
                          name: "localization",
                          rawName: "v-localization",
                          value: { key: "crmSyncPlugin.applicationErrors" },
                          expression: "{key: 'crmSyncPlugin.applicationErrors'}"
                        }
                      ],
                      staticClass: "label"
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "application-errors" },
                      _vm._l(_vm.syncStatus.ApplicationErrors, function(
                        error,
                        index
                      ) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "perfectscroll",
                                rawName: "v-perfectscroll",
                                value: { enable: true },
                                expression: "{enable: true}"
                              }
                            ],
                            key: index
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(error.Message) +
                                "\n\t\t\t\t\t"
                            )
                          ]
                        )
                      })
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "footer" }, [
      _c("span", { staticClass: "buttons" }, [
        _c("button", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.stepNumber === 1 && _vm.processId !== null,
              expression: "stepNumber===1 && processId!==null"
            },
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "crmSyncPlugin.button.cancel" },
              expression: "{key: 'crmSyncPlugin.button.cancel'}"
            }
          ],
          staticClass: "cancel",
          attrs: { type: "button" },
          on: { click: _vm.cancel }
        }),
        _vm._v(" "),
        _c("button", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.stepNumber === 2,
              expression: "stepNumber===2"
            },
            {
              name: "localization",
              rawName: "v-localization",
              value: { key: "crmSyncPlugin.button.ok" },
              expression: "{key: 'crmSyncPlugin.button.ok'}"
            }
          ],
          attrs: { type: "button" },
          on: { click: _vm.ok }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }