<template>
	<div class="thumbnail-annotated" :class="cssClass">
		<control class="thumbnail" v-if="options.thumbnail"
					:name="options.thumbnail.$type"
					:contentProps.once="options.thumbnail"
					:evaluationContext="evaluationContext"></control>

		<div class="info">
			<control	class="info-title-row" v-if="options.title" :key="index" v-for="(title, index) in options.title"
						:name="title.$type"
						:contentProps.once="title"
						:evaluationContext="evaluationContext"></control>
			<div class="info-holder sc-grid" v-if="options.info" :class="'columns-' + options.infoColumns">
				<div class="grid-cell" :class="'column-span-' + options.colspan" :key="index" v-for="(info,index) in options.info">
					<control	v-if="info"
								:name="info.$type"
								:contentProps.once="info"
								:evaluationContext="evaluationContext"></control>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
/* eslint vue/valid-v-bind: 0 */
/* eslint vue/no-use-v-if-with-v-for: 0 */
export default {
	name: 'entitytemplate-thumbnailannotated',
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: { Control: () => import('./../Entity/control.vue') },
	data() {
		return {
			cssClass: this.evaluationContext.eval(this.options.cssClass) || '',
			thumbnail: null,
			title: null,
			info: null
		};
	}
};
</script>
<style src="./entitytemplate-thumbnailannotated.less" scoped></style>
