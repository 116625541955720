var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.imagesForDisplay.length
    ? _c("div", { class: ["group-image-wrapper", _vm.cssClass] }, [
        _c(
          "div",
          {
            class:
              "group-image_" +
              (_vm.imagesForDisplay.length + (_vm.restItemsLength > 0 ? 1 : 0))
          },
          [
            _vm.restItemsLength
              ? _c("div", { staticClass: "rest-items" }, [
                  _c("div", {
                    staticClass: "circle",
                    style: {
                      width: _vm.identifyPixelSize() + "px",
                      height: _vm.identifyPixelSize() + "px"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "rest-items-count" }, [
                    _c("span", { staticClass: "p7" }, [_vm._v("+")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "p7" }, [
                      _vm._v(_vm._s(_vm.restItemsLength))
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.imagesForDisplay, function(image, index) {
              return _c("div", {
                directives: [
                  {
                    name: "image",
                    rawName: "v-image",
                    value: {
                      src: image,
                      width: _vm.identifyPixelSize(),
                      height: _vm.identifyPixelSize(),
                      placeholder: _vm.placeholderClass
                    },
                    expression:
                      "{\n\t\t\t\tsrc: image,\n\t\t\t\twidth: identifyPixelSize(),\n\t\t\t\theight: identifyPixelSize(),\n\t\t\t\tplaceholder: placeholderClass\n\t\t\t}"
                  }
                ],
                key: index
              })
            })
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }