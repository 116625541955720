export default {
	parameters(options) {
		return [
			this.eval(options.scopeName),
			this.scope
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.scopeName),
			this.scope
		];
	},
	evaluate(scopeName, scopes) {
		return scopes.get(scopeName);
	}
};
