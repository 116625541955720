let entityConfiguration;

export default {
	parameters(options) {
		console.error('metadataLabel must be awaited');
	},
	async parametersAsync(options) {
		if (!entityConfiguration) {
			entityConfiguration = sc.classes.get('entityConfiguration.dataProvider');
		}
		return [
			options.entity ? await this.evalAsync(options.entity) : this.entity.logicalname,
			await this.evalAsync(options.field),
			await this.evalAsync(options.language),
			sc.UILanguage.code
		];
	},
	evaluate(key, lng) {
		console.error('metadataLabel must be awaited');
	},
	async evaluateAsync(entity, field, lng, uiLng) {
		let data = await entityConfiguration.getFieldsCaption(entity, field);
		data = data[field];
		return data[lng || uiLng] || data.base;
	}
};
