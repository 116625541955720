export default {
	async enabled(options, context, selection) {
		return true;
	},
	execute(options, context, selection) {
		selection = selection || options.selection;
		const entity = selection && context.eval(selection);
		if (entity) {
			sc.events.emit('inviteParticipants.deleteParticipant', entity);
		}
	}
};
