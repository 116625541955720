<template>
	<component v-if="hasData" class="control-component" v-bind:is="chartName" :options="options" :data="data" :styles="styles" />
	<div class="no-results-message" v-else-if="!hasData">
		<div class="p13" v-localization="{ key: 'common.dictionary.noMatchesFound' }"></div>
	</div>
</template>

<script>
const BarChart = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './bar-chart');
const DoughnutChart = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './doughnut-chart');
const HorizontalBarChart = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ './horizontal-bar-chart');
export default {
	name: 'chart-wrapper',
	props: {
		data: Object,
		options: Object,
		styles: Object,
		chartName: String
	},
	components: {
		BarChart,
		DoughnutChart,
		HorizontalBarChart
	},
	created() {
		this.hasData = this.data && this.data.datasets && this.data.datasets.length && this.data.datasets[0].data.length;
	}
};
</script>
