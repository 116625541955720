import { mapAsync } from '@acx-xms/data-functions/dist';

export default {
	parameters(options) {
		return [
			options.argument.map(this.eval)
		];
	},
	async parametersAsync(options) {
		return [
			await mapAsync(options.argument, this.evalAsync)
		];
	},
	evaluate(args) {
		return args.reduce((result, item) => {
			const val = parseFloat(item);
			if (isNaN(val)) {
				return result;
			}
			return (result === null) ? val : result - val;
		}, null);
	}
};
