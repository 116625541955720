<template>
    <div class='wizard-root'>
        <component :is='wizardName' />
    </div>
</template>

<script>
import coDataProvider from '@/Data/DataProviders/coDataprovider';
import { deleteCampaign } from '@/Data/DataProviders/coMCIDataprovider';
import wizardState from '@/States/modules/wizard/index';
import stepCounterStore from '@/States/modules/step-counter';
import { wizardMixin } from './Mixins/wizardMixin';
import emailCampaign from './WizardSets/EmailCampaign/email-campaign';
import wizardLoader from './WizardSets/WizardLoader/wizard-loader';
import { mapActions } from 'vuex';

function onBeforeUnload(e) {
	e.preventDefault();
	return e.returnValue = '';
}
export default {
	name: 'wizard',
	components: {
		emailCampaign,
		wizardLoader
	},
	mixins: [wizardMixin],
	computed: {
		wizardName() {
			switch (this.getSet) {
			case 'email-campaign':
				return 'emailCampaign';
			default:
				return 'wizardLoader';
			}
		}
	},
	methods: { ...mapActions('wizard', ['processSelection', 'updateDetailsFlag', 'saveAsDraft']) },
	created() {
		this.$store.state && !this.$store.state.wizard && this.$store.registerModule('wizard', wizardState);
		this.$store.state && !this.$store.state['wizard.navigation'] && this.$store.registerModule('wizard.navigation', stepCounterStore);
		!this.isDetails && addEventListener('beforeunload', onBeforeUnload);
	},
	mounted() {
		if (this.$route.params && this.$route.params.selection) {
			this.processSelection(this.$route.params.selection);
			this.$store.commit('wizard/UPDATE_EDITED_FLAG', true);
			this.$route.params.selection = null;
		}
		// if (this.$route.params && this.$route.params.entryPointEdit && this.$route.params.selection) {
		// }
		// TODO: remove after test all cases with save as draft button
		// if (!this.withEditMode) {
		// 	this.initListingSelectionUnwatch = this.$store.watch(
		// 		(state, getters) => getters['wizard/initListingSelection'],
		// 		(newVal) => {
		// 			if (newVal && newVal.length) {
		// 				this.$store.commit('wizard/UPDATE_EDITED_FLAG', true);
		// 				this.initListingSelectionUnwatch && this.initListingSelectionUnwatch();
		// 			}
		// 		},
		// 		{ immediate: true }
		// 	);
		// 	this.initMlSelectionUnwatch = this.$store.watch(
		// 		(state, getters) => getters['wizard/initMlSelection'],
		// 		(newVal) => {
		// 			if (newVal && newVal.length) {
		// 				this.$store.commit('wizard/UPDATE_EDITED_FLAG', true);
		// 				this.initMlSelectionUnwatch && this.initMlSelectionUnwatch();
		// 			}
		// 		},
		// 		{ immediate: true }
		// 	);
		// }
	},
	beforeRouteLeave(to, from, next) {
		if (this.isForceExit) {
			next();
			return;
		}
		if (this.isEdited && !this.isSaved && !this.isDetails) {
			this.$root.$emit('vue.dialog.open', {
				icon: 'action-icons-search-save',
				title: 'You have unsaved changes',
				component: 'confirm.dialog',
				maximizedWidth: '450px',
				maximizedHeight: 'auto',
				message: 'It seems like the last changes keep unsaved. Would you like to save the edits?',
				customLabel: 'Leave anyway',
				okLabel: 'Save',
				onCustomSubmit: () => {
					const artifacts = this.$store.state.wizard.generatedArtifactsIds.length && this.$store.state.wizard.generatedArtifactsIds || null;
					if (this.$route.params && this.$route.params.edit) {
						this.$store.state.wizard.campaignWithArtifactStatus && artifacts.shift();
						artifacts && artifacts.length && Promise.all(artifacts.map(id => coDataProvider.deleteArtifact(id)));
					} else artifacts && Promise.all(artifacts.map(id => coDataProvider.deleteArtifact(id)));
					next();
				},
				onSubmit: () => {
					this.$route.params.entryPoint = to.fullPath;
					this.saveAsDraft({
						withBack: true,
						defaultPage: false,
						toast: true
					});
				},
				onCancel: () => false
			});
		} else {
			next();
		}
	},
	destroyed() {
		/* TODO: update vuex to 2.3+ */
		const modules = ['wizard.navigation', 'wizard.listing', 'wizard.ml', 'wizard'];
		modules.forEach(element => {
			this.$store.state[element] && this.$store.unregisterModule(element);
		});
		removeEventListener('beforeunload', onBeforeUnload);
	}
};
</script>

<style lang='less' scoped>
@import './wizard.less';
</style>
