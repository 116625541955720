import { getCampaign } from '@/Data/DataProviders/coMCIDataprovider';
import coAuthDataProvider from '@/Data/DataProviders/coAuthDataprovider';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
function openErrorModal() {
	sc.events.emit('vue.dialog.info', {
		title: 'Open Campaign Statistics',
		message: 'The campaign\'s statistics is not available for review. To proceed, please, connect the Mailchimp account from where it was sent.',
		showFooter: false
	});
}

export default {
	async enabled(options, context, selection) {
		if (!context.datasource.Source) {
			context = createEvaluationContext(selection[0]);
		}
		return context.eval(options.enable);
	},
	async execute(options, context, selection) {
		const ID = selection[0].Source.mcicampaignid ||
			(selection[0].Source.marketingcampaignmembermarketingcampaignidmarketingcampaign && selection[0].Source.marketingcampaignmembermarketingcampaignidmarketingcampaign.mcicampaignid) ||
			null;
		if (!ID) {
			openErrorModal();
			return;
		}
		const data = await getCampaign(ID).catch(async (er) => {
			if (er.type !== 2) return;
			return await coAuthDataProvider.authenticate(`${window.location.origin}/${sc.clusterKey()}/${sc.configurationKey()}/coauth`, true)
				.then(async () => await getCampaign(ID));
		});
		if (data && data.status === 'sent') {
			const url = `https://us1.admin.mailchimp.com/reports/summary?id=${ID}`;
			window.open(url, '_blank');
		} else {
			openErrorModal();
		}
	}
};
