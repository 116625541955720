import ExpressionCbool from '../boolean/cbool';

export default {
	parameters(options) {
		return [
			options.argument.map(this.eval)
		];
	},
	async parametersAsync(options) {
		return [
			await Promise.all(options.argument.map(await this.evalAsync))
		];
	},
	evaluate(args) {
		return args.some(ExpressionCbool.evaluate);
	}
};
