var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        {
          class: [
            "filter-group filter-group-toggle-panel",
            {
              selected: _vm.isSelected,
              open: _vm.isExpanded,
              collapsible: _vm.collapsible
            }
          ]
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "data-attr",
                  rawName: "v-data-attr",
                  value: { options: _vm.options, stateNamespace: _vm.name },
                  expression: "{ options, stateNamespace: name }"
                }
              ],
              staticClass: "panel-title panel-opener",
              on: { click: _vm.toggle }
            },
            [
              _c("h3", { staticClass: "panel-opener" }, [
                _vm._v("\n\t\t\t" + _vm._s(_vm.name) + "\n\t\t")
              ]),
              _vm._v(" "),
              _vm.collapsible
                ? _c("div", { staticClass: "icon-holder" }, [
                    _c("svg", { staticClass: "svg-icon svg-15" }, [
                      _c("use", {
                        staticClass: "hover",
                        attrs: {
                          "xlink:href": "#layout-icons-filter-group-collapse"
                        }
                      })
                    ])
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isExpanded,
                  expression: "isExpanded"
                }
              ],
              staticClass: "filter-items filter-group-content"
            },
            _vm._l(_vm.options.filter, function(filter) {
              return _c(_vm.getInlineComponentName(filter), {
                key: _vm.evalLogicalName(filter),
                tag: "component",
                attrs: {
                  stateNamespace: _vm.stateNamespace,
                  options: filter,
                  filters: _vm.filters,
                  evaluationContext: _vm.evaluationContext
                }
              })
            })
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }