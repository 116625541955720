<template>
	<div class="theme-details" v-if="rows.length">
		<div class="content">
			<div class="header">
				<div class="title h1">Picklist values</div>
			</div>
			<div class="rows content-scroll-wrapper" v-perfectscroll="{ onScroll: onScroll }">
				<DetailsRowControl v-for="row in rows" v-bind:style="{height: row.height}" :key="row.$key" v-bind:evaluationContext="evaluationContext" v-bind:row="row"></DetailsRowControl>
			</div>
		</div>
	</div>
</template>
<script>
import { generateId } from '@acx-xms/data-functions/dist';
import '@/Bindings/perfectscroll.directive';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';
const DetailsRowControl = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */ '@/Components/ComponentSet/Details/details-row-control');
export default {
	components: { DetailsRowControl },
	props: { options: Object },
	data() {
		return {
			evaluationContext: createEvaluationContext({}),
			rows: []
		};
	},
	async created() {
		this.fork = sc.events.fork();
		this.$root.$on(['entity.changed', 'entity.created', 'entity.deleted'], this.checkAutoRefresh);
		this.fork.on(['entity.changed', 'entity.created', 'entity.deleted'], this.checkAutoRefresh);
		this.configDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		await this.initTemplate();
	},
	methods: {
		onScroll() {
			this.lookupEntities.forEach(lookup => {
				this.$root.$emit(`mainSearch.${lookup}.list.scrolled`);
			});
		},
		checkAutoRefresh(data) {
			if (this.lookupEntities.includes(data.entity.logicalname)) {
				this.fork.emit(`mainSearch.${data.entity.logicalname}.list.searching`);
			}
		},
		async initTemplate() {
			let template;
			try {
				template = await this.configDataProvider.getTemplate('lookup', 'detail');
			} catch (ex) {
				return;
			}
			if (template && template.content && template.content.details) {
				this.rows = template.content.details.row.map((row) => {
					return Object.assign({ $key: generateId() }, row);
				});

				this.lookupEntities = this.rows.map(x => x.column[0].control.entity);
			}
		}
	},
	beforeDestroy() {
		if (this.fork) {
			this.fork.dispose();
		}
		this.$root.$off(['entity.changed', 'entity.created', 'entity.deleted'], this.checkAutoRefresh);
	}
};
</script>
<style src="../Theme/Details/theme-details.less" scoped></style>
