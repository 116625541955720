/* eslint no-unused-vars: 0 */
/* eslint vue/no-unused-components: 0 */
// TODO: review
import { SubscribeToSearch, UnSubscribeFromSearch } from '@acx-xms/data-functions/dist';
import { Timer } from '@/Components/Control/Chat/timer';
import * as signalR from '@microsoft/signalr';
import { GetToken } from '@/Data/Auth/authentication-service';
import {
	SetPerConfig, GetPerConfig, RemovePerConfig
} from '@/Data/DataProviders/userStorageDataProvider';

// list of availiable hubs:
// logMessage - for consuming logs
// notificationHub - for consuming notifications
// changeFeedHub - for consuming changes in entities matching specific search criteria

const url = location.origin + '/api/signalR/changeFeedHub';
let timer = null;

export const KINDS = {
	CREATED: 'created',
	UPDATED: 'updated',
	DELETED: 'deleted',
	UNSUBSCRIBED: 'unsubscribed',
	NOOP: 'noop'
};

export const LOCAL_STORAGE_FEED_KEYS = {
	MESSAGE: 'chatmessage.feedId',
	PARTICIPANT: 'chatparticipant.feedId'
};

const activeFeeds = {};

function _getEdgeLanguages() {
	return sc.localization.sort((l) => !l.current).map((l) => l.code).join(',');
}

async function subscribeToSignalR(feedId, callbackOnNewMessages) {
	const options = {
		accessTokenFactory: () => GetToken(sc.clusterKey()),
		headers: {
			'Edge-Languages': _getEdgeLanguages(),
			'Acx-Edge-FeedId': feedId
		}
	};
	const connection = new signalR.HubConnectionBuilder().withUrl(url, options).withAutomaticReconnect().build();
	connection.on('broadcastMessage', (data) => {
		if (data.Changes.some(c => c.Kind === KINDS.UNSUBSCRIBED)) {
			connection.stop();
		} else {
			callbackOnNewMessages(data);
		}
	});
	const start = async () => {
		try {
			await connection.start();
		} catch (e) {
			setTimeout(start, 5000);
		}
	};
	start();
}

export async function Subscribe(logicalNames, filters, callbackOnNewMessages, kinds = [], timeToLive = '00.01:00:00.00') { // timeToLive, default = 1 hour
	filters.push({
		requestLabel: 'timeToLive',
		data: timeToLive
	});
	filters.push({
		requestLabel: 'Kinds',
		data: [...kinds, KINDS.UNSUBSCRIBED]
	});
	const { Id: feedId } = await SubscribeToSearch(logicalNames, filters);
	activeFeeds[feedId] = null;

	await subscribeToSignalR(feedId, callbackOnNewMessages);
	return feedId;
}

export async function SubscribeWithResubscribe(logicalNames, filters, callbackOnNewMessages, kinds, resibscribeMinutes, prefix, timeToLive = '00.01:00:00.00') { // timeToLive, default = 1 hour
	const reSubscribe = async (prefix) => {
		const feedId = await Subscribe(logicalNames, filters, callbackOnNewMessages, kinds, timeToLive);
		if (prefix) {
			await addFeedIdToLocalStorageAndUnsubscribe(prefix, feedId);
			activeFeeds[feedId] = prefix;
		}
	};
	timer = new Timer(
		() => reSubscribe(prefix),
		resibscribeMinutes * 1000 * 60
	);
	await reSubscribe(prefix);
}

export async function addFeedIdToLocalStorageAndUnsubscribe(prefix, feedId) {
	const oldFeedId = await GetPerConfig(prefix);
	if (oldFeedId) {
		try {
			await UnSubscribeFromSearch(oldFeedId);
			delete activeFeeds[oldFeedId];
		} catch (e) {
			console.log('Could not unsubscribe from feed. Reason: ', e);
		}
	}
	await SetPerConfig(prefix, feedId);
}

export async function UnsubscribeAndProcessLocalStorageKey(prefix, feedId, withLocalStorageItemRemoval = false) {
	try {
		withLocalStorageItemRemoval ? await RemovePerConfig(activeFeeds[feedId]) : await SetPerConfig(prefix, feedId);
		await UnSubscribeFromSearch(feedId);
	} catch (ex) {
	}
	delete activeFeeds[feedId];
}

let lockResolver;
export function lockTab() {
	if (navigator && navigator.locks && navigator.locks.request) {
		const promise = new Promise((resolve) => {
			lockResolver = resolve;
		});
		navigator.locks.request('MarketSpacePercolationLock', { mode: 'shared' }, () => {
			return promise;
		});
	}
}

/*
	If you're specifically trying to detect page 'unload' events,
	the 'pagehide' event is the best option because the 'beforeunload' event fires after the current window is defocused.
*/
window.addEventListener('pagehide', () => {
	lockResolver && lockResolver();

	// TODO: move REST calls to other place because pagehide doesn't guarantee that the requests are sent'
	Object.keys(activeFeeds).forEach(feed => {
		if (activeFeeds[feed]) {
			/* unsubscribe from feed and delete config from storage */
			UnsubscribeAndProcessLocalStorageKey(activeFeeds[feed], feed, true);
		}
	});
});
