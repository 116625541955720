var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isError
    ? _c(
        "div",
        { staticClass: "manage-images component-search-results-details" },
        [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "rows" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "column" }, [
                  _c(
                    "div",
                    { staticClass: "column-content" },
                    [
                      _vm.componentOptions
                        ? _c("component-search-results", {
                            staticClass: "details-inner-results",
                            attrs: { options: _vm.componentOptions }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ]
      )
    : _vm.isError
    ? _c("div", { staticClass: "manage-images error" }, [
        _vm._v(
          "\n\tYou don't have an access to this record or record doesn't exist.\n\tPlease try to sync the record first.\n"
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }