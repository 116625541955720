export default {
	parameters(options) {
		return [
			this.queries.get(options.queryName).data,
			(options.schema && options.name) ? options.schema + '.' + options.name : null
		];
	},
	evaluate(records, fieldPath) {
		if (records && records.length) {
			const record = records[0];
			return fieldPath
				? sc.utils.findProperty(record, fieldPath, true)
				: record;
		}
		return null;
	}

};
