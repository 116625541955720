import ExpressionAggregateStringConcat from './aggregate.string.concat';
import ExpressionArrayAggregate from './array.aggregate';
import ExpressionArrayAll from './array.all';
import ExpressionArrayAny from './array.any';
import ExpressionArrayConcat from './array.concat';
import ExpressionArrayFilter from './array.filter';
import ExpressionArrayFilterPath from './array.filterPath';
import ExpressionArrayFind from './array.find';
import ExpressionArrayFindPath from './array.findPath';
import ExpressionArray from './array';
import ExpressionArrayLength from './array.length';
import ExpressionArrayMap from './array.map';
import ExpressionArrayMapPath from './array.mapPath';
import ExpressionArrayPick from './array.pick';
import ExpressionArrayReverse from './array.reverse';
import ExpressionArraySlice from './array.slice';
import ExpressionArraySort from './array.sort';
import ExpressionArraySum from './array.sum';
import ExpressionCarray from './carray';
import ExpressionPluck from './pluck';

export default {
	resolve() {
		return {
			'expression.aggregate.string.concat': ExpressionAggregateStringConcat,
			'expression.array.aggregate': ExpressionArrayAggregate,
			'expression.array.all': ExpressionArrayAll,
			'expression.array.any': ExpressionArrayAny,
			'expression.array.concat': ExpressionArrayConcat,
			'expression.array.filter': ExpressionArrayFilter,
			'expression.array.filterPath': ExpressionArrayFilterPath,
			'expression.array.find': ExpressionArrayFind,
			'expression.array.findPath': ExpressionArrayFindPath,
			'expression.array': ExpressionArray,
			'expression.array.length': ExpressionArrayLength,
			'expression.array.map': ExpressionArrayMap,
			'expression.array.mapPath': ExpressionArrayMapPath,
			'expression.array.pick': ExpressionArrayPick,
			'expression.array.reverse': ExpressionArrayReverse,
			'expression.array.slice': ExpressionArraySlice,
			'expression.array.sort': ExpressionArraySort,
			'expression.array.sum': ExpressionArraySum,
			'expression.carray': ExpressionCarray,
			'expression.pluck': ExpressionPluck
		};
	}
};
