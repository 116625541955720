import { isNullOrUndefined, isArray } from '@acx-xms/data-functions/dist';

export default {
	parameters(options) {
		return [
			this.eval(options.argument),
			this.eval(options.skip)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument),
			await this.evalAsync(options.skip)
		];
	},
	evaluate(array, skip) {
		if (isNullOrUndefined(array)) {
			return null;
		}
		if (!isArray(array)) {
			throw new Error('expression.array.pick: Argument is not an array');
		}
		skip = skip || 0;
		return array[skip] || null;
	}
};
