<template>
	<div class="ms-conversation-details">
		<div class="svg-thumbnail back-button" @click="closeDetails">
			<svg class="svg-icon svg-12">
				<use xlink:href="#action-icons-back-button"></use>
			</svg>
		</div>
		<control :key="entity.Id"
				 v-bind:name="detailsTemplate.$type"
				 v-bind:contentProps.once="detailsTemplate"
				 v-bind:evaluationContext="evaluationContext"></control>
		<div class="tabs" v-if="tabsData">
			<div v-for="(tab, key) in tabsData"
				 v-if="tab.enabled"
				 v-localization="{ key: tab.localizationKey }"
				 class="tab-title"
				 :class="{ active: tab.active }"
				 @click="loadTab(key)"
				 :key="key"></div>
		</div>
		<div class="tab-content" v-perfectscroll="{enable: true}" v-if="activeTab" :key="activeTab.key">
			<control v-for="(context, key) in activeTab.context"
					 :key="key"
					 v-bind:class="[activeTab.cssClass]"
					 v-bind:name="activeTab.template.$type"
					 v-bind:contentProps.once="activeTab.template"
					 v-bind:evaluationContext="context"></control>
		</div>
	</div>
</template>
<script>
import Control from '../Entity/control';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'ms-conversation-details',
	components: { Control },
	props: {
		evaluationContext: Object,
		entity: Object,
		detailsTemplate: Object,
		detailsType: String
	},
	data() {
		return {
			isLoading: true,
			activeTab: null,
			participantsData: null,
			tabsData: null
		};
	},
	created() {
		this.participantsData = this.entity.Source.Relations.chatparticipants;
		this.participantsData.sort((a, b) => {
			return a.primary > b.primary ? -1 : 1;
		});
		this.configDataProvider = sc.classes.get('entityConfiguration.dataProvider');
		this.initTabs();
	},
	methods: {
		closeDetails() {
			this.$emit('closeDetails');
		},
		loadTab(key) {
			Object.keys(this.tabsData).forEach(key => {
				this.tabsData[key].active = false;
			});
			if (this.tabsData[key]) {
				this.tabsData[key].active = true;
				this.activeTab = this.tabsData[key];
			}
		},
		async initTabs() {
			const conversationsTemplate = await this.configDataProvider.getTemplate('chat', 'custom', 'conversationsChat');
			const participantsTemplate = await this.configDataProvider.getTemplate('chatparticipant', 'custom', 'conversationsChatParticipant');
			const documentsTemplate = await this.configDataProvider.getTemplate('chat', 'custom', 'chatSharedDocuments');
			const preferencesTemplate = await this.configDataProvider.getTemplate('chat', 'custom', 'chatPreferences');
			this.tabsData = {
				conversations: {
					key: 'conversations',
					enabled: true,
					cssClass: 'conversations-chat single',
					active: true,
					localizationKey: 'conversationsPanelPlugin.conversations',
					template: conversationsTemplate,
					context: [this.evaluationContext]
				},
				preferences: {
					key: 'preferences',
					cssClass: 'single',
					enabled: this.entity.Source.collaborationroomid !== null,
					active: false,
					localizationKey: 'conversationsPanelPlugin.preferences',
					template: preferencesTemplate,
					context: [this.evaluationContext]
				},
				participants: {
					key: 'participants',
					enabled: true,
					active: false,
					localizationKey: 'conversationsPanelPlugin.participants',
					template: participantsTemplate,
					context: this.participantsData.map(participant => this.getContext(participant))
				},
				documents: {
					key: 'documents',
					cssClass: 'single',
					enabled: true,
					active: false,
					localizationKey: 'conversationsPanelPlugin.sharedDocuments',
					template: documentsTemplate,
					context: [this.evaluationContext]
				}
			};
			this.activeTab = this.tabsData.conversations;
		},
		getContext(item) {
			return createEvaluationContext(item);
		}
	}
};
</script>
<style src="./ms-conversation-details-styles.less"></style>
