<template>
	<div class="create-dealroom-from-crm">
		<div class="loading-overlay" v-show="isLoading"></div>
		<div class="header">
			<h2 v-localization="{key: 'CreateRoom.createDR.title'}"></h2>
		</div>
		<div class="body" v-if="!isLoading" v-perfectscroll="{enable: true}">
			<div class="editor">
				<control v-if="editorTemplate" :name="editorTemplate.$type"
						 :contentProps.once="editorTemplate"
						 :evaluationContext="controlContext" />
			</div>
			<h5 class="title" v-localization="{key: 'CreateRoom.contactsTable.title'}"></h5>
			<div class="entity-list" v-perfectscroll="{enable: true}" v-if="!syncContactProcessId">
				<control class="formcontrol-template"
						 v-if="userListTemplate"
						 :name="userListTemplate.$type"
						 :contentProps.once="userListTemplate"
						 :evaluationContext="evaluationContext"
						 :initResults="contacts"></control>

				<div class="p13" v-else-if="noContactInCRM">{{noContactInCRM}}</div>
			</div>
			<SyncProgress :processId="syncContactProcessId" @onSyncCompleted="onSyncContactCompleted(false)" v-else-if="syncContactProcessId" />
			<h5 class="title" v-localization="{key: 'CreateRoom.listingTable.title'}"></h5>
			<component v-if="hasListingData && !syncListingProcessId"
					   :is="'component-search-results'"
					   :options="componentOptions"></component>
			<div class="p13" v-else-if="!hasListingData" v-localization="{key: 'CreateDealRoomPlugin.noListingData'}"></div>
			<SyncProgress :processId="syncListingProcessId" @onSyncCompleted="onSyncListingCompleted" v-else-if="syncListingProcessId" />
		</div>
		<div class="footer">
			<div class="dialog-buttons">
				<button @click="cancel" class="cancel" type="button" v-localization="{key: 'common.dictionary.buttons.cancel'}"></button>
				<button @click="save" type="button" v-localization="{key: 'common.dictionary.buttons.save'}"></button>
			</div>
		</div>
	</div>
</template>
<script>
import Control from '@/Components/Entity/control';
import EditorMixin from '@/Components/editor.mixins.js';
import CreateRoomFromCrmMixin from '../create-room-from-crm.mixins';
import ComponentSearchResults from '@/Components/ComponentSet/SearchResults/component-search-results';
import CreateDealRoomMixins from './create-dealrom.mixins';
import helperMethods from '@/Components/ComponentSet/component-set-evaluation-context-helper.methods';
import SyncProgress from '@/Components/Sync/sync-progress';

export default {
	name: 'create-dealroom-from-crm',
	props: { options: Object },
	mixins: [EditorMixin, CreateRoomFromCrmMixin, CreateDealRoomMixins],
	components: {
		Control,
		ComponentSearchResults,
		SyncProgress
	},
	data() {
		return {
			hasListingData: false,
			editorTemplate: null,
			logicalName: 'dealroom',
			editorSubType: 'create',
			syncListingProcessId: null
		};
	},

	async created() {
		this.syncListingCompleted = false;
		this.listingSaveError = await this.localization.getLabelForCurrentLanguage('crAddListingPlugin.warning.drWarning');

		if (this.options.sfListingId || this.options.sfPropertyId) {
			this.hasListingData = true;
			const componentSet = await sc.classes.get('marketspaceComponents.dataProvider').getComponentSetById('dealroom.addnewlistingFromCrm');
			this.componentOptions = componentSet.component[0];
			this.componentOptions.parentData = this.options;
			const stateNamespace = this.componentOptions.stateNamespace.value;
			this.$store.subscribe((mutation, state) => {
				switch (mutation.type) {
				case stateNamespace + '/changeSelection':
					this.currentSelection = state[stateNamespace].selection;
					break;
				}
			});

			const searchSuffix = 'entity.searched';
			const drNamespace = helperMethods.eval(null, this.componentOptions.namespace);
			const drInternalNamespace = helperMethods.eval(null, this.componentOptions.internalNamespace);
			this.fork.on(`${drNamespace}.${drInternalNamespace}.${searchSuffix}`, async (res) => {
				if (!res) return;
				if (res.results.length === 0 && !this.syncListingCompleted) {
					if (this.options.sfListingId) {
						this.syncListingProcessId = await this.startSync(`${this.crmNamespace}__Listing__c`, [this.options.sfListingId]);
					} else if (this.options.sfPropertyId) {
						this.syncListingProcessId = await this.startSync(`${this.crmNamespace}__Property__c`, [this.options.sfPropertyId]);
					}

					this.syncListingCompleted = true;
				} else if (res.results.length === 1) {
					const listingid = res.results[0].Id;
					this.fork.on(`${drNamespace}.${drInternalNamespace}.availability.${listingid}.${searchSuffix}`, () => {
						this.$root.$emit(`.syncSelection.selectParent.${listingid}`);
					});
				}
			});
		} else {
			this.hasListingData = false;
		}
	},
	methods: {
		async save() {
			this.progress = {
				processed: 0,
				message: await this.localization.getLabelForCurrentLanguage('CreateDealRoomPlugin.progress.message'),
				title: await this.localization.getLabelForCurrentLanguage('CreateDealRoomPlugin.progress.title'),
				total: 0
			};

			try {
				if (await this.validateData()) {
					const data = this.$store.state[this.stateKey].entityData;
					const availabilities = this.currentSelection.availability;
					this.progress.total = this.currentSelection.listing.length +
							(this.currentSelection.availability ? this.currentSelection.availability.length : 0) +
							this.contacts.length +
							5;
					const listing = this.currentSelection.listing.pop();

					sc.events.emit('vue.progressDialog.open', this.progress);

					const dealroom = await this.createDealRoomWithRelations(data, listing);
					const dealroomRef = sc.classes.get('entityReference', dealroom);

					if (availabilities && availabilities.length) {
						await this.createDRAvailabilities(availabilities, dealroom);
					}

					this.navigateToEntityDetails(dealroomRef);
				}
			} catch (e) {
				sc.utils.errorMessage.byResponse(e);
			} finally {
				this.progress.processed = this.progress.total;
			}
		},
		async validateData() {
			const isValidListingSelection = this.currentSelection && this.currentSelection.listing && this.currentSelection.listing.length === 1;
			const validationResult = await this.validate(this.stateKey);
			if (!validationResult.isValid || !isValidListingSelection) {
				let message = validationResult.message || '';
				if (!isValidListingSelection) {
					message += `- ${this.listingSaveError}<br>`;
				}

				this.showValidationError(message);
				return false;
			}

			const isRoomExist = await this.isRoomExist();
			if (isRoomExist) {
				return false;
			}

			return true;
		},
		onSyncListingCompleted() {
			this.syncListingProcessId = null;
		}
	}

};
</script>
<style src="./create-dealroom-from-crm.less" scoped></style>
<style src="../create-room-from-crm.less" scoped></style>
