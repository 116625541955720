<template>
	<div class="selected-records-text mobile" v-if="showSelectedCount" v-show="selectedText">
		{{selectedText}}
	</div>
</template>
<script>
import SelectedrecordsMixin from './selectedrecords.mixins';

export default {
	name: 'mobile-component-set-search-results-selectedrecords',
	mixins: [SelectedrecordsMixin]
};
</script>
<style src="./selectedrecords.less" scoped></style>
