<template>
    <div class='step-loader' :style="`height: ${height ? height : 'calc(100% - 69px)'}`">
        <div class='reportingservice-overlay loading-overlay large'></div>
        <div class='step-loader-text'>
            {{text}}
        </div>
    </div>
</template>

<script>
export default {
    name: 'email-campaign-loader',
    props: {
        text: {
            type: String,
            default: '',
        },
        height: {
            type: String,
            default: '',
        }
    },
};
</script>

<style scoped lang='less'>
@import './loader.less';
</style>