<template>
	<div
		v-data-attr
		class="marketspace-conversation-menu"
		title="Notifications"
		@click="openNotification"
	>
		<svg class="svg-icon svg-20">
			<use xlink:href="#action-icons-ms-menu-notifications" class="icon" />
		</svg>
		<Counter v-if="newNotifications" :count="newNotifications" />
	</div>
</template>
<script>
import '@/Bindings/ui-attribute.directive';
import Counter from '@/Components/Control/UnreadMessagesCounter/counter';
import { Search } from '@acx-xms/data-functions/dist';
import { GetUserInfo } from '@/Data/Auth/authentication-service';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'menu-notification',
	data() {
		return {
			notifications: [],
			newNotifications: 0,
			isPushNotificationsEnabled: false,
			userRef: void 0
		};
	},
	methods: {
		openNotification() {
			sc.events.emit('sidePanel.open', 'ms-notifications-panel', {
				evaluationContext: createEvaluationContext(this.notifications),
				tabs: this.settings.tab,
				notifications: this.notifications,
				isPushNotificationsEnabled: this.isPushNotificationsEnabled,
				notificationsWithoutHyperLink: this.settings.notificationsWithoutHyperLink
			});
			setTimeout(() => {
				this.newNotifications = 0;
				this.$store.commit('counters/removeNotificationCounters');
			}, 2000);
		},
		async initOneSignal(appId) {
			const oneSignal = window.OneSignal || [];
			oneSignal.push(() => {
				oneSignal.init({
					appId,
					notifyButton: { enable: false },
					allowLocalhostAsSecureOrigin: true,
					welcomeNotification: { disable: true },
					notificationClickHandlerMatch: 'origin',
					notificationClickHandlerAction: 'focus',
					persistNotification: false,
					autoResubscribe: true
				});
				oneSignal.setExternalUserId(this.systemuserid);
				oneSignal.on('notificationDisplay', this.addNotification);
				oneSignal.showNativePrompt();
				oneSignal.on('subscriptionChange', this.subscriptionChange);
			});

			oneSignal.isPushNotificationsEnabled(result => {
				// as this promise is not resolving if notifications are disabled - we do not need to block execution of created hook
				this.isPushNotificationsEnabled = result;
				this.$store.commit('changeIsPushNotificationsEnabled', this.isPushNotificationsEnabled);
			});
		},
		addNotification(notification) {
			if (notification.data.recepientid.id !== this.userRef.id) { return; }

			if (notification.data.notificationtype && this.allowedNotificationTypes.indexOf(notification.data.notificationtype.id) > -1) {
				if (notification.data.notificationtype.id !== 'b08df37b-a8b1-400d-ab44-dcb76fe99294') {
					notification.data.userInfo = JSON.parse(notification.data.userInfo);
				}
				notification.createdon = new Date();
				notification.id = notification.data.external_id;
				// if accessrequestapproved and user is on the same page
				if (notification.data.notificationtype.id === 'f34cbdf6-e957-4d12-8a68-5c9f74e64350' && this.$router.currentRoute.path.includes(notification.data.userInfo.content.navigationRef.id)) {
					this.refreshPage();
				}
				if (!this.notifications.find(notif => notif.id === notification.id)) {
					this.updateNotifications([notification]);
				}

				this.$store.commit('counters/addNotificationCounters', { [notification.data.external_id]: { counter: 1 } });

				this.newNotifications++;
			}
		},
		subscriptionChange(isSubscribed) {
			this.isPushNotificationsEnabled = isSubscribed;
			if (isSubscribed) {
				Object.keys(sessionStorage).forEach(key => {
					if (key.toLowerCase().includes('onesignal')) {
						sessionStorage.removeItem(key);
					}
				});
			}
		},

		setUserInfo() {
			return sc.classes.get('authentication').getUserInfo()
				.then(({ systemuserid }) => {
					this.systemuserid = systemuserid;
				});
		},
		updateNotifications(notifications) {
			this.notifications = this.notifications.concat(notifications);
		},
		async getNotifications() {
			if (this.isPushNotificationsEnabled) {
				const lastMonthsNumber = this.settings.lastMonthsNumber > 0 ? this.settings.lastMonthsNumber : 12;
				const maximumNotificationCount = this.settings.maximumNotificationCount > 0 ? this.settings.maximumNotificationCount : 100;
				const to = new Date();
				const from = new Date();
				from.setMonth(from.getMonth() - lastMonthsNumber);
				const notifications = await Search(['notification'], [
					sc.classes.get('offsetSize.filter', maximumNotificationCount).fillQuery(),
					sc.classes.get('termFacet.filter', {
						logicalName: 'recepientid.id',
						query: [this.systemuserid]
					}).fillQuery(),
					sc.classes.get('termFacet.filter', {
						logicalName: 'skipped',
						query: [true],
						negative: true
					}).fillQuery(),
					sc.classes.get('termFacet.filter', {
						logicalName: 'notificationtype.id',
						query: this.allowedNotificationTypes
					}).fillQuery(),
					sc.classes.get('dateTimeFacet.filter', {
						logicalName: 'createdon',
						query: [{
							IsChecked: true,
							Range: {
								From: from.toJSON(),
								To: to.toJSON()
							}
						}]
					}).fillQuery(),
					sc.classes.get('orderBy.filter', {
						query: [{
							logicalName: 'createdon',
							value: true
						}]
					}).fillQuery(),
					sc.classes.get('selectedFields.filter', [
						{ logicalname: 'createdon' },
						{ logicalname: 'headings.en-us' },
						{ logicalname: 'contents.en-us' },
						{ logicalname: 'userInfo' },
						{ logicalname: 'notificationtype' }
					]).fillQuery()
				]);

				const newParticipanNotificatins = notifications.Results
					.map((x) => {
						return {
							id: x.Id,
							createdon: x.Source.createdon,
							heading: x.Source.headings['en-us'],
							content: x.Source.contents['en-us'],
							data: {
								userInfo: JSON.parse(x.Source.userInfo),
								notificationtype: x.Source.notificationtype
							}
						};
					});

				this.updateNotifications(newParticipanNotificatins);
			} else {
				this.notifications = [];
			}
		},
		refreshPage() {
			this.$root.$emit('refreshCurrentDetailsWithMiddleware');
		}
	},
	components: { Counter },
	watch: {
		isPushNotificationsEnabled: function (val) {
			if (val) {
				this.getNotifications();
			} else {
				this.notifications = [];
			}
		}
	},
	async created() {
		this.settings = await sc.classes.get('settings.dataProvider').get('notificationPanelSettings');
		this.allowedNotificationTypes = this.settings.allowedNotificationTypes && this.settings.allowedNotificationTypes.notificationTypeId;
		await this.setUserInfo();
		const environmentSettings = await sc.classes.get('settings.dataProvider').getEnvironmentSettings(this.$route.params.clusterKey);

		const info = await GetUserInfo();
		this.userRef = sc.classes.get('entityReference', info);

		if (environmentSettings && environmentSettings.oneSignalAppId) {
			await this.initOneSignal(environmentSettings.oneSignalAppId);
		}
		this.fork = sc.events.fork();
		this.fork.on('setNotifications', (notifications) => {
			this.notifications = notifications;
		});
	}
};
</script>
<style src="./menu-conversation.less" scoped></style>
