import { isNull, isNullOrUndefined } from '@acx-xms/data-functions/dist';
import ExpressionCDate from './cdate';

const round = (val, num, mode) => {
	let fn;
	switch (mode) {
	case 'ceil':
		fn = Math.ceil;
		break;
	case 'floor':
		fn = Math.floor;
		break;
	case 'round':
		fn = Math.round;
		break;
	default:
		throw new Error(`Unknown round function: ${mode}`);
	}
	return fn(val / num) * num;
};
const getDays = (date) => {
	return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

export default {
	parameters(options) {
		return [
			this.eval(options.basicDate),
			this.eval(options.span),
			this.eval(options.mode)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.basicDate),
			await this.evalAsync(options.span),
			await this.evalAsync(options.mode)
		];
	},
	evaluate(date, span, mode) {
		date = ExpressionCDate.evaluate(date);
		mode = mode || 'ceil';
		if (isNull(date)) {
			return null;
		}
		if (isNullOrUndefined(span)) {
			return date;
		}
		let year = 0;
		let month = 0; // month should be zero-based for the Date constructor
		let day = 1;
		let hour = 0;
		let minute = 0;

		if (span.years) {
			year = round(date.getFullYear() + (date.getMonth() + 1) / 12, span.years, mode);
		} else if (span.months) {
			year = date.getFullYear();
			month = round(date.getMonth() + date.getDate() / getDays(date), span.months, mode);
		} else if (span.days) {
			year = date.getFullYear();
			month = date.getMonth();
			day = round(date.getDate() + date.getHours() / 24, span.days, mode);
		} else if (span.hours) {
			year = date.getFullYear();
			month = date.getMonth();
			day = date.getDate();
			hour = round(date.getHours() + date.getMinutes() / 60, span.hours, mode);
		} else if (span.minutes) {
			year = date.getFullYear();
			month = date.getMonth();
			day = date.getDate();
			hour = date.getHours();
			minute = round(date.getMinutes() + date.getSeconds() / 60, span.minutes, mode);
		} else {
			return date;
		}
		return new Date(year, month, day, hour, minute);
	}
};
