var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items.length
    ? _c("div", { staticClass: "marketspace-header-menu" }, [
        _c(
          "div",
          { staticClass: "items" },
          _vm._l(_vm.items, function(item) {
            return _c("menu-item", {
              key: item.menuItem.action.$type,
              staticClass: "p6",
              attrs: { options: item.menuItem, evaluationContext: _vm.context },
              nativeOn: {
                click: function($event) {
                  _vm.executeCallback(item.menuItem.action)
                }
              }
            })
          })
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }