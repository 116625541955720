import { isActive, checkPrivilege } from './Methods/action.methods';
export default {
	async enabled(options, evaluationContext, selection) {
		const active = await isActive(evaluationContext, selection);
		if (!active) {
			return false;
		}
		return await checkPrivilege('staticlist', 'Write');
	},

	async execute(options, context, selection) {
		sc.classes.get('logger.processor').track('Static lists: create');
		// always "customSelection" or "selection", not both
		const localizations = sc.classes.get('localization.dataProvider');
		const customSelection = options.customSelection;
		let predefinedContactFilters;
		let filter;
		if (customSelection) {
			const ids = customSelection.map(item => item.id);
			filter = sc.classes.get('termFacet.filter', {
				logicalName: 'contactid',
				query: ids
			});
		}
		const logicalname = 'staticlist';
		const sel = selection || context.eval(options.selection);
		let entity = null;

		if (options.source !== 'noSelection' && sel) {
			entity = selection[0];
			filter = sc.classes.get('termFacet.filter', {
				logicalName: 'staticlistmembers.staticlist.id',
				query: [entity.id || entity.Id]
			});
		} else {
			entity = await sc.classes.get('entityConfiguration.dataProvider').produceEntity(logicalname);
		}

		if (customSelection || (sel && options.source !== 'noSelection')) {
			predefinedContactFilters = [
				sc.classes.get('offsetFrom.filter', 0),
				sc.classes.get('offsetSize.filter', 9999),
				filter.fillQuery()
			];
		}
		const entityData = await sc.classes.get('entityConfiguration.dataProvider').fetchEntity(logicalname);
		const entityLabel = await context.evalAsync(entityData.displayName);

		const title = options.customTitle ? context.eval(options.customTitle) : await localizations.getLabelForCurrentLanguage('entity.editor.titleCreate', [entityLabel]);

		const dialogConfig = {
			component: 'staticlists-ms-create',
			title,
			icon: 'entity-icons-awx_adhoclist-small',
			maximizedWidth: '90%',
			maximizedHeight: '100%',
			userListTemplate: options.listTemplate,
			editorTemplate: options.editorTemplate,
			params: {
				entity,
				predefinedContactFilters,
				...options,
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey)
			}
		};

		// uncomment when fixes for import will be applied
		// if (!sc.utils.hasCRMsettings) {
		// 	dialogConfig.params.importOptions = {
		// 		title: 'Import from Excel',
		// 		maximizedWidth: '75%',
		// 		maximizedHeight: '90%',
		// 		component: 'import.dialog',
		// 		fields: options.importOptions.fields.map(f => context.eval(f)),
		// 		dataExamples: options.importOptions.dataExamples.map(e => context.eval(e)),
		// 		columnsSize: options.importOptions.columnsSize,
		// 		refreshEvent: context.eval(options.importOptions.refreshEvent),
		// 		displayname: context.eval(options.importOptions.displayname),
		// 		logicalname: context.eval(options.importOptions.logicalname),
		// 		finalStepButtonLabel: context.eval(options.importOptions.finalStepButtonLabel),
		// 		failureOptions: options.importOptions.failureOptions
		// 	};
		// }

		sc.events.emit('vue.dialog.open', dialogConfig);
	}
};
