import ExpressionHasValue from '../boolean/hasvalue';

export default {
	parameters(options) {
		return [
			this.eval(options.argument)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument)
		];
	},
	evaluate(arg) {
		return !ExpressionHasValue.evaluate(arg);
	}
};
