export default {
	async enabled(options, evaluationContext, selection) {
		return Promise.resolve(true);
	},
	async execute(options, context, selection) {
		const refreshEvent = context.eval(options.refreshEvent);
		const localizations = sc.classes.get('localization.dataProvider');

		sc.events.emit('vue.dialog.open', {
			title: await localizations.getLabelForCurrentLanguage('dialogs.importTemplate'),
			allowPin: false,
			component: 'import-template-dialog',
			maximizedWidth: '500px',
			maximizedHeight: '400px',
			params: {
				evaluationContext: context,
				refreshEvent,
				requiredFields: options.requiredFields,
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey)
			}
		});
	}
};
