var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible && _vm.currentcomponentset
    ? _c(
        "div",
        { staticClass: "component-search-results is-parent" },
        [
          _vm.options && _vm.options.filterPanel
            ? [
                _c("FiltersPanel", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showFilterPanel,
                      expression: "showFilterPanel"
                    }
                  ],
                  attrs: {
                    options: _vm.options.filterPanel,
                    evaluationContext: _vm.filterPanelEvaluationContext,
                    isLoading: _vm.isLoading,
                    entityName: _vm.logicalName,
                    stateNamespace: _vm.stateNamespace
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "search-results-wrapper" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.hideHeader,
                    expression: "!hideHeader"
                  }
                ],
                staticClass: "header"
              },
              [
                _c(
                  "div",
                  { staticClass: "region" },
                  [
                    _vm.options.filterPanel
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "data-attr",
                                rawName: "v-data-attr",
                                value: {
                                  options: _vm.options,
                                  stateNamespace: "button"
                                },
                                expression:
                                  "{ options: options, stateNamespace: 'button' }"
                              }
                            ],
                            class: [
                              "filter-button active",
                              { open: !!_vm.showFilterPanel }
                            ],
                            on: {
                              click: function($event) {
                                _vm.toggleFilterPanel()
                              }
                            }
                          },
                          [
                            _c("svg", { staticClass: "svg-icon" }, [
                              _c("use", {
                                staticClass: "ms-filter-icon",
                                attrs: {
                                  "xlink:href": "#layout-icons-ms-filter-icon"
                                }
                              })
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._t("title-slot", [
                      _c("h1", { staticClass: "title h1" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" + _vm._s(_vm.title) + "\n\t\t\t\t\t"
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._t("toggle-slot", [
                      _vm.withToggleAll
                        ? _c("ResultsToggle", {
                            staticClass: "toggle-padding",
                            attrs: {
                              namespace: _vm.namespace,
                              internalNamespace: _vm.internalNamespace,
                              toggleNamespace: _vm.toggleNamespace,
                              isParent: ""
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("ComponentSetSearchResultsSelectedRecords", {
                      attrs: {
                        stateNamespace: _vm.stateNamespace,
                        showSelectedCount: _vm.showSelectedCount,
                        targetEntity: _vm.targetEntity,
                        withParent: _vm.options.selectedTextWithParent,
                        overridedNames: _vm.overridedNames
                      }
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "region" },
                  [
                    !_vm.hideSearchField ? _c("SearchField") : _vm._e(),
                    _vm._v(" "),
                    _vm.options.sortByFields
                      ? [
                          _c("sortpanel", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.sortByFields,
                                expression: "sortByFields"
                              }
                            ],
                            staticClass: "sortby-filter-wrapper",
                            attrs: {
                              fields: _vm.options.sortByFields,
                              entityName: _vm.logicalName,
                              evaluationContext: _vm.evaluationContext,
                              scrollNamespace: _vm.scrollNamespace,
                              namespace: _vm.namespace,
                              internalNamespace: _vm.internalNamespace,
                              stateNamespace: _vm.stateNamespace
                            }
                          })
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._t("favorites"),
                    _vm._v(" "),
                    _c("ViewSwitcher", {
                      attrs: {
                        items: _vm.items,
                        stateNamespace: _vm.stateNamespace
                      }
                    }),
                    _vm._v(" "),
                    _vm.actionMenuComponent &&
                    (_vm.actionMenuComponent.menu ||
                      _vm.actionMenuComponent.toolbarActions)
                      ? _c("ReactiveActionMenu", {
                          class: {
                            "without-border":
                              !_vm.sortByFields && (_vm.items || []).length < 2
                          },
                          attrs: {
                            options: _vm.actionMenuComponent,
                            stateNamespace: _vm.stateNamespace,
                            evaluationContext: _vm.evaluationContext
                          }
                        })
                      : _vm._e()
                  ],
                  2
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isCollapsed,
                    expression: "!isCollapsed"
                  }
                ],
                class: [
                  "content-wrapper",
                  {
                    withPanel: _vm.showFilterPanel,
                    loading: _vm.isLoading
                  }
                ]
              },
              [
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c("keep-alive", [
                      _c(_vm.content.name, {
                        tag: "div",
                        class: [
                          "content-inherit-height",
                          { "with-footer": !!_vm.results.total }
                        ],
                        attrs: {
                          options: _vm.content,
                          isActive: _vm.isActive,
                          results: _vm.results.results,
                          stateNamespace: _vm.stateNamespace,
                          combinedNamespace: !_vm.options.filterPanel
                            ? _vm.combinedNamespace
                            : "",
                          paginationParams: _vm.paginationParams
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isLoading,
                          expression: "isLoading"
                        }
                      ],
                      staticClass: "loading-overlay"
                    })
                  ],
                  1
                )
              ]
            )
          ])
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }