<template>
	<div class="content-loader">
		<div class="initialization">
			<div class="message">{{message}}</div>
			<div class="progress-wrapper">
				<div class="progress"></div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'initialization-panel',
	props: { message: String }
};
</script>
<style src="./initialization-panel.less" scoped></style>
