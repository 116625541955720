var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "total-counter-wrapper" }, [
    !_vm.isLoading
      ? _c("div", { staticClass: "total-counter" }, [
          _c("div", { staticClass: "number" }, [
            _c(
              "svg",
              { staticClass: "svg-icon svg-40", style: { fill: _vm.color } },
              [_c("use", { attrs: { "xlink:href": _vm.icon } })]
            ),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.count))])
          ]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.label))])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoading,
          expression: "isLoading"
        }
      ],
      staticClass: "loading-overlay"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }