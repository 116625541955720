<template>
    <div class="control-container" v-if="items">
        <control :name="options.control.$type" v-for="item in items"
					:key="item"
                    :contentProps="options.control"
                    :evaluationContext="createContext(item)">
        </control>
    </div>
</template>
<script>
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

export default {
	name: 'control-array',
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: { Control: () => import('./../Entity/control.vue') },
	data() {
		return { items: null };
	},
	created() {
		this.items = this.evaluationContext.eval(this.options.value);
	},
	methods: {
		createContext(item) {
			return createEvaluationContext(item);
		}
	}
};
</script>
