<template>
	<div class="search-results-with-preview">
		<div class="no-results-message" v-show="!results || !results.length">
			<div v-localization="{ key: 'common.dictionary.noMatchesFound' }"></div>
		</div>
		<div class="content-scroll-wrapper" v-if="results && results.length > 0">
			<div class="participant-preview" v-if="previewItem && withPreview">
				<entitytemplate-template :options="options.contentComponent.previewTemplate"
										 :key="previewItem.Id + '_' + updateNumber"
										 :evaluationContext="getContext(previewItem)">
				</entitytemplate-template>
			</div>
			<div :class="{'participants-list' : withPreview}" v-perfectscroll="{enable: true, addMouseoverEvent: true}">
				<entitytemplate-template v-for="item in results"
										 :options="options.contentComponent.listTemplate"
										 :evaluationContext="getContext(item)"
										 :class="{ 'selected': previewItem !== null && item.Id === previewItem.Id}"
										 :key="item.Id + '_' + updateNumber"
										 @click.native="selectItem(item)">
				</entitytemplate-template>
			</div>
		</div>
	</div>
</template>
<script>
import EntitytemplateTemplate from '@/Components/EntityTemplate/entitytemplate-template.vue';
import { createEvaluationContext } from '@/Data/EvaluationContext/evaluationContext';

const availableEntities = ['collaborationroom'];

export default {
	name: 'component-set-search-results-with-preview',
	props: {
		options: Object,
		results: Array,
		paginationParams: Object
	},
	data() {
		return {
			previewItem: null,
			updateNumber: 0,
			withPreview: !!this.options.contentComponent.previewTemplate
		};
	},
	components: { EntitytemplateTemplate },
	watch: {
		results() {
			this.updateNumber = this.updateNumber + 1;

			if (this.results && this.results.length && this.withPreview) {
				this.previewItem = this.results[0];
			}
			/* after chatparticipants update, save current room */
			this.saveRoomsInStore();
		}
	},
	methods: {
		getContext(item) {
			return createEvaluationContext(item);
		},
		selectItem(item) {
			if (this.withPreview) {
				this.previewItem = item;
			}
		},
		saveRoomsInStore() {
			const type = this.$route.params.type;
			if (availableEntities.includes(type)) {
				const data = JSON.parse(JSON.stringify((this.$attrs.evaluationContext.datasource)));
				const ID = (data.Source[`${type}id`] && data.Source[`${type}id`].id) ? data.Source[`${type}id`].id : this.$route.params.id;
				this.$store.dispatch('setRoomToUpdate', ID);
			}
		}
	}
};
</script>
<style src="./component-set-search-results-with-preview.less" scoped></style>
<style src="./search-details-participants.less"></style>
