<template>
	<div class="component-innersearch-results base" v-if="results.length>0">
		<div class="content-wrapper">
			<div class="content">
				<div class="content-inherit-height"
					 v-if="isMounted"
					 v-bind:is="prepareComponentName(content.$type)"
					 v-bind:options="content"
					 cssClass="inner-search-results-base"
					 v-bind:results="results"></div>
			</div>
		</div>
		<div class="loading-wrapper" v-show="loading">
			<div class="loading-overlay"></div>
		</div>

	</div>
</template>
<script>
import { prepareComponentName as prepCompName } from '@acx-xms/data-functions/dist';
import ComponentSearchResultsMixins from './component-search-results.mixins';
const availableEntities = ['collaborationroom'];

export default {
	name: 'component-set-component-inner-search-results-base',
	mixins: [ComponentSearchResultsMixins],
	props: {
		options: Object,
		evaluationContext: Object
	},
	components: { 'component-set-searchresultstableview': () => import('./Views/table-view/component-set-search-results-table-view') },
	data() {
		return {
			results: [],
			loading: true,
			isMounted: false,
			content: this.options.component[0]
		};
	},
	created() {
		this.logicalName = this.evaluationContext.eval(this.options.targetEntity[0]);
		const itemsPerPage = this.options.itemsPerPage
			? this.options.itemsPerPage.itemsCountPerPage
			: [{
				value: 10,
				default: true
			}];
		const defaultItem = itemsPerPage.filter(item => item.default === true);
		const availablePageSizes = itemsPerPage.map(item => parseInt(item.value));
		this.pageSize = defaultItem.length ? parseInt(defaultItem[0].value) : availablePageSizes[0];
	},
	mounted() {
		this.isMounted = true;
		this.groupedSearch();
	},
	activated() {
		/* call update info after return to this component */
		const type = this.evaluationContext.datasource.Type;
		if (!this.results.length && availableEntities.includes(type)) {
			const room = this.$store.getters.getRoomToUpdateByID;
			if (room === this.evaluationContext.datasource.Id) {
				const filter = sc.classes.get('termFacet.filter', {
					logicalName: `${type}id.id`,
					query: [room]
				});
				this.groupedSearch([filter]);
				this.$store.dispatch('setRoomToUpdate', '');
			}
		}
	},
	methods: {
		prepareComponentName(name) {
			return prepCompName(name);
		}
	}
};
</script>
<style>
	.component-innersearch-results {
		position: relative;
	}

	.component-innersearch-results.base .row {
		padding: 0;
	}

	.loading-wrapper .loading-overlay {
		background-color: transparent;
	}
</style>
<style src="./inner-search-results.less" scoped></style>
