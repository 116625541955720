import { isActive } from './Methods/action.methods';

export default {
	async enabled(options, evaluationContext, selection) {
		return selection.length > 0 && await isActive(evaluationContext, selection);
	},
	async execute(options, context, selection) {
		sc.events.emit('vue.dialog.open', {
			title: await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('dialogues.copyLink'),
			component: 'copy-link-dialog',
			state: 'hidden',
			maximizedWidth: '600px',
			maximizedHeight: '460px',
			params: {
				selection: selection[0],
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey),
				linkTail: context.eval(options.linkTail)
			}
		});
	}
};
