<template>
		<ComponentSetMobileSearchResultsTableView  v-bind:class="{ 'mobile-table-view': true }"
												  :options="options"
												  :results="results"
												  :paginationParams="paginationParams"
												  :stateNamespace="stateNamespace"></ComponentSetMobileSearchResultsTableView>
</template>
<script>
import ComponentSetMobileSearchResultsTableView from '@/Components/ComponentSet/SearchResults/Views/table-view/component-set-mobile-search-results-table-view';

export default {
	name: 'control-mobile-table-view',
	props: {
		options: Object,
		initResults: Array,
		paginationParams: Object,
		stateNamespace: String,
		evaluationContext: Object
	},
	computed: {
		results() {
			if (this.initResults !== void 0) {
				return this.initResults;
			}
			if (this.dataSource) {
				return this.evaluationContext.eval(this.dataSource);
			}
			return [];
		}
	},
	components: { ComponentSetMobileSearchResultsTableView },
	data() {
		return { dataSource: this.options.dataSource };
	}
};
</script>
<style src="./control-mobile-table-view.less" scoped></style>
