import { isAllowedActivation } from './Methods/action.methods';

export default {
	enabled(options, evaluationContext, selection, fullSelection) {
		const availability = selection.find(item => item.Type === 'availability');
		if (options.takefullSelection && availability) {
			const listingid = availability && availability.Source.listingid.id;
			selection = [fullSelection.listing && fullSelection.listing.find(listing => listing.Id === listingid)];
		}
		return isAllowedActivation(options, evaluationContext, selection);
	},
	execute(options, context, selection, fullSelection) {
		if (options.takefullSelection) {
			const records = [];
			fullSelection.listing && fullSelection.listing.forEach(selection => {
				records.push(selection);
			});
			selection = records;
		}
		sc.events.emit('vue.dialog.open', {
			localizedTitle: 'common.dictionary.confirmReactivationDlgCaption',
			component: 'deactivate-dialog',
			maximizedHeight: options.documentRelatedRecordsTemplate ? '400px' : 'auto',
			documentRelatedRecordsTemplate: options.documentRelatedRecordsTemplate,
			evaluationContext: context,
			params: {
				entities: selection,
				isDeactivation: false,
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey)
			}
		});
	}
};
