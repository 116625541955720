import {
	showToastOrInfoDialog, generateGuid, Search, CreateBulk
} from '@acx-xms/data-functions/dist';
import { GetUserInfo } from '@/Data/Auth/authentication-service';

export default {
	async enabled(options, context, [selection]) {
		const { systemuserid } = await GetUserInfo();
		const isEnabled = selection.Source.ReadAccessList ? selection.Source.ReadAccessList.Shared.some(item => item === systemuserid) : false;
		return isEnabled;
	},
	async execute(options, context, [selection]) {
		const localizations = sc.classes.get('localization.dataProvider');
		const { systemuserid } = await GetUserInfo();
		sc.events.emit('vue.dialog.open', {
			icon: 'action-icons-search-save',
			title: await localizations.getLabelForCurrentLanguage('common.dictionary.confirmDlgCaption'),
			component: 'confirm.dialog',
			maximizedWidth: '450px',
			maximizedHeight: 'auto',
			evaluationContext: context,
			refreshEvents: options.refreshEvents || [],
			onSubmit: async () => {
				const removeSharedRelatedEntities = options.removeSharedRelatedEntities; let relatedRecords = [];
				if (removeSharedRelatedEntities) {
					relatedRecords = await Search(removeSharedRelatedEntities.relatedEntityName, [
						sc.classes.get('offsetSize.filter', 10000),
						sc.classes.get('termFacet.filter', {
							logicalName: removeSharedRelatedEntities.relatedEntityFilterLogicalName,
							query: [context.entity.id]
						}).fillQuery(),
						sc.classes.get('selectedFields.filter', [
							{ logicalname: 'ownerid.id' },
							{ logicalname: 'recordstate.id' }
						]).fillQuery()
					]);
					relatedRecords = relatedRecords.Results;
				}
				let error = null;
				const revokeAccessRecords = [];
				revokeAccessRecords.push({
					id: generateGuid(),
					method: 'DELETE',
					url: `${selection.Type || selection.logicalname}/${selection.Id || selection.id}/RevokeAccess`,
					body: {
						SyncProcessMode: 'Brief',
						PrincipalType: 'systemuser',
						PrincipalId: systemuserid,
						AccessRights: ['Read', 'Share']
					}
				});
				if (relatedRecords.length) {
					for (const record of relatedRecords) {
						revokeAccessRecords.push({
							id: generateGuid(),
							method: 'DELETE',
							url: `${record.Type}/${record.Id}/RevokeAccess`,
							body: {
								SyncProcessMode: 'Brief',
								PrincipalType: 'systemuser',
								PrincipalId: systemuserid,
								AccessRights: ['Read', 'Share']
							}
						});
					}
				}
				try {
					await CreateBulk(revokeAccessRecords, { async: true });
				} catch (e) {
					error = e;
				}
				await showToastOrInfoDialog({
					toastMessageKey: context.eval(options.toastMessageKey),
					informationDialogTextKey: context.eval(options.informationDialogTextKey)
				});

				if (error) {
					showError(error, context, options.entityTitleName);
				}
			},
			message: await localizations.getLabelForCurrentLanguage('dialogues.savedSearch.removeShared', [context.eval(options.entityTitleName), selection.Name || selection.name]),
			okLabel: await localizations.getLabelForCurrentLanguage('common.dictionary.buttons.remove')
		});
	}
};

async function showError(error, context, entityTitleName) {
	let message = error.responseText;
	if (message && message.endsWith('is denied.')) { // TOOD: change after revokeAccess method is moved to edge-fetch.js
		message = await sc.classes.get('localization.dataProvider').getLabelForCurrentLanguage('dialogues.savedSearch.removeSharedForbidden', [context.eval(entityTitleName)]);
	}

	return sc.utils.errorMessage.byMessage(message);
}
