import { isArray } from '@acx-xms/data-functions/dist';

export default {
	parameters(options) {
		return [
			this.eval(options.argument)
		];
	},
	async parametersAsync(options) {
		return [
			await this.evalAsync(options.argument)
		];
	},
	evaluate(array) {
		if (!isArray(array)) {
			return [];
		}
		return array.reverse();
	}
};
