<template>
	<div v-if="visible" class="searchResults-tableView">
		<ControlTableView
			:class="cssClass || ''"
			:options="component"
			:stateNamespace="stateNamespace"
			:evaluationContext="evaluationContext"
			:initResults="results"
			:paginationParams="paginationParams"
		/>
		<Pagination
			v-if="paginationParams"
			:pageSize="paginationParams.pageSize"
			:total="paginationParams.total"
			:from="paginationParams.from"
			:showPaging="paginationParams.showPaging"
			:activePages="paginationParams.activePages"
		/>
	</div>
</template>
<script>
/* eslint no-unused-vars: 0 */
import KOComponent from '@/Bindings/kocomponent.directive';
import { evaluate } from '@/Data/EvaluationContext/evaluationContext';
const ControlTableView = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'@/Components/Control/Table/control-table-view');
const Pagination = () => import(/* webpackChunkName: "deffered" */ /* webpackPrefetch: true */'@/Components/ComponentSet/pagination/pagination');

/* TODO: move it to config files or global refactor components setup processes */
const VARS = [
	'contentComponent',
	'namespace',
	'internalNamespace',
	'modalNamespace',
	'targetEntity',
	'isParent',
	'scrollEnabled',
	'withToggleAll',
	'syncSelection',
	'useParentContext',
	'withCheckAll',
	'withSelectionList',
	'evaluationContext',
	'tagListPLaceholder'
];

export default {
	name: 'component-set-search-results-table-view',
	props: {
		options: Object,
		evaluationContext: Object,
		results: Array,
		stateNamespace: String,
		paginationParams: Object,
		cssClass: String
	},
	components: {
		ControlTableView,
		Pagination
	},
	data() {
		return {
			component: {},
			visible: this.options.contentComponent.visible === undefined ? true : evaluate(this.options.contentComponent.visible)
		};
	},
	methods: {
		prepare() {
			/* set component directly */
			this.component = this.options.contentComponent;
			/* set component props */
			VARS.forEach(item => { this.component[item] = this.options[item]; });
			/* selection rules */
			// TODO: rework with state CoolectionType value
			if (this.options.allowCampaignTemplateTypes) {
				const ENTITY = 'listing';
				const WIZARD = this.$store.state.wizard && this.$store.state.wizard;
				const TYPE_STORE = WIZARD.steps.find(step => step.name === 'template');
				const TYPE_STORE_TEMPLATES = TYPE_STORE.templates;
				const TYPE_STORE_SELECTED = TYPE_STORE_TEMPLATES && TYPE_STORE_TEMPLATES.find(template => template.selected);
				const HAS_ARTIFACT = TYPE_STORE.artifact && TYPE_STORE.artifact.mainEntity && TYPE_STORE.artifact.mainEntity === ENTITY;
				/* if true - set false and backward */
				this.component.allowSelection = TYPE_STORE_SELECTED ? !(TYPE_STORE_SELECTED.inputTypes === ENTITY) : !HAS_ARTIFACT;
			} else this.component.allowSelection = this.options.allowSelection;
		}
	},
	created() {
		this.prepare();
		this.$on('.pageChanged', function (activePages) {
			// TODO: use state managment
			this.$parent.$emit('.pageChanged', activePages);
		});
	}
};
</script>
<style scoped>
	.info-holder > .grid-cell > .field-wrapper .flexible-field-wrapper, .info-holder > .grid-cell > .field-wrapper, .info-holder > .grid-cell > .flexible-field-wrapper {
		margin-top: 10px;
		display: block;
	}
</style>
