import Vue from 'vue';

Vue.directive('localization', (() => {
	function localize(element, key, args, attrs) {
		const localizations = sc.classes.get('localization.dataProvider');

		localizations.getCurrentLanguage()
			.then(function (lang) {
				return localizations.getLabel(lang.code, key, args);
			})
			.done(function (label) {
				if (attrs) {
					attrs.forEach(function (attr) {
						// $(element).attr(attr, label);
						element.setAttribute(attr, label);
					});
				}
				if (label && !attrs) {
					// $(element).html(label);
					element.innerHTML = label;
				}
			});
	}
	return {
		inserted: function (element, binding) {
			const key = binding.value.key;
			const attrs = binding.value.attrs;
			const args = binding.value.args;
			localize(element, key, args, attrs);
		},
		componentUpdated: function (element, binding) {
			// needed to prevent infinite loop
			if (JSON.stringify(binding.value.args) === JSON.stringify(binding.oldValue.args)) {
				return;
			}
			const key = binding.value.key;
			const attrs = binding.value.attrs;
			const args = binding.value.args;
			localize(element, key, args, attrs);
		}
	};
})());
