import ScMapGoogleMediator from './scMap.google.mediator';
import { isObject, isFunction } from '@acx-xms/data-functions/dist';

export default class {
	constructor(map, options) {
		this.tooltipTpl = '<div class="scMap-tooltip">' +
			'<div class="tile"></div>' +
			'<div class="tile mapCompositeArrow"></div>' +
			'<div class="actions"><i class="layout-icons-lightbox-close hover"></i></div>' +
			'</div>';
		this.options = {
			hideOn: 'outer_mousedown',
			offset: {
				x: 0,
				y: 0
			}
		};
		this.isActive = false;
		if (!isObject(map)) {
			throw new Error('ScMapTooltip: Property `options.map` is required');
		}
		if (!isObject(options.location)) {
			throw new Error('ScMapTooltip: Property `options.location` is required');
		}
		this.map = map;
		this.location = options.location;
		if (options.offset) {
			this.options.offset = options.offset;
		}
		this.__tooltipElem = $(this.tooltipTpl);

		this.__tooltipElem.find('.actions').on('click', this.remove);

		this.__mapContainer = $(this.map._map.getDiv());
		this.__updatePositionAfterShow = true;

		this.__tooltipElem.append(options.content);
		this.__mapContainer.append(self.__tooltipElem);
		this.mediator = new ScMapGoogleMediator({ context: this });
		this.removeFork = sc.events.fork();
		this.removeFork.on('scMap.google.removeMapTooltips', () => {
			this.remove();
		});
		// Update tooltip position on map interactions
		this.listenTo(this.map, 'zoom_changed', this.__updatePosition);
		this.listenTo(this.map, 'center_changed', this.__updatePosition);

		this.onCloseCallback = options.onCloseCallback;
	}

	__calculateTooltipHeight(mapHeight) {
		const isCompositeTooltip = !!this.__tooltipElem.find('.mapSearchResultsCompositeTooltip');

		if (isCompositeTooltip) {
			const pinHeight = 35;
			const $relatedRecordPopupWrapper = this.__tooltipElem.find('.related-record-popup-wrapper');
			$relatedRecordPopupWrapper.height(mapHeight / 2 - pinHeight);
		}
	}

	__updatePosition() {
		if (!this.isActive) {
			this.__updatePositionAfterShow = true;
			return;
		}

		const point = this.getPoint();
		point.y -= 36; // Change coordinates to center of marker
		point.x -= 7;

		const clusterTooltipElement = this.__tooltipElem.find('.clusterTooltip');
		const mapRect = this.__mapContainer[0].getBoundingClientRect();

		this.__calculateTooltipHeight(mapRect.height);

		const rects = this._getRects(point, mapRect);
		const rect = this._getCompatibleRect(mapRect, rects, this.__tooltipElem);
		let possibleClasses = _(rects).map(r => {
			return 'align-' + r.type + (clusterTooltipElement.length ? ' clustered-tooltip' : ''); // here
		}).value().join(' ');

		this.__tooltipElem
			.removeClass(possibleClasses)
			.addClass('align-' + rect.type + (clusterTooltipElement.length ? ' clustered-tooltip' : ''))
			.css({
				left: rect.elementOffset.left,
				top: rect.elementOffset.top
			});

		const $tile = this.__tooltipElem.find('.mapSearchResultsCompositeTooltip').length > 0
			? this.__tooltipElem.find('.tile.mapCompositeArrow')
			: this.__tooltipElem.find('.tile:visible:not(.mapCompositeArrow)');

		const $tiles = this.__tooltipElem.find('.tile');
		$tiles.not($tile).hide();

		possibleClasses = _(rects).map(function (r) {
			return 'tile-' + r.type + ' scMap-icons-' + 'tile-' + r.type + ' ' + r.type;
		}).value().join(' ');

		let tileClasses = '';

		if ($tile.hasClass('mapCompositeArrow')) {
			tileClasses = ' center ' + rect.type;
		} else {
			tileClasses = 'tile-' + rect.type + ' scMap-icons-tile-' + rect.type;
		}

		$tile
			.removeClass(possibleClasses)
			.addClass(tileClasses)
			.css(this._getArrowOffset(rect));
	}

	_getRects(point, rect) {
		const MapRect = (type, left, top, width, height, pointOffset) => {
			this.type = type;
			this.offset = {
				left,
				top
			};
			this.width = width;
			this.height = height;
			this.pointOffset = pointOffset;
		};
		const elementOffset = {
			left: /* rect.left + */point.x,
			top: /* rect.top + */point.y
		};

		return [
			new MapRect('top', rect.left, rect.top, rect.width, point.y, elementOffset),
			new MapRect('bottom', rect.left, point.y, rect.width, rect.height - point.y, elementOffset),
			new MapRect('right', point.x, rect.top, rect.width - point.x, rect.height, elementOffset),
			new MapRect('left', rect.left, rect.top, point.x, rect.height, elementOffset)
		];
	}

	_getCompatibleRect(mapRect, rects, elem) {
		let rect = _(rects).sortBy(function (r) { return r.width * r.height; }).findLast(function (r) {
			return r.width >= elem.outerWidth() && r.height >= elem.outerHeight();
		});
		if (!rect) {
			rect = _.maxBy(rects, (r) => { return r.width * r.height; });
		}

		if (rect.width < elem.outerWidth() || rect.height < elem.outerHeight()) {
			console.warn('Selected rect isn\'t compatible with popup');
		}

		const mapping = {
			top: () => {
				return {
					left: rect.pointOffset.left - (elem.outerWidth() / 2),
					top: rect.pointOffset.top - elem.outerHeight()
				};
			},
			left: () => {
				return {
					left: rect.pointOffset.left - elem.outerWidth(),
					top: rect.pointOffset.top - (elem.outerHeight() / 2)
				};
			},
			right: () => {
				return {
					left: rect.pointOffset.left,
					top: rect.pointOffset.top - (elem.outerHeight() / 2)
				};
			},
			bottom: () => {
				return {
					left: rect.pointOffset.left - (elem.outerWidth() / 2),
					top: rect.pointOffset.top
				};
			}
		};

		const elementOffset = mapping[rect.type]();

		if (elementOffset.left < 0) {
			elementOffset.left = 0;
		}
		if (elementOffset.top < 0) {
			elementOffset.top = 0;
		}
		if (elementOffset.left + elem.outerWidth() > mapRect.width) {
			elementOffset.left = mapRect.width - elem.outerWidth();
		}
		if (elementOffset.top + elem.outerHeight() > mapRect.height) {
			elementOffset.top = mapRect.height - elem.outerHeight();
		}

		rect.elementOffset = elementOffset;

		return rect;
	}

	_getArrowOffset(rect) {
		if (rect.type === 'top' || rect.type === 'bottom') {
			return { left: rect.pointOffset.left - rect.elementOffset.left };
		}
		return { top: rect.pointOffset.top - rect.elementOffset.top };
	}

	getLocation() {
		let location;

		if (isFunction(this.location.getLocation)) {
			location = this.location.getLocation();
		} else {
			location = this.location;
		}

		return location;
	}

	getPoint() {
		const location = this.getLocation();

		return this.map.locationToPoint(location.lat, location.lng);
	}

	show(skipEvent) {
		$(document).trigger('click'); // need to close aside panels in compact mode (layout.adaptiveAsidePanel)

		if (this.isActive) { return; }

		if (this.__updatePositionAfterShow) {
			let width; let height; const $tooltip = this.__tooltipElem; // here

			const fn = () => {
				if (width !== $tooltip.width() || height !== $tooltip.height()) {
					width = $tooltip.width();
					height = $tooltip.height();
				} else {
					return;
				}

				this.isActive = true;
				$tooltip.addClass('active');
				this.__updatePosition();
			};
			if (skipEvent) {
				fn();
			} else {
				$tooltip.on('DOMNodeInserted', _.debounce(fn, 100));
			}
		}
	}

	hide() {
		if (!this.isActive) { return; }

		this.isActive = false;

		this.__tooltipElem.removeClass('active');
	}

	remove(e) {
		this.base();

		if (e && this.onCloseCallback) {
			this.onCloseCallback();
		}

		this.isActive = false;
		this.__tooltipElem.remove();
		this.mediator.dispose();
		this.removeFork.dispose();
	}
}
