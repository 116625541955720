import helperMethods from '../ComponentSet/component-set-evaluation-context-helper.methods';
export default {
	methods: {
		getOrderByFilter(field, isMultiple, hasPredefinedFields) {
			if (field === undefined) {
				return;
			}
			field = Array.isArray(field) ? field : [field];
			const fltr = (!isMultiple && !hasPredefinedFields)
				? {
					logicalName: field[0].logicalname,
					query: !field[0].asc
				}
				: {
					query: field.map(f => {
						return {
							logicalName: f.logicalname,
							value: !f.asc
						};
					})
				};

			return sc.classes.get('orderBy.filter', fltr);
		},

		getDefaultOrderByField(filterFields) {
			return filterFields.filter((field) => {
				return field.isSelected;
			});
		},

		evalOrderByField(fields) {
			return (fields || []).map(field => {
				return {
					logicalname: field.logicalName && helperMethods.eval(this.evaluationContext, field.logicalName),
					displayName: field.displayName && helperMethods.eval(this.evaluationContext, field.displayName),
					isSelected: (field.default && helperMethods.eval(this.evaluationContext, field.default)) || false,
					asc: field.ascending && helperMethods.eval(this.evaluationContext, field.ascending),
					visible: field.visible
				};
			});
		}
	}
};
