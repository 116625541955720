export default {
	computed: {
		errorMessage() {
			const _errorMessage = this.$store.state.errorMessage;
			try {
				const errorObj = JSON.parse(_errorMessage);
				return errorObj.Message;
			} catch (e) {
				return _errorMessage;
			}
		}
	}
};
