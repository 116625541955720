<template>
	<div class="organizations" v-perfectscroll="{ enable: true }">
		<p @click="SelectOrg(org)" v-for="org in organizations" :key="org.id">{{org.id}}</p>
	</div>
</template>
<script>
import axios from 'axios';
export default {
	name: 'static-discovery',
	data() {
		return { organizations: [] };
	},
	async created() {
		this.$store.commit('changeInitMessage', 'Loading available organizations');
		const organizationsResp = await axios.get('/api/Discovery/static');
		this.$store.commit('changeInitMessage', null);

		this.organizations = organizationsResp.data;
		if (!this.organizations.length) {
			this.$store.commit('changeErrorMessage', 'No organizations found');
			this.$router.push('/error');
		} else if (this.organizations.length === 1) {
			this.SelectOrg(this.organizations[0]);
		}
	},
	methods: {
		SelectOrg(org) {
			this.$router.push(`/${org.id}`);
		}
	}
};
</script>
<style src="@/Components/Initialization/initialization.mixins.less" scoped></style>
