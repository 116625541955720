import {
	generateGuid, CreateBulk, Search, SendRequest
} from '@acx-xms/data-functions/dist';
import store from '@/States/ms-state';

export default {
	async enabled(options, evaluationContext, selection) {
		return true;
	},

	async execute(options, context, selection) {
		this.parentRef = context.entity;
		const componentSet = await sc.classes.get('marketspaceComponents.dataProvider').getComponentSetById(context.eval(options.componentsetName));

		sc.events.emit('vue.dialog.open', {
			icon: 'entity-icons-deal-small',
			title: 'Add Listing',
			allowPin: false,
			component: 'ms.addlisting.dialog',
			maximizedWidth: '90%',
			maximizedHeight: '100%',
			evaluationContext: context,
			params: {
				componentSet,
				modalNamespace: 'addlistingpopup',
				searchResultComponentName: options.searchResultComponentName,
				modalDialogCallback: (listings, availabilities) => {
					return this.addListings(listings, (availabilities && availabilities.length > 0 ? availabilities : []))
						.then(() => sc.events.emit(context.eval(options.refreshNamespace) + '.searching'));
				},
				refreshNamespace: context.eval(options.refreshNamespace),
				toastMessageKey: context.eval(options.toastMessageKey),
				informationDialogTextKey: context.eval(options.informationDialogTextKey)
			}
		});
	},
	async addListings(listings, availabilities) {
		let records = [];
		const selected = [];
		const promiseArr = [];
		[...listings, ...availabilities].forEach((entity) => {
			const memberRef = sc.classes.get('entityReference', entity);
			if (this.parentRef.id) {
				promiseArr.push(Search('staticlistmember',
					[
						sc.classes.get('termFacet.filter', {
							logicalName: 'staticlist.id',
							query: [this.parentRef.id]
						}).fillQuery(),
						sc.classes.get('termFacet.filter', {
							logicalName: 'entityreference.id',
							query: [memberRef.id]
						}).fillQuery(),
						sc.classes.get('selectedFields.filter', []).fillQuery()
					]
				));
				selected.push(memberRef);
			}
		});
		const searchData = await Promise.all(promiseArr);
		records = selected.filter((_, index) => searchData[index].Total === 0).map(record => {
			return {
				staticlist: {
					id: this.parentRef.id,
					logicalname: 'staticlist'
				},
				entityreference: record,
				name: record.name,
				type: 'staticlistmember'
			};
		});
		if (records.length) {
			const [createdMembers, { PrincipalAccess: access }] = await Promise.all([
				SendRequest('/api/MarketingList/CreateMembers', 'POST', records),
				sc.classes.get('edge.dataProvider').getAccess('staticlist', this.parentRef.id)
			]);
			const staticListMembersIds = createdMembers.map(({ body }) => body.Id);
			createdMembers.forEach(({ body, status }) => {
				if (status === 200) {
					this.stateNamespace = 'mainSearch.marketspace.staticlistedit';
					const filters = store.getters[this.stateNamespace + '/getFiltersRaw'](body.entityreference.logicalname);
					Object.keys(filters).forEach(f => {
						if (filters[f] && filters[f].requestLabel === 'Ids') {
							filters[f].data.push(body.entityreference.id);
						}
					});
					store.commit(this.stateNamespace + '/replaceAllFilters', {
						entity: body.entityreference.logicalname,
						filters
					});
				}
			});
			const principals = access.filter(item => item.Privileges.some(p => p === 'Share')).map(item => item.Principal.id);
			const shareRecords = [];
			staticListMembersIds.forEach(id => {
				principals.forEach(userId => {
					shareRecords.push({
						id: generateGuid(),
						method: 'POST',
						url: `staticlistmember/${id}/GrantAccess`,
						body: {
							SyncProcessMode: 'Brief',
							PrincipalType: 'systemuser',
							PrincipalId: userId,
							AccessRights: ['Read', 'Share']
						}
					});
				});
			});
			CreateBulk(shareRecords, { async: true });
		}
		// TODO: implement multiple refresh events for actions
		sc.events.emit('mainSearch.marketspace.staticlistedit.searching');
		sc.events.emit('mainSearch.marketspace.staticlistedit.availabilities.searching');
		sc.events.emit('mainSearch.marketspace.staticlists.searching');
	}
};
